import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import StatusMark from "../../partisals/StatusMark";
import * as global from '../../../helpers/constants'
import {ReactComponent as CloseIcon} from "../../../assets/icons/close-icon.svg";
// import moment from "moment";

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
  margin-top: 20px;
`

const ActivityFeedItem = styled.div`
  display: flex;
  justify-content: space-between;
  
  padding-top: 7px;
  padding-bottom: 5px;
  gap: 10px;
  .feed-body {
  word-break: break-all;
  }
`

const TextWrapper = styled.div`
  width: 100%;
  margin-left: 15px;
`

const CloseWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`

const ActivityFeed = (props) => {
  const { activityFeeds, setHideActivity } = props
  // const [feedData] = useState(global.fakeActivityFeedData)
  const [activityFeedData, setActivityFeedData] = useState([])

  useEffect(() => {
    setActivityFeedData(activityFeeds.map(feed => ({
      status: 1,
      name: feed.event_id,
      content: feed.event_feed,
      passTime: global.getPassTime(feed.event_time),
      org_id: feed.organization_id,
      env: feed.event_enviorment,
      type: feed.event_type,
      prj_id: feed.project_id,
      provider: feed.provider
    })))
  }, [activityFeeds])


  return (
      <div>
        <CloseWrapper className="cursor-pointer" onClick={() => setHideActivity(hideActivity => !hideActivity)}>
          <CloseIcon width={20} stroke="#A4AFB7" />
        </CloseWrapper>
        <span className="font-size-36 font-weight-bold m-gray">Activity Feed</span>
        <ItemWrapper>
          {/*{Object.keys(activityFeedData).map(key => (*/}
          {/*    <div>*/}
                <span className="font-size-14 font-weight-normal m-dark-gray-40 text-capitalize"/>
                {activityFeedData.filter((item, idx) => idx < 10).map((item, idx) => (
                    <ActivityFeedItem key={idx}>
                      <div className="d-flex feed-body">
                        <div className="mt-2">
                          <StatusMark size={10} color={global.colors.status[item.status - 1]} />
                        </div>
                        <TextWrapper>
                          <span className="font-weight-bold font-size-16 m-dark-gray m-tooltip">
                            {item.content}
                            {/*<span className="tooltiptext">{item.name}</span>*/}
                          </span>
                          <p className="font-weight-normal font-size-14 m-dark-gray">{item.type}</p>
                          <p className="font-weight-normal font-size-14 m-dark-gray mt-n3 mb-0 pb-0">{item.name}</p>
                        </TextWrapper>
                      </div>
                      <span className="font-weight-normal font-size-14 m-dark-gray-60">{item.passTime}</span>
                    </ActivityFeedItem>
                ))}
              {/*</div>*/}
          {/*))}*/}
        </ItemWrapper>
      </div>
  )
}

export default ActivityFeed;
