import React, { useState, useEffect, useCallback } from "react";
import StyledTypo from "../../../../components/Typo/StyledTypo";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/cancel.svg";
import "react-modern-drawer/dist/index.css";
import { Col, Row } from "reactstrap";
import SlidePanel from "../../../../components/SlidePanel/SlidePanel";
import { ReactComponent as LeftArrow } from "../../../../assets/icons/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../../../assets/icons/right-arrow.svg";
import { ReactComponent as ExpandIcon } from "../../../../assets/icons/expand.svg";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { coldarkCold } from "react-syntax-highlighter/dist/esm/styles/prism";
import { actionIacPlatform } from "../../../../helpers/constants";
import { Badge } from "reactstrap";
import CommonSelect from '../../../../components/Select/CommonSelect'


const ResultTableBodyWrapper = styled.div`
  padding: 10px;
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #eaeaea;
  height: 200px;
  min-height: 180px;
  overflow-y: auto;
  overflow-x: hidden;
  resize: vertical;
`;

const ResultTableHeaderWrapper = styled.div`
  padding: 10px;
  padding-bottom: 0px;
  background: #f4f4f4;
  border: 1px solid #eaeaea;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const DividerWrapper = styled.div`
  height: 0;
  width: calc(100% + 60px);
  margin-left: -30px;
  border-bottom: 0.5px solid #ccc;
`;

const CloseWrapper = styled.div`
  border-radius: 10px;
  min-width: 45px;
  min-height: 40px;
  cursor: pointer;
  max-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CodeWrapper = styled.div`
  padding: 10px;
  width: 100%;
  word-break: break-word;
  border-radius: 5px;
  background: #fafafa;
  border: 1px solid #eaeaea;
  margin-top: 10px;
  height: calc(100vh - 550px);

  pre {
    word-break: break-all;
    overflow-x: hidden;
    // width: fit-content;
    background: transparent !important;
    padding: 0 !important;
  }
  .positive-highlight {
    background: #f9d7dc !important;
  }
  .negative-highlight {
    background: #ddfbe6 !important;
  }
`;

const ZoomCodeWrapper = styled(CodeWrapper)`
  height: calc(100vh - 180px);
`;

const ScrollWrapper = styled.div`
  max-height: 99%;
  overflow: auto;
  padding-right: 10px;
  padding-bottom: 10px;
  word-break: break-word;
`;

const MoveCTAWrapper = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  // align-items: center; // commented since items are not visible in safari
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  cursor: pointer;
`;

const CodeZoomWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  // align-items: center;
  cursor: pointer;
`;

const SeverityWrapper = styled.span`
  position: absolute;
  left: calc(100% - 200px);
  text-transform: capitalize;
`;

const CountWrapper = styled.span`
  position: absolute;
  left: calc(100% - 280px);
`;

const TitleWrapper = styled.span`
  display: inline-block;
  width: 58%;
  height: 1.4em;
`;

const CategoryContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 0px;
  justify-content: flex-end;
`

const emptyOption = { label: 'Select...', value: '' }

const IaCDetails = (props) => {
  const {
    data: allData,
    isOpen,
    toggle,
    compliant,
    non_compliant,
    positiveLineNumbers,
    openItemId,
    setOpenItemId,
  } = props;
  const [codeZoom, setCodeZoom] = useState(false);
  const [data, setData] = useState({})

  const [selectedError, setSelectedError] = useState(emptyOption)
  const [selectedType, setSelectedType] = useState(emptyOption)

  const [errorOptions, setErrorOptions] = useState([])
  const [typeOptions, setTypeOptions] = useState([])

  const findIndex = () => {
    return allData.findIndex((project) => project.query_id === openItemId);
  };

  const ActiveCodeWrapper = codeZoom ? ZoomCodeWrapper : CodeWrapper;

  useEffect(() => {
    setData(allData?.filter((item) => item.query_id === openItemId)?.[0] ?? {})
  }, [allData, openItemId])

  useEffect(() => {
    const errors = [...new Set((data?.files ?? [])?.map(res => res.issue_type))];
    const types = [...new Set((data?.files ?? [])?.map(res => res.resource_type))];

    setErrorOptions([emptyOption, ...errors.map(error => ({
      label: error,
      value: error
    }))])
    setTypeOptions([emptyOption, ...types.map(status => ({
      label: status,
      value: status
    }))])
  }, [data])

  const filteredData = useCallback(() => {
    let res = data?.files ?? []
    if(selectedError.value !== '') {
      res = res.filter(each => selectedError.value === each?.issue_type)
    }
    if(selectedType.value !== '') {
      res = res.filter(each => each?.resource_type === selectedType.value)
    }
    return res;
  }, [
    data,
    selectedError,
    selectedType,
  ])

  const findId = (up = true) => {
    const idx = findIndex();
    const targetIdx = up ? idx + 1 : idx - 1;
    return allData.filter((project, idx) => idx === targetIdx)?.[0]?.query_id;
  };
  return (
    <SlidePanel isOpen={isOpen} toggle={toggle} slideId="iac-detail-slide">
      <div className="p-3 position-relative mb-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <CloseWrapper className="cursor-pointer" onClick={toggle}>
            <CloseIcon width={22} fill="#505050" />
          </CloseWrapper>
          <SeverityWrapper>
            <Badge pill color={data?.severity?.toLowerCase()}>
              {data?.severity?.toLowerCase()}
            </Badge>
          </SeverityWrapper>
          <CountWrapper>
            <Badge pill color="primary">
              Count: {data?.files?.length}
            </Badge>
          </CountWrapper>
          <TitleWrapper>
            <StyledTypo className="text-center heading ln-2">
              {data?.platform} - {data?.query_name}
            </StyledTypo>
          </TitleWrapper>
          <div className="d-flex align-items-center">
            <StyledTypo className="mr-2" size="0.8">
              {findIndex() + 1} of {allData.length}
            </StyledTypo>
            <MoveCTAWrapper
              disabled={findIndex() === 0}
              onClick={() => setOpenItemId(findId(false))}
            >
              <LeftArrow fill={findIndex() === 0 ? "#aeaeae" : "#505050"} />
            </MoveCTAWrapper>
            <MoveCTAWrapper
              disabled={findIndex() + 1 === allData.length}
              onClick={() => setOpenItemId(findId(true))}
            >
              <RightArrow
                fill={
                  findIndex() + 1 === allData.length ? "#aeaeae" : "#505050"
                }
              />
            </MoveCTAWrapper>
          </div>
        </div>
        <DividerWrapper className="mb-3" />
        <div>
          {!codeZoom && (
            <>
              <div>
                <Row>
                  <Col xs={1}>
                    <StyledTypo className="label">Description</StyledTypo>
                  </Col>
                  <Col xs={11}>
                    <StyledTypo className="ln-2">{data?.description}</StyledTypo>
                  </Col>
                </Row>
              </div>
              <CategoryContainer>
                <div style={{ minWidth: 200 }}>
                  <CommonSelect
                    value={selectedError}
                    setValue={setSelectedError}
                    options={errorOptions}
                    label={`Error`}
                  />
                </div>
                <div className="ml-3" style={{ minWidth: 300 }}>
                  <CommonSelect
                    value={selectedType}
                    setValue={setSelectedType}
                    options={typeOptions}
                    label={`Resource Type`}
                  />
                </div>
              </CategoryContainer>
              <ResultTableHeaderWrapper>
                <Row className="text-break mb-2">
                  <Col xs={4}>
                    <StyledTypo className="label table"># Filename</StyledTypo>
                  </Col>
                  <Col xs={1}>
                    <StyledTypo className="label table">Line</StyledTypo>
                  </Col>
                  <Col xs={1}>
                    <StyledTypo className="label table">Error</StyledTypo>
                  </Col>
                  <Col xs={2}>
                    <StyledTypo className="label table">Actual</StyledTypo>
                  </Col>
                  <Col xs={2}>
                    <StyledTypo className="label table">Expected</StyledTypo>
                  </Col>
                  <Col xs={1}>
                    <StyledTypo className="label table">Resource Name</StyledTypo>
                  </Col>
                  <Col xs={1}>
                    <StyledTypo className="label table">Resource Type</StyledTypo>
                  </Col>
                </Row>
              </ResultTableHeaderWrapper>
              <ResultTableBodyWrapper>
                {filteredData()?.map((f, idx) => (
                  <Row
                    className={`text-break py-2 ${
                      idx % 2 === 0 ? "bg-white" : ""
                    }`}
                    style={{ backgroundColor: "#f7f7f7" }}
                  >
                    <Col xs={4}>
                      <StyledTypo className="mt-1 table">
                        <strong pill bg="dark">
                          {idx + 1}
                        </strong>{" "}
                        {f?.file_name}
                      </StyledTypo>
                    </Col>
                    <Col xs={1}>
                      <StyledTypo className="mt-1 table">{f?.line}</StyledTypo>
                    </Col>
                    <Col xs={1}>
                      <StyledTypo className="mt-1 table">
                        {f?.issue_type}
                      </StyledTypo>
                    </Col>
                    <Col xs={2}>
                      <StyledTypo className="mt-1 table">
                        {f?.actual_value}
                      </StyledTypo>
                    </Col>
                    <Col xs={2}>
                      <StyledTypo className="mt-1 table">
                        {f?.expected_value}
                      </StyledTypo>
                    </Col>
                    <Col xs={1}>
                      <StyledTypo className="mt-1 table">
                        {f?.resource_name}
                      </StyledTypo>
                    </Col>
                    <Col xs={1}>
                      <StyledTypo className="mt-1 table">
                        {f?.resource_type}
                      </StyledTypo>
                    </Col>
                  </Row>
                ))}
              </ResultTableBodyWrapper>
            </>
          )}
          <CodeZoomWrapper onClick={() => setCodeZoom(!codeZoom)}>
            <ExpandIcon fill="#aeaeae" />
          </CodeZoomWrapper>
          <Row>
            <Col md={6}>
              <StyledTypo className="label">
                Sample Non-Compliance code
              </StyledTypo>
              <ActiveCodeWrapper>
                <ScrollWrapper>
                  <SyntaxHighlighter
                    language={
                      actionIacPlatform?.filter(
                        (item) =>
                          item.codeValue.toLowerCase() ===
                            data?.platform?.toLowerCase() ||
                          item.value.toLowerCase() ===
                            data?.platform?.toLowerCase
                      )?.[0]?.codeType ?? "json"
                    }
                    showLineNumbers={true}
                    wrapLongLines={true}
                    style={coldarkCold}
                    lineProps={(lineNumber) => {
                      if (
                        (Array.isArray(positiveLineNumbers)
                          ? positiveLineNumbers
                          : []
                        ).find((item) => item.line === lineNumber)
                      )
                        return {
                          class: "positive-highlight",
                        };
                      return {};
                    }}
                  >
                    {non_compliant}
                  </SyntaxHighlighter>
                </ScrollWrapper>
              </ActiveCodeWrapper>
            </Col>
            <Col md={6}>
              <StyledTypo className="label">Sample Compliance code</StyledTypo>
              <ActiveCodeWrapper>
                <ScrollWrapper>
                  <SyntaxHighlighter
                    language={
                      actionIacPlatform?.filter(
                        (item) =>
                          item.codeValue.toLowerCase() ===
                            data?.platform?.toLowerCase() ||
                          item.value.toLowerCase() ===
                            data?.platform?.toLowerCase
                      )?.[0]?.codeType ?? "json"
                    }
                    showLineNumbers={true}
                    wrapLongLines={true}
                    style={coldarkCold}
                  >
                    {compliant}
                  </SyntaxHighlighter>
                </ScrollWrapper>
              </ActiveCodeWrapper>
            </Col>
          </Row>
        </div>
      </div>
    </SlidePanel>
  );
};

export default IaCDetails;
