import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import AuthDashboard from "./AuthDashboard";
import styled from "styled-components";

import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import StyledTypo from "../../components/Typo/StyledTypo";
import CommonInput from "../../components/Input/CommonInput";
import { ReactComponent as CheckIcon } from "../../assets/icons/check_icon.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/lock_icon.svg";
import StyledCheckbox from "../../components/Input/StyledCheckbox";
import EffectedButton from "../../components/Buttons/EffectedButton";
import DividerWithLabel from "../../components/Divider/DividerWithLabel";
import { ReactComponent as GoogleIcon } from "../../assets/icons/google_icon.svg";

import { MainContext } from "../../contexts";
import { commonTitle, showAlert } from "../../helpers/constants";
import { authAction } from "../../redux/actions";
import { useTitle } from "../../helpers/useTtitle";
import RecaptchaWrapper from '../../components/recaptchaWrapper/RecaptchaWraper'

const LeftWrapper = styled.div`
  background: linear-gradient(359.21deg, #76eeb5 -20.98%, #58a3ee 100.74%);
  width: 426px;
  height: 100%;
  min-height: 715px;
  border-radius: 20px 0 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 47px 0 65px;
`;

const RightWrapper = styled.div`
  padding: 84px 111px 93px 203px;
  height: 100%;
  width: 652px;
`;

const StyledAnchor = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;

const pageTitle = `${commonTitle} - Sign In`;

const Login = (props) => {
  const {
    loggedInUser,
    loginWithCredential,
    setLoggedInUserAction,
    setLoggedInStatusAction,
    loggedInStatus,
    logoutAction,
  } = props;
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [organization, setOrganization] = useState("");
  const [token, setToken] = useState()
  const [isRefresh, setRefresh] = useState(false)

  const history = useHistory();
  const { firebase } = useContext(MainContext);

  useTitle(pageTitle);
  
  useEffect(() => {
    const current_user = localStorage.getItem("current-user");
    localStorage.removeItem("current-user");
    localStorage.removeItem("user_token");
    localStorage.removeItem("subscription");
    setLoggedInUserAction(null);
    if (current_user && current_user !== "null") {
      const user_data = JSON.parse(current_user);
      const current_loc_origin = window.location.origin;
      if (
        user_data?.["location_origin"] &&
        user_data?.["location_origin"] !== current_loc_origin
      ) {
        window.location.href = `${user_data?.["location_origin"]}/login`;
      }
      logoutAction(current_user.email)
    }
    setRefresh(true)
  }, []);

  useEffect(() => {
    if (
      loggedInStatus &&
      loggedInUser &&
      Object.keys(loggedInUser).length &&
      loggedInUser?.["user_token"]
    ) {
      delete loggedInUser["kind"];

      localStorage.setItem("current-user", JSON.stringify(loggedInUser));
      localStorage.setItem("user_token", loggedInUser?.user_token);
      localStorage.setItem(
        "subscription",
        JSON.stringify({
          subscription: loggedInUser?.organization?.subscription,
        })
      );
      showAlert("success", "Login Success", "You logged in successfully.");
      setLoggedInStatusAction(false);
      // if (current_user?.['organization']?.['prefix']) {
      //   const prefix = current_user?.['organization']?.['prefix']
      //   if(current_user?.['organization']?.subscription?.status === 'trialing') {
      //     setLoggedInUserAction(null)
      //     window.location.href = `https://${prefix}.trial.x.matossphere.com?user-token=${btoa(loggedInUser?.user_token)}&current-user=${btoa(JSON.stringify(current_user))}`
      //   } else {
      //     setLoggedInUserAction(null)
      //     window.location.href = `https://${prefix}.matossphere.com?user-token=${btoa(loggedInUser?.user_token)}&current-user=${btoa(JSON.stringify(current_user))}`
      //   }
      // } else {
      //   history.push('/observe')
      // }
      history.push("/dashboard");
    }
  }, [loggedInStatus, loggedInUser]);

  const handleOnGoogle = () => {
    firebase.auth
      .signInWithPopup(firebase.provider)
      .then((result) => {
        let user = result.user;

      })
      .catch((error) => {
        const errorCode = error.code;
        const credential = error.credential;
        console.log(credential, errorCode);
      });
  };

  const handleSubmit = () => {
    if (email === "" || pwd === "") {
      showAlert(
        "warning",
        "Invalid Input",
        "Please fill valid email or password."
      );
      return;
    }
    if ( !token ) {
      showAlert(
        "warning",
        "Google Recaptcha Error",
        "Please check Google Recaptcha configuration."
      );
      return;
    }
    // localStorage.removeItem('current-user')
    localStorage.removeItem("user_token");
    loginWithCredential({
      email, password: pwd, captcha: token
    });
    // history.push('/dashboard')
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <AuthDashboard>
      <RecaptchaWrapper
        setToken={setToken}
        isRefresh={isRefresh}
        setRefresh={setRefresh}
      />
      <div className="d-flex">
        <LeftWrapper>
          <div className="text-center">
            <Logo width={40} height={60} fill="#fff" />
            <StyledTypo
              className="mt-3"
              color="#fff"
              size={1.2}
              line={34.4 / 28}
              letter="0"
              family="Roboto"
              fontWeight={400}
            >
              MatosSphere
            </StyledTypo>
          </div>
          <div className="text-center">
            <StyledTypo
              color="#fff"
              size={1}
              line={1.4}
              letter="0"
              family="Roboto"
              fontWeight={400}
            >
              Secure your cloud from build to run time
            </StyledTypo>
            <div className="col-10 offset-1">
              <StyledTypo
                className="mt-3 pt-1"
                color="#fff"
                size={0.8}
                line={25.2 / 18}
                letter="0"
                family="Roboto"
                fontWeight={300}
              >
                CloudMatos continuously assess, detect, govern, and remediate
                misconfigurations and threats in your build, deploy and cloud
                (AWS, GCP, Azure and Kubernetes) environments
              </StyledTypo>
            </div>
          </div>
        </LeftWrapper>
        <RightWrapper>
          <StyledTypo
            className="mb-4"
            color="#505050"
            size={1.5}
            line={50.4 / 36}
            letter="0"
            family="Roboto"
            fontWeight={400}
          >
            Sign In
          </StyledTypo>
          <div>
            <CommonInput
              label="Your Email"
              value={email}
              setValue={setEmail}
              active={false}
              locked={false}
              icon={CheckIcon}
              placeholder="Enter your email"
              handleKeyPress={handleKeyPress}
              autoComplete="email"
            />
          </div>
          <div>
            <CommonInput
              label="Password"
              value={pwd}
              setValue={setPwd}
              active={false}
              locked={false}
              icon={LockIcon}
              placeholder="Enter your password"
              inputType="password"
              handleKeyPress={handleKeyPress}
              autoComplete="current-password"
            />
          </div>
          <div>
            <CommonInput
              label="Organization"
              value={organization}
              setValue={setOrganization}
              active={false}
              locked={false}
              placeholder="Enter, if applicable"
            />
          </div>
          <div className="d-flex justify-content-between pt-2">
            <div className="d-flex">
              <StyledCheckbox
                checked={false}
                name="Remember me"
                fontSize="0.8"
                fontWeight={300}
                fontFamily="Roboto"
              />
            </div>
            <StyledAnchor onClick={() => history.push("/forgot-password")}>
              <StyledTypo
                color="#485DCD"
                size={0.8}
                line={1.1}
                letter="0"
                family="Poppins"
                fontWeight={300}
              >
                Forgot password?
              </StyledTypo>
            </StyledAnchor>
          </div>
          <div className="mt-4">
            <EffectedButton width="100%" type="submit" onClick={handleSubmit} disabled={!token}>
              <StyledTypo
                color="#fff"
                size={0.8}
                line={25.2 / 18}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                Sign In
              </StyledTypo>
            </EffectedButton>
          </div>
          <div className="d-flex mx-auto justify-content-center mt-3">
            <StyledTypo
              color="#505050"
              size={0.8}
              line={26.6 / 14}
              letter="0"
              family="Poppins"
              fontWeight={300}
            >
              Don't have an account?
            </StyledTypo>
            <StyledAnchor onClick={() => history.push("/register")}>
              <StyledTypo
                className="ml-2"
                color="#485DCD"
                size={0.8}
                line={26.6 / 14}
                letter="0"
                family="Poppins"
                fontWeight={300}
              >
                Click here
              </StyledTypo>
            </StyledAnchor>
          </div>
          <div className="mt-3 py-3">
            <DividerWithLabel />
          </div>
          <div className="mt-3 pt-2">
            <EffectedButton
              outline
              padding={10}
              noSpan
              width="100%"
              background="#A4AFB7"
              onClick={handleOnGoogle}
            >
              <GoogleIcon />
              <StyledTypo
                className="ml-3"
                color="#505050"
                size={0.8}
                line={25.2 / 18}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                Login with Google
              </StyledTypo>
            </EffectedButton>
          </div>
        </RightWrapper>
      </div>
    </AuthDashboard>
  );
};

function mapStateToProps(state) {
  const { loggedInUser, loggedInStatus } = state.auth;
  return {
    loggedInUser,
    loggedInStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginWithCredential: (payload) =>
      dispatch(authAction.loginAction(payload)),
    setLoggedInUserAction: (loggedInUser) =>
      dispatch(authAction.setLoggedInUserAction(loggedInUser)),
    setLoggedInStatusAction: (loggedInStatus) =>
      dispatch(authAction.setLoggedInStatusAction(loggedInStatus)),
    logoutAction: (email) => dispatch(authAction.logoutAction(email)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
