import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import StyledTypo from "../../../components/Typo/StyledTypo";
import EffectedButton from "../../../components/Buttons/EffectedButton";

import { ReactComponent as CirclePlusIcon } from "../../../assets/icons/circle_add_icon.svg";
import OrganizationTable from "./OrganizationTable";
import { usersActions } from "../../../redux/actions";

const PanelWrapper = styled.div`
  padding: 42.5px 46.5px 42.5px 37.5px;
  width: 100%;
  /* Gray light 2 */

  // border: 1px solid #f1f1f1;
  // box-sizing: border-box;
  // border-radius: 0 20px 20px 20px;
  min-height: 450px;

  .panel-title-header {
    padding: 0 0 47px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const PanelHeader = styled.div`
  padding: 20px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OrgListPanel = (props) => {
  const { organizations, manageOrgStatusAction, deleteManageOrgAction } = props;

  const history = useHistory();
  const [organizationList, setOrganizationList] = useState([]);

  useEffect(() => {
    setOrganizationList([...organizations]);
  }, [organizations]);

  return (
    <PanelWrapper>
      <PanelHeader>
        <StyledTypo
          color="#505050"
          size={1}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={700}
        >
          Organization List
        </StyledTypo>
        <div>
          <EffectedButton
            padding={0}
            height={45}
            noSpan
            onClick={() => history.push("/manage/add-org")}
          >
            <CirclePlusIcon />
            <StyledTypo
              className="ml-2"
              color="#fff"
              size={0.875}
              line={1.4}
              letter="0"
              family="Roboto"
              fontWeight={500}
            >
              New Organization
            </StyledTypo>
          </EffectedButton>
        </div>
      </PanelHeader>
      <div>
        <OrganizationTable
          organizations={organizationList}
          manageOrgStatusAction={manageOrgStatusAction}
          deleteManageOrgAction={deleteManageOrgAction}
        />
      </div>
    </PanelWrapper>
  );
};

function mapStateToProps(state) {}

function mapDispatchToProps(dispatch) {
  return {
    manageOrgStatusAction: (name, action) =>
      dispatch(usersActions.manageOrgStatusAction(name, action)),
    deleteManageOrgAction: (name, action) =>
      dispatch(usersActions.deleteManageOrgAction(name, action)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgListPanel);
