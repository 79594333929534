import React, { useEffect, useState } from "react";

export const GradientProgressBar = (props) => {
  const { value, maxValue, color, color2, rotation, showNumber } = props;
  const [maxWidth, setMaxWidth] = useState("0");

  useEffect(() => {
    setTimeout(() => setMaxWidth(value + "%"), 10);
  }, [value]);

  const getRadius = () => {
    return "2px";
  };

  const progressBarStyle = () => ({
    backgroundImage:
      "linear-gradient(" + rotation + "deg, " + color + ", " + color2 + ")",
    maxWidth,
    backgroundColor: color,
    borderRadius: getRadius(),
  });

  return (
    <div className="progress-bar-wrapper">
      <div className={`${showNumber ? "progress-bar-number" : "progress-bar"}`}>
        <div className="progress-bar-progress" style={progressBarStyle()}></div>
        {showNumber && (
          <div className="percentage">
            {Math.round((value / maxValue) * 100)}
          </div>
        )}
      </div>
    </div>
  );
};

// class GradientProgressBar extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       maxWidth: 0,
//       value: this.props.value,
//     };
//   }

//   progressBarStyle() {
//     return {
//       backgroundImage:
//         "linear-gradient(" +
//         this.props.rotation +
//         "deg, " +
//         this.props.color +
//         ", " +
//         this.props.color2 +
//         ")",
//       maxWidth: this.state.maxWidth,
//       backgroundColor: this.props.color,
//       borderRadius: this.getRadius(),
//     };
//   }

//   componentDidMount() {
//     setTimeout(() => this.setState({ maxWidth: this.props.value + "%" }), 10);
//   }

//   componentDidUpdate(prevProps) {
//     if (this.state.value !== this.props.value) {
//       setTimeout(() => this.setState({ maxWidth: this.props.value + "%" }), 10);
//       this.setState({ value: this.props.value });
//     }
//   }

//   getRadius() {
//     return (
//       "10px " + (this.props.value >= 100 ? "10px 10px" : "10px 10px") + " 10px"
//     );
//   }

//   render() {
//     return (
//       <div className="progress-bar-wrapper">
//         <div
//           className={`${
//             this.props.showNumber ? "progress-bar-number" : "progress-bar"
//           }`}
//         >
//           <div
//             className="progress-bar-progress"
//             style={this.progressBarStyle()}
//           ></div>
//           {this.props.showNumber && (
//             <div className="percentage">
//               {Math.round((this.props.value / this.props.maxValue) * 100)}
//             </div>
//           )}
//         </div>
//       </div>
//     );
//   }
// }

export default GradientProgressBar;
