import { authHeader, handleResponse } from "../../helpers";
import * as global from "../../helpers/constants";
import { getCurrentOrganization } from "../../helpers/constants";

export const iac = {
  fetchIacScanReport,
  createIacProject,
  updateIacProject,
  deleteIacProject,
  fetchIacProjects,
  fetchIacRemediation,
};

function fetchIacScanReport(git_url) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/iac-scan?git_url=${git_url}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
      return result;
    });
}

function fetchIacRemediation(query_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/iac-remediation?query-id=${query_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
      return result;
    });
}

function createIacProject(payload) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      ...payload,
      organization_id: getCurrentOrganization(),
    }),
  };
  return fetch(`${global.getBaseURL()}/app/iac-project`, requestOptions)
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function updateIacProject(payload) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({ ...payload }),
  };
  return fetch(
    `${global.getBaseURL()}/app/iac-project/${payload?.id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchIacProjects() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/iac-project/${getCurrentOrganization()}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function deleteIacProject(payload) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({ ...payload }),
  };
  return fetch(
    `${global.getBaseURL()}/app/iac-project/${payload?.id}/delete`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}
