import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import AuthDashboard from "./AuthDashboard";
import styled from "styled-components";

import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import StyledTypo from "../../components/Typo/StyledTypo";
import CommonInput from "../../components/Input/CommonInput";
import EffectedButton from "../../components/Buttons/EffectedButton";
import { ReactComponent as LockIcon } from "../../assets/icons/lock_icon.svg";
import { MainContext } from "../../contexts";
import { getParamsStr, showAlert } from "../../helpers/constants";
import { authAction } from "../../redux/actions";

const LeftWrapper = styled.div`
  background: linear-gradient(359.21deg, #76eeb5 -20.98%, #58a3ee 100.74%);
  width: 426px;
  height: 100%;
  min-height: 715px;
  border-radius: 20px 0 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 47px 0 65px;
`;

const RightWrapper = styled.div`
  padding: 195px 111px 93px 203px;
  height: 100%;
  width: 652px;
`;

const ChangePassword = (props) => {
  const {
    changePasswordAction,
    clearChangePasswordFlag,
    change_password_flag,
  } = props;
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");

  const { setTrackHistory } = useContext(MainContext);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setEmail(
      location?.search ? new URLSearchParams(location?.search).get("email") : ""
    );
    setToken(
      location?.search ? new URLSearchParams(location?.search).get("token") : ""
    );
    setTrackHistory((trackHistory) => [
      {
        pathname: location.pathname,
        params: getParamsStr({
          email: location?.search
            ? new URLSearchParams(location?.search).get("email")
            : "",
          token: location?.search
            ? new URLSearchParams(location?.search).get("token")
            : "",
        }),
      },
      ...trackHistory,
    ]);
  }, []);

  useEffect(() => {
    if (change_password_flag) {
      clearChangePasswordFlag();
      history.push("/login");
    }
  }, [change_password_flag]);

  const handleSubmit = () => {
    if (pwd.length < 8) {
      showAlert(
        "warning",
        "Invalid password",
        "Password length must be 8 characters at least."
      );
    } else if (pwd !== confirmPwd) {
      showAlert(
        "warning",
        "Invalid password",
        "Confirm Password is not same as New Password."
      );
    } else {
      const data = {
        email,
        token,
        new_password: pwd,
      };
      changePasswordAction(data);
    }
  };

  return (
    <AuthDashboard>
      <div className="d-flex">
        <LeftWrapper>
          <div className="text-center">
            <Logo width={40} height={60} fill="#fff" />
            <StyledTypo
              className="mt-3"
              color="#fff"
              size={1.2}
              line={34.4 / 28}
              letter="0"
              family="Roboto"
              fontWeight={400}
            >
              MatosSphere
            </StyledTypo>
          </div>
          <div className="text-center">
            <StyledTypo
              color="#fff"
              size={1}
              line={1.4}
              letter="0"
              family="Roboto"
              fontWeight={400}
            >
              Secure your cloud from build to run time
            </StyledTypo>
            <div className="col-10 offset-1">
              <StyledTypo
                className="mt-3 pt-1"
                color="#fff"
                size={0.8}
                line={25.2 / 18}
                letter="0"
                family="Roboto"
                fontWeight={300}
              >
                CloudMatos continuously assess, detect, govern, and remediate
                misconfigurations and threats in your build, deploy and cloud
                (AWS, GCP, Azure and Kubernetes) environments
              </StyledTypo>
            </div>
          </div>
        </LeftWrapper>
        <RightWrapper>
          <StyledTypo
            className="mb-4"
            color="#505050"
            size={1}
            line={36.4 / 26}
            letter="0"
            family="Roboto"
            fontWeight={700}
          >
            Reset Password
          </StyledTypo>
          <div>
            <CommonInput
              label="Password"
              value={pwd}
              setValue={setPwd}
              active={false}
              locked={false}
              icon={LockIcon}
              placeholder="Enter your new password"
              inputType="password"
            />
          </div>
          <div>
            <CommonInput
              label="Confirm Password"
              value={confirmPwd}
              setValue={setConfirmPwd}
              active={false}
              locked={false}
              placeholder="Re-enter your new password"
              inputType="password"
            />
          </div>
          <div className="mt-4 pt-2">
            <EffectedButton width="100%" type="submit" onClick={handleSubmit}>
              <StyledTypo
                color="#fff"
                size={0.8}
                line={25.2 / 18}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                Reset
              </StyledTypo>
            </EffectedButton>
          </div>
        </RightWrapper>
      </div>
    </AuthDashboard>
  );
};

function mapStateToProps(state) {
  const { change_password_flag } = state.auth;
  return {
    change_password_flag,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePasswordAction: (data) =>
      dispatch(authAction.changePasswordAction(data)),
    clearChangePasswordFlag: () =>
      dispatch(authAction.clearChangePasswordFlag()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
