import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import AuthDashboard from "./AuthDashboard";
import styled from "styled-components";

import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import StyledTypo from "../../components/Typo/StyledTypo";
import CommonInput from "../../components/Input/CommonInput";
import EffectedButton from "../../components/Buttons/EffectedButton";
import { ReactComponent as BackArrowIcon } from "../../assets/icons/back_arrow_icon.svg";
import { MainContext } from "../../contexts";
import { commonTitle, emailRegex, getParamsStr, showAlert } from "../../helpers/constants";
import { authAction } from "../../redux/actions";
import { useTitle } from "../../helpers/useTtitle";

const LeftWrapper = styled.div`
  background: linear-gradient(359.21deg, #76eeb5 -20.98%, #58a3ee 100.74%);
  width: 426px;
  height: 100%;
  min-height: 715px;
  border-radius: 20px 0 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 47px 0 65px;
`;

const RightWrapper = styled.div`
  padding: 197px 111px 93px 203px;
  height: 100%;
  width: 652px;
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

const pageTitle = `${commonTitle} - Forgot Password`;

const ForgotPassword = (props) => {
  const {
    forgotPasswordRequestAction,
    clearForgotPasswordFlag,
    forgot_password_flag,
  } = props;
  const [email, setEmail] = useState("");
  const history = useHistory();
  const { setTrackHistory } = useContext(MainContext);
  const location = useLocation();

  useTitle(pageTitle)

  useEffect(() => {
    setTrackHistory((trackHistory) => [
      {
        pathname: location.pathname,
        params: getParamsStr({}),
      },
      ...trackHistory,
    ]);
  }, []);

  useEffect(() => {
    if (forgot_password_flag) {
      clearForgotPasswordFlag();
      history.push("/email-sent");
    }
  }, [forgot_password_flag]);

  const handleSubmit = () => {
    if (!email.length) {
      showAlert("warning", "Empty Email", "Please fill Email.");
    } else if (!emailRegex.test(email)) {
      showAlert("warning", "Invalid Email", "Please re-enter valid Email.");
    } else {
      const data = {
        email,
      };
      forgotPasswordRequestAction(data);
    }
  };

  return (
    <AuthDashboard>
      <div className="d-flex">
        <LeftWrapper>
          <div className="text-center">
            <Logo width={40} height={60} fill="#fff" />
            <StyledTypo
              className="mt-3"
              color="#fff"
              size={1.2}
              line={34.4 / 28}
              letter="0"
              family="Roboto"
              fontWeight={400}
            >
              MatosSphere
            </StyledTypo>
          </div>
          <div className="text-center">
            <StyledTypo
              color="#fff"
              size={1}
              line={1.4}
              letter="0"
              family="Roboto"
              fontWeight={400}
            >
              Secure your cloud from build to run time
            </StyledTypo>
            <div className="col-10 offset-1">
              <StyledTypo
                className="mt-3 pt-1"
                color="#fff"
                size={0.8}
                line={25.2 / 18}
                letter="0"
                family="Roboto"
                fontWeight={300}
              >
                CloudMatos continuously assess, detect, govern, and remediate
                misconfigurations and threats in your build, deploy and cloud
                (AWS, GCP, Azure and Kubernetes) environments
              </StyledTypo>
            </div>
          </div>
        </LeftWrapper>
        <RightWrapper>
          <StyledAnchor
            className="d-flex align-items-center mb-3 pb-1"
            onClick={() => history.goBack()}
          >
            <BackArrowIcon width={12} height={12} />
            <StyledTypo
              className="ml-2"
              color="#505050"
              size={0.8}
              line={23.44 / 20}
              letter="0"
              family="Roboto"
              fontWeight={300}
            >
              Back
            </StyledTypo>
          </StyledAnchor>
          <StyledTypo
            className="mb-4"
            color="#505050"
            size={1}
            line={36.4 / 26}
            letter="0"
            family="Roboto"
            fontWeight={400}
          >
            Forgot my Password
          </StyledTypo>
          <StyledTypo
            className="mb-4"
            color="#505050"
            size={0.8}
            line={21 / 14}
            letter="0"
            family="Poppins"
            fontWeight={300}
          >
            Please, enter the email address associated with your account so that
            we can send you instructions to reset your password.
          </StyledTypo>
          <div>
            <CommonInput
              label="Email"
              value={email}
              setValue={setEmail}
              active={false}
              locked={false}
              placeholder=""
            />
          </div>
          <div className="mt-4">
            <EffectedButton width="100%" type="submit" onClick={handleSubmit}>
              <StyledTypo
                color="#fff"
                size={0.8}
                line={25.2 / 18}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                Send
              </StyledTypo>
            </EffectedButton>
          </div>
        </RightWrapper>
      </div>
    </AuthDashboard>
  );
};

function mapStateToProps(state) {
  const { forgot_password_flag } = state.auth;
  return {
    forgot_password_flag,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    forgotPasswordRequestAction: (data) =>
      dispatch(authAction.forgotPasswordRequestAction(data)),
    clearForgotPasswordFlag: () =>
      dispatch(authAction.clearForgotPasswordFlag()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
