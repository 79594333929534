import React, { forwardRef } from "react";
import styled from "styled-components";
import IacReportTable from "./IacReportTable";

const PanelWrapper = styled.div`
  padding-top: 10px;
  width: 100%;

  // border: 1px solid #f1f5fa;
  box-sizing: border-box;
  // border-radius: 0 15px 15px;

  .panel-title-header {
    padding: 0 0 47px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const PanelBody = styled.div`
  padding: 0px 10px;
`;

const BuildTimePanel = forwardRef((props, ref) => {
  const queries = props?.report?.report?.queries;
  return (
    <PanelWrapper>
      <PanelBody>
        <IacReportTable queries={queries ?? []} {...props} ref={ref} />
      </PanelBody>
    </PanelWrapper>
  );
});

export default BuildTimePanel;
