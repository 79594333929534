/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import StyledTypo from "../../../../components/Typo/StyledTypo";
import styled from "styled-components";
import { ReactComponent as ArrowUp } from "../../../../assets/icons/up_arrow_icon.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/icons/down_arrow_icon.svg";
import ReactPaginate from "react-paginate";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filter_icon.svg";
import { ReactComponent as FilterClearIcon } from "../../../../assets/icons/filter_clear_icon.svg";
import { ReactComponent as UpArrow } from "../../../../assets/icons/arrow-angle-up-sm.svg";
import { ReactComponent as RightArrow } from "../../../../assets/icons/arrow-angle-right-sm.svg";
import StyledCheckbox from "../../../../components/Input/StyledCheckbox";
import CustomInput from "../../../../components/Input/CustomInput";
import CommonSelect from "../../../../components/Select/CommonSelect";
import {
  actionIacCategory,
  actionIacPlatform,
  actionSeverity,
  loadingBar,
} from "../../../../helpers/constants";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close-icon.svg";
import IaCDetails from "./IaCDetails";
import { TableWrapper } from "../../../../components/PageLayout/Table";
import {
  FilterWrapper,
  FilterBodyContainer,
} from "../../../../components/PageLayout/Filter";
import { Badge } from "reactstrap";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 26px;
`;

const EachCell = styled.div`
  width: ${(props) => props.width};
  padding-right: ${(props) => (props.pr ? props.pr : 0)}px;
  word-break: break-word;
`;

const RecordContainer = styled.div`
  border-top: 1px solid #fff;
  transition: border 500ms ease;
`;

const RecordWrapper = styled.div`
  padding: 11px 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .text-cell {
    color: #505050;
  }
  cursor: pointer;
  &:hover {
    border-radius: 5px;
    background-color: #f1f1f1;
  }
`;

const ShowingWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  position: fixed;
  right: 40px;
  bottom: 20px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100vw - 200px);
  background-color: #fff;

  .page-link:focus {
    box-shadow: none !important;
  }
  ul.pagination {
    margin-bottom: 0;
    font-size: 0.8rem;
    font-weight: 0.4;
    font-family: "Roboto";
  }
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const selectEmptyResourceType = {
  label: "Select...",
  value: "",
};

const BadgeWrapper = styled.div`
  width: 20px;
  height: 20px;
  background: #65c2d7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FilterPanel = (props) => {
  const {
    searchText,
    setSearchText,
    selectedCategory,
    setSelectedCategory,
    selectedSeverity,
    setSelectedSeverity,
    selectedPlatform,
    setSelectedPlatform,
    selectedFilterType,
    setSelectedFilterType,
  } = props;

  const [filterBarOpen, setFilterBarOpen] = useState(false);

  const clearFilter = () => {
    setSelectedPlatform(selectEmptyResourceType);
    setSelectedSeverity(selectEmptyResourceType);
    setSelectedCategory(selectEmptyResourceType);
    setFilterBarOpen(false);
  };
  useEffect(() => {
    if (selectedFilterType) {
      setSelectedFilterType(null);
      setFilterBarOpen(true);
    }
  }, [selectedFilterType, setSelectedFilterType]);

  const checkFilterContent = () => {
    return (
      !!selectedCategory?.value?.length ||
      !!selectedPlatform?.value?.length ||
      !!selectedSeverity?.value?.length
    );
  };

  return (
    <FilterWrapper left="640px">
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="d-flex align-items-center mr-3 filter-menu flex-wrap">
          <a
            className="menu-icon"
            onClick={() => setFilterBarOpen(!filterBarOpen)}
            title="Filter By"
            alt="Filter By"
            href="#"
          >
            <FilterIcon
              width={22}
              height={22}
              fill="#606060"
              className="svg-icon"
            />
            <span className="menu-tip">Filter by</span>
          </a>
          <a
            className={
              checkFilterContent() ? "menu-icon" : "menu-icon menu-disabled"
            }
            onClick={clearFilter}
            title="Filter Clear"
            alt="Filter Clear"
            href="#"
          >
            <FilterClearIcon
              width={22}
              height={22}
              fill="#606060"
              className="svg-icon"
            />
            <span className="menu-tip">Filter clear</span>
          </a>
        </div>
        <div>
          <CustomInput
            // minWidth={300}
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>
      {filterBarOpen && (
        <FilterBodyContainer>
          <CloseWrapper
            className="cursor-pointer"
            onClick={() => setFilterBarOpen(false)}
          >
            <CloseIcon width={15} stroke="#A4AFB7" />
          </CloseWrapper>
          <div className="ml-3" style={{ minWidth: 250 }}>
            <CommonSelect
              value={selectedCategory}
              setValue={setSelectedCategory}
              options={actionIacCategory}
              label={`Category`}
              isClear
            />
          </div>
          <div className="ml-3" style={{ minWidth: 250 }}>
            <CommonSelect
              value={selectedSeverity}
              setValue={setSelectedSeverity}
              options={actionSeverity}
              label={`Severity`}
              isClear
            />
          </div>
          <div style={{ minWidth: 250 }}>
            <CommonSelect
              value={selectedPlatform}
              setValue={setSelectedPlatform}
              options={[
                ...actionIacPlatform.map((item) => ({
                  ...item,
                  value: item?.codeValue,
                })),
              ]}
              label={`Platform`}
              isClear
            />
          </div>
        </FilterBodyContainer>
      )}
    </FilterWrapper>
  );
};

const TableHeader = (props) => {
  const { columns, currentSort, setCurrentSort, data, setData } = props;

  const handleSetSort = (access) => {
    setCurrentSort({
      access,
      order:
        (Array.isArray(currentSort.access)
          ? currentSort.access.join()
          : currentSort.access) ===
        (Array.isArray(access) ? access.join() : access)
          ? !currentSort.order
          : true,
    });
  };

  return (
    <HeaderWrapper>
      {columns &&
        columns.map((column) => (
          <EachCell
            width={column.width ?? "100%"}
            key={column.access}
            className={`d-flex align-items-center ${
              column.sort ? "cursor-pointer" : ""
            } ${column.title === "Action" && "justify-content-end"}`}
            pr={10}
            onClick={() => {
              if (column.sort) {
                handleSetSort(column.access);
              }
            }}
          >
            {column.type === "checkbox" && (
              <StyledCheckbox
                handleOnChange={() => {
                  const allChecked =
                    data?.filter((item) => item.checked)?.length ===
                    data.length;
                  setData((dataList) =>
                    dataList.map((item) => ({ ...item, checked: !allChecked }))
                  );
                }}
                className="mt-2"
                checked={
                  data?.filter((item) => item.checked).length === data.length
                }
              />
            )}
            {column.sort &&
            (Array.isArray(currentSort.access)
              ? currentSort.access.join()
              : currentSort.access) ===
              (Array.isArray(column.access)
                ? column.access.join()
                : column.access) ? (
              currentSort.order ? (
                <ArrowUp fill={"#A4AFB7"} className="mr-3" />
              ) : (
                <ArrowDown fill={"#A4AFB7"} className="mr-3" />
              )
            ) : (
              <div />
            )}
            <div
              className={
                column.title === "Action"
                  ? "ml-auto"
                  : column.type === "overview"
                  ? "ml-auto mr-auto"
                  : ""
              }
            >
              <StyledTypo
                color={"#505050"}
                size={0.8}
                line={1.2}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                {column.title}
              </StyledTypo>
            </div>
          </EachCell>
        ))}
    </HeaderWrapper>
  );
};

const Record = (props) => {
  const { data, columns, openItemId, setOpenItemId } = props;

  const handleSelectRow = () => {
    setOpenItemId((openItemId) => (openItemId ? null : data.query_id));
  };

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      handleKey(event.key);
    });
    return function () {
      document.removeEventListener("keydown", function (event) {
        console.log("removed listener");
      });
    };
  }, []);

  const handleKey = (key) => {
    if (key === "Escape" && openItemId) {
      setOpenItemId(null);
    }
  };

  return (
    <div>
      <RecordContainer active={openItemId === data.query_id}>
        <RecordWrapper active={openItemId === data.query_id}>
          {
            <>
              {columns.map((column) => (
                <EachCell width={column.width ?? "100%"} pr={10} className="iac">
                  {column.type === "arrow" && (
                    <div className="cursor-pointer" onClick={handleSelectRow}>
                      <RightArrow fill="#505050" />
                    </div>
                  )}
                  {column.type === 'severity' && (
                    <StyledTypo className="severity-text">
                      <Badge
                        color={
                           data?.severity?.toLowerCase() ?? "low"
                        }
                        pill
                      >
                        {data?.[column.access]?.toLowerCase()}
                      </Badge>
                    </StyledTypo>
                  )}
                  {column.type !== 'severity' && column.access && (
                    <StyledTypo className="text-cell" onClick={handleSelectRow}>
                      {Array.isArray(column.access)
                        ? column.access
                            .map((access) => data[access])
                            .join(" / ")
                        : column.access === "platform"
                        ? actionIacPlatform?.filter(
                            (item) =>
                              item.codeValue.toLowerCase() ===
                              data[column.access].toLowerCase()
                          )?.[0]?.label ?? data[column.access]
                        : data[column.access]}
                    </StyledTypo>
                  )}
                  {column.issue_counter && (
                    <StyledTypo
                      onClick={handleSelectRow}
                      lineHeight={1}
                      className="table-label"
                    >
                      {data.files.length}
                    </StyledTypo>
                  )}
                </EachCell>
              ))}
            </>
          }
        </RecordWrapper>
      </RecordContainer>
    </div>
  );
};

const IacReportTable = forwardRef((props, ref) => {
  const {
    queries,
    fetchIacRemediationAction,
    iac_remediation,
    preFilterData,
    setRenderFlag,
  } = props;

  const columns = [
    {
      title: "Name",
      access: "query_name",
      width: `${29000 / 680}%`,
      sort: true,
      search: true,
    },
    {
      title: "Category",
      access: "category",
      width: `${20000 / 680}%`,
      sort: true,
      search: true,
    },
    {
      title: "Severity",
      access: "severity",
      width: `${8000 / 680}%`,
      sort: false,
      search: false,
      type: 'severity'
    },
    {
      title: "Platform",
      access: "platform",
      width: `${9000 / 680}%`,
      sort: false,
      search: true,
    },
    {
      title: "Result",
      width: `${1000 / 680}`,
      sort: false,
      search: false,
      issue_counter: true,
    },
  ];

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({ access: "", order: true });
  const [filters, setFilters] = useState(
    (columns ?? []).filter((item) => item.search)
  );
  const [searchText, setSearchText] = useState(null);

  const [selectedPlatform, setSelectedPlatform] = useState(
    selectEmptyResourceType
  );
  const [selectedCategory, setSelectedCategory] = useState(
    selectEmptyResourceType
  );
  const [selectedSeverity, setSelectedSeverity] = useState(
    selectEmptyResourceType
  );
  const [openItemId, setOpenItemId] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const itemPerPage = 15;
  const handleSort = () => {
    const retOrder = currentSort.order ? 1 : -1;
    setData((data) => [
      ...data.sort((a, b) => {
        const aCompare = Array.isArray(currentSort.access)
          ? currentSort.access.map((access) => a[access]).join(" ")
          : a[currentSort.access];
        const bCompare = Array.isArray(currentSort.access)
          ? currentSort.access.map((access) => b[access]).join(" ")
          : b[currentSort.access];
        return aCompare > bCompare ? retOrder : -1 * retOrder;
      }),
    ]);
  };

  useEffect(() => {
    setCurrentSort((currentSort) =>
      currentSort.access.length === 0
        ? {
            access: columns[0].access,
            order: true,
          }
        : { ...currentSort }
    );
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (preFilterData?.type === "severity") {
      setSelectedSeverity({
        label: preFilterData?.data,
        value: preFilterData?.data.toLowerCase(),
      });
    }
    if (preFilterData?.type === "category") {
      setSelectedCategory({
        label: preFilterData?.data,
        value: preFilterData?.data,
      });
    }
    if (preFilterData?.type === "platform") {
      setSelectedPlatform({
        label: preFilterData?.data,
        value: preFilterData?.data,
      });
    }
  }, [preFilterData]);

  useEffect(() => {
    setData([...queries]);
  }, [queries]);

  useEffect(() => {
    handleSort();
  }, [currentSort]);

  const filteredData = useCallback(() => {
    let filteredData = [...data];
    if (searchText && searchText.length) {
      filteredData = [
        ...(filteredData ?? []).filter(
          (item) =>
            !!(filters ?? []).filter(
              (col) =>
                col.search &&
                item[col.access]
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
            ).length
        ),
      ];
    }

    if (selectedPlatform && selectedPlatform.value.length) {
      filteredData = [
        ...(filteredData ?? []).filter(
          (item) =>
            item.platform.toLowerCase() === selectedPlatform.value.toLowerCase()
        ),
      ];
    }
    if (selectedCategory && selectedCategory.value.length) {
      filteredData = [
        ...(filteredData ?? []).filter(
          (item) =>
            item.category.toLowerCase() === selectedCategory.value.toLowerCase()
        ),
      ];
    }
    if (selectedSeverity && selectedSeverity.value.length) {
      filteredData = [
        ...(filteredData ?? []).filter(
          (item) =>
            item.severity.toLowerCase() === selectedSeverity.value.toLowerCase()
        ),
      ];
    }
    if (
      currentPage !== 1 &&
      currentPage > filteredData.length / itemPerPage + 1
    ) {
      setCurrentPage(1);
    }
    return filteredData;
  }, [
    data,
    searchText,
    filters,
    currentPage,
    showAll,
    selectedPlatform,
    selectedCategory,
    selectedSeverity,
  ]);

  useEffect(() => {
    setRenderFlag((renderFlag) => !renderFlag);
  }, [
    data,
    searchText,
    filters,
    currentPage,
    showAll,
    selectedPlatform,
    selectedCategory,
    selectedSeverity,
  ]);

  useEffect(() => {
    if (openItemId && !iac_remediation?.[openItemId]) {
      fetchIacRemediationAction(openItemId);
    }
  }, [openItemId]);

  const pageFilter = (data) => {
    return (data ?? []).filter(
      (item, idx) =>
        idx >= itemPerPage * (currentPage - 1) &&
        idx < itemPerPage * currentPage
    );
  };

  const handlePageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };

  useImperativeHandle(ref, () => ({
    getFilterPanel: () => (
      <FilterPanel
        columns={columns}
        filters={filters}
        setFilters={setFilters}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedSeverity={selectedSeverity}
        setSelectedSeverity={setSelectedSeverity}
        selectedPlatform={selectedPlatform}
        setSelectedPlatform={setSelectedPlatform}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        showAll={showAll}
        setShowAll={setShowAll}
      />
    ),
  }));

  return (
    <TableWrapper marginTop="10px">
      <TableHeader
        columns={columns}
        currentSort={currentSort}
        setCurrentSort={setCurrentSort}
        data={filteredData()}
        setData={setData}
      />
      <div className="divider" />
      {!!queries?.length ? (
        filteredData().length ? (
          pageFilter(filteredData()).map((remediation) => (
            <Record
              data={remediation}
              columns={columns}
              setData={setData}
              openItemId={openItemId}
              force={currentPage - 1}
              setOpenItemId={setOpenItemId}
            />
          ))
        ) : (
          <StyledTypo
            className="text-center py-5"
            color={"#505050"}
            size={0.8}
            line={26.15 / 16}
            letter="0"
            family="Poppins"
            fontWeight={400}
          >
            No Queries
          </StyledTypo>
        )
      ) : (
        <div className="d-flex justify-content-center py-5 align-items-center">
          {loadingBar("Rings", 50, 50)}
          <StyledTypo
            className="pl-2"
            color={"#505050"}
            size={0.8}
            line={26.15 / 16}
            letter="0"
            family="Poppins"
            fontWeight={400}
          >
            Loading...
          </StyledTypo>
        </div>
      )}
      <ShowingWrapper>
        <StyledTypo
          color={"#505050"}
          size={0.8}
          line={26.15 / 16}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {`Showing ${
            itemPerPage * (currentPage - 1) + 1 > filteredData().length
              ? filteredData().length
              : itemPerPage * (currentPage - 1) + 1
          } - ${
            itemPerPage * currentPage > filteredData().length
              ? filteredData().length
              : itemPerPage * currentPage
          } from ${filteredData().length} Results`}
        </StyledTypo>
        <ReactPaginate
          pageCount={(filteredData()?.length ?? 0) / itemPerPage}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          previousLabel="< Prev"
          nextLabel="Next >"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          forcePage={currentPage - 1}
          onPageChange={handlePageChange}
          activeClassName="active"
        />
      </ShowingWrapper>
      <IaCDetails
        data={filteredData()}
        isOpen={!!openItemId}
        openItemId={openItemId}
        toggle={() =>
          setOpenItemId((openItemId) => (openItemId ? null : openItemId))
        }
        setOpenItemId={setOpenItemId}
        compliant={openItemId ? iac_remediation?.[openItemId]?.compliant : ""}
        non_compliant={
          openItemId ? iac_remediation?.[openItemId]?.non_compliant : ""
        }
        positiveLineNumbers={
          openItemId ? iac_remediation?.[openItemId]?.positive_line_numbers : ""
        }
      />
    </TableWrapper>
  );
});

export default IacReportTable;
