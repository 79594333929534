export const dashboardConstants = {
    FETCH_DASHBOARD_CLOUD_SECURITY_REQUEST: 'FETCH_DASHBOARD_CLOUD_SECURITY_REQUEST',
    FETCH_DASHBOARD_CLOUD_SECURITY_SUCCESS: 'FETCH_DASHBOARD_CLOUD_SECURITY_SUCCESS',
    FETCH_DASHBOARD_CLOUD_SECURITY_FAILURE: 'FETCH_DASHBOARD_CLOUD_SECURITY_FAILURE',

    FETCH_DASHBOARD_CODE_SECURITY_REQUEST: 'FETCH_DASHBOARD_CODE_SECURITY_REQUEST',
    FETCH_DASHBOARD_CODE_SECURITY_SUCCESS: 'FETCH_DASHBOARD_CODE_SECURITY_SUCCESS',
    FETCH_DASHBOARD_CODE_SECURITY_FAILURE: 'FETCH_DASHBOARD_CODE_SECURITY_FAILURE',

    FETCH_COMPLIANCE_INFRALIST_REQUEST: 'FETCH_COMPLIANCE_INFRALIST_REQUEST',
    FETCH_COMPLIANCE_INFRALIST_SUCCESS: 'FETCH_COMPLIANCE_INFRALIST_SUCCESS',
    FETCH_COMPLIANCE_INFRALIST_FAILURE: 'FETCH_COMPLIANCE_INFRALIST_FAILURE',

    FETCH_RESOURCE_INFRALIST_REQUEST: 'FETCH_RESOURCE_INFRALIST_REQUEST',
    FETCH_RESOURCE_INFRALIST_SUCCESS: 'FETCH_RESOURCE_INFRALIST_SUCCESS',
    FETCH_RESOURCE_INFRALIST_FAILURE: 'FETCH_RESOURCE_INFRALIST_FAILURE',
}