import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import StyledTypo from "../../../components/Typo/StyledTypo";
import { ReactComponent as ProfileEmailIcon } from "../../../assets/icons/profile_email_icon.svg";
import { ReactComponent as ProfilePhoneIcon } from "../../../assets/icons/profile_phone_icon.svg";
import { ReactComponent as ProfileBagIcon } from "../../../assets/icons/profile_bag_icon.svg";
import { ReactComponent as UserEditIcon } from "../../../assets/icons/user_edit_icon.svg";
import { ReactComponent as ImpersonateIcon } from "../../../assets/icons/impersonate_icon.svg";
import { ReactComponent as PermissionIcon } from "../../../assets/icons/permission_icon.svg";

import ProfileImage from "../../../assets/images/profile_img.png";
import EffectedButton from "../../../components/Buttons/EffectedButton";
import ProfileOrganizationTable from "./ProfileOrganizationTable";
import DropdownIcon from "../../../components/Buttons/DropdownIcon";
import StyledDropdown from "../../../components/Buttons/StyledDropdown";
import ChangePasswordPanel from "../../modals/ChangePasswordPanel";
import { authAction } from "../../../redux/actions";
import { getCurrentUser } from "../../../helpers/constants";

const UserProfileContainer = styled.div`
  width: 1056px;
  min-height: 775px;
  padding: 41px 40px 61px;

  background: #ffffff;
  border: 1px solid #66c4d5;
  box-sizing: border-box;
  border-radius: 0 10px 10px 10px;
  //margin-left: 13px;
`;

const ProfileDetailContainer = styled.div`
  padding: 0 31px 0 26px;
`;

const ProfileIconWrapper = styled.div`
  width: 28px;
  height: 28px;
  //background: ${(props) => (props.color ? props.color : "#2D9CDB33")};
  background: ${(props) => (props.color ? props.color : "#2D9CDB")}33;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

const UserProfile = (props) => {
  const {
    users,
    organizations,
    changePasswordAction,
    clearChangePasswordFlag,
    change_password_flag,
  } = props;
  const [userId, setUserId] = useState("");
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setSelectedUser(getCurrentUser());
  }, []);

  // useEffect(() => {
  //   if (Object.keys(selectedUser).length) {
  //     setSelectedOrganizations([...organizations.filter(org => org.id === selectedUser.organization_id)])
  //   }
  // }, [selectedUser, organizations])

  useEffect(() => {
    if (change_password_flag) {
      setChangePasswordModal(false);
      clearChangePasswordFlag();
    }
  }, [change_password_flag]);

  return (
    <UserProfileContainer>
      <ProfileDetailContainer>
        <div className="title mb-4 pb-2">
          <StyledTypo
            color="#50505088"
            size={1.625}
            line={36.4 / 26}
            letter="0"
            family="Roboto"
            fontWeight={700}
          >
            User Profile
          </StyledTypo>
        </div>
        <div className="profile-pic d-flex mb-4 pb-2">
          <img src={ProfileImage} alt="user photo" />
          <div className="ml-3 pl-3 mt-2 pt-1" style={{ width: "100%" }}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <StyledTypo
                  color="#464255"
                  size={2.25}
                  line={50.4 / 36}
                  letter="0"
                  family="Roboto"
                  fontWeight={700}
                >
                  {`${selectedUser?.first_name ?? "Eren"} ${
                    selectedUser?.last_name ?? "Yeager"
                  }`}
                </StyledTypo>
                <StyledTypo
                  color="#00B074"
                  size={1}
                  line={33.6 / 24}
                  letter="0"
                  family="Roboto"
                  fontWeight={700}
                >
                  {selectedUser?.role?.toUpperCase()}
                </StyledTypo>
              </div>
              <div className="text-right">
                <EffectedButton
                  onClick={() => history.push(`/manage/add-user?id=${userId}`)}
                >
                  <UserEditIcon fill="#fff" />
                </EffectedButton>
                <div className="d-flex justify-content-end align-items-center mt-2">
                  <div className="d-flex align-items-center cursor-pointer">
                    <ImpersonateIcon />
                    <StyledTypo
                      className="ml-2"
                      color="#505050"
                      size={1.125}
                      line={25.2 / 18}
                      letter="0"
                      family="Roboto"
                      fontWeight={700}
                    >
                      Impersonate
                    </StyledTypo>
                  </div>
                  <StyledDropdown
                    isOpen={changePasswordModal}
                    toggle={() =>
                      setChangePasswordModal(
                        (changePasswordModal) => !changePasswordModal
                      )
                    }
                  >
                    <DropdownIcon>
                      <div className="d-flex align-items-center ml-4 cursor-pointer">
                        <PermissionIcon fill="#485DCD" />
                        <StyledTypo
                          className="ml-2"
                          color="#505050"
                          size={1.125}
                          line={25.2 / 18}
                          letter="0"
                          family="Roboto"
                          fontWeight={700}
                        >
                          Change Password
                        </StyledTypo>
                      </div>
                    </DropdownIcon>
                    {changePasswordModal && (
                      <ChangePasswordPanel
                        changePasswordAction={changePasswordAction}
                        toggle={() =>
                          setChangePasswordModal(
                            (changePasswordModal) => !changePasswordModal
                          )
                        }
                      />
                    )}
                  </StyledDropdown>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <StyledTypo
                color="#A3A3A3"
                size={0.875}
                line={23 / 14}
                letter="0"
                family="Poppins"
                fontWeight={400}
              >
                St. Kings Road 57th, Garden Hills, Chelsea - London
              </StyledTypo>
            </div>
            <div className="d-flex justify-content-between mt-4 pt-2">
              <div className="d-flex align-items-center">
                <ProfileIconWrapper>
                  <ProfileEmailIcon />
                </ProfileIconWrapper>
                <StyledTypo
                  color="#464255"
                  size={1}
                  line={24 / 16}
                  letter="0"
                  family="Poppins"
                  fontWeight={400}
                >
                  {selectedUser?.email ?? "empty"}
                </StyledTypo>
              </div>
              <div className="d-flex align-items-center">
                <ProfileIconWrapper color="#00B074">
                  <ProfilePhoneIcon />
                </ProfileIconWrapper>
                <StyledTypo
                  color="#464255"
                  size={1}
                  line={24 / 16}
                  letter="0"
                  family="Poppins"
                  fontWeight={400}
                >
                  {selectedUser?.phone ?? "empty"}
                </StyledTypo>
              </div>
              <div className="d-flex align-items-center">
                <ProfileIconWrapper color="#FF5B5B">
                  <ProfileBagIcon />
                </ProfileIconWrapper>
                <StyledTypo
                  color="#464255"
                  size={1}
                  line={24 / 16}
                  letter="0"
                  family="Poppins"
                  fontWeight={400}
                >
                  {selectedUser?.skill ?? "empty"}
                </StyledTypo>
              </div>
            </div>
          </div>
        </div>
      </ProfileDetailContainer>
      {/*<ProfileOrganizationTable data={selectedOrganizations}/>*/}
    </UserProfileContainer>
  );
};

function mapStateToProps(state) {
  const { change_password_flag } = state.auth;
  return {
    change_password_flag,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePasswordAction: (data) =>
      dispatch(authAction.changePasswordAction(data)),
    clearChangePasswordFlag: () =>
      dispatch(authAction.clearChangePasswordFlag()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
