import React, { useEffect, useState, useCallback } from "react";
import StyledTypo from "../../../components/Typo/StyledTypo";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../../assets/icons/cancel.svg";
import "react-modern-drawer/dist/index.css";
import { Col, Row } from "reactstrap";
import SlidePanel from "../../../components/SlidePanel/SlidePanel";
import AttackPathsDetail from "./details/AttackPathsDetail";
import { ReactComponent as LeftArrow } from '../../../assets/icons/left-arrow.svg'
import { ReactComponent as RightArrow } from '../../../assets/icons/right-arrow.svg'
import { Badge } from "reactstrap";

const DividerWrapper = styled.div`
  height: 0;
  width: calc(100% + 60px);
  margin-left: -30px;
  border-bottom: 0.5px solid #ccc;
`;

const CloseWrapper = styled.div`
  border-radius: 10px;
  min-width: 45px;
  min-height: 40px;
  cursor: pointer;
  max-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TitleWrapper = styled.span`
  display: inline-block;
  width: 58%;
  height: 1.4em;
`;

const MoveCTAWrapper = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  // align-items: center;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  cursor: pointer;
`

const SeverityWrapper = styled.span`
  position: absolute;
  left: calc(100% - 240px);
`


const DetailPanel = (props) => {
  const {
    data,
    isOpen,
    toggle,
    total,
    setData
  } = props;

  const findIndex = () => {
    return total?.findIndex(item => item?.app_id === data?.data?.app_id)
  }

  const findId = (up = true) => {
    const idx = findIndex()
    const targetIdx = up ? idx + 1 : idx - 1
    return total?.filter((_, idx) => idx === targetIdx)?.[0]?.app_id
  }
 
  return (
    <SlidePanel isOpen={isOpen} toggle={toggle} slideId="apag-detail-slide">
      <div className="p-3 position-relative mb-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <CloseWrapper className="cursor-pointer" onClick={toggle}>
            <CloseIcon width={22} fill="#505050" />
          </CloseWrapper>
          <SeverityWrapper>
            <Badge
              color={data?.data?.severity?.toLowerCase() ?? "low"}
              pill
            >
              {data?.data?.severity}
            </Badge>
          </SeverityWrapper>
          <TitleWrapper>
            <StyledTypo className="text-center heading ln-2">
              {data?.data?.name}
            </StyledTypo>
          </TitleWrapper>
          
          <div className="d-flex align-items-center">
            <StyledTypo className="mr-2" size="0.8">
              {findIndex() + 1} of {total?.length}
            </StyledTypo>
            <MoveCTAWrapper
              disabled={findIndex() === 0}
              onClick={() => setData({data: total?.filter(item => item?.app_id === findId(false))?.[0], type: data?.type})}
            >
              <LeftArrow fill={findIndex() === 0 ? "#A4AFB7" : "#505050"} />
            </MoveCTAWrapper>
            <MoveCTAWrapper
              disabled={findIndex() + 1 === total?.length}
              onClick={() => setData({data: total?.filter(item => item?.app_id === findId(true))?.[0], type: data?.type})}
            >
              <RightArrow
                fill={findIndex() + 1 === total?.length ? "#A4AFB7" : "#505050"}
              />
            </MoveCTAWrapper>
          </div>
        </div>
        <DividerWrapper className="mb-3" />
        {/* <GraphWrapper> */}
          {
            data?.type === 'attack_paths' && <AttackPathsDetail data={data.data} />
          }
        {/* </GraphWrapper> */}

      </div>
    </SlidePanel>
  );
};

export default DetailPanel;
