import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import StyledTypo from "../../../components/Typo/StyledTypo";
import { GradientCircularProgress } from "react-circular-gradient-progress";
import GradientProgressBar from "../../../components/GradientProgressBar/GradientProgressBar";
import {
  providerComplianceType,
  dashboardOverviewList,
  NonComplianceStatusColor,
  providerResourceTypes,
  loadingBar,
  aggregatedData,
  commonTitle,
  cspData,
  topRisksbyCategoryTabs,
  dummyPriorIssuesData,
  priorityColumns,
  securityTabs,
  dummyInfraPreviewData,
} from "../../../helpers/constants";
import { ReactComponent as AssetsMenuIcon } from "../../../assets/icons/assets-menu-icon.svg";
import { ReactComponent as CloudSecurityIcon } from "../../../assets/icons/cloud-security.svg";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { dashboardActions, applicationActions } from "../../../redux/actions";
import { connect } from "react-redux";
import { useTitle } from "../../../helpers/useTtitle";
import CommonTable from "./CommonTable";
import ComplianceProviderTab from "./compliance/ComplianceProviderTab";


const PanelWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  box-sizing: border-box;
  padding: 0 30px;

  .top-section {
    padding-top: 15px;
    height: 50%;
  }

  .bottom-section {
    padding-top: 15px;
    height: 50%;
  }

  .panel-title-header {
    padding: 0 0 47px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .CircularProgress {
    position: relative;
  }

  #cpg-main {
    top: 12px;
  }
`;

const TabPanelBodyWrapper = styled.div`
  width: calc(100vw - 195px);
  display: grid;
  grid-gap: 10px;
  grid-template-rows: ${props => [...Array(props.rows).keys()].map(each => '1fr').join(' ')};
  height: calc(100vh - 110px);
  padding: 10px 30px;
`;

const StatsTabWrapper = styled.div`
  padding: 10px 0px;
`;

const CompliancePanel = (props) => {
  return (
    <PanelWrapper>
      <StatsTabWrapper>
        <Tabs>
          <TabList>
            {cspData.map((item, idx) => (
              <Tab key={`${item.key}-tab-header`}>
                <StyledTypo
                  line="1.8"
                  size={0.8}
                  family="Poppins"
                  fontWeight={300}
                >
                  {item.title}
                </StyledTypo>
              </Tab>
            ))}
          </TabList>
          {cspData.map((item, idx) => (
            <TabPanel>
              <TabPanelBodyWrapper key={`${item.key}-tab-body`} rows={dummyInfraPreviewData?.[item.key]?.length}>
                <ComplianceProviderTab csp={item.key} />
              </TabPanelBodyWrapper>
            </TabPanel>
          ))}
        </Tabs>
      </StatsTabWrapper>
    </PanelWrapper>
  );
};

// function mapStateToProps(state) {
//   const { loading_summary, compliance_infralist, resource_infralist } =
//     state.dashboard;
//   const { compliance_trend } = state.applications

//   return {
//     loading_summary,
//     compliance_infralist,
//     resource_infralist,
//     compliance_trend,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     fetchComplianceInfrastructureListAction: (provider, compliance) =>
//       dispatch(
//         dashboardActions.fetchComplianceInfrastructureListAction(
//           provider,
//           compliance
//         )
//       ),
//     fetchResourceInfrastructureListAction: (provider, resource) =>
//       dispatch(
//         dashboardActions.fetchResourceInfrastructureListAction(
//           provider,
//           resource
//         )
//       ),
//     fetchTrendDataAction: (data) => dispatch(applicationActions.fetchTrendDataAction(data)),
//   };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(TopPriorityPanel);
export default connect(undefined, undefined)(CompliancePanel);
