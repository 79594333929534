import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import StyledTypo from "../../components/Typo/StyledTypo";
import { ReactComponent as GitUrlIcon } from "../../assets/icons/git_link_icon.svg";
import CommonInput from "../../components/Input/CommonInput";
import moment from "moment";
import EffectedButton from "../../components/Buttons/EffectedButton";
import { DropdownMenu } from "reactstrap";
import {
  applicationInputOption,
  convertBase64,
  showAlert,
} from "../../helpers/constants";
import CommonSelect from "../../components/Select/CommonSelect";
import StyledFile from "../../components/Input/StyledFile";
import AWSCredPartial from "./applicationPanel/AWSCredPartial";
import GCPCredPartial from "./applicationPanel/GCPCredPartial";
import AzureCredPartial from "./applicationPanel/AzureCredPartial";

const MainContainer = styled(DropdownMenu)`
  width: 480px;
  padding: 33px 41px;

  background: #ffffff;
  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  //box-shadow: 0 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 15px;
  // border-radius: ${(props) =>
    props.directly ? "20px 0 20px 20px" : "20px"};
  transform: ${(props) =>
    props.directly
      ? "translate3d(-228px, 35px, 0px)"
      : "translate3d(-360px, 45px, 0px)"} !important;
  box-shadow: 0 0 0 9999px #00000066;
`;


const AddApplicationPanel = (props) => {
  const {
    addNewApplicationAction,
    selectedEnv,
    directly,
    envData,
    addAppStatus,
    selectedCsp,
  } = props;

  const emptyOption = { value: -1, label: "None" };
  const defaultEnvOption = { value: "default", label: "Default Environment" };

  const [name, setName] = useState("");
  const [gitUrl, setGitUrl] = useState("");
  const [yamlFileName, setYamlFileName] = useState("");
  const [sshKey, setSSHKey] = useState("");
  const [inputOption, setInputOption] = useState({ ...emptyOption });

  const [fileContent, setFileContent] = useState(null);
  const [fileName, setFileName] = useState("");
  const [environment, setEnvironment] = useState({ ...defaultEnvOption });
  const [credValue, setCredValue] = useState({});

  const options = [
    { ...emptyOption },
    ...applicationInputOption.map((item, idx) => ({ value: idx, label: item })),
  ];
  const envOptions = [
    { ...defaultEnvOption },
    ...(envData
      ? envData?.environments
          ?.filter((item) => item.is_active && item.key !== "default")
          .map((item) => ({ value: item.key, label: item.name }))
      : []),
  ];

  const clearValue = () => {
    setName("");
    setGitUrl("");
    setYamlFileName("");
    setSSHKey("");
    setInputOption({ ...emptyOption });
    setFileContent(null);
    setFileName("");
    setEnvironment({ ...defaultEnvOption });
    setCredValue({});
  };

  const handleModeChange = (selectedOption) => {
    setInputOption(selectedOption);
    setFileContent(null);
    setFileName("");
    if (document.getElementById("hiddenAvatarInputField")) {
      document.getElementById("hiddenAvatarInputField").value = "";
    }
  };

  useEffect(() => {
    setEnvironment(
      envOptions.filter((op) => op.value === selectedEnv.key)?.[0]
    );
  }, [selectedEnv]);

  useEffect(() => {
    if (addAppStatus === 1) {
      // setDefaultCsp()
      props.toggle();
    }
  }, [addAppStatus]);

  const handleUploadFile = (filename, content) => {
    setFileName(filename);
    setFileContent(content);
  };

  const handleKeyChange = (filename, content) => {
    setSSHKey(convertBase64(content));
  };

  const validateInput = () => {
    let msg = "";
    const sshKeyValid =
      /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
    if (!name.length) {
      msg = "Please fill new infrastructure name.";
      // } else if(inputOption.value !== 0 && !fileContent) {
      //   msg = "Please input valid file. Empty file."
    } else if (inputOption.value === -1) {
      msg = "";
    } else if (
      inputOption.value === 0 &&
      gitUrl.startsWith("ssh@") &&
      !sshKey.length
    ) {
      msg = "Please input valid SSH Key. Empty key.";
    } else if (
      inputOption.value === 0 &&
      gitUrl.startsWith("ssh@") &&
      !sshKeyValid.test(sshKey)
    ) {
      msg =
        "Please input valid SSH Key. SSH Key format needs to be encoded by Base64.";
    } else if (inputOption.value === 0 && !yamlFileName.length) {
      msg = "Please input valid YAML file name.";
    }
    if (msg.length) {
      showAlert("warning", "Invalid input", msg);
      return false;
    }
    return true;
  };

  const submitNewApplication = () => {
    if (!validateInput()) return;
    let data = {
      name,
      git_url: inputOption.value === 0 ? gitUrl : null,
      file_name:
        inputOption.value === 0
          ? yamlFileName
          : inputOption.value === -1
          ? null
          : fileName,
      git_key: inputOption.value === 0 ? sshKey : null,
      content: inputOption.value > 0 ? fileContent : null,
      createdDate: moment().format("YYYY-MM-DD hh:mm"),
      environment: environment.value ?? "default",
      platform: selectedCsp.key,
    };
    if (credValue?.credential) {
      data["credential"] = credValue?.credential;
    }

    addNewApplicationAction(data);
  };

  return (
    <MainContainer directly={directly}>
      <div className="title mb-2">
        <StyledTypo
          color="#A4AFB7"
          size={1.125}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={700}
        >
          Add an Infrastructure
        </StyledTypo>
      </div>
      <div className="sub-title mb-4">
        <StyledTypo
          color="#A4AFB7"
          size={1}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={700}
        >
          You are creating a new Infrastructure
        </StyledTypo>
      </div>
      <CommonSelect
        value={inputOption}
        setValue={setInputOption}
        options={options}
        label="Input Option"
        onChange={handleModeChange}
      />
      <div>
        <CommonInput
          label="Infrastructure Name"
          value={name}
          setValue={setName}
          active={false}
          locked={false}
          placeholder=""
        />
      </div>
      <CommonSelect
        value={environment}
        setValue={setEnvironment}
        options={envOptions}
        label="Environment"
      />
      {inputOption.value === 0 ? (
        <>
          <div>
            <CommonInput
              label="Git Repository Link"
              value={gitUrl}
              setValue={setGitUrl}
              active={false}
              locked={false}
              icon={GitUrlIcon}
              placeholder=""
            />
          </div>
          <div className="d-flex">
            <div style={{ width: "80%" }}>
              <CommonInput
                label="SSH Key"
                value={sshKey}
                setValue={setSSHKey}
                active={false}
                locked={false}
                placeholder=""
              />
            </div>
            <StyledFile
              hiddenId="hiddenKeyInputField"
              onChange={handleKeyChange}
            />
          </div>
          <div>
            <CommonInput
              label="YAML File Name"
              value={yamlFileName}
              setValue={setYamlFileName}
              active={false}
              locked={false}
              placeholder=""
            />
          </div>
        </>
      ) : inputOption.value === -1 ? (
        <Fragment>
          {selectedCsp?.key === "aws" && (
            <AWSCredPartial credValue={credValue} setCredValue={setCredValue} />
          )}
          {selectedCsp?.key === "gcp" && (
            <GCPCredPartial credValue={credValue} setCredValue={setCredValue} />
          )}
          {selectedCsp?.key === "azure" && (
            <AzureCredPartial
              credValue={credValue}
              setCredValue={setCredValue}
            />
          )}
        </Fragment>
      ) : (
        <StyledFile
          // label="Upload a file"
          extensions={
            inputOption?.value === 1
              ? ["yml", "yaml"]
              : inputOption?.value === 2
              ? ["json"]
              : []
          }
          isShowName
          className="mt-4"
          onChange={handleUploadFile}
          hiddenId="hiddenAppInputField"
        />
      )}
      <div className="d-flex justify-content-between align-items-center mt-4">
        <StyledTypo
          color="#505050"
          size={1}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={400}
        >
          Create Date
        </StyledTypo>
        <StyledTypo
          color="#505050"
          size={1}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {moment().format("MM-DD-YYYY hh:mm")}
        </StyledTypo>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-5">
        <div>
          <EffectedButton marginLeft="0" onClick={submitNewApplication}>
            <StyledTypo
              color="#fff"
              size={1.125}
              line={25.2 / 18}
              letter="0"
              family="Roboto"
              fontWeight={500}
            >
              Create
            </StyledTypo>
          </EffectedButton>
        </div>
        <div>
          <EffectedButton
            marginLeft="0"
            background="#485DCD"
            onClick={() => {
              clearValue();
              props.toggle();
            }}
          >
            <StyledTypo
              color="#fff"
              size={1.125}
              line={25.2 / 18}
              letter="0"
              family="Roboto"
              fontWeight={500}
            >
              Cancel
            </StyledTypo>
          </EffectedButton>
        </div>
      </div>
    </MainContainer>
  );
};

export default AddApplicationPanel;
