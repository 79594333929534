import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import StyledTypo from "../../../components/Typo/StyledTypo";
import CustomTextarea from "../../../components/Input/CustomTextarea";
import {tfCredCategory} from "../../../helpers/constants";
import StyledCheckbox from "../../../components/Input/StyledCheckbox";

const BorderWrapper = styled.div`
  border: 0.5px solid #ccc;
  padding: 10px 15px;
  border-radius: 10px;
  position: relative;
  margin-top: 30px;
  
  .title {
    padding: 0 5px;
    position: absolute;
    left: 15px;
    top: -10px;
    background-color: #fff;
  }
`

const AWSCredPartial = (props) => {
  const {credValue, setCredValue, disabled} = props

  const [checkValue, setCheckValue] = useState(false)

  const handleCredChange = (parentKey, key, val) => {
    if(checkValue) {
      setCredValue(credValue => ({...credValue, backend: {...(credValue['backend'] ?? {}), [key]: val}}))
    }
    setCredValue(credValue => ({...credValue, [parentKey]: {...(credValue[parentKey] ?? {}), [key]: val}}))
  }

  const handleCheck = () => {
    setCheckValue(checkValue => !checkValue)
  }

  useEffect(() => {
    if (checkValue) {
      setCredValue(credValue => ({...credValue,
        backend: credValue?.backend ? {
          ...credValue?.backend,
            ...(credValue?.terraform ?? {})
        } : {...(credValue?.terraform ?? {})}
      }))
    }
  }, [checkValue])

  return (
      <div>
        {tfCredCategory.aws.map((each) => (
            <BorderWrapper key={each.key}>
              <div className="title">
                <StyledTypo size="0.8">
                  {each.label}
                </StyledTypo>
              </div>
              {each.hasCheck && (
                  <StyledCheckbox
                      name="Use same credential"
                      handleOnChange={handleCheck}
                      checked={checkValue}
                      color="#A4AFB7"
                      fontSize={0.875}
                      fontWeight={400}
                      className="mt-3"
                  />
              )}
              {each.children && each.children.map((category, idx) => each.hasCheck && category.checkSame && checkValue ? (<></>) : (
                  <CustomTextarea
                      key={idx} label={category.label} value={credValue?.[each.key]?.[category.key] ?? ''}
                      setValue={(val) => handleCredChange(each.key, category.key, val)}
                      disabled={(each.hasCheck && category.checkSame && checkValue) || disabled}
                  />
              ))}
            </BorderWrapper>
        ))}
      </div>
  )
}

export default AWSCredPartial
