import React, { PureComponent } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import CustomizedAxisTick from '../../../components/Chart/CustomizedAxisTick';

const trend_data = [
    {
      name: '07-2022',
      compliance: 75,
    },
    {
      name: '08-2022',
      compliance: 85,
    },
    {
      name: '09-2022',
      compliance: 54,
    },
    {
      name: '10-2022',
      compliance: 99,
    },
    {
      name: '11-2022',
      compliance: 22,
    },
    {
      name: '12-2022',
      compliance: 35,
    },
    {
      name: '01-2023',
      compliance: 45,
    },
    {
      name: '02-2023',
      compliance: 55,
    },
    {
      name: '03-2023',
      compliance: 65,
    },
    {
      name: '04-2023',
      compliance: 75,
    },
    {
      name: '05-2023',
      compliance: 95,
    },
    {
      name: '06-2023',
      compliance: 69,
    },
  ];


const PaddingLineChart = (props) => {
    const { data, xAxis, yAxis, line } = props
    return (
      <ResponsiveContainer width="85%" height={400}>
        <LineChart width={500} height={300} data={data ?? trend_data}>
          {/* <CartesianGrid strokeDasharray="1 1" vertical={false} /> */}
          <XAxis axisLine={false} tickLine={false} dataKey={xAxis.key} padding={{ left: 30, right: 30 }} />
          <YAxis axisLine={false} tickLine={false} ticks={[0, 20, 40, 60, 80, 100]} tick={<CustomizedAxisTick yAxis />} />
          <Tooltip />
          <Legend />
          <Line type={line.type} dataKey={yAxis.compliance.key} dot={{ strokeWidth: 6 }} strokeWidth={3} stroke={line.complianceStroke} activeDot={{ r: line.activeDotRadius }} />
          <Line type={line.type} dataKey={yAxis.risk.key} dot={{ strokeWidth: 6 }} strokeWidth={3} stroke={line.riskStroke} activeDot={{ r: line.activeDotRadius }} />
        </LineChart>
      </ResponsiveContainer>
    );
}

export default PaddingLineChart;
