import React from "react";
import styled from "styled-components";

const TabSpan = styled.span`
  padding-bottom: 15px;
  margin-bottom: -1px;
  border-bottom: 1px solid ${props=>props.selected ? "#59a5ed" : "transparent"};
  border-image-slice: unset;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  width: ${props=>props.width};
  text-align: center;
  color: ${props=>props.selected ? "#505050": "#5050505f" };
  font-family: "Roboto";
  font-size: .9rem;
  font-weight: ${props=>props.selected ? 500: 400 };
`;

const SelectedTabSpan = styled(TabSpan)`
  border-image: linear-gradient(0.25turn, rgba(255,249,34), rgba(255,0,128), rgba(56,2,155,0));
  border-image-slice: 1;
`;

const DashboardTab = function DashboardTab({ children, isActive, width = null }) {
  const DashboardTabSpan = isActive ? SelectedTabSpan : TabSpan;
  return <DashboardTabSpan width={width ?? "33.33%"} selected={isActive}>{children}</DashboardTabSpan>;
};

export default DashboardTab;