export const usersConstants = {
  FETCH_ORG_LIST_REQUEST: 'FETCH_ORG_LIST_REQUEST',
  FETCH_ORG_LIST_SUCCESS: 'FETCH_ORG_LIST_SUCCESS',
  FETCH_ORG_LIST_FAILURE: 'FETCH_ORG_LIST_FAILURE',

  FETCH_USER_LIST_REQUEST: 'FETCH_USER_LIST_REQUEST',
  FETCH_USER_LIST_SUCCESS: 'FETCH_USER_LIST_SUCCESS',
  FETCH_USER_LIST_FAILURE: 'FETCH_USER_LIST_FAILURE',

  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  CLEAR_CREATE_USER_FLAG: 'CLEAR_CREATE_USER_FLAG',
  CLEAR_UPDATE_USER_FLAG: 'CLEAR_UPDATE_USER_FLAG',

  MODIFY_USER_STATUS_REQUEST: 'MODIFY_USER_STATUS_REQUEST',
  MODIFY_USER_STATUS_SUCCESS: 'MODIFY_USER_STATUS_SUCCESS',
  MODIFY_USER_STATUS_FAILURE: 'MODIFY_USER_STATUS_FAILURE',

  MODIFY_ORG_STATUS_REQUEST: 'MODIFY_ORG_STATUS_REQUEST',
  MODIFY_ORG_STATUS_SUCCESS: 'MODIFY_ORG_STATUS_SUCCESS',
  MODIFY_ORG_STATUS_FAILURE: 'MODIFY_ORG_STATUS_FAILURE',

  CREATE_ORG_REQUEST: "CREATE_ORG_REQUEST",
  CREATE_ORG_SUCCESS: "CREATE_ORG_SUCCESS",
  CREATE_ORG_FAILURE: "CREATE_ORG_FAILURE",

  CLEAR_CREATE_ORG_FLAG: 'CLEAR_CREATE_ORG_FLAG',
}
