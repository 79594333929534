import React, { useEffect, useState } from "react";
import HealthIndicator from "../HealthIndicator";
import HexaGrid from "../HexaGrid";
import Resources from "../Resources";
import Observation from "../Observation";
import Deployments from "../Deployments";
import Remediation from "../Remediation";
import styled from "styled-components";
import {
  actionResourceType,
  loadingBar,
  remediation_status_color,
  remediationActionType,
} from "../../../../helpers/constants";
import StyledTypo from "../../../../components/Typo/StyledTypo";

const MainBodyWrapper = styled.div`
  width: 100%;
  //width: ${126200 / 1742}%;
  //border: 1px solid #ccc;
  //height: 1200px;
  padding: 30px 25px;
  min-height: 80vh;
  background: #ffffff;

  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  border-radius: 15px;
`;

const ProcessWrapper = styled.div`
  min-height: 146px;
`;

const GraphWrapper = styled.div`
  background: #ffffff;
  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 20px;
  min-height: 424px;
  width: 100%;
  margin-bottom: 45px;
  padding: 46px 10px 46px 46px;
  display: flex;
  margin-top: 30px;
`;

const DetailItemWrapper = styled.div`
  background: #ffffff;
  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 20px;
  width: ${39400 / 1264}%;
  padding: 23px 29px 23px 24px;

  min-height: 930px;
  max-height: 950px;
  //overflow-y: auto;
`;

const RemediationOverviewWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 30px 0;
`;

const RemediationOverviewBlock = styled.div`
  padding: 20px 30px;
  border-radius: 10px;
  background-color: ${(props) => props.color};
  width: 100%;
  //min-height: 100px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
`;

const TriggerAlert = styled.div`
  padding: 10px 20px;
  border-left: 5px solid #65c2d7;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px #ccc;
`;

const AppDetailPanel = (props) => {
  const {
    selectedApp,
    clusterInfo,
    healthInfo,
    resourceHistoryData,
    clusterInfoLoading,
    remediations,
    solution,
    setSelectedRemediationTab,
    selectedRemediationTab,
    setSelectedFilterType,
    deploymentApps,
    templates,
    activeOrgTemplates,
    hideActivity,
    triggerInfra,
  } = props;

  const [overviewCount, setOverviewCount] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const resources = clusterInfo?.["resources"];
    const remediation = remediations.filter(
      (item) => item.platform === selectedApp.platform
    );
    let totalCases = 0;
    let criticalCases = 0;
    let errorCases = 0;
    let info = 0;
    remediation &&
      remediation?.forEach((item) => {
        item.attributes.resources.forEach((each) => {
          totalCases += (
            resources?.[each.type === "k8" ? "cluster" : each.type] ?? []
          ).length;
        });
      });
    solution?.solutions?.forEach((sol) => {
      const severity = remediation.filter(
        (item) => item.id === sol.remediation
      )?.[0]?.metadata?.severity;
      const action = remediation.filter(
        (item) => item.id === sol.remediation
      )?.[0]?.metadata?.remediation_action;
      const invalid = [
        ...new Set([...sol.templates.map((item) => item?.invalid?.resource)]),
      ].length;
      if (action === remediationActionType.report_only.value) {
        info += invalid;
      } else {
        if (["Critical", "High"].includes(severity)) {
          criticalCases += invalid;
        } else {
          errorCases += invalid;
        }
      }
    });
    const okVal = totalCases - criticalCases - errorCases;
    setOverviewCount({
      ok: okVal > 0 ? okVal : 0,
      critical: criticalCases,
      error: errorCases,
      info: info,
    });
  }, [remediations, solution, selectedApp, clusterInfo]);

  return (
    <MainBodyWrapper>
      {triggerInfra?.[selectedApp.id] === "pending" && (
        <TriggerAlert>
          {loadingBar("Rings", 50, 50)}
          <StyledTypo
            className="ml-3"
            color="#A4AFB7"
            size={0.875}
            line={1.4}
            letter="0"
            family="Poppins"
            fontWeight={400}
          >
            Processing fetching resource as background
          </StyledTypo>
        </TriggerAlert>
      )}
      <RemediationOverviewWrapper>
        {Object.keys(remediation_status_color).map((status) => (
          <RemediationOverviewBlock
            color={remediation_status_color[status].color}
          >
            <StyledTypo
              color="#fff"
              size={1}
              line={1.4}
              letter="0"
              family="Roboto"
              fontWeight={400}
            >
              {status.toUpperCase()}
            </StyledTypo>
            <StyledTypo
              className="mt-2"
              color="#fff"
              size={2.5}
              line={1.4}
              letter="0"
              family="Poppins"
              fontWeight={700}
            >
              {overviewCount?.[status] ?? 0}
            </StyledTypo>
          </RemediationOverviewBlock>
        ))}
      </RemediationOverviewWrapper>
      {/*<ProcessWrapper>*/}
      {/*  <HealthIndicator*/}
      {/*      health={selectedApp ? Math.ceil(healthInfo?.health ?? 0) : 0}*/}
      {/*      provider={selectedApp && selectedApp.platform}*/}
      {/*      resource={selectedApp ? clusterInfo?.['resources'] ?? {} : {}}*/}
      {/*  />*/}
      {/*</ProcessWrapper>*/}
      {/*<HexaGrid data={resourceHistoryData}/>*/}
      <GraphWrapper>
        {!clusterInfoLoading && (
          <Resources
            resources={selectedApp ? clusterInfo?.["resources"] ?? {} : {}}
            grouped={clusterInfo?.["grouped_type"]}
            clusterInfoLoading={clusterInfoLoading}
            csp={selectedApp?.platform}
            setSelectedRemediationTab={setSelectedRemediationTab}
            selectedRemediationTab={selectedRemediationTab}
            setSelectedFilterType={setSelectedFilterType}
            hideActivity={hideActivity}
          />
        )}
      </GraphWrapper>
      {/*<div className="d-flex justify-content-between">*/}
      {/*  <DetailItemWrapper>*/}
      {/*    <Observation resources={selectedApp ? clusterInfo?.['resources'] ?? [] : []}*/}
      {/*                 clusterInfoLoading={clusterInfoLoading}/>*/}
      {/*  </DetailItemWrapper>*/}
      {/*  <DetailItemWrapper>*/}
      {/*    <Deployments*/}
      {/*        deploymentApps={deploymentApps}*/}
      {/*        selectedApp={selectedApp}*/}
      {/*        templates={templates.filter(template => template.application_id === selectedApp?.id)}*/}
      {/*        appStatus={!!selectedApp && !!Math.ceil(healthInfo?.health ?? 0)}*/}
      {/*    />*/}
      {/*  </DetailItemWrapper>*/}
      {/*  <DetailItemWrapper>*/}
      {/*    <Remediation*/}
      {/*        templates={templates}*/}
      {/*        remediationTemplates={activeOrgTemplates}*/}
      {/*        selectedApp={selectedApp}*/}
      {/*        appStatus={!!selectedApp && !!Math.ceil(healthInfo?.health ?? 0)}*/}
      {/*    />*/}
      {/*  </DetailItemWrapper>*/}
      {/*</div>*/}
    </MainBodyWrapper>
  );
};

export default AppDetailPanel;
