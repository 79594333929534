import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { planActions } from "../../../redux/actions";
import { connect } from "react-redux";
import PlanComponent from "../../partisals/PlanComponent";
import { FullViewContainer } from "../../../components/PageLayout/Containers";

const PlanLayout = (props) => {
  const {
    createSubscriptionAction,
    new_subscription_processing,
    new_subscription_result,
    clearNewSubscriptionStatusAction,
    fetchSubscriptionAction,
    upgradeSubscriptionAction,
    cancelSubscriptionAction,
    clearSubscriptionAction,
    fetchPaymentMethodsAction,
    changeSubscriptionPaymentMethodAction,
    current_subscription,
    payment_methods,
    isPlanPage,
  } = props;
  const history = useHistory();

  const handleAccept = (data) => {
    if (data.chosenPlan) {
      upgradeSubscriptionAction(data);
    } else {
      createSubscriptionAction(data);
    }
  };

  const handleCancel = () => {
    cancelSubscriptionAction();
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    fetchSubscriptionAction();
    fetchPaymentMethodsAction();
  };

  useEffect(() => {
    if (new_subscription_result === 1) {
      history.push("/login");
    }
    clearNewSubscriptionStatusAction();
  }, [new_subscription_result]);

  return (
    <FullViewContainer>
      <PlanComponent
        isPlanPage={isPlanPage}
        width={props?.width}
        current_subscription={current_subscription}
        payment_methods={payment_methods}
        handleAccept={handleAccept}
        handleCancel={handleCancel}
        changePaymentMethod={changeSubscriptionPaymentMethodAction}
        clearSubscription={clearSubscriptionAction}
        new_subscription_processing={new_subscription_processing}
        handleRefresh={loadData}
      />
    </FullViewContainer>
  );
};

function mapStateToProps(state) {
  const {
    new_subscription_processing,
    new_subscription_result,
    current_subscription,
    payment_methods,
  } = state.plans;
  return {
    new_subscription_processing,
    new_subscription_result,
    current_subscription,
    payment_methods,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createSubscriptionAction: (data) =>
      dispatch(planActions.createSubscriptionAction(data)),
    clearNewSubscriptionStatusAction: () =>
      dispatch(planActions.clearNewSubscriptionStatusAction()),
    fetchSubscriptionAction: () =>
      dispatch(planActions.fetchSubscriptionAction()),
    upgradeSubscriptionAction: (data) =>
      dispatch(planActions.upgradeSubscriptionAction(data)),
    cancelSubscriptionAction: () =>
      dispatch(planActions.cancelSubscriptionAction()),
    clearSubscriptionAction: () =>
      dispatch(planActions.clearSubscriptionAction()),
    fetchPaymentMethodsAction: () =>
      dispatch(planActions.fetchPaymentMethodsAction()),
    changeSubscriptionPaymentMethodAction: (data) =>
      dispatch(planActions.changeSubscriptionPaymentMethodAction(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanLayout);
