import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Dropdown, DropdownMenu, Row, Col } from "reactstrap";
import Slider from "react-slick";

import ActivityFeed from "./ActivityFeed";
import StatusMark from "../../partisals/StatusMark";
import EffectedButton from "../../../components/Buttons/EffectedButton";

import { ReactComponent as DownArrowIcon } from "../../../assets/icons/down_arrow_icon.svg";
import { ReactComponent as UpArrowIcon } from "../../../assets/icons/up_arrow_icon.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/m_refresh_icon.svg";
import { ReactComponent as ActivityFeedIcon } from "../../../assets/icons/left-arrow-angle-mark.svg";

import * as global from "../../../helpers/constants";
import { applicationActions, deploymentActions } from "../../../redux/actions";
import { connect } from "react-redux";
import StyledTypo from "../../../components/Typo/StyledTypo";
import DropdownIcon from "../../../components/Buttons/DropdownIcon";
import StyledDropdown from "../../../components/Buttons/StyledDropdown";
import ApplicationDetailsPanel from "../../modals/ApplicationDetailsPanel";
import useWindowDimensions from "./useWindowDimensions";
import { MainContext } from "../../../contexts";
import {
  defaultEnv,
  getCurrentOrganization,
  getParamsStr,
  loadingBar,
  cspData,
} from "../../../helpers/constants";
import AddApplicationPanel from "../../modals/AddApplicationPanel";
import HealthChart from "./HealthChart";
import HealthHistoryChart from "./HealthHistoryChart";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info_icon.svg";
import moment from "moment";
import RemediationModal from "../../modals/RemediationModal";
import ObserveTab from "./ObserveTabs";

const ContainerWrapper = styled.div`
  flex: 1;
  //width: calc(100vw - 101px - 10px);
  margin-left: 101px;
`;
const EnvStatusWrapper = styled.div`
  height: 100px;
  background: #ffffff;
  border-radius: 0 0 20px;
  padding: 20px ${(props) => (props.count ? 0 : 30)}px;
  filter: drop-shadow(0px 6px 4px rgba(104, 112, 137, 0.05));
  // z-index: 110;
  gap: 20px;
`;

const AppStatusWrapper = styled.div`
  padding: 20px ${(props) => (props.count ? 0 : 30)}px;
  height: 80px;
  //border: 1px solid #ccc;
  margin-bottom: 14px;
  z-index: 50;
  display: flex;
  //justify-content: space-between;
  gap: 30px;
`;

const StatusBodyWrapper = styled.div`
  display: flex;
  gap: ${4000 / 1742}%;
  flex-direction: row-reverse;
`;

const MainBodyWrapper = styled.div`
  width: ${(props) => (props.hideActivity ? "100" : `${126200 / 1742}`)}%;
  //border: 1px solid #ccc;
  height: 1200px;
`;

const ActivityFeedWrapper = styled.div`
  background: #ffffff;
  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  border-radius: 20px;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
  width: 100%;
  padding: 44px 27px 44px 34px;
  &.hide-wrapper {
    width: 50px;
    height: 50px;
    padding: 5px;
    border-radius: 5px;
    border: 2px solid #f1f5fa;
    filter: none;
  }
`;

const RightSideWrapper = styled.div`
  width: ${(props) => (!props.hide ? "50px" : `${44000 / 1742}%`)};
`;

const AppContainer = styled.div`
  background: #ffffff;
  border: 1px solid #f1f5fa;
  box-sizing: border-box;
  width: 250px;
  min-height: 40px;

  box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 10px;
  padding: 6px 20px;
  cursor: pointer;

  &.active {
    padding-bottom: 19px;
  }

  &.selected {
    background: #d6eff4;
  }
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
  word-break: break-all;

  &:hover {
    text-decoration: none;
  }
`;

const EnvContainer = styled.div`
  background: #f1f1f1;

  box-shadow: inset -4px 4px 10px rgba(27, 64, 152, 0.1);
  border-radius: 20px;
  width: 350px;
  min-height: 40px;

  padding: 6px 20px;
  cursor: pointer;

  &.selected {
    background: #65c2d744;
    //box-shadow: inset -4px 4px 10px rgba(27, 64, 152, 0.2);
    //border: 1px solid #65C2D766;
  }
`;

const SelectWrapper = styled.div`
  width: 285px;
  height: 50px;
  padding: 0 25px 0 30px;

  background: #ffffff;

  border: 2px solid #f1f5fa;
  box-sizing: border-box;

  box-shadow: 0 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: ${(props) => (props.active ? "15px 15px 0 0" : "15px")};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CategoryDropdownMenu = styled(DropdownMenu)`
  // --width: ${(props) => props.width - 350}px;
  // left: calc(var(--width) - 50px - 1vw) !important;
  /* white */
  width: 285px;
  padding: 20px 30px;
  background: #ffffff;
  /* Gray light 2 */

  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  /* box shadow */

  box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 20px;
  transform: translate3d(-239px, 30px, 0px) !important;
`;

const CSPDropdownMenu = styled(DropdownMenu)`
  /* white */
  width: 350px;
  padding: 20px 30px;
  background: #ffffff;
  /* Gray light 2 */

  border: 1px solid #f1f5fa;
  box-sizing: border-box;
  /* box shadow */

  box-shadow: 0px 2px 4px rgba(104, 112, 137, 0.05);
  border-radius: 20px;
  transform: translate3d(0px, 50px, 0px) !important;
`;

const EnvDropdownMenu = styled(DropdownMenu)`
  // --width: ${(props) => props.width - 350}px;
  // left: calc(var(--width) - 50px - 1vw) !important;
  /* white */
  width: 350px;
  padding: 20px 30px;
  background: #ffffff;
  /* Gray light 2 */

  border: 1px solid #f1f5fa;
  box-sizing: border-box;
  /* box shadow */

  box-shadow: 0px 2px 4px rgba(104, 112, 137, 0.05);
  border-radius: 20px;
  transform: translate3d(0px, 50px, 0px) !important;
`;

const SummaryWrapper = styled.div`
  background: #fff;
  //border: 1px solid #F1F5FA;
  box-sizing: border-box;
  border-radius: 10px;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
  // width: ${44000 / 1742}%;
  width: 100%;
  //max-width: 300px;
  padding: 30px 30px 20px;
  margin: 20px 0;
  word-break: break-all;
`;

const SummaryItem = styled.div`
  border-top: 1px solid #a4afb7;
  padding: 20px 0;
  cursor: pointer;
`;

const TextWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const NoApplicationWrapper = styled.div`
  width: 100%;
  background: #fff;
  //border: 1px solid #F1F5FA;
  box-sizing: border-box;
  border-radius: 10px;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChartContainer = styled.div`
  background: #fff;
  //border: 1px solid #F1F5FA;
  box-sizing: border-box;
  border-radius: 10px;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
  // width: ${44000 / 1742}%;
  width: 100%;
  //max-width: 300px;
  padding: 30px 30px 20px;
  margin: 0 0 30px 0;
  word-break: break-all;
  //margin-bottom: 30px;
`;

const StyledLegend = styled.div`
  display: flex;
  gap: 7px;
  .legend {
    width: 15px;
    height: 15px;
    background-color: ${(props) => props.color};
  }
  align-items: center;
`;

const LegendWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const LegendContainer = styled.div`
  padding: 5px 20px;
  border-radius: 10px;
  background-color: #f1f1f1;
  margin-left: 20px;
  box-shadow: 0px 6px 4px rgb(104 112 137 / 5%);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const ObserveLayout = (props) => {
  const {
    appList,
    deploymentApps,
    templates,
    remediations,
    activityFeeds,
    fetchActivityFeeds,
    // demoList,
    clusterInfo,
    fetchClusterDetailsAction,
    clusterInfoLoading,
    healthInfo,
    fetchAppHealthAction,
    fetchTaskStatusAction,
    loading_task_id,
    type,
    addAppStatus,
    resetAddAppStatusAction,
    addApplicationAction,
    deleteAppStatus,
    resetDeleteAppStatusAction,
    deleteApplicationAction,
    activeOrgTemplates,
    fetchRemediationRequired,
    envData,
    fetchOrgEnvListAction,
    appListLoading,
    expired_token,
    new_refresh_token,
    healthHistoryData,
    resourceHistoryData,
    fetchResourceHistoryAction,
    fetchSolutionsAction,
    solution,
    triggerStatusAction,
    trigger_infra,
    triggerResourceAction,
    single_resource_update_status,
    triggerSteampipeAction,
    fetchSingleTriggerStatusAction,
    fetchRemediationListAction,
  } = props;
  const [cspList] = useState(cspData);
  const [envList, setEnvList] = useState([defaultEnv]);
  const [applicationList, setApplicationList] = useState(
    appList ? appList.map((env) => ({ ...env, active: false })) : []
  );
  const [graphAppList, setGraphAppList] = useState([]);
  const [selectedApp, setSelectedApp] = useState(null);
  const [selectedEnv, setSelectedEnv] = useState({});
  const [selectedCsp, setSelectedCsp] = useState(cspData[1]);
  const [realTimeFlag, setRealTimeFlag] = useState(false);
  const [prevLoading, setPrevLoading] = useState([]);
  const [showSelectCategoryPanel, setShowSelectCategoryPanel] = useState(false);
  const [showCategoryPanel, setShowCategoryPanel] = useState(false);
  const [showCspPanel, setShowCspPanel] = useState(false);
  const [showEnvPanel, setShowEnvPanel] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [appItemCount, setAppItemCount] = useState(6);
  const [envItemCount, setEnvItemCount] = useState(4);
  const [appParam, setAppParam] = useState(null);
  const [hideActivity, setHideActivity] = useState(false);
  const [selectedRemediationTab, setSelectedRemediationTab] =
    useState("summary");

  const [showRemediationModal, setShowRemediationModal] = useState(false);

  const { width } = useWindowDimensions();

  const { setTrackHistory, trackHistory } = useContext(MainContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (
      trackHistory?.[0]?.pathname !== location.pathname ||
      trackHistory?.[0]?.params !== getParamsStr({})
    ) {
      setTrackHistory((trackHistory) => [
        {
          pathname: location.pathname,
          params: getParamsStr({}),
          details: [`${selectedEnv?.key}`, `Dashboard`],
        },
        ...trackHistory, //.filter(item => item.pathname !== location.pathname)
      ]);
    }
  }, [setTrackHistory]);

  useEffect(() => {
    setAppParam(
      location?.search ? new URLSearchParams(location?.search).get("app") : null
    );
  });

  useEffect(() => {
    fetchOrgEnvListAction(getCurrentOrganization());
  }, [fetchOrgEnvListAction]);

  useEffect(() => {
    if (!expired_token && new_refresh_token) {
      // when get new refresh token
      fetchOrgEnvListAction(getCurrentOrganization());
    }
  }, [expired_token, new_refresh_token, fetchOrgEnvListAction]);

  useEffect(() => {
    if (envData && Object.keys(envData).length && envData.environments.length) {
      let envPayload = [
        ...envData.environments
          .filter((env) => env.is_active)
          .map((env) => ({
            key: env.key,
            abbr: env.key,
            title: env.name,
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
          })),
      ];
      if (!envPayload.length) {
        envPayload = [defaultEnv, ...envPayload];
      }
      setEnvList(
        envPayload.map((item) => ({
          ...item,
          active: false,
          status: 1,
          apps: 0,
        }))
      );
      setSelectedEnv(
        envPayload.map((item) => ({
          ...item,
          active: false,
          status: 1,
          apps: 0,
        }))?.[0]
      );
    }
  }, [envData]);

  useEffect(() => {
    if (appParam) {
      setSelectedApp({
        ...applicationList.filter((app) => app.id === appParam)?.[0],
      });
      if (
        trackHistory?.[0]?.pathname !== location.pathname ||
        trackHistory?.[0]?.params !==
          getParamsStr({
            env: selectedEnv.key,
            app: appParam,
          })
      ) {
        setTrackHistory((trackHistory) => [
          {
            pathname: location.pathname,
            params: getParamsStr({
              env: selectedEnv.key,
              app: appParam,
            }),
            details: [
              `${selectedEnv?.key ?? ""}`,
              `${
                applicationList.filter((app) => app.id === appParam)?.[0]
                  ?.name ?? ""
              }`,
            ],
          },
          ...trackHistory, //.filter(item => item.pathname !== location.pathname)
        ]);
      }
    }
  }, [appParam]);

  useEffect(() => {
    setAppItemCount(Math.floor((width - 100) / 300));
    setEnvItemCount(Math.floor((width - 385) / 400));
  }, [width]);

  useEffect(() => {
    const timeIntv = setInterval(() => {
      single_resource_update_status.forEach((item, idx) => {
        if (item.status === "pending") {
          setTimeout(() => {
            fetchSingleTriggerStatusAction(
              item.application_id,
              item.remediation_id,
              item.task_id
            );
          }, 50);
        }
      });
      Object.keys(trigger_infra)
        .filter((app_id) => trigger_infra[app_id] === "pending")
        .forEach((app_id) => {
          setTimeout(() => {
            triggerStatusAction(app_id);
          }, 50);
        });
      loading_task_id.forEach((item, idx) => {
        setTimeout(() => {
          fetchTaskStatusAction(item.task, item.type, item.app);
        }, 50);
      });
    }, 10 * 1000);

    return function cleanup() {
      if (timeIntv !== 0) {
        clearInterval(timeIntv);
      }
    };
  }, [
    loading_task_id,
    fetchTaskStatusAction,
    type,
    prevLoading,
    trigger_infra,
    single_resource_update_status,
  ]);

  useEffect(() => {
    if (
      selectedApp &&
      prevLoading.filter((item) => item.app === selectedApp.id).length &&
      !loading_task_id.filter((item) => item.app === selectedApp.id).length
    ) {
      fetchAppHealthAction("", selectedApp.id, realTimeFlag);
      // fetchClusterDetailsAction('', selectedApp.id, realTimeFlag)
      fetchRemediationRequired(selectedApp.id);
      setRealTimeFlag(false);
      fetchActivityFeeds();
    }
    setPrevLoading(loading_task_id);
  }, [loading_task_id, prevLoading]);

  useEffect(() => {
    setEnvList((e) => [
      ...e.map((env) => ({
        ...env,
        apps: appList.filter(
          (app) =>
            app?.environment === env.key && app?.platform === selectedCsp?.key
        ).length,
      })),
    ]);
    const filteredAppList = [
      ...appList
        .filter(
          (app) =>
            app?.environment === selectedEnv?.key &&
            app?.platform === selectedCsp?.key
        )
        .map((env) => ({
          ...env,
          active: false,
        })),
    ];
    setApplicationList(filteredAppList);

    if (appParam && !selectedApp) {
      setSelectedApp({
        ...filteredAppList.filter((app) => app.id === appParam)?.[0],
      });
    }
  }, [appList, selectedEnv, appParam, selectedCsp]);

  useEffect(() => {
    if (selectedEnv) {
      const filteredAppList = [
        ...appList
          .filter(
            (app) =>
              app?.environment === selectedEnv?.key &&
              app?.platform === selectedCsp?.key
          )
          .map((env) => ({
            ...env,
            active: false,
          })),
      ];
      setGraphAppList(filteredAppList);
    }
  }, [selectedEnv, selectedCsp]);

  useEffect(() => {
    if (appList.length && !graphAppList.length) {
      setGraphAppList([
        ...appList
          .filter(
            (app) =>
              app?.environment === selectedEnv?.key &&
              app?.platform === selectedCsp?.key
          )
          .map((env) => ({
            ...env,
            active: false,
          })),
      ]);
    }
  }, [appList]);

  useEffect(() => {
    if (selectedApp) {
      // fetchAppHealthAction('', selectedApp.id, realTimeFlag)
      if (!clusterInfo[selectedApp.id] || realTimeFlag) {
        fetchClusterDetailsAction(selectedApp.id, false);
      }
      if (realTimeFlag && selectedApp.platform !== "aws") {
        triggerSteampipeAction(selectedApp.id);
      }
      // fetchRemediationRequired(selectedApp.id)
      setRealTimeFlag(false);
      fetchActivityFeeds();
      // fetchSolutionsAction({app_id: selectedApp.id})
      // fetchResourceHistoryAction({
      //   app_id: selectedApp.id,
      //   startHistoryTime: moment().format('X') - 30 * 24 * 60 * 60,
      //   endHistoryTime: moment().format('X'),
      // })
    }
  }, [selectedApp, realTimeFlag]);

  const handleSelectEnv = (env) => {
    if (selectedEnv?.key !== env.key) {
      const filteredAppList = [
        ...appList
          .filter(
            (app) =>
              app?.environment === env?.key &&
              app?.platform === selectedCsp?.key
          )
          .map((env) => ({
            ...env,
            active: false,
          })),
      ];
      setApplicationList(filteredAppList);
      setGraphAppList(filteredAppList);
      setSelectedEnv({ ...env });
      setSelectedApp(null);
    }
  };

  const handleSelectCsp = (csp) => {
    if (selectedCsp?.key !== csp?.key) {
      const filteredAppList = [
        ...appList
          .filter(
            (app) =>
              app?.environment === selectedEnv?.key &&
              app?.platform === csp?.key
          )
          .map((env) => ({
            ...env,
            active: false,
          })),
      ];
      setSelectedCsp({ ...csp });
      setApplicationList(filteredAppList);
      setGraphAppList(filteredAppList);
      setSelectedApp(null);
    }
  };

  useEffect(() => {
    if (deleteAppStatus === 1) {
      resetDeleteAppStatusAction();
      setShowCategoryPanel(false);
      setSelectedApp(null);
    }
  }, [deleteAppStatus]);

  useEffect(() => {
    fetchRemediationListAction(selectedCsp?.key);
  }, [selectedCsp]);

  return (
    <ContainerWrapper>
      <div className="d-flex flex-column-reverse">
        <div className="main-container">
          <StatusBodyWrapper>
            {/*{!hideActivity && (*/}
            <RightSideWrapper hide={!hideActivity}>
              <ActivityFeedWrapper
                className={hideActivity ? "hide-wrapper" : ""}
              >
                {!hideActivity ? (
                  <ActivityFeed
                    setHideActivity={setHideActivity}
                    activityFeeds={activityFeeds.filter(
                      (feed) =>
                        feed.organization_id === global.getCurrentOrganization()
                    )}
                  />
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center mt-1 mr-1 cursor-pointer"
                    onClick={() =>
                      setHideActivity((hideActivity) => !hideActivity)
                    }
                    data-tooltip="Activity Feed"
                  >
                    <ActivityFeedIcon width={20} fill="#A4AFB7" />
                  </div>
                )}
              </ActivityFeedWrapper>
            </RightSideWrapper>
            {/*)}*/}
            {appListLoading ? (
              applicationList.length ? (
                selectedApp ? (
                  <MainBodyWrapper hideActivity={hideActivity}>
                    <ObserveTab
                      selectedApp={selectedApp}
                      clusterInfo={clusterInfo?.[selectedApp?.id] ?? []}
                      healthInfo={healthInfo}
                      resourceHistoryData={resourceHistoryData}
                      clusterInfoLoading={clusterInfoLoading}
                      deploymentApps={deploymentApps}
                      templates={templates}
                      activeOrgTemplates={activeOrgTemplates}
                      remediations={remediations}
                      solution={solution?.[selectedApp.id] ?? []}
                      setHideActivity={setHideActivity}
                      hideActivity={hideActivity}
                      setSelectedRemediationTab={setSelectedRemediationTab}
                      selectedRemediationTab={selectedRemediationTab}
                      triggerInfra={trigger_infra}
                    />
                  </MainBodyWrapper>
                ) : (
                  <MainBodyWrapper hideActivity={hideActivity}>
                    {/*<ChartContainer>*/}
                    {/*  <div className="d-flex justify-content-between">*/}
                    {/*    <div className="m-tooltip">*/}
                    {/*      <span className="font-size-36 font-weight-bold m-gray ml-4">Health Indicator</span>*/}
                    {/*      <span className="cursor-pointer ml-2">*/}
                    {/*        <InfoIcon width={15} height={15} fill="#485DCD" style={{marginBottom: 35}}/>*/}
                    {/*        <span className="tooltiptext"> <div>This chart shows current health values for all infrastructures.</div></span>*/}
                    {/*      </span>*/}
                    {/*    </div>*/}
                    {/*    <LegendContainer>*/}
                    {/*      <LegendWrapper>*/}
                    {/*        <StyledLegend color="#ff487f">*/}
                    {/*          <div className="legend"/>*/}
                    {/*          <StyledTypo color="#505050" size={0.875} line={1.4} letter="0" family="Roboto"*/}
                    {/*                      fontWeight={400}>*/}
                    {/*            over 80%*/}
                    {/*          </StyledTypo>*/}
                    {/*        </StyledLegend>*/}
                    {/*        <StyledLegend color="#FFBA00">*/}
                    {/*          <div className="legend"/>*/}
                    {/*          <StyledTypo color="#505050" size={0.875} line={1.4} letter="0" family="Roboto"*/}
                    {/*                      fontWeight={400}>*/}
                    {/*            between 20% and 80%*/}
                    {/*          </StyledTypo>*/}
                    {/*        </StyledLegend>*/}
                    {/*        <StyledLegend color="#61bf92">*/}
                    {/*          <div className="legend"/>*/}
                    {/*          <StyledTypo color="#505050" size={0.875} line={1.4} letter="0" family="Roboto"*/}
                    {/*                      fontWeight={400}>*/}
                    {/*            below 20%*/}
                    {/*          </StyledTypo>*/}
                    {/*        </StyledLegend>*/}
                    {/*      </LegendWrapper>*/}
                    {/*    </LegendContainer>*/}

                    {/*  </div>*/}
                    {/*  {graphAppList.length ? (*/}
                    {/*      <HealthChart*/}
                    {/*          bitOrder={1}*/}
                    {/*          summaryData={graphAppList}*/}
                    {/*          color="#5EB1E4"*/}
                    {/*          id="health"*/}
                    {/*      />*/}
                    {/*  ) : (*/}
                    {/*      <div className="text-center mt-5 mb-3">*/}
                    {/*        <StyledTypo color="#A4AFB7" size={1} line={1.4}*/}
                    {/*                    letter="0" family="Poppins" fontWeight={400}>*/}
                    {/*          No Infrastructure.*/}
                    {/*        </StyledTypo>*/}
                    {/*      </div>*/}
                    {/*  )}*/}
                    {/*</ChartContainer>*/}
                    {/*<ChartContainer>*/}
                    {/*  <div className="m-tooltip">*/}
                    {/*    <span className="font-size-36 font-weight-bold m-gray ml-4">Time Series Health</span>*/}
                    {/*    <span className="cursor-pointer ml-2">*/}
                    {/*      <InfoIcon width={15} height={15} fill="#485DCD" style={{marginBottom: 35}}/>*/}
                    {/*      <span className="tooltiptext"> <div>This chart shows history of health values for all infrastructures.</div></span>*/}
                    {/*    </span>*/}
                    {/*  </div>*/}

                    {/*  {graphAppList.length ? (*/}
                    {/*      <HealthHistoryChart summaryData={healthHistoryData} appList={graphAppList}/>*/}
                    {/*  ) : (*/}
                    {/*      <div className="text-center mt-5 mb-3">*/}
                    {/*        <StyledTypo color="#A4AFB7" size={1} line={1.4}*/}
                    {/*                    letter="0" family="Poppins" fontWeight={400}>*/}
                    {/*          No Infrastructure.*/}
                    {/*        </StyledTypo>*/}
                    {/*      </div>*/}
                    {/*  )}*/}
                    {/*</ChartContainer>*/}
                    <div className="pt-3 mt-3 pb-5">
                      <SummaryWrapper>
                        <span className="font-size-36 font-weight-bold m-gray">
                          Infrastructure Summary
                        </span>
                        <Row className="mb-3 mt-4">
                          <Col md={5}>
                            <StyledTypo
                              className="text-justify"
                              color="#505050"
                              size={1.125}
                              line={1.4}
                              letter="0"
                              family="Roboto"
                              fontWeight={700}
                            >
                              Name
                            </StyledTypo>
                          </Col>
                          <Col md={1}>
                            <StyledTypo
                              className="text-justify"
                              color="#505050"
                              size={1.125}
                              line={1.4}
                              letter="0"
                              family="Roboto"
                              fontWeight={700}
                            >
                              Health
                            </StyledTypo>
                          </Col>
                          <Col md={6}>
                            <StyledTypo
                              className="text-justify"
                              color="#505050"
                              size={1.125}
                              line={1.4}
                              letter="0"
                              family="Roboto"
                              fontWeight={700}
                            >
                              Summary
                            </StyledTypo>
                          </Col>
                        </Row>
                        {applicationList.length ? (
                          applicationList.map((item, idx) => (
                            <SummaryItem
                              onClick={() => {
                                setSelectedApp({ ...item });
                                setSelectedRemediationTab("summary");
                                setTrackHistory((trackHistory) => [
                                  {
                                    pathname: location.pathname,
                                    params: getParamsStr({
                                      env: selectedEnv?.key ?? "",
                                      app: item?.id ?? "",
                                    }),
                                    details: [
                                      `${selectedEnv?.key ?? ""}`,
                                      `${
                                        applicationList.filter(
                                          (app) => app.id === item?.id
                                        )?.[0]?.name ?? ""
                                      }`,
                                    ],
                                  },
                                  ...trackHistory, //.filter(item => item.pathname !== location.pathname)
                                ]);
                              }}
                              key={idx}
                            >
                              <Row className="align-items-center">
                                <Col md={5}>
                                  <StyledTypo
                                    className="text-justify"
                                    color="#485DCD"
                                    size={1}
                                    line={1.4}
                                    letter="0"
                                    family="Poppins"
                                    fontWeight={400}
                                  >
                                    {item.name}
                                  </StyledTypo>
                                </Col>
                                <Col md={1}>
                                  <StyledTypo
                                    className="text-justify"
                                    color="#A4AFB7"
                                    size={1}
                                    line={1.4}
                                    letter="0"
                                    family="Poppins"
                                    fontWeight={400}
                                  >
                                    {item?.health ?? 0} %
                                  </StyledTypo>
                                </Col>
                                <Col md={6}>
                                  <Row>
                                    <Col md={4}>
                                      <TextWrapper>
                                        <div className="font-weight-normal font-size-16 m-dark-gray">
                                          Platform:
                                        </div>
                                        <StyledTypo
                                          className="text-justify"
                                          color="#A4AFB7"
                                          size={1}
                                          line={1.4}
                                          letter="0"
                                          family="Poppins"
                                          fontWeight={400}
                                        >
                                          {item?.platform?.toUpperCase() ?? ""}
                                        </StyledTypo>
                                        {/*<div className="font-weight-lighter font-size-16 m-blue">{item?.platform?.toUpperCase() ?? ''}</div>*/}
                                      </TextWrapper>
                                    </Col>
                                    <Col md={4}>
                                      <TextWrapper>
                                        <div className="font-weight-normal font-size-16 m-dark-gray">
                                          Nodes:
                                        </div>
                                        <StyledTypo
                                          className="text-justify"
                                          color="#A4AFB7"
                                          size={1}
                                          line={1.4}
                                          letter="0"
                                          family="Poppins"
                                          fontWeight={400}
                                        >
                                          {item?.summary?.node ?? 0}
                                        </StyledTypo>
                                      </TextWrapper>
                                    </Col>
                                    <Col md={4}>
                                      <TextWrapper>
                                        <div className="font-weight-normal font-size-16 m-dark-gray">
                                          Pods:
                                        </div>
                                        <StyledTypo
                                          className="text-justify"
                                          color="#A4AFB7"
                                          size={1}
                                          line={1.4}
                                          letter="0"
                                          family="Poppins"
                                          fontWeight={400}
                                        >
                                          {item?.summary?.pod ?? 0}
                                        </StyledTypo>
                                      </TextWrapper>
                                    </Col>
                                    <Col md={4}>
                                      <TextWrapper>
                                        <div className="font-weight-normal font-size-16 m-dark-gray">
                                          Cluster:
                                        </div>
                                        <StyledTypo
                                          className="text-justify"
                                          color="#A4AFB7"
                                          size={1}
                                          line={1.4}
                                          letter="0"
                                          family="Poppins"
                                          fontWeight={400}
                                        >
                                          {item?.summary?.cluster ?? 0}
                                        </StyledTypo>
                                      </TextWrapper>
                                    </Col>
                                    <Col md={4}>
                                      <TextWrapper>
                                        <div className="font-weight-normal font-size-16 m-dark-gray">
                                          Services:
                                        </div>
                                        <StyledTypo
                                          className="text-justify"
                                          color="#A4AFB7"
                                          size={1}
                                          line={1.4}
                                          letter="0"
                                          family="Poppins"
                                          fontWeight={400}
                                        >
                                          {item?.summary?.service ?? 0}
                                        </StyledTypo>
                                      </TextWrapper>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </SummaryItem>
                          ))
                        ) : (
                          <div className="text-center mt-5 mb-3">
                            <StyledTypo
                              color="#A4AFB7"
                              size={1}
                              line={1.4}
                              letter="0"
                              family="Poppins"
                              fontWeight={400}
                            >
                              No Infrastructure.
                            </StyledTypo>
                          </div>
                        )}
                      </SummaryWrapper>
                    </div>
                  </MainBodyWrapper>
                )
              ) : (
                <div />
              )
            ) : (
              <div className="d-flex justify-content-center mt-5 pt-5 w-100">
                {loadingBar("Rings", 150, 150)}
              </div>
            )}
          </StatusBodyWrapper>
        </div>
        <div className="main-container app-container">
          <AppStatusWrapper count={applicationList.length <= appItemCount}>
            {applicationList.length ? (
              applicationList.length > appItemCount ? (
                <Slider
                  {...global.settingSlide({
                    showSlideCnt: appItemCount,
                    type: "app",
                  })}
                >
                  {applicationList.map((app, idx) => (
                    <Dropdown key={idx}>
                      <AppContainer
                        className={`my-2 ${app.active ? "active" : ""} ${
                          selectedApp?.id === app.id ? "selected" : ""
                        }`}
                        onMouseEnter={() => {
                          setApplicationList([
                            ...applicationList.map((item) => ({
                              ...item,
                              active: item.id === app.id ? !item.active : false,
                            })),
                          ]);
                        }}
                        onMouseLeave={() => {
                          setApplicationList([
                            ...applicationList.map((item) => ({
                              ...item,
                              active: false,
                            })),
                          ]);
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="font-size-18 font-weight-lighter m-blue">
                            <StyledAnchor
                              onClick={() => {
                                // setApplicationList([...applicationList.map(item => ({
                                //   ...item,
                                //   active: item.id === app.id && app.id === selectedApp?.id ? !item.active : false
                                // }))])
                                setSelectedApp({ ...app });
                                setTrackHistory((trackHistory) => [
                                  {
                                    pathname: location.pathname,
                                    params: getParamsStr({
                                      env: selectedEnv?.key ?? "",
                                      app: app?.id ?? "",
                                    }),
                                    details: [
                                      `${selectedEnv?.key ?? ""}`,
                                      `${
                                        applicationList.filter(
                                          (item) => item.id === app?.id
                                        )?.[0]?.name ?? ""
                                      }`,
                                    ],
                                  },
                                  ...trackHistory, //.filter(item => item.pathname !== location.pathname)
                                ]);
                              }}
                            >
                              {app.active
                                ? app.name
                                : app.name.length > 10
                                ? `${app.name.substr(0, 10)}...`
                                : app.name}{" "}
                              ({app.platform})
                            </StyledAnchor>
                          </div>
                          {selectedApp?.id === app?.id && (
                            <div data-tooltip="Refresh">
                              <StyledAnchor
                                onClick={() => {
                                  setRealTimeFlag(true);
                                  setSelectedRemediationTab("summary");
                                }}
                              >
                                <RefreshIcon
                                  width={16}
                                  fill={global.colors.custom_fill_inactive}
                                />
                              </StyledAnchor>
                            </div>
                          )}
                          {/*<div className="font-size-18 font-weight-bold m-red">{Math.ceil(app?.health ?? 0)} %</div>*/}
                        </div>
                        {app.active && (
                          <div>
                            <div className="pt-3 font-weight-lighter font-size-14 m-dark-gray">
                              {app?.description ?? ""}
                              {/*{app?.content ?? ''}*/}
                            </div>
                            <div className="mt-3">
                              <EffectedButton fontsize={18}>
                                Remediation
                              </EffectedButton>
                            </div>
                          </div>
                        )}
                      </AppContainer>
                    </Dropdown>
                  ))}
                </Slider>
              ) : (
                applicationList.map((app, idx) => (
                  <Dropdown key={idx}>
                    <AppContainer
                      className={`my-2 ${app.active ? "active" : ""} ${
                        selectedApp?.id === app.id ? "selected" : ""
                      }`}
                      onMouseEnter={() => {
                        setApplicationList([
                          ...applicationList.map((item) => ({
                            ...item,
                            active: item.id === app.id ? !item.active : false,
                          })),
                        ]);
                      }}
                      onMouseLeave={() => {
                        setApplicationList([
                          ...applicationList.map((item) => ({
                            ...item,
                            active: false,
                          })),
                        ]);
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="font-size-18 font-weight-lighter m-blue">
                          <StyledAnchor
                            onClick={() => {
                              setSelectedApp({ ...app });
                              setSelectedRemediationTab("summary");
                              setTrackHistory((trackHistory) => [
                                {
                                  pathname: location.pathname,
                                  params: getParamsStr({
                                    env: selectedEnv?.key ?? "",
                                    app: app?.id ?? "",
                                  }),
                                  details: [
                                    `${selectedEnv?.key ?? ""}`,
                                    `${
                                      applicationList.filter(
                                        (item) => item.id === app?.id
                                      )?.[0]?.name ?? ""
                                    }`,
                                  ],
                                },
                                ...trackHistory, //.filter(item => item.pathname !== location.pathname)
                              ]);
                            }}
                          >
                            {app.active
                              ? app.name
                              : app.name.length > 10
                              ? `${app.name.substr(0, 10)}...`
                              : app.name}{" "}
                            ({app.platform})
                          </StyledAnchor>
                        </div>
                        {selectedApp?.id === app?.id && (
                          <div data-tooltip="Refresh">
                            <StyledAnchor
                              onClick={() => {
                                setRealTimeFlag(true);
                                setSelectedRemediationTab("summary");
                              }}
                            >
                              <RefreshIcon
                                width={16}
                                fill={global.colors.custom_fill_inactive}
                              />
                            </StyledAnchor>
                          </div>
                        )}
                      </div>
                      {app.active && (
                        <div>
                          <div className="pt-3 font-weight-lighter font-size-14 m-dark-gray">
                            {app?.description ?? ""}
                            {/*{app?.content ?? ''}*/}
                          </div>
                          <div className="mt-3">
                            <EffectedButton
                              fontsize={18}
                              onClick={() => {
                                setSelectedApp({ ...app });
                                setSelectedRemediationTab("remediation");
                                setTrackHistory((trackHistory) => [
                                  {
                                    pathname: location.pathname,
                                    params: getParamsStr({
                                      env: selectedEnv?.key ?? "",
                                      app: app?.id ?? "",
                                    }),
                                    details: [
                                      `${selectedEnv?.key ?? ""}`,
                                      `${
                                        applicationList.filter(
                                          (item) => item.id === app?.id
                                        )?.[0]?.name ?? ""
                                      }`,
                                    ],
                                  },
                                  ...trackHistory, //.filter(item => item.pathname !== location.pathname)
                                ]);
                              }}
                            >
                              Remediation
                            </EffectedButton>
                          </div>
                        </div>
                      )}
                    </AppContainer>
                  </Dropdown>
                ))
              )
            ) : (
              <NoApplicationWrapper className="font-size-18 font-weight-lighter m-blue">
                <div>No Infrastructure.</div>
              </NoApplicationWrapper>
            )}
          </AppStatusWrapper>
        </div>
        <EnvStatusWrapper count={envList.length <= envItemCount}>
          <div
            className={`env-slick main-container d-flex justify-content-between align-items-center`}
          >
            <div className="d-flex" style={{ gap: 20 }}>
              <StyledDropdown
                isOpen={showCspPanel}
                toggle={() => setShowCspPanel((showCspPanel) => !showCspPanel)}
              >
                <DropdownIcon>
                  <Dropdown className="pt-2">
                    <EnvContainer>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="ml-3 font-size-18 font-weight-bold m-blue">
                            {selectedCsp.title}
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <DownArrowIcon fill="#A4AFB7" />
                        </div>
                      </div>
                    </EnvContainer>
                  </Dropdown>
                </DropdownIcon>
                <CSPDropdownMenu>
                  {cspList.map((csp, idx) => (
                    <div
                      className={`d-flex mb-2 align-items-center ${
                        csp.key === selectedCsp?.key ? "" : "cursor-pointer"
                      }`}
                      onClick={() => {
                        selectedCsp?.key !== csp?.key && handleSelectCsp(csp);
                        setHideActivity(false);
                        setShowCspPanel(false);
                      }}
                      key={csp.key}
                    >
                      <StyledTypo
                        color={
                          selectedCsp?.key !== csp?.key
                            ? "#505050"
                            : "#50505066"
                        }
                        size={1}
                        line={1.4}
                        letter="0"
                        family="Poppins"
                        fontWeight={500}
                      >
                        {csp?.title}
                      </StyledTypo>
                    </div>
                  ))}
                </CSPDropdownMenu>
              </StyledDropdown>
              <StyledDropdown
                isOpen={showEnvPanel}
                toggle={() => setShowEnvPanel((showEnvPanel) => !showEnvPanel)}
              >
                <DropdownIcon>
                  <Dropdown className="pt-2">
                    <EnvContainer>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <StatusMark
                            size={10}
                            color={global.colors.status[selectedEnv.status - 1]}
                          />
                          <div className="ml-3 font-size-18 font-weight-bold m-blue">
                            {selectedEnv.title}
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="font-size-18 mr-1 font-weight-lighter m-blue">
                            {applicationList.length} apps
                          </div>
                          <DownArrowIcon fill="#A4AFB7" />
                        </div>
                      </div>
                    </EnvContainer>
                  </Dropdown>
                </DropdownIcon>
                <EnvDropdownMenu>
                  {envList.map((env, idx) => (
                    <div
                      className={`d-flex mb-2 align-items-center justify-content-between ${
                        env.key === selectedEnv?.key ? "" : "cursor-pointer"
                      }`}
                      onClick={() => {
                        if (selectedEnv?.key !== env?.key) {
                          handleSelectEnv(env);
                          setHideActivity(false);
                          setShowEnvPanel(false);
                        }
                      }}
                      key={env.key}
                    >
                      <StyledTypo
                        color={
                          selectedEnv?.key !== env?.key
                            ? "#505050"
                            : "#50505066"
                        }
                        size={1}
                        line={1.4}
                        letter="0"
                        family="Poppins"
                        fontWeight={500}
                      >
                        {env?.title}
                      </StyledTypo>
                      <StyledTypo
                        color={
                          selectedEnv?.key !== env?.key
                            ? "#505050"
                            : "#50505066"
                        }
                        size={1}
                        line={1.4}
                        letter="0"
                        family="Poppins"
                        fontWeight={500}
                      >
                        {appList?.filter(
                          (app) =>
                            app?.environment === env?.key &&
                            app?.platform === selectedCsp?.key
                        )?.length ?? 0}{" "}
                        apps
                      </StyledTypo>
                    </div>
                  ))}
                </EnvDropdownMenu>
              </StyledDropdown>
            </div>
            <div className="pt-1 d-flex align-items-center">
              <SelectWrapper active={showCategoryPanel}>
                <div className="d-flex align-items-center">
                  <StyledDropdown
                    isOpen={showCategoryPanel}
                    toggle={() =>
                      setShowCategoryPanel(
                        (showCategoryPanel) => !showCategoryPanel
                      )
                    }
                  >
                    <DropdownIcon>
                      <StyledTypo
                        color={"#485DCD"}
                        size={1.25}
                        line={36.4 / 26}
                        letter="0"
                        family="Roboto"
                        fontWeight={700}
                      >
                        {
                          global.categories.filter(
                            (item) => item.id === selectedCategory
                          )?.[0]?.name
                        }
                      </StyledTypo>
                    </DropdownIcon>
                    {global.categories
                      .filter((item) => item.id === selectedCategory)?.[0]
                      ?.name?.toLowerCase() === "infrastructure" &&
                    selectedApp ? (
                      <ApplicationDetailsPanel
                        app={selectedApp}
                        resources={selectedApp ? clusterInfo ?? [] : []}
                        addAppStatus={addAppStatus}
                        resetAddAppStatusAction={resetAddAppStatusAction}
                        addNewApplicationAction={addApplicationAction}
                        deleteApplicationAction={deleteApplicationAction}
                        selectedEnv={selectedEnv}
                        envData={envData}
                        selectedCsp={selectedCsp}
                        setDefaultCsp={() => setSelectedCsp(cspData[0])}
                      />
                    ) : (
                      <AddApplicationPanel
                        addNewApplicationAction={addApplicationAction}
                        selectedEnv={selectedEnv}
                        directly
                        addAppStatus={addAppStatus}
                        toggle={() =>
                          setShowCategoryPanel(
                            (showCategoryPanel) => !showCategoryPanel
                          )
                        }
                        envData={envData}
                        selectedCsp={selectedCsp}
                        setDefaultCsp={() => setSelectedCsp(cspData[0])}
                      />
                    )}
                  </StyledDropdown>
                </div>
                <div className="d-flex align-items-center px-2">
                  <StyledDropdown
                    isOpen={showSelectCategoryPanel}
                    toggle={() =>
                      setShowSelectCategoryPanel(
                        (showSelectCategoryPanel) => !showSelectCategoryPanel
                      )
                    }
                  >
                    <DropdownIcon>
                      {showSelectCategoryPanel ? (
                        <UpArrowIcon />
                      ) : (
                        <DownArrowIcon fill="#A4AFB7" />
                      )}
                    </DropdownIcon>
                    <CategoryDropdownMenu>
                      {global.categories.map((item) => (
                        <div
                          className={`d-flex mb-2 align-items-center ${
                            item.id === selectedCategory ? "" : "cursor-pointer"
                          }`}
                          onClick={() => {
                            item.id !== selectedCategory &&
                              setSelectedCategory(item.id);
                            setShowSelectCategoryPanel(false);
                          }}
                        >
                          <StyledTypo
                            color={
                              item.id === selectedCategory
                                ? "#50505066"
                                : "#505050"
                            }
                            size={1.125}
                            line={26.15 / 18}
                            letter="0"
                            family="Roboto"
                            fontWeight={500}
                          >
                            {item.name}
                          </StyledTypo>
                        </div>
                      ))}
                    </CategoryDropdownMenu>
                  </StyledDropdown>
                </div>
              </SelectWrapper>
              {/*<div className="ml-4 cursor-pointer"*/}
              {/*     onClick={() => setShowRemediationModal(showRemediationModal => !showRemediationModal)}>*/}
              {/*  <AttachIcon width={30} fill="#5EB1E4"/>*/}
              {/*</div>*/}
            </div>
          </div>
        </EnvStatusWrapper>
      </div>
      <RemediationModal
        modal={showRemediationModal}
        toggle={() =>
          setShowRemediationModal(
            (showRemediationModal) => !showRemediationModal
          )
        }
        remediations={remediations}
      />
    </ContainerWrapper>
  );
};

function mapStateToProps(state) {
  const {
    clusterInfo,
    clusterInfoLoading,
    healthInfo,
    addAppStatus,
    deleteAppStatus,
    activeOrgTemplates,
    envData,
    appListLoading,
    healthHistoryData,
    resourceHistoryData,
    solution,
    trigger_infra,
    single_resource_update_status,
  } = state.applications;
  const { loading_task_id, type } = state.deployments;
  const { expired_token, new_refresh_token } = state.auth;
  return {
    clusterInfo,
    clusterInfoLoading,
    healthInfo,
    loading_task_id,
    type,
    addAppStatus,
    deleteAppStatus,
    activeOrgTemplates,
    envData,
    appListLoading,
    expired_token,
    new_refresh_token,
    healthHistoryData,
    resourceHistoryData,
    solution,
    trigger_infra,
    single_resource_update_status,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchClusterDetailsAction: (app_id, realtimeFlag) =>
      dispatch(
        applicationActions.fetchClusterDetailsAction(app_id, realtimeFlag)
      ),
    fetchAppHealthAction: (org_id, app_id, realtimeFlag) =>
      dispatch(
        applicationActions.fetchAppHealthAction(org_id, app_id, realtimeFlag)
      ),
    fetchTaskStatusAction: (task_id, apply_type, app_id) =>
      dispatch(
        deploymentActions.fetchTaskStatusAction(task_id, apply_type, app_id)
      ),
    addApplicationAction: (data) =>
      dispatch(applicationActions.addApplicationAction(data)),
    resetAddAppStatusAction: () =>
      dispatch(applicationActions.resetAddAppStatusAction()),
    resetDeleteAppStatusAction: () =>
      dispatch(applicationActions.resetDeleteAppStatusAction()),
    deleteApplicationAction: (app_id) =>
      dispatch(applicationActions.deleteApplicationAction(app_id)),
    fetchRemediationRequired: (app_id) =>
      dispatch(applicationActions.fetchRemediationRequiredAction(app_id)),
    fetchOrgEnvListAction: (org_id) =>
      dispatch(applicationActions.fetchOrgEnvListAction(org_id)),
    fetchResourceHistoryAction: (data) =>
      dispatch(applicationActions.fetchResourceHistoryAction(data)),
    fetchSolutionsAction: (data) =>
      dispatch(applicationActions.fetchSolutionsAction(data)),
    triggerStatusAction: (app_id) =>
      dispatch(applicationActions.triggerStatusAction(app_id)),
    fetchSingleTriggerStatusAction: (app_id, remediation_id, task_id) =>
      dispatch(
        applicationActions.fetchSingleTriggerStatusAction(
          app_id,
          remediation_id,
          task_id
        )
      ),
    triggerResourceAction: (app_id) =>
      dispatch(applicationActions.triggerResourceAction(app_id)),
    triggerSteampipeAction: (app_id) =>
      dispatch(applicationActions.triggerSteampipeAction(app_id)),
    fetchRemediationListAction: (platform) =>
      dispatch(applicationActions.fetchRemediationListAction(platform)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ObserveLayout);
