import { dashboard } from '../apis'
import { authAction } from "./auth.actions";
import { dashboardConstants } from "../constants";

export const dashboardActions = {
  fetchCloudSecurityDataAction,
  fetchCodeSecurityDataAction,
  fetchComplianceInfrastructureListAction,
  fetchResourceInfrastructureListAction,
}

function fetchCloudSecurityDataAction() {

  return dispatch => {
    dispatch(request());
    dashboard.fetchCloudSecurityData()
      .then(
        cloud_data => {
          dispatch(success(cloud_data));
        },
        error => {
          error && dispatch(authAction.setTokenExpired(error, failure));
        }
      );
  };
  function request() { return { type: dashboardConstants.FETCH_DASHBOARD_CLOUD_SECURITY_REQUEST } }
  function success(cloud_data) { return { type: dashboardConstants.FETCH_DASHBOARD_CLOUD_SECURITY_SUCCESS, cloud_data } }
  function failure(error) { return { type: dashboardConstants.FETCH_DASHBOARD_CLOUD_SECURITY_FAILURE, error } }
}


function fetchCodeSecurityDataAction() {

  return dispatch => {
    dispatch(request());
    dashboard.fetchCodeSecurityData()
      .then(
        iac_data => {
          dispatch(success(iac_data));
        },
        error => {
          error && dispatch(authAction.setTokenExpired(error, failure));
        }
      );
  };
  function request() { return { type: dashboardConstants.FETCH_DASHBOARD_CODE_SECURITY_REQUEST } }
  function success(iac_data) { return { type: dashboardConstants.FETCH_DASHBOARD_CODE_SECURITY_SUCCESS, iac_data } }
  function failure(error) { return { type: dashboardConstants.FETCH_DASHBOARD_CODE_SECURITY_FAILURE, error } }
}

function fetchComplianceInfrastructureListAction(provider, compliance) {

  return dispatch => {
    dispatch(request());
    dashboard.fetchComplianceInfrastructureList(provider, compliance)
      .then(
        summary_data => {
          dispatch(success(summary_data));
        },
        error => {
          error && dispatch(authAction.setTokenExpired(error, failure));
        }
      );
  };
  function request() { return { type: dashboardConstants.FETCH_COMPLIANCE_INFRALIST_REQUEST } }
  function success(summary_data) { return { type: dashboardConstants.FETCH_COMPLIANCE_INFRALIST_SUCCESS, summary_data } }
  function failure(error) { return { type: dashboardConstants.FETCH_COMPLIANCE_INFRALIST_FAILURE, error } }
}

function fetchResourceInfrastructureListAction(provider, resource) {

  return dispatch => {
    dispatch(request());
    dashboard.fetchResourceInfrastructureList(provider, resource)
      .then(
        summary_data => {
          dispatch(success(summary_data));
        },
        error => {
          error && dispatch(authAction.setTokenExpired(error, failure));
        }
      );
  };
  function request() { return { type: dashboardConstants.FETCH_RESOURCE_INFRALIST_REQUEST } }
  function success(summary_data) { return { type: dashboardConstants.FETCH_RESOURCE_INFRALIST_SUCCESS, summary_data } }
  function failure(error) { return { type: dashboardConstants.FETCH_RESOURCE_INFRALIST_FAILURE, error } }
}
