import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
  currentRole,
  getCurrentUser,
  colors,
} from "../../../helpers/constants";
import { ReactComponent as ThreeDotIcon } from "../../../assets/icons/three_dot_icon.svg";
import { ReactComponent as ArrowUp } from '../../../assets/icons/up_arrow_icon.svg'
import { ReactComponent as ArrowDown } from '../../../assets/icons/down_arrow_icon.svg'
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter_icon.svg'
import { ReactComponent as FilterClearIcon } from '../../../assets/icons/filter_clear_icon.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg'
import { ReactComponent as AddItemIcon } from '../../../assets/icons/add_item.svg'
import { ReactComponent as EditUserIcon } from '../../../assets/icons/user_edit_icon.svg'
import { ReactComponent as ChangeStatusIcon } from '../../../assets/icons/change_status_icon.svg'
import { ReactComponent as DeleteButtonIcon } from '../../../assets/icons/trash_icon.svg'

import AddEnvironmentPanel from '../../modals/AddEnvironmentPanel'
import CustomInput from '../../../components/Input/CustomInput'
import CommonSelect from '../../../components/Select/CommonSelect'
import StyledTypo from "../../../components/Typo/StyledTypo";
import StyledDropdown from "../../../components/Buttons/StyledDropdown";
import DropdownIcon from "../../../components/Buttons/DropdownIcon";
import { DropdownMenu } from "reactstrap";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import AddUserSlide from "./AddUserSlide";
import moment from 'moment'
import Switch from 'react-switch'
import AddEnvSlide from "./AddEnvSlide";

const TableWrapper = styled.div`
  .divider {
    margin: 8px -15px;
    //margin: 8px -46.5px 4px -37.5px;
    border-top: 1px solid rgba(164, 175, 183, 0.3);
    height: 0;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 26px;
`;

const EachCell = styled.div`
  width: ${(props) => props.width};
  padding-right: ${(props) => (props.pr ? props.pr : 0)}px;
  word-break: break-word;
`;

const RecordContainer = styled.div`
  border-top: 1px solid #fff;
  transition: border 500ms ease;
  background: ${props => (props.active ? '#f1f1f1' : 'transparent')};
  position: relative;

  .floating-button {
    display: none;
    z-index: 100;
  }
  &:hover .floating-button {
    display: block;
  }
`

const RecordWrapper = styled.div`
  padding: 11px 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .text-cell {
    color: #505050;
  }
  cursor: pointer;
  &:hover {
    border-radius: 5px;
    background-color: #f1f1f1;
  }
`;

const ActionDropdownMenu = styled(DropdownMenu)`
  // --width: ${(props) => props.width - 350}px;
  // left: calc(var(--width) - 50px - 1vw) !important;
  /* white */
  min-width: 150px;
  width: 200px;
  background: #ffffff;
  /* Gray light 2 */

  border: 1px solid #f1f5fa;
  box-sizing: border-box;
  /* box shadow */

  box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 10px;
  padding: 15px 20px;
  transform: translate3d(-150px, -10px, 0px) !important;

  .item:hover p {
    color: #66c4d5 !important;
  }
`;

const ShowingWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  position: fixed;
  right: 40px;
  bottom: 20px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100vw - 200px);
  background-color: #fff;

  .page-link:focus {
    box-shadow: none !important;
  }
  ul.pagination {
    margin-bottom: 0;
    font-size: 0.8rem;
    font-weight: 0.4;
    font-family: "Roboto";
  }
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`

const CustomizeFilterWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 15px 0;
  z-index: 100;

  .filter-menu {
    gap: 10px;
  }
`

const CustomizeFilterBodyContainer = styled.div`
  background: #fff;
  box-shadow: 0 26px 58px 0 rgba(0, 0, 0, 0.22),
    0 5px 14px 0 rgba(0, 0, 0, 0.18);
  // padding: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  z-index: 100;
  bottom: 0px;
  transform: translateY(105%);
  left: 0px;
  width: 100%;
  border: 1px solid rgb(234 234 234);
  border-left: 2px solid #5eade6;
`

const columns = [
  {
    title: "Organization Name",
    access: "organization_name",
    width: `${45000 / 1596}%`,
    sort: true,
    search: true,
    type: 'text',
  },
  {
    title: "Name",
    access: "name",
    width: `${35000 / 1596}%`,
    sort: true,
    search: true,
    type: "text",
  },
  {
    title: "Key",
    access: "key",
    width: `${25800 / 1596}%`,
    sort: true,
    search: true,
    type: "text",
  },
  {
    title: "Audit",
    access: "status",
    width: `${23600 / 1596}%`,
    sort: false,
    search: true,
    type: "switch",
  },
  {
    title: "Actions",
    access: 'actions',
    width: `${8400 / 1596}%`,
    sort: false,
    search: false,
    type: "action",
  },
]

const selectEmptyResourceType = {
  label: 'Select...',
  value: ''
}

const FilterPanel = props => {
  const {
    searchText,
    setSearchText,
    selectedOrg,
    setSelectedOrg,
    selectedRole,
    setSelectedRole,
    orgOptions,
    roleOptions,
    openPanel,
  } = props

  const [filterBarOpen, setFilterBarOpen] = useState(false)

  const clearFilter = () => {
    setSelectedOrg(selectEmptyResourceType)
    setSelectedRole(selectEmptyResourceType)
    setFilterBarOpen(false)
  }

  const checkFilterContent = () => {
    return !!selectedOrg?.value?.length || !!selectedRole?.value?.length
  }

  return (
    <CustomizeFilterWrapper>
      <div className='d-flex align-items-center justify-content-end'>
        <div className='d-flex align-items-center mr-3 filter-menu'>
          <a
            className='menu-icon'
            onClick={() => openPanel(true)}
            title='Add Environment'
            alt='Add Environment'
          >
            <AddItemIcon
              width={22}
              height={22}
              fill='#606060'
              className='svg-icon'
            />
            <span className='menu-tip'>Add Environment</span>
          </a>
          {/* <a
            className='menu-icon'
            onClick={() => setFilterBarOpen(!filterBarOpen)}
            title='Filter By'
            alt='Filter'
          >
            <FilterIcon
              width={22}
              height={22}
              fill='#606060'
              className='svg-icon'
            />
            <span className='menu-tip'>Filter by</span>
          </a>
          <a
            className={
              checkFilterContent() ? 'menu-icon' : 'menu-icon menu-disabled'
            }
            onClick={clearFilter}
            title='Filter Clear'
            alt='Filter Clear'
          >
            <FilterClearIcon
              width={22}
              height={22}
              fill='#606060'
              className='svg-icon'
            />
            <span className='menu-tip'>Filter clear</span>
          </a> */}
        </div>
        <div style={{display: 'none'}}>
          <CustomInput
            minWidth={350}
            placeholder='Search'
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            autoComplete="off"
          />
        </div>
        <div>
          <CustomInput
            minWidth={350}
            placeholder='Search'
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            autoComplete="off"
          />
        </div>
      </div>
      {filterBarOpen && (
        <CustomizeFilterBodyContainer>
          <CloseWrapper
            className='cursor-pointer'
            onClick={() => setFilterBarOpen(false)}
          >
            <CloseIcon width={15} stroke='#A4AFB7' />
          </CloseWrapper>
          <div className='ml-3' style={{ minWidth: 250 }}>
            <CommonSelect
              value={selectedOrg}
              setValue={setSelectedOrg}
              options={orgOptions}
              label={`Organization`}
            />
          </div>
          <div className='ml-3' style={{ minWidth: 250 }}>
            <CommonSelect
              value={selectedRole}
              setValue={setSelectedRole}
              options={roleOptions}
              label={`Role`}
            />
          </div>
        </CustomizeFilterBodyContainer>
      )}
    </CustomizeFilterWrapper>
  )
}


const TableHeader = ({ currentSort, setCurrentSort }) => {
  const handleSetSort = (access) => {
    setCurrentSort({
      access,
      order:
        (Array.isArray(currentSort.access)
          ? currentSort.access.join()
          : currentSort.access) ===
        (Array.isArray(access) ? access.join() : access)
          ? !currentSort.order
          : true,
    });
  };

  return (
    <HeaderWrapper>
      {columns.map((column, idx) => (
        <EachCell 
          width={column.width} key={`header-${idx}`}
          className={` ${
              column.title === "Results" ? "flex-column align-items-start" : ""
            }d-flex align-items-center ${column.sort ? "cursor-pointer" : ""} ${
              column.title === "Action" ? "justify-content-end" : ""
            } `}
          pr={10}
          onClick={() => {
            if (column.sort) {
              handleSetSort(column.access);
            }
          }}
        >
          {column.sort &&
            (Array.isArray(currentSort?.access)
              ? currentSort?.access.join()
              : currentSort?.access) ===
              (Array.isArray(column.access)
                ? column.access.join()
                : column.access) ? (
              currentSort.order ? (
                <ArrowUp fill={"#A4AFB7"} className="mr-3" />
              ) : (
                <ArrowDown fill={"#A4AFB7"} className="mr-3" />
              )
            ) : (
              <div />
            )}
          <StyledTypo 
            color={"#505050"}
            size={0.8}
            line={1.2}
            letter="0"
            family="Roboto"
            fontWeight={400}>
              {column.title}
          </StyledTypo>
        </EachCell>
      ))}
    </HeaderWrapper>
  );
};

const Record = (props) => {
  const {
    data,
    isOpenActionMenu,
    toggleActionMenu,
    handleRemoveEnv,
    handleChangeStatus,
    handleEditEnv,
    orgScanSetting,
    updateOrgScanSettingAction
  } = props;

  const [showEditModal, setShowEditModal] = useState(false);

  const handleChangeScanSetting = (checked) => {
    const modifiedEnv = !checked ? 
      [...(orgScanSetting?.environment ?? []).filter(item => data.key !== item), data.key] : 
      [...(orgScanSetting?.environment ?? []).filter(item => data.key !== item)];
    const modifiedScanSetting = {...(orgScanSetting ?? {}), environment: modifiedEnv}

    updateOrgScanSettingAction(modifiedScanSetting);
  }

  const deleteConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>{`You want to delete environment "${data.name}"?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                data.key && handleRemoveEnv(data.key);
                onClose();
              }}
            >
              Confirm
            </button>
          </div>
        );
      },
    });
  };
  return (
    <RecordContainer>
      <RecordWrapper>
        {columns.map((column, idx) => (
          <EachCell width={column.width}>
            {column.type === 'text' && (
              <StyledTypo
                className="text-cell"
                // onClick={handleSelectRow}
                size={0.9}
                line={1.4}
                letter="0"
                >
                {data?.[column.access]}
              </StyledTypo>
            )}
            {column.type === 'switch' && (
              <Switch
                onChange={(checked) =>
                  handleChangeScanSetting(checked)
                }
                checked={!(orgScanSetting?.environment ?? []).includes(data.key)}
                onColor="#65C2D7"
                offColor="#ff487f"
              />
            )}
            {column.type === 'date' && (
              <StyledTypo
                className="text-cell"
                // onClick={handleSelectRow}
                size={0.9}
                line={1.4}
                letter="0"
              >
                {moment(data?.[column.access]).format('YYYY-MM-DD hh:mm')}
              </StyledTypo>
            )}
            {column.type === 'action' && data?.id !== getCurrentUser()?.UUID && (
              <div className="text-right">
                <StyledDropdown
                  isOpen={isOpenActionMenu.filter((item) => item[data.id]).length}
                  toggle={() => toggleActionMenu(data.id)}
                >
                  <DropdownIcon>
                    <ThreeDotIcon />
                  </DropdownIcon>
                  <ActionDropdownMenu>
                    <StyledDropdown
                      isOpen={showEditModal}
                      toggle={() => setShowEditModal((showEditModal) => !showEditModal)}
                    >
                      <DropdownIcon>
                        <div className="d-flex mb-2 align-items-center cursor-pointer">
                          <EditUserIcon fill="#485DCD" width={20} />
                          <StyledTypo className="ml-3">Edit</StyledTypo>
                        </div>
                      </DropdownIcon>
                      <AddEnvironmentPanel
                        editData={data}
                        handleEditEnv={handleEditEnv}
                        toggle={(status) => setShowEditModal(status)}
                      />
                    </StyledDropdown>
                    <div
                      className="d-flex mb-2 align-items-center cursor-pointer"
                      onClick={() => {
                        toggleActionMenu(data.key);
                        handleChangeStatus(data.key);
                      }}
                    >
                      <ChangeStatusIcon fill="#485DCD" width={20} />
                      <StyledTypo className="ml-3">
                        {data.status === "active" ? "Disable" : "Enable"}
                      </StyledTypo>
                    </div>
                    <div
                      className="d-flex mb-2 align-items-center cursor-pointer"
                      onClick={() => deleteConfirm()}
                    >
                      <DeleteButtonIcon fill={colors.red} width={20} />
                      <StyledTypo className="ml-3">Remove</StyledTypo>
                    </div>
                  </ActionDropdownMenu>
                </StyledDropdown>
              </div>
            )}
          </EachCell>
        ))}
      </RecordWrapper>
    </RecordContainer>
  );
};

const EnvironmentTable = (props) => {
  const { data: environments, organizations, handleRemoveEnv, handleChangeStatus, handleEditEnv, updateOrgScanSettingAction, addNewEnvAction, envData } =
    props;
  
  const [isOpenActionMenu, setIsOpenActionMenu] = useState([]);
  const [data, setData] = useState([]);
  const [currentSort, setCurrentSort] = useState({ access: '', order: true })
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpenAddPanel, setIsOpenAddPanel] = useState(false)

  const [searchText, setSearchText] = useState(null)
  const [selectedOrg, setSelectedOrg] = useState(selectEmptyResourceType)
  const [selectedRole, setSelectedRole] = useState(selectEmptyResourceType)
  const [orgOptions, setOrgOptions] = useState([])
  const [roleOptions, setRoleOptions] = useState([])

  const itemPerPage = 15
  const handleSort = () => {
    const retOrder = currentSort.order ? 1 : -1
    setData(data => [
      ...data.sort((a, b) => {
        const aCompare = Array.isArray(currentSort.access)
          ? currentSort.access.map(access => a[access]).join(' ')
          : a[currentSort.access]
        const bCompare = Array.isArray(currentSort.access)
          ? currentSort.access.map(access => b[access]).join(' ')
          : b[currentSort.access]
        return aCompare > bCompare ? retOrder : -1 * retOrder
      })
    ])
  }

  useEffect(() => {
    setOrgOptions([
      selectEmptyResourceType, 
      ...(organizations ?? [])?.map(org => ({
      label: org.name,
      value: org.name
    }))])
  }, [organizations])

  // useEffect(() => {
  //   setRoleOptions([
  //     selectEmptyResourceType, 
  //     ...[...new Set(userList.map(user => user.role))]?.map(role => ({
  //     label: role,
  //     value: role
  //   }))])
  // }, [userList])


  useEffect(() => {
    if (environments && Object.keys(environments).length) {
      const skipScanSetting = environments?.org_setting?.skip_scan
      const envs = [
        ...environments['environments'].map((env, idx) => ({
          id: idx + 1,
          key: env.key,
          name: env.name,
          organization_name: environments.organization_name,
          status: env.is_active ? 'active' : 'inactive',
          auditEnabled: (skipScanSetting?.environment ?? []).includes(env.key)
        }))
      ]
      setData(envs)
      setCurrentSort(currentSort =>
        currentSort.access.length === 0
          ? {
              access: columns[0].access,
              order: true
            }
          : { ...currentSort }
      )
      setIsOpenActionMenu([...envs.map(item => ({ [item.id]: false }))])
    }
  }, [environments])


  useEffect(() => {
    handleSort();
  }, [currentSort]);

  const toggleActionMenu = (key) => {
    setIsOpenActionMenu(
      isOpenActionMenu.map((item) => ({ ...item, [key]: !item[key] }))
    );
  };

  const handlePageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const filteredData = useCallback(() => {
    let filteredData = [...data];
    if (searchText && searchText.length) {
      const filters = columns.filter((item) => item.search);
      filteredData = [
        ...filteredData.filter(
          (item) =>
            !!filters.filter(
              (col) =>
                col.search &&
                item[col.access]
                  .toLowerCase()
                  .includes(searchText.trim().toLowerCase())
            ).length
        ),
      ];
    }

    // if (selectedOrg && selectedOrg.value.length) {
    //   filteredData = [
    //     ...filteredData.filter(
    //       (item) =>
    //         item.organization_name.toLowerCase() === selectedOrg.value.toLowerCase()
    //     ),
    //   ];
    // }
    // if (selectedRole && selectedRole.value.length) {
    //   filteredData = [
    //     ...filteredData.filter(
    //       (item) =>
    //         item.role.toLowerCase() === selectedRole.value.toLowerCase()
    //     ),
    //   ];
    // }
    if (
      currentPage !== 1 &&
      currentPage > filteredData.length / itemPerPage + 1
    ) {
      setCurrentPage(1);
    }
    return filteredData;
  }, [
    data,
    currentPage,
    // selectedOrg,
    // selectedRole,
    searchText
  ]);

  const pageFilter = useCallback(
    (data) => {
      return data.filter(
        (item, idx) =>
          idx >= itemPerPage * (currentPage - 1) &&
          idx < itemPerPage * currentPage
      );
    },
    [currentPage]
  );

  const handleChange = (id) => {
    setData((data) =>
      data.map((item) => ({
        ...item,
        selected: item.id === id ? !item.selected : item.selected,
      }))
    );
  };

  return (
    <TableWrapper>
      <FilterPanel
        openPanel={setIsOpenAddPanel}
        setSearchText={setSearchText}
        searchText={searchText}
        selectedOrg={selectedOrg}
        setSelectedOrg={setSelectedOrg}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        orgOptions={orgOptions}
        roleOptions={roleOptions}
      />
      <TableHeader 
        currentSort={currentSort}
        setCurrentSort={setCurrentSort}
      />
      <div className="divider" />
      {filteredData().length ? (
        pageFilter(filteredData()).map((env) => (
          <Record
            data={env}
            isOpenActionMenu={isOpenActionMenu}
            toggleActionMenu={toggleActionMenu}
            handleChange={handleChange}
            handleRemoveEnv={handleRemoveEnv}
            handleChangeStatus={handleChangeStatus}
            handleEditEnv={handleEditEnv}
            orgScanSetting={environments?.org_setting?.skip_scan ?? {}}
            updateOrgScanSettingAction={updateOrgScanSettingAction}
          />
        ))
      ) : (
        <div className="text-center my-5">
          <StyledTypo>No environment</StyledTypo>
        </div>
      )}
      <ShowingWrapper>
        <StyledTypo
          size={0.8}
          line={1.6}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {`Showing ${
            itemPerPage * (currentPage - 1) + 1 > filteredData().length
              ? filteredData().length
              : itemPerPage * (currentPage - 1) + 1
          } - ${
            itemPerPage * currentPage > filteredData().length
              ? filteredData().length
              : itemPerPage * currentPage
          } from ${filteredData().length} Results`}
        </StyledTypo>
        <ReactPaginate
          pageCount={(filteredData()?.length ?? 0) / itemPerPage}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          previousLabel="< Prev"
          nextLabel="Next >"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          forcePage={currentPage - 1}
          onPageChange={handlePageChange}
          activeClassName="active"
        />
      </ShowingWrapper>
      <AddEnvSlide addNewEnvAction={addNewEnvAction} envData={envData} isOpen={isOpenAddPanel} closePanel={() => setIsOpenAddPanel(false)} />
      {/* <AddUserSlide organizations={organizations} users={userList} isOpen={isOpenAddPanel} closePanel={() => setIsOpenAddPanel(false)} /> */}
    </TableWrapper>
  );
};

export default EnvironmentTable;
