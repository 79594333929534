import React, { useEffect, useState, useCallback } from "react";
import StyledTypo from "../../../../components/Typo/StyledTypo";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/cancel.svg";
import "react-modern-drawer/dist/index.css";
import { Col, Row } from "reactstrap";
import SlidePanel from "../../../../components/SlidePanel/SlidePanel";
import { ReactComponent as LeftArrow } from "../../../../assets/icons/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../../../assets/icons/right-arrow.svg";
import { ReactComponent as ExpandIcon } from "../../../../assets/icons/expand.svg";
import { Badge } from "reactstrap";

import moment from "moment";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { coldarkCold } from "react-syntax-highlighter/dist/esm/styles/prism";

import { parse } from "marked";
import CommonSelect from '../../../../components/Select/CommonSelect'

const ResultTableBodyWrapper = styled.div`
  padding: 10px;
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #eaeaea;
  height: 150px;
  min-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  resize: vertical;
`;

const ResultTableHeaderWrapper = styled.div`
  padding: 10px;
  padding-bottom: 0px;
  background: #f4f4f4;
  border: 1px solid #eaeaea;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const DividerWrapper = styled.div`
  height: 0;
  width: calc(100% + 60px);
  margin-left: -30px;
  border-bottom: 0.5px solid #ccc;
`;

const CloseWrapper = styled.div`
  border-radius: 10px;
  min-width: 45px;
  min-height: 40px;
  cursor: pointer;
  max-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CodeWrapper = styled.div`
  padding: 10px;
  width: 100%;
  word-break: break-word;
  border-radius: 5px;
  // background: #f6f6f6;
  // box-shadow: inset 0 2px 5px #ccc;
  border: 1px solid #ccc;
  margin-top: 16px;

  h1 {
    font-size: 1.2rem !important;
  }

  & h2 {
    font-size: 1rem !important;
  }

  & h3 {
    font-size: 0.9rem !important;
  }

  & h4 {
    font-size: 0.85rem !important;
  }

  & h5 {
    font-size: 0.8rem !important;
  }

  pre {
    word-break: break-all;
    overflow-x: hidden;
    width: fit-content;
    color: #495057 !important;
    background: #0000001a !important;
    padding: 5px;
    margin-left: 35px;
  }

  .cloud-manual-rememdiation-content {
    color: #505050 !important;
    font-size: 0.75rem;
    line: 1.4;
    letter: 0;
    font-family: Roboto;
    font-weight: 300;
  }
`;

const CodeZoomWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  // align-items: center;
  cursor: pointer;
`;

const ZoomCodeWrapper = styled(CodeWrapper)`
  height: calc(100vh - 205px);
`;

const TableWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const ScrollWrapper = styled.div`
  height: 255px;
  min-height: 255px;
  overflow: auto;
  padding-right: 10px;
  padding-bottom: 10px;
  margin: 10px;
  word-break: break-word;
`;

const ZoomScrollWrapper = styled.div`
  height: 99%;
  max-height: 99%;
  overflow: auto;
  padding-right: 10px;
  padding-bottom: 10px;
  margin: 10px;
  word-break: break-all;
`;

const MoveCTAWrapper = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  // align-items: center;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  cursor: pointer;
`;

const SeverityWrapper = styled.span`
  position: absolute;
  left: calc(100% - 240px);
`;

const TitleWrapper = styled.span`
  display: inline-block;
  width: 58%;
  height: 1.4em;
`;

const CategoryContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 10px;
  justify-content: flex-end;
`

const emptyOption = { label: 'Select...', value: '' }

const CloudDetails = (props) => {
  const {
    data,
    csp,
    isOpen,
    toggle,
    setOpenItemId,
    manual_cloud_remediations,
    findIndex,
    findId,
    projectsCnt,
  } = props;
  const [resources, setResources] = useState([]);
  const [codeZoom, setCodeZoom] = useState(false);

  const [selectedRegion, setSelectedRegion] = useState(emptyOption)
  const [selectedStatus, setSelectedStatus] = useState(emptyOption)

  const [regionOptions, setRegionOptions] = useState([emptyOption])
  const [statusOptions, setStatusOptions] = useState([emptyOption])

  const ActiveCodeWrapper = codeZoom ? ZoomCodeWrapper : CodeWrapper;
  const ActiveScrollWrapper = codeZoom ? ZoomScrollWrapper : ScrollWrapper;

  useEffect(() => {
    const regions = [...new Set((data?.templates ?? [])?.map(res => csp === 'aws' ? res?.region : getRegion(csp, res?.resource)))]
    const statusList = ['pass', 'fail']

    setRegionOptions([emptyOption, ...regions.map(region => ({
      label: region,
      value: region
    }))])
    setStatusOptions([emptyOption, ...statusList.map(status => ({
      label: status,
      value: status
    }))])
  }, [data])

  const filteredData = useCallback(() => {
    let res = data?.templates ?? []
    if(selectedRegion.value !== '') {
      res = res.filter(each => selectedRegion.value === (csp === 'aws' ? each?.region : getRegion(csp, each?.resource)))
    }
    if(selectedStatus.value !== '') {
      res = res.filter(each => each?.status === selectedStatus.value)
    }
    return res;
  }, [
    data,
    selectedRegion,
    selectedStatus,
    csp
  ])

  const getResource = (csp, res_id) => {
    let result = res_id;
    let arr1 = res_id.split("projects");
    if (arr1 && arr1.length > 1) {
      result = arr1[1];
    }
    return result;
  };

  const getRegion = (csp, res_id) => {
    let result = "global";
    let arr1 = res_id.split("projects");
    if (arr1 && arr1.length > 1) {
      let arr2 = arr1[1]?.split("/");
      if (arr2 && arr2.length) {
        result =
          arr2[2] === "regions" || arr2[2] === "locations" ? arr2[3] : result;
      }
    }
    return result;
  };

  useEffect(() => {
    if (data?.templates) {
      let temp = {};
      data?.templates?.forEach((item) => {
        let rsc_names = item?.invalid?.resource ?? item?.resource_id;
        /* updated 11-18-2022 */
        if (!rsc_names) return;
        if (!Array.isArray(rsc_names)) {
          rsc_names = [rsc_names];
        }
        rsc_names.forEach((name) => {
          temp[name] = {
            ...item,
            invalid: { ...(item?.invalid || {}), resource: name },
          };
        });
      });
      setResources(
        Object.keys(temp).map((key) => ({ ...temp[key], selected: true }))
      );
    } else {
      setResources([]);
    }
  }, [data]);

  console.log(data, "==== data")

  const getDescription = (description) => {
    return typeof description === 'object' ? `${Object.keys(description)?.[0]} ${Object.values(description)?.[0]}` : description; 
  }

  return (
    <SlidePanel isOpen={isOpen} toggle={toggle} slideId="cloud-detail-slide">
      <div className="p-3 position-relative mb-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <CloseWrapper className="cursor-pointer" onClick={toggle}>
            <CloseIcon width={22} fill="#505050" />
          </CloseWrapper>
          <SeverityWrapper>
            <Badge
              color={data?.severity?.toLowerCase() ?? "low"}
              pill
            >
              Resources:{" "}
              {data?.templates?.filter(
                (res) =>
                  res.status.toLowerCase() === "fail" ||
                  res.status.toLowerCase() === "info"
              ).length ?? 0}{" "}
              {" / "} {data?.templates?.length ?? 0}
            </Badge>
          </SeverityWrapper>
          <TitleWrapper>
            <StyledTypo className="text-center heading ln-2">
              {data?.name}
            </StyledTypo>
          </TitleWrapper>
          <div className="d-flex align-items-center">
            <StyledTypo className="mr-2" size="0.8">
              {findIndex() + 1} of {projectsCnt}
            </StyledTypo>
            <MoveCTAWrapper
              disabled={findIndex() === 0}
              onClick={() => setOpenItemId(findId(false))}
            >
              <LeftArrow fill={findIndex() === 0 ? "#A4AFB7" : "#505050"} />
            </MoveCTAWrapper>
            <MoveCTAWrapper
              disabled={findIndex() + 1 === projectsCnt}
              onClick={() => setOpenItemId(findId(true))}
            >
              <RightArrow
                fill={findIndex() + 1 === projectsCnt ? "#A4AFB7" : "#505050"}
              />
            </MoveCTAWrapper>
          </div>
        </div>
        <DividerWrapper className="mb-3" />
        {!codeZoom && (
          <Row>
            <Col md={12} className="position-static d-flex p-0">
              <Col md={2}>
                <StyledTypo className="mt-2 mr-3 label">Description</StyledTypo>
              </Col>
              <Col md={10}>
                <StyledTypo className="mt-2">{getDescription(data?.description)}</StyledTypo>
              </Col>
            </Col>
            <Col md={12} className="position-static d-flex p-0">
              <Col md={2}>
                <StyledTypo className="mt-2 mr-3 label">
                  Detection Timestamp
                </StyledTypo>
              </Col>
              <Col md={4}>
                <StyledTypo className="mt-2">
                  {data?.scan_date
                    ? moment(data?.scan_date).format("YYYY-MM-DD HH:mm")
                    : "--"}
                </StyledTypo>
              </Col>
            </Col>
            {data?.remediation_cli && (
              <Col
                md={12}
                className="position-static d-flex p-0 align-items-center"
              >
                <Col md={2}>
                  <StyledTypo className="mt-2 mr-3 label">
                    Remediation CLI
                  </StyledTypo>
                </Col>
                <Col md={10}>
                  <SyntaxHighlighter
                    language="bash"
                    code={data?.remediation_cli}
                    wrapLongLines={true}
                    style={coldarkCold}
                  />
                </Col>
              </Col>
            )}
            {data?.recommendation_url && (
              <Col md={12} className="position-static d-flex p-0">
                <Col md={2}>
                  <StyledTypo className="mt-2 mr-3 label">
                    Recommendation URL
                  </StyledTypo>
                </Col>
                <Col md={10}>
                  <a href={data?.recommendation_url} target="_blank">
                    <StyledTypo className="mt-2">
                      {data?.recommendation_url}
                    </StyledTypo>
                  </a>
                </Col>
              </Col>
            )}
            {data?.recommendation_txt && (
              <Col md={12} className="position-static d-flex p-0">
                <Col md={2}>
                  <StyledTypo className="mt-2 mr-3 label">
                    Recommendation Text
                  </StyledTypo>
                </Col>
                <Col md={10}>
                  <StyledTypo className="mt-2">
                    {data?.recommendation_txt}
                  </StyledTypo>
                </Col>
              </Col>
            )}
          </Row>
        )}
        <CategoryContainer>
          <div style={{ minWidth: 200 }}>
            <CommonSelect
              value={selectedRegion}
              setValue={setSelectedRegion}
              options={regionOptions}
              label={`Region`}
            />
          </div>
          <div className="ml-3" style={{ minWidth: 200 }}>
            <CommonSelect
              value={selectedStatus}
              setValue={setSelectedStatus}
              options={statusOptions}
              label={`Status`}
            />
          </div>
        </CategoryContainer>
        <TableWrapper>
          {!codeZoom && (
            <>
              <ResultTableHeaderWrapper>
                <Row className="text-break mb-2">
                  <Col xs={1}>
                    <StyledTypo className="label table">#</StyledTypo>
                  </Col>
                  <Col xs={2}>
                    <StyledTypo className="label table">Account</StyledTypo>
                  </Col>
                  <Col xs={2}>
                    <StyledTypo className="label table">Region</StyledTypo>
                  </Col>
                  <Col xs={6}>
                    <StyledTypo className="label table">Resource ID</StyledTypo>
                  </Col>
                  <Col xs={1}>
                    <StyledTypo className="label table">Status</StyledTypo>
                  </Col>
                </Row>
              </ResultTableHeaderWrapper>
              <ResultTableBodyWrapper>
                {filteredData()?.map((res, idx) => (
                  <Row
                    className={`text-break py-2 ${
                      idx % 2 === 0 ? "bg-white" : ""
                    }`}
                    style={{ backgroundColor: "#f7f7f7" }}
                  >
                    <Col xs={1}>
                      <StyledTypo className="mt-1 table">{idx + 1}</StyledTypo>
                    </Col>
                    <Col xs={2}>
                      <StyledTypo className="mt-1 table">
                        {res?.account}
                      </StyledTypo>
                    </Col>
                    <Col xs={2}>
                      <StyledTypo className="mt-1 table">
                        {csp === "aws"
                          ? res?.region
                          : getRegion(csp, res?.resource)}
                      </StyledTypo>
                    </Col>
                    <Col xs={6}>
                      <StyledTypo className="mt-1 table">
                        {csp === "aws"
                          ? res?.resource_id
                          : getResource(csp, res?.resource)}
                      </StyledTypo>
                    </Col>
                    <Col xs={1}>
                      <StyledTypo className="mt-1 table">
                        {res?.status}
                      </StyledTypo>
                    </Col>
                  </Row>
                ))}
              </ResultTableBodyWrapper>
            </>
          )}
        </TableWrapper>
        <CodeZoomWrapper onClick={() => setCodeZoom(!codeZoom)}>
          <ExpandIcon fill="#aeaeae" />
        </CodeZoomWrapper>
        <div className="mt-1">
          <StyledTypo className="label">Remediation</StyledTypo>
        </div>
        <Row>
          <Col md={12}>
            <ActiveCodeWrapper>
              <ActiveScrollWrapper>
                {manual_cloud_remediations?.[data?.id] ? (
                  <div
                    className="cloud-manual-rememdiation-content"
                    dangerouslySetInnerHTML={{
                      __html: parse(
                        manual_cloud_remediations?.[data?.id]
                      ),
                    }}
                  />
                ) : (
                  <></>
                )}
              </ActiveScrollWrapper>
            </ActiveCodeWrapper>
          </Col>
        </Row>
      </div>
    </SlidePanel>
  );
};

export default CloudDetails;
