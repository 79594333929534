import { Graph } from "react-d3-graph";
import { config1 as graphConfig } from "../graph-configs";

const Sample05 = (props) => {
    // graph payload (with minimalist structure)
    const graphData = {
        nodes: [
            { id: 1, name: "Attacker", x: 200, y: 200, svg: './images/Attacker.png' },
            { id: 2, name: "3rd Party Access", x: 300, y: 200 },
            { id: 3, name: "Cloud Account", x: 400, y: 200 },
            { id: 4, name: "EC2 No internet", x: 500, y: 150 },
            { id: 5, name: "Identity (User/Role)", x: 500, y: 250 },
            { id: 6, name: "Permissions", x: 600, y: 200 },
        ],
        links: [
            { source: 1, target: 2 },
            { source: 2, target: 3 },
            { source: 3, target: 4 },
            { source: 3, target: 5 },
            { source: 4, target: 6 },
            { source: 5, target: 6 },
        ]
    };

    // Callback to handle click on the graph.
    // @param {Object} event click dom event
    const onClickGraph = function (event) {
        console.log('Clicked the graph background');
    };

    const onClickNode = function (nodeId, node) {
        console.log(`Clicked node ${nodeId} in position (${node.x}, ${node.y})`);
    };

    const onDoubleClickNode = function (nodeId, node) {
        console.log(`Double clicked node ${nodeId} in position (${node.x}, ${node.y})`);
    };

    const onRightClickNode = function (event, nodeId, node) {
        console.log(`Right clicked node ${nodeId} in position (${node.x}, ${node.y})`);
    };

    const onMouseOverNode = function (nodeId, node) {
        console.log(`Mouse over node ${nodeId} in position (${node.x}, ${node.y})`);
    };

    const onMouseOutNode = function (nodeId, node) {
        console.log(`Mouse out node ${nodeId} in position (${node.x}, ${node.y})`);
    };

    const onClickLink = function (source, target) {
        console.log(`Clicked link between ${source} and ${target}`);
    };

    const onRightClickLink = function (event, source, target) {
        console.log(`Right clicked link between ${source} and ${target}`);
    };

    const onMouseOverLink = function (source, target) {
        console.log(`Mouse over in link between ${source} and ${target}`);
    };

    const onMouseOutLink = function (source, target) {
        console.log(`Mouse out link between ${source} and ${target}`);
    };

    const onNodePositionChange = function (nodeId, x, y) {
        console.log(`Node ${nodeId} moved to new position x= ${x} y= ${y}`);
    };

    // Callback that's called whenever the graph is zoomed in/out
    // @param {number} previousZoom the previous graph zoom
    // @param {number} newZoom the new graph zoom
    const onZoomChange = function (previousZoom, newZoom) {
        console.log(`Graph is now zoomed at ${newZoom} from ${previousZoom}`);
    };

    let myConfig = JSON.parse(JSON.stringify(graphConfig));
    myConfig.staticGraph = props.settings.staticGraph;
    myConfig.initialZoom = 1.2;

    myConfig.d3.disableLinkForce = true;

    myConfig.node.size = 500;
    myConfig.node.fontSize = 10;
    myConfig.node.highlightFontSize = 10;

    myConfig.link.strokeWidth = 1.5;
    myConfig.link.markerHeight = 4;
    myConfig.link.markerWidth = 4;

    return (
        <>
            Attack Path Visualization: <em>Third party account access leading to privilege escalation</em>
            <div class="box">
                <Graph
                    id="graph-03" // id is mandatory
                    data={graphData}
                    config={myConfig}
                    onClickGraph={onClickGraph}
                    onClickNode={onClickNode}
                    onDoubleClickNode={onDoubleClickNode}
                    onRightClickNode={onRightClickNode}
                    onClickLink={onClickLink}
                    onRightClickLink={onRightClickLink}
                    onMouseOverNode={onMouseOverNode}
                    onMouseOutNode={onMouseOutNode}
                    onMouseOverLink={onMouseOverLink}
                    onMouseOutLink={onMouseOutLink}
                    onNodePositionChange={onNodePositionChange}
                    onZoomChange={onZoomChange}
                />
            </div>
        </>
    );
}

export default Sample05;