import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import { planList } from "../../helpers/constants";
import StyledTypo from "../../components/Typo/StyledTypo";
import EffectedButton from "../../components/Buttons/EffectedButton";
import React, { useState } from "react";

const ModalWrapper = styled.div``;

const ModalContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
`;

const PlanWrapper = styled.div`
  //background-color: #d1d8e0;
  background: ${(props) =>
    props.disabled ? "#f8f8f8" : `${props.badgeColor}33`};
  width: 30%;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  padding: 20px;

  cursor: ${(props) => (!props.disabled ? "default" : "pointer")};
  &:hover {
    box-shadow: ${(props) =>
      props.disabled
        ? "0 5px 5px rgba(0,0,0,.30)"
        : "0 5px 5px rgba(0,0,0,.15)"};
  }

  .badge {
    border-radius: 15px;
    background: ${(props) => props.badgeColor};
    padding: 5px 10px;
    position: absolute;
    left: 10px;
    top: 20px;
  }

  .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    top: -10px;
    right: -10px;
  }
  .ribbon__content {
    left: -25px;
    top: 35px;
    //-webkit-transform: rotate(45deg);
    //-ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    display: block;
    width: 240px;
    padding: 10px 0;
    background-color: ${(props) =>
      props.badgeColor ? props.badgeColor : "#f5cd79"};
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.192);
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
    border: 2px dotted #fff;
    outline: 5px solid
      ${(props) => (props.badgeColor ? props.badgeColor : "#f5cd79")};
    font-family: "Roboto";
    font-size: 0.75rem;
    font-weight: 400;
  }
  .ribbon::before {
    top: 0;
    left: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid
      ${(props) => (props.foldColor ? props.foldColor : "#f19066")};
    box-sizing: content-box;
  }
  .ribbon::after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid
      ${(props) => (props.foldColor ? props.foldColor : "#f19066")};
  }
  .horizontal-divider {
    border-top: 1px solid #d2d2d2;
    width: calc(100% + 40px);
    height: 0;
    margin: 30px 0 40px -20px;
  }

  .feature-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 7px 0;
    flex-wrap: wrap;
  }

  .feature-container {
    height: 250px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #ccc;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #64748b;
      //box-shadow: inset 0 0 5px #eee;
      border-radius: 5px;
    }
  }
`;

const AcceptButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 35px 10px;
`;

const PlanModal = (props) => {
  const { toggle, handleAccept } = props;
  const [selectedPlan, setSelectedPlan] = useState(planList[0].id);

  return (
    <ModalWrapper>
      <Modal
        isOpen={props.modal}
        className="plan-modal"
        fade={true}
        toggle={toggle}
        style={{ background: "transparent" }}
      >
        <ModalBody>
          <div className="d-flex justify-content-center">
            <StyledTypo
              className="mb-5 pb-3 mt-5"
              color="#505050"
              size={2}
              line={1.4}
              letter="0"
              family="Roboto"
              fontWeight={500}
            >
              Choose a plan
            </StyledTypo>
          </div>
          <ModalContent>
            {planList.map((plan) => (
              <PlanWrapper
                badgeColor={plan.color}
                foldColor={plan.foldColor}
                disabled={plan.id !== selectedPlan}
                onClick={() => setSelectedPlan(plan.id)}
              >
                <div className="ribbon">
                  <span className="ribbon__content">{plan.title}</span>
                </div>
                {plan.id === selectedPlan && (
                  <div className="badge">
                    <StyledTypo
                      color="#fff"
                      size={0.75}
                      line={1.4}
                      letter="0"
                      family="Poppins"
                      fontWeight={400}
                    >
                      Selected
                    </StyledTypo>
                  </div>
                )}
                <div className="d-flex align-items-end mb-4 mt-5 justify-content-center">
                  <StyledTypo
                    color="#505050"
                    size={1.75}
                    line={1.4}
                    letter="0"
                    family="Roboto"
                    fontWeight={700}
                  >{`${plan.noUnit ? "" : "$"}${plan.price}`}</StyledTypo>
                  <StyledTypo
                    className="ml-2 pb-2"
                    color="#50505099"
                    size={0.875}
                    line={1.4}
                    letter="0"
                    family="Roboto"
                    fontWeight={400}
                  >
                    {plan.noUnit ? "" : `/mo`}
                  </StyledTypo>
                </div>
                <div className="text-center mb-4">
                  <StyledTypo
                    color="#505050"
                    size={0.875}
                    line={1.4}
                    letter="0"
                    family="Roboto"
                    fontWeight={400}
                  >
                    {plan.description}
                  </StyledTypo>
                </div>

                {/*<EffectedButton width="100%" background="#65C2D7" padding={0} height={45} noSpan disabled={!plan.active}>*/}
                {/*  <StyledTypo className="ml-2" color="#fff" size={1.25} line={1.4} letter="0" family="Roboto" fontWeight={500}>Purchase Now</StyledTypo>*/}
                {/*</EffectedButton>*/}

                <div className="horizontal-divider" />
                <div className="feature-container">
                  {plan.features
                    .filter((feature) => feature.active)
                    .map((feature) => (
                      <div>
                        <div className="feature-wrapper">
                          <StyledTypo
                            color="#505050"
                            size={0.875}
                            line={1.4}
                            letter="0"
                            family="Roboto"
                            fontWeight={500}
                          >
                            {feature.name}
                          </StyledTypo>
                          {!!feature.value.length && (
                            <StyledTypo
                              color="#505050"
                              size={0.75}
                              line={1.4}
                              letter="0"
                              family="Poppins"
                              fontWeight={200}
                            >
                              {feature.value}
                            </StyledTypo>
                          )}
                        </div>
                        {feature.children && (
                          <div className="pl-3">
                            {feature.children.map((each) => (
                              <StyledTypo
                                className="pb-2"
                                color="#505050"
                                size={0.75}
                                line={1.4}
                                letter="0"
                                family="Roboto"
                                fontWeight={300}
                              >
                                {each}
                              </StyledTypo>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </PlanWrapper>
            ))}
          </ModalContent>
          <AcceptButtonWrapper>
            <EffectedButton
              width="150px"
              marginLeft="0"
              background="#65C2D7"
              padding={0}
              height={40}
              noSpan
              onClick={() => handleAccept(selectedPlan)}
            >
              <StyledTypo
                className="ml-2"
                color="#fff"
                size={0.875}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={500}
              >
                Accept
              </StyledTypo>
            </EffectedButton>
          </AcceptButtonWrapper>
        </ModalBody>
      </Modal>
    </ModalWrapper>
  );
};

export default PlanModal;
