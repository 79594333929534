import React from "react";
import styled from "styled-components";

import { applicationActions } from "../../../redux/actions";
import { connect } from "react-redux";
import StyledTypo from "../../../components/Typo/StyledTypo";
import EffectedButton from "../../../components/Buttons/EffectedButton";

import { ReactComponent as BillingIcon } from "../../../assets/icons/billing_icon.svg";
import moment from "moment";

const PanelWrapper = styled.div`
  padding: 42.5px 46.5px 42.5px 37.5px;
  width: 100%;
`;

const PanelHeader = styled.div`
  padding: 40px 0 47px;
`;

const NextBillingWrapper = styled.div`
  min-height: 355px;
  width: 442px;
  /* white */

  background: #ffffff;
  /* Gray light 2 */

  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  /* box shadow */

  box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 20px;
  padding: 28px 34px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 32px;
`;

const PlanDetailsWrapper = styled.div`
  width: 610px;
  min-height: 355px;
  /* white */

  background: #ffffff;
  /* Gray light 2 */

  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  /* box shadow */

  box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 20px;
  padding: 28px 34px;
`;

const BillingIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 184, 0, 0.2);
  border-radius: 10px;
  width: 45px;
  height: 45px;
`;

const BillingDataPanel = (props) => {
  return (
    <PanelWrapper>
      <PanelHeader>
        <StyledTypo
          color="#485DCD"
          size={2.25}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={700}
        >
          Billing Data
        </StyledTypo>
      </PanelHeader>
      <div className="d-flex">
        <NextBillingWrapper>
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between">
              <StyledTypo
                color="#A4AFB7"
                size={2.25}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                Next Billing
              </StyledTypo>
              <BillingIconWrapper>
                <BillingIcon />
              </BillingIconWrapper>
            </div>
            <div className="mt-3">
              <StyledTypo
                color="#FF487F"
                size={2.25}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                $500
              </StyledTypo>
              <StyledTypo
                color="#A4AFB7"
                size={1.125}
                line={25.2 / 18}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                on {moment().format("MMM DD, YYYY")}
              </StyledTypo>
            </div>
          </div>
          <div className="text-right">
            <EffectedButton padding={0} height={45} noSpan>
              <StyledTypo
                className="ml-2"
                color="#fff"
                size={1.125}
                line={25.2 / 18}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                Manage Payments
              </StyledTypo>
            </EffectedButton>
          </div>
        </NextBillingWrapper>
        <PlanDetailsWrapper>
          <StyledTypo
            color="#A4AFB7"
            size={2.25}
            line={1.4}
            letter="0"
            family="Roboto"
            fontWeight={700}
          >
            Plan Details
          </StyledTypo>
          <div className="mt-3">
            <StyledTypo
              color="#505050"
              size={1.625}
              line={36.4 / 26}
              letter="0"
              family="Roboto"
              fontWeight={700}
            >
              Plan Name
            </StyledTypo>
            <div className="d-flex justify-content-between">
              <StyledTypo
                color="#A4AFB7"
                size={1.125}
                line={25.2 / 18}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                Lorem Ipsum Plan
              </StyledTypo>
              <StyledTypo
                color="#FF487F"
                size={1.125}
                line={25.2 / 18}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                View Details
              </StyledTypo>
            </div>
          </div>
          <div className="mt-3">
            <StyledTypo
              color="#505050"
              size={1.625}
              line={36.4 / 26}
              letter="0"
              family="Roboto"
              fontWeight={700}
            >
              Subscription date
            </StyledTypo>
            <StyledTypo
              color="#A4AFB7"
              size={1.125}
              line={25.2 / 18}
              letter="0"
              family="Roboto"
              fontWeight={700}
            >
              {moment().format("MM/DD/YYYY")}
            </StyledTypo>
          </div>
          <div className="mt-3 d-flex justify-content-between align-items-center">
            <div>
              <StyledTypo
                color="#505050"
                size={1.625}
                line={36.4 / 26}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                Status
              </StyledTypo>
              <StyledTypo
                color="#A4AFB7"
                size={1.125}
                line={25.2 / 18}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                Currently Active
              </StyledTypo>
            </div>
            <div>
              <EffectedButton padding={0} height={45} noSpan>
                <StyledTypo
                  color="#fff"
                  size={1.125}
                  line={25.2 / 18}
                  letter="0"
                  family="Roboto"
                  fontWeight={700}
                >
                  Change Plan
                </StyledTypo>
              </EffectedButton>
            </div>
          </div>
        </PlanDetailsWrapper>
      </div>
    </PanelWrapper>
  );
};

function mapStateToProps(state) {
  const { clusterInfo, clusterInfoLoading, healthInfo } = state.applications;
  return {
    clusterInfo,
    clusterInfoLoading,
    healthInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchClusterDetailsAction: (org_id, app_id, realtimeFlag) =>
      dispatch(
        applicationActions.fetchClusterDetailsAction(
          org_id,
          app_id,
          realtimeFlag
        )
      ),
    fetchAppHealthAction: (org_id, app_id, realtimeFlag) =>
      dispatch(
        applicationActions.fetchAppHealthAction(org_id, app_id, realtimeFlag)
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingDataPanel);
