/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { applicationActions } from "../../../redux/actions";
import { connect } from "react-redux";
import StyledTypo from "../../../components/Typo/StyledTypo";
import WorkloadList from "./workloadList/WorkloadList";
import {
  ViewContainer,
  PageTitle,
  PageMenu,
  FlexComp,
} from "../../../components/PageLayout/Containers";
import { TableWrapper } from "../../../components/PageLayout/Table";
import { BackButton } from "../../../components/PageLayout/BackButton";
import { commonTitle } from "../../../helpers/constants";
import { useTitle } from "../../../helpers/useTtitle";

const pageTitle = `${commonTitle} - Cloud Security - CWPP`

const WorkloadListLayout = (props) => {
  const {
    fetchWorkloadsAction,
    workloads,
    workloadsLoading
  } = props;
  const workloadsRef = useRef();
  const headerRef = useRef();
  const history = useHistory()
  const [, setRenderFlag] = useState(false);

  useTitle(pageTitle)

  useEffect(() => {
    fetchWorkloadsAction()
  }, []);

  return (
    <ViewContainer>
      <div className="main-container d-flex flex-column">
        <PageTitle
          className="d-flex align-items-center justify-content-between"
          ref={headerRef}
        >
          <StyledTypo className="ml-2 heading">
            <BackButton
              onClick={() => history.push("/cloud")}
              title="Back"
              href="#"
            >
              {" "}
              Cloud Security{" "}
            </BackButton>{" - CWPP"}
          </StyledTypo>
          <FlexComp>
            {workloadsRef?.current?.getFilterPanel()}
          </FlexComp>
        </PageTitle>
        <TableWrapper
          marginTop={`${
            headerRef?.current?.offsetHeight + headerRef?.current?.offsetTop ||
            100
          }px`}
        >
          <WorkloadList
            topGap={
              headerRef?.current?.offsetHeight +
                headerRef?.current?.offsetTop || 100
            }
            workloads={workloads}
            fetchingFlag={workloadsLoading}
            ref={workloadsRef}
            setRenderFlag={setRenderFlag}
          />
        </TableWrapper>
      </div>
    </ViewContainer>
  );
};

function mapStateToProps(state) {
  const { workloads, workloadsLoading } = state.applications;
  return {
    workloads,
    workloadsLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchWorkloadsAction: () => dispatch(applicationActions.fetchWorkloadsAction())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkloadListLayout);
