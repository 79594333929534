import {ReactComponent as RightBlueArrowIcon} from "../../assets/icons/right_blue_arrow_icon.svg";
import {ReactComponent as LeftBlueArrowIcon} from "../../assets/icons/left_blue_arrow_icon.svg";
import {ReactComponent as RightBlackArrowIcon} from "../../assets/icons/right_arrow_icon.svg";
import {ReactComponent as LeftBlackArrowIcon} from "../../assets/icons/left_arrow_icon.svg";
import React from "react";

export const NextArrow = (props) => {
  const {className, style, onClick} = props;
  return (
      <>
        {props.type === 'app' ? (
          <RightBlackArrowIcon className={className} onClick={onClick} style={{...style, width: 12, top: 30}}/>
        ) : props.type === 'env' ? (
          <RightBlueArrowIcon className={className} onClick={onClick} style={{...style, width: 9, top: 20}}/>
        ) : <></>}
      </>
  );
}

export const PrevArrow = (props) => {
  const {className, style, onClick} = props;
  return (
      <>
        {props.type === 'app' ? (
          <LeftBlackArrowIcon className={className} onClick={onClick} style={{...style, width: 12, top: 30}}/>
        ) : props.type === 'env' ? (
          <LeftBlueArrowIcon className={className} onClick={onClick} style={{...style, width: 9, top: 20}}/>
        ) : <></>}
      </>
  );
}