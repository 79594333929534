import { plans } from "../apis";
import { planConstants } from "../constants";
import { authAction } from "./auth.actions";
import { showAlert } from "../../helpers/constants";

export const planActions = {
  createSubscriptionAction,
  clearNewSubscriptionStatusAction,
  fetchSubscriptionAction,
  upgradeSubscriptionAction,
  cancelSubscriptionAction,
  clearSubscriptionAction,
  fetchPaymentMethodsAction,
  changeSubscriptionPaymentMethodAction,
  createPaymentAction,
  removePaymentAction,
};

function createSubscriptionAction(data) {
  return (dispatch) => {
    dispatch(request());
    plans.createNewSubscription(data).then(
      (res) => {
        if (
          res.organization.subscription.status === "trialing" ||
          res.organization.subscription.status === "active"
        ) {
          showAlert(
            "success",
            "Create subscription",
            "Successfully created subscription."
          );
          dispatch(success(res));
        } else {
          showAlert(
            "error",
            "Create subscription",
            "Successfully created subscription, but invalid status."
          );
          dispatch(failure("Invalid subscription"));
        }
      },
      (error) => {
        showAlert("error", "Create subscription", error);
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: planConstants.CREATE_NEW_SUBSCRIPTION_REQUEST };
  }
  function success(data) {
    return { type: planConstants.CREATE_NEW_SUBSCRIPTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: planConstants.CREATE_NEW_SUBSCRIPTION_FAILURE, error };
  }
}

function clearNewSubscriptionStatusAction() {
  return (dispatch) => {
    dispatch({
      type: planConstants.CLEAR_NEW_SUBSCRIPTION_STATUS,
    });
  };
}

function clearSubscriptionAction() {
  return (dispatch) => {
    dispatch({
      type: planConstants.CLEAR_SUBSCRIPTION,
    });
  };
}

function fetchSubscriptionAction() {
  return (dispatch) => {
    dispatch(request());
    plans.fetchSubscription().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: planConstants.FETCH_CURRENT_SUBSCRIPTION_REQUEST };
  }
  function success(data) {
    return { type: planConstants.FETCH_CURRENT_SUBSCRIPTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: planConstants.FETCH_CURRENT_SUBSCRIPTION_FAILURE, error };
  }
}

function upgradeSubscriptionAction(data) {
  return (dispatch) => {
    dispatch(request());
    plans.upgradeSubscription(data).then(
      (res) => {
        showAlert(
          "success",
          "Upgrade subscription",
          "Successfully upgraded subscription."
        );
        dispatch(success(res));
      },
      (error) => {
        showAlert("error", "Upgrade subscription", "error");
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: planConstants.UPGRADE_SUBSCRIPTION_REQUEST };
  }
  function success(data) {
    return { type: planConstants.UPGRADE_SUBSCRIPTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: planConstants.UPGRADE_SUBSCRIPTION_FAILURE, error };
  }
}

function cancelSubscriptionAction() {
  return (dispatch) => {
    dispatch(request());
    plans.cancelSubscription().then(
      (res) => {
        showAlert(
          "success",
          "Cancel subscription",
          "Successfully canceled subscription."
        );
        dispatch(success(res));
      },
      (error) => {
        showAlert("error", "Cancel subscription", error);
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: planConstants.UPGRADE_SUBSCRIPTION_REQUEST };
  }
  function success(data) {
    return { type: planConstants.UPGRADE_SUBSCRIPTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: planConstants.UPGRADE_SUBSCRIPTION_FAILURE, error };
  }
}

function fetchPaymentMethodsAction() {
  return (dispatch) => {
    dispatch(request());
    plans.fetchPaymentMethods().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: planConstants.FETCH_PAYMENT_METHODS_REQUEST };
  }
  function success(data) {
    return { type: planConstants.FETCH_PAYMENT_METHODS_SUCCESS, data };
  }
  function failure(error) {
    return { type: planConstants.FETCH_PAYMENT_METHODS_FAILURE, error };
  }
}

function changeSubscriptionPaymentMethodAction(data) {
  return (dispatch) => {
    dispatch(request());
    plans.changeSubscriptionPaymentMethod(data).then(
      (res) => {
        showAlert(
          "success",
          "Modify subscription",
          "Successfully modified subscription."
        );
        dispatch(success(res));
      },
      (error) => {
        showAlert("error", "Modify subscription", error);
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: planConstants.UPGRADE_SUBSCRIPTION_REQUEST };
  }
  function success(data) {
    return { type: planConstants.UPGRADE_SUBSCRIPTION_SUCCESS, data };
  }
  function failure(error) {
    return { type: planConstants.UPGRADE_SUBSCRIPTION_FAILURE, error };
  }
}

function createPaymentAction(data) {
  return (dispatch) => {
    dispatch(request());
    plans.createPayment(data).then(
      (res) => {
        showAlert(
          "success",
          "Add new payment method",
          "Successfully added new payment method."
        );
        dispatch(success(res));
      },
      (error) => {
        showAlert("error", "Add new payment method", error);
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: planConstants.CREATE_PAYMENT_REQUEST };
  }
  function success(data) {
    return { type: planConstants.CREATE_PAYMENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: planConstants.CREATE_PAYMENT_FAILURE, error };
  }
}

function removePaymentAction(pm_id) {
  return (dispatch) => {
    dispatch(request());
    plans.deletePayment(pm_id).then(
      (res) => {
        showAlert(
          "success",
          "Detach payment method",
          "Successfully detached payment method."
        );
        dispatch(success(res));
      },
      (error) => {
        showAlert("error", "Detach payment method", error);
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: planConstants.DELETE_PAYMENT_REQUEST };
  }
  function success(data) {
    return { type: planConstants.DELETE_PAYMENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: planConstants.DELETE_PAYMENT_FAILURE, error };
  }
}
