import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { ReactComponent as ThreeDotIcon } from "../../../assets/icons/three_dot_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/trash_icon.svg";

import StyledTypo from "../../../components/Typo/StyledTypo";
import StyledDropdown from "../../../components/Buttons/StyledDropdown";
import DropdownIcon from "../../../components/Buttons/DropdownIcon";
import { DropdownMenu } from "reactstrap";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";

const TableWrapper = styled.div`
  padding-top: 10px;
  .divider {
    margin: 8px 0px 4px 0;
    border-top: 1px solid rgba(164, 175, 183, 0.3);
    height: 0;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 26px;
`;

const EachCell = styled.div`
  width: ${(props) => props.width};
`;

const RecordWrapper = styled.div`
  padding: 10px 26px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: 5px;

  &:hover {
    border-radius: 5px;
    background-color: #f1f1f1;
  }
`;

const ActionDropdownMenu = styled(DropdownMenu)`
  min-width: 150px;
  width: 200px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 10px;
  padding: 15px 20px;
  transform: translate3d(-150px, -10px, 0px) !important;

  .item:hover p {
    color: #66c4d5 !important;
  }
`;

const ShowingWrapper = styled.div`
  margin-top: 32px;
  padding: 0 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .page-link:focus {
    box-shadow: none !important;
  }
  ul.pagination {
    margin-bottom: 0;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 0.8rem;
  }
`;

const StatusWrapper = styled.div`
  background-color: ${(props) => props.color};
  padding: 5px 20px;
  border-radius: 215px;
  display: flex;
  margin-left: 7px;
`;

const TableHeader = () => {
  return (
    <HeaderWrapper>
      <EachCell width={`${30000 / 1596}%`}>
        <StyledTypo className="label">Number</StyledTypo>
      </EachCell>
      <EachCell width={`${30000 / 1596}%`}>
        <StyledTypo className="label">Type</StyledTypo>
      </EachCell>
      <EachCell width={`${30000 / 1596}%`}>
        <StyledTypo className="label">Fingerprint</StyledTypo>
      </EachCell>
      <EachCell width={`${30000 / 1596}%`}>
        <StyledTypo className="label">Expires</StyledTypo>
      </EachCell>
      <EachCell width={`${30000 / 1596}%`}>
        <StyledTypo className="label">CVC check</StyledTypo>
      </EachCell>
      <EachCell width={`${9600 / 1596}%`}>
        <StyledTypo className="text-right label">Actions</StyledTypo>
      </EachCell>
    </HeaderWrapper>
  );
};

const Record = (props) => {
  const { data, isOpenActionMenu, toggleActionMenu, removePaymentAction } =
    props;

  const deleteConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>{`Do you want to detach payment method"?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                removePaymentAction(data.id);
                onClose();
              }}
            >
              Confirm
            </button>
          </div>
        );
      },
    });
  };
  return (
    <RecordWrapper>
      <EachCell width={`${30000 / 1596}%`}>
        <div className="d-flex align-items-center">
          <StyledTypo>•••• {data.card.last4}</StyledTypo>
          {data.id ===
            data?.customer?.invoice_settings?.default_payment_method && (
            <StatusWrapper color="#65C2D7">
              <StyledTypo color={"#fff"} className="table-label">
                default
              </StyledTypo>
            </StatusWrapper>
          )}
        </div>
      </EachCell>
      <EachCell width={`${30000 / 1596}%`}>
        <StyledTypo>
          {data.card["brand"].toUpperCase()} {data.card.funding} card
        </StyledTypo>
      </EachCell>
      <EachCell width={`${30000 / 1596}%`}>
        <StyledTypo>{data.card.fingerprint}</StyledTypo>
      </EachCell>
      <EachCell width={`${30000 / 1596}%`}>
        <StyledTypo>
          {moment(`${data.card.exp_year}-${data.card.exp_month}-01`).format(
            "MMM YYYY"
          )}
        </StyledTypo>
      </EachCell>
      <EachCell width={`${30000 / 1596}%`} className="d-flex">
        <StyledTypo>{data.card.checks.cvc_check}</StyledTypo>
      </EachCell>
      <EachCell width={`${9600 / 1596}%`} className="text-right">
        <StyledDropdown
          isOpen={isOpenActionMenu.filter((item) => item[data.id]).length}
          toggle={() => toggleActionMenu(data.id)}
        >
          <DropdownIcon>
            <ThreeDotIcon />
          </DropdownIcon>
          <ActionDropdownMenu>
            <div
              className="d-flex my-2 align-items-center cursor-pointer item"
              onClick={() => {
                deleteConfirm();
                toggleActionMenu(data.id);
              }}
            >
              <DeleteIcon width={20} fill={"#ff487f"} />
              <StyledTypo className="ml-3" color={"#ff487f"}>
                {data.is_deleted ? "Restore" : "Delete"}
              </StyledTypo>
            </div>
          </ActionDropdownMenu>
        </StyledDropdown>
      </EachCell>
    </RecordWrapper>
  );
};

const PaymentsTable = (props) => {
  const { data, removePaymentAction } = props;
  const [isOpenActionMenu, setIsOpenActionMenu] = useState([]);
  const [paymentsData, setPaymentsData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemPerPage = 10;

  useEffect(() => {
    setPaymentsData(data);
    setIsOpenActionMenu(data.map((item) => ({ [item.id]: false })));
  }, [data]);

  const toggleActionMenu = (key) => {
    setIsOpenActionMenu(
      isOpenActionMenu.map((item) => ({ ...item, [key]: !item[key] }))
    );
  };

  const handlePageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const pageFilter = useCallback(
    (data) => {
      return data.filter(
        (item, idx) =>
          idx >= itemPerPage * (currentPage - 1) &&
          idx < itemPerPage * currentPage
      );
    },
    [data, currentPage]
  );

  const showPaginateLabel = useCallback(
    (data) => {
      return `Showing 
    ${
      itemPerPage * (currentPage - 1) + 1 > data.length
        ? data.length
        : itemPerPage * (currentPage - 1) + 1
    } - 
    ${
      itemPerPage * currentPage > data.length
        ? data.length
        : itemPerPage * currentPage
    } from ${data.length} payments`;
    },
    [data, currentPage]
  );

  const handleChange = (id) => {
    setPaymentsData((deployData) =>
      deployData.map((item) => ({
        ...item,
        selected: item.id === id ? !item.selected : item.selected,
      }))
    );
  };

  return (
    <TableWrapper>
      <TableHeader />
      <div className="divider" />
      {pageFilter(paymentsData).length ? (
        pageFilter(paymentsData).map((paymentData) => (
          <Record
            data={paymentData}
            isOpenActionMenu={isOpenActionMenu}
            toggleActionMenu={toggleActionMenu}
            handleChange={handleChange}
            removePaymentAction={removePaymentAction}
          />
        ))
      ) : (
        <div className="text-center my-5">
          <StyledTypo>No payments</StyledTypo>
        </div>
      )}
      <ShowingWrapper>
        <StyledTypo className="pagination-label">
          {showPaginateLabel(paymentsData)}
        </StyledTypo>
        <ReactPaginate
          pageCount={(paymentsData?.length ?? 0) / itemPerPage}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          previousLabel="< Prev"
          nextLabel="Next >"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          forcePage={currentPage - 1}
          onPageChange={handlePageChange}
          activeClassName="active"
        />
      </ShowingWrapper>
    </TableWrapper>
  );
};

export default PaymentsTable;
