import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import PeriodOption from './PeriodOption';
import PaddingLineChart from './PaddingLineChart';
import { colors } from '../../../helpers/constants';
import moment from 'moment';

const MainContainer = styled.div`
    width: calc(100vw - 145px);
    margin-top: 50px;
`;

const PeriodContainer = styled.div`
    width: 90%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
`;

const ChartContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;


const trend_compliance_data = [
    {
      name: '07-2022',
      compliance: 75,
      risk: 10
    },
    {
      name: '08-2022',
      compliance: 85,
      risk: 15
    },
    {
      name: '09-2022',
      compliance: 54,
      risk: 23
    },
    {
      name: '10-2022',
      compliance: 99,
      risk: 17
    },
    {
      name: '11-2022',
      compliance: 22,
      risk: 10
    },
    {
      name: '12-2022',
      compliance: 35,
      risk: 28
    },
    {
      name: '01-2023',
      compliance: 45,
      risk: 19
    },
    {
      name: '02-2023',
      compliance: 55,
      risk: 41
    },
    {
      name: '03-2023',
      compliance: 65,
      risk: 20
    },
    {
      name: '04-2023',
      compliance: 75,
      risk: 27
    },
    {
      name: '05-2023',
      compliance: 95,
      risk: 39
    },
    {
      name: '06-2023',
      compliance: 69,
      risk: 10
    },
  ].reverse().map((each, idx) => ({
    ...each,
    name: moment().subtract(idx, 'months').format('MMM YYYY')
  })).reverse();

const ProviderComplianceTrend = (props) => {
    const { data } = props
    const [compliance, setCompliance] = useState([]);
    
    const [duration, setDuration] = useState('monthly');
    const getTotal = (item) => {
        return item.pass + item.critical + item.high + item.medium + item.low
    }
    
    useEffect(() => {
        const { compliance_summary_results } = data || {}
        const monthlyData = (compliance_summary_results ?? []).map(item => ({
            name: moment(item.created_at).format('MMM, YYYY'),
            compliance: Math.ceil((item.pass) / getTotal(item) * 100)
        }))
        setCompliance([...monthlyData]);
    }, [data])

    return (
        <MainContainer>
            <PeriodContainer>
                <PeriodOption duration={duration} setDuration={setDuration} />
            </PeriodContainer>
            <ChartContainer>
                <PaddingLineChart 
                    data={trend_compliance_data} 
                    xAxis={{
                        key: 'name'
                    }}
                    yAxis={{
                      compliance: {
                        key: "compliance"
                      },
                      risk: {
                        key: 'risk'
                      }
                    }}
                    line={{
                        activeDotRadius: 4,
                        type: 'circle',
                        complianceStroke: '#3F51B5',
                        riskStroke: '#01B5AD'
                    }}
                />
            </ChartContainer>
        </MainContainer>
    );
};

export default ProviderComplianceTrend;