import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { userStatusInfo } from "../../../helpers/constants";
import { ReactComponent as EditUserIcon } from "../../../assets/icons/user_edit_icon.svg";
import { ReactComponent as EditPermissionIcon } from "../../../assets/icons/permission_icon.svg";
import { ReactComponent as ImpersonateIcon } from "../../../assets/icons/impersonate_icon.svg";
import { ReactComponent as ThreeDotIcon } from "../../../assets/icons/three_dot_icon.svg";
import { ReactComponent as DeployButtonIcon } from "../../../assets/icons/deploy_icon.svg";

import StyledTypo from "../../../components/Typo/StyledTypo";
import StyledDropdown from "../../../components/Buttons/StyledDropdown";
import DropdownIcon from "../../../components/Buttons/DropdownIcon";
import { DropdownMenu } from "reactstrap";

const TableWrapper = styled.div`
  padding-top: 20px;
  .divider {
    margin: 8px -40px 4px -40px;
    border-top: 1px solid rgba(164, 175, 183, 0.3);
    height: 0;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 31px 0 26px;
`;

const EachCell = styled.div`
  width: ${(props) => props.width};
`;

const RecordWrapper = styled.div`
  padding: 19px 31px 19px 26px;
  background: #ffffff;
  // border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const ActionDropdownMenu = styled(DropdownMenu)`
  // --width: ${(props) => props.width - 350}px;
  // left: calc(var(--width) - 50px - 1vw) !important;
  /* white */
  min-width: 164px;
  width: 280px;
  background: #ffffff;
  /* Gray light 2 */

  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  /* box shadow */

  box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 20px;
  padding: 33px 35px;
  transform: translate3d(-230px, -10px, 0px) !important;
`;

const StatusWrapper = styled.div`
  background-color: ${(props) => props.color};
  padding: 5px 20px;
  border-radius: 215px;
  display: flex;
`;

const TableHeader = () => {
  return (
    <HeaderWrapper>
      <EachCell width={`${34400 / 919}%`}>
        <StyledTypo
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          Org ID
        </StyledTypo>
      </EachCell>
      <EachCell width={`${30100 / 919}%`}>
        <StyledTypo
          color={"#505050"}
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          Org Name
        </StyledTypo>
      </EachCell>
      <EachCell width={`${19000 / 919}%`}>
        <StyledTypo
          color={"#505050"}
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          Status
        </StyledTypo>
      </EachCell>
      <EachCell width={`${8200 / 919}%`}>
        <StyledTypo
          className="text-right"
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          Actions
        </StyledTypo>
      </EachCell>
    </HeaderWrapper>
  );
};

const Record = (props) => {
  const { data, isOpenActionMenu, toggleActionMenu } = props;
  return (
    <RecordWrapper>
      <EachCell width={`${34400 / 919}%`}>
        <StyledTypo
          line={26.15 / 16}
          letter="0"
          family="Poppins"
        >
          {data.id}
        </StyledTypo>
      </EachCell>
      <EachCell width={`${30100 / 919}%`}>
        <StyledTypo
          line={26.15 / 16}
          letter="0"
          family="Poppins"
        >
          {data.name}
        </StyledTypo>
      </EachCell>
      <EachCell width={`${19000 / 919}%`} className="d-flex">
        <StatusWrapper color={userStatusInfo["active"].color}>
          <StyledTypo
            color={"#fff"}
            line={26.15 / 16}
            letter="0"
            family="Poppins"
          >
            {userStatusInfo["active"].name}
          </StyledTypo>
        </StatusWrapper>
      </EachCell>
      <EachCell width={`${8200 / 919}%`} className="text-right">
        <StyledDropdown
          isOpen={isOpenActionMenu.filter((item) => item[data.id]).length}
          toggle={() => toggleActionMenu(data.id)}
        >
          <DropdownIcon>
            <ThreeDotIcon />
          </DropdownIcon>
          {/*<ActionDropdownMenu>*/}
          {/*  <div className="d-flex mb-2 align-items-center cursor-pointer">*/}
          {/*    <EditUserIcon fill="#485DCD"/>*/}
          {/*    <StyledTypo className="ml-3" color={'#505050'} size={0.875} line={26.15 / 18} letter="0" family="Roboto"*/}
          {/*                fontWeight={700}>*/}
          {/*      Edit Details*/}
          {/*    </StyledTypo>*/}
          {/*  </div>*/}
          {/*  <div className="d-flex  mb-2 align-items-center cursor-pointer">*/}
          {/*    <EditPermissionIcon/>*/}
          {/*    <StyledTypo className="ml-3" color={'#505050'} size={1.125} line={26.15 / 18} letter="0" family="Roboto"*/}
          {/*                fontWeight={700}>*/}
          {/*      Edit Permissions*/}
          {/*    </StyledTypo>*/}
          {/*  </div>*/}
          {/*  <div className="d-flex mb-2 align-items-center cursor-pointer">*/}
          {/*    <DeployButtonIcon/>*/}
          {/*    <StyledTypo className="ml-3" color={'#505050'} size={1.125} line={26.15 / 18} letter="0" family="Roboto"*/}
          {/*                fontWeight={700}>*/}
          {/*      Enable/Disable User*/}
          {/*    </StyledTypo>*/}
          {/*  </div>*/}
          {/*  <div className="d-flex align-items-center cursor-pointer">*/}
          {/*    <ImpersonateIcon/>*/}
          {/*    <StyledTypo className="ml-3" color={'#505050'} size={1.125} line={26.15 / 18} letter="0" family="Roboto"*/}
          {/*                fontWeight={700}>*/}
          {/*      Impersonate*/}
          {/*    </StyledTypo>*/}
          {/*  </div>*/}
          {/*</ActionDropdownMenu>*/}
        </StyledDropdown>
      </EachCell>
    </RecordWrapper>
  );
};

const ProfileOrganizationTable = (props) => {
  const { data } = props;
  const [isOpenActionMenu, setIsOpenActionMenu] = useState([]);
  const [usersData, setUsersData] = useState([]);

  useEffect(() => {
    if (data.length) {
      setUsersData([...data]);
      setIsOpenActionMenu([...data.map((item) => ({ [item.id]: false }))]);
    }
  }, [data]);

  const toggleActionMenu = (key) => {
    setIsOpenActionMenu(
      isOpenActionMenu.map((item) => ({ ...item, [key]: !item[key] }))
    );
  };

  const handleChange = (id) => {
    setUsersData((deployData) =>
      deployData.map((item) => ({
        ...item,
        selected: item.id === id ? !item.selected : item.selected,
      }))
    );
  };

  return (
    <TableWrapper>
      <TableHeader />
      <div className="divider" />
      {usersData.map((userData) => (
        <Record
          data={userData}
          isOpenActionMenu={isOpenActionMenu}
          toggleActionMenu={toggleActionMenu}
          handleChange={handleChange}
        />
      ))}
    </TableWrapper>
  );
};

export default ProfileOrganizationTable;
