import React, { useState } from "react";
import styled from "styled-components";
import StyledTypo from "../../components/Typo/StyledTypo";
import { ReactComponent as GitUrlIcon } from "../../assets/icons/git_link_icon.svg";
import CommonInput from "../../components/Input/CommonInput";
import moment from "moment";
import EffectedButton from "../../components/Buttons/EffectedButton";
import { DropdownMenu } from "reactstrap";
import {
  devopsArr,
  showAlert,
  templateTypeList,
  deployTypes,
  parseCSV,
  convertBase64,
} from "../../helpers/constants";
import CommonSelect from "../../components/Select/CommonSelect";
import CustomTextarea from "../../components/Input/CustomTextarea";
import StyledFile from "../../components/Input/StyledFile";

const MainContainer = styled(DropdownMenu)`
  width: 480px;
  padding: 33px 41px;

  background: #ffffff;
  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  //box-shadow: 0 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 20px;
  box-shadow: 0 0 0 9999px #00000066;
  transform: translate3d(-320px, 45px, 0px) !important;
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
`;

const StatusWrapper = styled.div`
  background-color: ${(props) => props.color};
  padding: 5px 20px;
  border-radius: 215px;
  display: flex;

  cursor: pointer;
`;


const AddTemplatePanel = (props) => {
  const { handleSubmitDeployment } = props;

  const templateTypeOptions = templateTypeList.map((type) => ({
    value: type.toLowerCase(),
    label: type,
  }));
  const categoryOptions = deployTypes;

  const [name, setName] = useState("");
  const [gitUrl, setGitUrl] = useState("");
  const [branch, setBranch] = useState("");
  const [path, setPath] = useState("");
  const [status, setStatus] = useState(true);
  const [devops, setDevops] = useState(0);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [sshKey, setSSHKey] = useState("");
  const [credContent, setCredContent] = useState(null);

  const [category, setCategory] = useState(categoryOptions?.[0]);

  const toggleStatus = () => {
    setStatus((status) => !status);
  };

  const toggleDevops = () => {
    setDevops((devops) => (devops + 1) % 2);
  };

  const handleCredentialChange = (filename, content) => {
    if (filename.endsWith(".json")) {
      setCredContent(content);
    } else if (filename.endsWith(".csv")) {
      setCredContent(JSON.stringify(parseCSV(content)[0]));
    }
  };

  const handleKeyChange = (filename, content) => {
    setSSHKey(convertBase64(content));
  };

  const validateInput = () => {
    // const gitUrlRegex = /^((git|ssh|http(s)?)|(git@[\\w\\.]+))(:(\/\/)?)([\\w\\.@\\:/\\-~]+)(\\.git)(\/)?$/g
    let msg = "";
    const sshKeyValid =
      /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
    if (!name.length) {
      msg = "Please fill new deployment name.";
    } else if (!gitUrl.length) {
      msg = "Please enter valid git URL.";
    } else if (!selectedTypes.length) {
      msg = "Please select 1 template type at least.";
    } else if (sshKey.length && !sshKeyValid.test(sshKey)) {
      msg = "SSH Key must be base64 string.";
    }

    if (msg.length) {
      showAlert("warning", "Invalid input", msg);
      return false;
    }
    return true;
  };

  const submitNewDeployment = () => {
    if (!validateInput()) return;
    let templateData = {
      name,
      repo_url: gitUrl,
      branch,
      path,
      config: {},
      description: name,
      category: category?.value,
      type: selectedTypes.map((item) => item.value),
      devops: devopsArr[devops],
      status: status ? "active" : "inactive",
      createdDate: moment().format("YYYY-MM-DD hh:mm"),
      sshKey: sshKey,
      credential: credContent,
    };

    handleSubmitDeployment(templateData);
  };

  return (
    <MainContainer>
      <div className="title mb-2">
        <StyledTypo
          color="#A4AFB7"
          size={1.125}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={700}
        >
          Add a Template
        </StyledTypo>
      </div>
      <div className="sub-title mb-4">
        <StyledTypo
          color="#A4AFB7"
          size={1}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={700}
        >
          You are creating a new Template
        </StyledTypo>
      </div>
      <CommonSelect
        value={category}
        setValue={setCategory}
        options={categoryOptions}
        label="Deploy Type"
      />
      <div>
        <CommonInput
          label="Name"
          value={name}
          setValue={setName}
          active={false}
          locked={false}
          placeholder=""
        />
      </div>
      <div>
        <CommonInput
          label="Git Repository Link"
          value={gitUrl}
          setValue={setGitUrl}
          active={false}
          locked={false}
          icon={GitUrlIcon}
          placeholder=""
        />
      </div>
      <div>
        <CommonInput
          label="Branch"
          value={branch}
          setValue={setBranch}
          active={false}
          locked={false}
          placeholder=""
        />
      </div>
      <div>
        <CommonInput
          label="Sub-Directory"
          value={path}
          setValue={setPath}
          active={false}
          locked={false}
          placeholder=""
        />
      </div>
      <div className="d-flex">
        <div style={{ width: "80%" }}>
          <CustomTextarea
            label="SSH Key (Optional)"
            value={sshKey}
            setValue={setSSHKey}
          />
        </div>
        <StyledFile hiddenId="hiddenKeyInputField" onChange={handleKeyChange} />
      </div>
      <CommonSelect
        value={selectedTypes}
        setValue={setSelectedTypes}
        options={templateTypeOptions}
        label="Template Type"
        isMulti
      />
      <div className="d-flex justify-content-between align-items-center mt-3">
        <StyledTypo
          color="#505050"
          size={1}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={400}
        >
          DevOps Tool
        </StyledTypo>
        <StatusWrapper
          onClick={toggleDevops}
          color={devops ? "#ff487f" : "#5EB1E4"}
        >
          <StyledTypo
            color="#fff"
            size={1}
            line={1.4}
            letter="0"
            family="Poppins"
            fontWeight={400}
          >
            {devopsArr[devops]}
          </StyledTypo>
        </StatusWrapper>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <StyledTypo
          color="#505050"
          size={1}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={400}
        >
          Status
        </StyledTypo>
        <StatusWrapper onClick={toggleStatus} color={"#A4AFB7"}>
          <StyledTypo
            color="#fff"
            size={1}
            line={1.4}
            letter="0"
            family="Poppins"
            fontWeight={400}
          >
            {status ? "Active" : "Inactive"}
          </StyledTypo>
        </StatusWrapper>
      </div>
      <StyledFile
        label="Credential"
        extensions={["json", "csv"]}
        isShowName
        className="mt-4"
        onChange={handleCredentialChange}
        hiddenId="hiddenCredInputField"
      />
      <div className="d-flex justify-content-between align-items-center mt-3 mb-5 pb-2">
        <StyledTypo
          color="#505050"
          size={1}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={400}
        >
          Create Date
        </StyledTypo>
        <StyledTypo
          color="#505050"
          size={1}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {moment().format("MM-DD-YYYY hh:mm")}
        </StyledTypo>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <EffectedButton marginLeft="0" onClick={submitNewDeployment}>
            <StyledTypo
              color="#fff"
              size={1.125}
              line={25.2 / 18}
              letter="0"
              family="Roboto"
              fontWeight={500}
            >
              Create
            </StyledTypo>
          </EffectedButton>
        </div>
        <div>
          <EffectedButton
            marginLeft="0"
            background="#485DCD"
            onClick={props.toggle}
          >
            <StyledTypo
              color="#fff"
              size={1.125}
              line={25.2 / 18}
              letter="0"
              family="Roboto"
              fontWeight={500}
            >
              Cancel
            </StyledTypo>
          </EffectedButton>
        </div>
      </div>
    </MainContainer>
  );
};

export default AddTemplatePanel;
