/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import { iacActions } from "../../../redux/actions";
import { connect } from "react-redux";
import StyledTypo from "../../../components/Typo/StyledTypo";
import IacProjects from "./Projects/IacProjects";
import { ReactComponent as AddItemIcon } from "../../../assets/icons/add_item.svg";
import { ReactComponent as RemoveItemIcon } from "../../../assets/icons/remove_item.svg";
import CreateProjectPanel from "./Projects/CreateProjectPanel";
import EffectedButton from "../../../components/Buttons/EffectedButton";
import { confirmAlert } from "react-confirm-alert";
import {
  ViewContainer,
  PageTitle,
  FlexComp,
  PageMenu,
} from "../../../components/PageLayout/Containers";
import { TableWrapper } from "../../../components/PageLayout/Table";
import { commonTitle } from "../../../helpers/constants";
import { useTitle } from "../../../helpers/useTtitle";

const pageTitle = `${commonTitle} - IaC Scan`

const IaCProjectLayout = (props) => {
  const {
    fetchIacProjectsAction,
    iac_projects,
    createIacProjectAction,
    createdFlag,
    clearCreateFlagAction,
    updateIacProjectAction,
    deleteIacProjectAction,
    fetching_projects_flag,
  } = props;

  const filterPanelRef = useRef();
  const headerRef = useRef();

  const [openCreatePanel, setOpenCreatePanel] = useState(false);
  const [updateProject, setUpdateProject] = useState(null);
  const [markedProjects, setMarkedProjects] = useState([]);
  const [renderFlag, setRenderFlag] = useState(false);

  useTitle(pageTitle)

  const togglePanel = () => {
    setOpenCreatePanel((openCreatePanel) => !openCreatePanel);
  };

  useEffect(() => {
    fetchIacProjectsAction();
  }, []);

  useEffect(() => {
    if (!openCreatePanel) {
      setUpdateProject(null);
    }
  }, [openCreatePanel]);

  const chooseProject2Update = (project) => {
    setUpdateProject(project);
    togglePanel();
  };

  const deleteConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>{`You want to delete IaC project named ${markedProjects?.[0]?.display_name}?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                deleteIacProjectAction(markedProjects?.[0]);
                onClose();
              }}
            >
              Confirm
            </button>
          </div>
        );
      },
    });
  };

  return (
    <ViewContainer>
      <div className="main-container d-flex flex-column">
        <PageTitle
          className="d-flex align-items-center justify-content-between"
          ref={headerRef}
        >
          <StyledTypo className="ml-2 heading">IaC Scan - Projects</StyledTypo>
          <FlexComp>
            <PageMenu>
              <a
                className="menu-icon"
                onClick={togglePanel}
                title="Add Project"
                alt="Add Project"
                href="#"
              >
                <AddItemIcon
                  width={22}
                  height={22}
                  fill="#606060"
                  className="svg-icon"
                />
                <span className="menu-tip">Add Infra</span>
              </a>
              <a
                className={
                  markedProjects.length > 0
                    ? "menu-icon"
                    : "menu-icon menu-disabled"
                }
                onClick={deleteConfirm}
                title="Remove Project"
                alt="Remove Project"
                href="#"
              >
                <RemoveItemIcon
                  width={22}
                  height={22}
                  fill="#606060"
                  className="svg-icon"
                />
                <span className="menu-tip">Remove Infra</span>
              </a>
            </PageMenu>
            {filterPanelRef?.current?.getFilterPanel()}
          </FlexComp>
        </PageTitle>
        <TableWrapper
          marginTop={`${
            headerRef?.current?.offsetHeight + headerRef?.current?.offsetTop ||
            100
          }px`}
        >
          <IacProjects
            iac_projects={iac_projects}
            setUpdateProject={chooseProject2Update}
            setMarkedProjects={setMarkedProjects}
            fetchingFlag={fetching_projects_flag}
            ref={filterPanelRef}
            setRenderFlag={setRenderFlag}
          />
        </TableWrapper>
      </div>
      <CreateProjectPanel
        isOpen={openCreatePanel}
        toggle={togglePanel}
        createProjectAction={createIacProjectAction}
        updateProjectAction={updateIacProjectAction}
        createdFlag={createdFlag}
        clearCreateFlagAction={clearCreateFlagAction}
        updateProject={updateProject}
      />
    </ViewContainer>
  );
};

function mapStateToProps(state) {
  const { iac_projects, createdFlag, fetching_projects_flag } = state.iacs;
  return {
    iac_projects,
    createdFlag,
    fetching_projects_flag,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchIacProjectsAction: () => dispatch(iacActions.fetchIacProjectsAction()),
    createIacProjectAction: (payload) =>
      dispatch(iacActions.createIacProjectAction(payload)),
    updateIacProjectAction: (payload) =>
      dispatch(iacActions.updateIacProjectAction(payload)),
    deleteIacProjectAction: (payload) =>
      dispatch(iacActions.deleteIacProjectAction(payload)),
    clearCreateFlagAction: () => dispatch(iacActions.clearCreateFlagAction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IaCProjectLayout);
