import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { usersActions } from "../../../redux/actions";
import { connect } from "react-redux";
import UserListPanel from "./UserListPanel";
import BillingDataPanel from "./BillingDataPanel";
import AddUser from "./AddUser";
import UserProfile from "./UserProfile";
import { MainContext } from "../../../contexts";
import { currentRole, getParamsStr } from "../../../helpers/constants";
import * as global from "../../../helpers/constants";
import StyledTypo from "../../../components/Typo/StyledTypo";
import DeploymentsPanel from "../deploy/DeploymentsPanel";
import PoliciesPanel from "../deploy/PoliciesPanel";
import EnvironmentListPanel from "./EnvironmentListPanel";
import PlansPanel from "./PlansPanel";
import SettingsPanel from "./SettingsPanel";
import OrgListPanel from "./OrgListPanel";
import AddOrganization from "./AddOrganization";
import ReportPanel from "./ReportPanel";
import PaymentsListPanel from "./PaymentsListPanel";
import InfraPanel from "./InfraPanel";
import ProfilePanel from "./ProfilePanel";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import APIPanel from "./APIPanel";
import { useTitle } from "../../../helpers/useTtitle";
import CompliancePanel from "./CompliancePanel";


const ContainerWrapper = styled.div`
  width: calc(100% - 101px - 10px);
  margin-left: 101px;
  padding-top: 20px;
  padding-inline: 39px;
`;

const UserListMainPanel = (props) => (
  <div>
    <UserListPanel {...props} />
    {/*<BillingDataPanel />*/}
  </div>
);

const ManageLayout = (props) => {
  const {
    organizationList,
    fetchOrganizationList,
    userList,
    fetchUserListAction,
    expired_token,
    new_refresh_token,
    appList,
    iac_projects,
  } = props;

  const history = useHistory();

  const [tabs, setTabs] = useState(global.manageTabList[currentRole()]);
  const [selectedTab, setSelectedTab] = useState(tabs?.[0]);

  const location = useLocation();
  const [users, setUsers] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  useTitle(`${global.commonTitle} - Settings - ${selectedTab?.title}`)

  // useEffect(() => {
  //   setSelectedTab(
  //     location?.search
  //       ? new URLSearchParams(location?.search).get("tab")
  //       : tabs.filter((item) => item.active)?.[0]?.key
  //   );
  // }, [setSelectedTab]);

  useEffect(() => {
    fetchOrganizationList();
    fetchUserListAction();
  }, [fetchOrganizationList, fetchUserListAction]);

  useEffect(() => {
    if (!expired_token && new_refresh_token) {
      fetchOrganizationList();
      fetchUserListAction();
    }
  }, [
    expired_token,
    new_refresh_token,
    fetchOrganizationList,
    fetchUserListAction,
  ]);

  // useEffect(() => {
  //   if (selectedTab) {
  //     handleSelectedTab(selectedTab);
  //   }
  // }, [selectedTab]);

  const handleSelectedTab = (key) => {
    history.push("/manage");
    setTabs([...tabs.map((tab) => ({ ...tab, active: tab.key === key }))]);
  };

  useEffect(() => {
    setOrganizations([...organizationList]);
  }, [organizationList]);

  useEffect(() => {
    setUsers([...userList]);
  }, [userList]);

  return (
    <ContainerWrapper>
      <Tabs onSelect={(index) => setSelectedTab(tabs?.[index])}>
        <TabList>
          {tabs.map((tab) => (
            <Tab>
              <StyledTypo
                line="1.8"
                size={0.8}
                family="Poppins"
                fontWeight={300}
              >
                {tab.title}
              </StyledTypo>
            </Tab>
          ))}
        </TabList>

        {!!tabs.filter((tab) => tab.key === "profile").length > 0 && (
          <TabPanel>
            <ProfilePanel />
          </TabPanel>
        )}
        {!!tabs.filter((tab) => tab.key === "environments").length > 0 && (
          <TabPanel>
            <EnvironmentListPanel />
          </TabPanel>
        )}
        {!!tabs.filter((tab) => tab.key === "infra").length > 0 && (
          <TabPanel>
            <InfraPanel />
          </TabPanel>
        )}
        {!!tabs.filter((tab) => tab.key === "compliance").length > 0 && (
          <TabPanel>
            <CompliancePanel organizations={organizations} />
          </TabPanel>
        )}
        {!!tabs.filter((tab) => tab.key === "organizations").length > 0 && (
          <TabPanel>
            {location.pathname === "/manage" && (
                <OrgListPanel users={users} organizations={organizations} />
              )}
              {location.pathname === "/manage/add-org" && (
                <AddOrganization organizations={organizations} />
              )}
          </TabPanel>
        )}
        
        {!!tabs.filter((tab) => tab.key === "report").length > 0 && (
          <TabPanel>
            <ReportPanel applications={appList} iac_projects={iac_projects} />
          </TabPanel>
        )}
        {!!tabs.filter((tab) => tab.key === "settings").length > 0 && (
          <TabPanel>
            <SettingsPanel organizations={organizations} />
          </TabPanel>
        )}
        {!!tabs.filter((tab) => tab.key === "users").length > 0 && (
          <TabPanel>
            <UserListMainPanel
              users={users}
              organizations={organizations}
            />
          </TabPanel>
        )}
        {!!tabs.filter((tab) => tab.key === "api_keys").length > 0 && (
          <TabPanel>
              <APIPanel />
          </TabPanel>
        )}
        {!!tabs.filter((tab) => tab.key === "plans").length > 0 && (
          <TabPanel>
              <PlansPanel />
          </TabPanel>
        )}
        {!!tabs.filter((tab) => tab.key === "payments").length > 0 && (
          <TabPanel>
            <PaymentsListPanel />
          </TabPanel>
        )}
        
      </Tabs>
    </ContainerWrapper>
  );
};

function mapStateToProps(state) {
  const { organizationList, userList } = state.users;
  const { expired_token, new_refresh_token } = state.auth;
  const { appList } = state.applications;
  const { iac_projects } = state.iacs;
  return {
    organizationList,
    userList,
    expired_token,
    new_refresh_token,
    appList,
    iac_projects,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrganizationList: () =>
      dispatch(usersActions.fetchOrganizationListAction()),
    fetchUserListAction: () => dispatch(usersActions.fetchUserListAction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageLayout);
