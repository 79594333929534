import React, { useState } from 'react'
import styled from 'styled-components'
import StyledTypo from '../../components/Typo/StyledTypo'
import SGAssets from './analysis/SGAssets'

const GraphWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  padding: 50px 20px;
`

const APAGSGAssetsPreview = () => {
  const [staticGraph, setStaticGraph] = useState(true)
  const [panAndZoom, setPanAndZoom] = useState(true)
  const [collapsible, setCollapsible] = useState(false)
  const [directedGraph, setDirectedGraph] = useState(true)

  return (
    <GraphWrapper>
      <StyledTypo size={2}>SG Assets</StyledTypo>
      <SGAssets
        settings={{ staticGraph, panAndZoom, collapsible, directedGraph }}
      />
    </GraphWrapper>
  )
}

export default APAGSGAssetsPreview
