import React from "react";
import StyledTypo from "../../../components/Typo/StyledTypo";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../../assets/icons/cancel.svg";
import "react-modern-drawer/dist/index.css";
import { Col, Row } from "reactstrap";
import SlidePanel from "../../../components/SlidePanel/SlidePanel";
import CubeIcon from "../../../assets/images/3d-cube.png";
import {
  loadingBar,
  cspData,
  providerResourceTypes,
  providerComplianceType,
  NonComplianceStatusColor,
} from "../../../helpers/constants";
import GradientProgressBar from "../../../components/GradientProgressBar/GradientProgressBar";

const CloseWrapper = styled.div`
  border-radius: 10px;
  min-width: 45px;
  min-height: 40px;
  cursor: pointer;
  max-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TitleWrapper = styled.span`
  display: inline-block;
  width: 95%;
  height: 1.4em;
`;

const DividerWrapper = styled.div`
  height: 0;
  width: calc(100% + 60px);
  margin-left: -30px;
  border-bottom: 0.5px solid #ccc;
`;

const SummaryWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  word-break: break-all;
`;

const StyledStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 65px;

  margin-right: ${(props) => (props.mr ? props.mr : 10)}px;

  .mark {
    width: ${(props) => (props.size ? props.size : 12)}px;
    height: ${(props) => (props.size ? props.size : 12)}px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    // box-shadow: 0 8px 6px -3px ${(props) => props.color};
  }
`;

const SummaryItemContainer = styled.div`
  border-top: ${(props) => (props.idx === 0 ? "none" : "1px solid #f1f1f1")};
  padding: 10px;
  cursor: ${(props) => (props.cursor ? props.cursor : "default")};
  ${(props) =>
    props.hover &&
    `
    &:hover {
      background-color: #f7f7f7;
    }
  `}
`;

const ProjectsSummary = (props) => {
  const {
    provider,
    loading,
    compliance,
    compliance_infralist,
    resource,
    resource_infralist,
    isOpen,
    toggle,
  } = props;

  const history = useHistory();

  const statusLayout = [
    {
      label: "Name",
      width: 5,
      subLabel: null,
      key: "app_name",
    },
    {
      label: "Results",
      width: 5,
      subLabel: "Result",
      key: "result",
    },
    { label: "Compliance Score", width: 2, subLabel: null, key: "overall" },
  ];

  const getCompliancePercentage = (infra) => {
    let total = 0;
    let pass = 0;
    let result = 0;

    for (const key in NonComplianceStatusColor) {
      total += infra.result_by_compliance?.[key]?.total ?? 0;
      pass += infra.result_by_compliance?.[key]?.pass ?? 0;
    }

    result = total > 0 ? ((pass * 100) / total).toFixed() : 0;
    return result;
  };

  const getResourcePercentage = (infra) => {
    let total = 0;
    let pass = 0;
    let result = 0;

    for (const key in NonComplianceStatusColor) {
      total += infra.result?.[key]?.total ?? 0;
      pass += infra.result?.[key]?.pass ?? 0;
    }

    result = total > 0 ? ((pass * 100) / total).toFixed() : 0;
    return result;
  };

  return (
    <SlidePanel
      isOpen={isOpen}
      toggle={toggle}
      slideId="dashboard-detail-slide"
    >
      <div className="p-3 position-relative mb-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <CloseWrapper className="cursor-pointer" onClick={toggle}>
            <CloseIcon width={22} fill="#505050" />
          </CloseWrapper>
          <TitleWrapper>
            <StyledTypo className="text-center heading ln-2">
              {cspData.find((element) => element.key === provider)?.logo(25)}{" "}
              {cspData.find((element) => element.key === provider)?.title}
              {" - "}
              {compliance !== "" && (
                <>
                  <img
                    src={
                      Object.values(providerComplianceType[provider]).find(
                        (item) => (item.use ?? item.value) === compliance
                      )?.logo ?? CubeIcon
                    }
                    alt={"todo"}
                    width={30}
                    height={30}
                    className="mr-1"
                  />
                  {
                    Object.values(providerComplianceType[provider]).find(
                      (item) => (item.use ?? item.value) === compliance
                    )?.label
                  }
                </>
              )}
              {resource !== "" && (
                <>
                  <img
                    src={
                      Object.values(providerResourceTypes[provider]).find(
                        (item) => item.value === resource
                      )?.logo ?? CubeIcon
                    }
                    alt={"todo"}
                    width={30}
                    height={30}
                    className="mr-1"
                  />
                  {
                    Object.values(providerResourceTypes[provider]).find(
                      (item) => item.value === resource
                    )?.label
                  }
                </>
              )}
            </StyledTypo>
          </TitleWrapper>
        </div>
        <DividerWrapper className="mb-3" />
        {loading && (
          <div className="d-flex justify-content-center py-5 align-items-center">
            {loadingBar("Rings", 50, 50)}
            <StyledTypo
              className="pl-2"
              size={0.8}
              line={26.15 / 16}
              letter="0"
              fontWeight={400}
            >
              Loading...
            </StyledTypo>
          </div>
        )}
        {!loading && (
          <>
            <SummaryWrapper>
              {((compliance !== "" &&
                compliance_infralist?.results?.length > 0) ||
                (resource !== "" &&
                  resource_infralist?.results?.length > 0)) && (
                <Row className="p-2 align-items-center">
                  {statusLayout.map((status) => (
                    <Col md={status.width}>
                      <StyledTypo className="text-justify table-label label">
                        {status.label}
                      </StyledTypo>
                      {status.subLabel === "Result" && (
                        <div className="d-flex align-items-center flex-wrap mt-2">
                          {["Critical", "High", "Medium", "Low"].map((key) => (
                            <StyledStatusWrapper>
                              <StyledTypo
                                color="#687089"
                                size={0.6}
                                line={1}
                                letter="0"
                                family="Roboto"
                                fontWeight={300}
                              >
                                {key}
                              </StyledTypo>
                            </StyledStatusWrapper>
                          ))}
                        </div>
                      )}
                    </Col>
                  ))}
                </Row>
              )}
              {compliance !== "" &&
                compliance_infralist?.results?.length > 0 &&
                compliance_infralist?.results.map((infrastructure) => (
                  <SummaryItemContainer
                    hover
                    cursor="pointer"
                    onClick={() =>
                      history.push(`/cspm/${infrastructure.app_id}`)
                    }
                  >
                    <Row>
                      {statusLayout.map((status) => (
                        <Col md={status.width}>
                          {status.key === "app_name" && (
                            <div className="d-flex align-items-center">
                              <StyledTypo className="text-justify table-label">
                                {infrastructure.app_name}
                              </StyledTypo>
                            </div>
                          )}
                          {status.key === "result" && (
                            <div className="d-flex align-items-center flex-wrap">
                              {Object.keys(NonComplianceStatusColor).map(
                                (key) => (
                                  <StyledStatusWrapper
                                    color={NonComplianceStatusColor[key].color}
                                  >
                                    <div className="mark" />
                                    <StyledTypo
                                      size={0.9}
                                      line={1.4}
                                      letter="0"
                                      family="Roboto"
                                      fontWeight={300}
                                    >
                                      {infrastructure.result_by_compliance?.[
                                        key
                                      ]?.fail ?? 0}
                                    </StyledTypo>
                                  </StyledStatusWrapper>
                                )
                              )}
                            </div>
                          )}
                          {status.key === "overall" && (
                            <GradientProgressBar
                              color="#bdbdbd" color2="#bdbdbd"
                              value={getCompliancePercentage(infrastructure)}
                              maxValue="100"
                              rotation="90"
                              showNumber
                            />
                          )}
                        </Col>
                      ))}
                    </Row>
                  </SummaryItemContainer>
                ))}
              {compliance !== "" &&
                compliance_infralist?.results?.length === 0 && (
                  <div>There are no Infrastructures to show.</div>
                )}
            </SummaryWrapper>
            <SummaryWrapper>
              {resource !== "" &&
                resource_infralist?.results?.length > 0 &&
                resource_infralist?.results.map((infrastructure) => (
                  <SummaryItemContainer
                    hover
                    cursor="pointer"
                    onClick={() =>
                      history.push(`/cspm/${infrastructure.app_id}`)
                    }
                  >
                    <Row>
                      {statusLayout.map((status) => (
                        <Col md={status.width}>
                          {status.key === "app_name" && (
                            <div className="d-flex align-items-center">
                              <StyledTypo className="text-justify table-label">
                                {infrastructure.app_name}
                              </StyledTypo>
                            </div>
                          )}
                          {status.key === "result" && (
                            <div className="d-flex align-items-center flex-wrap">
                              {Object.keys(NonComplianceStatusColor).map(
                                (key) => (
                                  <StyledStatusWrapper
                                    color={NonComplianceStatusColor[key].color}
                                  >
                                    <div className="mark" />
                                    <StyledTypo
                                      size={0.9}
                                      line={1.4}
                                      letter="0"
                                      family="Roboto"
                                      fontWeight={300}
                                    >
                                      {infrastructure.result?.[key]?.fail ?? 0}
                                    </StyledTypo>
                                  </StyledStatusWrapper>
                                )
                              )}
                            </div>
                          )}
                          {status.key === "overall" && (
                            <GradientProgressBar
                              color="#bdbdbd" color2="#bdbdbd"
                              value={getResourcePercentage(infrastructure)}
                              maxValue="100"
                              rotation="90"
                              showNumber
                            />
                          )}
                        </Col>
                      ))}
                    </Row>
                  </SummaryItemContainer>
                ))}
              {resource !== "" && resource_infralist?.results?.length === 0 && (
                <div>There are no Infrastructures to show.</div>
              )}
            </SummaryWrapper>
          </>
        )}
      </div>
    </SlidePanel>
  );
};

export default ProjectsSummary;
