import React from "react";
import { useParams } from "react-router-dom";
import Page from "../../components/PageLayout/Page";
import ClusterLayout from "./kspm/ClusterLayout";

const KSPMCluster = (props) => {
  const params = useParams();

  return (
    <div>
      <Page noUpperCta>
        <ClusterLayout id={params.id} />
      </Page>
    </div>
  );
};

export default KSPMCluster;
