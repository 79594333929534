import {deploymentConstants} from "../constants";

const initialState = {
  deploymentAppsLoading: false,
  deploymentAppsLoadingError: '',
  deploymentApps: [],

  templates: [],

  deploy: 0,
  reconfiguration: 0,
  remediation: 0,
  destroy: 0,

  loading_task_id: [],
  type: '',
  applyStatus: 'request',

  createTemplateStatus: 0,
  createOrgTemplateStatus: 0,

  originTemplates: [],

  templateVars: {},
}

export function deployments(state = initialState, action) {
  switch (action.type) {
    case deploymentConstants.FETCH_DEPLOY_APPS_REQUEST:
      return {...state, deploymentAppsLoading: true, deploymentAppsLoadingError: ''}
    case deploymentConstants.FETCH_DEPLOY_APPS_SUCCESS:
      return {...state, deploymentApps: action.deployApps.results, deploymentAppsLoading: false}
    case deploymentConstants.FETCH_DEPLOY_APPS_FAILURE:
      return {...state, deploymentAppsLoadingError: action.error, deploymentAppsLoading: false, deploymentApps: []}

    case deploymentConstants.FETCH_TEMPLATES_REQUEST:
      return {...state,}
    case deploymentConstants.FETCH_TEMPLATES_SUCCESS:
      return {...state, templates: action.templates,}
    case deploymentConstants.FETCH_TEMPLATES_FAILURE:
      return {...state, templates: []}

    case deploymentConstants.APPLY_TERRAFORM_REQUEST:
      return {
        ...state, [action.loading]: action.appId,
        loading_task_id: [...state.loading_task_id.filter(item => item.app !== action.appId)]
      }
    case deploymentConstants.APPLY_TERRAFORM_SUCCESS:
      return {
        ...state, [action.loading]: 0,
        loading_task_id: [...state.loading_task_id
            .filter(item => item.app !== action.appId), {
          app: action.appId,
          task: action.task_id,
          type: action.loading
        }],
        type: action.loading
      }
    case deploymentConstants.APPLY_TERRAFORM_FAILURE:
      return {
        ...state, [action.loading]: 0,
        loading_task_id: [...state.loading_task_id.filter(item => item.app !== action.appId)]
      }

    case deploymentConstants.DESTROY_TERRAFORM_REQUEST:
      return {
        ...state, destroy: action.appId,
        loading_task_id: [...state.loading_task_id.filter(item => item.app !== action.appId)]
      }
    case deploymentConstants.DESTROY_TERRAFORM_SUCCESS:
      return {
        ...state, destroy: 0,
        loading_task_id: [...state.loading_task_id
            .filter(item => item.app !== action.appId), {app: action.appId, task: action.task_id, type: 'destroy'}],
        type: 'destroy'
      }
    case deploymentConstants.DESTROY_TERRAFORM_FAILURE:
      return {
        ...state, destroy: 0,
        loading_task_id: [...state.loading_task_id.filter(item => item.app !== action.appId)]
      }

    case deploymentConstants.TASK_STATUS_REQUEST:
      return {...state, applyStatus: 'request'}
    case deploymentConstants.TASK_STATUS_SUCCESS:
      return {
        ...state,
        loading_task_id: action.result.TaskID
            ? state.loading_task_id
            : [...state.loading_task_id.filter(item => item.task !== action.task_id)],
        applyStatus: action.result.TaskID ? state.applyStatus : 'success'
      }
    case deploymentConstants.TASK_STATUS_FAILURE:
      return {
        ...state,
        loading_task_id: [...state.loading_task_id.filter(item => item.task !== action.task_id)],
        applyStatus: 'failure'
      }

    case deploymentConstants.REMOVE_APPLY_STATUS:
      return {
        ...state,
        applyStatus: 'request'
      }

    case deploymentConstants.CREATE_NEW_DEPLOYMENT_TEMPLATE_REQUEST:
      return {...state, createTemplateStatus: 0}
    case deploymentConstants.CREATE_NEW_DEPLOYMENT_TEMPLATE_SUCCESS:
      return {...state, createTemplateStatus: 1}
    case deploymentConstants.CREATE_NEW_DEPLOYMENT_TEMPLATE_FAILURE:
      return {...state, createTemplateStatus: 2}

    case deploymentConstants.CREATE_NEW_ORGANIZATION_TEMPLATE_REQUEST:
      return {...state, createOrgTemplateStatus: 0}
    case deploymentConstants.CREATE_NEW_ORGANIZATION_TEMPLATE_SUCCESS:
      return {...state, createOrgTemplateStatus: 1}
    case deploymentConstants.CREATE_NEW_ORGANIZATION_TEMPLATE_FAILURE:
      return {...state, createOrgTemplateStatus: 2}

    case deploymentConstants.REMOVE_CREATE_TEMPLATE_STATUS:
      return {...state, createTemplateStatus: 0}

    case deploymentConstants.REMOVE_CREATE_ORG_TEMPLATE_STATUS:
      return {...state, createOrgTemplateStatus: 0}

    case deploymentConstants.FETCH_ORIGIN_TEMPLATES_REQUEST:
      return {...state, originTemplates: []}
    case deploymentConstants.FETCH_ORIGIN_TEMPLATES_SUCCESS:
      return {...state, originTemplates: action.templates}
    case deploymentConstants.FETCH_ORIGIN_TEMPLATES_FAILURE:
      return {...state, originTemplates: []}

    case deploymentConstants.FETCH_TEMPLATE_VARS_REQUEST:
      return {...state, templateVars: {...state.templateVars, [action.template_id]: {}}}
    case deploymentConstants.FETCH_TEMPLATE_VARS_SUCCESS:
      return {...state, templateVars: {...state.templateVars, [action.template_id]: action.vars.result}}
    case deploymentConstants.FETCH_TEMPLATE_VARS_FAILURE:
      return {...state, templateVars: {...state.templateVars, [action.template_id]: {}}}

    case deploymentConstants.APPLY_REMEDIATION_REQUEST:
      return {
        ...state, [action.loading]: action.appId,
        loading_task_id: [...state.loading_task_id.filter(item => item.app !== action.appId)]
      }
    case deploymentConstants.APPLY_REMEDIATION_SUCCESS:
      return {
        ...state, [action.loading]: 0,
        loading_task_id: [
          ...state.loading_task_id
              .filter(item => item.app !== action.appId && item.remediation !== action.remediation),
          {
            app: action.appId,
            task: action.task_id,
            type: action.loading,
            remediation: action.remediation,
            resource_type: action.resource_type
          }],
        type: action.loading
      }
    case deploymentConstants.APPLY_REMEDIATION_FAILURE:
      return {
        ...state, [action.loading]: 0,
        loading_task_id: [...state.loading_task_id.filter(item => item.app !== action.appId && item.remediation !== action.remediation)]
      }

    default:
      return {...state}
  }
}
