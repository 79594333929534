import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import StyledTypo from '../../../components/Typo/StyledTypo'
import CustomTextarea from '../../../components/Input/CustomTextarea'
import { credentialMap, regionOptions } from '../../../helpers/constants'
import CommonSelect from '../../../components/Select/CommonSelect'
import { FormGroup, Label, Input } from 'reactstrap'

const BorderWrapper = styled.div`
  border: 0.5px solid #ccc;
  padding: 15px 15px;
  border-radius: 10px;
  position: relative;
  margin-top: 30px;

  .title {
    padding: 0 5px;
    position: absolute;
    left: 15px;
    top: -10px;
    background-color: #fff;
  }
`

const AWSCredPartial = props => {
  const { credValue, setCredValue } = props

  const [credOption, setCredOption] = useState('accesskey')

  useEffect(() => {
    setCredValue({})
  }, [credOption])

  const handleCredChange = (parentKey, key, val, type = 'text') => {
    setCredValue(credValue => ({
      ...credValue,
      [parentKey]: { ...(credValue[parentKey] ?? {}), [key]: val }
    }))
  }

  return (
    <div>
      <FormGroup className='d-flex mt-4' tag='fieldset'>
        <FormGroup
          check
          onClick={() =>
            credOption !== 'accesskey' && setCredOption('accesskey')
          }
        >
          <Input
            name='key_based'
            type='radio'
            checked={credOption === 'accesskey'}
          />{' '}
          <Label check>Key-based</Label>
        </FormGroup>
        <FormGroup
          check
          className='ml-4'
          onClick={() =>
            credOption === 'accesskey' && setCredOption('rolebased')
          }
        >
          <Input
            name='role_based'
            type='radio'
            checked={credOption !== 'accesskey'}
          />{' '}
          <Label check>Role-based</Label>
        </FormGroup>
      </FormGroup>
      {credOption === 'accesskey' ? (
        credentialMap.aws.map(each => (
          <BorderWrapper key={each.key}>
            <div className='title'>
              <StyledTypo size='0.8'>{each.label}</StyledTypo>
            </div>
            {each.children &&
              each.children.map((category, idx) => {
                if (category.type === 'text') {
                  return (
                    <CustomTextarea
                      key={idx}
                      label={category.label}
                      value={credValue?.[each.key]?.[category.key] ?? ''}
                      setValue={val =>
                        handleCredChange(each.key, category.key, val)
                      }
                      disabled={false}
                    />
                  )
                }
                if (category.type === 'select') {
                  return (
                    <CommonSelect
                      key={idx}
                      value={
                        category.options.filter(
                          item =>
                            item.value === credValue?.[each.key]?.[category.key]
                        )?.[0]
                      }
                      setValue={val =>
                        handleCredChange(each.key, category.key, val?.value)
                      }
                      options={category.options}
                      label={category.label}
                    />
                  )
                }
              })}
          </BorderWrapper>
        ))
      ) : (
        <BorderWrapper>
          <div className='title'>
            <StyledTypo size='0.8'>Role-based Credential</StyledTypo>
          </div>
          <div className='mb-2 mt-2'>
            <StyledTypo size='0.8'>
              1. Create the assume role with trust relationship to matos system
              user and valid scan permission in client infras
            </StyledTypo>
            <StyledTypo size='0.8' className='mt-1'>
              2. User download cloudformation template (link will be provide by
              API) and manually run the script
            </StyledTypo>
            <StyledTypo size='0.8' className='mt-1'>
              3. Get the created role_arn and submit into form
            </StyledTypo>
          </div>
          <CustomTextarea
            label='Role ARN'
            value={credValue?.['credential']?.['role_arn'] ?? ''}
            setValue={val => handleCredChange('credential', 'role_arn', val)}
            disabled={false}
          />
          <CommonSelect
            value={
              regionOptions.filter(
                item =>
                  item.value === credValue?.['credential']?.['region']
              )?.[0]
            }
            setValue={val =>
              handleCredChange('credential', 'region', val?.value)
            }
            options={regionOptions}
            label="Region"
          />
        </BorderWrapper>
      )}
    </div>
  )
}

export default AWSCredPartial
