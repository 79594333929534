import React, { useEffect, useState, useRef } from 'react'

import { applicationActions } from '../../../redux/actions'
import { connect } from 'react-redux'
import StyledTypo from '../../../components/Typo/StyledTypo'
import InfraList from '../cloud/InfraList/InfraList'
import { ReactComponent as AddItemIcon } from '../../../assets/icons/add_item.svg'
import { ReactComponent as RemoveItemIcon } from '../../../assets/icons/remove_item.svg'
import { confirmAlert } from 'react-confirm-alert'
// import CreateInfraPanel from './Infra/CreateInfraPanel'
import CreateInfraPanel from '../cloud/Infra/CreateInfraPanel'
import {
  ViewContainer,
  // PageTitle,
  PageMenu,
  FlexComp
} from '../../../components/PageLayout/Containers'
import { TableWrapper } from '../../../components/PageLayout/Table'
import styled from 'styled-components'
import APIKeyTable from './APIKeyTable'
import { iacActions } from '../../../redux/actions'
import APIKeyCreatePanel from './APIKeyCreatePanel'


const PanelWrapper = styled.div`
  padding: 15px;
  width: 100%;
  // border: 1px solid #f1f1f1;
  // box-sizing: border-box;
  // border-radius: 0 20px 20px 20px;

  .panel-title-header {
    padding: 0 0 47px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const PageTitle = styled.div`
  // background: #fff;
  border-radius: 5px;
  // border: 1px solid rgb(234, 234, 234);
  // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.075);
  margin-top: 0px;
  min-height: 64px;
  padding-left: 24px;
  padding: 12px;
  padding: 12px 0px;
  position: relative;
  z-index: 100;
  flex-wrap: wrap;
  row-gap: 10px;

  .page-menu {
    font-family: Poppins;
    gap: 10px;
    left: calc(100% - ${props => props.menuLeft ?? '920px'});
    position: fixed;
  }
`


const APIPanel = (props) => {
  const {
    fetchIacProjectsAction,
    iac_projects,
    createIacProjectAction,
    createdFlag,
    clearCreateFlagAction,
    updateIacProjectAction,
    deleteIacProjectAction,
    fetching_projects_flag,
  } = props;
  const filterPanelRef = useRef();
  const headerRef = useRef();

  const [openCreatePanel, setOpenCreatePanel] = useState(false);
  const [updateProject, setUpdateProject] = useState(null);
  const [markedProjects, setMarkedProjects] = useState([]);
  const [renderFlag, setRenderFlag] = useState(false);

  const togglePanel = () => {
    setOpenCreatePanel((openCreatePanel) => !openCreatePanel);
  };

  useEffect(() => {
    fetchIacProjectsAction();
  }, []);

  useEffect(() => {
    if (!openCreatePanel) {
      setUpdateProject(null);
    }
  }, [openCreatePanel]);

  const chooseProject2Update = (project) => {
    setUpdateProject(project);
    togglePanel();
  };

  const deleteConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>{`You want to delete IaC project named ${markedProjects?.[0]?.display_name}?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                deleteIacProjectAction(markedProjects?.[0]);
                onClose();
              }}
            >
              Confirm
            </button>
          </div>
        );
      },
    });
  };

  return (
    <PanelWrapper>
      <div className="d-flex flex-column">
        <PageTitle
          className="d-flex align-items-center justify-content-between"
          ref={headerRef}
        >

          <FlexComp>
            <StyledTypo className="ml-2 heading">
              API Keys
            </StyledTypo>
            <PageMenu className="d-flex align-items-center ml-2">
              {/* <a
                className="menu-icon"
                onClick={togglePanel}
                title="Add Infra"
                alt="Add Infra"
                href="#"
              >
                <AddItemIcon
                  width={22}
                  height={22}
                  fill="#606060"
                  className="svg-icon"
                />
                <span className="menu-tip">Add Infra</span>
              </a>
              <a
                className={
                  markedInfras.length > 0
                    ? "menu-icon"
                    : "menu-icon menu-disabled"
                }
                onClick={deleteConfirm}
                title="Remove Infra"
                alt="Remove Infrastructure"
                href="#"
              >
                <RemoveItemIcon
                  width={22}
                  height={22}
                  fill="#606060"
                  className="svg-icon"
                />
                <span className="menu-tip">Remove Infra</span>
              </a> */}
            </PageMenu>
            {/* {infraListRef?.current?.getFilterPanel(true)} */}
          </FlexComp>
          
        </PageTitle>
        <TableWrapper
          marginTop={30}
        >
          <APIKeyTable
            iac_projects={iac_projects}
            setUpdateProject={chooseProject2Update}
            setMarkedProjects={setMarkedProjects}
            fetchingFlag={fetching_projects_flag}
            ref={filterPanelRef}
            setRenderFlag={setRenderFlag}
          />
        </TableWrapper>
      </div>
      <APIKeyCreatePanel
        isOpen={openCreatePanel}
        toggle={togglePanel}
        createProjectAction={createIacProjectAction}
        updateProjectAction={updateIacProjectAction}
        createdFlag={createdFlag}
        clearCreateFlagAction={clearCreateFlagAction}
        updateProject={updateProject}
      />
    </PanelWrapper>
  );
};
function mapStateToProps (state) {
  const { iac_projects, createdFlag, fetching_projects_flag } = state.iacs
  return {
    iac_projects,
    createdFlag,
    fetching_projects_flag
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchIacProjectsAction: () => dispatch(iacActions.fetchIacProjectsAction()),
    createIacProjectAction: payload =>
      dispatch(iacActions.createIacProjectAction(payload)),
    updateIacProjectAction: payload =>
      dispatch(iacActions.updateIacProjectAction(payload)),
    deleteIacProjectAction: payload =>
      dispatch(iacActions.deleteIacProjectAction(payload)),
    clearCreateFlagAction: () => dispatch(iacActions.clearCreateFlagAction())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(APIPanel);
