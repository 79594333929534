import React from 'react'
import {Row, Col} from 'reactstrap'
import styled from 'styled-components'
import {ReactComponent as AnsibleIcon} from "../../../assets/icons/ansible_logo_icon.svg";
import {ReactComponent as TerraformIcon} from "../../../assets/icons/terraform_logo_icon.svg";
import {ReactComponent as InfoIcon} from "../../../assets/icons/info_icon.svg";
import StyledTypo from "../../../components/Typo/StyledTypo";
import moment from 'moment'
import EffectedButton from "../../../components/Buttons/EffectedButton";
import {ReactComponent as RunningIcon} from "../../../assets/icons/retry_icon.svg";
import {ReactComponent as DeleteIcon} from "../../../assets/icons/trash_icon.svg";

const Container = styled.div`
 width: 100%;
 padding: 21px 18px;
 min-height: 200px;
 max-width: 396px;
 /* white */
  background: #FFFFFF;
  border: 1px solid #E4E7EA;
  box-sizing: border-box;
  border-radius: 20px;
  word-break: break-all;
  
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .divider {
    height: 0;
    margin: 20px -18px;
    border-top: 1px solid #EAEDEF;
  }
`

const DisplayDetailDeployment = (props) => {
  const {data} = props
  return (
      <Container>
        <div className="title">
          <div className="d-flex align-items-center">
            {data.devops_tool === 'ansible' ? <AnsibleIcon/> : data.devops_tool === 'terraform' ? <TerraformIcon/> :
                <AnsibleIcon/>}
            <StyledTypo className="ml-3" color="#858585" size={1} line={19.2 / 16} letter="0" family="Poppins"
                        fontWeight={700}>
              {data.name ?? 'sched-ansible-cilium-policy-configure'}
            </StyledTypo>
            {/*<div className="m-tooltip">*/}
            {/*  <InfoIcon/>*/}
            {/*  <span className="tooltiptext">*/}
            {/*      {data.description ?? 'description'}*/}
            {/*  </span>*/}
            {/*</div>*/}
          </div>
        </div>
        <div className="divider"/>
        <div className="content">
          <StyledTypo color="#858585" size={1} line={26.1 / 16} letter="0" family="Poppins" fontWeight={400}>
            {data.repo_url ?? 'https://github.com/cloudmatos/sched-ansible-cilium-policy-configure'} - {data.branch ?? 'main'}/{data.path ?? 'deployment'}
          </StyledTypo>
          <Row className="mt-3 pt-2 mb-4">
            <Col md={6}>
              <StyledTypo color="#858585" size={1} line={24 / 16} letter="0" family="Poppins" fontWeight={500}>
                Created
              </StyledTypo>
              <StyledTypo color="#858585" size={1} line={26.1 / 16} letter="0" family="Poppins" fontWeight={400}>
                {data.createdDate ? moment(data.createdDate).format("MM-DD-YYYY HH:mm") : moment().format("MM-DD-YYYY HH:mm")}
              </StyledTypo>
            </Col>
            <Col md={6}>
              <StyledTypo color="#858585" size={1} line={24 / 16} letter="0" family="Poppins" fontWeight={500}>
                Updated
              </StyledTypo>
              <StyledTypo color="#858585" size={1} line={26.1 / 16} letter="0" family="Poppins" fontWeight={400}>
                {data.updatedDate ? moment(data.updatedDate).format("MM-DD-YYYY HH:mm") : moment().format("MM-DD-YYYY HH:mm")}
              </StyledTypo>
            </Col>
          </Row>
          <div className="action d-flex justify-content-end mb-2">
            <div className="mr-3">
              <EffectedButton padding={0} height={40} noSpan>
                <DeleteIcon fill="#FCFDFF"/>
                <StyledTypo className="ml-2" color="#fff" size={1.125} line={25.2 / 18} letter="0" family="Roboto"
                            fontWeight={700}>Delete</StyledTypo>
              </EffectedButton>
            </div>
            <div>
              <EffectedButton padding={0} height={40} noSpan background="#485DCD">
                <RunningIcon fill="#FCFDFF"/>
                <StyledTypo className="ml-2" color="#fff" size={1.125} line={25.2 / 18} letter="0" family="Roboto"
                            fontWeight={700}>Run</StyledTypo>
              </EffectedButton>
            </div>
          </div>
        </div>
      </Container>
  )
}

export default DisplayDetailDeployment
