import React from "react";
import styled from "styled-components";

import corner_gradient from "../../assets/images/corner_gradient.png";
import waves_top from "../../assets/images/waves_bottom.png";
import circle_image from "../../assets/images/circle.png";

const RightCornerGradient = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
`;

const TopWave = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
`;


const LeftCircle = styled.div`
  position: absolute;
  left: 0;
  top: 500px;
  z-index: -1;
`;


const Background = () => {
  return (
    <>
      <RightCornerGradient>
        <img src={corner_gradient} alt="right corner gradient" />
      </RightCornerGradient>
      <TopWave>
        <img src={waves_top} width="85%" height="40%" alt="top wave" />
      </TopWave>
      {/* <BackgroundImage>
        <img
          src={background_img}
          width="100%"
          height="40%"
          alt="background img"
        />
      </BackgroundImage> */}
      {/* <BottomWave>
        <img src={waves_bottom} width="100%" alt="bottom wave" />
      </BottomWave> */}
      <LeftCircle>
        <img src={circle_image} alt="left circle" />
      </LeftCircle>
    </>
  );
};

export default Background;
