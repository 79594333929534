import { usersConstants } from "../constants";

const initialState = {
  organizationList: [],
  userList: [],
  updateUserFlag: 0,
  createUserFlag: 0,
  createOrgFlag: 0,
}

export function users(state = initialState, action) {
  switch (action.type) {
    case usersConstants.FETCH_ORG_LIST_REQUEST:
      return {...state, }
    case usersConstants.FETCH_ORG_LIST_SUCCESS:
      return {...state, organizationList: action.orgList.result}
    case usersConstants.FETCH_ORG_LIST_FAILURE:
      return {...state, organizationList: []}

    case usersConstants.FETCH_USER_LIST_REQUEST:
      return {...state, }
    case usersConstants.FETCH_USER_LIST_SUCCESS:
      return {...state, userList: action.userList}
    case usersConstants.FETCH_USER_LIST_FAILURE:
      return {...state, userList: []}

    case usersConstants.CREATE_USER_REQUEST:
      return {...state, createUserFlag: 1}
    case usersConstants.CREATE_USER_SUCCESS:
      return {...state, createUserFlag: 2}
    case usersConstants.CREATE_USER_FAILURE:
      return {...state, createUserFlag: 3}

    case usersConstants.UPDATE_USER_REQUEST:
      return {...state, updateUserFlag: 1}
    case usersConstants.UPDATE_USER_SUCCESS:
      return {...state, updateUserFlag: 2}
    case usersConstants.UPDATE_USER_FAILURE:
      return {...state, updateUserFlag: 3}

    case usersConstants.CLEAR_CREATE_USER_FLAG:
      return {...state, createUserFlag: 0}
    case usersConstants.CLEAR_UPDATE_USER_FLAG:
      return {...state, updateUserFlag: 0}

    // case usersConstants.MODIFY_USER_STATUS_SUCCESS:
    //   return {
    //     ...state,
    //     userList: [
    //         action.user.user,
    //         ...state.userList.filter(user => user.UUID !== action.user.user.UUID)
    //     ]
    //   }

    case usersConstants.MODIFY_ORG_STATUS_SUCCESS:
      return {
        ...state,
        organizationList: [
            action.organization,
            ...state.organizationList.filter(org => org.id !== action.organization.id)
        ]
      }

    case usersConstants.CREATE_ORG_SUCCESS:
      return {
        ...state,
        createOrgFlag: 2,
        organizationList: [
            action.organization,
            ...state.organizationList
        ]
      }
    case usersConstants.CREATE_ORG_FAILURE:
      return {
        ...state,
        createOrgFlag: 3
      }

    case usersConstants.CLEAR_CREATE_ORG_FLAG:
      return {
        ...state,
        createOrgFlag: 0
      }
    default:
      return { ...state }
  }
}
