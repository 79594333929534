import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import StyledTypo from "../../../components/Typo/StyledTypo";
import EffectedButton from "../../../components/Buttons/EffectedButton";
import {
  getCurrentOrganization,
  getCurrentUser,
} from "../../../helpers/constants";
import CommonInput from "../../../components/Input/CommonInput";
import { manageActions } from "../../../redux/actions";
import Switch from "react-switch";

const PanelWrapper = styled.div`
  padding: 20px 0;
  width: 100%;
  // border: 1px solid #f1f1f1;
  // box-sizing: border-box;
  // border-radius: 0 15px 15px;

  .panel-title-header {
    padding: 0 0 47px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const PanelHeader = styled.div`
  padding: 0px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PanelBody = styled.div`
  padding: 10px 26px;
`;

const SettingItemContainer = styled.div`
  padding: 15px 0 0;
`;

const SettingsPanel = (props) => {
  const { organizations, updateOrganizationSettingsAction } = props;

  const [notificationEmail, setNotificationEmail] = useState("");

  const [notificationSetting, setNotificationSetting] = useState({});

  useEffect(() => {
    if (organizations) {
      const currentOrganization = organizations.filter(
        (org) => org.id === getCurrentOrganization()
      )?.[0];

      setNotificationSetting(currentOrganization?.settings ?? {});
    }
  }, [organizations]);

  const handleSubmit = () => {
    updateOrganizationSettingsAction(notificationSetting);
  };

  const handleChangeSetting = (type, prop, value) => {
    setNotificationSetting((notificationSetting) => ({
      ...notificationSetting,
      [type]: { ...(notificationSetting?.[type] ?? {}), [prop]: value },
    }));
  };

  return (
    <PanelWrapper>
      <PanelHeader>
        <StyledTypo className="heading label">Notification Settings</StyledTypo>
        {getCurrentUser()?.role === "org-admin" && (
          <div>
            <EffectedButton
              padding={0}
              height={40}
              noSpan
              onClick={handleSubmit}
            >
              <StyledTypo className="ml-2 label" color="#fff">
                Save Settings
              </StyledTypo>
            </EffectedButton>
          </div>
        )}
      </PanelHeader>
      <PanelBody>
        <SettingItemContainer>
          <StyledTypo className="label">Email Notification</StyledTypo>
          <div className="row mt-4">
            <div className="col-12 d-flex align-items-center mb-2">
              <Switch
                onChange={(checked) =>
                  handleChangeSetting("email", "enabled", checked)
                }
                checked={notificationSetting?.email?.enabled}
                onColor="#65C2D7"
                offColor="#ff487f"
              />
              <StyledTypo className={"ml-3"}>
                {notificationSetting?.email?.enabled ? "Disable" : "Enable"}{" "}
                Notification
              </StyledTypo>
            </div>
            <div className="col-4">
              <CommonInput
                label="Email"
                value={notificationSetting?.email?.email}
                setValue={(val) => handleChangeSetting("email", "email", val)}
                active={false}
                placeholder="Notification Email"
              />
            </div>
          </div>
        </SettingItemContainer>
        <SettingItemContainer>
          <StyledTypo className="label">Slack Notification</StyledTypo>
          <div className="row mt-4">
            <div className="col-12 d-flex align-items-center mb-2">
              <Switch
                onChange={(checked) =>
                  handleChangeSetting("slack", "enabled", checked)
                }
                checked={notificationSetting?.slack?.enabled}
                onColor="#65C2D7"
                offColor="#ff487f"
              />
              <StyledTypo className={"ml-3"}>
                {notificationSetting?.slack?.enabled ? "Disable" : "Enable"}{" "}
                Notification
              </StyledTypo>
            </div>
            <div className="col-4">
              <CommonInput
                label="Webhook URL"
                value={notificationSetting?.slack?.webhook}
                setValue={(val) => handleChangeSetting("slack", "webhook", val)}
                active={false}
                placeholder="Webhook URL"
              />
            </div>
            <div className="col-4">
              <CommonInput
                label="Username"
                value={notificationSetting?.slack?.username}
                setValue={(val) =>
                  handleChangeSetting("slack", "username", val)
                }
                active={false}
                placeholder="Username"
              />
            </div>
          </div>
        </SettingItemContainer>
      </PanelBody>
    </PanelWrapper>
  );
};

function mapStateToProps(state) {}

function mapDispatchToProps(dispatch) {
  return {
    updateOrganizationSettingsAction: (settingData) =>
      dispatch(manageActions.updateOrganizationSettingsAction(settingData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPanel);
