import React from "react";
import Page from "../../components/PageLayout/Page";
import PentestingLayout from "./pentesting/PentestingLayout";

const Pentesting = () => {
  return (
    <div>
      <Page>
        <PentestingLayout />
      </Page>
    </div>
  );
};

export default Pentesting;
