/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import StyledTypo from "../../../../components/Typo/StyledTypo";
import styled from "styled-components";
import { ReactComponent as ArrowUp } from "../../../../assets/icons/up_arrow_icon.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/icons/down_arrow_icon.svg";
import { ReactComponent as ListIcon } from "../../../../assets/icons/list-show-all.svg";
import { ReactComponent as TileIcon } from "../../../../assets/icons/tile.svg";
import ReactPaginate from "react-paginate";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filter_icon.svg";
import { ReactComponent as FilterClearIcon } from "../../../../assets/icons/filter_clear_icon.svg";
// import { ReactComponent as EditIcon } from "../../../../assets/icons/edit_rec_icon.svg";
import GradientProgressBar from '../../../../components/GradientProgressBar/GradientProgressBar'
import StyledCheckbox from "../../../../components/Input/StyledCheckbox";
import CustomInput from "../../../../components/Input/CustomInput";
import CommonSelect from "../../../../components/Select/CommonSelect";
import moment from "moment";
import {
  loadingBar,
  NonComplianceStatusColor,
  platformList,
  cspData,
  auditStatus,
  aggregatedData
} from "../../../../helpers/constants";
import { connect } from "react-redux";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close-icon.svg";
import { iacActions } from "../../../../redux/actions";
import { useHistory } from "react-router-dom";
import EffectedButton from "../../../../components/Buttons/EffectedButton";
import { MainContext } from "../../../../contexts";
import { TableWrapper } from "../../../../components/PageLayout/Table";
import {
  FilterWrapper,
  FilterBodyContainer,
  FastFilterBodyContainer,
} from "../../../../components/PageLayout/Filter";
import InfraCards from "./InfraCard";
import InfraDetail from "./InfraDetail";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 26px;
`;

const EachCell = styled.div`
  width: ${(props) => props.width};
  padding-right: ${(props) => (props.pr ? props.pr : 0)}px;
  word-break: break-word;
`;

const RecordContainer = styled.div`
  border-top: 1px solid #fff;
  transition: border 500ms ease;
  background: ${(props) => (props.active ? "#f1f1f1" : "transparent")};
  position: relative;

  .floating-button {
    display: none;
    z-index: 100;
  }
  &:hover .floating-button {
    display: block;
  }
`;

const RecordWrapper = styled.div`
  padding: 11px 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .text-cell {
    color: #505050;
  }
  cursor: pointer;
  &:hover {
    border-radius: 5px;
    background-color: #f1f1f1;
  }
`;

const FloatingButtonWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 7px;
`;

const ShowingWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  position: fixed;
  right: 40px;
  bottom: 20px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100vw - 200px);
  background-color: #fff;

  .page-link:focus {
    box-shadow: none !important;
  }
  ul.pagination {
    margin-bottom: 0;
    font-size: 0.8rem;
    font-weight: 0.4;
    font-family: "Roboto";
  }
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const selectEmptyResourceType = {
  label: "Select...",
  value: "",
};

const StyledStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 65px;

  margin-right: ${(props) => (props.mr ? props.mr : 20)}px;

  .mark {
    width: ${(props) => (props.size ? props.size : 12)}px;
    height: ${(props) => (props.size ? props.size : 12)}px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    // box-shadow: 0 8px 6px -3px ${(props) => props.color};
  }
`;

const FastFilterPanel = props => {
  const {
    searchText,
    setSearchText,
    selectedPlatform,
    setSelectedPlatform,
    selectedFilterType,
    setSelectedFilterType,
    setSelectedEnv,
    selectedEnv,
    envOptions,
  } = props

  const [filterBarOpen, setFilterBarOpen] = useState(false)

  const clearFilter = () => {
    setSelectedPlatform(selectEmptyResourceType)
    setSelectedEnv(selectEmptyResourceType)
    setFilterBarOpen(false)
  }
  useEffect(() => {
    if (selectedFilterType) {
      setSelectedFilterType(null)
      setFilterBarOpen(true)
    }
  }, [selectedFilterType, setSelectedFilterType])

  // const checkFilterContent = () => {
  //   return !!selectedPlatform?.value?.length
  // }

  return (
    <FilterWrapper left='520px'>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center mr-3 filter-menu ml-5 pl-3'>
          {/* <a
            className='menu-icon'
            onClick={() => setFilterBarOpen(!filterBarOpen)}
            title='Filter By'
            alt='Filter'
            href='#'
          >
            <FilterIcon
              width={22}
              height={22}
              fill='#606060'
              className='svg-icon'
            />
            <span className='menu-tip'>Filter by</span>
          </a>
          <a
            className={
              checkFilterContent() ? 'menu-icon' : 'menu-icon menu-disabled'
            }
            onClick={clearFilter}
            title='Filter Clear'
            alt='Filter Clear'
            href='#'
          >
            <FilterClearIcon
              width={22}
              height={22}
              fill='#606060'
              className='svg-icon'
            />
            <span className='menu-tip'>Filter clear</span>
          </a> */}
        </div>
        {[{label: 'All', value: ''}, ...platformList].map(platform => (
          <StyledCheckbox
              handleOnChange={() => {
                setSelectedPlatform(platform)
              }}
              checked={
                selectedPlatform?.value === platform.value
              }
              name={platform.label}
              fontWeight={300}
              fontSize={0.8}
              className="mr-3 mt-2"
            />
        ))}
        {/* <div>
          <CustomInput
            minWidth={350}
            placeholder='Search'
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
        </div> */}
      </div>
      {/* {filterBarOpen && (
        <FastFilterBodyContainer>
          <CloseWrapper
            className='cursor-pointer'
            onClick={() => setFilterBarOpen(false)}
          >
            <CloseIcon width={15} stroke='#A4AFB7' />
          </CloseWrapper>
          <div className='ml-3' style={{ minWidth: 250 }}>
            <CommonSelect
              value={selectedPlatform}
              setValue={setSelectedPlatform}
              options={platformList}
              label={`Cloud Service Provider`}
              isClear
            />
          </div>
          <div className='ml-3' style={{ minWidth: 250 }}>
            <CommonSelect
              value={selectedEnv}
              setValue={setSelectedEnv}
              options={envOptions}
              label={`Environment`}
              isClear
            />
          </div>
        </FastFilterBodyContainer>
      )} */}
    </FilterWrapper>
  )
}


const FilterPanel = (props) => {
  const {
    searchText,
    setSearchText,
    selectedPlatform,
    setSelectedPlatform,
    selectedFilterType,
    setSelectedFilterType,
    setSelectedEnv,
    selectedEnv,
    envOptions,
    viewMode,
    setViewMode
  } = props;

  const [filterBarOpen, setFilterBarOpen] = useState(false);

  const clearFilter = () => {
    setSelectedPlatform(selectEmptyResourceType);
    setSelectedEnv(selectEmptyResourceType);
    setFilterBarOpen(false);
  };
  useEffect(() => {
    if (selectedFilterType) {
      setSelectedFilterType(null);
      setFilterBarOpen(true);
    }
  }, [selectedFilterType, setSelectedFilterType]);

  const checkFilterContent = () => {
    return !!selectedPlatform?.value?.length || !!selectedEnv?.value?.length;
  };

  return (
    <FilterWrapper left="520px">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center mr-3 filter-menu">
          <a
            className={`menu-icon ${viewMode === 'list' ? 'menu-hidden' : ''}`}
            onClick={() => setViewMode('list')}
            title="List View"
            alt="Filter"
            href="#"
          >
            <ListIcon
              width={22}
              height={22}
              fill="#606060"
              className="svg-icon"
            />
            <span className="menu-tip">List View</span>
          </a>
          <a
            className={`menu-icon ${viewMode === 'card' ? 'menu-hidden' : ''}`}
            onClick={() => setViewMode('card')}
            title="Card View"
            alt="Filter"
            href="#"
          >
            <TileIcon
              width={22}
              height={22}
              fill="#606060"
              className="svg-icon"
            />
            <span className="menu-tip">List View</span>
          </a>
          <a
            className="menu-icon"
            onClick={() => setFilterBarOpen(!filterBarOpen)}
            title="Filter By"
            alt="Filter"
            href="#"
          >
            <FilterIcon
              width={22}
              height={22}
              fill="#606060"
              className="svg-icon"
            />
            <span className="menu-tip">Filter by</span>
          </a>
          <a
            className={
              checkFilterContent() ? "menu-icon" : "menu-icon menu-disabled"
            }
            onClick={clearFilter}
            title="Filter Clear"
            alt="Filter Clear"
            href="#"
          >
            <FilterClearIcon
              width={22}
              height={22}
              fill="#606060"
              className="svg-icon"
            />
            <span className="menu-tip">Filter clear</span>
          </a>
        </div>
        <div>
          <CustomInput
            minWidth={350}
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>
      {filterBarOpen && (
        <FilterBodyContainer>
          <CloseWrapper
            className="cursor-pointer"
            onClick={() => setFilterBarOpen(false)}
          >
            <CloseIcon width={15} stroke="#A4AFB7" />
          </CloseWrapper>
          <div className="ml-3" style={{ minWidth: 250 }}>
            <CommonSelect
              value={selectedPlatform}
              setValue={setSelectedPlatform}
              options={platformList}
              label={`Cloud Service Provider`}
              isClear
            />
          </div>
          <div className="ml-3" style={{ minWidth: 250 }}>
            <CommonSelect
              value={selectedEnv}
              setValue={setSelectedEnv}
              options={envOptions}
              label={`Environment`}
              isClear
            />
          </div>
        </FilterBodyContainer>
      )}
    </FilterWrapper>
  );
};

const TableHeader = (props) => {
  const { columns, currentSort, setCurrentSort, data, setData } = props;

  const handleSetSort = (access) => {
    setCurrentSort({
      access,
      order:
        (Array.isArray(currentSort.access)
          ? currentSort.access.join()
          : currentSort.access) ===
        (Array.isArray(access) ? access.join() : access)
          ? !currentSort.order
          : true,
    });
  };

  return (
    <HeaderWrapper>
      {columns &&
        columns.map((column, idx) => (
          <EachCell
            width={column.width ?? "100%"}
            key={idx}
            className={` ${
              column.title === "Results" ? "flex-column align-items-start" : ""
            }d-flex align-items-center ${column.sort ? "cursor-pointer" : ""} ${
              column.title === "Action" ? "justify-content-end" : ""
            } `}
            pr={10}
            onClick={() => {
              if (column.sort) {
                handleSetSort(column.access);
              }
            }}
          >
            {column.type === "checkbox" && (
              <StyledCheckbox
                handleOnChange={() => {
                  const allChecked =
                    data.filter((item) => item.checked).length === data.length;
                  setData((dataList) =>
                    dataList.map((item) => ({ ...item, checked: !allChecked }))
                  );
                }}
                className="mt-2"
                checked={
                  data.filter((item) => item.checked).length === data.length
                }
              />
            )}
            {column.sort &&
            (Array.isArray(currentSort.access)
              ? currentSort.access.join()
              : currentSort.access) ===
              (Array.isArray(column.access)
                ? column.access.join()
                : column.access) ? (
              currentSort.order ? (
                <ArrowUp fill={"#A4AFB7"} className="mr-3" />
              ) : (
                <ArrowDown fill={"#A4AFB7"} className="mr-3" />
              )
            ) : (
              <div />
            )}
            <div
              className={
                column.title === "Action"
                  ? "ml-auto"
                  : column.type === "overview"
                  ? "ml-auto mr-auto"
                  : ""
              }
            >
              <StyledTypo
                color={"#505050"}
                size={0.8}
                line={1.2}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                {column.title}
              </StyledTypo>
            </div>
            <div>
              {column.title === "Results" && (
                <div className="d-flex align-items-center flex-wrap mt-2">
                  {Object.keys(NonComplianceStatusColor).map((key) => (
                    <StyledStatusWrapper
                      color={NonComplianceStatusColor[key].color}
                    >
                      <StyledTypo
                        color="#687089"
                        size={0.6}
                        line={1}
                        letter="0"
                        family="Roboto"
                        fontWeight={300}
                        className="severity-text"
                      >
                        {key.toLowerCase()}
                      </StyledTypo>
                    </StyledStatusWrapper>
                  ))}
                </div>
              )}
            </div>
          </EachCell>
        ))}
    </HeaderWrapper>
  );
};

const Record = (props) => {
  const { data, columns, setData, setOpenItemId, shortMode, setUpdateProject } = props;
  const history = useHistory();

  const toggleCheck = (id) => {
    setData((data) => [
      ...data.map((item) => ({
        ...item,
        checked: item.id === id ? !item.checked : item.checked,
      })),
    ]);
  };

  const handleSelectRow = () => {
    if(shortMode) {
      // handleOpenDetail();
      setUpdateProject(data);
    } else {
      history.push(`/cspm/${data.id}`)
    }
  };

  // const handleOpenDetail = () => {
  //   setOpenItemId(data.id);
  // };

  return (
    <div>
      <RecordContainer active={data.checked}>
        <RecordWrapper active={data.checked} onClick={handleSelectRow}>
          {
            <>
              {columns.map((column) => (
                <EachCell width={column.width ?? "100%"} pr={10}>
                  {column.type === "checkbox" && (
                    <StyledCheckbox
                      handleOnChange={() => toggleCheck(data.id)}
                      checked={data.checked}
                      name=""
                      className="mr-5 mt-2 w-100"
                      color="#505050"
                      fontFamily="Roboto"
                      letter="0"
                    />
                  )}
                  {column.type === "date" && (
                    <StyledTypo
                      className="d-flex"
                      size={0.9}
                      line={1.4}
                      letter="0"
                    >
                      {data[column.access]
                        ? moment(data[column.access]).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        : "---"}
                    </StyledTypo>
                  )}
                  {column.type === "icon" && (
                    <div className="d-flex align-items-center">
                      {cspData
                        .filter((csp) => csp.key === data.platform)?.[0]
                        ?.logo(20)}
                      <StyledTypo
                        className="ml-2 pl-1"
                        // onClick={handleSelectRow}
                        size={0.9}
                        line={1.4}
                        letter="0"
                      >
                        {data[column.access]}
                      </StyledTypo>
                    </div>
                  )}
                  {column.access === 'scan_result' && (
                    <StyledTypo
                      size={0.9}
                      line={1.4}
                      letter="0"
                    >
                      {auditStatus[data[column.access]?.status]?.label ?? ''}
                    </StyledTypo>
                  )}
                  {column.type === "object" && (
                    <div className="d-flex align-items-center flex-wrap">
                      {Object.keys(NonComplianceStatusColor).map((key) => (
                        <StyledStatusWrapper color={NonComplianceStatusColor[key].color}>
                          <div className="mark" />
                          <StyledTypo
                            size={0.9}
                            line={1.4}
                            letter="0"
                          >
                            {data?.[column.access]?.[key]?.fail ?? 0}
                          </StyledTypo>
                        </StyledStatusWrapper>
                      ))}
                    </div>
                  )}
                  {column.type === "progress" && (
                    <div className="w-75">
                      <GradientProgressBar
                        color="#bdbdbd"
                        color2="#bdbdbd"
                        showNumber
                        value={
                          aggregatedData(data?.summary, "total") !== 0
                            ? (
                                (aggregatedData(data?.summary, "pass") /
                                aggregatedData(
                                    data?.summary,
                                    "total"
                                )) *
                                100
                            ).toFixed(1)
                            : 0
                        }
                        maxValue="100"
                        rotation="90"
                      />
                    </div>
                  )}
                  {!column.type && (
                    <StyledTypo
                      className="text-cell"
                      // onClick={handleSelectRow}
                      size={0.9}
                      line={1.4}
                      letter="0"
                    >
                      {Array.isArray(column.access)
                        ? column.access
                            .map((access) => data[access])
                            .join(" / ")
                        : data[column.access]}
                    </StyledTypo>
                  )}
                </EachCell>
              ))}
              {/* {shortMode && (
                <div
                  className="floating-button"
                  onClick={() => {
                    setUpdateProject(data)
                    setOpenItemId(null)
                  }}
                >
                  <FloatingButtonWrapper>
                    <EditIcon width={20} height={20} fill="#606060" />
                  </FloatingButtonWrapper>
                </div>
              )} */}
            </>
          }
        </RecordWrapper>
      </RecordContainer>
    </div>
  );
};

const InfraList = forwardRef((props, ref) => {
  const {
    infras,
    setUpdateProject,
    setMarkedInfras,
    fetchingFlag,
    setRenderFlag,
    shortMode = false,
  } = props;

  const columns = shortMode ? [
    {
      title: "",
      access: "",
      width: `${2000 / 680}%`,
      sort: false,
      search: false,
      type: "checkbox",
    },
    {
      title: "Name",
      access: "name",
      width: `${30000 / 680}%`,
      sort: true,
      search: true,
      type: "icon",
    },
    {
      title: "Environment",
      access: "environment",
      width: `${23000 / 680}%`,
      sort: true,
      search: true,
    },
    // {
    //   title: "Results",
    //   access: "summary",
    //   width: `${23000 / 680}%`,
    //   sort: false,
    //   search: false,
    //   type: "object",
    // },
    // {
    //   title: "Updated",
    //   access: "updated_timestamp",
    //   width: `${10000 / 680}%`,
    //   sort: false,
    //   search: false,
    //   type: "date",
    // },
    {
      title: "Audit",
      access: "scan_result",
      width: `${10000 / 680}%`,
      sort: false,
      search: false,
      type: 'mark'
    }
  ] : [
    // {
    //   title: "",
    //   access: "",
    //   width: `${2000 / 680}%`,
    //   sort: false,
    //   search: false,
    //   type: "checkbox",
    // },
    {
      title: "Name",
      access: "name",
      width: `${18000 / 680}%`,
      sort: true,
      search: true,
      type: "icon",
    },
    {
      title: "Environment",
      access: "environment",
      width: `${8000 / 680}%`,
      sort: true,
      search: true,
    },
    {
      title: "Results",
      access: "summary",
      width: `${18000 / 680}%`,
      sort: false,
      search: false,
      type: "object",
    },
    {
      title: "Compliance",
      access: "compliance",
      width: `${15000 / 680}%`,
      sort: false,
      search: false,
      type: "progress",
    },
    {
      title: "Audit",
      access: "skip_scan",
      width: `${5000 / 680}%`,
      sort: false,
      search: false,
    },
    {
      title: "Scan Result",
      access: "scan_result",
      width: `${5000 / 680}%`,
      sort: false,
      search: false,
      type: 'mark'
    },
    {
      title: "Updated",
      access: "updated_timestamp",
      width: `${8000 / 680}%`,
      sort: false,
      search: false,
      type: "date",
    },
  ];

  const { setGInfras } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({ access: "", order: true });
  const [filters, setFilters] = useState(columns.filter((item) => item.search));
  const [searchText, setSearchText] = useState(null);

  const [selectedPlatform, setSelectedPlatform] = useState(
    selectEmptyResourceType
  );
  const [selectedCategory, setSelectedCategory] = useState(
    selectEmptyResourceType
  );
  const [selectedSeverity, setSelectedSeverity] = useState(
    selectEmptyResourceType
  );
  const [selectedEnv, setSelectedEnv] = useState(selectEmptyResourceType);
  const [remediationStatus, setRemediationStatus] = useState(false);
  const [envOptions, setEnvOptions] = useState([selectEmptyResourceType]);
  const [openItems, setOpenItems] = useState({});
  const [showAll, setShowAll] = useState(false);
  const [selectedInfra, setSelectedInfra] = useState({})
  const [openItemId, setOpenItemId] = useState(null)

  const [viewMode, setViewMode] = useState('list');

  const itemPerPage = 15;
  const handleSort = () => {
    const retOrder = currentSort.order ? 1 : -1;
    setData((data) => [
      ...data.sort((a, b) => {
        const aCompare = Array.isArray(currentSort.access)
          ? currentSort.access.map((access) => a[access]).join(" ")
          : a[currentSort.access];
        const bCompare = Array.isArray(currentSort.access)
          ? currentSort.access.map((access) => b[access]).join(" ")
          : b[currentSort.access];
        return aCompare > bCompare ? retOrder : -1 * retOrder;
      }),
    ]);
  };

  useEffect(() => {
    setData([...infras.map((item) => ({ ...item, skip_scan: item.skip_scan ? 'Disabled' : 'Enabled', checked: false }))]);
    setCurrentSort((currentSort) =>
      currentSort.access.length === 0
        ? {
            access: columns[0].access,
            order: true,
          }
        : { ...currentSort }
    );
    window.scrollTo(0, 0);
    const envList = [...new Set(infras.map((infra) => infra.environment))];
    setEnvOptions([
      selectEmptyResourceType,
      ...envList.map((env) => ({ label: env, value: env })),
    ]);
  }, [infras]);

  useEffect(() => {
    setMarkedInfras(data.filter((project) => project.checked));
  }, [data]);

  useEffect(() => {
    handleSort();
  }, [currentSort]);

  useEffect(() => {
    let filteredData = [...data];
    if (searchText && searchText.length) {
      filteredData = [
        ...filteredData.filter(
          (item) =>
            !!filters.filter(
              (col) =>
                col.search &&
                item[col.access]
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
            ).length
        ),
      ];
    }

    if (selectedEnv && selectedEnv.value.length) {
      filteredData = [
        ...filteredData.filter(
          (item) =>
            item.environment.toLowerCase() === selectedEnv.value.toLowerCase()
        ),
      ];
    }
    if (selectedPlatform && selectedPlatform.value.length) {
      filteredData = [
        ...filteredData.filter(
          (item) =>
            item.platform.toLowerCase() === selectedPlatform.value.toLowerCase()
        ),
      ];
    }
    setGInfras(filteredData);
    setRenderFlag((renderFlag) => !renderFlag);
  }, [
    data,
    searchText,
    filters,
    currentPage,
    showAll,
    selectedEnv,
    selectedPlatform,
    viewMode,
  ]);

  const filteredData = useCallback(() => {
    let filteredData = [...data];
    if (searchText && searchText.length) {
      filteredData = [
        ...filteredData.filter(
          (item) =>
            !!filters.filter(
              (col) =>
                col.search &&
                item[col.access]
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
            ).length
        ),
      ];
    }
    if (selectedEnv && selectedEnv.value.length) {
      filteredData = [
        ...filteredData.filter(
          (item) =>
            item.environment.toLowerCase() === selectedEnv.value.toLowerCase()
        ),
      ];
    }
    if (selectedPlatform && selectedPlatform.value.length) {
      filteredData = [
        ...filteredData.filter(
          (item) =>
            item.platform.toLowerCase() === selectedPlatform.value.toLowerCase()
        ),
      ];
    }
    if (
      currentPage !== 1 &&
      currentPage > filteredData.length / itemPerPage + 1
    ) {
      setCurrentPage(1);
    }
    return filteredData;
  }, [
    data,
    searchText,
    filters,
    currentPage,
    showAll,
    selectedEnv,
    selectedPlatform,
  ]);

  const pageFilter = useCallback(
    (data) => {
      return data.filter(
        (item, idx) =>
          idx >= itemPerPage * (currentPage - 1) &&
          idx < itemPerPage * currentPage
      );
    },
    [currentPage]
  );

  const handlePageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };

  useEffect(() => {
    setSelectedInfra(
      filteredData()?.filter((item) => item.id === openItemId)?.[0] ?? {}
    );
  }, [openItemId]);

  const findIndex = () => {
    return filteredData()?.findIndex((project) => project.id === openItemId);
  };

  const findId = (up = true) => {
    const idx = findIndex();
    const targetIdx = up ? idx + 1 : idx - 1;
    return filteredData()?.filter((_, idx) => idx === targetIdx)?.[0]?.id;
  };

  useImperativeHandle(ref, () => ({
    getFilterPanel(fastFilter = false) {
      return !fastFilter ? (
        <FilterPanel
          columns={columns}
          filters={filters}
          setFilters={setFilters}
          searchText={searchText}
          setSearchText={setSearchText}
          selectedSeverity={selectedSeverity}
          setSelectedSeverity={setSelectedSeverity}
          selectedPlatform={selectedPlatform}
          setSelectedPlatform={setSelectedPlatform}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedEnv={selectedEnv}
          setSelectedEnv={setSelectedEnv}
          envOptions={envOptions}
          showAll={showAll}
          setShowAll={setShowAll}
          viewMode={viewMode}
          setViewMode={setViewMode}
        />
      ) : <FastFilterPanel
          columns={columns}
          filters={filters}
          setFilters={setFilters}
          searchText={searchText}
          setSearchText={setSearchText}
          selectedSeverity={selectedSeverity}
          setSelectedSeverity={setSelectedSeverity}
          selectedPlatform={selectedPlatform}
          setSelectedPlatform={setSelectedPlatform}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedEnv={selectedEnv}
          setSelectedEnv={setSelectedEnv}
          envOptions={envOptions}
          showAll={showAll}
          setShowAll={setShowAll}
        />;
    },
  }));

  return (
    <>
    {viewMode === 'card' ? (
      <InfraCards infras={filteredData()} />
    ) : (
      <TableWrapper marginTop="10px">
        <TableHeader
          columns={columns}
          currentSort={currentSort}
          setCurrentSort={setCurrentSort}
          data={filteredData()}
          setData={setData}
        />
        <div className="divider" />
        {fetchingFlag && !infras?.length ? (
          <div className="d-flex justify-content-center py-5 align-items-center">
            {loadingBar("Rings", 50, 50)}
            <StyledTypo
              className="pl-2"
              size={0.8}
              line={26.15 / 16}
              letter="0"
              fontWeight={400}
            >
              Loading...
            </StyledTypo>
          </div>
        ) : filteredData().length ? (
          pageFilter(filteredData()).map((infra) => (
            <Record
              data={infra}
              columns={columns}
              setData={setData}
              remediationStatus={remediationStatus}
              setUpdateProject={setUpdateProject}
              force={currentPage - 1}
              openItemId={openItemId}
              setOpenItemId={setOpenItemId}
              shortMode={shortMode}
            />
          ))
        ) : (
          <StyledTypo
            className="text-center py-5"
            size={0.8}
            line={26.15 / 16}
            letter="0"
            fontWeight={400}
          >
            No Infrastructure
          </StyledTypo>
        )}

        <ShowingWrapper>
          <StyledTypo
            size={0.8}
            line={1.6}
            letter="0"
            family="Poppins"
            fontWeight={400}
          >
            {`Showing ${
              itemPerPage * (currentPage - 1) + 1 > filteredData().length
                ? filteredData().length
                : itemPerPage * (currentPage - 1) + 1
            } - ${
              itemPerPage * currentPage > filteredData().length
                ? filteredData().length
                : itemPerPage * currentPage
            } from ${filteredData().length} Results`}
          </StyledTypo>
          <ReactPaginate
            pageCount={(filteredData()?.length ?? 0) / itemPerPage}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            previousLabel="< Prev"
            nextLabel="Next >"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            forcePage={currentPage - 1}
            onPageChange={handlePageChange}
            activeClassName="active"
          />
        </ShowingWrapper>
        <InfraDetail
          data={selectedInfra}
          isOpen={!!openItemId}
          openItemId={openItemId}
          toggle={() =>
            setOpenItemId((openItemId) => (openItemId ? null : openItemId))
          }
          setOpenItemId={setOpenItemId}
          findIndex={findIndex}
          findId={findId}
          projectsCnt={filteredData().length}
        />
      </TableWrapper>
    )}
    </>
  );
});

function mapDispatchToProps(dispatch) {
  return {
    createIacProjectAction: (payload) =>
      dispatch(iacActions.createIacProjectAction(payload)),
  };
}

export default connect(undefined, mapDispatchToProps, null, {
  forwardRef: true,
})(InfraList);
