import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PlanLayout from "../plan/PlanLayout";

const PanelWrapper = styled.div`
  padding: 15px;
  width: 100%;
  // border: 1px solid #f1f1f1;
  // box-sizing: border-box;
  // border-radius: 0 15px 15px;

  .panel-title-header {
    padding: 0 0 10px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const PlansPanel = (props) => {
  return (
    <PanelWrapper>
      <PlanLayout width="100%" />
    </PanelWrapper>
  );
};

function mapStateToProps(state) {}

function mapDispatchToProps(dispatch) {}

export default connect(mapStateToProps, mapDispatchToProps)(PlansPanel);
