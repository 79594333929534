import styled from "styled-components";
import { colors } from "../../helpers/constants";

const ButtonWrapper = styled.div`
  //width: 100%;
  text-align: center;
`;
const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => (props.fontsize ? props.fontsize : 12)}px;
  background: ${(props) =>
    props.outline
      ? "transparent"
      : props.background
      ? props.background
      : colors.red};
  color: ${(props) => (props.color ? props.color : "#fff")};
  height: ${(props) => (props.height ? props.height : 45)}px;
  width: ${(props) =>
    props.width
      ? typeof props.width === "number"
        ? `${props.width}px`
        : props.width
      : null};
  //width: calc(100% - 10px);
  //margin-left: 10px;
  position: relative;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  //z-index: 1;
  overflow: hidden;
  border: ${(props) =>
    props.outline
      ? `1px solid ${props.background ? props.background : colors.red}`
      : 0};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : 10)}px;
  padding: 0 ${(props) => (props.padding ? props.padding : 16)}px;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "auto")};
  //padding-left: -10px;
  //box-shadow: -10px 0 0 0 #FF487F;
  ${(props) =>
    !props.disabled &&
    `
      &:hover {
        opacity: ${(props) => (props.outline ? 1 : 0.8)};
      }
    `}
  & span {
    //font-family: 'Roboto';
    line-height: 25.2 / 18;
    letter-spacing: 0;
    font-weight: bold;
  }
  &::focus {
    outline: 0px !important;
  }
  &::after {
    content: "";
    background: linear-gradient(130deg, #00b1ff, rgb(76, 211, 255));
    position: absolute;
    z-index: -1;
    padding: 0.85em 0.75em;
    display: block;
    transition: all 0.1s;
    top: 0;
    bottom: 0;
    left: -100%;
    right: 100%;
  }
  ${(props) =>
    !props.disabled &&
    `
      &:hover::after {
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          transition: all 0.35s;
      }
    `}
`;

const EffectedButton = (props) => (
  <ButtonWrapper
    className={props.className}
    onClick={!props.disabled && props.onClick}
  >
    <StyledButton
      outline={props.outline}
      type={props.type ? props.type : "button"}
      color={props.color}
      width={props.width}
      height={props.height}
      fontsize={props.fontsize}
      background={props.background}
      padding={props.padding}
      marginLeft={props.marginLeft}
      borderRadius={props.borderRadius}
      disabled={props.disabled}
    >
      {props.noSpan ? props.children : <span>{props.children}</span>}
    </StyledButton>
  </ButtonWrapper>
);

export default EffectedButton;
