import styled from "styled-components";
import { Input } from "reactstrap";

const CustomInput = styled(Input)`
  background: #fff;
  /* inner shadow */
  // box-shadow: inset -4px 4px 10px rgba(27, 64, 152, 0.1);
  color: rgb(80, 80, 80);
  height: ${(props) => (props.height ? props.height : "36")}px;
  // min-width: ${(props) => (props.minWidth ? props.minWidth : 400)}px;
  border-radius: 20px;
  border: 1px solid rgb(234, 234, 234);
  padding-left: 22px;
  font-family: "Roboto";
  font-size: 0.8rem;
  font-weight: 300;
  &::placeholder {
    color: #505050;
    opacity: 0.8;
    font-family: "Roboto";
    font-size: 0.8rem;
    line-height: 1.2;
    font-weight: 300;
    letter-spacing: 0;
  }
`;
export default CustomInput;
