import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import * as global from "../../../helpers/constants";
import StyledTypo from "../../../components/Typo/StyledTypo";

import {
  ViewContainer,
} from "../../../components/PageLayout/Containers";
import { useTitle } from "../../../helpers/useTtitle";

const LayoutWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 25px;
`;

const ItemBlock = styled.div`
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
  transition: all .2s ease-in-out;
  // height: 100% !important;
  width: 380px;
  border-radius: 0.5rem;
  margin-bottom: 15px;
  cursor: pointer;
  
  .card-image-top {
    height: 300px;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    overflow: hidden;
  }
  .card-image-top img {
    height: 300px;
    -o-object-fit: cover;
    filter: grayscale(1);
    object-fit: cover;

    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    width: 100%;
  }

  .card-body {
    padding: 40px 1.5rem;
    flex: auto;
    row-gap: 10px;
  }

  img {
    transition: transform .5s ease;
  }

  &:hover img {
    transform: scale(1.2);
  }
`;

const pageTitle = `${global.commonTitle} - Cloud Security`;

const CloudWrapperLayout = () => {
  const history = useHistory();
  useTitle(pageTitle);
  return (
    <ViewContainer>
        <LayoutWrapper>
          {global.cloud_security_menus.map(item => (
            <ItemBlock className="text-center" key={item.title} onClick={() => history.push(item.link)}>
              <div className="card-image-top">
                <img src={item.img_src} />
              </div>
              <div className="card-body">
                {item.icon}
                <StyledTypo size={1.5} fontWeight="300">
                  {item.title}          
                </StyledTypo>
              </div>
            </ItemBlock>
          ))}
        </LayoutWrapper>
    </ViewContainer>
  );
};


export default CloudWrapperLayout;
