import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import StyledTypo from "../../../components/Typo/StyledTypo";
import EffectedButton from "../../../components/Buttons/EffectedButton";
import { showAlert, noneOption, reportTypes } from "../../../helpers/constants";
import { applicationActions, iacActions } from "../../../redux/actions";
import CommonSelect from "../../../components/Select/CommonSelect";
import {Col, Row} from 'reactstrap'

const PanelWrapper = styled.div`
  padding: 20px;
  width: 100%;
  // border: 1px solid #f1f1f1;
  // box-sizing: border-box;
  // border-radius: 0 20px 20px 20px;
  min-height: 450px;

  .panel-title-header {
    padding: 0 0 47px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const PanelBody = styled.div`
  padding: 10px 26px;
`;

const Divider = styled.div`
  height: 0;
  border-bottom: 1px solid #ccc;
  width: ${11 / 12 * 100}%;
  margin: 20px 0;
`;

const ReportPanel = (props) => {
  const {
    applications,
    report_downloading,
    iac_projects,
    clusters, workloads,
    fetchApplicationList,
    fetchIacProjectsAction,
    fetchKspmClustersAction,
    fetchWorkloadsAction,
    generateSummaryReportAction,
    generateDetailReportAction,
  } = props;
  const [selectedItem, setSelectedItem] = useState({})

  const options = {
    infra: [noneOption, ...applications.map(app => ({ value: app.id, label: app.name }))],
    iac: [
      noneOption,
      ...iac_projects.map((project) => ({
        value: project.id,
        label: project.display_name,
      })),
    ],
    kspm: [
      noneOption,
      ...clusters.map(cluster => ({
        value: cluster.cluster_id,
        label: cluster.cluster_name
      }))
    ],
    cwpp: [
      noneOption,
      ...workloads.map(workload => ({
        value: workload.workload_id,
        label: workload.workload_name
      }))
    ]
  }

  useEffect(() => {
    if (!applications || applications.length === 0) {
      fetchApplicationList();
    }
    if (!iac_projects || iac_projects.length === 0) {
      fetchIacProjectsAction();
    }
    if (!clusters || clusters.length === 0) {
      fetchKspmClustersAction();
    }
    if (!workloads || workloads.length === 0) {
      fetchWorkloadsAction();
    }
    setSelectedItem(reportTypes.reduce((acc, cur) => acc = { ...acc, [cur.key]: noneOption }, {}))
  }, []);

  const generateDetailReport = (type) => {
    if (selectedItem?.[type?.key]?.value === "") {
      showAlert(
        "warning",
        `Invalid ${type.item}`,
        `Please choose any ${type.item} to download report.`
      );
      return;
    }
    !report_downloading?.[`${type.key}_detail`] &&
      generateDetailReportAction(type.key, selectedItem?.[type.key].value);
  };

  const generateSummaryReport = (type) => {
    !report_downloading?.[`${type.key}_summary`] &&
      generateSummaryReportAction(type.key);
  };


  return (
    <PanelWrapper>
      <PanelBody>
        <Row>
          {reportTypes.map((rType, idx) => (
            <Col md={6} sm={12} key={rType.key} className="pt-3">
              <StyledTypo className="heading label">{rType.label}</StyledTypo>
              <div className="row ml-3 mt-5">
                <div className="col-4">
                  <StyledTypo className="label">Summary Report</StyledTypo>
                </div>
                <div className="col-4" />
                <div className="col-3">
                  <EffectedButton
                    outline={report_downloading?.[`${rType.key}_summary`]}
                    padding={0}
                    height={40}
                    background="#65C2D7"
                    noSpan
                    onClick={() => generateSummaryReport(rType)}
                  >
                    <StyledTypo
                      color={
                        report_downloading?.[`${rType.key}_summary`] ? "#65C2D7" : "#fff"
                      }
                      className="label"
                    >
                      {report_downloading?.[`${rType.key}_summary`]
                        ? "Downloading..."
                        : "Download"}
                    </StyledTypo>
                  </EffectedButton>
                </div>
              </div>
              <div className="row ml-3 align-items-center">
                <div className="col-3">
                  <StyledTypo className="label">Detail Report</StyledTypo>
                </div>
                <div className="col-5">
                  <CommonSelect
                    value={selectedItem?.[rType.key]}
                    setValue={(val) => setSelectedItem(selectedItem => ({ ...selectedItem, [rType.key]: val }))}
                    options={options?.[rType.key]}
                    label={rType.item}
                  />
                </div>
                <div className="col-3">
                  <EffectedButton
                    outline={report_downloading?.[`${rType.key}_detail`]}
                    padding={0}
                    height={40}
                    background="#65C2D7"
                    noSpan
                    onClick={() => generateDetailReport(rType)}
                  >
                    <StyledTypo
                      color={
                        report_downloading?.[`${rType.key}_detail`] ? "#65C2D7" : "#fff"
                      }
                      className="label"
                    >
                      {report_downloading?.[`${rType.key}_detail`]
                        ? "Downloading..."
                        : "Download"}
                    </StyledTypo>
                  </EffectedButton>
                </div>
              </div>
      
              <Divider />
            </Col>
          ))}
        </Row>
      </PanelBody>
    </PanelWrapper>
  );
};

function mapStateToProps(state) {
  const { report_downloading, clusters, workloads } = state.applications;
  return {
    report_downloading, clusters, workloads
  };
}

function mapDispatchToProps(dispatch) {
  return {
    generateSummaryReportAction: (type) => dispatch(applicationActions.generateSummaryReportAction(type)),
    generateDetailReportAction: (type, id) => dispatch(applicationActions.generateDetailReportAction(type, id)),
    fetchIacProjectsAction: () => dispatch(iacActions.fetchIacProjectsAction()),
    fetchApplicationList: () =>
      dispatch(applicationActions.fetchApplicationListAction()),
    fetchKspmClustersAction: () => dispatch(applicationActions.fetchKspmClustersAction()),
    fetchWorkloadsAction: () => dispatch(applicationActions.fetchWorkloadsAction())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportPanel);
