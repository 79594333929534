import {authHeader, handleResponse} from '../../helpers';
import * as global from "../../helpers/constants";

export const manageApis = {
  updateOrganizationSettings,
  updateOrganizationComplianceEnables
}

function updateOrganizationSettings(settingsData) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(settingsData)
  };
  return fetch(`${global.getBaseURL()}/onboarding/org-settings/update?id=${global.getCurrentOrganization()}`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function updateOrganizationComplianceEnables(payload) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload)
  };
  return fetch(`${global.getBaseURL()}/onboarding/org-compliance-enables/update?id=${global.getCurrentOrganization()}`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}
