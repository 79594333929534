import React from 'react'
import styled from 'styled-components'

const StatusMarkWrapper = styled.div`
  min-width: ${props => props.size || 10}px;
  width: ${props => props.size || 10}px;
  height: ${props => props.size || 10}px;
  border-radius: 50%;
  background-color: ${props => props.color || '#FF487F'};
`

const StatusMark = (props) => {
  const { size, color } = props
  return (
      <StatusMarkWrapper size={size} color={color} />
  )
}

export default StatusMark;