import styled from "styled-components";

export const FilterWrapper = styled.div`
  // width: 410px;
  // position: fixed;
  // top: ${(props) => props.top ?? "34px"};
  // left: calc(100% - ${(props) => props.left ?? "575px"});
  // z-index: 100;

  .filter-menu {
    gap: 10px;
  }
`;

export const FilterBodyContainer = styled.div`
  background: #fff;
  box-shadow: 0 26px 58px 0 rgba(0, 0, 0, 0.22),
    0 5px 14px 0 rgba(0, 0, 0, 0.18);
  // padding: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  z-index: 100;
  bottom: 0px;
  transform: translateY(105%);
  left: 0px;
  width: calc(100vw - 185px);
  border: 1px solid rgb(234 234 234);
  border-left: 2px solid #5eade6;
`;

export const FastFilterBodyContainer = styled.div`
  background: #fff;
  box-shadow: 0 26px 58px 0 rgba(0, 0, 0, 0.22),
    0 5px 14px 0 rgba(0, 0, 0, 0.18);
  // padding: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  z-index: 100;
  bottom: 0px;
  transform: translateY(105%);
  left: 0px;
  width: 100%;
  border: 1px solid rgb(234 234 234);
  border-left: 2px solid #5eade6;
`
