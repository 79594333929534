import React from "react";
import styled from "styled-components";
import StyledTypo from "../Typo/StyledTypo";

const Divider = styled.div`
  width: 100%;
  border: 1px solid #00000011;
  height: 0;
  margin-top: 10px;
`;
const LabelWrapper = styled.div`
  width: 60px;
  margin-top: -10px;
  display: flex;
  justify-content: center;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
`;

const DividerWithLabel = (props) => {
  return (
    <div>
      <Divider />
      <LabelWrapper>
        <StyledTypo
          color="#505050"
          size={0.8}
          line={19.6 / 14}
          letter="0"
          family="Roboto"
          fontWeight={300}
        >
          or
        </StyledTypo>
      </LabelWrapper>
    </div>
  );
};

export default DividerWithLabel;
