import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../helpers/constants";

import Select from "react-select";

const CommonSelectWrapper = styled.div`
  .field {
    width: 100%;
    height: 55px;
    border-radius: 4px;
    position: relative;
    background-color: rgba(255, 255, 255, 1);
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
    margin: 11px 0;
  }

  .field:hover {
    background-color: rgba(255, 255, 255, 0.6);
    //box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }

  .field.active {
    background-color: #ffffff;
    //box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  }

  .field .common-select + label {
    top: 4px;
    opacity: 1;
    color: #505050;
  }

  .field.locked {
    pointer-events: none;
  }

  .field .common-select {
    width: 100%;
    margin-top: 20px;
    position: relative;
    padding: 0;
    border: none;
    border-radius: 4px;
    font-family: "Roboto";
    font-size: 0.8rem;
    font-weight: 300;
    line-height: 1.2;
    background-color: transparent;
    //color: #282828;
    color: #505050;
    outline: none;
    /* box-shadow: 0px 4px 20px 0px transparent; */
    transition: 0.3s background-color ease-in-out;
    -webkit-appearance: none;
  }

  .field.active .common-select {
    //height: 56px;
    margin-top: 0;
    padding: 20px 0 0;
  }

  .common-select__control {
    border: none !important;
  }
  .common-select__control--is-focused {
    border: none !important;
    box-shadow: none !important;
    .common-select__dropdown-indicator {
      color: #505050 !important;
    }
  }

  .common-select__value-container {
    padding-left: 0 !important;
  }

  .common-select__indicator-separator {
    width: 0 !important;
  }

  .common-select__single-value,
  .common-select__input {
    color: #505050 !important;
  }

  .field .common-select + label {
    position: absolute;
    left: 0;
    font-family: "Roboto";
    font-size: 0.8rem;
    font-weight: 300;
    line-height: 1.2;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
    top: 0px;
    opacity: 1;
    color: #505050;
  }

  .field .error {
    margin-top: 3px;
    font-family: "Roboto", "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 0.8rem;
    font-weight: 300;
    color: #ec392f;
  }
  .field .bottom-line {
    height: 0;
    border-top: 1px solid #ebebeb;
    transition: 0.3s border-top ease-in-out;
  }

  .field.active .bottom-line {
    height: 0;
    border-top: 1px solid #66c4d5;
    transition: 0.3s border-top ease-in-out;
  }
`;

const IconWrapper = styled.div`
  //margin-top: 20px;
  margin: 0 5px;
  width: 15px;
  height: 15px;
`;

const CommonSelect = (props) => {
  const {
    locked,
    icon,
    label,
    value,
    setValue,
    error,
    options,
    isMulti = false,
    onChange,
    isClear,
    disabled,
  } = props;
  const [active, setActive] = useState((locked && props.active) || false);

  const AddedIcon = icon;

  const handleChange = (selectedOption) => {
    setValue && setValue(selectedOption);
  };

  const fieldClassName = `field ${(locked ? active : active) && "active"} ${
    locked && !active && "locked"
  }`;

  return (
    <CommonSelectWrapper>
      <div className={fieldClassName}>
        <div className="d-flex align-items-center">
          <div className="common-select">
            <Select
              value={value}
              onChange={onChange ? onChange : handleChange}
              options={options}
              classNamePrefix="common-select"
              onFocus={() => !locked && setActive(true)}
              onBlur={() => !locked && setActive(false)}
              isMulti={isMulti}
              isClearable={isClear}
              isDisabled={disabled}
              isSearchable={false}
            />
          </div>
          <label htmlFor={1}>{label}</label>
          {icon && (
            <IconWrapper>
              <AddedIcon
                fill={
                  active
                    ? colors.input_color.active
                    : colors.input_color.inactive
                }
              />
            </IconWrapper>
          )}
        </div>
        <div className="bottom-line" />
        {error && <div className={error && "error"}>{error}</div>}
      </div>
    </CommonSelectWrapper>
  );
};

export default CommonSelect;

// render(
//     <Input
//         id={1}
//         label="Field label"
//         // predicted="California"
//         locked={false}
//         // error={"invalid value"}
//         active={false}
//         icon={"icon"}
//     />,
//     document.getElementById("root")
// );
