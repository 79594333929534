import React from 'react'
import Page from "../../components/PageLayout/Page";
import ProfileLayout from "./profile/ProfileLayout";

const Profile = (props) => {
  return (<div>
    <Page>
      <ProfileLayout />
    </Page>
  </div>)
}

export default Profile;
