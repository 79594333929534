import {manageApis} from '../apis';
import {showAlert} from "../../helpers/constants";
import {usersActions} from "./users.actions";
import {authAction} from "./auth.actions";

export const manageActions = {
  updateOrganizationSettingsAction,
  updateOrgComplianceEnablesAction,
};

function updateOrganizationSettingsAction(settingsData) {
  return dispatch => {
    manageApis.updateOrganizationSettings(settingsData)
        .then(
            res => {
              showAlert('success', "Successfully Updated", res.msg)
              dispatch(usersActions.fetchOrganizationListAction())
            },
            error => {
              showAlert('error', "Error", "Failed to update organization settings.")
              error && dispatch(authAction.setTokenExpired(error, () => {}));
            }
        );
  };
}

function updateOrgComplianceEnablesAction(payload) {
  return dispatch => {
    manageApis.updateOrganizationComplianceEnables(payload)
        .then(
            res => {
              showAlert('success', "Successfully update compliance enable status", res.msg)
              dispatch(usersActions.fetchOrganizationListAction())
            },
            error => {
              showAlert('error', "Error", "Failed to update organization compliance enable status.")
              error && dispatch(authAction.setTokenExpired(error, () => {}));
            }
        );
  };
}
