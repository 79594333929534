import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux'
import Page from "../../components/PageLayout/Page";
import ObserveLayout from "./observe/ObserveLayout";
import {applicationActions, deploymentActions} from "../../redux/actions";
import {convertSummaryData} from "../../helpers/constants";
import moment from 'moment'

const Observe = (props) => {
  const {
    appList, fetchApplicationList,
      deploymentApps,
      demoList, fetchDemoBlogList,
      activityFeeds, fetchActivityFeeds,
      templates, fetchOrgTemplatesAction,
      appResourceSummary, fetchResourceSummary,
      clearAppListLoadingFlag,
      expired_token, new_refresh_token,
      healthHistoryData, fetchHealthHistoryAction,
      fetchRemediationListAction, remediations,
  } = props

  const [appData, setAppData] = useState([])

  useEffect(() => {
    const historyDateRange = {
      startHistoryTime: moment().format('X') - 12 * 60 * 60,
      endHistoryTime: moment().format('X'),
    }
    fetchHealthHistoryAction(historyDateRange)
    clearAppListLoadingFlag()
    fetchApplicationList()
    fetchActivityFeeds()
    fetchOrgTemplatesAction()
    fetchResourceSummary()
    fetchRemediationListAction()
  }, [fetchApplicationList, fetchDemoBlogList, fetchActivityFeeds, fetchOrgTemplatesAction, fetchResourceSummary, clearAppListLoadingFlag, fetchRemediationListAction])

  useEffect(() => {
    if(!expired_token && new_refresh_token) {
      clearAppListLoadingFlag()
      fetchApplicationList()
      fetchActivityFeeds()
      fetchOrgTemplatesAction()
      fetchResourceSummary()
      fetchRemediationListAction()
    }
  }, [fetchApplicationList, fetchDemoBlogList, fetchActivityFeeds, fetchOrgTemplatesAction, fetchResourceSummary, clearAppListLoadingFlag, expired_token, new_refresh_token, fetchRemediationListAction])

  useEffect(() => {
    if(appList) {
      setAppData(appList.map(app => ({
        ...app,
        summary: convertSummaryData(appResourceSummary
            .filter(summary => summary.application_id === app.id)?.[0]?.['resources_summary'] ?? [])})))
    }
  }, [appList, appResourceSummary])

  return (<div>
    <Page>
      <ObserveLayout
          appList={appData}
          templates={templates}
          deploymentApps={deploymentApps}
          demoList={demoList}
          activityFeeds={activityFeeds}
          fetchActivityFeeds={fetchActivityFeeds}
          healthHistoryData={healthHistoryData}
          remediations={remediations}
      />
    </Page>
  </div>)
}

function mapStateToProps(state) {
  const {appList, demoList, activityFeeds, appResourceSummary, healthHistoryData, remediations} = state.applications;
  const {deploymentApps, templates} = state.deployments;
  const {expired_token, new_refresh_token} = state.auth;

  return {
    appList, demoList, activityFeeds, appResourceSummary,
    deploymentApps, templates,
    expired_token, new_refresh_token,
    healthHistoryData, remediations
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchApplicationList: () => dispatch(applicationActions.fetchApplicationListAction()),
    fetchDemoBlogList: () => dispatch(applicationActions.fetchDemoBlogListAction()),
    fetchDeploymentApps: () => dispatch(deploymentActions.fetchDeploymentApps()),
    fetchActivityFeeds: () => dispatch(applicationActions.fetchActivityFeedsAction()),
    fetchOrgTemplatesAction: () => dispatch(deploymentActions.fetchOrgTemplatesAction()),
    fetchResourceSummary: () => dispatch(applicationActions.fetchResourceSummaryAction()),
    clearAppListLoadingFlag: () => dispatch(applicationActions.clearAppListLoadingFlag()),
    fetchHealthHistoryAction: (data) => dispatch(applicationActions.fetchHealthHistoryAction(data)),
    fetchRemediationListAction: () => dispatch(applicationActions.fetchRemediationListAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Observe);
