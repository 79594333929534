import React, { forwardRef } from "react";
import styled from "styled-components";
import WorkloadTable from "./WorkloadTable";

const MainBodyWrapper = styled.div`
  padding: 10px;
`;

const WorkloadDetailsTab = forwardRef((props, ref) => {
  const {
    data, dataLoading, selectedWorkload,
    setSelectedFilterType,
    selectedFilterType,
    preFilterData,
    setRenderFlag,
  } = props;

  return (
    <MainBodyWrapper>
      <WorkloadTable
        ref={ref}
        data={data}
        dataLoading={dataLoading}
        workloadId={selectedWorkload?.workload_id}
        selectedWorkload={selectedWorkload}
        selectedFilterType={selectedFilterType}
        setSelectedFilterType={setSelectedFilterType}
        preFilterData={preFilterData}
        setRenderFlag={setRenderFlag}
      />
    </MainBodyWrapper>
  );
});

export default WorkloadDetailsTab;
