import styled from "styled-components";

export const FullViewContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 15px;
  width: 100%;
`;

export const ViewContainer = styled.div`
  margin-left: 101px;
  // padding: 10px;
  width: calc(100% - 101px);
`;

export const PageTitle = styled.div`
  background: #fff;
  border-radius: 5px;
  border: 1px solid rgb(234, 234, 234);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.075);
  margin-top: 20px;
  min-height: 64px;
  padding-left: 24px;
  padding: 12px;
  position: fixed;
  width: calc(100% - 179px);
  z-index: 100;
  flex-wrap: wrap;
  row-gap: 10px;

  .page-menu {
    font-family: Poppins;
    gap: 10px;
    left: calc(100% - ${(props) => props.menuLeft ?? "920px"});
    position: fixed;
  }
`;

export const FlexComp = styled.div`
  display: flex;
  flex-direction: ${(props) => props.dir || "row"};
  align-items: center;
  flex-wrap: wrap;
  gap: ${(props) => props.gap || "10px"};
`;

export const PageMenu = styled.div`
  display: flex;
  align-items: center;
  font-family: Poppins;
  gap: 10px;
  // left: calc(100% - ${(props) => props.menuLeft ?? "920px"} );
  // position: fixed;
`;

export const ShowingWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  position: fixed;
  bottom: 20px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100vw - 200px);
  background-color: #fff;

  .page-link:focus {
    box-shadow: none !important;
  }
  ul.pagination {
    margin-bottom: 0;
    font-size: 0.8rem;
    font-weight: 0.4;
    font-family: "Roboto";
  }
`;
