import styled from "styled-components";
import StyledTypo from "../Typo/StyledTypo";

import { ReactComponent as CheckedStatus } from "../../assets/icons/check_on_icon.svg";
import { ReactComponent as UnCheckedStatus } from "../../assets/icons/check_off_icon.svg";

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  & input ~ span {
    position: absolute;
    left: 0;
    //height: 15px;
    //width: 15px;
    //border: 3px solid #121314;
    //background-color: #484848;
    //border-radius: 3px;
    &:after {
      //width: 5px;
      //height: 10px;
      //border: solid white;
      //border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  &:hover input ~ span {
    //background-color: #ccc;
  }
  //& input:checked ~ span {
  //    background-image: linear-gradient(90deg, #00B1FF, #65DFFF);
  //}
  & input:checked ~ span:after {
    display: block;
  }
`;
const StyledCheckbox = ({
  name,
  checked,
  handleOnChange,
  color,
  disabled,
  gap,
  fontSize,
  fontWeight,
  className,
  fontFamily,
  letter,
}) => {
  return (
    <StyledLabel color={color} className={className}>
      {/* <StyledTypo color="#fff" family="Helvetica Neue LT Pro 55 Roman" letter="0.05" size="0.75" line={14/12} style={{marginLeft: '27px'}}>{name}</StyledTypo> */}
      <StyledTypo
        className="pt-1"
        color={`${color ? color : "#212529"}${disabled ? "66" : ""}`}
        size={fontSize ?? 0.875}
        family={fontFamily ?? "Roboto"}
        line={1.4}
        fontWeight={fontWeight ?? 500}
        style={{ marginLeft: gap ? gap : "27px", paddingLeft: "5px" }}
        letter={letter ?? "0.05"}
      >
        {name}
      </StyledTypo>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleOnChange}
        disabled={!!disabled}
      />
      <span>{checked ? <CheckedStatus /> : <UnCheckedStatus />}</span>
    </StyledLabel>
  );
};

export default StyledCheckbox;
