import React from "react";
import styled from "styled-components";

import corner_gradient from "../../assets/images/corner_gradient.png";

const RightCornerGradient = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
`;


const AuthBackground = () => {
  return (
    <>
      <RightCornerGradient>
        <img src={corner_gradient} width="100%" alt="right corner gradient" />
      </RightCornerGradient>
    </>
  );
};

export default AuthBackground;
