import {authHeader, handleResponse} from "../../helpers";
import {getCurrentOrganization, getCurrentUser} from "../../helpers/constants";
import * as global from "../../helpers/constants";

export const plans = {
  createNewSubscription,
  fetchSubscription,
  upgradeSubscription,
  cancelSubscription,
  fetchPaymentMethods,
  changeSubscriptionPaymentMethod,
  createPayment,
  deletePayment,
}

function createNewSubscription(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      ...data,
      organization_id: getCurrentOrganization(),
      email: getCurrentUser()?.email
    })
  }
  const url = `${global.getBaseURL()}/onboarding/subscription`
  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(result => {
        return result;
      });
}

function fetchSubscription() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  const url = `${global.getBaseURL()}/onboarding/subscription?org_id=${getCurrentOrganization()}`
  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(result => {
        return result;
      });
}

function upgradeSubscription(data) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({
      ...data,
      organization_id: getCurrentOrganization(),
    })
  }
  const url = `${global.getBaseURL()}/onboarding/manage-subscription`
  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(result => {
        return result;
      });
}

function cancelSubscription() {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    body: JSON.stringify({})
  }
  const url = `${global.getBaseURL()}/onboarding/subscription?org_id=${getCurrentOrganization()}`
  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(result => {
        return result;
      });
}

function fetchPaymentMethods() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  const url = `${global.getBaseURL()}/onboarding/payment-methods?org_id=${getCurrentOrganization()}`
  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(result => {
        return result;
      });
}

function changeSubscriptionPaymentMethod(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      ...data,
      organization_id: getCurrentOrganization(),
    })
  }
  const url = `${global.getBaseURL()}/onboarding/subscription/change-payment`
  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(result => {
        return result;
      });
}

function createPayment(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      ...data,
      organization_id: getCurrentOrganization(),
    })
  }
  const url = `${global.getBaseURL()}/onboarding/payment-method`
  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(result => {
        return result;
      });
}

function deletePayment(pm_id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    body: JSON.stringify({
      organization_id: getCurrentOrganization(),
    })
  }
  const url = `${global.getBaseURL()}/onboarding/payment-method?pm_id=${pm_id}`
  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(result => {
        return result;
      });
}

