import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors, userStatusInfo } from "../../../helpers/constants";
import { ReactComponent as EditUserIcon } from "../../../assets/icons/user_edit_icon.svg";
import { ReactComponent as ThreeDotIcon } from "../../../assets/icons/three_dot_icon.svg";
import { ReactComponent as DeleteButtonIcon } from "../../../assets/icons/trash_icon.svg";

import StyledTypo from "../../../components/Typo/StyledTypo";
import StyledDropdown from "../../../components/Buttons/StyledDropdown";
import DropdownIcon from "../../../components/Buttons/DropdownIcon";
import { DropdownMenu } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import { ReactComponent as ChangeStatusIcon } from "../../../assets/icons/change_status_icon.svg";
import AddEnvironmentPanel from "../../modals/AddEnvironmentPanel";
import Switch from "react-switch";

const TableWrapper = styled.div`
  padding-top: 0px;
  .divider {
    margin: 8px -15px;
    border-top: 1px solid rgba(164, 175, 183, 0.3);
    height: 0;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 26px;
`;

const EachCell = styled.div`
  width: ${(props) => props.width};
`;

const RecordWrapper = styled.div`
  padding: 8px 26px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: 5px;

  &:hover {
    border-radius: 5px;
    background-color: #f1f1f1;
  }
`;

const ActionDropdownMenu = styled(DropdownMenu)`
  /* white */
  min-width: 164px;
  width: 180px;
  background: #ffffff;
  /* Gray light 2 */

  border: 1px solid #f1f5fa;
  box-sizing: border-box;
  /* box shadow */

  box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 10px;
  padding: 20px 25px;
  transform: translate3d(-110px, 0px, 0px) !important;
`;

const ShowingWrapper = styled.div`
  margin-top: 50px;
  padding: 0 26px;
  margin-bottom: 15px;
`;

const StatusWrapper = styled.div`
  background-color: ${(props) => props.color};
  padding: 5px 20px;
  border-radius: 215px;
  display: flex;
`;

const TableHeader = () => {
  return (
    <HeaderWrapper>
      <EachCell width={`${45000 / 1596}%`}>
        <StyledTypo className="label">Organization Name</StyledTypo>
      </EachCell>
      <EachCell width={`${35000 / 1596}%`}>
        <StyledTypo className="label">Name</StyledTypo>
      </EachCell>
      <EachCell width={`${35000 / 1596}%`}>
        <StyledTypo className="label">Key</StyledTypo>
      </EachCell>
      <EachCell width={`${35000 / 1596}%`}>
        <StyledTypo className="label">Audit</StyledTypo>
      </EachCell>
      <EachCell width={`${9600 / 1596}%`}>
        <StyledTypo className="label">Actions</StyledTypo>
      </EachCell>
    </HeaderWrapper>
  );
};

const Record = (props) => {
  const {
    data,
    isOpenActionMenu,
    toggleActionMenu,
    handleRemoveEnv,
    handleChangeStatus,
    handleEditEnv,
    orgScanSetting,
    updateOrgScanSettingAction
  } = props;
  const [showEditModal, setShowEditModal] = useState(false);

  const handleChangeScanSetting = (checked) => {
    const modifiedEnv = checked ? 
      [...(orgScanSetting?.environment ?? []).filter(item => data.key !== item), data.key] : 
      [...(orgScanSetting?.environment ?? []).filter(item => data.key !== item)];
    const modifiedScanSetting = {...(orgScanSetting ?? {}), environment: modifiedEnv}

    updateOrgScanSettingAction(modifiedScanSetting);
  }

  const deleteConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>{`You want to delete environment "${data.name}"?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                data.key && handleRemoveEnv(data.key);
                onClose();
              }}
            >
              Confirm
            </button>
          </div>
        );
      },
    });
  };

  return (
    <RecordWrapper>
      <EachCell width={`${45000 / 1596}%`}>
        <StyledTypo>{data.organization_name}</StyledTypo>
      </EachCell>
      <EachCell width={`${35000 / 1596}%`}>
        <StyledTypo>{data.name}</StyledTypo>
      </EachCell>
      <EachCell width={`${35000 / 1596}%`}>
        <StyledTypo>{data.key}</StyledTypo>
      </EachCell>
      <EachCell width={`${35000 / 1596}%`} className="d-flex">
        <Switch
          onChange={(checked) =>
            handleChangeScanSetting(checked)
          }
          checked={(orgScanSetting?.environment ?? []).includes(data.key)}
          onColor="#65C2D7"
          offColor="#ff487f"
        />
        {/* <StatusWrapper color={userStatusInfo[data?.auditEnabled].color}>
          <StyledTypo className="table-label" color={"#fff"}>
            {userStatusInfo[data?.auditEnabled].name}
          </StyledTypo>
        </StatusWrapper> */}
      </EachCell>
      <EachCell width={`${9600 / 1596}%`} className="text-right">
        <StyledDropdown
          isOpen={isOpenActionMenu.filter((item) => item[data.id]).length}
          toggle={() => toggleActionMenu(data.id)}
        >
          <DropdownIcon>
            <ThreeDotIcon />
          </DropdownIcon>
          <ActionDropdownMenu>
            <StyledDropdown
              isOpen={showEditModal}
              toggle={() => setShowEditModal((showEditModal) => !showEditModal)}
            >
              <DropdownIcon>
                <div className="d-flex mb-2 align-items-center cursor-pointer">
                  <EditUserIcon fill="#485DCD" width={20} />
                  <StyledTypo className="ml-3">Edit</StyledTypo>
                </div>
              </DropdownIcon>
              <AddEnvironmentPanel
                editData={data}
                handleEditEnv={handleEditEnv}
                toggle={(status) => setShowEditModal(status)}
              />
            </StyledDropdown>
            <div
              className="d-flex mb-2 align-items-center cursor-pointer"
              onClick={() => {
                toggleActionMenu(data.key);
                handleChangeStatus(data.key);
              }}
            >
              <ChangeStatusIcon fill="#485DCD" width={20} />
              <StyledTypo className="ml-3">
                {data.status === "active" ? "Disable" : "Enable"}
              </StyledTypo>
            </div>
            <div
              className="d-flex mb-2 align-items-center cursor-pointer"
              onClick={() => deleteConfirm()}
            >
              <DeleteButtonIcon fill={colors.red} width={20} />
              <StyledTypo className="ml-3">Remove</StyledTypo>
            </div>
          </ActionDropdownMenu>
        </StyledDropdown>
      </EachCell>
    </RecordWrapper>
  );
};

const EnvTable = (props) => {
  const { data, handleRemoveEnv, handleChangeStatus, handleEditEnv, updateOrgScanSettingAction } = props;
  const [isOpenActionMenu, setIsOpenActionMenu] = useState([]);
  const [envData, setEnvData] = useState([]);

  useEffect(() => {
    if (data && Object.keys(data).length) {
      const skipScanSetting = data?.org_setting?.skip_scan;
      const envs = [
        ...data["environments"].map((env, idx) => ({
          id: idx + 1,
          key: env.key,
          name: env.name,
          organization_name: data.organization_name,
          status: env.is_active ? "active" : "inactive",
          auditEnabled: (skipScanSetting?.environment ?? []).includes(env.key)
        })),
      ];
      setEnvData(envs);
      setIsOpenActionMenu([...envs.map((item) => ({ [item.id]: false }))]);
    }
  }, [data]);

  const toggleActionMenu = (key) => {
    setIsOpenActionMenu(
      isOpenActionMenu.map((item) => ({ ...item, [key]: !item[key] }))
    );
  };

  const handleChange = (id) => {
    setEnvData((deployData) =>
      deployData.map((item) => ({
        ...item,
        selected: item.id === id ? !item.selected : item.selected,
      }))
    );
  };

  return (
    <TableWrapper>
      <TableHeader />
      <div className="divider" />
      {envData.map((env) => (
        <Record
          data={env}
          isOpenActionMenu={isOpenActionMenu}
          toggleActionMenu={toggleActionMenu}
          handleChange={handleChange}
          handleRemoveEnv={handleRemoveEnv}
          handleChangeStatus={handleChangeStatus}
          handleEditEnv={handleEditEnv}
          orgScanSetting={data?.org_setting?.skip_scan ?? {}}
          updateOrgScanSettingAction={updateOrgScanSettingAction}
        />
      ))}
      <ShowingWrapper>
        <StyledTypo className="pagination-label">
          {`Showing ${envData.length} from ${envData.length} Results`}
        </StyledTypo>
      </ShowingWrapper>
    </TableWrapper>
  );
};

export default EnvTable;
