import React, { useState } from "react";
import StyledTypo from "../Typo/StyledTypo";
import { Input } from "reactstrap";
import styled from "styled-components";
import { showAlert } from "../../helpers/constants";

const UploadFileWrapper = styled.div`
  padding: 5px 15px;
  background: ${(props) => (props.disabled ? "#5EB1E455" : "#5EB1E4")};
  border-radius: 40px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  &:hover {
    background: ${(props) => (props.disabled ? "#5EB1E455" : "#5EB1E499")};
  }
`;

const StyledFile = (props) => {
  const {
    hiddenId,
    label,
    onChange,
    extensions,
    isShowName,
    className,
    disabled,
  } = props;

  const [fileName, setFileName] = useState("");

  const triggerInput = () => {
    document.getElementById(hiddenId).value = "";
    document.getElementById(hiddenId).click();
  };

  const handleInputFile = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    const filename = files[0].name;
    if (
      (extensions &&
        extensions.length &&
        extensions.filter((ext) => filename.endsWith(`.${ext}`)).length) ||
      !extensions.length
    ) {
      onLoadInputFile(files[0]);
      setFileName && setFileName(filename);
    } else {
      showAlert(
        "warning",
        "Invalid file",
        `File extension must be ${extensions.join(" or ")}.`
      );
    }
  };

  const onLoadInputFile = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      onChange && onChange(file.name, e.target.result);
    };
    reader.readAsText(file);
  };

  return (
    <div
      className={`d-flex justify-content-between align-items-center ${
        className ?? ""
      }`}
    >
      <UploadFileWrapper onClick={triggerInput} disabled={disabled}>
        <StyledTypo
          className={disabled ? "" : "cursor-pointer"}
          color="#fff"
          size={0.8}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={400}
        >
          {label ?? "Browse"}
        </StyledTypo>
      </UploadFileWrapper>
      {isShowName && (
        <StyledTypo
          className="ml-3"
          color={disabled ? "#50505066" : "#505050"}
          size={0.875}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={400}
        >
          {fileName}
        </StyledTypo>
      )}
      <Input
        type="file"
        hidden="hidden"
        id={hiddenId}
        onChange={handleInputFile}
        accept={
          extensions && extensions.length
            ? extensions.map((ext) => `.${ext}`).join(", ")
            : "*.*"
        }
        disabled={disabled}
      />
    </div>
  );
};

export default StyledFile;
