import React, { useEffect } from "react";

import { applicationActions } from "../../../redux/actions";
import { connect } from "react-redux";
import { ViewContainer } from "../../../components/PageLayout/Containers";
import TrendChartTab from "./TrendsChartTab";
import { cspData } from "../../../helpers/constants";

const TrendsLayout = (props) => {
  const { fetchTrendDataAction, compliance_trend } = props;

  useEffect(() => {
    cspData.forEach((provider) => {
        fetchTrendDataAction({provider: provider.key})
    })
  }, [cspData])
  

  return (
    <ViewContainer>
        <div className="dashboard-container d-flex flex-column">
            <TrendChartTab data={compliance_trend ?? []} />
        </div>
    </ViewContainer>
  );
};

function mapStateToProps(state) {
  const { compliance_trend } = state.applications;
  return {
    compliance_trend
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTrendDataAction: (data) => dispatch(applicationActions.fetchTrendDataAction(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrendsLayout);
