import styled from "styled-components"

export const BackButton = styled.a`
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  color: #505050;
  :hover {
    text-decoration: none;
  }
`
