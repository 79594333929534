import { combineReducers } from 'redux';

import { applications } from "./application.reducer";
import { deployments } from "./deployment.reducer";
import { users } from "./users.reducer";
import { auth } from "./auth.reducer";
import { manages } from "./manage.reducer";
import { plans } from "./plan.reducer";
import {iacs} from './iac.reducer'
import {dashboard} from './dashboard.reducer'

const rootReducer = combineReducers({
  applications,
  deployments,
  users,
  auth,
  manages,
  plans,
  iacs,
  dashboard
})

export default rootReducer;
