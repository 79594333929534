import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { applicationActions } from '../../../redux/actions'
import { connect } from 'react-redux'
import { columns, pentestingTabs } from '../../../helpers/constants'
import StyledTypo from '../../../components/Typo/StyledTypo'
import { ViewContainer } from '../../../components/PageLayout/Containers'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import CommonTable from './components/CommonTable'


const Container = styled.div`
  padding: 15px 40px;
`

const TabPanelBodyWrapper = styled.div`
  width: calc(100vw - 195px);
  padding: 10px 30px;

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 15px;
  }
`

const PentestingLayout = props => {
  const { 
    workspaces, create_update_workspace_flag,
    fetchWorkspacesAction,
    createWorkspaceAction,
    updateWorkspaceAction,
    deleteWorkspaceAction,
  } = props

  useEffect(() => {
    fetchWorkspacesAction()
  }, [])

  return (
    <ViewContainer>
      <Container>
        <StyledTypo
          className="mt-3"
          size={1.2}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={400}
        >
          VAPT
        </StyledTypo>
        <Tabs>
          <TabList>
            {pentestingTabs.map((item, idx) => (
              <Tab key={`${item.key}-tab-header`}>
                <StyledTypo
                  line="1.8"
                  size={0.8}
                  family="Poppins"
                  fontWeight={300}
                >
                  {item.label}
                </StyledTypo>
              </Tab>
            ))}
          </TabList>
          {pentestingTabs.map((item, idx) => (
            <TabPanel>
              <TabPanelBodyWrapper key={`${item.key}-tab-body`}>
                {item.key === 'workspaces' && (
                  <CommonTable 
                    data={workspaces}
                    columns={columns?.pentesting?.[item.key]}
                    options={{ noPagination: false }}
                    type={item.key}
                    hasDetail={true}
                    hasSearch
                    hasCreate={true}
                    category={item.key}
                  />
                )}
                {item.key === 'reports' && (
                  <CommonTable 
                    data={[]}
                    columns={columns?.pentesting?.[item.key]}
                    options={{ noPagination: false }}
                    type={item.key}
                    hasSearch
                  />
                )}
              </TabPanelBodyWrapper>
            </TabPanel>
          ))}
        </Tabs>
      </Container>
    </ViewContainer>
  )
}

function mapStateToProps (state) {
  const { workspaces, create_update_workspace_flag } = state.applications
  return {
    workspaces, create_update_workspace_flag
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchWorkspacesAction: () => dispatch(applicationActions.fetchWorkspacesAction()),
    createWorkspaceAction: (payload) => dispatch(applicationActions.createWorkspaceAction(payload)),
    updateWorkspaceAction: (id, payload) => dispatch(applicationActions.updateWorkspaceAction(id, payload)),
    deleteWorkspaceAction: (id) => dispatch(applicationActions.deleteWorkspaceAction(id)),
    clearCRUDWorkspaceFlagAction: () => dispatch(applicationActions.clearCRUDWorkspaceFlagAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PentestingLayout)
