import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  dashboardOverviewList,
  NonComplianceStatusColor,
  loadingBar,
} from "../../../../helpers/constants";
import StyledTypo from "../../../../components/Typo/StyledTypo";
import GradientProgressBar from "../../../../components/GradientProgressBar/GradientProgressBar";
import { Row, Col } from "reactstrap";
import moment from 'moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

const MainBodyWrapper = styled.div`
  padding: 10px;
`;

const RemediationOverviewWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 20px 0px;
`;

const RemediationOverviewBlock = styled.div`
padding: 15px 5px;
border-radius: 5px;
// background-color: ${(props) => props.color};
border: 1px solid #eee;
border-left: 5px solid ${(props) => (props.color ? props.color : "#ccc")};
width: 100%;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
// box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 2px, rgba(0, 0, 0, 0.2) 2px 2px 2px;
filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
cursor: pointer;
`;

const SummaryItemContainer = styled.div`
  border-top: ${(props) => (props.idx === 0 ? "none" : "1px solid #f1f1f1")};
  padding: 10px;
  cursor: ${(props) => (props.cursor ? props.cursor : "default")};
  ${(props) =>
    props.hover &&
    `
    &:hover {
      background-color: #f7f7f7;
    }
  `}
`;

const OverviewContentWrapper = styled.div`
  width: 100%;
`;

const TotalBlock = styled.div`
  // padding: 10px;
  padding: 15px 5px;
  border-radius: 5px;
  // background-color: ${(props) => (props.color ? props.color : "#fff")};
  width: 100%;
  min-height: 120px;
  border: 1px solid rgba(234, 234, 234, 0.65);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  // filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
  display: flex;
  align-items: center;
  justify-content: space-between;

  .item {
    border-right: 1px solid #eaeaea;
    &.nonborder {
      border: none;
    }
    width: 100%;
    padding: 5px 10px;
  }
`;

const SummaryWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  word-break: break-all;
`;

const StyledStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 65px;

  margin-right: ${(props) => (props.mr ? props.mr : 10)}px;

  .mark {
    width: ${(props) => (props.size ? props.size : 12)}px;
    height: ${(props) => (props.size ? props.size : 12)}px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    // box-shadow: 0 8px 6px -3px ${(props) => props.color};
  }
`;

const StatsTabWrapper = styled.div`
  padding: 10px;
`

const infraSummary = [
  [
    { key: 'type', label: 'Cluster Type' },
    { key: 'csp', label: 'Cloud Service Provider' }
  ],
  [
    { key: 'updated_at', label: 'Created Date', type: 'date' },
    { key: 'updated_at', label: 'Updated Date', type: 'date' }
  ]
]


const kindStatusLayout = [
  {
    label: "Kind",
    width: 2,
    subLabel: null,
    key: "kind",
  },
  {
    label: "Results",
    width: 3,
    subLabel: "Result",
    key: "result",
  },
  {
    label: "Non-Compliant",
    width: 1,
    subLabel: null,
    key: "failed",
  },
  {
    label: "Compliant",
    width: 1,
    subLabel: null,
    key: "passed",
  },
  {
    label: "Skipped",
    width: 1,
    subLabel: null,
    key: "skipped",
  },
  { label: "Compliance Score", width: 2, subLabel: null, key: "overall" },
];

const namespaceStatusLayout = [
  {
    label: "Namespace",
    width: 2,
    subLabel: null,
    key: "namespace",
  },
  {
    label: "Results",
    width: 3,
    subLabel: "Result",
    key: "result",
  },
  {
    label: "Non-Compliant",
    width: 1,
    subLabel: null,
    key: "failed",
  },
  {
    label: "Compliant",
    width: 1,
    subLabel: null,
    key: "passed",
  },
  {
    label: "Skipped",
    width: 1,
    subLabel: null,
    key: "skipped",
  },
  { label: "Compliance Score", width: 2, subLabel: null, key: "overall" },
];

const ClusterSummaryTab = (props) => {
  const {
    cluster,
    onLink,
    summary,
    detail
  } = props;

  const [resourcesByKind, setResourcesByKind] = useState({})
  const [resourcesByNamespace, setResourcesByNamespace] = useState({})
  const [summaryData, setSummaryData] = useState({})

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const resources = detail?.result?.results?.map(result => ({
        status: result.controls?.map(control => control.status?.status).reduce((acc, cur) => ({
          ...acc,
          [cur]: (acc?.[cur] ?? 0) + 1
        }), {}),
        // status: result.controls?.filter(control => control.status?.status?.includes('fail')).length > 0 ? 'failed' : result.controls?.filter(control => control.status?.status?.includes('skip')).length > 0 ? 'skipped' : 'passed',
        severity: result.controls.map(control => ({
          severity: detail?.result?.controls?.[control?.controlID]?.severity,
          status: control?.status?.status
        })),
        kind: detail?.result?.resources?.[result?.resourceID]?.kind,
        namespace:
          detail?.result?.resources?.[result?.resourceID]?.namespace ??
          detail?.result?.resources?.[result?.resourceID]?.metadata?.namespace
      })) ?? []

    setSummaryData({
      resource_counters: ['excluded', 'failed', 'passed', 'skipped'].reduce((acc, cur) => ({
        ...acc,
        [`${cur}Resources`]: resources.reduce((accu, current) => accu + (current?.status?.[cur] ?? 0), 0),
      }), {}),
      resource_severity_counters: Object.keys(NonComplianceStatusColor).reduce((acc, cur) => ({
        ...acc,
        [`${cur}Severity`]: resources.reduce((accu, current) => accu + current?.severity.filter(sev => sev.severity === cur && sev.status === 'failed')?.length, 0)
      }), {})
    });

    const groupByKind = Object.groupBy(resources, ({ kind }) => kind) 
    const groupByNamespace = Object.groupBy(resources, ({ namespace }) => namespace)
    
    let resourcesWStatusByKind = {}
    let resourcesWStatusByNamespace = {}
    Object.keys(groupByKind ?? {}).forEach((kind) => {
      resourcesWStatusByKind = {
        ...resourcesWStatusByKind,
        [kind]: {
          failed: groupByKind?.[kind].map(rsc => rsc.status?.failed ?? 0).reduce((acc, cur) => acc + cur, 0),
          passed: groupByKind?.[kind].map(rsc => rsc.status?.passed ?? 0).reduce((acc, cur) => acc + cur, 0),
          skipped: groupByKind?.[kind].map(rsc => rsc.status?.skipped ?? 0).reduce((acc, cur) => acc + cur, 0),
          total: groupByKind?.[kind].map(rsc => Object.values(rsc.status).reduce((acc, cur) => acc + cur, 0)).reduce((acc, cur) => acc + cur, 0),
          // failed: groupByKind?.[kind].filter(rsc => rsc.status === 'failed')?.length,
          // passed: groupByKind?.[kind].filter(rsc => rsc.status === 'passed')?.length,
          // skipped: groupByKind?.[kind].filter(rsc => rsc.status === 'skipped')?.length,
          // total: groupByKind?.[kind]?.length,
          kind: kind,
          overall: (groupByKind?.[kind].map(rsc => rsc.status?.passed ?? 0).reduce((acc, cur) => acc + cur, 0) / groupByKind?.[kind].map(rsc => Object.values(rsc.status).reduce((acc, cur) => acc + cur, 0)).reduce((acc, cur) => acc + cur, 0)) * 100,
          // overall: (groupByKind?.[kind].filter(rsc => rsc.status === 'passed')?.length / groupByKind?.[kind]?.length) * 100,
          result: ['critical', 'high', 'medium', 'low'].reduce((acc, cur) => ({ 
            ...acc,
            [cur]: {
              fail: groupByKind?.[kind]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc.severity === cur && rsc.status === 'failed')?.length,
              // fail: groupByKind?.[kind]?.map(rsc => rsc.severity.filter(sev => sev.severity === cur && rsc.status === 'failed').length).reduce((acc, cur) => acc + cur, 0),
              // total: groupByKind?.[kind]?.map(rsc => rsc.severity.filter(sev => sev.severity === cur).length).reduce((acc, cur) => acc + cur, 0)
            }
           }), {})
          // {
          //   critical: {
          //     pass: groupByKind?.[kind]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc.severity === 'critical' && rsc.status === 'passed')?.length,
          //     fail: groupByKind?.[kind]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc.severity === 'critical' && rsc.status === 'failed')?.length,
          //     total: groupByKind?.[kind]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc.severity === 'critical')?.length
          //   },
          //   high: {
          //     pass: groupByKind?.[kind]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc.severity === 'high' && rsc.status === 'passed')?.length,
          //     fail: groupByKind?.[kind]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc.severity === 'high' && rsc.status === 'failed')?.length,
          //     total: groupByKind?.[kind]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc.severity === 'high')?.length
          //   },
          //   medium: {
          //     pass: groupByKind?.[kind]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc.severity === 'medium' && rsc.status === 'passed')?.length,
          //     fail: groupByKind?.[kind]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc.severity === 'medium' && rsc.status === 'failed')?.length,
          //     total: groupByKind?.[kind]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc.severity === 'medium')?.length
          //   },
          //   low: {
          //     pass: groupByKind?.[kind]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc.severity === 'low' && rsc.status === 'passed')?.length,
          //     fail: groupByKind?.[kind]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc.severity === 'low' && rsc.status === 'failed')?.length,
          //     total: groupByKind?.[kind]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc.severity === 'low')?.length
          //   },
            
          // }
        }
      }
    })

    Object.keys(groupByNamespace ?? {}).filter(namespace => namespace).forEach((namespace) => {
      resourcesWStatusByNamespace = {
        ...resourcesWStatusByNamespace,
        [namespace]: {
          // failed: groupByNamespace?.[namespace].filter(rsc => rsc.status === 'failed')?.length,
          // passed: groupByNamespace?.[namespace].filter(rsc => rsc.status === 'passed')?.length,
          // skipped: groupByNamespace?.[namespace].filter(rsc => rsc.status === 'skipped')?.length,
          // total: groupByNamespace?.[namespace]?.length,
          failed: groupByNamespace?.[namespace].map(rsc => rsc.status?.failed ?? 0).reduce((acc, cur) => acc + cur, 0),
          passed: groupByNamespace?.[namespace].map(rsc => rsc.status?.passed ?? 0).reduce((acc, cur) => acc + cur, 0),
          skipped: groupByNamespace?.[namespace].map(rsc => rsc.status?.skipped ?? 0).reduce((acc, cur) => acc + cur, 0),
          total: groupByNamespace?.[namespace].map(rsc => Object.values(rsc.status).reduce((acc, cur) => acc + cur, 0)).reduce((acc, cur) => acc + cur, 0),
          namespace: namespace,
          // overall: (groupByNamespace?.[namespace].filter(rsc => rsc.status === 'passed')?.length / groupByNamespace?.[namespace]?.length) * 100,
          overall: (groupByNamespace?.[namespace].map(rsc => rsc.status?.passed ?? 0).reduce((acc, cur) => acc + cur, 0) / groupByNamespace?.[namespace].map(rsc => Object.values(rsc.status).reduce((acc, cur) => acc + cur, 0)).reduce((acc, cur) => acc + cur, 0)) * 100,
          result: ['critical', 'high', 'medium', 'low'].reduce((acc, cur) => ({ 
            ...acc,
            [cur]: {
              // fail: groupByNamespace?.[namespace]?.filter(rsc => rsc.severity.filter(sev => sev.severity === cur && sev.status === 'failed').length > 0)?.length,
              fail: groupByNamespace?.[namespace]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc?.severity === cur && rsc?.status === 'failed')?.length,
              total: groupByNamespace?.[namespace]?.filter(rsc => rsc.severity.filter(sev => sev.severity === cur).length > 0)?.length
            }
           }), {})
          // {
          //   critical: {
          //     pass: groupByNamespace?.[namespace]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc?.severity === 'critical' && rsc?.status === 'passed')?.length,
          //     fail: groupByNamespace?.[namespace]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc?.severity === 'critical' && rsc?.status === 'failed')?.length,
          //     total: groupByNamespace?.[namespace]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc?.severity === 'critical')?.length,
          //   },
          //   high: {
          //     pass: groupByNamespace?.[namespace]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc?.severity === 'high' && rsc?.status === 'passed')?.length,
          //     fail: groupByNamespace?.[namespace]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc?.severity === 'high' && rsc?.status === 'failed')?.length,
          //     total: groupByNamespace?.[namespace]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc?.severity === 'high')?.length,
          //   },
          //   medium: {
          //     pass: groupByNamespace?.[namespace]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc?.severity === 'medium' && rsc?.status === 'passed')?.length,
          //     fail: groupByNamespace?.[namespace]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc?.severity === 'medium' && rsc?.status === 'failed')?.length,
          //     total: groupByNamespace?.[namespace]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc?.severity === 'medium')?.length,
          //   },
          //   low: {
          //     pass: groupByNamespace?.[namespace]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc?.severity === 'low' && rsc?.status === 'passed')?.length,
          //     fail: groupByNamespace?.[namespace]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc?.severity === 'low' && rsc?.status === 'failed')?.length,
          //     total: groupByNamespace?.[namespace]?.map(rsc => rsc.severity)?.flat()?.filter(rsc => rsc?.severity === 'low')?.length,
          //   },
          // }
        }
      }
    })
    setResourcesByKind(resourcesWStatusByKind)
    setResourcesByNamespace(resourcesWStatusByNamespace)
  }, [detail])

  const getResourceCnt = (type, isTotal = false) => {
    const realKey = Object.keys(summaryData?.resource_counters ?? {}).filter(item => item.toLowerCase().includes(type.toLowerCase()))?.[0];
    const total = Object.values(summaryData?.resource_counters ?? {}).reduce((cur, acc) => cur + acc, 0);
    return isTotal ? total : summaryData?.resource_counters?.[realKey];
  }

  const getSeverityCnt = (severity, isTotal = false) => {
    const realKey = Object.keys(summaryData?.resource_severity_counters ?? {}).filter(item => item.toLowerCase().includes(severity.toLowerCase()))?.[0];
    const total = Object.values(summaryData?.resource_severity_counters ?? {}).reduce((cur, acc) => cur + acc, 0);
    return isTotal ? total : summaryData?.resource_severity_counters?.[realKey];
  }

  return (
    <MainBodyWrapper>
      <div className="mb-1">
        {infraSummary.map((item, idx) => (
          <SummaryItemContainer key={idx} idx={idx}>
            <Row className="align-items-center">
              {item.map((each) => (
                <Col md={6}>
                  <Row className="p-0 align-items-center">
                    <Col md={4}>
                      <StyledTypo className="text-justify label table-label">
                        {each.label}
                      </StyledTypo>
                    </Col>
                    <Col md={8}>
                      <StyledTypo className="text-justify table-label">
                        {cluster?.[each.key]
                          ? each.type === "date"
                            ? moment(cluster?.[each.key]).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : each.type === "array"
                            ? cluster?.[each.key].join(", ")
                            : each.type === "upper_string"
                            ? cluster?.[each.key].toUpperCase()
                            : cluster?.[each.key]
                          : "---"}
                      </StyledTypo>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </SummaryItemContainer>
        ))}
      </div>
      {
        Object.keys(cluster ?? {}).length ? (
          <>
            <div>
              <OverviewContentWrapper>
                <TotalBlock>
                  {dashboardOverviewList
                    .filter((each) => each.key !== "total")
                    .map((each) => (
                      <div
                        className={`item ${!each.border && "nonborder"}`}
                        key={each.key}
                      >
                        <StyledTypo
                          className="ml-3"
                          size={0.8}
                          line={1.4}
                          letter="0"
                          family="Roboto"
                          fontWeight={300}
                        >
                          {each.title}
                        </StyledTypo>
                        <div className="d-flex justify-content-between align-items-end pt-3">
                          <StyledTypo
                            className="text-center ml-3"
                            size={1.7}
                            line={1}
                            letter="0"
                            family="Roboto"
                            fontWeight={400}
                          >
                            {getResourceCnt(each.key, true) === 0 ? '0 %' : `${(getResourceCnt(each.key) * 100 / getResourceCnt(each.key, true)).toFixed()} %`}
                          </StyledTypo>
                          <div className="d-flex justify-content-center align-items-center">
                            <StyledTypo
                              className="mr-2"
                              color="#505050"
                              size={0.75}
                              line={1}
                              letter="0"
                              family="Roboto"
                              fontWeight={300}
                            >
                              {`${getResourceCnt(each.key)} / ${getResourceCnt(each.key, true)}`}
                            </StyledTypo>
                          </div>
                        </div>
                        <GradientProgressBar
                          color="#ececec70" color2="#adadad"
                          value={getResourceCnt(each.key, true) === 0 ? 0 : (getResourceCnt(each.key) * 100 / getResourceCnt(each.key, true)).toFixed()}
                          maxValue="100"
                          rotation="90"
                        />
                      </div>
                    ))}
                </TotalBlock>
                <RemediationOverviewWrapper>
                  {Object.keys(NonComplianceStatusColor).map((status) => (
                    <RemediationOverviewBlock
                      color={NonComplianceStatusColor[status].color}
                      onClick={() =>
                        onLink({
                          type: "severity",
                          data: ["Critical", "High", "Medium", "Low"].filter(sev => sev.toLowerCase() === status?.toLowerCase())?.[0],
                        })
                      }
                      key={status}
                    >
                      <StyledTypo
                        className="ml-3 severity-text"
                        size={0.75}
                        line={1.4}
                        letter="0"
                        family="Roboto"
                        fontWeight={400}
                      >
                        {status}
                      </StyledTypo>
                      <div className="d-flex justify-content-between align-items-end pt-3">
                        <StyledTypo
                          className="text-center ml-3"
                          size={1.7}
                          line={1}
                          letter="0"
                          family="Roboto"
                          fontWeight={400}
                        >
                          {getSeverityCnt(status, true) === 0 ? '0 %' : `${(getSeverityCnt(status) * 100 / getSeverityCnt(status, true)).toFixed()} %`}
                        </StyledTypo>
                        <div className="d-flex justify-content-center align-items-center">
                          <StyledTypo
                            className="mr-2"
                            size={0.75}
                            line={1}
                            letter="0"
                            family="Roboto"
                            fontWeight={400}
                          >
                            {`${getSeverityCnt(status)} / ${getSeverityCnt(status, true)}`}
                          </StyledTypo>
                        </div>
                      </div>
                    </RemediationOverviewBlock>
                  ))}
                </RemediationOverviewWrapper>
              </OverviewContentWrapper>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center py-5 align-items-center">
              {loadingBar("Rings", 50, 50)}
              <StyledTypo className="pl-2">Loading ...</StyledTypo>
            </div>
          </>
        )
    }
      <StatsTabWrapper>
        <Tabs>
          <TabList>
            <Tab>
              <StyledTypo
                line="1.8"
                size={0.8}
                family="Poppins"
                fontWeight={300}
              >
                Status by Kind
              </StyledTypo>
            </Tab>
            <Tab>
              <StyledTypo
                line="1.8"
                size={0.8}
                family="Poppins"
                fontWeight={300}
              >
                Status by Namespace
              </StyledTypo>
            </Tab>
          </TabList>
          <TabPanel>
            <SummaryWrapper>
              <Row className="p-2 align-items-center">
                {kindStatusLayout.map((status) => (
                  <Col md={status.width} key={status.label}>
                    <StyledTypo className="text-justify table-label label">
                      {status.label}
                    </StyledTypo>
                    {status.subLabel === "Result" && (
                      <div className="d-flex align-items-center flex-wrap mt-2">
                        {["Critical", "High", "Medium", "Low"].map(
                          (key) => (
                            <StyledStatusWrapper key={key}>
                              <StyledTypo
                                color="#687089"
                                size={0.6}
                                line={1}
                                letter="0"
                                family="Roboto"
                                fontWeight={300}
                              >
                                {key}
                              </StyledTypo>
                            </StyledStatusWrapper>
                          )
                        )}
                      </div>
                    )}
                  </Col>
                ))}
              </Row>
              {Object.values(resourcesByKind).map((data, idx) => (
                <SummaryItemContainer
                  hover
                  // cursor="pointer"
                  // onClick={() =>
                  //   onLink({
                  //     type: "compliance",
                  //     data: data?.compliance_name,
                  //   })
                  // }
                  key={idx}
                >
                  <Row className="d-flex align-items-center">
                    {kindStatusLayout.map((status) => (
                      <Col md={status.width} key={status.key}>
                        {['passed', 'failed', 'skipped', 'total'].includes(status.key) && (
                          <StyledTypo className="text-justify table-label">
                            {data?.[status.key] ?? 0}
                          </StyledTypo>
                        )}
                        {status.key === "overall" && (
                          <GradientProgressBar
                            color="#bdbdbd"
                            color2="#bdbdbd"
                            value={data?.[status.key] ?? 0}
                            maxValue="100"
                            rotation="90"
                            showNumber
                          />
                        )}
                        {status.key === "result" && (
                          <div className="d-flex align-items-center flex-wrap">
                            {Object.keys(NonComplianceStatusColor).map(
                              (key) => (
                                <StyledStatusWrapper
                                  color={
                                    NonComplianceStatusColor[key].color
                                  }
                                  key={key}
                                >
                                  <div className="mark" />
                                  <StyledTypo
                                    size={0.9}
                                    line={1.4}
                                    letter="0"
                                    family="Roboto"
                                    fontWeight={300}
                                  >
                                    {data?.[status.key]?.[key]?.fail ??
                                      0}
                                  </StyledTypo>
                                </StyledStatusWrapper>
                              )
                            )}
                          </div>
                        )}
                        {status.key === "kind" && (
                          <div className="d-flex align-items-center">
                            <StyledTypo className="text-justify table-label">
                              {data[status.key]}
                            </StyledTypo>
                          </div>
                        )}
                      </Col>
                    ))}
                  </Row>
                </SummaryItemContainer>
              ))}
            </SummaryWrapper>
          </TabPanel>
          <TabPanel>
            <SummaryWrapper>
              <Row className="p-2 align-items-center">
                {namespaceStatusLayout.map((status) => (
                  <Col md={status.width} key={status.label}>
                    <StyledTypo className="text-justify table-label label">
                      {status.label}
                    </StyledTypo>
                    {status.subLabel === "Result" && (
                      <div className="d-flex align-items-center flex-wrap mt-2">
                        {["Critical", "High", "Medium", "Low"].map(
                          (key) => (
                            <StyledStatusWrapper key={key}>
                              <StyledTypo
                                color="#687089"
                                size={0.6}
                                line={1}
                                letter="0"
                                family="Roboto"
                                fontWeight={300}
                              >
                                {key}
                              </StyledTypo>
                            </StyledStatusWrapper>
                          )
                        )}
                      </div>
                    )}
                  </Col>
                ))}
              </Row>
              {Object.values(resourcesByNamespace).map((data, idx) => (
                <SummaryItemContainer
                  hover
                  // cursor="pointer"
                  // onClick={() =>
                  //   onLink({
                  //     type: "resourceType",
                  //     data: data?.resource_category,
                  //   })
                  // }
                  key={idx}
                >
                  <Row>
                    {namespaceStatusLayout.map((status) => (
                      <Col md={status.width} key={status.key}>
                        {['passed', 'failed', 'skipped', 'total'].includes(status.key) && (
                          <StyledTypo className="text-justify table-label">
                            {data[status.key] ?? 0}
                          </StyledTypo>
                        )}
                        {status.key === "overall" && (
                          <GradientProgressBar
                            color="#bdbdbd"
                            color2="#bdbdbd"
                            value={data[status.key] ?? 0}
                            maxValue="100"
                            rotation="90"
                            showNumber
                          />
                        )}
                        {status.key === "namespace" && (
                          <div className="d-flex align-items-center">
                            {/* <img
                              src={
                                Object.values(
                                  providerResourceTypes[
                                    selectedApp?.platform
                                  ]
                                ).filter(
                                  (item) =>
                                    item.label === data[status.key]
                                )?.[0]?.logo ?? CubeIcon
                              }
                              alt={data[status.key]}
                              width={25}
                              height={25}
                              className="mr-2"
                            /> */}
                            <StyledTypo className="text-justify table-label">
                              {data[status.key] === 'undefined' ? 'Other' : data[status.key]}
                            </StyledTypo>
                          </div>
                        )}
                        {status.key === "result" && (
                          <div className="d-flex align-items-center flex-wrap">
                            {Object.keys(NonComplianceStatusColor).map(
                              (key) => (
                                <StyledStatusWrapper
                                  color={
                                    NonComplianceStatusColor[key].color
                                  }
                                >
                                  <div className="mark" />
                                  <StyledTypo
                                    size={0.9}
                                    line={1.4}
                                    letter="0"
                                    family="Roboto"
                                    fontWeight={300}
                                  >
                                    {data?.[status.key]?.[key]?.fail ??
                                      0}
                                  </StyledTypo>
                                </StyledStatusWrapper>
                              )
                            )}
                          </div>
                        )}
                      </Col>
                    ))}
                  </Row>
                </SummaryItemContainer>
              ))}
            </SummaryWrapper>
          </TabPanel>
        </Tabs>
      </StatsTabWrapper>
    </MainBodyWrapper>
  );
};

export default ClusterSummaryTab;
