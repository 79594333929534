import React, {useEffect} from 'react'

import {useHistory, useLocation} from 'react-router-dom'

import {authAction} from "../../redux/actions";
import {connect} from "react-redux";
import SideBar from "./SideBar";
import styled from 'styled-components'
import {ReactComponent as Logo} from "../../assets/icons/color-logo.svg";
import './guideDocStyle.css'
import StyledTypo from "../../components/Typo/StyledTypo";
import {colors} from "../../helpers/constants";

const DocContainer = styled.div`
  max-width: var(--container-lg);
  width: 100%;
  margin: 0 auto;
`

const DocHeaderWrapper = styled.div`
  width: 100%;
  min-height: var(--doc-header-height);
  border-bottom: var(--doc-header-border-width) solid var(--doc-header-bottom-color);
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    fill: var(--doc-logo-color);
  }
`

const DocHeaderLeftWrapper = styled.div`
  padding: 5px 0 5px 20px;
  flex: 1 1 auto;
  -webkit-box-flex: 1;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  cursor: pointer;
`

const DocHeaderRightWrapper = styled.div`
  padding: 5px 20px 5px 0;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
`

const StyledLink = styled.a`
  height: 30px;
  padding: 10px;
  text-decoration: none;
  color: var(--doc-main-font-color);
  
  &:hover {
    color: var(--doc-logo-color);
    text-decoration: none;
    cursor: pointer;
  }
`


const Dashboard = (props) => {
  const history = useHistory()

  return (
      <div className="user-doc-wrapper">
        <DocHeaderWrapper>
          <DocContainer className="d-flex">
            <DocHeaderLeftWrapper onClick={() => history.push('/login')}>
              <div className="cursor-pointer">
                <Logo/>
              </div>
              <StyledTypo className="cursor-pointer" color="#2E82D6" size={1} line={1} letter="0.05" family="Roboto"
                          fontWeight={900}>
                MatosSphere
              </StyledTypo>
            </DocHeaderLeftWrapper>
            <DocHeaderRightWrapper>
              <StyledLink onClick={() => history.push('/login')} alt="sign-in">
                <StyledTypo size={0.875} line={1.4} letter="0.05" family="Roboto"
                            fontWeight={500}>
                  Sign in
                </StyledTypo>
              </StyledLink>
              <StyledLink onClick={() => history.push('/register')} alt="sign-up">
                <StyledTypo size={0.875} line={1.4} letter="0.05" family="Roboto"
                            fontWeight={500}>
                  Sign up
                </StyledTypo>
              </StyledLink>
            </DocHeaderRightWrapper>
          </DocContainer>
        </DocHeaderWrapper>

        <DocContainer className="d-flex">
          <SideBar/>
          {props.children}
        </DocContainer>
      </div>
  )
}

function mapStateToProps(state) {
  const {expired_token, new_refresh_token, defused_refresh_token} = state.auth;
  return {
    expired_token,
    new_refresh_token,
    defused_refresh_token,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getRefreshToken: () => dispatch(authAction.getRefreshToken()),
    clearDefuseRefreshToken: () => dispatch(authAction.clearDefuseRefreshToken()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
