// the graph configuration, just override the ones you need
export const config1 = {
  "automaticRearrangeAfterDropNode": false,
  "collapsible": false,
  "directed": true,
  "focusAnimationDuration": 1,
  "focusZoom": 1,
  "freezeAllDragEvents": false,
  "height": 520,
  "highlightDegree": 1,
  "highlightOpacity": 1,
  "linkHighlightBehavior": true,
  "maxZoom": 4,
  "minZoom": 0.4,
  "initialZoom": 1.4,
  "nodeHighlightBehavior": true,
  "panAndZoom": true,
  "staticGraph": true,
  "staticGraphWithDragAndDrop": false,
  "width": 1100,
  "d3": {
    "alphaTarget": 0.05,
    "gravity": -600,
    "linkLength": 100,
    "linkStrength": 1,
    "disableLinkForce": false
  },
  "node": {
    "color": "#d3d3d3",
    "fontColor": "black",
    "fontSize": 12,
    "fontWeight": "normal",
    "highlightColor": "red",
    "highlightFontSize": 12,
    "highlightFontWeight": "normal",
    "highlightStrokeColor": "#C70039",
    "highlightStrokeWidth": "3",
    "labelProperty": "name",
    "mouseCursor": "pointer",
    "opacity": 1,
    "renderLabel": true,
    "size": 600,
    "strokeColor": "none",
    "strokeWidth": 1,
    "svg": "",
    "symbolType": "circle",
    "labelPosition": "bottom"
  },
  "link": {
    "color": "#BDBDBD",
    "fontColor": "black",
    "fontSize": 12,
    "fontWeight": "normal",
    "highlightColor": "#757575",
    "highlightFontSize": 12,
    "highlightFontWeight": "normal",
    "labelProperty": "label",
    "mouseCursor": "pointer",
    "opacity": 1,
    "renderLabel": false,
    "semanticStrokeWidth": false,
    "strokeWidth": 2,
    "markerHeight": 6,
    "markerWidth": 6,
    "strokeDasharray": 0,
    "strokeDashoffset": 0,
    "strokeLinecap": "butt"
  }
};