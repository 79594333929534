import {Container, Row, Col} from "reactstrap"
import handleViewport from 'react-in-viewport'

const Chart = (props) => {
  const {forwardedRef, violation} = props

    return (
        <div className="viewport-block" ref={forwardedRef} style={{height: violation ? '49vh' : '40vh'}}>
          {props.children}
        </div>
    )
}

const ViewportBlock = handleViewport(Chart)

const ChartContainer = (props) => {
  return (
      <Container fluid style={{padding: '24px 10px'}}>
        <Row>
          <Col md={12}>
            <ViewportBlock
                violation={!!props.violation}
            >
              {props.children}
            </ViewportBlock>
          </Col>
        </Row>
      </Container>
  )
}

export default ChartContainer;
