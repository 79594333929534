import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../helpers/constants";

const CommonInputWrapper = styled.div`
  .field {
    width: 100%;
    height: 56px;
    border-radius: 4px;
    position: relative;
    background-color: rgba(255, 255, 255, 1);
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
    margin: 11px 0;
  }

  .field:hover {
    background-color: rgba(255, 255, 255, 0.6);
    //box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }

  .field.active {
    background-color: #ffffff;
    //box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  }

  .field input + label {
    top: 4px;
    opacity: 1;
    color: #505050;
  }

  .field.locked {
    pointer-events: none;
  }

  .field input {
    width: 100%;
    height: 36px;
    margin-top: 20px;
    position: relative;
    padding: 0;
    border: none;
    border-radius: 4px;
    font-family: "Roboto";
    font-size: 0.8rem;
    font-weight: 300;
    line-height: 1.2;
    background-color: transparent;
    //color: #282828;
    color: #485dcd;
    outline: none;
    /* box-shadow: 0px 4px 20px 0px transparent; */
    transition: 0.3s background-color ease-in-out;
    -webkit-appearance: none;
  }

  .field.active input {
    height: 56px;
    margin-top: 0;
    padding: 24px 0 1px;
  }

  .field.active input::-webkit-input-placeholder {
    color: #ffffff;
  }
  .field input::-webkit-input-placeholder {
    color: #50505088;
  }
  .field input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .field input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
  .field input:-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }

  .field input + label {
    position: absolute;
    //top: 24px;
    left: 0;
    font-family: "Roboto";
    font-size: 0.8rem;
    font-weight: 300;
    line-height: 1.2;
    //color: #ffffff;
    //opacity: 0;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
    top: 4px;
    opacity: 1;
    color: #505050;
  }

  .field .error {
    margin-top: 3px;
    font-family: "Roboto", "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #ec392f;
  }
  .field .bottom-line {
    height: 0;
    border-top: 1px solid #ebebeb;
    transition: 0.3s border-top ease-in-out;
  }

  .field.active .bottom-line {
    height: 0;
    border-top: 1px solid #66c4d5;
    transition: 0.3s border-top ease-in-out;
  }
`;

const IconWrapper = styled.div`
  //margin-top: 20px;
  margin: 0 5px;
  width: 15px;
  height: 15px;
`;

const CommonInput = (props) => {
  const {
    locked,
    icon,
    label,
    value,
    setValue,
    placeholder,
    inputType,
    error,
    handleKeyPress = () => {},
    disabled,
    required,
    autoComplete,
  } = props;
  const [active, setActive] = useState((locked && props.active) || false);

  const AddedIcon = icon;

  const handleChange = (e) => {
    setValue && setValue(e.target.value);
  };

  const fieldClassName = `field ${(locked ? active : active) && "active"} ${
    locked && !active && "locked"
  }`;

  return (
    <CommonInputWrapper>
      <div className={fieldClassName}>
        <div className="d-flex align-items-center">
          <input
            type={inputType ? inputType : "text"}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            onFocus={() => !locked && setActive(true)}
            onBlur={() => !locked && setActive(false)}
            onKeyPress={handleKeyPress}
            disabled={disabled}
            autocomplete={autoComplete ? autoComplete : 'new-password'}
          />
          <label htmlFor={1}>
            {label} {required ? "*" : ""}
          </label>
          {icon && (
            <IconWrapper>
              <AddedIcon
                fill={
                  active
                    ? colors.input_color.active
                    : colors.input_color.inactive
                }
              />
            </IconWrapper>
          )}
        </div>
        <div className="bottom-line" />
        {error && <div className={error && "error"}>{error}</div>}
      </div>
    </CommonInputWrapper>
  );
};

export default CommonInput;
