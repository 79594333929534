import React from "react";
import Page from "../../components/PageLayout/Page";
import PentestingSettingsLayout from "./pentesting/settings/PentestingSettingsLayout";

const PentestingSettings = () => {
  return (
    <div>
      <Page>
        <PentestingSettingsLayout />
      </Page>
    </div>
  );
};

export default PentestingSettings;
