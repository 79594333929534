import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import React, {useRef, useEffect} from "react";
import styled from "styled-components";
import StyledTypo from "../../components/Typo/StyledTypo";
import EffectedButton from "../../components/Buttons/EffectedButton";

const PrettierContainer = styled.div`
  width: 300px;
  padding: 20px 15px;
  
  background: #FFFFFF;
  border: 2px solid #F1F5FA;
  box-sizing: border-box;
  box-shadow: ${props => props.showBackground ? '0 0 0 9999px #00000066' : '0 6px 4px rgba(104, 112, 137, 0.05)'};
  border-radius: 10px;
  transform: translate3d(-300px, 0px, 0px) !important;
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  z-index: 120;
`

const PrettierPanel = (props) => {
  const {prettierData, id, toggle, onChangeValue, onlyShow, title, showBackground} = props
  const ref = useRef()
  let initialClick = 0

  const outClickEvent = (e) => {
    if(ref && ref.current && !ref.current.contains(e.target)) {
      if(!initialClick) {
        initialClick ++;
      } else {
        toggle()
      }
    }
  }
  useEffect(() => {
    document.addEventListener('click', outClickEvent, false)
    return function() {
      document.removeEventListener('click', outClickEvent, false)
    }
  }, [])

  return (
      <PrettierContainer className="dropdown-menu show" ref={ref} showBackground={!showBackground}>
        <StyledTypo className="text-center mb-2" color="#A4AFB7" size={0.875} line={1.4} letter="0" family="Roboto" fontWeight={500}>
          {title}
        </StyledTypo>
        <JSONInput
            id={id}
            theme="light_mitsuketa_tribute"
            placeholder={prettierData}
            locale={locale}
            height="200px"
            colors={{
              string: "#DAA520" // overrides theme colors with whatever color value you want
            }}
            style={{labelColumn: {display: 'none'}, body: {backgroundColor: '#fff', border: '1px solid #dedede'}}}
            width="100%"
            confirmGood={false}
            onChange={onChangeValue}
            viewOnly={onlyShow}
        />
        <div className="d-flex align-items-center justify-content-center mt-2">
          <EffectedButton background="#485DCD" onClick={toggle}>
            <StyledTypo color="#fff" size={0.875} line={1.4} letter="0" family="Roboto" fontWeight={400}>
              OK
            </StyledTypo>
          </EffectedButton>
        </div>
        {/*<div className="modal-backdrop show"/>*/}
      </PrettierContainer>
  )
}

export default PrettierPanel;
