import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import { getCurrentUser } from "../../helpers/constants";
import StyledTypo from "../../components/Typo/StyledTypo";
import EffectedButton from "../../components/Buttons/EffectedButton";
import Cards from "react-credit-cards";
import React, { useState, useEffect } from "react";
import "react-credit-cards/lib/styles.scss";
import CommonInput from "../../components/Input/CommonInput";
import CreditCardInput from "react-credit-card-input";
import moment from "moment";
import CommonSelect from "../../components/Select/CommonSelect";

const ModalWrapper = styled.div``;

const ModalContent = styled.div`
  padding: 30px 20px 15px;
`;
const AcceptButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 35px 10px;
`;

const PaymentMethodContainer = styled.div`
  padding: 20px 10px;
`;

const PaymentMethodItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
`;

const CardInputModal = (props) => {
  const { toggle, handleAccept, payment_methods, default_payment } = props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [number, setNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [paymentData, setPaymentData] = useState({});

  const newOption = { value: null, label: "New payment method" };
  const paymentOptions = [
    newOption,
    ...payment_methods.map((p) => ({
      value: p.id,
      label: `**** ${p.card.last4}`,
    })),
  ];

  const [selectedPayment, setSelectedPayment] = useState(
    paymentOptions.filter((p) => p.value === default_payment)?.[0] ?? newOption
  );

  useEffect(() => {
    setPaymentData({
      card: {
        number,
        cvc,
        exp_month: parseInt(expiry.split(" / ")?.[0] ?? ""),
        exp_year: parseInt(
          `${moment().format("YYYY").substr(0, 2)}${
            expiry.split(" / ")?.[1] ?? ""
          }`
        ),
      },
      billing_details: {
        address: {
          postal_code: zipCode,
        },
        email: getCurrentUser()?.email,
        name: `${firstName} ${lastName}`,
      },
    });
  }, [number, expiry, cvc, firstName, lastName, zipCode]);

  return (
    <ModalWrapper>
      <Modal
        isOpen={props.modal}
        className="card-input-modal"
        fade={true}
        toggle={toggle}
        style={{ background: "transparent" }}
      >
        <ModalBody>
          <ModalContent>
            <StyledTypo
              className="text-center"
              color="#505050"
              size={1}
              line={1.4}
              letter="0"
              family="Roboto"
              fontWeight={500}
            >
              Select Payment method
            </StyledTypo>
            <CommonSelect
              value={selectedPayment}
              setValue={setSelectedPayment}
              options={paymentOptions}
              label=""
              // onChange={handleModeChange}
            />
            {selectedPayment.value ? (
              <PaymentMethodContainer>
                <PaymentMethodItem>
                  <StyledTypo
                    color="#505050"
                    size={0.875}
                    line={1.4}
                    letter="0"
                    family="Roboto"
                    fontWeight={500}
                  >
                    Brand:
                  </StyledTypo>
                  <StyledTypo
                    color="#505050"
                    size={0.875}
                    line={1.4}
                    letter="0"
                    family="Roboto"
                    fontWeight={400}
                  >
                    {
                      payment_methods.filter(
                        (item) => item.id === selectedPayment.value
                      )?.[0]?.card?.["brand"]
                    }
                  </StyledTypo>
                </PaymentMethodItem>
                <PaymentMethodItem>
                  <StyledTypo
                    color="#505050"
                    size={0.875}
                    line={1.4}
                    letter="0"
                    family="Roboto"
                    fontWeight={500}
                  >
                    Card Number:
                  </StyledTypo>
                  <StyledTypo
                    color="#505050"
                    size={0.875}
                    line={1.4}
                    letter="0"
                    family="Roboto"
                    fontWeight={400}
                  >
                    {selectedPayment.label}
                  </StyledTypo>
                </PaymentMethodItem>
                <PaymentMethodItem>
                  <StyledTypo
                    color="#505050"
                    size={0.875}
                    line={1.4}
                    letter="0"
                    family="Roboto"
                    fontWeight={500}
                  >
                    Expiry:
                  </StyledTypo>
                  <StyledTypo
                    color="#505050"
                    size={0.875}
                    line={1.4}
                    letter="0"
                    family="Roboto"
                    fontWeight={400}
                  >
                    {`${
                      payment_methods.filter(
                        (item) => item.id === selectedPayment.value
                      )?.[0]?.card?.["exp_year"]
                    }-${
                      payment_methods.filter(
                        (item) => item.id === selectedPayment.value
                      )?.[0]?.card?.["exp_month"]
                    }`}
                  </StyledTypo>
                </PaymentMethodItem>
              </PaymentMethodContainer>
            ) : (
              <div className="mt-5">
                <Cards
                  cvc={cvc}
                  expiry={expiry.replace(" / ", "")}
                  name={`${firstName} ${lastName}`}
                  number={number}
                />
                <div className="mt-5">
                  <CommonInput
                    label="First Name"
                    value={firstName}
                    setValue={setFirstName}
                    active={false}
                    locked={false}
                    placeholder=""
                  />
                  <CommonInput
                    label="Last Name"
                    value={lastName}
                    setValue={setLastName}
                    active={false}
                    locked={false}
                    placeholder=""
                  />
                  <CreditCardInput
                    cardNumberInputProps={{
                      value: number,
                      onChange: (e) => setNumber(e.target.value),
                    }}
                    cardExpiryInputProps={{
                      value: expiry,
                      onChange: (e) => setExpiry(e.target.value),
                    }}
                    cardCVCInputProps={{
                      value: cvc,
                      onChange: (e) => setCvc(e.target.value),
                    }}
                    cardZipInputProps={{
                      value: zipCode,
                      onChange: (e) => setZipCode(e.target.value),
                    }}
                    fieldClassName="input"
                    enableZipInput={true}
                  />
                </div>
              </div>
            )}
            <AcceptButtonWrapper>
              <EffectedButton
                width="150px"
                marginLeft="0"
                background="#65C2D7"
                padding={0}
                height={40}
                noSpan
                onClick={() => {
                  toggle();
                  handleAccept(selectedPayment, paymentData);
                }}
              >
                <StyledTypo
                  className="ml-2"
                  color="#fff"
                  size={0.875}
                  line={1.4}
                  letter="0"
                  family="Roboto"
                  fontWeight={500}
                >
                  Ok
                </StyledTypo>
              </EffectedButton>
            </AcceptButtonWrapper>
          </ModalContent>
        </ModalBody>
      </Modal>
    </ModalWrapper>
  );
};

export default CardInputModal;
