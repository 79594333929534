import React from "react";
import Page from "../../components/PageLayout/Page";
import IaCProjectLayout from "./iac/IaCProjectLayout";

const IaC = (props) => {
  return (
    <div>
      <Page noUpperCta>
        <IaCProjectLayout />
      </Page>
    </div>
  );
};

export default IaC;
