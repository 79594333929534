import React, { useState, useEffect } from "react";
import StyledTypo from "../../../components/Typo/StyledTypo";
import styled from "styled-components";
import StyledCheckbox from "../../../components/Input/StyledCheckbox";
import { colors, fakeDeploymentData } from "../../../helpers/constants";
import { ReactComponent as CircleTimesIcon } from "../../../assets/icons/m_circle_times_icon.svg";
import { ReactComponent as CircleRefreshIcon } from "../../../assets/icons/m_circle_refresh_icon.svg";
import { ReactComponent as CircleCheckIcon } from "../../../assets/icons/m_circle_check_icon.svg";
import { ReactComponent as CircleTrashIcon } from "../../../assets/icons/m_circle_trash_icon.svg";
import { ReactComponent as ThreeDotIcon } from "../../../assets/icons/three_dot_icon.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info_icon.svg";
import { ReactComponent as DeployButtonIcon } from "../../../assets/icons/deploy_icon.svg";
import { ReactComponent as DeleteButtonIcon } from "../../../assets/icons/trash_icon.svg";

import StyledDropdown from "../../../components/Buttons/StyledDropdown";
import DropdownIcon from "../../../components/Buttons/DropdownIcon";
import { DropdownMenu } from "reactstrap";

import moment from "moment";

const TableWrapper = styled.div`
  padding-top: 20px;
  .divider {
    margin: 8px -46.5px 4px -37.5px;
    border-top: 1px solid rgba(164, 175, 183, 0.3);
    height: 0;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 26px;
`;

const EachCell = styled.div`
  width: ${(props) => props.width};
  //word-break: break-all;
  padding-right: 15px;
`;

const RecordWrapper = styled.div`
  padding: 11px 26px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  word-break: break-all;
`;

const StatusItem = styled.div`
  position: relative;
  width: 40px;
  //padding-top: 10px;
  //margin-top: 10px;

  .status-badge {
    position: absolute;
    top: -5px;
    right: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #f1f1f1;
    color: #ff487f;
    font-size: 11px;
    line-height: 20px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
`;

const ActionDropdownMenu = styled(DropdownMenu)`
  // --width: ${(props) => props.width - 350}px;
  // left: calc(var(--width) - 50px - 1vw) !important;
  /* white */
  min-width: 164px;
  background: #ffffff;
  /* Gray light 2 */

  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  /* box shadow */

  box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 20px;
  padding: 33px 35px;
  transform: translate3d(-140px, -10px, 0px) !important;
`;

const ShowingWrapper = styled.div`
  margin-top: 16px;
`;

const TableHeader = ({ handleAllSelect, checked }) => {
  return (
    <HeaderWrapper>
      <EachCell width={`${3400 / 1596}%`}>
        <StyledCheckbox handleOnChange={handleAllSelect} checked={checked} />
      </EachCell>
      <EachCell width={`${39000 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={700}
        >
          Name
        </StyledTypo>
      </EachCell>
      <EachCell width={`${34600 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          Git Repo
        </StyledTypo>
      </EachCell>
      <EachCell width={`${15600 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          Created
        </StyledTypo>
      </EachCell>
      <EachCell width={`${15600 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          Updated
        </StyledTypo>
      </EachCell>
      <EachCell width={`${9200 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          Creator
        </StyledTypo>
      </EachCell>
      <EachCell width={`${13600 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          Deployments
        </StyledTypo>
      </EachCell>
      <EachCell width={`${21500 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          Node Instances
        </StyledTypo>
      </EachCell>
      <EachCell width={`${7100 / 1596}%`}>
        <StyledTypo
          className="text-right"
          color={"#505050"}
          size={1}
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          Actions
        </StyledTypo>
      </EachCell>
    </HeaderWrapper>
  );
};

const Record = (props) => {
  const { data, isOpenActionMenu, toggleActionMenu, handleChange } = props;
  return (
    <RecordWrapper key={data.id}>
      <EachCell width={`${3400 / 1596}%`}>
        <StyledCheckbox
          checked={data.selected}
          handleOnChange={() => handleChange(data.id)}
        />
      </EachCell>
      <EachCell width={`${39000 / 1596}%`}>
        <div className="d-flex">
          <StyledTypo
            color={"#505050"}
            size={1}
            line={26.15 / 16}
            letter="0"
            family="Poppins"
            fontWeight={700}
          >
            {data.template.name}
          </StyledTypo>
          <div className="m-tooltip ml-3">
            <InfoIcon />
            <span className="tooltiptext">
              <div>{data.template.description ?? "description"}</div>
            </span>
          </div>
        </div>
      </EachCell>
      <EachCell width={`${34600 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={26.15 / 16}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {data.template.repo_url} - {data.template.branch}
        </StyledTypo>
      </EachCell>
      <EachCell width={`${15600 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={26.15 / 16}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {moment(data.createdDate).format("MM-DD-YYYY HH:mm")}
        </StyledTypo>
      </EachCell>
      <EachCell width={`${15600 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={26.15 / 16}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {moment(data.updatedDate).format("MM-DD-YYYY HH:mm")}
        </StyledTypo>
      </EachCell>
      <EachCell width={`${9200 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={26.15 / 16}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {data.template.creator ?? "admin 1"}
        </StyledTypo>
      </EachCell>
      <EachCell width={`${13600 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={26.15 / 16}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {data.template.deployments ?? "now"}
        </StyledTypo>
      </EachCell>
      <EachCell width={`${21500 / 1596}%`}>
        <div className="d-flex justify-content-between align-items-center">
          <StatusItem>
            <CircleTimesIcon />
            {/*<span className="status-badge">{data.node_instance?.fail ?? 1}</span>*/}
          </StatusItem>
          <StatusItem>
            <CircleRefreshIcon />
            {/*<span className="status-badge">{data.node_instance?.refresh ?? 2}</span>*/}
          </StatusItem>
          <StatusItem>
            <CircleCheckIcon />
            {/*<span className="status-badge">{data.node_instance?.running ?? 3}</span>*/}
          </StatusItem>
          <StatusItem>
            <CircleTrashIcon />
            {/*<span className="status-badge">{data.node_instance?.delete ?? 0}</span>*/}
          </StatusItem>
        </div>
      </EachCell>
      <EachCell width={`${7100 / 1596}%`} className="text-right">
        <StyledDropdown
          isOpen={isOpenActionMenu.filter((item) => item[data.id]).length}
          toggle={() => toggleActionMenu(data.id)}
        >
          <DropdownIcon>
            <ThreeDotIcon />
          </DropdownIcon>
          <ActionDropdownMenu>
            <div className="d-flex mb-2 align-items-center">
              <DeleteButtonIcon fill={colors.red} width={17} height={20} />
              <StyledTypo
                className="ml-3"
                color={"#505050"}
                size={1.125}
                line={26.15 / 18}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                Remove
              </StyledTypo>
            </div>
            <div className="d-flex align-items-center">
              <DeployButtonIcon />
              <StyledTypo
                className="ml-3"
                color={"#505050"}
                size={1.125}
                line={26.15 / 18}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                Deploy
              </StyledTypo>
            </div>
          </ActionDropdownMenu>
        </StyledDropdown>
      </EachCell>
    </RecordWrapper>
  );
};

const DisplayTableDeployment = (props) => {
  const { data } = props;
  const [isOpenActionMenu, setIsOpenActionMenu] = useState(
    fakeDeploymentData.map((item) => ({ [item.id]: false }))
  );
  const [deployData, setDeployData] = useState([...data]);

  const toggleActionMenu = (key) => {
    setIsOpenActionMenu(
      isOpenActionMenu.map((item) => ({ ...item, [key]: !item[key] }))
    );
  };

  const handleChange = (id) => {
    setDeployData((deployData) =>
      deployData.map((item) => ({
        ...item,
        selected: item.id === id ? !item.selected : item.selected,
      }))
    );
  };

  const handleAllSelect = () => {
    let unselectedCount = deployData.filter((item) => !item.selected).length;
    setDeployData((deployData) =>
      deployData.map((item) => ({ ...item, selected: !!unselectedCount }))
    );
  };

  useEffect(() => {
    setDeployData([...data]);
  }, [data]);

  return (
    <TableWrapper>
      <TableHeader
        handleAllSelect={handleAllSelect}
        checked={
          deployData.filter((item) => item.selected).length ===
          deployData.length
        }
      />
      <div className="divider" />
      {deployData?.length ? (
        deployData.map((data) => (
          <Record
            data={data}
            isOpenActionMenu={isOpenActionMenu}
            toggleActionMenu={toggleActionMenu}
            handleChange={handleChange}
          />
        ))
      ) : (
        <div className="text-center my-5">
          <StyledTypo
            color="#50505099"
            size={1.125}
            line={26.15 / 18}
            letter="0"
            family="Poppins"
            fontWeight={500}
          >
            No template
          </StyledTypo>
        </div>
      )}
      <ShowingWrapper>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={26.15 / 16}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {`Showing ${deployData.length} from ${deployData.length} Results`}
        </StyledTypo>
      </ShowingWrapper>
    </TableWrapper>
  );
};

export default DisplayTableDeployment;
