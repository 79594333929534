import React, { useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const RecaptchaWrapper = (props) => {
  const { setToken, isRefresh, setRefresh } = props;
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (isRefresh) {
      if (!executeRecaptcha) {
        return;
      }
      const handleReCaptchaVerify = async () => {
        const token = await executeRecaptcha("register");
        console.log(token, "====== recaptcha token")
        setToken(token);
      };
      handleReCaptchaVerify();
      setRefresh(false);
    }
  }, [isRefresh, executeRecaptcha]);

  return <></>;
};

export default RecaptchaWrapper;
