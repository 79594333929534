import React, { useState, useEffect } from "react";
import styled from "styled-components";

import {
  historyTabList,
  calendarDays,
  commonTitle
} from "../../../helpers/constants";
import { connect } from "react-redux";
import NotificationsPanel from "./NotificationsPanel";
import StyledTypo from "../../../components/Typo/StyledTypo";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useTitle } from "../../../helpers/useTtitle";
import CalendarComponent from './CalendarComponent'
import { applicationActions } from '../../../redux/actions'
import moment from 'moment'


const ContainerWrapper = styled.div`
  width: calc(100vw - 111px);
  margin-left: 102px;
  padding-top: 20px;
  padding-inline: 39px;
  .filter-container {
    display: flex;
    flex-direction: row-reverse;
    margin-block: 10px;
  }
`;
const pageTitle = `${commonTitle} - Activity Logs`

const HistoryLayout = (props) => {
  const { logs, loading_log, fetchGLogsAction } = props
  const [duration, setDuration] = useState(calendarDays?.[0])
  useTitle(pageTitle);

  useEffect(() => {
    const start = moment()
    const end = moment().subtract(duration?.value, duration?.unit)
    const period = Math.ceil(moment.duration(start.diff(end)).asDays())
    fetchGLogsAction(period);
  }, [duration]);

  return (
    <ContainerWrapper>
      <div className="filter-container">
        <CalendarComponent duration={duration} setDuration={setDuration} />
      </div>
      <Tabs>
        <TabList>
          {historyTabList.map((tab) => (
            <Tab>
              <StyledTypo
                line="1.8"
                size={0.8}
                family="Poppins"
                fontWeight={300}
              >
                {tab.title}
              </StyledTypo>
            </Tab>
          ))}
        </TabList>
        {historyTabList.map((tab) => (
            <TabPanel key={tab.key}>
              <NotificationsPanel category={tab.key} duration={duration} logs={logs} loading_log={loading_log}/>
            </TabPanel>
        ))}
      </Tabs>
    </ContainerWrapper>
  );
};

function mapStateToProps (state) {
  const { logs, loading_log } = state.applications
  return {
    logs,
    loading_log
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchGLogsAction: period =>
      dispatch(applicationActions.fetchGLogsAction(period))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryLayout);
