import React from 'react'
import Dashboard from "./Dashboard";
import ArticleContentTemplate from "./pages/ArticleContentTemplate";

const UserGuide = () => {
  return (
      <Dashboard>
        <ArticleContentTemplate />
      </Dashboard>
  )
}

export default UserGuide
