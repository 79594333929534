/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  forwardRef,
  useImperativeHandle
} from 'react'
import StyledTypo from '../../../components/Typo/StyledTypo'
import styled from 'styled-components'
import { ReactComponent as ArrowUp } from '../../../assets/icons/up_arrow_icon.svg'
import { ReactComponent as ArrowDown } from '../../../assets/icons/down_arrow_icon.svg'
import ReactPaginate from 'react-paginate'
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter_icon.svg'
import { ReactComponent as FilterClearIcon } from '../../../assets/icons/filter_clear_icon.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_rec_icon.svg'
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy-icon.svg'

import StyledCheckbox from '../../../components/Input/StyledCheckbox'
import CustomInput from '../../../components/Input/CustomInput'
import CommonSelect from '../../../components/Select/CommonSelect'
import moment from 'moment'
import {
  actionIacPlatform,
  iac_status_color,
  loadingBar,
  platformList,
  showAlert
} from '../../../helpers/constants'
import { connect } from 'react-redux'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg'
import { iacActions } from '../../../redux/actions'
import { useHistory } from 'react-router-dom'
import EffectedButton from '../../../components/Buttons/EffectedButton'
import { MainContext } from '../../../contexts'
import { TableWrapper } from '../../../components/PageLayout/Table'
import {
  FilterWrapper,
  FilterBodyContainer
} from '../../../components/PageLayout/Filter'

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 26px;
`

const EachCell = styled.div`
  width: ${props => props.width};
  padding-right: ${props => (props.pr ? props.pr : 0)}px;
  word-break: break-word;
`

const RecordContainer = styled.div`
  border-top: 1px solid #fff;
  transition: border 500ms ease;
  background: ${props => (props.active ? '#f1f1f1' : 'transparent')};
  position: relative;

  .floating-button {
    display: none;
  }
  &:hover .floating-button {
    display: block;
  }
`

const RecordWrapper = styled.div`
  padding: 11px 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .text-cell {
    color: #505050;
  }
  cursor: pointer;
  &:hover {
    border-radius: 5px;
    background-color: #f1f1f1;
  }
`

const FloatingButtonWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 7px;
`

const ShowingWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  position: fixed;
  right: 40px;
  bottom: 20px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100vw - 200px);
  background-color: #fff;

  .page-link:focus {
    box-shadow: none !important;
  }
  ul.pagination {
    margin-bottom: 0;
    font-size: 0.8rem;
    font-weight: 0.4;
    font-family: 'Roboto';
  }
`

const CloseWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`

const selectEmptyResourceType = {
  label: 'Select...',
  value: ''
}

const StyledStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 65px;

  margin-right: ${props => (props.mr ? props.mr : 20)}px;

  .mark {
    width: ${props => (props.size ? props.size : 12)}px;
    height: ${props => (props.size ? props.size : 12)}px;
    border-radius: 50%;
    background-color: ${props => props.color};
    // box-shadow: 0 8px 6px -3px ${props => props.color};
  }
`

const CopyCTAWrapper = styled.div`
  padding: 5px;
  cursor: pointer;
  & svg:hover {
    fill: #5eb1e4;
  }
`


const FilterPanel = props => {
  const {
    searchText,
    setSearchText,
    selectedCategory,
    setSelectedCategory,
    selectedPlatform,
    setSelectedPlatform,
    selectedFilterType,
    setSelectedFilterType
  } = props

  const [filterBarOpen, setFilterBarOpen] = useState(false)

  const clearFilter = () => {
    setSelectedPlatform(selectEmptyResourceType)
    setSelectedCategory(selectEmptyResourceType)
    setFilterBarOpen(false)
  }
  useEffect(() => {
    if (selectedFilterType) {
      setSelectedFilterType(null)
      setFilterBarOpen(true)
    }
  }, [selectedFilterType, setSelectedFilterType])

  const checkFilterContent = () => {
    return (
      !!selectedCategory?.value?.length || !!selectedPlatform?.value?.length
    )
  }

  return (
    <FilterWrapper left='520px'>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center mr-3 filter-menu'>
          <a
            className='menu-icon'
            onClick={() => setFilterBarOpen(!filterBarOpen)}
            title='Filter By'
            alt='Filter By'
            href='#'
          >
            <FilterIcon
              width={22}
              height={22}
              fill='#606060'
              className='svg-icon'
            />
            <span className='menu-tip'>Filter by</span>
          </a>
          <a
            className={
              checkFilterContent() ? 'menu-icon' : 'menu-icon menu-disabled'
            }
            onClick={clearFilter}
            title='Filter Clear'
            alt='Filter Clear'
            href='#'
          >
            <FilterClearIcon
              width={22}
              height={22}
              fill='#606060'
              className='svg-icon'
            />
            <span className='menu-tip'>Filter clear</span>
          </a>
        </div>
        <div>
          <CustomInput
            minWidth={350}
            placeholder='Search'
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
        </div>
      </div>
      {filterBarOpen && (
        <FilterBodyContainer>
          <CloseWrapper
            className='cursor-pointer'
            onClick={() => setFilterBarOpen(false)}
          >
            <CloseIcon width={15} stroke='#A4AFB7' />
          </CloseWrapper>
          <div className='ml-3' style={{ minWidth: 250 }}>
            <CommonSelect
              value={selectedCategory}
              setValue={setSelectedCategory}
              options={platformList}
              label={`Cloud Service Provider`}
              isClear
            />
          </div>
          <div className='ml-3' style={{ minWidth: 250 }}>
            <CommonSelect
              value={selectedPlatform}
              setValue={setSelectedPlatform}
              options={actionIacPlatform}
              label={`Platform`}
              isClear
            />
          </div>
        </FilterBodyContainer>
      )}
    </FilterWrapper>
  )
}

const TableHeader = props => {
  const { columns, currentSort, setCurrentSort, data, setData } = props

  const handleSetSort = access => {
    setCurrentSort({
      access,
      order:
        (Array.isArray(currentSort.access)
          ? currentSort.access.join()
          : currentSort.access) ===
        (Array.isArray(access) ? access.join() : access)
          ? !currentSort.order
          : true
    })
  }

  return (
    <HeaderWrapper>
      {columns &&
        columns.map(column => (
          <EachCell
            width={column.width ?? '100%'}
            key={column.access}
            className={` ${
              column.title === 'Results' ? 'flex-column align-items-start' : ''
            }d-flex align-items-center ${column.sort ? 'cursor-pointer' : ''} ${
              column.title === 'Action' ? 'justify-content-end' : ''
            } `}
            pr={10}
            onClick={() => {
              if (column.sort) {
                handleSetSort(column.access)
              }
            }}
          >
            {column.type === 'checkbox' && (
              <StyledCheckbox
                handleOnChange={() => {
                  const allChecked =
                    data.filter(item => item.checked).length === data.length
                  setData(dataList =>
                    dataList.map(item => ({ ...item, checked: !allChecked }))
                  )
                }}
                className='mt-2'
                checked={
                  data.filter(item => item.checked).length === data.length
                }
              />
            )}
            {column.sort &&
            (Array.isArray(currentSort.access)
              ? currentSort.access.join()
              : currentSort.access) ===
              (Array.isArray(column.access)
                ? column.access.join()
                : column.access) ? (
              currentSort.order ? (
                <ArrowUp fill={'#A4AFB7'} className='mr-3' />
              ) : (
                <ArrowDown fill={'#A4AFB7'} className='mr-3' />
              )
            ) : (
              <div />
            )}
            <div
              className={
                column.title === 'Action'
                  ? 'ml-auto'
                  : column.type === 'overview'
                  ? 'ml-auto mr-auto'
                  : ''
              }
            >
              <StyledTypo
                color={
                  column.access === currentSort.access && column.access
                    ? '#485DCD'
                    : '#505050'
                }
                size={0.8}
                line={1.2}
                letter='0'
                family='Roboto'
                fontWeight={400}
              >
                {column.title}
              </StyledTypo>
            </div>
            <div>
              {column.title === 'Results' && (
                <div className='d-flex align-items-center flex-wrap mt-2'>
                  {Object.keys(iac_status_color).map(key => (
                    <StyledStatusWrapper color={iac_status_color[key].color}>
                      <StyledTypo
                        color='#687089'
                        size={0.6}
                        line={1}
                        letter='0'
                        family='Roboto'
                        fontWeight={300}
                        className='severity-text'
                      >
                        {key.toLowerCase()}
                      </StyledTypo>
                    </StyledStatusWrapper>
                  ))}
                </div>
              )}
            </div>
          </EachCell>
        ))}
    </HeaderWrapper>
  )
}

const Record = props => {
  const { data, columns, setData, setUpdateProject } = props
  const history = useHistory()

  const toggleCheck = id => {
    setData(data => [
      ...data.map(item => ({
        ...item,
        checked: item.id === id ? !item.checked : item.checked
      }))
    ])
  }

  return (
    <div>
      <RecordContainer active={data.checked}>
        <RecordWrapper active={data.checked}>
          {
            <>
              {columns.map(column => (
                <EachCell width={column.width ?? '100%'} pr={10}>
                  {column.type === 'checkbox' && (
                    <StyledCheckbox
                      handleOnChange={() => toggleCheck(data.id)}
                      checked={data.checked}
                      name=''
                      className='mr-5 mt-2 w-100'
                      color='#505050'
                      fontFamily='Roboto'
                      letter='0'
                    />
                  )}
                  {column.type === 'date' && (
                    <StyledTypo
                      className='d-flex'
                      color={'#505050'}
                      size={0.9}
                      line={1.4}
                      letter='0'
                      family='Roboto'
                      fontWeight={300}
                    >
                      {data[column.access]
                        ? moment(data[column.access]).format(
                            'YYYY-MM-DD HH:mm:ss'
                          )
                        : '---'}
                    </StyledTypo>
                  )}
                  {column.type === 'icon' && (
                    <div className='d-flex align-items-center'>
                      <img
                        src={
                          actionIacPlatform.filter(
                            iac =>
                              iac.label.toLowerCase() ===
                              data.platform?.[0]?.toLowerCase()
                          )?.[0]?.logo
                        }
                        alt={`${data[column.access]} logo`}
                        width={18}
                        height={18}
                      />

                      <StyledTypo
                        className='ml-2 pl-1'
                        onClick={() => {
                          history.push(`/iac/${data.id}`)
                        }}
                        color={'#505050'}
                        size={0.9}
                        line={1.4}
                        letter='0'
                        family='Roboto'
                        fontWeight={300}
                      >
                        {data[column.access]}
                      </StyledTypo>
                    </div>
                  )}
                  {column.type === 'object' && (
                    <div className='d-flex align-items-center flex-wrap'>
                      {Object.keys(iac_status_color).map(key => (
                        <StyledStatusWrapper
                          color={iac_status_color[key].color}
                        >
                          <div className='mark' />
                          <StyledTypo
                            size={0.9}
                            line={1.4}
                            letter='0'
                            family='Roboto'
                            fontWeight={300}
                          >
                            {data?.[column.access]?.[key] ?? 0}
                          </StyledTypo>
                        </StyledStatusWrapper>
                      ))}
                    </div>
                  )}
                  {column.type === 'button' && (
                    <EffectedButton
                      padding={20}
                      height={25}
                      noSpan
                      onClick={() => setUpdateProject(data)}
                      background='#5EB1E4'
                    >
                      <StyledTypo
                        color='#fff'
                        size={0.9}
                        line={1.4}
                        letter='0'
                        family='Roboto'
                        fontWeight={400}
                      >
                        Edit
                      </StyledTypo>
                    </EffectedButton>
                  )}
                  {column.type === 'hint' && (
                    <div className="d-flex">
                        <StyledTypo
                        className='text-cell'
                        // onClick={() => {
                        //     history.push(`/iac/${data.id}`)
                        // }}
                        color={'#505050'}
                        size={0.9}
                        line={1.4}
                        letter='0'
                        family='Roboto'
                        fontWeight={300}
                        >
                        **** {data[column.access].substr(-4)}
                        </StyledTypo>
                        <CopyCTAWrapper
                        className='ml-2'
                        onClick={() => {
                            navigator.clipboard.writeText(data[column.access])
                            showAlert('success', 'Copied API Key', '', 2000)
                        }}
                        >
                        <CopyIcon width={20} height={20} />
                        </CopyCTAWrapper>
                    </div>
                  )}
                  {!column.type && (
                    <StyledTypo
                      className='text-cell'
                      onClick={() => {
                        history.push(`/iac/${data.id}`)
                      }}
                      color={'#505050'}
                      size={0.9}
                      line={1.4}
                      letter='0'
                      family='Roboto'
                      fontWeight={300}
                    >
                      {Array.isArray(column.access)
                        ? column.access.map(access => data[access]).join(' / ')
                        : data[column.access]}
                    </StyledTypo>
                  )}
                </EachCell>
              ))}
              {/*<div className="cursor-pointer" onClick={toggleDrawer}>*/}
              {/*  <RightArrow fill="#505050" />*/}
              {/*</div>*/}
              <div
                className='floating-button'
                onClick={() => setUpdateProject(data)}
              >
                <FloatingButtonWrapper>
                  <EditIcon width={20} height={20} fill='#606060' />
                </FloatingButtonWrapper>
              </div>
            </>
          }
        </RecordWrapper>
      </RecordContainer>
      {/* -------------- Filter Drawer ---------------  */}
      {/*<IaCDetails data={data} isOpen={isOpen} toggle={handleSelectRow} compliant={compliant} non_compliant={non_compliant} />*/}
    </div>
  )
}

const APIKeyTable = forwardRef((props, ref) => {
  const {
    iac_projects,
    setUpdateProject,
    setMarkedProjects,
    fetchingFlag,
    setRenderFlag
  } = props

  const columns = [
    {
      title: '',
      access: '',
      width: `${2000 / 680}%`,
      sort: false,
      search: false,
      type: 'checkbox'
    },
    {
      title: 'Name',
      access: 'display_name',
      width: `${15000 / 680}%`,
      sort: true,
      search: true,
      type: 'icon'
    },
    {
      title: 'API Key',
      access: 'api_key',
      width: `${24000 / 680}%`,
      sort: true,
      search: true,
      type: 'hint'
    },
    {
      title: 'Created',
      access: 'created_at',
      width: `${10000 / 680}%`,
      sort: false,
      search: false,
      type: 'date'
    }
    // {
    //   title: "Action",
    //   width: `${5000 / 680}%`,
    //   sort: false,
    //   search: false,
    //   type: "button"
    // },
  ]

  const { setGIacProjects } = useContext(MainContext)
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSort, setCurrentSort] = useState({ access: '', order: true })
  const [filters, setFilters] = useState(columns.filter(item => item.search))
  const [searchText, setSearchText] = useState(null)

  const [selectedPlatform, setSelectedPlatform] = useState(
    selectEmptyResourceType
  )
  const [selectedCategory, setSelectedCategory] = useState(
    selectEmptyResourceType
  )
  const [selectedSeverity, setSelectedSeverity] = useState(
    selectEmptyResourceType
  )
  const [remediationStatus, setRemediationStatus] = useState(false)
  const [openItems, setOpenItems] = useState({})
  const [showAll, setShowAll] = useState(false)

  const itemPerPage = 15
  const handleSort = () => {
    const retOrder = currentSort.order ? 1 : -1
    setData(data => [
      ...data.sort((a, b) => {
        const aCompare = Array.isArray(currentSort.access)
          ? currentSort.access.map(access => a[access]).join(' ')
          : a[currentSort.access]
        const bCompare = Array.isArray(currentSort.access)
          ? currentSort.access.map(access => b[access]).join(' ')
          : b[currentSort.access]
        return aCompare > bCompare ? retOrder : -1 * retOrder
      })
    ])
  }

  useEffect(() => {
    setData([...iac_projects.map(item => ({ ...item, checked: false }))])
    setCurrentSort(currentSort =>
      currentSort.access.length === 0
        ? {
            access: columns[1].access,
            order: true
          }
        : { ...currentSort }
    )
    window.scrollTo(0, 0)
  }, [iac_projects])

  useEffect(() => {
    setMarkedProjects(data.filter(project => project.checked))
  }, [data])

  useEffect(() => {
    handleSort()
  }, [currentSort])

  useEffect(() => {
    let filteredData = [...data]
    if (searchText && searchText.length) {
      filteredData = [
        ...filteredData.filter(
          item =>
            !!filters.filter(
              col =>
                col.search &&
                item[col.access]
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
            ).length
        )
      ]
    }
    setGIacProjects(filteredData)
    setRenderFlag(renderFlag => !renderFlag)
  }, [
    data,
    searchText,
    filters,
    currentPage,
    showAll,
    selectedPlatform,
    selectedCategory
  ])

  const filteredData = useCallback(() => {
    let filteredData = [...data]
    if (searchText && searchText.length) {
      filteredData = [
        ...filteredData.filter(
          item =>
            !!filters.filter(
              col =>
                col.search &&
                item[col.access]
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
            ).length
        )
      ]
    }

    if (selectedPlatform && selectedPlatform.value.length) {
      filteredData = [
        ...filteredData.filter(
          item =>
            item.platform.filter(
              platform =>
                platform.toLowerCase() === selectedPlatform.value.toLowerCase()
            ).length > 0
        )
      ]
    }
    if (selectedCategory && selectedCategory.value.length) {
      filteredData = [
        ...filteredData.filter(
          item =>
            item.csp.toLowerCase() === selectedCategory.value.toLowerCase()
        )
      ]
    }
    if (
      currentPage !== 1 &&
      currentPage > filteredData.length / itemPerPage + 1
    ) {
      setCurrentPage(1)
    }
    // setGIacProjects(filteredData)
    return filteredData
  }, [
    data,
    searchText,
    filters,
    currentPage,
    showAll,
    selectedPlatform,
    selectedCategory
  ])

  const pageFilter = data => {
    return data.filter(
      (item, idx) =>
        idx >= itemPerPage * (currentPage - 1) &&
        idx < itemPerPage * currentPage
    )
  }

  const handlePageChange = e => {
    setCurrentPage(e.selected + 1)
  }

  useImperativeHandle(ref, () => ({
    getFilterPanel: () => (
      <FilterPanel
        columns={columns}
        filters={filters}
        setFilters={setFilters}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedSeverity={selectedSeverity}
        setSelectedSeverity={setSelectedSeverity}
        selectedPlatform={selectedPlatform}
        setSelectedPlatform={setSelectedPlatform}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        showAll={showAll}
        setShowAll={setShowAll}
      />
    )
  }))

  return (
    <TableWrapper marginTop='10px'>
      <TableHeader
        columns={columns}
        currentSort={currentSort}
        setCurrentSort={setCurrentSort}
        data={filteredData()}
        setData={setData}
      />
      <div className='divider' />
      {fetchingFlag && !iac_projects?.length ? (
        <div className='d-flex justify-content-center py-5 align-items-center'>
          {loadingBar('Rings', 50, 50)}
          <StyledTypo
            className='pl-2'
            size={0.8}
            line={1.2}
            letter='0'
            fontWeight={400}
          >
            Loading...
          </StyledTypo>
        </div>
      ) : filteredData().length ? (
        pageFilter(filteredData()).map(project => (
          <Record
            data={project}
            columns={columns}
            setData={setData}
            remediationStatus={remediationStatus}
            openItems={openItems}
            force={currentPage - 1}
            setOpenItems={setOpenItems}
            setUpdateProject={setUpdateProject}
          />
        ))
      ) : (
        <StyledTypo
          className='text-center py-5'
          size={0.8}
          line={1.2}
          letter='0'
          fontWeight={400}
        >
          No Keys
        </StyledTypo>
      )}

      <ShowingWrapper>
        <StyledTypo
          color={'#505050'}
          size={0.8}
          line={1.6}
          letter='0'
          family='Poppins'
          fontWeight={400}
        >
          {`Showing ${
            itemPerPage * (currentPage - 1) + 1 > filteredData().length
              ? filteredData().length
              : itemPerPage * (currentPage - 1) + 1
          } - ${
            itemPerPage * currentPage > filteredData().length
              ? filteredData().length
              : itemPerPage * currentPage
          } from ${filteredData().length} Results`}
        </StyledTypo>
        <ReactPaginate
          pageCount={(filteredData()?.length ?? 0) / itemPerPage}
          renderOnZeroPageCount={null}
          containerClassName='pagination'
          previousLabel='< Prev'
          nextLabel='Next >'
          pageClassName='page-item'
          pageLinkClassName='page-link'
          previousClassName='page-item'
          previousLinkClassName='page-link'
          nextClassName='page-item'
          nextLinkClassName='page-link'
          breakLabel='...'
          breakClassName='page-item'
          breakLinkClassName='page-link'
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          forcePage={currentPage - 1}
          onPageChange={handlePageChange}
          activeClassName='active'
        />
      </ShowingWrapper>
    </TableWrapper>
  )
})

function mapDispatchToProps (dispatch) {
  return {
    createIacProjectAction: payload =>
      dispatch(iacActions.createIacProjectAction(payload))
  }
}

export default connect(undefined, mapDispatchToProps, null, {
  forwardRef: true
})(APIKeyTable)
