import React from 'react'
import StyledTypo from '../../../../components/Typo/StyledTypo'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/cancel.svg'
import 'react-modern-drawer/dist/index.css'
import { Col, Row, Badge } from 'reactstrap'
import SlidePanel from '../../../../components/SlidePanel/SlidePanel'
import { ReactComponent as LeftArrow } from '../../../../assets/icons/left-arrow.svg'
import { ReactComponent as RightArrow } from '../../../../assets/icons/right-arrow.svg'

import moment from 'moment'

const DividerWrapper = styled.div`
  height: 0;
  width: calc(100% + 60px);
  margin-left: -30px;
  border-bottom: 0.5px solid #ccc;
`

const CloseWrapper = styled.div`
  border-radius: 10px;
  min-width: 45px;
  min-height: 40px;
  cursor: pointer;
  max-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
`

const MoveCTAWrapper = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  // align-items: center;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  cursor: pointer;
`

const TitleWrapper = styled.span`
  display: inline-block;
  width: 58%;
  height: 1.4em;
`

const SeverityWrapper = styled.span`
  position: absolute;
  left: calc(100% - 200px);
  text-transform: capitalize;
`

const WorkloadDetailSlide = props => {
  const {
    data,
    isOpen,
    toggle,
    setOpenItemId,
    findIndex,
    findId,
    projectsCnt
  } = props

  console.log(data, '===== data')

  return (
    <SlidePanel isOpen={isOpen} toggle={toggle} slideId='cloud-detail-slide'>
      <div className='p-3 position-relative mb-3'>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <CloseWrapper className='cursor-pointer' onClick={toggle}>
            <CloseIcon width={22} fill='#505050' />
          </CloseWrapper>
          <SeverityWrapper>
            <Badge pill color={data?.Severity?.toLowerCase()}>
              {data?.Severity?.toLowerCase()}
            </Badge>
          </SeverityWrapper>
          <TitleWrapper>
            <StyledTypo className='text-center heading ln-2'>
              {data?.VulnerabilityID}
            </StyledTypo>
          </TitleWrapper>
          <div className='d-flex align-items-center'>
            <StyledTypo className='mr-2' size='0.8'>
              {findIndex() + 1} of {projectsCnt}
            </StyledTypo>
            <MoveCTAWrapper
              disabled={findIndex() === 0}
              onClick={() => setOpenItemId(findId(false))}
            >
              <LeftArrow fill={findIndex() === 0 ? '#A4AFB7' : '#505050'} />
            </MoveCTAWrapper>
            <MoveCTAWrapper
              disabled={findIndex() + 1 === projectsCnt}
              onClick={() => setOpenItemId(findId(true))}
            >
              <RightArrow
                fill={findIndex() + 1 === projectsCnt ? '#A4AFB7' : '#505050'}
              />
            </MoveCTAWrapper>
          </div>
        </div>
        <DividerWrapper className='mb-3' />
        <Row>
          <Col md={12} className='position-static pt-3'>
            <StyledTypo className='mt-2 mr-3 label'>Name</StyledTypo>
            <StyledTypo className='mt-3'>{data?.Title}</StyledTypo>
          </Col>
          <Col md={12} className='position-static mt-3'>
            <StyledTypo className='mt-2 mr-3 label'>Description</StyledTypo>
            <StyledTypo className='mt-3'>{data?.Description}</StyledTypo>
          </Col>
          <Col md={6} className='position-static mt-3'>
            <StyledTypo className='mt-2 mr-3 label'>Package ID</StyledTypo>
            <StyledTypo className='mt-3'>{data?.PkgID}</StyledTypo>
          </Col>
          <Col md={6} className='position-static mt-3'>
            <StyledTypo className='mt-2 mr-3 label'>Package Name</StyledTypo>
            <StyledTypo className='mt-3'>{data?.PkgName}</StyledTypo>
          </Col>
          <Col md={6} className='position-static mt-3'>
            <StyledTypo className='mt-2 mr-3 label'>
              Installed Version
            </StyledTypo>
            <StyledTypo className='mt-3'>{data?.InstalledVersion}</StyledTypo>
          </Col>
          {data?.FixedVersion && (
            <Col md={6} className='position-static mt-3'>
              <StyledTypo className='mt-2 mr-3 label'>Fixed Version</StyledTypo>
              <StyledTypo className='mt-3'>{data?.FixedVersion}</StyledTypo>
            </Col>
          )}
          <Col md={6} className='position-static mt-3'>
            <StyledTypo className='mt-2 mr-3 label'>Primay URL</StyledTypo>
            <a href={data?.PrimaryURL} target='_blank' rel='noreferrer'>
              <StyledTypo className='mt-3'>{data?.PrimaryURL}</StyledTypo>
            </a>
          </Col>
          <Col md={6} className='position-static mt-3'>
            {data?.SeveritySource && (
              <StyledTypo className='mt-2 mr-3 label'>
                Severity Source
              </StyledTypo>
            )}
            <StyledTypo className='mt-3'>{data?.SeveritySource}</StyledTypo>
          </Col>
          <Col md={6} className='position-static mt-3'>
            <StyledTypo className='mt-2 mr-3 label'>Published Date</StyledTypo>
            <StyledTypo className='mt-3'>
              {data?.PublishedDate
                ? moment(data?.PublishedDate).format('YYYY-MM-DD HH:mm:ss')
                : '---'}
            </StyledTypo>
          </Col>
          <Col md={6} className='position-static mt-3'>
            <StyledTypo className='mt-2 mr-3 label'>
              Modified Timestamp
            </StyledTypo>
            <StyledTypo className='mt-3'>
              {data?.LastModifiedDate
                ? moment(data?.LastModifiedDate).format('YYYY-MM-DD HH:mm:ss')
                : '---'}
            </StyledTypo>
          </Col>
          <Col md={12} className='position-static mt-3'>
            <StyledTypo className='mt-2 pb-2 mr-3 label'>References</StyledTypo>
            <Row>
              {(data?.References ?? []).map((ref, idx) => (
                <Col md={6} className='pr-3'>
                  <a href={ref} key={idx} target='_blank' rel='noreferrer'>
                    <StyledTypo className='mt-3'>{ref}</StyledTypo>
                  </a>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    </SlidePanel>
  )
}

export default WorkloadDetailSlide
