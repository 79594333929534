import React, { useState } from "react";
import StyledTypo from "../../../components/Typo/StyledTypo";
import styled from "styled-components";
import StyledCheckbox from "../../../components/Input/StyledCheckbox";
import { fakePoliciesData, policyStatusInfo } from "../../../helpers/constants";
import { ReactComponent as ResourcesIcon } from "../../../assets/icons/resources_icon.svg";
import { ReactComponent as ThreeDotIcon } from "../../../assets/icons/three_dot_icon.svg";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter_icon.svg";

import StyledDropdown from "../../../components/Buttons/StyledDropdown";
import DropdownIcon from "../../../components/Buttons/DropdownIcon";
import { DropdownMenu } from "reactstrap";

import moment from "moment";
import EffectedButton from "../../../components/Buttons/EffectedButton";
import { ReactComponent as CirclePlusIcon } from "../../../assets/icons/circle_add_icon.svg";
import { ReactComponent as ChangeStatusIcon } from "../../../assets/icons/change_status_icon.svg";
import { ReactComponent as RetryIcon } from "../../../assets/icons/retry_icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/user_edit_icon.svg";
import CustomInput from "../../../components/Input/CustomInput";
import ReactPaginate from "react-paginate";
import AddPolicyPanel from "../../modals/AddPolicyPanel";

const TableWrapper = styled.div`
  padding-top: 20px;
  .divider {
    margin: 8px -46.5px 4px -37.5px;
    border-top: 1px solid rgba(164, 175, 183, 0.3);
    height: 0;
  }
  li.pagination-active {
    border-radius: 50%;
    background: #485dcd;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
  .pagination-item {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    color: #7d7d7d;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
  }
  .next {
    height: 26px;
    padding: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7d7d7d;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
  }
  .previous {
    height: 26px;
    padding: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7d7d7d;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 26px;
`;

const EachCell = styled.div`
  width: ${(props) => props.width};
`;

const RecordWrapper = styled.div`
  padding: 11px 26px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
const ActionDropdownMenu = styled(DropdownMenu)`
  min-width: 164px;
  background: #ffffff;
  /* Gray light 2 */

  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  /* box shadow */

  box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 20px;
  padding: 33px 35px;
  transform: translate3d(220px, 25px, 0px) !important;
`;

const ShowingWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
`;

const StatusWrapper = styled.div`
  background-color: ${(props) => props.color};
  padding: 5px 20px;
  border-radius: 215px;
  display: flex;
`;

const FilterWrapper = styled.div`
  padding: 20px 26px;
  display: flex;
  justify-content: space-between;
`;

const FilterPanel = () => {
  const [addPolicyModal, setAddPolicyModal] = useState(false);

  const togglePolicyModal = () => {
    setAddPolicyModal((addPolicyModal) => !addPolicyModal);
  };
  return (
    <FilterWrapper>
      <div className="d-flex">
        <div className="d-flex align-items-center">
          <FilterIcon width={25} height={25} fill="#ff487f" />
          <StyledTypo
            className="ml-3"
            color={"#505050"}
            size={1.125}
            line={24 / 18}
            letter="0"
            family="Poppins"
            fontWeight={700}
          >
            Filters
          </StyledTypo>
        </div>
        <div className="d-flex align-items-center ml-3">
          <div className="pt-2 ml-4">
            <StyledCheckbox name="Project" checked />
          </div>
          <div className="ml-3 pl-1 pt-2">
            <StyledCheckbox name="Environment" />
          </div>
        </div>
        <div className="ml-4">
          <CustomInput placeholder="Search" />
        </div>
      </div>
      <div>
        <StyledDropdown isOpen={addPolicyModal} toggle={togglePolicyModal}>
          <DropdownIcon>
            <EffectedButton padding={0} height={40} noSpan>
              <CirclePlusIcon />
              <StyledTypo
                className="ml-2"
                color="#fff"
                size={1.125}
                line={25.2 / 18}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                Add new Policy
              </StyledTypo>
            </EffectedButton>
          </DropdownIcon>
          <AddPolicyPanel toggle={togglePolicyModal} />
        </StyledDropdown>
      </div>
    </FilterWrapper>
  );
};

const TableHeader = () => {
  return (
    <HeaderWrapper>
      <EachCell width={`${40100 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={700}
        >
          Name
        </StyledTypo>
      </EachCell>
      <EachCell width={`${27500 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          Created
        </StyledTypo>
      </EachCell>
      <EachCell width={`${17900 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          Creator
        </StyledTypo>
      </EachCell>
      <EachCell width={`${17800 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          Status
        </StyledTypo>
      </EachCell>
      <EachCell width={`${12600 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          View Resources
        </StyledTypo>
      </EachCell>
      <EachCell width={`${43700 / 1596}%`}>
        <StyledTypo
          className="text-right"
          color={"#505050"}
          size={1}
          line={24 / 16}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          Actions
        </StyledTypo>
      </EachCell>
    </HeaderWrapper>
  );
};

const Record = (props) => {
  const { data, isOpenActionMenu, toggleActionMenu } = props;
  return (
    <RecordWrapper>
      <EachCell width={`${40100 / 1596}%`}>
        <div className="d-flex">
          <StyledTypo
            color={"#505050"}
            size={1}
            line={26.15 / 16}
            letter="0"
            family="Poppins"
            fontWeight={700}
          >
            {data.name}
          </StyledTypo>
        </div>
      </EachCell>
      <EachCell width={`${27500 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={26.15 / 16}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {moment(data.created).format("MM-DD-YYYY HH:mm")}
        </StyledTypo>
      </EachCell>
      <EachCell width={`${17900 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={26.15 / 16}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {data.creator}
        </StyledTypo>
      </EachCell>
      <EachCell width={`${17800 / 1596}%`} className="d-flex">
        <StatusWrapper color={policyStatusInfo[data.status].color}>
          <StyledTypo
            color={"#fff"}
            size={1}
            line={26.15 / 16}
            letter="0"
            family="Poppins"
            fontWeight={400}
          >
            {policyStatusInfo[data.status].name}
          </StyledTypo>
        </StatusWrapper>
      </EachCell>
      <EachCell className="text-center" width={`${12600 / 1596}%`}>
        <ResourcesIcon />
      </EachCell>
      <EachCell width={`${43700 / 1596}%`} className="text-right">
        <StyledDropdown
          isOpen={isOpenActionMenu.filter((item) => item[data.id]).length}
          toggle={() => toggleActionMenu(data.id)}
        >
          <DropdownIcon>
            <ThreeDotIcon />
          </DropdownIcon>
          <ActionDropdownMenu>
            <div className="d-flex mb-2 align-items-center cursor-pointer">
              <ChangeStatusIcon fill="#485DCD" width={17} height={20} />
              <StyledTypo
                className="ml-3"
                color={"#505050"}
                size={1.125}
                line={26.15 / 18}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                Change Status
              </StyledTypo>
            </div>
            <div className="d-flex mb-2 align-items-center cursor-pointer">
              <EditIcon fill="#485DCD" width={17} height={20} />
              <StyledTypo
                className="ml-3"
                color={"#505050"}
                size={1.125}
                line={26.15 / 18}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                Change Name
              </StyledTypo>
            </div>
            <div className="d-flex align-items-center cursor-pointer">
              <RetryIcon fill="#485DCD" width={17} height={20} />
              <StyledTypo
                className="ml-3"
                color={"#505050"}
                size={1.125}
                line={26.15 / 18}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                Refresh
              </StyledTypo>
            </div>
          </ActionDropdownMenu>
        </StyledDropdown>
      </EachCell>
    </RecordWrapper>
  );
};

const DisplayTablePolicies = (props) => {
  const [isOpenActionMenu, setIsOpenActionMenu] = useState(
    fakePoliciesData.map((item) => ({ [item.id]: false }))
  );
  const [policiesData, setPoliciesData] = useState(fakePoliciesData);

  const toggleActionMenu = (key) => {
    setIsOpenActionMenu(
      isOpenActionMenu.map((item) => ({ ...item, [key]: !item[key] }))
    );
  };

  const handleChange = (id) => {
    setPoliciesData((deployData) =>
      deployData.map((item) => ({
        ...item,
        selected: item.id === id ? !item.selected : item.selected,
      }))
    );
  };

  const handlePageClick = (data) => {
    let selected = data.selected;
    console.log("selected page number: ", selected);
  };

  return (
    <TableWrapper>
      <FilterPanel />
      <TableHeader />
      <div className="divider" />
      {policiesData.map((data) => (
        <Record
          data={data}
          isOpenActionMenu={isOpenActionMenu}
          toggleActionMenu={toggleActionMenu}
          handleChange={handleChange}
        />
      ))}
      <ShowingWrapper>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={26.15 / 16}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {`Showing 10 from 100 Results`}
        </StyledTypo>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={10}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"pagination-active"}
          initialPage={0}
          pageClassName={"pagination-item"}
          nextClassName={"next"}
          previousClassName={"previous"}
        />
      </ShowingWrapper>
    </TableWrapper>
  );
};

export default DisplayTablePolicies;
