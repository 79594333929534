import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
  currentRole,
  getCurrentUser,
  userRoleInfo,
  userStatusInfo,
} from "../../../helpers/constants";
import { ReactComponent as ThreeDotIcon } from "../../../assets/icons/three_dot_icon.svg";
import { ReactComponent as DeployButtonIcon } from "../../../assets/icons/deploy_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/trash_icon.svg";
import { ReactComponent as ArrowUp } from '../../../assets/icons/up_arrow_icon.svg'
import { ReactComponent as ArrowDown } from '../../../assets/icons/down_arrow_icon.svg'
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter_icon.svg'
import { ReactComponent as FilterClearIcon } from '../../../assets/icons/filter_clear_icon.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg'
import { ReactComponent as AddItemIcon } from '../../../assets/icons/add_item.svg'

import CustomInput from '../../../components/Input/CustomInput'
import CommonSelect from '../../../components/Select/CommonSelect'
import StyledTypo from "../../../components/Typo/StyledTypo";
import StyledDropdown from "../../../components/Buttons/StyledDropdown";
import DropdownIcon from "../../../components/Buttons/DropdownIcon";
import { DropdownMenu } from "reactstrap";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";

import moment from 'moment'


const TableWrapper = styled.div`
  .divider {
    margin: 8px -15px;
    //margin: 8px -46.5px 4px -37.5px;
    border-top: 1px solid rgba(164, 175, 183, 0.3);
    height: 0;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 26px;
`;

const EachCell = styled.div`
  width: ${(props) => props.width};
  padding-right: ${(props) => (props.pr ? props.pr : 0)}px;
  word-break: break-word;
`;

const RecordContainer = styled.div`
  border-top: 1px solid #fff;
  transition: border 500ms ease;
  background: ${props => (props.active ? '#f1f1f1' : 'transparent')};
  position: relative;

  .floating-button {
    display: none;
    z-index: 100;
  }
  &:hover .floating-button {
    display: block;
  }
`

const RecordWrapper = styled.div`
  padding: 11px 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .text-cell {
    color: #505050;
  }
  cursor: pointer;
  &:hover {
    border-radius: 5px;
    background-color: #f1f1f1;
  }
`;

const ActionDropdownMenu = styled(DropdownMenu)`
  // --width: ${(props) => props.width - 350}px;
  // left: calc(var(--width) - 50px - 1vw) !important;
  /* white */
  min-width: 150px;
  width: 200px;
  background: #ffffff;
  /* Gray light 2 */

  border: 1px solid #f1f5fa;
  box-sizing: border-box;
  /* box shadow */

  box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 10px;
  padding: 15px 20px;
  transform: translate3d(-150px, -10px, 0px) !important;

  .item:hover p {
    color: #66c4d5 !important;
  }
`;

const ShowingWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  position: fixed;
  right: 40px;
  bottom: 20px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100vw - 200px);
  background-color: #fff;

  .page-link:focus {
    box-shadow: none !important;
  }
  ul.pagination {
    margin-bottom: 0;
    font-size: 0.8rem;
    font-weight: 0.4;
    font-family: "Roboto";
  }
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`

const CustomizeFilterWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 15px 0;
  z-index: 100;

  .filter-menu {
    gap: 10px;
  }
`

const CustomizeFilterBodyContainer = styled.div`
  background: #fff;
  box-shadow: 0 26px 58px 0 rgba(0, 0, 0, 0.22),
    0 5px 14px 0 rgba(0, 0, 0, 0.18);
  // padding: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  z-index: 100;
  bottom: 0px;
  transform: translateY(105%);
  left: 0px;
  width: 100%;
  border: 1px solid rgb(234 234 234);
  border-left: 2px solid #5eade6;
`

const columns = [
  {
    title: "Name",
    access: "first_name",
    width: `${38600 / 1596}%`,
    sort: true,
    search: true,
  },
  // {
  //   title: "Last Name",
  //   access: "last_name",
  //   width: `${18300 / 1596}%`,
  //   sort: true,
  //   search: true,
  //   type: "text",
  // },
  // {
  //   title: "Organization Name",
  //   access: "organization_name",
  //   width: `${27100 / 1596}%`,
  //   sort: true,
  //   search: true,
  //   type: "text",
  // },
  {
    title: "Email",
    access: "email",
    width: `${36200 / 1596}%`,
    sort: true,
    search: true,
    type: "text",
  },
  {
    title: "Role",
    access: "role",
    width: `${25800 / 1596}%`,
    sort: false,
    search: true,
    type: "role",
  },
  {
    title: "Status",
    access: "status",
    width: `${23600 / 1596}%`,
    sort: false,
    search: true,
    type: "status",
  },
  {
    title: "Last Login",
    access: "logged_in_at",
    width: `${23600 / 1596}%`,
    sort: false,
    search: false,
    type: "date",
  },
  {
    title: "Actions",
    access: 'actions',
    width: `${8400 / 1596}%`,
    sort: false,
    search: false,
    type: "action",
  },
]

const selectEmptyResourceType = {
  label: 'Select...',
  value: ''
}

const FilterPanel = props => {
  const {
    searchText,
    setSearchText,
    selectedOrg,
    setSelectedOrg,
    selectedRole,
    setSelectedRole,
    orgOptions,
    roleOptions,
    openPanel,
  } = props

  const [filterBarOpen, setFilterBarOpen] = useState(false)

  const clearFilter = () => {
    setSelectedOrg(selectEmptyResourceType)
    setSelectedRole(selectEmptyResourceType)
    setFilterBarOpen(false)
  }

  const checkFilterContent = () => {
    return !!selectedOrg?.value?.length || !!selectedRole?.value?.length
  }

  return (
    <CustomizeFilterWrapper>
      <div className='d-flex align-items-center justify-content-end'>
        <div className='d-flex align-items-center mr-3 filter-menu'>
          {currentRole() === "org-admin" && (
            <a
              className='menu-icon'
              onClick={() => openPanel(true)}
              title='Add User'
              alt='Add User'
            >
              <AddItemIcon
                width={22}
                height={22}
                fill='#606060'
                className='svg-icon'
              />
              <span className='menu-tip'>Add User</span>
            </a>
          )}
          <a
            className='menu-icon'
            onClick={() => setFilterBarOpen(!filterBarOpen)}
            title='Filter By'
            alt='Filter'
          >
            <FilterIcon
              width={22}
              height={22}
              fill='#606060'
              className='svg-icon'
            />
            <span className='menu-tip'>Filter by</span>
          </a>
          <a
            className={
              checkFilterContent() ? 'menu-icon' : 'menu-icon menu-disabled'
            }
            onClick={clearFilter}
            title='Filter Clear'
            alt='Filter Clear'
          >
            <FilterClearIcon
              width={22}
              height={22}
              fill='#606060'
              className='svg-icon'
            />
            <span className='menu-tip'>Filter clear</span>
          </a>
        </div>
        <div>
          <CustomInput
            minWidth={350}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
        </div>
      </div>
      {filterBarOpen && (
        <CustomizeFilterBodyContainer>
          <CloseWrapper
            className='cursor-pointer'
            onClick={() => setFilterBarOpen(false)}
          >
            <CloseIcon width={15} stroke='#A4AFB7' />
          </CloseWrapper>
          <div className='ml-3' style={{ minWidth: 250 }}>
            <CommonSelect
              value={selectedOrg}
              setValue={setSelectedOrg}
              options={orgOptions}
              label={`Organization`}
            />
          </div>
          <div className='ml-3' style={{ minWidth: 250 }}>
            <CommonSelect
              value={selectedRole}
              setValue={setSelectedRole}
              options={roleOptions}
              label={`Role`}
            />
          </div>
        </CustomizeFilterBodyContainer>
      )}
    </CustomizeFilterWrapper>
  )
}


const TableHeader = ({ currentSort, setCurrentSort }) => {
  const handleSetSort = (access) => {
    setCurrentSort({
      access,
      order:
        (Array.isArray(currentSort.access)
          ? currentSort.access.join()
          : currentSort.access) ===
        (Array.isArray(access) ? access.join() : access)
          ? !currentSort.order
          : true,
    });
  };

  return (
    <HeaderWrapper>
      {columns.map((column, idx) => (
        <EachCell 
          width={column.width} key={`header-${idx}`}
          className={` ${
              column.title === "Results" ? "flex-column align-items-start" : ""
            }d-flex align-items-center ${column.sort ? "cursor-pointer" : ""} ${
              column.title === "Action" ? "justify-content-end" : ""
            } `}
          pr={10}
          onClick={() => {
            if (column.sort) {
              handleSetSort(column.access);
            }
          }}
        >
          {column.sort &&
            (Array.isArray(currentSort?.access)
              ? currentSort?.access.join()
              : currentSort?.access) ===
              (Array.isArray(column.access)
                ? column.access.join()
                : column.access) ? (
              currentSort.order ? (
                <ArrowUp fill={"#A4AFB7"} className="mr-3" />
              ) : (
                <ArrowDown fill={"#A4AFB7"} className="mr-3" />
              )
            ) : (
              <div />
            )}
          <StyledTypo 
            color={"#505050"}
            size={0.8}
            line={1.2}
            letter="0"
            family="Roboto"
            fontWeight={400}>
              {column.title}
          </StyledTypo>
        </EachCell>
      ))}
    </HeaderWrapper>
  );
};

const Record = (props) => {
  const {
    data,
    isOpenActionMenu,
    toggleActionMenu,
    manageUserStatusAction,
    deleteUserAction,
  } = props;

  const deleteConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>{`You want to ${data.is_deleted ? "restore" : "delete"} user "${
              data.first_name
            } ${data.last_name}"?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                deleteUserAction(
                  data.id,
                  data.is_deleted ? "restore" : "delete"
                );
                onClose();
              }}
            >
              Confirm
            </button>
          </div>
        );
      },
    });
  };
  return (
    <RecordContainer>
      <RecordWrapper>
        {columns.map((column, idx) => (
          <EachCell width={column.width}>
            {column.type === 'text' && (
              <StyledTypo
                className="text-cell"
                // onClick={handleSelectRow}
                size={0.9}
                line={1.4}
                letter="0"
                >
                {data?.[column.access]}
              </StyledTypo>
            )}
            {column.title === 'Name' && (
              <StyledTypo
                className="text-cell"
                // onClick={handleSelectRow}
                size={0.9}
                line={1.4}
                letter="0"
                >
                {data?.['first_name']} {data?.last_name}
              </StyledTypo>
            )}
            {column.type === 'role' && (
              <StyledTypo
                className="text-cell"
                // onClick={handleSelectRow}
                size={0.9}
                line={1.4}
                letter="0"
              >
                {userRoleInfo[data.role].name}
              </StyledTypo>
            )}
            {column.type === 'status' && (
              <StyledTypo
                className="text-cell"
                // onClick={handleSelectRow}
                size={0.9}
                line={1.4}
                letter="0"
              >
                {userStatusInfo[data.is_deleted ? "deleted" : data.status].name}
              </StyledTypo>
            )}
            {column.type === 'date' && (
              <StyledTypo
                className="text-cell"
                // onClick={handleSelectRow}
                size={0.9}
                line={1.4}
                letter="0"
              >
                {moment(data?.[column.access]).format('YYYY-MM-DD hh:mm')}
              </StyledTypo>
            )}
            {column.type === 'action' && data?.id !== getCurrentUser()?.UUID && (
              <div className="text-right">
                <StyledDropdown
                  isOpen={isOpenActionMenu.filter((item) => item[data.id]).length}
                  toggle={() => toggleActionMenu(data.id)}
                >
                  <DropdownIcon>
                    <ThreeDotIcon />
                  </DropdownIcon>
                  <ActionDropdownMenu>
                    {!data.is_deleted && (
                      <div
                        className="d-flex my-2 align-items-center cursor-pointer item"
                        onClick={() => {
                          manageUserStatusAction(
                            data.id,
                            data.status === "active" ? "deactivate" : "activate"
                          );
                          toggleActionMenu(data.id);
                        }}
                      >
                        <DeployButtonIcon width={18} />
                        <StyledTypo className="ml-3">
                          {data.status === 'active' ? "Disable" : "Enable"}
                        </StyledTypo>
                      </div>
                    )}
                    <div
                      className="d-flex my-2 align-items-center cursor-pointer item"
                      onClick={() => {
                        deleteConfirm();
                        toggleActionMenu(data.id);
                      }}
                    >
                      <DeleteIcon width={18} fill={"#ff487f"} />
                      <StyledTypo className="ml-3" color={"#ff487f"}>
                        {data.is_deleted ? "Restore" : "Delete"}
                      </StyledTypo>
                    </div>
                  </ActionDropdownMenu>
                </StyledDropdown>
              </div>
            )}
          </EachCell>
        ))}
      </RecordWrapper>
    </RecordContainer>
  );
};

const UserTable = (props) => {
  const { data: users, organizations, manageUserStatusAction, deleteUserAction, userList, setIsOpenAddPanel } =
    props;
  
  const [isOpenActionMenu, setIsOpenActionMenu] = useState([]);
  const [data, setData] = useState([]);
  const [currentSort, setCurrentSort] = useState({ access: '', order: true })
  const [currentPage, setCurrentPage] = useState(1);

  const [searchText, setSearchText] = useState(null)
  const [selectedOrg, setSelectedOrg] = useState(selectEmptyResourceType)
  const [selectedRole, setSelectedRole] = useState(selectEmptyResourceType)
  const [orgOptions, setOrgOptions] = useState([])
  const [roleOptions, setRoleOptions] = useState([])

  const itemPerPage = 15
  const handleSort = () => {
    const retOrder = currentSort.order ? 1 : -1
    setData(data => [
      ...data.sort((a, b) => {
        const aCompare = Array.isArray(currentSort.access)
          ? currentSort.access.map(access => a[access]).join(' ')
          : a[currentSort.access]
        const bCompare = Array.isArray(currentSort.access)
          ? currentSort.access.map(access => b[access]).join(' ')
          : b[currentSort.access]
        return aCompare > bCompare ? retOrder : -1 * retOrder
      })
    ])
  }

  useEffect(() => {
    setOrgOptions([
      selectEmptyResourceType, 
      ...(organizations ?? [])?.map(org => ({
      label: org.name,
      value: org.name
    }))])
  }, [organizations])

  useEffect(() => {
    setRoleOptions([
      selectEmptyResourceType, 
      ...[...new Set(userList.map(user => user.role))]?.map(role => ({
      label: role,
      value: role
    }))])
  }, [userList])

  useEffect(() => {
    if (organizations.length) {
      let approvedUsers = [
        ...users
          // .filter((user) => !user.is_deleted)
          // .filter((user) => user.UUID !== getCurrentUser().UUID)
          .map((user) => ({
            id: user?.id ?? user.UUID ,
            first_name: user.first_name,
            last_name: user.last_name,
            organization_name:
              organizations.filter(
                (org) => org?.id === user.organization_id
              )?.[0]?.name ?? "",
            email: user.email,
            status: user.status,
            role: user.role,
            logged_in_at: user.logged_in_at,
            is_deleted: user.is_deleted,
          })),
      ];

      setData(approvedUsers);
      setCurrentSort(currentSort =>
        currentSort.access.length === 0
          ? {
              access: columns[0].access,
              order: true
            }
          : { ...currentSort }
      )

      setIsOpenActionMenu([
        ...approvedUsers.map((item) => ({ [item.id]: false })),
      ]);
    }
  }, [users, organizations]);

  useEffect(() => {
    handleSort();
  }, [currentSort]);

  const toggleActionMenu = (key) => {
    setIsOpenActionMenu(
      isOpenActionMenu.map((item) => ({ ...item, [key]: !item[key] }))
    );
  };

  const handlePageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const filteredData = useCallback(() => {
    let filteredData = [...data];
    if (searchText && searchText.length) {
      const filters = columns.filter((item) => item.search);
      filteredData = [
        ...filteredData.filter(
          (item) =>
            !!filters.filter(
              (col) =>
                col.search &&
                item[col.access]
                  .toLowerCase()
                  .includes(searchText.trim().toLowerCase())
            ).length
        ),
      ];
    }

    if (selectedOrg && selectedOrg.value.length) {
      filteredData = [
        ...filteredData.filter(
          (item) =>
            item.organization_name.toLowerCase() === selectedOrg.value.toLowerCase()
        ),
      ];
    }
    if (selectedRole && selectedRole.value.length) {
      filteredData = [
        ...filteredData.filter(
          (item) =>
            item.role.toLowerCase() === selectedRole.value.toLowerCase()
        ),
      ];
    }
    if (
      currentPage !== 1 &&
      currentPage > filteredData.length / itemPerPage + 1
    ) {
      setCurrentPage(1);
    }
    return filteredData;
  }, [
    data,
    currentPage,
    selectedOrg,
    selectedRole,
    searchText
  ]);

  const pageFilter = useCallback(
    (data) => {
      return data.filter(
        (item, idx) =>
          idx >= itemPerPage * (currentPage - 1) &&
          idx < itemPerPage * currentPage
      );
    },
    [currentPage]
  );

  const handleChange = (id) => {
    setData((deployData) =>
      deployData.map((item) => ({
        ...item,
        selected: item.id === id ? !item.selected : item.selected,
      }))
    );
  };

  return (
    <TableWrapper>
      <FilterPanel
        openPanel={setIsOpenAddPanel}
        setSearchText={setSearchText}
        searchText={searchText}
        selectedOrg={selectedOrg}
        setSelectedOrg={setSelectedOrg}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        orgOptions={orgOptions}
        roleOptions={roleOptions}
      />
      <TableHeader 
        currentSort={currentSort}
        setCurrentSort={setCurrentSort}
      />
      <div className="divider" />
      {filteredData().length ? (
        pageFilter(filteredData()).map((userData) => (
          <Record
            data={userData}
            isOpenActionMenu={isOpenActionMenu}
            toggleActionMenu={toggleActionMenu}
            handleChange={handleChange}
            manageUserStatusAction={manageUserStatusAction}
            deleteUserAction={deleteUserAction}
          />
        ))
      ) : (
        <div className="text-center my-5">
          <StyledTypo>No users</StyledTypo>
        </div>
      )}
      <ShowingWrapper>
        <StyledTypo
          size={0.8}
          line={1.6}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {`Showing ${
            itemPerPage * (currentPage - 1) + 1 > filteredData().length
              ? filteredData().length
              : itemPerPage * (currentPage - 1) + 1
          } - ${
            itemPerPage * currentPage > filteredData().length
              ? filteredData().length
              : itemPerPage * currentPage
          } from ${filteredData().length} Results`}
        </StyledTypo>
        <ReactPaginate
          pageCount={(filteredData()?.length ?? 0) / itemPerPage}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          previousLabel="< Prev"
          nextLabel="Next >"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          forcePage={currentPage - 1}
          onPageChange={handlePageChange}
          activeClassName="active"
        />
      </ShowingWrapper>
    </TableWrapper>
  );
};

export default UserTable;
