import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import StyledTypo from '../../../../components/Typo/StyledTypo'
import {
  cspData,
  priorityColumns,
  dummyInfraPreviewData,
  providerComplianceType
} from '../../../../helpers/constants'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { connect } from 'react-redux'
import CommonTable from '../CommonTable'


const PanelBody = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 3fr;
    grid-gap: 40px;
`;

const ComplianceListWrapper = styled.div`
    padding: 15px;
    border-radius: 10px;
    box-shadow: 1px 2px 6px #ccc;
    border: 1px solid #f1f5fa;
    height: fit-content;

    .compliance-item {
        padding: 5px;
        cursor: pointer;
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .compliance-item.active {
        background: rgb(241, 241, 241);
        pointer-events: none;
    }

`;

const ComplianceProviderTab = props => {
  const { csp } = props;

  const [selectedCompliance, setSelectedCompliance] = useState([])
  const [mockData, setMockData] = useState([])

  useEffect(() => {
    setSelectedCompliance(Object.values(providerComplianceType?.[csp])?.[0])
  }, [csp])

  useEffect(() => {
    setMockData([...Array(Math.ceil(Math.random() * 10)).keys()].map(key => ({
        id: key, name: `${selectedCompliance.label} - ${key}`, summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: Math.ceil(Math.random() * 100) 
    })))
  }, [selectedCompliance])

  return (
    <PanelBody>
        <ComplianceListWrapper>
            {(Object.values(providerComplianceType?.[csp]) ?? []).map(compliance => (
                <div 
                    className={`compliance-item ${selectedCompliance.value === compliance?.value ? 'active' : ''}`} key={compliance.value}
                    onClick={() => setSelectedCompliance(compliance)}
                >
                    <img 
                        src={compliance.logo} alt={compliance.value} 
                        width={40}
                        height={40}
                    />
                    <StyledTypo
                        size={0.9}
                        line={1.4}
                    >
                        {compliance.label}
                    </StyledTypo>
                </div>
            ))}
        </ComplianceListWrapper>
        <CommonTable
            data={mockData}
            columns={priorityColumns}
            options={{
                noPagination: false
            }}
        />
    </PanelBody>
  )
}

// function mapStateToProps(state) {
//   const { loading_summary, compliance_infralist, resource_infralist } =
//     state.dashboard;
//   const { compliance_trend } = state.applications

//   return {
//     loading_summary,
//     compliance_infralist,
//     resource_infralist,
//     compliance_trend,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     fetchComplianceInfrastructureListAction: (provider, compliance) =>
//       dispatch(
//         dashboardActions.fetchComplianceInfrastructureListAction(
//           provider,
//           compliance
//         )
//       ),
//     fetchResourceInfrastructureListAction: (provider, resource) =>
//       dispatch(
//         dashboardActions.fetchResourceInfrastructureListAction(
//           provider,
//           resource
//         )
//       ),
//     fetchTrendDataAction: (data) => dispatch(applicationActions.fetchTrendDataAction(data)),
//   };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(TopPriorityPanel);
export default connect(undefined, undefined)(ComplianceProviderTab)
