/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState,
  useEffect,
  useCallback,
} from "react";
import StyledTypo from "../../../components/Typo/StyledTypo";
import styled from "styled-components";
import { ReactComponent as ArrowUp } from "../../../assets/icons/up_arrow_icon.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/down_arrow_icon.svg";
import StyledCheckbox from "../../../components/Input/StyledCheckbox";
import moment from "moment";
import {
  loadingBar,
  NonComplianceStatusColor,
  cspData,
  auditStatus,
} from "../../../helpers/constants";
import { connect } from "react-redux";
import { TableWrapper } from "../../../components/PageLayout/Table";
import GradientProgressBar from '../../../components/GradientProgressBar/GradientProgressBar'


const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 26px;
`;

const EachCell = styled.div`
  width: ${(props) => props.width};
  padding-right: ${(props) => (props.pr ? props.pr : 0)}px;
  word-break: break-word;
`;

const RecordContainer = styled.div`
  border-top: 1px solid #fff;
  transition: border 500ms ease;
  background: ${(props) => (props.active ? "#f1f1f1" : "transparent")};
  position: relative;

  .floating-button {
    display: none;
  }
  &:hover .floating-button {
    display: block;
  }
`;

const RecordWrapper = styled.div`
  padding: 11px 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .text-cell {
    color: #505050;
  }
  cursor: pointer;
  &:hover {
    border-radius: 5px;
    background-color: #f1f1f1;
  }
`;

const StyledStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 65px;

  margin-right: ${(props) => (props.mr ? props.mr : 20)}px;

  .mark {
    width: ${(props) => (props.size ? props.size : 12)}px;
    height: ${(props) => (props.size ? props.size : 12)}px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    // box-shadow: 0 8px 6px -3px ${(props) => props.color};
  }
`;

const TableHeader = (props) => {
  const { columns, currentSort, setCurrentSort, data, setData } = props;

  const handleSetSort = (access) => {
    setCurrentSort({
      access,
      order:
        (Array.isArray(currentSort.access)
          ? currentSort.access.join()
          : currentSort.access) ===
        (Array.isArray(access) ? access.join() : access)
          ? !currentSort.order
          : true,
    });
  };

  return (
    <HeaderWrapper>
      {columns &&
        columns.map((column) => (
          <EachCell
            width={column.width ?? "100%"}
            key={column.access}
            className={` ${
              column.title === "Results" ? "flex-column align-items-start" : ""
            }d-flex align-items-center ${column.sort ? "cursor-pointer" : ""} ${
              column.title === "Action" ? "justify-content-end" : ""
            } `}
            pr={10}
            onClick={() => {
              if (column.sort) {
                handleSetSort(column.access);
              }
            }}
          >
            {column.type === "checkbox" && (
              <StyledCheckbox
                handleOnChange={() => {
                  const allChecked =
                    data.filter((item) => item.checked).length === data.length;
                  setData((dataList) =>
                    dataList.map((item) => ({ ...item, checked: !allChecked }))
                  );
                }}
                className="mt-2"
                checked={
                  data.filter((item) => item.checked).length === data.length
                }
              />
            )}
            {column.sort &&
            (Array.isArray(currentSort.access)
              ? currentSort.access.join()
              : currentSort.access) ===
              (Array.isArray(column.access)
                ? column.access.join()
                : column.access) ? (
              currentSort.order ? (
                <ArrowUp fill={"#A4AFB7"} className="mr-3" />
              ) : (
                <ArrowDown fill={"#A4AFB7"} className="mr-3" />
              )
            ) : (
              <div />
            )}
            <div
              className={
                column.title === "Action"
                  ? "ml-auto"
                  : column.type === "overview"
                  ? "ml-auto mr-auto"
                  : ""
              }
            >
              <StyledTypo
                color={"#505050"}
                size={0.8}
                line={1.2}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                {column.title}
              </StyledTypo>
            </div>
            <div>
              {column.title === "Results" && (
                <div className="d-flex align-items-center flex-wrap mt-2">
                  {Object.keys(NonComplianceStatusColor).map((key) => (
                    <StyledStatusWrapper
                      color={NonComplianceStatusColor[key].color}
                    >
                      <StyledTypo
                        color="#687089"
                        size={0.6}
                        line={1}
                        letter="0"
                        family="Roboto"
                        fontWeight={300}
                      >
                        {key.toUpperCase()}
                      </StyledTypo>
                    </StyledStatusWrapper>
                  ))}
                </div>
              )}
            </div>
          </EachCell>
        ))}
    </HeaderWrapper>
  );
};

const Record = (props) => {
  const { data, columns } = props;

  return (
    <div>
      <RecordContainer active={data.checked}>
        <RecordWrapper active={data.checked}>
          {
            <>
              {columns.map((column) => (
                <EachCell width={column.width ?? "100%"} pr={10}>
                  {column.type === "checkbox" && (
                    <StyledCheckbox
                      checked={data.checked}
                      name=""
                      className="mr-5 mt-2 w-100"
                      color="#505050"
                      fontFamily="Roboto"
                      letter="0"
                    />
                  )}
                  {column.type === "date" && (
                    <StyledTypo
                      className="d-flex"
                      size={0.9}
                      line={1.4}
                      letter="0"
                    >
                      {data[column.access]
                        ? moment(data[column.access]).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        : "---"}
                    </StyledTypo>
                  )}
                  {column.type === "icon" && (
                    <div className="d-flex align-items-center">
                      {cspData
                        .filter((csp) => csp.key === data.platform)?.[0]
                        ?.logo(20)}
                      <StyledTypo
                        className="ml-2 pl-1"
                        size={0.9}
                        line={1.4}
                        letter="0"
                      >
                        {data[column.access]}
                      </StyledTypo>
                    </div>
                  )}

                  {column.type === 'progress' && (
                    <div className="d-flex">
                      <div className="w-75">
                        <GradientProgressBar
                          color="#ececec70" color2="#adadad"
                          value={
                            data[column.access]
                          }
                          maxValue="100"
                          rotation="90"
                        />
                      </div>
                      <StyledTypo
                        className="ml-3"
                        size={0.9}
                        line={1.4}
                        letter="0"
                      >
                        {Math.round(data[column.access] * 10) / 10} %
                      </StyledTypo>
                    </div>
                  )}
                  {column.access === 'scan_result' && (
                    <StyledTypo
                      size={0.9}
                      line={1.4}
                      letter="0"
                    >
                      {auditStatus[data[column.access]?.status].label ?? ''}
                    </StyledTypo>
                  )}
                  {column.type === "object" && (
                    <div className="d-flex align-items-center flex-wrap">
                      {Object.keys(NonComplianceStatusColor).map((key) => (
                        <StyledStatusWrapper
                          color={NonComplianceStatusColor[key].color}
                        >
                          <div className="mark" />
                          <StyledTypo
                            size={0.9}
                            line={1.4}
                            letter="0"
                          >
                            {data?.[column.access]?.[key]?.fail ?? 0}
                          </StyledTypo>
                        </StyledStatusWrapper>
                      ))}
                    </div>
                  )}
                  {!column.type && (
                    <StyledTypo
                      className="text-cell"
                      size={0.9}
                      line={1.4}
                      letter="0"
                    >
                      {Array.isArray(column.access)
                        ? column.access
                            .map((access) => data[access])
                            .join(" / ")
                        : data[column.access]}
                    </StyledTypo>
                  )}
                </EachCell>
              ))}
            </>
          }
        </RecordWrapper>
      </RecordContainer>
    </div>
  );
};

const InfraListByCompliance = (props) => {
  const {
    infras,
  } = props;

  const columns = [
    {
      title: "Name",
      access: "name",
      width: `${25000 / 680}%`,
      sort: true,
      search: true,
    },
    {
      title: "Results",
      access: "summary",
      width: `${25000 / 680}%`,
      sort: false,
      search: false,
      type: "object",
    },
    {
      title: "Compliance Score",
      access: "compliance_score",
      width: `${18000 / 680}%`,
      sort: false,
      search: false,
      type: 'progress',
    }
  ];

  const [data, setData] = useState([]);
  const [currentSort, setCurrentSort] = useState({ access: "", order: true });

  const [openItems, setOpenItems] = useState({});

  useEffect(() => {
    setData([...infras.map((item) => ({ ...item, checked: false }))]);
  }, [infras]);

  // useEffect(() => {
  //   setMarkedInfras(data.filter((project) => project.checked));
  // }, [data]);

  // useEffect(() => {
  //   handleSort();
  // }, [currentSort]);

  // useEffect(() => {
  //   let filteredData = [...data];
  //   // if (searchText && searchText.length) {
  //   //   filteredData = [
  //   //     ...filteredData.filter(
  //   //       (item) =>
  //   //         !!filters.filter(
  //   //           (col) =>
  //   //             col.search &&
  //   //             item[col.access]
  //   //               .toLowerCase()
  //   //               .includes(searchText.toLowerCase())
  //   //         ).length
  //   //     ),
  //   //   ];
  //   // }

  //   // if (selectedEnv && selectedEnv.value.length) {
  //   //   filteredData = [
  //   //     ...filteredData.filter(
  //   //       (item) =>
  //   //         item.environment.toLowerCase() === selectedEnv.value.toLowerCase()
  //   //     ),
  //   //   ];
  //   // }
  //   // if (selectedPlatform && selectedPlatform.value.length) {
  //   //   filteredData = [
  //   //     ...filteredData.filter(
  //   //       (item) =>
  //   //         item.platform.toLowerCase() === selectedPlatform.value.toLowerCase()
  //   //     ),
  //   //   ];
  //   // }
  //   // setGInfras(filteredData);
  //   // setRenderFlag((renderFlag) => !renderFlag);
  // }, [
  //   data,
  //   searchText,
  //   filters,
  //   currentPage,
  //   showAll,
  //   selectedEnv,
  //   selectedPlatform,
  // ]);

  const filteredData = useCallback(() => {
    let filteredData = [...data];
    // if (searchText && searchText.length) {
    //   filteredData = [
    //     ...filteredData.filter(
    //       (item) =>
    //         !!filters.filter(
    //           (col) =>
    //             col.search &&
    //             item[col.access]
    //               .toLowerCase()
    //               .includes(searchText.toLowerCase())
    //         ).length
    //     ),
    //   ];
    // }
    // if (selectedEnv && selectedEnv.value.length) {
    //   filteredData = [
    //     ...filteredData.filter(
    //       (item) =>
    //         item.environment.toLowerCase() === selectedEnv.value.toLowerCase()
    //     ),
    //   ];
    // }
    // if (selectedPlatform && selectedPlatform.value.length) {
    //   filteredData = [
    //     ...filteredData.filter(
    //       (item) =>
    //         item.platform.toLowerCase() === selectedPlatform.value.toLowerCase()
    //     ),
    //   ];
    // }
    // if (
    //   currentPage !== 1 &&
    //   currentPage > filteredData.length / itemPerPage + 1
    // ) {
    //   setCurrentPage(1);
    // }
    return filteredData;
  }, [
    data,
  ]);

  // const handlePageChange = (e) => {
  //   setCurrentPage(e.selected + 1);
  // };

  // useImperativeHandle(ref, () => ({
  //   getFilterPanel() {
  //     return (
  //       <FilterPanel
  //         columns={columns}
  //         filters={filters}
  //         setFilters={setFilters}
  //         searchText={searchText}
  //         setSearchText={setSearchText}
  //         selectedSeverity={selectedSeverity}
  //         setSelectedSeverity={setSelectedSeverity}
  //         selectedPlatform={selectedPlatform}
  //         setSelectedPlatform={setSelectedPlatform}
  //         selectedCategory={selectedCategory}
  //         setSelectedCategory={setSelectedCategory}
  //         selectedEnv={selectedEnv}
  //         setSelectedEnv={setSelectedEnv}
  //         envOptions={envOptions}
  //         showAll={showAll}
  //         setShowAll={setShowAll}
  //       />
  //     );
  //   },
  // }));

  return (
    <TableWrapper marginTop="10px">
      <TableHeader
        columns={columns}
        currentSort={currentSort}
        setCurrentSort={setCurrentSort}
        data={filteredData()}
        setData={setData}
      />
      <div className="divider" />
      {!infras?.length ? (
        <div className="d-flex justify-content-center py-5 align-items-center">
          {loadingBar("Rings", 50, 50)}
          <StyledTypo
            className="pl-2"
            size={0.8}
            line={26.15 / 16}
            letter="0"
            fontWeight={400}
          >
            Loading...
          </StyledTypo>
        </div>
      ) : data.length ? data.map((infra) => (
          <Record
            data={infra}
            columns={columns}
            setData={setData}
            // remediationStatus={remediationStatus}
            openItems={openItems}
            // force={currentPage - 1}
            setOpenItems={setOpenItems}
          />
        )
      ) : (
        <StyledTypo
          className="text-center py-5"
          size={0.8}
          line={26.15 / 16}
          letter="0"
          fontWeight={400}
        >
          No Infrastructure
        </StyledTypo>
      )}

      {/* <ShowingWrapper>
        <StyledTypo
          size={0.8}
          line={1.6}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {`Showing ${
            itemPerPage * (currentPage - 1) + 1 > filteredData().length
              ? filteredData().length
              : itemPerPage * (currentPage - 1) + 1
          } - ${
            itemPerPage * currentPage > filteredData().length
              ? filteredData().length
              : itemPerPage * currentPage
          } from ${filteredData().length} Results`}
        </StyledTypo>
        <ReactPaginate
          pageCount={(filteredData()?.length ?? 0) / itemPerPage}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          previousLabel="< Prev"
          nextLabel="Next >"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          forcePage={currentPage - 1}
          onPageChange={handlePageChange}
          activeClassName="active"
        />
      </ShowingWrapper> */}
    </TableWrapper>
  );
};

export default connect(undefined, undefined)(InfraListByCompliance);
