import React, { useState } from 'react'
import { connect } from 'react-redux'

import { ViewContainer } from '../../../components/PageLayout/Containers'
import { Tabs, TabList, TabPanel, Tab } from 'react-re-super-tabs'
import DashboardTab from '../../../components/Tab/DashboardTab'
import TopPriorityPanel from './TopPriorityPanel'
import { cspData, cloudDashboardTabList } from '../../../helpers/constants'
import SecurityPanel from './SecurityPanel'
import CompliancePanel from './CompliancePanel'
import AssetsPanel from './AssetsPanel'
import DetailPanel from './DetailPanel'

const CloudDashboardLayout = props => {
  return (
    <ViewContainer>
      <div className='dashboard-container d-flex flex-column'>
        <Tabs activeTab={cloudDashboardTabList?.[0]?.key}>
          <TabList>
            {cloudDashboardTabList.map(tab => (
              <Tab
                component={({ children, isActive }) =>
                  DashboardTab({ children, isActive, width: '25%' })
                }
                label={tab.label}
                id={tab.key}
                key={tab.key}
              />
            ))}
          </TabList>
          <TabList>
            <TabPanel
              component={() => (
                <TopPriorityPanel/>
              )}
              id={cloudDashboardTabList?.[0]?.key}
            />
            <TabPanel
              component={() => (
                <SecurityPanel/>
              )}
              id={cloudDashboardTabList?.[1]?.key}
            />
            <TabPanel
              component={() => (
                <CompliancePanel/>
              )}
              id={cloudDashboardTabList?.[2]?.key}
            />
            <TabPanel
              component={() => (
                <AssetsPanel/>
              )}
              id={cloudDashboardTabList?.[3]?.key}
            />
          </TabList>
        </Tabs>
      </div>
    </ViewContainer>
  )
}

// function mapStateToProps(state) {
//   const { cloud_data, iac_data } = state.dashboard;
//   const { organizationList, userList } = state.users;
//   const { expired_token, new_refresh_token } = state.auth;
//   const { appList } = state.applications;
//   return {
//     organizationList,
//     userList,
//     expired_token,
//     new_refresh_token,
//     appList,
//     cloud_data,
//     iac_data,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     fetchCloudDataAction: () =>
//       dispatch(dashboardActions.fetchCloudSecurityDataAction()),
//     fetchCodeDataAction: () =>
//       dispatch(dashboardActions.fetchCodeSecurityDataAction()),
//     fetchOrganizationList: () =>
//       dispatch(usersActions.fetchOrganizationListAction()),
//     fetchUserListAction: () => dispatch(usersActions.fetchUserListAction()),
//   };
// }

export default connect(undefined, undefined)(CloudDashboardLayout)
// export default connect(mapStateToProps, mapDispatchToProps)(CloudDashboardLayout);
