import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { usersActions } from "../../../redux/actions";
import { dashboardActions } from "../../../redux/actions";
import { connect } from "react-redux";
import * as global from "../../../helpers/constants";
import StyledTypo from "../../../components/Typo/StyledTypo";
// import CloudScanTab from "./CloudScanTab";
import Dashboard from "./Dashboard";
import { cspData } from "../../../helpers/constants";
import GradientProgressBar from "../../../components/GradientProgressBar/GradientProgressBar";
import { ReactComponent as AssetsMenuIcon } from "../../../assets/icons/assets-menu-icon.svg";
import { ReactComponent as CloudSecurityIcon } from "../../../assets/icons/cloud-security.svg";
import { ViewContainer } from "../../../components/PageLayout/Containers";

const CspWrapper = styled.div`
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  padding-bottom: 10px;
  justify-content: center;
  pointer-events: ${(props) => (props.active ? "none" : "auto")};
  cursor: pointer;
  min-width: 380px;
  box-shadow: ${(props) =>
    props.active
      ? "rgb(14 30 37 / 12%) 0px 2px 4px 0px, rgb(14 30 37 / 32%) 0px 2px 16px 0px"
      : "rgb(0 0 0 / 10%) 0px 3px 12px"};
  border-bottom: 3px solid
    ${(props) => (props.active ? "rgb(90 163 237)" : "transparent")};
  position: relative;
  transition: transform 1s;
  &:hover {
    // border-bottom: 3px solid rgb(90 163 237);
    transform: scale(1.05);
  }

  .horizon-divider {
    width: calc(100% + 40px);
    border-top: 1px solid #ececec;
    margin: 5px 0;
    margin-left: -20px;
    height: 0;
  }

  .vertical-divider {
    width: 0;
    border-left: 1px solid #ececec;
    margin: 0 5px;
    margin-top: -20px;
    height: calc(100% + 40px);
  }

  .status-mark {
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: 50%;
    background: ${(props) => props.markColor ?? "#ff487f"};
    width: ${(props) => props.markSize ?? 7}px;
    height: ${(props) => props.markSize ?? 7}px;
  }
`;

const HomeLayout = (props) => {
  const { fetchCloudDataAction, fetchCodeDataAction, cloud_data } = props;
  const history = useHistory();

  const [tabs, setTabs] = useState(global.dashboardTabList);
  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedCsp, setSelectedCsp] = useState(cspData[0]);

  const location = useLocation();

  useEffect(() => {
    fetchCloudDataAction();
    fetchCodeDataAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedTab(
      location?.search
        ? new URLSearchParams(location?.search).get("tab")
        : tabs.filter((item) => item.active)?.[0]?.key
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedTab]);

  useEffect(() => {
    if (selectedTab) {
      handleSelectedTab(selectedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const handleSelectedTab = (key) => {
    history.push("/dashboard");
    setSelectedTab(key);
    setTabs([...tabs.map((tab) => ({ ...tab, active: tab.key === key }))]);
  };

  return (
    <ViewContainer>
      <div className="dashboard-container d-flex flex-column">
        <Dashboard data={cloud_data ?? []} />
      </div>
    </ViewContainer>
  );
};

function mapStateToProps(state) {
  const { cloud_data, iac_data } = state.dashboard;
  const { organizationList, userList } = state.users;
  const { expired_token, new_refresh_token } = state.auth;
  const { appList } = state.applications;
  return {
    organizationList,
    userList,
    expired_token,
    new_refresh_token,
    appList,
    cloud_data,
    iac_data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCloudDataAction: () =>
      dispatch(dashboardActions.fetchCloudSecurityDataAction()),
    fetchCodeDataAction: () =>
      dispatch(dashboardActions.fetchCodeSecurityDataAction()),
    fetchOrganizationList: () =>
      dispatch(usersActions.fetchOrganizationListAction()),
    fetchUserListAction: () => dispatch(usersActions.fetchUserListAction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeLayout);
