import React, { useState } from "react";
import styled from "styled-components";
import StyledTypo from "../../components/Typo/StyledTypo";
import { ReactComponent as GitUrlIcon } from "../../assets/icons/git_link_icon.svg";
import CommonInput from "../../components/Input/CommonInput";
import moment from "moment";
import EffectedButton from "../../components/Buttons/EffectedButton";
import { DropdownMenu } from "reactstrap";

const MainContainer = styled(DropdownMenu)`
  width: 480px;
  padding: 33px 41px;

  background: #ffffff;
  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  //box-shadow: 0 6px 4px rgba(104, 112, 137, 0.05);
  box-shadow: 0 0 0 9999px #00000066;
  border-radius: 20px;
  transform: translate3d(-295px, 40px, 0px) !important;
`;

const StatusWrapper = styled.div`
  background-color: ${(props) => props.color};
  padding: 5px 20px;
  border-radius: 215px;
  display: flex;

  cursor: pointer;
`;

const AddPolicyPanel = (props) => {
  const [name, setName] = useState("");
  const [gitUrl, setGitUrl] = useState("");
  const [status, setStatus] = useState(true);

  const toggleStatus = () => {
    setStatus((status) => !status);
  };

  return (
    <MainContainer>
      <div className="title mb-2">
        <StyledTypo
          color="#A4AFB7"
          size={1.125}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={700}
        >
          Add a Policy
        </StyledTypo>
      </div>
      <div className="sub-title mb-4">
        <StyledTypo
          color="#A4AFB7"
          size={1}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={700}
        >
          You are creating a new Policy
        </StyledTypo>
      </div>
      <div>
        <CommonInput
          label="Deployment Name"
          value={name}
          setValue={setName}
          active={false}
          locked={false}
          placeholder=""
        />
      </div>
      <div>
        <CommonInput
          label="Git Repository Link"
          value={gitUrl}
          setValue={setGitUrl}
          active={false}
          locked={false}
          icon={GitUrlIcon}
          placeholder=""
        />
      </div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <StyledTypo
          color="#505050"
          size={1}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={400}
        >
          Create Date
        </StyledTypo>
        <StyledTypo
          color="#505050"
          size={1}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {moment().format("MM-DD-YYYY hh:mm")}
        </StyledTypo>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-3 mb-5 pb-2">
        <StyledTypo
          color="#505050"
          size={1}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={400}
        >
          Status
        </StyledTypo>
        <StatusWrapper onClick={toggleStatus} color={"#A4AFB7"}>
          <StyledTypo
            color="#fff"
            size={1}
            line={1.4}
            letter="0"
            family="Poppins"
            fontWeight={400}
          >
            {status ? "Active" : "Inactive"}
          </StyledTypo>
        </StatusWrapper>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <EffectedButton marginLeft="0">
            <StyledTypo
              color="#fff"
              size={1.125}
              line={25.2 / 18}
              letter="0"
              family="Roboto"
              fontWeight={700}
            >
              Confirm Policy Creation
            </StyledTypo>
          </EffectedButton>
        </div>
        <div>
          <EffectedButton
            marginLeft="0"
            background="#485DCD"
            onClick={props.toggle}
          >
            <StyledTypo
              color="#fff"
              size={1.125}
              line={25.2 / 18}
              letter="0"
              family="Roboto"
              fontWeight={700}
            >
              Cancel
            </StyledTypo>
          </EffectedButton>
        </div>
      </div>
    </MainContainer>
  );
};

export default AddPolicyPanel;
