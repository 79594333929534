import React, { useEffect, useState } from 'react'

import {useHistory} from 'react-router-dom'

import Sidebar from "./Sidebar";
import Background from "./Background";
import {authAction} from "../../redux/actions";
import {connect} from "react-redux";
import {ReactComponent as UpArrow} from "../../assets/icons/arrow-angle-up-sm.svg";
import styled from 'styled-components'

const GoTopButton = styled.div`
  padding: 10px 15px; 
  position: fixed;
  bottom: 20px;
  right: 30px;
  //width: 20px;
  //height: 20px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #65C2D7;
  cursor: pointer;
  &:hover {
    background-color: #f6f6f6;
  }
`

const Page = (props) => {
  const {
    expired_token, new_refresh_token, getRefreshToken,
    defused_refresh_token, clearDefuseRefreshToken,
      noBackground, noUpperCta
  } = props

  const history = useHistory()
  const [scrollY, setScrollY] = useState(window.scrollY)

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollY(position);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  useEffect(() => {
    if(expired_token && !new_refresh_token) {
      getRefreshToken()
    }
  }, [expired_token, new_refresh_token])

  useEffect(() => {
    if(defused_refresh_token) {
      clearDefuseRefreshToken()
      localStorage.removeItem('user_token')
      history.push('/login')
    }
  }, [defused_refresh_token])

  return (
      <div className="dashboard">
        {!noBackground && (
          <Background />
        )}
        <div className="d-flex">
          <Sidebar />
          {props.children}
        </div>
        {!noUpperCta && scrollY > 50 && (
          <GoTopButton onClick={() => {window.scrollTo(0, 0)}}>
            <UpArrow fill="#65C2D7"/>
          </GoTopButton>
        )}
      </div>
  )
}

function mapStateToProps(state) {
  const {expired_token, new_refresh_token, defused_refresh_token} = state.auth;
  return {
    expired_token,
    new_refresh_token,
    defused_refresh_token,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getRefreshToken: () => dispatch(authAction.getRefreshToken()),
    clearDefuseRefreshToken: () => dispatch(authAction.clearDefuseRefreshToken()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page);
