import React from "react";
import Page from "../../components/PageLayout/Page";
import InfraListLayout from "./cloud/InfraListLayout";

const CloudSecurity = (props) => {
  return (
    <div>
      <Page noUpperCta>
        <InfraListLayout />
      </Page>
    </div>
  );
};

export default CloudSecurity;
