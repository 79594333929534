import React, { forwardRef } from "react";
import styled from "styled-components";
import RemediationTable from "../../../modals/RemediationTable";

const MainBodyWrapper = styled.div`
  padding: 10px;
`;

const RemediationPanel = forwardRef((props, ref) => {
  const {
    remediations,
    solution,
    selectedApp,
    clusterInfo,
    setSelectedFilterType,
    selectedFilterType,
    preFilterData,
    setRenderFlag,
  } = props;

  const remediationList = remediations
    .filter((item) => item?.details?.title)
    .map((r) => ({
      ...r.details,
      name: r.details.title,
      type: r.details.resource_type,
      id: r.audit_id,
    }))
    .reduce((acc, cur) => {
      let tmp = acc.filter((item) => item.id === cur.id)?.length
        ? [...acc]
        : [...acc, cur];
      return tmp;
    }, []);

  return (
    <MainBodyWrapper>
      <RemediationTable
        ref={ref}
        remediationList={remediationList}
        solution={{ ...(solution ?? {}) }}
        appId={selectedApp?.id}
        selectedApp={selectedApp}
        csp={selectedApp.platform}
        resources={clusterInfo?.["resources"] ?? {}}
        grouped={clusterInfo?.["grouped_type"]}
        selectedFilterType={selectedFilterType}
        setSelectedFilterType={setSelectedFilterType}
        preFilterData={preFilterData}
        setRenderFlag={setRenderFlag}
      />
    </MainBodyWrapper>
  );
});

export default RemediationPanel;
