import React from 'react'
import styled from 'styled-components'
import StyledTypo from "../../../components/Typo/StyledTypo";
import CustomTextarea from "../../../components/Input/CustomTextarea";
import {credentialMap} from "../../../helpers/constants";

const BorderWrapper = styled.div`
  border: 0.5px solid #ccc;
  padding: 10px 15px;
  border-radius: 10px;
  position: relative;
  margin-top: 30px;
  
  .title {
    padding: 0 5px;
    position: absolute;
    left: 15px;
    top: -10px;
    background-color: #fff;
  }
`

const AzureCredPartial = (props) => {
  const {credValue, setCredValue} = props
  const handleCredChange = (parentKey, key, val) => {
    setCredValue(credValue => ({...credValue, [parentKey]: {...(credValue[parentKey] ?? {}), [key]: val}}))
  }

  return (
      <div>
        {credentialMap.azure.map((each) => (
            <BorderWrapper key={each.key}>
              <div className="title">
                <StyledTypo size="0.8">
                  {each.label}
                </StyledTypo>
              </div>
              {each.children && each.children.map((category, idx) => (
                  <CustomTextarea
                      key={idx} label={category.label} value={credValue?.[each.key]?.[category.key] ?? ''}
                      setValue={(val) => handleCredChange(each.key, category.key, val)}
                      disabled={false}
                  />
              ))}
            </BorderWrapper>
        ))}
      </div>
  )
}

export default AzureCredPartial
