import { authHeader, handleResponse } from "../../helpers";
import * as global from "../../helpers/constants";
import { getCurrentOrganization } from "../../helpers/constants";
import moment from "moment";

export const applications = {
  fetchApplicationList,
  fetchDemoBlogList,
  fetchAppHealth,
  fetchClusterDetails,
  fetchActivityFeeds,
  addApplication,
  updateApplication,
  deleteApplication,
  fetchRemediationRequired,
  fetchResourceSummary,
  resetAppInfo,
  fetchOrgEnvList,
  updateOrgEnvList,
  fetchHealthHistory,
  fetchResourceHistory,
  fetchLatestResourceHistory,
  fetchRemediations,
  fetchSolutions,
  fetchRemediationLogs,
  generateSummaryReport,
  generateDetailReport,
  triggerResource,
  triggerStatus,
  fetchSteampipeResult,
  triggerSteampipe,
  triggerSingleResource,
  fetchSingleResourceTriggerStatus,
  fetchManualCloudRemediation,
  fetchProwlerResource,
  fetchProwlerRemediation,
  fetchCloudRemediationSolution,
  updateOrgScanSetting,
  updateOrgComplianceSetting,
  fetchTrendData,
  fetchWorkloads,
  fetchWorkloadSummary,
  fetchWorkloadDetail,
  fetchKspmClusters,
  fetchKspmClusterSummary,
  fetchKspmClusterDetail,
  fetchUserAssets,
  fetchInstanceCveGroup,
  fetchInstanceCveDetail,

  fetchSGAssets,
  fetchNetworkAssets,
  fetchGLogs,

  fetchTopAttackPaths,
  fetchTopInfras,
  fetchTopNoncompliances,

  fetchTopIdentityAccess,
  fetchTopContainers,
  fetchTopClusters,
  fetchTopDataSecurity,
  fetchTopPublicExposure,
  fetchTopSoftwareUpdate,

  fetchPentestingConfigurations,
  fetchPentestingConfiguration: fetchSelectedPentestingConfiguration,
  setPentestingConfigurationByContent,
  setSelectedPentestingConfiguration,

  fetchWorkspaces,
  fetchWorkspace,
  createWorkspace,
  updateWorkspace,
  deleteWorkspace,
};

function fetchApplicationList(payload = {}) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/infrastructure/list?org_id=${global.getCurrentOrganization()}${
      payload?.platform ? `&platform=${payload?.platform}` : ""
    }${payload?.start_time ? `&start_time=${payload?.start_time}` : ""}${
      payload?.end_time ? `&end_time=${payload?.end_time}` : ""
    }${
      payload?.only_active
        ? `&only_active=${payload?.only_active === "only"}`
        : ""
    }`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
      return result;
    });
}

function fetchDemoBlogList() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${global.getBaseURL()}/gcp/demoblog`, requestOptions)
    .then(handleResponse)
    .then((result) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
      return result;
    });
}

function fetchAppHealth(org_id, app_id, realtimeFlag) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    timeout: 1200000,
  };
  const url =
    `${global.getBaseURL()}/app/get-app-health?org_id=${getCurrentOrganization()}&app_id=${app_id}` +
    (realtimeFlag ? `&realtime=${realtimeFlag}` : "");
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((result) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
      return result;
    });
}

function fetchClusterDetails(org_id, app_id, realtimeFlag) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  // const url = `${global.getBaseURL()}/gcp/resources?org_id=${global.getCurrentOrganization()}&app_id=${app_id}` + (realtimeFlag ? `&realtime=${realtimeFlag}` : '')
  const url =
    `${global.getBaseURL()}/apps/${app_id}/resources` +
    (realtimeFlag ? `?real_time=${realtimeFlag}` : "");
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((result) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
      return result;
    });
}

function fetchActivityFeeds(page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${global.getBaseURL()}/app/activity-feeds?organization_id=${global.getCurrentOrganization()}${
    page ? `&page=${page}` : ""
  }`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((result) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
      return result;
    });
}

function addApplication(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      ...data,
      organization_id: getCurrentOrganization(),
    }),
  };
  const url = `${global.getBaseURL()}/apps`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function updateApplication(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      ...data,
      organization_id: getCurrentOrganization(),
    }),
  };
  const url = `${global.getBaseURL()}/app/update-app?app_id=${data.app_id}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function deleteApplication(app_id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${global.getBaseURL()}/app/delete-app?app_id=${app_id}&organization_id=${global.getCurrentOrganization()}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((result) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
      return result;
    });
}

function fetchRemediationRequired(app_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${global.getBaseURL()}/app/remediation-plan?app_id=${app_id}&remediation_required=1`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((result) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
      return result;
    });
}

function fetchResourceSummary() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    timeout: 1200000,
  };
  const url = `${global.getBaseURL()}/apps/${getCurrentOrganization()}/resources-summary`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((result) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
      return result;
    });
}

function resetAppInfo(app_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${global.getBaseURL()}/app/${app_id}/reset`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((result) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
      return result;
    });
}

function fetchOrgEnvList(org_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${global.getBaseURL()}/app/org-env?org_id=${org_id}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function updateOrgScanSetting(org_id, data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ organization_id: org_id, ...data })
  };
  const url = `${global.getBaseURL()}/app/update-scan-setting`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function updateOrgComplianceSetting(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ organization_id: getCurrentOrganization(), compliance: data })
  };
  const url = `${global.getBaseURL()}/app/update-compliance-setting`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function updateOrgEnvList(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  const url = `${global.getBaseURL()}/app/org-env`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchHealthHistory(data) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/health/history?org_id=${getCurrentOrganization()}&start_time=${
      data.startHistoryTime
    }&end_time=${data.endHistoryTime}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchResourceHistory(data) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/resource/history?app_id=${
      data.app_id
    }&start_time=${data.startHistoryTime}&end_time=${data.endHistoryTime}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchLatestResourceHistory(data) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/lts-history-resource?org_id=${global.getCurrentOrganization()}&start_time=${
      data.start_time
    }${data?.platform ? `&platform=${data?.platform}` : ""}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchRemediations(platform) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/remediations${
      platform ? `?platform=${platform}` : ""
    }`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchSolutions(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/remediation-solution?app_id=${data.app_id}${
      data.real_time ? `&real_time=true` : ""
    }`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchRemediationLogs(data) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/remediation-logs?app_id=${
      data.app_id
    }&remediation_id=${data.remediation_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

async function generateSummaryReport(type) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    timeout: 120000,
  };
  const type_url = `${type === 'iac' ? 'iac-project' : type}-summary${type !== 'infra' ? '-report' : ''}`
  const url = `${global.getBaseURL()}/app/report/${type_url}?org_id=${getCurrentOrganization()}`;
  return fetch(url, requestOptions)
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${moment().format("MMM Do")} MS Summary Report.pdf`;
      link.click();
      return blob;
    })
    .catch((err) => {
      return Promise.reject("Failed");
    });
}

async function generateDetailReport(type, id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
    timeout: 120000,
  };
  const id_field = type === 'kspm' ? 'cluster_id' : type === 'cwpp' ? 'workload_id' : type === 'iac' ? 'project_id' : type === 'infra' ? 'app_id' : ''
  const type_field = type === 'iac' ? 'iac-project-details' : type === 'infra' ? 'infra-details' : `${type}-detail-report`
  const url = `${global.getBaseURL()}/app/report/${type_field}?${id_field}=${id}`;
  return fetch(url, requestOptions)
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${moment().format("MMM Do")} MS Detail Report (${type}).pdf`;
      link.click();
      return blob;
    })
    .catch((err) => {
      return Promise.reject("Failed");
    });
}

function triggerResource(application_id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ application_id }),
  };
  return fetch(
    `${global.getBaseURL()}/apps/task-resource/trigger`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function triggerStatus(application_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/apps/task-resource/${application_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function triggerSteampipe(application_id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ application_id }),
  };
  return fetch(
    `${global.getBaseURL()}/apps/task-resource/add-steampipe-task`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchSteampipeResult(application_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/steampipe?application_id=${application_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function triggerSingleResource(application_id, remediation_id, resource_type) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ application_id, remediation_id, resource_type }),
  };
  return fetch(
    `${global.getBaseURL()}/apps/task-resource/trigger-by-single-resource`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchSingleResourceTriggerStatus(task_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/apps/task_resource-by-id/${task_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchManualCloudRemediation(remediation_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/manual-cloud-remediation?remediation_id=${remediation_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchProwlerResource(app_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/cspm-resource?application_id=${app_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchProwlerRemediation(app_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/cspm-remediation-solutions?app_id=${app_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchCloudRemediationSolution(app_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/v2/cloud-remediation-solutions?app_id=${app_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchTrendData(data) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const start_time = data?.start_time ?? moment().subtract(2, 'years').format('YYYY-MM-DD HH:mm:ss');
  const end_time = data?.end_time ?? moment().format('YYYY-MM-DD HH:mm:ss');
  return fetch(
    `${global.getBaseURL()}/app/cloud-security/compliance-trend?org_id=${getCurrentOrganization()}&provider=${data.provider}&start_time=${start_time}&end_time=${end_time}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}


function fetchWorkloads() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/cwp/workloads?org_id=${getCurrentOrganization()}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchWorkloadSummary(workload_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/cwp/workloads/summary?org_id=${getCurrentOrganization()}&workload_id=${workload_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchWorkloadDetail(workload_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/cwp/workloads/resource-details?org_id=${getCurrentOrganization()}&workload_id=${workload_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchKspmClusters() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/kspm/clusters?org_id=${getCurrentOrganization()}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchKspmClusterSummary(cluster_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/kspm/clusters/summary?org_id=${getCurrentOrganization()}&cluster_id=${cluster_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchKspmClusterDetail(cluster_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${global.getBaseURL()}/app/kspm/clusters/resource-details?org_id=${getCurrentOrganization()}&cluster_id=${cluster_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchUserAssets (app_id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/graph-resource/user-asset?application_id=${app_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchInstanceCveGroup (app_id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/graph-resource/instance-cve-group?application_id=${app_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchInstanceCveDetail (app_id, node_id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/graph-resource/instance-cve-detail?application_id=${app_id}&node_id=${node_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchSGAssets (app_id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/graph-resource/sg-asset?application_id=${app_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchNetworkAssets (app_id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/graph-resource/network-asset?application_id=${app_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchGLogs (period) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/logs?org_id=${getCurrentOrganization()}&days=${period}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchTopAttackPaths () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/top-attack-paths?org_id=${getCurrentOrganization()}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchTopInfras () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/top-infrastructures?org_id=${getCurrentOrganization()}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchTopNoncompliances () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/top-noncompliances?org_id=${getCurrentOrganization()}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchTopIdentityAccess () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/top-identity-access?org_id=${getCurrentOrganization()}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchTopContainers () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/top-containers?org_id=${getCurrentOrganization()}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchTopClusters () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/top-clusters?org_id=${getCurrentOrganization()}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchTopDataSecurity () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/top-data-security?org_id=${getCurrentOrganization()}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchTopPublicExposure () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/top-public-exposure?org_id=${getCurrentOrganization()}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchTopSoftwareUpdate () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/top-software-update?org_id=${getCurrentOrganization()}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchPentestingConfigurations() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/pentesting-setting?org_id=${getCurrentOrganization()}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchSelectedPentestingConfiguration() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/pentesting-setting/config?org_id=${getCurrentOrganization()}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function setSelectedPentestingConfiguration(id) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/pentesting-setting/config?id=${id}&org_id=${getCurrentOrganization()}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function setPentestingConfigurationByContent(content) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      org_id: getCurrentOrganization(),
      content
    })
  }
  return fetch(
    `${global.getBaseURL()}/app/pentesting-setting/custom-set`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function createWorkspace(payload) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload)
  }
  return fetch(
    `${global.getBaseURL()}/app/pentest/workspace`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function updateWorkspace(id, payload) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(payload)
  }
  return fetch(
    `${global.getBaseURL()}/app/pentest/workspace?id=${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function deleteWorkspace(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/pentest/workspace?id=${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchWorkspaces() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/pentest/workspaces`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}

function fetchWorkspace(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return fetch(
    `${global.getBaseURL()}/app/pentest/workspace?id=${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(result => {
      return result
    })
}


