import React from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-re-super-tabs";
import DashboardTab from "../../../components/Tab/DashboardTab";
import { cspData } from "../../../helpers/constants";
import ProviderDashboard from "./ProviderDashboard";

const Dashboard = (props) => {
  return (
    <div>
      <Tabs activeTab="aws">
        <TabList>
          {cspData.map((provider) => (
            <Tab
              component={DashboardTab}
              label={provider.title}
              id={provider.key}
              key={provider.key}
            />
          ))}
        </TabList>
        <TabList>
          {cspData.map((provider) => (
            <TabPanel
              component={() => (
                <ProviderDashboard
                  data={
                    props.data?.filter(
                      (item) => item.provider === provider.key
                    )?.[0] ?? {}
                  }
                  provider={provider.key}
                />
              )}
              id={provider.key}
            />
          ))}
        </TabList>
      </Tabs>
    </div>
  );
};

export default Dashboard;
