import { iacConstants } from "../constants";

const initialState = {
  iacReport: {},
  iac_projects: [],
  createdFlag: 0,
  iac_remediation: {},
  fetching_projects_flag: false,
};

export function iacs(state = initialState, action) {
  switch (action.type) {
    case iacConstants.FETCH_IAC_SCAN_REPORT_SUCCESS:
      return {
        ...state,
        iacReport: {
          ...state.iacReport,
          [action.project_id]: action.iacReport,
        },
      };
    case iacConstants.FETCH_IAC_SCAN_REPORT_FAILURE:
      return {
        ...state,
        iacReport: { ...state.iacReport, [action.project_id]: [] },
      };

    case iacConstants.FETCH_IAC_REMEDIATION_SUCCESS:
      return {
        ...state,
        iac_remediation: {
          ...state.iac_remediation,
          [action.query_id]: action.payload,
        },
      };
    case iacConstants.FETCH_IAC_REMEDIATION_FAILURE:
      return {
        ...state,
        iac_remediation: { ...state.iac_remediation, [action.query_id]: null },
      };
    case iacConstants.FETCH_IAC_PROJECTS_SUCCESS:
      return {
        ...state,
        iac_projects: [...action.projects],
        fetching_projects_flag: false,
      };
    case iacConstants.FETCH_IAC_PROJECTS_REQUEST:
      return {
        ...state,
        fetching_projects_flag: true,
      };
    case iacConstants.FETCH_IAC_PROJECTS_FAILURE:
      return {
        ...state,
        fetching_projects_flag: false,
      };
    case iacConstants.IAC_CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        createdFlag: 1,
      };
    case iacConstants.IAC_CREATE_PROJECT_FAILURE:
      return {
        ...state,
        createdFlag: 2,
      };
    case iacConstants.CLEAR_CREATE_FLAG:
      return {
        ...state,
        createdFlag: 0,
      };
    default:
      return { ...state };
  }
}
