import React from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-re-super-tabs";
import DashboardTab from "../../../components/Tab/DashboardTab";
import { cspData } from "../../../helpers/constants";
import ProviderComplianceTrend from "./ProviderComplianceTrend";

const TrendChartTab = (props) => {
    const { data } = props;
  return (
    <div>
      <Tabs activeTab="aws">
        <TabList>
          {cspData.map((provider) => (
            <Tab
              component={DashboardTab}
              label={provider.title}
              id={provider.key}
              key={provider.key}
            />
          ))}
        </TabList>
        <TabList>
          {cspData.map((provider) => (
            <TabPanel
              component={() => (
                <ProviderComplianceTrend
                    data={data?.[provider?.key]}
                    provider={provider.title}
                />
              )}
              id={provider.key}
            />
          ))}
        </TabList>
      </Tabs>
    </div>
  );
};

export default TrendChartTab;
