export const deploymentConstants = {
  FETCH_DEPLOY_APPS_REQUEST: 'FETCH_DEPLOY_APPS_REQUEST',
  FETCH_DEPLOY_APPS_SUCCESS: 'FETCH_DEPLOY_APPS_SUCCESS',
  FETCH_DEPLOY_APPS_FAILURE: 'FETCH_DEPLOY_APPS_FAILURE',

  FETCH_TEMPLATES_REQUEST: 'FETCH_TEMPLATES_REQUEST',
  FETCH_TEMPLATES_SUCCESS: 'FETCH_TEMPLATES_SUCCESS',
  FETCH_TEMPLATES_FAILURE: 'FETCH_TEMPLATES_FAILURE',

  APPLY_TERRAFORM_REQUEST: 'APPLY_TERRAFORM_REQUEST',
  APPLY_TERRAFORM_SUCCESS: 'APPLY_TERRAFORM_SUCCESS',
  APPLY_TERRAFORM_FAILURE: 'APPLY_TERRAFORM_FAILURE',

  DESTROY_TERRAFORM_REQUEST: 'DESTROY_TERRAFORM_REQUEST',
  DESTROY_TERRAFORM_SUCCESS: 'DESTROY_TERRAFORM_SUCCESS',
  DESTROY_TERRAFORM_FAILURE: 'DESTROY_TERRAFORM_FAILURE',

  TASK_STATUS_REQUEST: 'TASK_STATUS_REQUEST',
  TASK_STATUS_SUCCESS: 'TASK_STATUS_SUCCESS',
  TASK_STATUS_FAILURE: 'TASK_STATUS_FAILURE',

  CREATE_NEW_DEPLOYMENT_TEMPLATE_REQUEST: 'CREATE_NEW_DEPLOYMENT_TEMPLATE_REQUEST',
  CREATE_NEW_DEPLOYMENT_TEMPLATE_SUCCESS: 'CREATE_NEW_DEPLOYMENT_TEMPLATE_SUCCESS',
  CREATE_NEW_DEPLOYMENT_TEMPLATE_FAILURE: 'CREATE_NEW_DEPLOYMENT_TEMPLATE_FAILURE',

  FETCH_TEMPLATE_VARS_REQUEST: 'FETCH_TEMPLATE_VARS_REQUEST',
  FETCH_TEMPLATE_VARS_SUCCESS: 'FETCH_TEMPLATE_VARS_SUCCESS',
  FETCH_TEMPLATE_VARS_FAILURE: 'FETCH_TEMPLATE_VARS_FAILURE',

  CREATE_NEW_ORGANIZATION_TEMPLATE_REQUEST: 'CREATE_NEW_ORGANIZATION_TEMPLATE_REQUEST',
  CREATE_NEW_ORGANIZATION_TEMPLATE_SUCCESS: 'CREATE_NEW_ORGANIZATION_TEMPLATE_SUCCESS',
  CREATE_NEW_ORGANIZATION_TEMPLATE_FAILURE: 'CREATE_NEW_ORGANIZATION_TEMPLATE_FAILURE',

  REMOVE_CREATE_TEMPLATE_STATUS: 'REMOVE_CREATE_TEMPLATE_STATUS',
  REMOVE_CREATE_ORG_TEMPLATE_STATUS: 'REMOVE_CREATE_ORG_TEMPLATE_STATUS',
  CLEAR_TEMPLATE_VARS: 'CLEAR_TEMPLATE_VARS',

  FETCH_ORIGIN_TEMPLATES_REQUEST: 'FETCH_ORIGIN_TEMPLATES_REQUEST',
  FETCH_ORIGIN_TEMPLATES_SUCCESS: 'FETCH_ORIGIN_TEMPLATES_SUCCESS',
  FETCH_ORIGIN_TEMPLATES_FAILURE: 'FETCH_ORIGIN_TEMPLATES_FAILURE',

  APPLY_REMEDIATION_REQUEST: 'APPLY_REMEDIATION_REQUEST',
  APPLY_REMEDIATION_SUCCESS: 'APPLY_REMEDIATION_SUCCESS',
  APPLY_REMEDIATION_FAILURE: 'APPLY_REMEDIATION_FAILURE',

  REMOVE_APPLY_STATUS: 'REMOVE_APPLY_STATUS',
}
