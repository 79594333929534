import {authHeader, handleResponse} from '../../helpers';
import * as global from '../../helpers/constants'

export const deployments = {
  fetchDeploymentApps,
  fetchOrgTemplates,
  applyTerraform,
  destroyTerraform,
  fetchTaskStatus,
  createDeploymentTemplate,
  addNewOrgTemplate,
  fetchTemplates,
  fetchTemplateVars,
  applyRemediation,
}

function fetchDeploymentApps() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${global.getBaseURL()}/sched/organizations/Kaq6wF4SIMaAucCfdrYn/apps`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function fetchOrgTemplates() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${global.getBaseURL()}/app/org-templates?org_id=${global.getCurrentOrganization()}`, requestOptions)
      .then(handleResponse)
      .then(result => {
        return result;
      });
}

function fetchTemplates() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${global.getBaseURL()}/sched/templates`, requestOptions)
      .then(handleResponse)
      .then(result => {
        return result;
      });
}

function applyTerraform(app_id, type) {
  const random = 10 + Math.floor(Math.random() * 100)
  const deployPayload = {
    // "vpc": "cmdbvpc" + random,
    // "subnet": "cmdbsubnet" + random,
    // "firewall": "cmdbfirewall" + random,
    // "wpdbvm": "demoblogdbvm" + random,
    // "wpdb": "demoblogdb" + random,
    // "wpdb_user": "demoblog" + random,
    // "wpdb_userpass": "dem0b10g2021" + random,
    // "gke": "cmdbgke" + random,
    // "gkenodepool": "cmdbnodepool" + random,
    // "wpdep": "cmdbwp" + random,
    // "wppod": "cmdbwp" + random,
    // "wpsvc": "cmdbwpsvc" + random,
    suffix: "" + random
  }

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(type === 'deploy' ? deployPayload : {}),
    timeout: 1200000
  };
  return fetch(`${global.getBaseURL()}/sched/${type}/${app_id}`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function destroyTerraform(app_id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    timeout: 1800000
  };
  return fetch(`${global.getBaseURL()}/sched/destroy/${app_id}`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function fetchTaskStatus(task_id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    timeout: 1800000
  };
  return fetch(`${global.getBaseURL()}/sched/task_status/${task_id}`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function createDeploymentTemplate(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${global.getBaseURL()}/sched/templates/add`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function addNewOrgTemplate(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${global.getBaseURL()}/app/org-template/add`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function fetchTemplateVars(template_id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${global.getBaseURL()}/sched/var-list/${template_id}`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function applyRemediation(app_id, data) {
  let custom_var = {}
  Object.keys(data?.custom_var ?? {}).forEach(key => {
    custom_var[key] = data.custom_var?.[key]?.default
  })
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      templates: data.templates,
      customVar: custom_var,
      variables: data?.variables ?? {},
      use_api: data?.use_api ?? false,
      provider: data?.platform ?? 'gcp'
    }),
    timeout: 1200000
  };
  return fetch(`${global.getBaseURL()}/sched/remediation-solution/${app_id}`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

