import React from "react";
import { useParams } from "react-router-dom";
import Page from "../../components/PageLayout/Page";
import IacLayout from "./iac/IaCLayout";

const IaCItem = (props) => {
  const params = useParams();

  return (
    <div>
      <Page noUpperCta>
        <IacLayout id={params.id} />
      </Page>
    </div>
  );
};

export default IaCItem;
