import {authHeader, handleResponse} from '../../helpers';
import * as global from '../../helpers/constants'

export const users = {
  fetchOrganizations,
  fetchUsers,
  addUser,
  updateUser,
  manageUserStatus,
  deleteUser,
  addOrganization,
  manageOrgStatus,
  deleteManageOrg,
}

function fetchOrganizations() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${global.getBaseURL()}/onboarding/admin-orgs/`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function fetchUsers() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  const current_user = global.getCurrentUser()
  const suffix = current_user?.tid ? `?tid=${current_user?.tid}` : ''
  const url = `${global.getBaseURL()}/onboarding${global.enableV2Auth ? '/v2' : ''}/all-users${suffix}`
  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function addUser(payload) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload)
  };
  const url = `${global.getBaseURL()}/onboarding${global.enableV2Auth ? '/v2' : ''}/users/add`
  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function updateUser(id, payload) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload)
  };
  return fetch(`${global.getBaseURL()}/onboarding/users/update?id=${id}`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}


function manageUserStatus(id, action) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  const url = `${global.getBaseURL()}/onboarding${global.enableV2Auth ? '/v2' : ''}/user/${action}?id=${id}`
  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function manageOrgStatus(name, action) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return fetch(`${global.getBaseURL()}/onboarding/organization/${action}?name=${name}`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function deleteUser(id, action) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };

  const url = `${global.getBaseURL()}/onboarding${global.enableV2Auth ? '/v2' : ''}/user-delete/${action}?id=${id}`
  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function deleteManageOrg(name, action) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return fetch(`${global.getBaseURL()}/onboarding/org-delete/${action}?name=${name}`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function addOrganization(payload) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload)
  };
  return fetch(`${global.getBaseURL()}/onboarding/organization/add`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}
