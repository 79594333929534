import {deployments, applications} from '../apis';
import {deploymentConstants} from '../constants';
import {getCurrentOrganization} from "../../helpers/constants";
import {applicationActions} from "./application.actions";
import {store} from '../store'

import {showAlert} from "../../helpers/constants";
import {authAction} from "./auth.actions";

export const deploymentActions = {
  fetchDeploymentApps,
  fetchOrgTemplatesAction,
  applyTerraformAction,
  destroyTerraformAction,
  fetchTaskStatusAction,
  createNewDeploymentTemplateAction,
  removeCreateNewTemplateStatus,
  fetchTemplatesAction,
  createNewOrganizationTemplateAction,
  removeCreateNewOrgTemplateStatus,
  fetchTemplateVarsAction,
  clearTemplateVarsAction,
  applyRemediationAction,
  removeApplyStatusAction,
};

function fetchDeploymentApps() {
  return dispatch => {
    dispatch(request());
    deployments.fetchDeploymentApps()
        .then(
            deployments => {
              dispatch(success(deployments));
            },
            error => {
              dispatch(authAction.setTokenExpired(error, failure));
              // dispatch(failure(error));
            }
        );
  };

  function request() {
    return {type: deploymentConstants.FETCH_DEPLOY_APPS_REQUEST}
  }

  function success(deployApps) {
    return {type: deploymentConstants.FETCH_DEPLOY_APPS_SUCCESS, deployApps}
  }

  function failure(error) {
    return {type: deploymentConstants.FETCH_DEPLOY_APPS_FAILURE, error}
  }
}

function fetchOrgTemplatesAction() {
  return dispatch => {
    dispatch(request());
    deployments.fetchOrgTemplates()
        .then(
            templates => {
              dispatch(success(templates));
            },
            error => {
              dispatch(authAction.setTokenExpired(error, failure));
              // dispatch(failure(error));
            }
        );
  };

  function request() {
    return {type: deploymentConstants.FETCH_TEMPLATES_REQUEST}
  }

  function success(templates) {
    return {type: deploymentConstants.FETCH_TEMPLATES_SUCCESS, templates}
  }

  function failure(error) {
    return {type: deploymentConstants.FETCH_TEMPLATES_FAILURE, error}
  }
}

function applyTerraformAction(org_temp_id, type, app_id) {
  return dispatch => {
    dispatch(request());
    deployments.applyTerraform(org_temp_id, type)
        .then(
            result => {
              dispatch(success(result));
              dispatch(applicationActions.fetchActivityFeedsAction())
              showAlert('info', "Progress", `Processing ${type} of ${app_id} ...`)
            },
            error => {
              dispatch(authAction.setTokenExpired(error, failure));
              showAlert('error', "Error", `${error} ...`)
              // dispatch(failure(error));
            }
        );
  };

  function request() {
    return {type: deploymentConstants.APPLY_TERRAFORM_REQUEST, loading: type, appId: app_id}
  }

  function success(result) {
    return {type: deploymentConstants.APPLY_TERRAFORM_SUCCESS, task_id: result.task_id, appId: app_id, loading: type}
  }

  function failure() {
    return {type: deploymentConstants.APPLY_TERRAFORM_FAILURE, loading: type, appId: app_id}
  }
}

function destroyTerraformAction(org_temp_id, app_id) {
  return dispatch => {
    dispatch(request());
    deployments.destroyTerraform(org_temp_id)
        .then(
            result => {
              dispatch(success(result));
              dispatch(applicationActions.fetchActivityFeedsAction())
              showAlert('info', "Progress", `Processing Destroy of ${app_id} ...`, 20 * 1000)
            },
            error => {
              dispatch(authAction.setTokenExpired(error, failure));
              showAlert('error', "Error", `${error} ...`)
              // dispatch(failure(error));
            }
        );
  };

  function request() {
    return {type: deploymentConstants.DESTROY_TERRAFORM_REQUEST, loading: 'destroy', appId: app_id}
  }

  function success(result) {
    return {type: deploymentConstants.DESTROY_TERRAFORM_SUCCESS, task_id: result.task_id, appId: app_id, loading: 'destroy'}
  }

  function failure() {
    return {type: deploymentConstants.DESTROY_TERRAFORM_FAILURE, loading: 'destroy', appId: app_id}
  }
}

function fetchTaskStatusAction(task_id, apply_type, app_name = "") {
  return dispatch => {
    dispatch(request());
    deployments.fetchTaskStatus(task_id)
        .then(
            result => {
              if(!result['TaskID']) {
                const finishedTask = store.getState()?.deployments?.loading_task_id?.filter(item => item.task === task_id)?.[0]
                const app_id = finishedTask?.app
                const org_id = getCurrentOrganization();
                if(finishedTask?.type === 'remediation_solution') {
                  dispatch(applicationActions.triggerSingleResourceAction(finishedTask?.app, finishedTask?.remediation, finishedTask?.resource_type))
                }
                else if(finishedTask?.type === 'destroy') {
                  applications.resetAppInfo(app_id).then(
                      res => {
                        if(res) {
                          dispatch(applicationActions.fetchAppHealthAction(org_id, app_id, true))
                          dispatch(applicationActions.fetchClusterDetailsAction(org_id, app_id, true))
                          dispatch(deploymentActions.fetchOrgTemplatesAction())
                        } else {
                          showAlert('error', "Processing Error", `Can not reset resources and health value.`)
                        }
                      },
                      error => {
                        showAlert('error', "Server Internal Error", `Can not reset resources and health value.`)
                      }
                  )
                } else {
                  dispatch(applicationActions.fetchAppHealthAction(org_id, app_id, true))
                  dispatch(applicationActions.fetchClusterDetailsAction(org_id, app_id, true))
                  dispatch(deploymentActions.fetchOrgTemplatesAction())
                }
                showAlert('success', "Successful", `${result.message} ${finishedTask?.type !== 'destroy' ? `of ${app_name}` : ''}`)
              }
              dispatch(success(result, task_id));
            },
            error => {
              showAlert('error', typeof error === 'string' ? 'Error' : 'Server Internal Error', typeof error === 'string' ? error : `Failed ${apply_type} of ${app_name}.`)
              if(error === 'invalid-token') {
                dispatch(authAction.setTokenExpired(error, failure));
              } else {
                dispatch(failure(task_id));
              }
            }
        );
  };

  function request() {
    return {type: deploymentConstants.TASK_STATUS_REQUEST}
  }

  function success(result, task_id) {
    return {type: deploymentConstants.TASK_STATUS_SUCCESS, result, task_id}
  }

  function failure(task_id) {
    return {type: deploymentConstants.TASK_STATUS_FAILURE, task_id}
  }
}

function createNewDeploymentTemplateAction(data) {
  return dispatch => {
    dispatch(request());
    deployments.createDeploymentTemplate(data)
        .then(
            result => {
              dispatch(success(result));
              // const template_id = result.id
              // if('app_id' in data) {
              //   deployments.addNewOrgTemplate({
              //     organization_id: getCurrentOrganization(),
              //     template_id,
              //     application_id: data.app_id,
              //     variables: result.data.variables ?? {},
              //     credential: data?.credential ?? null,
              //     platform: data?.platform
              //   })
              //       .then(
              //           result => {
              //             dispatch(success(result))
              //             dispatch(applicationActions.fetchActivityFeedsAction())
              //           },
              //           error => {
              //             error && dispatch(authAction.setTokenExpired(error, failure));
              //             // dispatch(failure(error))
              //           }
              //       )
              // }
            },
            error => {
              error && dispatch(authAction.setTokenExpired(error, failure));
              // dispatch(failure(error));
            }
        );
  };

  function request() {
    return {type: deploymentConstants.CREATE_NEW_DEPLOYMENT_TEMPLATE_REQUEST}
  }

  function success(result) {
    return {type: deploymentConstants.CREATE_NEW_DEPLOYMENT_TEMPLATE_SUCCESS, result}
  }

  function failure() {
    return {type: deploymentConstants.CREATE_NEW_DEPLOYMENT_TEMPLATE_FAILURE}
  }
}

function createNewOrganizationTemplateAction(data) {
  return dispatch => {
    dispatch(request());
    deployments.addNewOrgTemplate(data)
        .then(
            result => {
              dispatch(success(result))
              dispatch(applicationActions.fetchActivityFeedsAction())
            },
            error => {
              error && dispatch(authAction.setTokenExpired(error, failure));
              // dispatch(failure(error))
            }
        )
  };

  function request() {
    return {type: deploymentConstants.CREATE_NEW_ORGANIZATION_TEMPLATE_REQUEST}
  }

  function success(result) {
    return {type: deploymentConstants.CREATE_NEW_ORGANIZATION_TEMPLATE_SUCCESS, result}
  }

  function failure() {
    return {type: deploymentConstants.REMOVE_CREATE_ORG_TEMPLATE_STATUS}
  }
}

function removeCreateNewTemplateStatus() {
  return dispatch => {
    dispatch({type: deploymentConstants.REMOVE_CREATE_TEMPLATE_STATUS})
  }
}

function removeCreateNewOrgTemplateStatus() {
  return dispatch => {
    dispatch({type: deploymentConstants.REMOVE_CREATE_ORG_TEMPLATE_STATUS})
  }
}

function removeApplyStatusAction() {
  return dispatch => {
    dispatch({type: deploymentConstants.REMOVE_APPLY_STATUS})
  }
}

function fetchTemplatesAction() {
  return dispatch => {
    dispatch(request());
    deployments.fetchTemplates()
        .then(
            templates => {
              dispatch(success(templates));
            },
            error => {
              error && dispatch(authAction.setTokenExpired(error, failure));
              // dispatch(failure(error));
            }
        );
  };

  function request() { return {type: deploymentConstants.FETCH_ORIGIN_TEMPLATES_REQUEST} }
  function success(templates) { return {type: deploymentConstants.FETCH_ORIGIN_TEMPLATES_SUCCESS, templates} }
  function failure(error) { return {type: deploymentConstants.FETCH_ORIGIN_TEMPLATES_FAILURE, error} }
}

function fetchTemplateVarsAction(template_id) {
  return dispatch => {
    dispatch(request(template_id));
    deployments.fetchTemplateVars(template_id)
        .then(
            vars => {
              dispatch(success(vars, template_id));
            },
            error => {
              if(error === 'invalid-token') {
                dispatch(authAction.setTokenExpired(error, failure));
              } else {
                dispatch(failure(error, template_id));
              }
            }
        );
  };

  function request(template_id) { return {type: deploymentConstants.FETCH_TEMPLATE_VARS_REQUEST, template_id} }
  function success(vars, template_id) { return {type: deploymentConstants.FETCH_TEMPLATE_VARS_SUCCESS, vars, template_id} }
  function failure(error, template_id) { return {type: deploymentConstants.FETCH_TEMPLATE_VARS_FAILURE, error, template_id} }
}

function clearTemplateVarsAction() {
  return dispatch => {
    dispatch(request())
  }
  function request() { return {type: deploymentConstants.CLEAR_TEMPLATE_VARS} }
}

function applyRemediationAction(app_id, remediation_id, data) {
  const type = "remediation_solution"
  const resource_type = data.attributes.resources[0]?.type.split('/')?.[0]
  return dispatch => {
    dispatch(request());
    deployments.applyRemediation(app_id, data)
        .then(
            result => {
              dispatch(success(result));
              dispatch(applicationActions.fetchActivityFeedsAction())
              showAlert('info', "Progress", `Processing remediation of ${app_id} ...`)
            },
            error => {
              dispatch(authAction.setTokenExpired(error, failure));
              showAlert('error', "Error", `${error} ...`)
              // dispatch(failure(error));
            }
        );
  };

  function request() {
    return {type: deploymentConstants.APPLY_REMEDIATION_REQUEST, loading: type, appId: app_id, remediation: remediation_id, resource_type}
  }

  function success(result) {
    return {type: deploymentConstants.APPLY_REMEDIATION_SUCCESS, task_id: result.task_id, appId: app_id, loading: type, remediation: remediation_id, resource_type}
  }

  function failure() {
    return {type: deploymentConstants.APPLY_REMEDIATION_FAILURE, loading: type, appId: app_id, remediation: remediation_id, resource_type}
  }
}
