import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'

import { Graph } from 'react-d3-graph'
import { config1 as graphConfig } from '../graph-configs'
import { applicationActions } from '../../../redux/actions'
import styled from 'styled-components'

const SGAssetsWrapper = styled.div`
    width: 1500px;
    height: 600px;
    background: #F8F9F9;
    margin: 10px 10px;
    border-radius: 4px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`

const app_id = 's8j968Tx50CCW0E351yN'
const sgAssetsIcons = {
  securitygroup: './images/SecurityGroup.png',
  anyip: './images/AnyIP.png',
  vpc: './images/VPC.png',
  accesskey: './images/IAMAccessKey.png'
}

const SGAssets = props => {
  // graph payload (with minimalist structure)
  const { sg_assets, fetchSGAssetsAction } = props

  const [sgAssetsData, setSGAssetsData] = useState(null)

  useEffect(() => {
    fetchSGAssetsAction(app_id)
  }, [])

  useEffect(() => {
    if (sg_assets[app_id]) {
      const sgAssetsResp = sg_assets[app_id].result ?? {}
      setSGAssetsData(() => ({
        nodes: sgAssetsResp.nodes.map(node => ({
          id: node.id ?? node.name,
          name: node.type === 'AccessKey' ? `${node.type ?? 'VPC'}: ****${node.id.substr(-4)}` : `${node.type ?? 'VPC'}: ${node.name ?? node.id}`,
          svg: sgAssetsIcons?.[(node.type ?? 'vpc')?.toLowerCase()]
        })),
        links: sgAssetsResp.links.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              t => t.source === value.source && t.target === value.target
            )
        )
      }))
    }
  }, [sg_assets])


  const onClickNode = function (nodeId) {
    console.log(`Clicked node ${nodeId}`)
  }

  const onClickLink = function (source, target) {
    console.log(`Clicked link between ${source} and ${target}`)
  }

  let myConfig = JSON.parse(JSON.stringify(graphConfig))

  myConfig.staticGraph = false
  myConfig.collapsible = props.settings.collapsible
  myConfig.directed = props.settings.directedGraph
  myConfig.initialZoom = 0.8

  myConfig.node.size = 300
  myConfig.node.fontSize = 10
  myConfig.node.highlightFontSize = 10

  myConfig.link.strokeWidth = 1.5
  myConfig.link.markerHeight = 4
  myConfig.link.markerWidth = 4
  myConfig.width = 1500
  myConfig.height = 600

  return (
    <>
      <SGAssetsWrapper>
        {sgAssetsData && (
          <Graph
            id='graph-user-assets' // id is mandatory
            data={sgAssetsData}
            config={myConfig}
            onClickNode={onClickNode}
            onClickLink={onClickLink}
          />
        )}
        {/* <Graph
            id='graph-user-assets' // id is mandatory
            data={graph_data}
            config={myConfig}
            onClickNode={onClickNode}
            onClickLink={onClickLink}
        /> */}
      </SGAssetsWrapper>
    </>
  )
}
function mapStateToProps (state) {
  const { sg_assets } = state.applications
  return {
    sg_assets
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchSGAssetsAction: app_id =>
      dispatch(applicationActions.fetchSGAssetsAction(app_id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SGAssets)
