import {users} from '../apis';
import {usersConstants} from '../constants';
import {
  organizationModificationAlert,
  organizationDeleteAlert,
  showAlert,
  userDeleteAlert,
  userModificationAlert,
  getCurrentUser
} from "../../helpers/constants";
import {authAction} from "./auth.actions";

export const usersActions = {
  fetchOrganizationListAction,
  fetchUserListAction,
  addUserAction,
  updateUserAction,
  clearUpdateUserFlag,
  clearCreateUserFlag,
  manageUserStatusAction,
  deleteUserAction,

  manageOrgStatusAction,
  deleteManageOrgAction,

  addOrganizationAction,
  clearCreateOrgFlag,
};

function fetchOrganizationListAction() {
  return dispatch => {
    dispatch(request());
    users.fetchOrganizations()
        .then(
            orgList => {
              dispatch(success(orgList));
            },
            error => {
              error && dispatch(authAction.setTokenExpired(error, failure));
            }
        );
  };

  function request() { return { type: usersConstants.FETCH_ORG_LIST_REQUEST } }
  function success(orgList) { return { type: usersConstants.FETCH_ORG_LIST_SUCCESS, orgList } }
  function failure(error) { return { type: usersConstants.FETCH_ORG_LIST_FAILURE, error } }
}

function fetchUserListAction() {
  return dispatch => {
    dispatch(request());
    users.fetchUsers()
        .then(
            userList => {
              dispatch(success(userList));
            },
            error => {
              error && dispatch(authAction.setTokenExpired(error, failure));
              // dispatch(failure(error));
            }
        );
  };

  function request() { return { type: usersConstants.FETCH_USER_LIST_REQUEST } }
  function success(userList) { return { type: usersConstants.FETCH_USER_LIST_SUCCESS, userList } }
  function failure(error) { return { type: usersConstants.FETCH_USER_LIST_FAILURE, error } }
}

function addUserAction(payload) {
  return dispatch => {
    dispatch(request());
    users.addUser(payload)
        .then(
            user => {
              // showAlert('success', 'Success', 'Successfully updated user.')
              dispatch(success(user));
              dispatch(fetchUserListAction())
            },
            error => {
              // showAlert('error', 'Error', 'Failed to update user.')
              error && dispatch(authAction.setTokenExpired(error, failure));
              // dispatch(failure(error));
            }
        );
  };

  function request() { return { type: usersConstants.CREATE_USER_REQUEST } }
  function success(user) { return { type: usersConstants.CREATE_USER_SUCCESS, user } }
  function failure(error) { return { type: usersConstants.CREATE_USER_FAILURE, error } }
}

function updateUserAction(id, payload) {
  return dispatch => {
    dispatch(request());
    users.updateUser(id, payload)
        .then(
            user => {
              showAlert('success', 'Success', 'Successfully updated user.')
              dispatch(success(user));
              dispatch(fetchUserListAction())
            },
            error => {
              showAlert('error', 'Error', 'Failed to update user.')
              error && dispatch(authAction.setTokenExpired(error, failure));
              // dispatch(failure(error));
            }
        );
  };

  function request() { return { type: usersConstants.UPDATE_USER_REQUEST } }
  function success(user) { return { type: usersConstants.UPDATE_USER_SUCCESS, user } }
  function failure(error) { return { type: usersConstants.UPDATE_USER_FAILURE, error } }
}

function manageUserStatusAction(id, action) {
  return dispatch => {
    dispatch(request());
    users.manageUserStatus(id, action)
        .then(
            user => {
              showAlert('success', 'Success', userModificationAlert[action]['success'])
              dispatch(fetchUserListAction())
              dispatch(success(user));
            },
            error => {
              showAlert('error', 'Error', userModificationAlert[action]['failure'])
              error && dispatch(authAction.setTokenExpired(error, failure));
            }
        );
  };

  function request() { return { type: usersConstants.MODIFY_USER_STATUS_REQUEST } }
  function success(user) { return { type: usersConstants.MODIFY_USER_STATUS_SUCCESS, user } }
  function failure(error) { return { type: usersConstants.MODIFY_USER_STATUS_FAILURE, error } }
}

function manageOrgStatusAction(name, action) {
  return dispatch => {
    dispatch(request());
    users.manageOrgStatus(name, action)
        .then(
            org => {
              showAlert('success', 'Success', organizationModificationAlert[action]['success'])
              dispatch(success(org));
            },
            error => {
              showAlert('error', 'Error', organizationModificationAlert[action]['failure'])
              error && dispatch(authAction.setTokenExpired(error, failure));
            }
        );
  };

  function request() { return { type: usersConstants.MODIFY_ORG_STATUS_REQUEST } }
  function success(organization) { return { type: usersConstants.MODIFY_ORG_STATUS_SUCCESS, organization } }
  function failure(error) { return { type: usersConstants.MODIFY_ORG_STATUS_FAILURE, error } }
}

function deleteUserAction(id, action) {
  return dispatch => {
    dispatch(request());
    users.deleteUser(id, action)
        .then(
            user => {
              showAlert('success', 'Success', userDeleteAlert[action]['success'])
              dispatch(fetchUserListAction())
              dispatch(success(user));
            },
            error => {
              showAlert('error', 'Error', userDeleteAlert[action]['failure'])
              error && dispatch(authAction.setTokenExpired(error, failure));
            }
        );
  };

  function request() { return { type: usersConstants.MODIFY_USER_STATUS_REQUEST } }
  function success(user) { return { type: usersConstants.MODIFY_USER_STATUS_SUCCESS, user } }
  function failure(error) { return { type: usersConstants.MODIFY_USER_STATUS_FAILURE, error } }
}

function deleteManageOrgAction(name, action) {
  return dispatch => {
    dispatch(request());
    users.deleteManageOrg(name, action)
        .then(
            org => {
              showAlert('success', 'Success', organizationDeleteAlert[action]['success'])
              dispatch(success(org));
            },
            error => {
              showAlert('error', 'Error', organizationDeleteAlert[action]['failure'])
              error && dispatch(authAction.setTokenExpired(error, failure));
            }
        );
  };

  function request() { return { type: usersConstants.MODIFY_ORG_STATUS_REQUEST } }
  function success(organization) { return { type: usersConstants.MODIFY_ORG_STATUS_SUCCESS, organization } }
  function failure(error) { return { type: usersConstants.MODIFY_ORG_STATUS_FAILURE, error } }
}

function addOrganizationAction(payload) {
  return dispatch => {
    dispatch(request());
    users.addOrganization(payload)
        .then(
            user => {
              // showAlert('success', 'Success', 'Successfully created new organization.')
              dispatch(success(user));
            },
            error => {
              // showAlert('error', 'Error', 'Failed to create organization.')
              error && dispatch(authAction.setTokenExpired(error, failure));
              // dispatch(failure(error));
            }
        );
  };

  function request() { return { type: usersConstants.CREATE_ORG_REQUEST } }
  function success(organization) { return { type: usersConstants.CREATE_ORG_SUCCESS, organization } }
  function failure(error) { return { type: usersConstants.CREATE_ORG_FAILURE, error } }
}

function clearUpdateUserFlag() {
  return dispatch => {
    dispatch({type: usersConstants.CLEAR_UPDATE_USER_FLAG})
  }
}

function clearCreateUserFlag() {
  return dispatch => {
    dispatch({type: usersConstants.CLEAR_CREATE_USER_FLAG})
  }
}

function clearCreateOrgFlag() {
  return dispatch => {
    dispatch({type: usersConstants.CLEAR_CREATE_ORG_FLAG})
  }
}
