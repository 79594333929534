import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { applicationActions } from "../../../redux/actions";
import { connect } from "react-redux";
import * as global from "../../../helpers/constants";
import StyledTypo from "../../../components/Typo/StyledTypo";
import InfraSummary from "./Infra/InfraSummary";
import RemediationPanel from "./Infra/RemediationPanel";
import { ReactComponent as LeftArrow } from "../../../assets/icons/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../../assets/icons/right-arrow.svg";
import { MainContext } from "../../../contexts";
import {
  ViewContainer,
  PageTitle,
  FlexComp,
} from "../../../components/PageLayout/Containers";
import {
  TabHeader,
  TabHeaderItem,
  TabBody,
} from "../../../components/PageLayout/Tab";
import { TableWrapper } from "../../../components/PageLayout/Table";
import { BackButton } from "../../../components/PageLayout/BackButton";
import { useTitle } from "../../../helpers/useTtitle";

const MoveCTAWrapper = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  // align-items: center;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  cursor: pointer;
`;

const InfraLayout = (props) => {
  const {
    appList,
    id: infra_id,
    clusterInfoLoading,
    trigger_infra,
    prowler_resources,
    fetchProwlerResourceAction,
    fetchCloudRemediationSolutionAction,
    cloud_solutions,
    fetchApplicationListAction
  } = props;

  const history = useHistory();
  const filterPanelRef = useRef();
  const headerRef = useRef();

  const { gInfras, setGInfras } = useContext(MainContext);

  const [tabs, setTabs] = useState(global.cloudTabList);
  const [selectedTab, setSelectedTab] = useState(null);

  const [, setUpdateProject] = useState(null);
  const [selectedFilterType, setSelectedFilterType] = useState(null);
  const [preFilterData, setPreFilterData] = useState();
  const [, setRenderFlag] = useState(false);
  const [pageTitle, setPageTitle] = useState();

  const location = useLocation();

  const [selectedApp, setSelectedApp] = useState(null);

  useTitle(pageTitle);

  useEffect(() => {
    setSelectedTab(
      location?.search
        ? new URLSearchParams(location?.search).get("tab")
        : tabs.filter((item) => item.active)?.[0]?.key
    );
  }, [setSelectedTab]);

  useEffect(() => {
    if(appList.length === 0) {
      fetchApplicationListAction();
    }
  }, [appList])

  useEffect(() => {
    setPageTitle(`${global.commonTitle} - Cloud Security - CSPM - ${selectedApp?.name}`)
  }, [selectedApp])

  const handleSelectedTab = (key) => {
    setPreFilterData(null);
    setTabs([...tabs.map((tab) => ({ ...tab, active: tab.key === key }))]);
  };

  useEffect(() => {
    const selectedInfra = gInfras.filter(
      (project) => project.id === infra_id
    )?.[0];
    if (selectedInfra) {
      selectedApp?.id !== infra_id && setSelectedApp({ ...selectedInfra });
    } else if(appList.length >= gInfras.length){
      setGInfras([...appList])
    }
    setUpdateProject(appList.filter((project) => project.id === infra_id)?.[0]);
  }, [appList, infra_id, gInfras]);

  const onLink = (data, tab = "details") => {
    handleSelectedTab(tab);
    setPreFilterData({ ...data });
  };

  const findIndex = () => {
    return gInfras.findIndex((project) => project.id === infra_id);
  };

  const findId = (up = true) => {
    const idx = findIndex();
    const targetIdx = up ? idx + 1 : idx - 1;
    return gInfras.filter((project, idx) => idx === targetIdx)?.[0]?.id;
  };

  useEffect(() => {
    if (selectedApp?.id) {
      fetchProwlerResourceAction(selectedApp?.id);
      fetchCloudRemediationSolutionAction(selectedApp?.id);
    }
  }, [selectedApp]);

  return (
    <ViewContainer>
      <div className="main-container d-flex flex-column">
        <PageTitle
          className="pb-2 d-flex align-items-center justify-content-between"
          ref={headerRef}
        >
          <StyledTypo className="ml-2 heading">
            <BackButton
              onClick={() => history.push("/cloud")}
              title="Back"
              href="#"
            >
              {" "}
              Cloud Security{" "}
            </BackButton>{" - "}
            <BackButton
              onClick={() => history.push("/cspm")}
              title="Back"
              href="#"
            >
              {" "}
              CSPM{" "}
            </BackButton>{" "}
            - <strong>{selectedApp?.name}</strong>
          </StyledTypo>
          <TabHeader>
            {tabs.map((tab) => (
              <TabHeaderItem
                className={tab.active && "active"}
                onClick={() => handleSelectedTab(tab.key)}
              >
                {tab.title}
              </TabHeaderItem>
            ))}
          </TabHeader>
          <FlexComp gap="20px">
            {!!tabs.filter((tab) => tab.key === "details" && tab.active)
              .length && filterPanelRef?.current?.getFilterPanel()}
            <FlexComp gap="0px">
              <StyledTypo className="mr-2">
                {findIndex() + 1} of {gInfras.length}
              </StyledTypo>
              <MoveCTAWrapper
                disabled={findIndex() === 0}
                onClick={() => history.push(`/cspm/${findId(false)}`)}
              >
                <LeftArrow fill={findIndex() === 0 ? "#A4AFB7" : "#505050"} />
              </MoveCTAWrapper>
              <MoveCTAWrapper
                disabled={findIndex() + 1 === gInfras.length}
                onClick={() => history.push(`/cspm/${findId()}`)}
              >
                <RightArrow
                  fill={
                    findIndex() + 1 === gInfras.length ? "#A4AFB7" : "#505050"
                  }
                />
              </MoveCTAWrapper>
            </FlexComp>
          </FlexComp>
        </PageTitle>
        <TableWrapper
          marginTop={`${
            headerRef?.current?.offsetHeight + headerRef?.current?.offsetTop ||
            100
          }px`}
        >
          <TabBody>
            {!!tabs.filter((tab) => tab.key !== "details" && tab.active)
              .length && (
              <InfraSummary
                remediations={cloud_solutions?.[selectedApp?.id]?.results ?? []}
                solution={cloud_solutions?.[selectedApp?.id]?.results ?? []}
                selectedApp={selectedApp ?? {}}
                clusterInfo={prowler_resources?.[selectedApp?.id] ?? []}
                clusterInfoLoading={clusterInfoLoading}
                hideActivity={true}
                triggerInfra={trigger_infra}
                setSelectedFilterType={setSelectedFilterType}
                type={
                  tabs.filter((tab) => tab.key !== "details" && tab.active)?.[0]
                    ?.key
                }
                onLink={onLink}
              />
            )}
            {!!tabs.filter((tab) => tab.key === "details" && tab.active)
              .length && (
              <RemediationPanel
                remediations={cloud_solutions?.[selectedApp?.id]?.results ?? []}
                solution={cloud_solutions?.[selectedApp?.id]}
                selectedApp={selectedApp ?? {}}
                clusterInfo={prowler_resources?.[selectedApp?.id] ?? []}
                setSelectedFilterType={setSelectedFilterType}
                selectedFilterType={selectedFilterType}
                preFilterData={preFilterData}
                ref={filterPanelRef}
                setRenderFlag={setRenderFlag}
              />
            )}
          </TabBody>
        </TableWrapper>
      </div>
    </ViewContainer>
  );
};

function mapStateToProps(state) {
  const {
    clusterInfo,
    clusterInfoLoading,
    envData,
    appListLoading,
    solution,
    trigger_infra,
    appList,
    remediations,
    prowler_resources,
    prowler_remediations,
    cloud_solutions,
  } = state.applications;
  return {
    appList,
    clusterInfo,
    clusterInfoLoading,
    envData,
    appListLoading,
    solution,
    trigger_infra,
    remediations,
    prowler_resources,
    prowler_remediations,
    cloud_solutions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchClusterDetailsAction: (app_id, realtimeFlag) =>
      dispatch(
        applicationActions.fetchClusterDetailsAction(app_id, realtimeFlag)
      ),
    fetchApplicationListAction: () =>
      dispatch(applicationActions.fetchApplicationListAction()),
    addApplicationAction: (data) =>
      dispatch(applicationActions.addApplicationAction(data)),
    resetAddAppStatusAction: () =>
      dispatch(applicationActions.resetAddAppStatusAction()),
    resetDeleteAppStatusAction: () =>
      dispatch(applicationActions.resetDeleteAppStatusAction()),
    deleteApplicationAction: (app_id) =>
      dispatch(applicationActions.deleteApplicationAction(app_id)),
    fetchSolutionsAction: (data) =>
      dispatch(applicationActions.fetchSolutionsAction(data)),
    fetchRemediationListAction: (platform) =>
      dispatch(applicationActions.fetchRemediationListAction(platform)),
    fetchProwlerResourceAction: (app_id) =>
      dispatch(applicationActions.fetchProwlerResourceAction(app_id)),
    fetchProwlerRemediationAction: (app_id) =>
      dispatch(applicationActions.fetchProwlerRemediationAction(app_id)),
    fetchCloudRemediationSolutionAction: (app_id) =>
      dispatch(applicationActions.fetchCloudRemediationSolutionAction(app_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InfraLayout);
