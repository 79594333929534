import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  authAction,
} from "../../redux/actions";
import { connect } from "react-redux";
import { asyncLocalStorage } from "../../helpers/constants";

const Index = (props) => {
  const { setLoggedInUserAction } = props;
  const history = useHistory();
  useEffect(() => {
    let user_token = window?.location?.search
      ? new URLSearchParams(window?.location?.search).get("user-token")
      : null;
    let current_user = window?.location?.search
      ? new URLSearchParams(window?.location?.search).get("current-user")
      : null;
    user_token = user_token ? atob(user_token) : null;
    current_user = current_user ? atob(current_user) : null;
    // localStorage.setItem('user_token', user_token)
    asyncLocalStorage.setItem("user_token", user_token).then(function () {
      localStorage.setItem("current-user", current_user);
      if (current_user) {
        setLoggedInUserAction(JSON.parse(current_user));
        localStorage.setItem(
          "subscription",
          JSON.stringify({
            subscription: JSON.parse(current_user)?.organization?.subscription,
          })
        );
      } else {
        localStorage.removeItem("subscription");
      }
      history.push("/dashboard");
    });
  }, []);

  return <div />;
};

function mapDispatchToProps(dispatch) {
  return {
    setLoggedInUserAction: (loggedInUser) =>
      dispatch(authAction.setLoggedInUserAction(loggedInUser)),
  };
}

export default connect(undefined, mapDispatchToProps)(Index);
