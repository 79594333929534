import styled from "styled-components";

const StyledTypo = styled.p`
  color: ${(props) => props.color ?? "#505050"};
  font-size: ${(props) => (props.size ? `${props.size}rem` : "0.9rem")};
  font-weight: ${(props) => props.fontWeight ?? "300"};
  font-family: ${(props) => props.family ?? `inherit`};
  letter-spacing: ${(props) =>
    props.letter ? `${props.letter}em` : "inherit"};
  line-height: ${(props) => (props.line ? props.line : "1.2")};
  margin: 0;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : 0)}px;

  opacity: ${(props) => (props.opacity ? props.opacity : 1)};

  &:hover {
    color: ${(props) => props.hoverColor};
  }

  &.heading {
    font-family: Poppins;
    line-height: 1;
  }

  &.label {
    font-weight: 400;
  }

  &.table-label {
    font-size: 0.75rem;
  }

  &.pagination-label {
    font-family: Poppins;
    font-weight: 400;
    font-size: 0.8rem;
  }

  &.ln-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    height: 2.8em;
    line-height: 1.4em;
  }
`;

export default StyledTypo;
