import { dashboardConstants } from "../constants";

const initialState = {
  cloud_data: [],
  iac_data: {},
  loading_summary: false,
  compliance_infralist: [],
  resource_infralist: [],
};

export function dashboard(state = initialState, action) {
  switch (action.type) {
    case dashboardConstants.FETCH_DASHBOARD_CLOUD_SECURITY_SUCCESS:
      return {
        ...state,
        cloud_data: action.cloud_data,
      };
    case dashboardConstants.FETCH_DASHBOARD_CODE_SECURITY_SUCCESS:
      return {
        ...state,
        iac_data: action.iac_data,
      };
    case dashboardConstants.FETCH_COMPLIANCE_INFRALIST_REQUEST:
      return {
        ...state,
        loading_summary: true,
      };
    case dashboardConstants.FETCH_COMPLIANCE_INFRALIST_SUCCESS:
      return {
        ...state,
        loading_summary: false,
        compliance_infralist: action.summary_data,
      };
    case dashboardConstants.FETCH_COMPLIANCE_INFRALIST_FAILURE:
      return {
        ...state,
        loading_summary: false,
      };
    case dashboardConstants.FETCH_RESOURCE_INFRALIST_REQUEST:
      return {
        ...state,
        loading_summary: true,
      };
    case dashboardConstants.FETCH_RESOURCE_INFRALIST_SUCCESS:
      return {
        ...state,
        loading_summary: false,
        resource_infralist: action.summary_data,
      };
    case dashboardConstants.FETCH_RESOURCE_INFRALIST_FAILURE:
      return {
        ...state,
        loading_summary: false,
      };
    default:
      return { ...state };
  }
}
