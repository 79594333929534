import React from "react";
import { connect } from "react-redux";
import Page from "../../components/PageLayout/Page";
import TrendsLayout from "./trends/TrendsLayout";

const Trends = () => {
  return (
    <div>
      <Page>
        <TrendsLayout />
      </Page>
    </div>
  );
};

export default Trends;
