
const initialState = {

}

export function manages(state = initialState, action) {
  switch (action.type) {

    default:
      return { ...state }
  }
}
