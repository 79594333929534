import React from 'react'
import Page from "../../components/PageLayout/Page";
import DeployLayout from "./deploy/DeployLayout";

const Deploy = (props) => {
  return (<div>
    <Page>
      <DeployLayout />
    </Page>
  </div>)
}

export default Deploy;