import React, { useCallback, useState } from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
  LabelList,
} from "recharts";
import CustomizedAxisTick from "./CustomizedAxisTick";
import CustomizedRectLabel from "./CustomizedRectLabel";
import styled from "styled-components";

const TooltipWrapper = styled.div`
  background: #5eb1e4;
  //border: 2px solid #F1F5FA;
  box-sizing: border-box;
  border-radius: 10px;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
  max-width: 300px;
  padding: 15px;
  color: #f1f1f1;
`;

const OneBarChart = (props) => {
  const { color, dateArr, id, summaryData } = props;
  const [selIndex, setSelIndex] = useState(null);

  const enterSelection = useCallback(
    (data, index) => {
      if (selIndex === index) return;

      setSelIndex(index);
    },
    [selIndex]
  );

  const leaveSelection = useCallback(() => {
    setSelIndex(null);
  }, [setSelIndex]);

  const CustomizedOneBarToolTip = (props) => {
    const { label, payload } = props;
    return (
      <TooltipWrapper>
        {summaryData ? summaryData[label]?.name : label}:{" "}
        {payload?.[0]?.value ?? 0}%
      </TooltipWrapper>
    );
  };

  return (
    <ResponsiveContainer>
      <BarChart
        data={props.data}
        // style={{padding: '30px 60px'}}
        margin={{ top: 15 }}
      >
        <defs>
          <linearGradient id={`fill-${id}-100`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#66C4D5" stopOpacity={0.9} />
            <stop offset="30%" stopColor="#5EB1E4" stopOpacity={0.7} />
            <stop offset="80%" stopColor="#FFBA00" stopOpacity={0.8} />
            <stop offset="100%" stopColor="#ff487f" stopOpacity={0.7} />
          </linearGradient>
          <linearGradient id={`fill-${id}-80`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="30%" stopColor="#5EB1E4" stopOpacity={0.7} />
            <stop offset="80%" stopColor="#FFBA00" stopOpacity={0.8} />
            <stop offset="100%" stopColor="#ff487f" stopOpacity={0.7} />
          </linearGradient>
          <linearGradient id={`fill-${id}-60`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="80%" stopColor="#FFBA00" stopOpacity={0.8} />
            <stop offset="100%" stopColor="#ff487f" stopOpacity={0.7} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="name"
          stroke="#505050"
          tickLine={false}
          tick={
            <CustomizedAxisTick
              dateStr={dateArr}
              summaryData={summaryData}
              appName
            />
          }
          height={70}
        ></XAxis>
        <YAxis
          stroke="#505050"
          dx={-10}
          axisLine={false}
          tickLine={false}
          tick={<CustomizedAxisTick dateStr={dateArr} yAxis neg />}
          tickCount={props.yTicks ? props.yTicks.length : 1}
          ticks={props.yTicks}
        ></YAxis>
        <Tooltip cursor={false} content={<CustomizedOneBarToolTip />} />
        <CartesianGrid vertical={false} />
        {/*<ReferenceLine*/}
        {/*    y={0}*/}
        {/*    stroke="#505050"*/}
        {/*    strokeWidth={1}*/}
        {/*    // label={{value: 'STOP BAR', fill: 'white'}}*/}
        {/*/>*/}
        <Bar
          isAnimationActive={true}
          dataKey="data1"
          fill={`url(#fill-${id})`}
          barSize={40}
          onMouseEnter={enterSelection}
          onMouseLeave={leaveSelection}
        >
          {/*<LabelList*/}
          {/*    content={(props) => CustomizedTooltip({selIndex: selIndex, color: color, ...props})}/>*/}
          <LabelList
            dataKey="green"
            content={(props) => CustomizedRectLabel({ fill: color, ...props })}
          />
          {props.data.map((item, idx) => (
            <Cell
              key={`cell-${idx}`}
              fill={
                item.data1 <= 20
                  ? "#61bf92"
                  : item.data1 < 80
                  ? `#FFBA00`
                  : `#ff487f`
              }
            />
          ))}
          {/*<LabelList*/}
          {/*    dataKey="green"*/}
          {/*    content={(props) => CustomizedCircleLabel({fill: color, selIndex: selIndex, ...props})}/>*/}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default OneBarChart;
