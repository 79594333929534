import React from "react";
import styled from "styled-components";

import NotificationsTable from "./NotificationsTable";
import { getCurrentOrganization } from "../../../helpers/constants";

const PanelWrapper = styled.div`
  padding: 15px;
  width: 100%;

  .panel-title-header {
    padding: 10px 26px;
    display: flex;
    flex-direction: reverse;
    justify-content: space-between;
    align-items: center;
  }
`;

const NotificationsPanel = (props) => {
  const { logs, category, loading_log } = props;

  return (
    <PanelWrapper>
      <div>
        <NotificationsTable
          data={logs?.[getCurrentOrganization()] ?? {}}
          category={category}
          loading_log={loading_log}
        />
      </div>
    </PanelWrapper>
  );
};

export default NotificationsPanel;
