import { authHeader, handleResponse } from "../../helpers";
import * as global from "../../helpers/constants";

export const dashboard = {
  fetchCloudSecurityData,
  fetchCodeSecurityData,
  fetchComplianceInfrastructureList,
  fetchResourceInfrastructureList,
};

function fetchCloudSecurityData() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${global.getBaseURL()}/app/dashboard/cloud-security?org_id=${global.getCurrentOrganization()}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchCodeSecurityData() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${global.getBaseURL()}/app/dashboard/code-security-data?org_id=${global.getCurrentOrganization()}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchComplianceInfrastructureList(provider, compliance) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${global.getBaseURL()}/app/infrastructure/summary/compliance?org_id=${global.getCurrentOrganization()}&provider=${provider}&code=${compliance}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function fetchResourceInfrastructureList(provider, resource) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${global.getBaseURL()}/app/infrastructure/summary/resource?org_id=${global.getCurrentOrganization()}&provider=${provider}&resource_type=${resource}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}