import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import StatusMark from "../../partisals/StatusMark";
import * as global from '../../../helpers/constants'

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  width: 100%;
`

const ObservationItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 5px;
`

const TextWrapper = styled.div`
  //width: 60%;
  margin-left: 12px;
`

const ObservationEachPart = styled.div`
  margin-top: 50px;
`

const ObservationContentPart = styled.div`
  margin-top: 10px;
  max-height: 300px;
  overflow-y: auto;
`

const ContentWrapper = styled.div`
  margin-left: 22px;
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 150px;
`

const Observation = (props) => {
  const {resources, clusterInfoLoading} = props
  const [observationData, setObservationData] = useState({
    instances: [],
    clusters: []
  })

  useEffect(() => {
    if (resources) {
      setObservationData({
        instances:
            resources?.instance?.map(vm => ({
              status: vm.self?.status === "Running" ? 2 : 1,
              name: vm.self?.display_name ?? vm.self?.instance_id,
              passTime: '0h',
              content: vm.self?.zone ? vm.self?.zone?.split('/')[vm.self?.zone?.split('/').length - 1] : vm.self?.region
            })),
        clusters: resources?.['cluster']?.map(cluster => ({
              status: cluster.self?.status === "RUNNING" ? 2 : 1,
              name: cluster.self?.display_name,
              passTime: '0h',
              content: cluster.self?.region
            }))
      })

    }
  }, [resources])

  return (
      <div>
        <span className="font-size-36 font-weight-bold m-gray">Observation</span>
        <ItemWrapper>
          {clusterInfoLoading ?
              <LoaderWrapper>{global.loadingBar('MutatingDots')}</LoaderWrapper> :
              Object.keys(observationData).map(key => (
                  <ObservationEachPart>
                    <span className="font-size-26 font-weight-bold m-gray text-capitalize mb-3">{key}</span>
                    <ObservationContentPart>
                      {observationData[key]?.map((item, idx) => (
                          <div>
                            <ObservationItem key={idx}>
                              <div className="d-flex">
                                <div className="mt-2">
                                  <StatusMark size={10} color={global.colors.status[item.status - 1]}/>
                                </div>
                                <TextWrapper>
                                  <span className="font-weight-bold font-size-16 m-dark-gray">{item.name}</span>
                                </TextWrapper>
                              </div>
                              <span className="font-weight-normal font-size-14 m-dark-gray-60">{item.passTime}</span>
                            </ObservationItem>
                            <ContentWrapper>
                              <p className="font-weight-lighter font-size-18 m-dark-gray">{item.content}</p>
                            </ContentWrapper>
                          </div>
                      ))}
                    </ObservationContentPart>

                  </ObservationEachPart>
              ))}
        </ItemWrapper>
      </div>
  )
}

export default Observation;
