import React, { useState, useEffect } from 'react'
import { applicationActions, authAction } from '../../../redux/actions'
import { connect } from 'react-redux'
import StyledTypo from '../../../components/Typo/StyledTypo'
import { Row, Col } from 'reactstrap'
import {
  FlexComp
} from '../../../components/PageLayout/Containers'
import styled from 'styled-components'
import { getCurrentUser, showAlert } from '../../../helpers/constants'
import moment from 'moment'
import CommonInput from '../../../components/Input/CommonInput'
import EffectedButton from "../../../components/Buttons/EffectedButton";
import { confirmAlert } from "react-confirm-alert";


const PanelWrapper = styled.div`
  padding: 15px;
  width: 100%;
  // border: 1px solid #f1f1f1;
  // box-sizing: border-box;
  // border-radius: 0 20px 20px 20px;

  .panel-title-header {
    padding: 0 0 47px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const PanelHeader = styled.div`
  padding: 20px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PageTitle = styled.div`
  // background: #fff;
  border-radius: 5px;
  // border: 1px solid rgb(234, 234, 234);
  // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.075);
  margin-top: 20px;
  min-height: 64px;
  padding-left: 24px;
  padding: 12px;
  padding: 12px 0px;
  position: relative;
  z-index: 100;
  flex-wrap: wrap;
  row-gap: 10px;

  .page-menu {
    font-family: Poppins;
    gap: 10px;
    left: calc(100% - ${props => props.menuLeft ?? '920px'});
    position: fixed;
  }
`

const SummaryItemContainer = styled.div`
  border-top: ${props => (props.idx === 0 ? 'none' : '1px solid #f1f1f1')};
  padding: 10px;
  cursor: ${props => (props.cursor ? props.cursor : 'default')};
  ${props =>
    props.hover &&
    `
    &:hover {
      background-color: #f7f7f7;
    }
  `}
`

const InputWrapper = styled.div`
    width: 280px;
    min-width: 250px; 
    padding-inline: 10px;
`;

const userSummary = [
  [
    { key: 'first_name', label: 'First Name' },
    { key: 'last_name', label: 'Last Name' }
  ],
  [
    { key: 'email', label: 'Email Address' },
    { key: 'phone', label: 'Phone' }
  ],
  [
    { key: 'role', label: 'Role' },
  ]
]


const ProfilePanel = (props) => {
  const { changePasswordAction, clearChangePasswordFlag, change_password_flag } = props
  const currentUser = getCurrentUser();

  const [pwdPayload, setPwdPayload] = useState({ email: currentUser.email });

  useEffect(() => {
    if(change_password_flag) {
      setPwdPayload({ email: currentUser.email, old_password: "", new_password: "", confirm_password: "" })
      clearChangePasswordFlag()
    }
  }, [change_password_flag])

  const validated = () => {
    let msg = ''
    if(!pwdPayload?.old_password?.length) {
      msg = "Please fill Old Password."
    } else if(!pwdPayload?.new_password?.length) {
      msg = "Please fill New Password"
    } else if(pwdPayload?.new_password === pwdPayload?.old_password) {
      msg = "New Password must not be same as Old Password."
    } else if(pwdPayload?.new_password?.length < 8) {
      msg = "New password must be 8 characters at least."
    } else if(pwdPayload?.new_password !== pwdPayload?.confirm_password) {
      msg = "Confirm Password must be same as New Password."
    }
    if(msg.length) {
      showAlert('warning', "Invalid input", msg)
      return false
    }
    return true
  }

  const handleSubmit = () => {
    if(!validated()) return
    changePasswordConfirm()
  }

  const changePasswordConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <div className='custom-ui'>
              <h1>Are you sure?</h1>
              <p>You want to change password?</p>
              <button onClick={onClose}>No</button>
              <button
                  onClick={() => {
                    changePasswordAction(pwdPayload)
                    onClose();
                  }}
              >
                Confirm
              </button>
            </div>
        );
      }
    });
  };

  return (
    <PanelWrapper>
      <div className="d-flex flex-column">
        <PageTitle className="d-flex align-items-center justify-content-between">
          <FlexComp>
            <StyledTypo className="ml-2 heading">
              Personal Details
            </StyledTypo>
          </FlexComp>
        </PageTitle>
        <div className="mb-1">
          {userSummary.map((item, idx) => (
            <SummaryItemContainer key={idx} idx={idx}>
              <Row className="align-items-center">
                {item.map((each) => (
                  <Col md={6}>
                    <Row className="p-0 align-items-center">
                      <Col md={4}>
                        <StyledTypo className="text-justify label table-label">
                          {each.label}
                        </StyledTypo>
                      </Col>
                      <Col md={8}>
                        <StyledTypo className="text-justify table-label">
                          {currentUser?.[each.key]
                            ? each.type === "date"
                              ? moment(currentUser?.[each.key]).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : each.type === "array"
                              ? currentUser?.[each.key].join(", ")
                              : each.type === "upper_string"
                              ? currentUser?.[each.key].toUpperCase()
                              : currentUser?.[each.key]
                            : "---"}
                        </StyledTypo>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </SummaryItemContainer>
          ))}
        </div>
        <PageTitle className="d-flex align-items-center justify-content-between">
          <StyledTypo className="ml-2 heading">
            Change Password
          </StyledTypo>
        </PageTitle>
        <div className="mb-1">
          <InputWrapper>
            <CommonInput
              label="Old Password"
              required
              inputType="password"
              value={pwdPayload?.old_password}
              setValue={val => setPwdPayload(pwdPayload => ({
                ...pwdPayload,
                old_password: val
              }))}
              active={false}
              locked={false}
              placeholder=""
            />
          </InputWrapper>
          <InputWrapper>
            <CommonInput
              label="New Password"
              inputType="password"
              required
              value={pwdPayload?.new_password}
              setValue={val => setPwdPayload(pwdPayload => ({
                ...pwdPayload,
                new_password: val
              }))}
              active={false}
              locked={false}
              placeholder=""
            />
          </InputWrapper>
          <InputWrapper>
            <CommonInput
              label="Confirm Password"
              required
              inputType="password"
              value={pwdPayload?.confirm_password}
              setValue={val => setPwdPayload(pwdPayload => ({
                ...pwdPayload,
                confirm_password: val
              }))}
              active={false}
              locked={false}
              placeholder=""
            />
          </InputWrapper>
          <InputWrapper>
            <EffectedButton
              padding={20}
              height={32}
              noSpan
              marginLeft="0px"
              onClick={handleSubmit}
              // background="#5EB1E4"
              width={100}
            >
              <StyledTypo color="#fff" className="label">
                Change
              </StyledTypo>
            </EffectedButton>
          </InputWrapper>
        </div>
      </div>
    </PanelWrapper>
  );
};

function mapStateToProps (state) {
  const { change_password_flag } = state.auth
  return {
    change_password_flag
  }
}

function mapDispatchToProps (dispatch) {
  return {
    changePasswordAction: (data) =>
      dispatch(authAction.changePasswordAction(data)),
    clearChangePasswordFlag: () => 
      dispatch(authAction.clearChangePasswordFlag()),
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePanel);
