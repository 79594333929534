import React, { useState } from 'react'
import UserAssets from './analysis/UserAssets'
import styled from 'styled-components'
import StyledTypo from '../../components/Typo/StyledTypo'

const GraphWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  padding: 50px 20px;
`

const APAGPreview = () => {
  const [staticGraph, setStaticGraph] = useState(true)
  const [panAndZoom, setPanAndZoom] = useState(true)
  const [collapsible, setCollapsible] = useState(false)
  const [directedGraph, setDirectedGraph] = useState(true)

  return (
    <GraphWrapper>
      <StyledTypo size={2}>
        User Assets
      </StyledTypo>
      <UserAssets
        settings={{ staticGraph, panAndZoom, collapsible, directedGraph }}
      />
    </GraphWrapper>
  )
}

export default APAGPreview;
