import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, DropdownMenu } from "reactstrap";

import * as global from "../../../helpers/constants";
import { applicationActions, deploymentActions } from "../../../redux/actions";
import { connect } from "react-redux";
import StyledTypo from "../../../components/Typo/StyledTypo";
import EffectedButton from "../../../components/Buttons/EffectedButton";

import { ReactComponent as DownArrowIcon } from "../../../assets/icons/down_arrow_icon.svg";
import { ReactComponent as CirclePlusIcon } from "../../../assets/icons/circle_add_icon.svg";
import { ReactComponent as SlideLeftIcon } from "../../../assets/icons/circle_arrow_left_icon.svg";
import { ReactComponent as SlideRightIcon } from "../../../assets/icons/circle_arrow_right_icon.svg";

import DisplayDetailDeployment from "./DisplayDetailDeployment";
import DisplayTableDeployment from "./DisplayTableDeployments";
import DropdownIcon from "../../../components/Buttons/DropdownIcon";
import StyledDropdown from "../../../components/Buttons/StyledDropdown";
import AddDeploymentPanel from "../../modals/AddDeploymentPanel";
import {
  cspData,
  getCurrentOrganization,
  showAlert,
  templateTypeList,
} from "../../../helpers/constants";
import AddTemplatePanel from "../../modals/AddTemplatePanel";

const PanelWrapper = styled.div`
  padding: 42.5px 46.5px 42.5px 37.5px;
  width: 100%;
  min-height: 80vh;

  /* white */

  background: #ffffff;
  /* Gray light 2 */

  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  border-radius: 20px;

  .panel-title-header {
    padding: 0 0 47px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const CategoryDropdownMenu = styled(DropdownMenu)`
  // --width: ${(props) => props.width - 350}px;
  // left: calc(var(--width) - 50px - 1vw) !important;
  /* white */
  width: 200px;
  padding: 20px 25px 15px;
  background: #ffffff;
  /* Gray light 2 */

  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  /* box shadow */

  //box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 10px;
  transform: translate3d(0px, 43px, 0px) !important;
`;

const CSPDropdownMenu = styled(DropdownMenu)`
  // --width: ${(props) => props.width - 350}px;
  // left: calc(var(--width) - 50px - 1vw) !important;
  /* white */
  width: 130px;
  padding: 20px 25px 15px;
  background: #ffffff;
  /* Gray light 2 */

  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  min-width: 130px;
  /* box shadow */

  //box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 10px;
  transform: translate3d(0px, 43px, 0px) !important;
`;

const DeploymentsPanel = (props) => {
  const {
    templates,
    fetchOrgTemplatesAction,
    createTemplateStatus,
    createNewDeploymentTemplate,
    clearCreateTemplateStatus,
    fetchApplicationList,
    appList,
    fetchTemplatesAction,
    originTemplates,
    createNewOrganizationTemplateAction,
    createOrgTemplateStatus,
    clearCreateOrgTemplateStatus,
    templateVars,
    fetchTemplateVarsAction,
    clearTemplateVarsAction,
    expired_token,
    new_refresh_token,
  } = props;
  const [deploymentsData, setDeploymentData] = useState([]);
  const [addDeployModal, setAddDeployModal] = useState(false);
  const [addTemplateModal, setAddTemplateModal] = useState(false);
  const [selectTypeModal, setSelectTypeModal] = useState(false);
  const [selectCspModal, setSelectCspModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(0);
  const [selectedTemplateType, setSelectedTemplateType] = useState(
    templateTypeList[0]
  );
  const [selectedCsp, setSelectedCsp] = useState(cspData[1]);

  const nextDeployOrder = () => {
    if (currentOrder + 4 >= deploymentsData.length) return;
    setCurrentOrder(currentOrder + 1);
  };

  const prevDeployOrder = () => {
    if (currentOrder <= 0) return;
    setCurrentOrder(currentOrder - 1);
  };

  const toggleDeployModal = () =>
    setAddDeployModal((addDeployModal) => !addDeployModal);
  const toggleTemplateModal = () => {
    setAddTemplateModal((addTemplateModal) => !addTemplateModal);
    setAddDeployModal(false);
  };
  const toggleTypeModal = () =>
    setSelectTypeModal((selectTypeModal) => !selectTypeModal);

  useEffect(() => {
    fetchApplicationList();
    fetchOrgTemplatesAction();
    fetchTemplatesAction();
  }, [fetchOrgTemplatesAction, fetchApplicationList, fetchTemplatesAction]);

  useEffect(() => {
    if (!expired_token && new_refresh_token) {
      fetchApplicationList();
      fetchOrgTemplatesAction();
      fetchTemplatesAction();
    }
  }, [
    expired_token,
    new_refresh_token,
    fetchOrgTemplatesAction,
    fetchApplicationList,
    fetchTemplatesAction,
  ]);

  useEffect(() => {
    setDeploymentData([
      ...templates.filter(
        (item) =>
          !!item.template.type.filter(
            (each) => each === selectedTemplateType.toLowerCase()
          ).length &&
          !!appList.filter(
            (app) =>
              app.id === item.application_id &&
              app.platform === selectedCsp?.key
          ).length
      ),
    ]);
  }, [templates, selectedTemplateType, selectedCsp, appList]);

  useEffect(() => {
    setCurrentOrder(0);
  }, [selectedTemplateType, selectedCsp]);

  useEffect(() => {
    if (createTemplateStatus === 1) {
      // success to create
      showAlert(
        "success",
        "Created new deployment",
        "Successfully create new template."
      );
      toggleTemplateModal();
      fetchTemplatesAction();
    } else if (createTemplateStatus === 2) {
      // fail to create
      showAlert(
        "error",
        "Created new template",
        "Failed to create new template."
      );
    }
    if (createTemplateStatus) {
      clearCreateTemplateStatus();
    }
  }, [createTemplateStatus, clearCreateTemplateStatus, fetchTemplatesAction]);

  useEffect(() => {
    if (createOrgTemplateStatus === 1) {
      // success to create
      showAlert(
        "success",
        "Created new Deployment",
        "Successfully create new deployment."
      );
      toggleDeployModal();
      fetchOrgTemplatesAction();
      fetchTemplatesAction();
    } else if (createOrgTemplateStatus === 2) {
      // fail to create
      showAlert(
        "error",
        "Created new deployment",
        "Failed to create new deployment."
      );
    }
    if (createOrgTemplateStatus) {
      clearCreateOrgTemplateStatus();
    }
  }, [
    createOrgTemplateStatus,
    clearCreateOrgTemplateStatus,
    fetchOrgTemplatesAction,
    fetchTemplatesAction,
  ]);

  return (
    <PanelWrapper>
      <div className="panel-title-header">
        <StyledTypo
          color="#50505099"
          size={1.625}
          line={36.4 / 26}
          letter="0"
          family="Roboto"
          fontWeight={700}
        >
          Deployments
        </StyledTypo>
        <div className="d-flex">
          <div className="mr-3">
            <StyledDropdown
              isOpen={selectCspModal}
              toggle={() =>
                setSelectCspModal((selectCspModal) => !selectCspModal)
              }
            >
              <DropdownIcon>
                <EffectedButton
                  background={global.colors.blue}
                  outline
                  padding={10}
                  noSpan
                  width="130px"
                >
                  <div className="mr-auto ml-2">
                    <DownArrowIcon fill={global.colors.blue} />
                  </div>
                  <StyledTypo
                    className="mr-auto"
                    color={global.colors.blue}
                    size={1.125}
                    line={25.2 / 18}
                    letter="0"
                    family="Roboto"
                    fontWeight={500}
                  >
                    {selectedCsp?.abbr}
                  </StyledTypo>
                </EffectedButton>
              </DropdownIcon>
              {selectedCsp && (
                <CSPDropdownMenu>
                  {cspData.map((item, idx) => (
                    <div
                      className={`d-flex mb-2 align-items-center ${
                        item?.key === selectedCsp.key ? "" : "cursor-pointer"
                      }`}
                      onClick={() => {
                        item?.key !== selectedCsp.key && setSelectedCsp(item);
                        setSelectCspModal((selectCspModal) => !selectCspModal);
                      }}
                      key={idx}
                    >
                      <StyledTypo
                        color={
                          item?.key === selectedCsp.key
                            ? global.colors.blue
                            : global.colors.red
                        }
                        size={1.125}
                        line={26.15 / 18}
                        letter="0"
                        family="Roboto"
                        fontWeight={500}
                      >
                        {item.abbr}
                      </StyledTypo>
                    </div>
                  ))}
                </CSPDropdownMenu>
              )}
            </StyledDropdown>
          </div>
          <div className="mr-4">
            <StyledDropdown isOpen={selectTypeModal} toggle={toggleTypeModal}>
              <DropdownIcon>
                <EffectedButton outline padding={10} noSpan width="200px">
                  <div className="mr-auto ml-2">
                    <DownArrowIcon fill={global.colors.red} />
                  </div>
                  <StyledTypo
                    className="mr-auto"
                    color={global.colors.red}
                    size={1.125}
                    line={25.2 / 18}
                    letter="0"
                    family="Roboto"
                    fontWeight={500}
                  >
                    {selectedTemplateType}
                  </StyledTypo>
                </EffectedButton>
              </DropdownIcon>
              {selectTypeModal && (
                <CategoryDropdownMenu>
                  {templateTypeList.map((item, idx) => (
                    <div
                      className={`d-flex mb-2 align-items-center ${
                        item === selectedTemplateType ? "" : "cursor-pointer"
                      }`}
                      onClick={() => {
                        item !== selectedTemplateType &&
                          setSelectedTemplateType(item);
                        toggleTypeModal();
                      }}
                      key={idx}
                    >
                      <StyledTypo
                        color={
                          item === selectedTemplateType
                            ? global.colors.red
                            : global.colors.blue
                        }
                        size={1.125}
                        line={26.15 / 18}
                        letter="0"
                        family="Roboto"
                        fontWeight={500}
                      >
                        {item}
                      </StyledTypo>
                    </div>
                  ))}
                </CategoryDropdownMenu>
              )}
            </StyledDropdown>
          </div>
          <div>
            <StyledDropdown
              isOpen={addTemplateModal}
              toggle={toggleTemplateModal}
            >
              <DropdownIcon>
                <EffectedButton padding={10} noSpan>
                  <CirclePlusIcon />
                  <StyledTypo
                    className="ml-2"
                    color="#fff"
                    size={1.125}
                    line={25.2 / 18}
                    letter="0"
                    family="Roboto"
                    fontWeight={500}
                  >
                    Add Template
                  </StyledTypo>
                </EffectedButton>
              </DropdownIcon>
              {addTemplateModal && (
                <AddTemplatePanel
                  toggle={toggleTemplateModal}
                  handleSubmitDeployment={createNewDeploymentTemplate}
                />
              )}
            </StyledDropdown>
          </div>
          <div className="ml-3">
            <div className="dropdown">
              <EffectedButton padding={10} noSpan onClick={toggleDeployModal}>
                <CirclePlusIcon />
                <StyledTypo
                  className="ml-2"
                  color="#fff"
                  size={1.125}
                  line={25.2 / 18}
                  letter="0"
                  family="Roboto"
                  fontWeight={500}
                >
                  Add Deployment
                </StyledTypo>
              </EffectedButton>
              {addDeployModal && (
                <AddDeploymentPanel
                  toggle={toggleDeployModal}
                  handleSubmitDeployment={createNewDeploymentTemplate}
                  createNewOrgTemplate={createNewOrganizationTemplateAction}
                  templates={originTemplates}
                  fetchTemplateVarsAction={fetchTemplateVarsAction}
                  templateVars={templateVars}
                  clearTemplateVarsAction={clearTemplateVarsAction}
                  appList={appList.filter(
                    (item) => item.organization_id === getCurrentOrganization()
                  )}
                  orgTemplates={templates}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Row
        className={`${
          deploymentsData?.length ? "" : "justify-content-center"
        } px-3`}
      >
        {deploymentsData?.length ? (
          deploymentsData
            .filter(
              (item, idx) => idx >= currentOrder && idx < currentOrder + 4
            )
            .map((item) => (
              <Col md={3} className="px-1" key={item.id}>
                <DisplayDetailDeployment data={item.template} />
              </Col>
            ))
        ) : (
          <div className="d-flex justify-content-center my-5">
            <StyledTypo
              color="#50505099"
              size={1.125}
              line={26.15 / 18}
              letter="0"
              family="Poppins"
              fontWeight={500}
            >
              No template
            </StyledTypo>
          </div>
        )}
      </Row>
      <div className="slide-buttons d-flex justify-content-end align-items-center my-3">
        <div
          className={`mr-2 ${currentOrder > 0 ? "cursor-pointer" : ""}`}
          onClick={prevDeployOrder}
        >
          <SlideLeftIcon fill={currentOrder > 0 ? "#A4AFB7" : "#A4AFB788"} />
        </div>
        <div
          className={`${
            currentOrder + 4 < deploymentsData.length ? "cursor-pointer" : ""
          }`}
          onClick={nextDeployOrder}
        >
          <SlideRightIcon
            fill={
              currentOrder + 4 < deploymentsData.length
                ? "#A4AFB7"
                : "#A4AFB788"
            }
          />
        </div>
      </div>
      <div>
        <DisplayTableDeployment data={deploymentsData} />
      </div>
    </PanelWrapper>
  );
};

function mapStateToProps(state) {
  const {
    templates,
    createTemplateStatus,
    originTemplates,
    createOrgTemplateStatus,
    templateVars,
  } = state.deployments;
  const { appList } = state.applications;
  const { expired_token, new_refresh_token } = state.auth;
  return {
    templates,
    createTemplateStatus,
    appList,
    originTemplates,
    createOrgTemplateStatus,
    templateVars,
    expired_token,
    new_refresh_token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrgTemplatesAction: () =>
      dispatch(deploymentActions.fetchOrgTemplatesAction()),
    fetchApplicationList: () =>
      dispatch(applicationActions.fetchApplicationListAction()),
    createNewDeploymentTemplate: (data) =>
      dispatch(deploymentActions.createNewDeploymentTemplateAction(data)),
    clearCreateTemplateStatus: () =>
      dispatch(deploymentActions.removeCreateNewTemplateStatus()),
    clearCreateOrgTemplateStatus: () =>
      dispatch(deploymentActions.removeCreateNewOrgTemplateStatus()),
    fetchTemplatesAction: () =>
      dispatch(deploymentActions.fetchTemplatesAction()),
    createNewOrganizationTemplateAction: (data) =>
      dispatch(deploymentActions.createNewOrganizationTemplateAction(data)),
    fetchTemplateVarsAction: (data) =>
      dispatch(deploymentActions.fetchTemplateVarsAction(data)),
    clearTemplateVarsAction: () =>
      dispatch(deploymentActions.clearTemplateVarsAction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeploymentsPanel);
