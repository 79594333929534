import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { authAction, usersActions } from "../../../redux/actions";
import { useHistory, useLocation } from "react-router-dom";

import styled from "styled-components";
import StyledTypo from "../../../components/Typo/StyledTypo";
import CommonInput from "../../../components/Input/CommonInput";

import { ReactComponent as PlusIcon } from "../../../assets/icons/plus_icon.svg";
import EffectedButton from "../../../components/Buttons/EffectedButton";
import CommonSelect from "../../../components/Select/CommonSelect";
import {
  colors,
  getCurrentOrganization,
  showAlert,
} from "../../../helpers/constants";

const AddUserContainer = styled.div`
  width: 80%;
  //min-height: 775px;
  padding: 41px 66px;

  background: #ffffff;
  // border: 1px solid #66c4d5;
  // box-sizing: border-box;
  // border-radius: 0 10px 10px 10px;
  //margin-left: 13px;
`;

const AddProfilePic = styled.div`
  width: 64px;
  height: 64px;
  background: #dbe0e9;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const noneOption = {
  value: "none",
  label: "None",
};

const AddOrganization = (props) => {
  const {
    organizations,
    addOrganizationAction,
    createOrgFlag,
    clearCreateOrgFlag,
  } = props;

  const [name, setName] = useState("");
  const [prefix, setPrefix] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const history = useHistory();
  const location = useLocation();

  const validateInput = () => {
    let msg = "";
    const existedOrgName = organizations.filter((org) => org.name === name);
    if (!name.length) {
      msg = "Please fill organization name.";
    } else if (existedOrgName.length) {
      msg = "Already existed name. Please input valid organization name.";
    } else if (!prefix.length) {
      msg = "Please fill domain prefix.";
    }
    if (msg.length) {
      showAlert("warning", "Invalid input", msg);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!validateInput()) return;
    const registerData = {
      email,
      name,
      prefix,
      phone,
      address,
    };
    addOrganizationAction(registerData);
  };

  useEffect(() => {
    if (createOrgFlag === 2) {
      showAlert("success", "Success", "Successfully added a new user.");
      clearCreateOrgFlag();
      history.push("/manage");
    } else if (createOrgFlag === 3) {
      showAlert("error", "Error", "Failed to add a new user");
      clearCreateOrgFlag();
    }
  }, [createOrgFlag, clearCreateOrgFlag]);

  return (
    <AddUserContainer>
      <div className="title mb-4 pb-2">
        <StyledTypo
          color="#50505088"
          size={1.625}
          line={36.4 / 26}
          letter="0"
          family="Roboto"
          fontWeight={700}
        >
          New Organization
        </StyledTypo>
      </div>
      <div className="row">
        <div className="col-6">
          <CommonInput
            label="Name"
            required
            value={name}
            setValue={setName}
            active={false}
            locked={false}
            placeholder=""
          />
        </div>
        <div className="col-6">
          <CommonInput
            label="Domain Prefix"
            required
            value={prefix}
            setValue={setPrefix}
            active={false}
            locked={false}
            placeholder=""
          />
        </div>
        <div className="col-6">
          <CommonInput
            label="Email"
            value={email}
            setValue={setEmail}
            active={false}
            locked={false}
            placeholder=""
          />
        </div>
        <div className="col-6">
          <CommonInput
            label="Phone"
            value={phone}
            setValue={setPhone}
            active={false}
            locked={false}
            placeholder=""
          />
        </div>
        <div className="col-12">
          <CommonInput
            label="Address"
            value={address}
            setValue={setAddress}
            active={false}
            locked={false}
            placeholder=""
          />
        </div>
      </div>
      <div className="mt-5 mb-4 pb-2 d-flex justify-content-start">
        <EffectedButton
          className="mr-4"
          padding={20}
          height={35}
          noSpan
          marginLeft="0px"
          onClick={handleSubmit}
        >
          <StyledTypo
            color="#fff"
            size={1}
            line={25.2 / 18}
            letter="0"
            family="Roboto"
            fontWeight={500}
          >
            Confirm Add Organization
          </StyledTypo>
        </EffectedButton>
        <EffectedButton
          background={colors.blue}
          padding={20}
          height={35}
          noSpan
          marginLeft="0px"
          onClick={() => history.push("/manage")}
        >
          <StyledTypo
            color="#fff"
            size={1}
            line={25.2 / 18}
            letter="0"
            family="Roboto"
            fontWeight={500}
          >
            Cancel
          </StyledTypo>
        </EffectedButton>
      </div>
    </AddUserContainer>
  );
};

function mapStateToProps(state) {
  const { createOrgFlag } = state.users;
  return {
    createOrgFlag,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addOrganizationAction: (data) =>
      dispatch(usersActions.addOrganizationAction(data)),
    clearCreateOrgFlag: () => dispatch(usersActions.clearCreateOrgFlag()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrganization);
