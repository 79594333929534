import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import styled from "styled-components";
import { ReactComponent as LogoIcon } from "../../../assets/icons/logo.svg";
import { colors, getCurrentUser } from "../../../helpers/constants";
import StyledTypo from "../../../components/Typo/StyledTypo";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close-icon.svg";
import { ReactComponent as MenuIcon } from "../../../assets/icons/menu-icon.svg";

const MainContainer = styled.div`
  padding: 15px;
  min-height: 64px;
  width: 100%;
  display: flex;
  box-shadow: 0 0.7px 1.4px rgb(0 0 0 / 7%), 0 1.9px 4px rgb(0 0 0 / 5%),
    0 4.5px 10px rgb(0 0 0 / 5%);
  align-items: center;
  justify-content: space-between;
  align-items: center;
  .right-side {
    display: flex;
    align-items: center;
  }

  .left-side {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

const PicWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #5eb1e4;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  img {
    object-fit: cover;
    object-position: 50% 0%;
    height: 40px;
    border-radius: 50%;
  }
`;

const Header = (props) => {
  const { sidebarOpen, setSidebarOpen } = props;

  return (
    <MainContainer>
      <div className="left-side">
        <div
          className="cursor-pointer"
          onClick={() => setSidebarOpen((open) => !open)}
        >
          {sidebarOpen ? (
            <CloseIcon width={16} stroke={colors.menu_active} />
          ) : (
            <MenuIcon width={22} stroke={colors.menu_active} />
          )}
        </div>
        <LogoIcon fill="#5EB1E4" />
        <StyledTypo
          color="#505050"
          size={1.375}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          MatosSphere
        </StyledTypo>
      </div>
      <div className="right-side">
        <PicWrapper>
          {getCurrentUser()?.pic ? (
            <img src={getCurrentUser()?.pic} alt="profile pic" />
          ) : (
            <StyledTypo
              color="#fff"
              size={1.125}
              line={1.4}
              letter="0"
              family="Roboto"
              fontWeight={500}
            >
              {getCurrentUser()?.first_name?.substr(0, 1)?.toUpperCase()}
              {getCurrentUser()?.last_name?.substr(0, 1)?.toUpperCase()}
            </StyledTypo>
          )}
        </PicWrapper>
      </div>
    </MainContainer>
  );
};

function mapStateToProps(state) {
  const { expired_token, new_refresh_token, defused_refresh_token } =
    state.auth;
  return {
    expired_token,
    new_refresh_token,
    defused_refresh_token,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
