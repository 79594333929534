import React, { useState, useEffect, useCallback } from "react";
import StyledTypo from "../../../../components/Typo/StyledTypo";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/cancel.svg";
import "react-modern-drawer/dist/index.css";
import { Col, Row, Badge } from "reactstrap";
import SlidePanel from "../../../../components/SlidePanel/SlidePanel";
import { ReactComponent as LeftArrow } from "../../../../assets/icons/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../../../assets/icons/right-arrow.svg";
import CommonSelect from '../../../../components/Select/CommonSelect'


const ResultTableBodyWrapper = styled.div`
  padding: 10px;
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #eaeaea;
  // height: 200px;
  height: 60vh;
  min-height: 180px;
  overflow-y: auto;
  overflow-x: hidden;
  resize: vertical;
`;

const ResultTableHeaderWrapper = styled.div`
  padding: 10px;
  padding-bottom: 0px;
  background: #f4f4f4;
  border: 1px solid #eaeaea;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const DividerWrapper = styled.div`
  height: 0;
  width: calc(100% + 60px);
  margin-left: -30px;
  border-bottom: 0.5px solid #ccc;
`;

const CloseWrapper = styled.div`
  border-radius: 10px;
  min-width: 45px;
  min-height: 40px;
  cursor: pointer;
  max-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CodeWrapper = styled.div`
  padding: 10px;
  width: 100%;
  word-break: break-word;
  border-radius: 5px;
  // background: #f6f6f6;
  // box-shadow: inset 0 2px 5px #ccc;
  border: 1px solid #ccc;
  margin-top: 16px;

  h1 {
    font-size: 1.2rem !important;
  }

  & h2 {
    font-size: 1rem !important;
  }

  & h3 {
    font-size: 0.9rem !important;
  }

  & h4 {
    font-size: 0.85rem !important;
  }

  & h5 {
    font-size: 0.8rem !important;
  }

  pre {
    word-break: break-all;
    overflow-x: hidden;
    width: fit-content;
    color: #495057 !important;
    background: #0000001a !important;
    padding: 5px;
    margin-left: 35px;
  }

  .cloud-manual-rememdiation-content {
    color: #505050 !important;
    font-size: 0.75rem;
    line: 1.4;
    letter: 0;
    font-family: Roboto;
    font-weight: 300;
  }
`;

const TableWrapper = styled.div`
  margin-top: 5px;
  width: 100%;
`;

const MoveCTAWrapper = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  // align-items: center;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  cursor: pointer;
`;

const SeverityWrapper = styled.span`
  position: absolute;
  left: calc(100% - 240px);
`;

const TitleWrapper = styled.span`
  display: inline-block;
  width: 58%;
  height: 1.4em;
`;

const CategoryContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
  padding-block: 10px;
  justify-content: flex-end;
`;

const CategoryItem = styled.div`
  padding: 6px 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 1px 1px 4px;
  border: 1px solid #eee;
  border-radius: 10px;
  width: 70px;
  justify-content: center;
  &.active {
    border: 1px solid #666;
    box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 4px;
    cursor: default;
  }
  cursor: pointer;
  display: flex;
  gap: 5px;
`;

const selectEmptyResourceType = { label: 'Select...', value: '' }

const ClusterDetailSlide = (props) => {
  const {
    data,
    isOpen,
    toggle,
    setOpenItemId,
    findIndex,
    findId,
    projectsCnt,
  } = props;
  const [codeZoom, ] = useState(false);

  const [selectedNamespace, setSelectedNamespace] = useState(selectEmptyResourceType)
  const [selectedKind, setSelectedKind] = useState(selectEmptyResourceType)
  const [selectedStatus, setSelectedStatus] = useState(selectEmptyResourceType)

  const [namespaceOptions, setNamespaceOptions] = useState([selectEmptyResourceType])
  const [kindOptions, setKindOptions] = useState([selectEmptyResourceType])
  const [statusOptions, setStatusOptions] = useState([selectEmptyResourceType])

  useEffect(() => {
    const namespaces = [...new Set((data?.resources ?? [])?.map(res => res?.resource_data?.namespace ?? res?.resource_data?.metadata?.namespace ?? 'Other'))]
    const kinds = [...new Set((data?.resources ?? [])?.map(res => res?.resource_data?.kind ?? 'Other'))]
    const statusList = ['passed', 'failed']

    setNamespaceOptions([selectEmptyResourceType, ...namespaces.map(namespace => ({
      label: namespace,
      value: namespace
    }))])
    setKindOptions([selectEmptyResourceType, ...kinds.map(kind => ({
      label: kind,
      value: kind
    }))])
    setStatusOptions([selectEmptyResourceType, ...statusList.map(status => ({
      label: status,
      value: status
    }))])
  }, [data])

  const filteredData = useCallback(() => {
    let res = data?.resources ?? []
    if(selectedNamespace.value !== '') {
      res = res.filter(each => (selectedNamespace.value === 'Other' && (!each?.resource_data?.namespace && !each?.resource_data?.metadata?.namespace )) || each?.resource_data?.namespace === selectedNamespace.value || each?.resource_data?.metadata?.namespace === selectedNamespace.value)
    }
    if(selectedKind.value !== '') {
      res = res.filter(each => each?.resource_data?.kind === selectedKind.value)
    }
    if(selectedStatus.value !== '') {
      res = res.filter(each => each?.status === selectedStatus.value)
    }
    return res;
  }, [
    data,
    selectedNamespace,
    selectedKind,
    selectedStatus
  ])

  return (
    <SlidePanel isOpen={isOpen} toggle={toggle} slideId="cloud-detail-slide">
      <div className="p-3 position-relative mb-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <CloseWrapper className="cursor-pointer" onClick={toggle}>
            <CloseIcon width={22} fill="#505050" />
          </CloseWrapper>
          <SeverityWrapper>
            <Badge
              color={data?.severity?.toLowerCase() ?? "low"}
              pill
            >
              Resources:{" "}
              {data?.ResourceCounters?.failedResources} / {Object.values(data?.ResourceCounters ?? {}).reduce((c,a) => c + a, 0)}
            </Badge>
          </SeverityWrapper>
          <TitleWrapper>
            <StyledTypo className="text-center heading ln-2">
              {data?.controlID}
            </StyledTypo>
          </TitleWrapper>
          <div className="d-flex align-items-center">
            <StyledTypo className="mr-2" size="0.8">
              {findIndex() + 1} of {projectsCnt}
            </StyledTypo>
            <MoveCTAWrapper
              disabled={findIndex() === 0}
              onClick={() => setOpenItemId(findId(false))}
            >
              <LeftArrow fill={findIndex() === 0 ? "#A4AFB7" : "#505050"} />
            </MoveCTAWrapper>
            <MoveCTAWrapper
              disabled={findIndex() + 1 === projectsCnt}
              onClick={() => setOpenItemId(findId(true))}
            >
              <RightArrow
                fill={findIndex() + 1 === projectsCnt ? "#A4AFB7" : "#505050"}
              />
            </MoveCTAWrapper>
          </div>
        </div>
        <DividerWrapper className="mb-3" />
        {!codeZoom && (
          <Row>
            <Col md={12} className="position-static d-flex p-0">
              <Col md={2}>
                <StyledTypo className="mt-2 mr-3 label">Name</StyledTypo>
              </Col>
              <Col md={10}>
                <StyledTypo className="mt-2">{data?.name}</StyledTypo>
              </Col>
            </Col>
            <Col md={12} className="position-static d-flex p-0">
              <Col md={2}>
                <StyledTypo className="mt-2 mr-3 label">Description</StyledTypo>
              </Col>
              <Col md={10}>
                <StyledTypo className="mt-2">{data?.description}</StyledTypo>
              </Col>
            </Col>
            
            <Col md={12} className="position-static d-flex p-0">
              <Col md={2}>
                <StyledTypo className="mt-2 mr-3 label">Severity</StyledTypo>
              </Col>
              <Col md={10}>
                <StyledTypo className="mt-2 severity-text">{data?.severity?.toLowerCase()}</StyledTypo>
              </Col>
            </Col>
            
          </Row>
        )}
        <CategoryContainer>
          <div style={{ minWidth: 200 }}>
            <CommonSelect
              value={selectedNamespace}
              setValue={setSelectedNamespace}
              options={namespaceOptions}
              label={`Namespace`}
            />
          </div>
          <div className="ml-3" style={{ minWidth: 200 }}>
            <CommonSelect
              value={selectedKind}
              setValue={setSelectedKind}
              options={kindOptions}
              label={`Kind`}
            />
          </div>
          <div className="ml-3" style={{ minWidth: 200 }}>
            <CommonSelect
              value={selectedStatus}
              setValue={setSelectedStatus}
              options={statusOptions}
              label={`Status`}
            />
          </div>
        </CategoryContainer>
        <TableWrapper>
          {!codeZoom && (
            <>
              <ResultTableHeaderWrapper>
                <Row className="text-break mb-2">
                  <Col xs={1}>
                    <StyledTypo className="label table">#</StyledTypo>
                  </Col>
                  <Col xs={5}>
                    <StyledTypo className="label table">Name</StyledTypo>
                  </Col>
                  <Col xs={2}>
                    <StyledTypo className="label table">Namespace</StyledTypo>
                  </Col>
                  <Col xs={2}>
                    <StyledTypo className="label table">Kind</StyledTypo>
                  </Col>
                  <Col xs={2}>
                    <StyledTypo className="label table">Status</StyledTypo>
                  </Col>
                </Row>
              </ResultTableHeaderWrapper>
              {filteredData().length > 0 && (
                <ResultTableBodyWrapper>
                  {filteredData()?.map((res, idx) => (
                    <Row
                      className={`text-break py-2 ${
                        idx % 2 === 0 ? "bg-white" : ""
                      }`}
                      style={{ backgroundColor: "#f7f7f7" }}
                    >
                      <Col xs={1}>
                        <StyledTypo className="mt-1 table">{idx + 1}</StyledTypo>
                      </Col>
                      <Col xs={5}>
                        <StyledTypo className="mt-1 table">
                          {res?.resource_data?.name ?? res?.resource_data?.metadata?.name}
                        </StyledTypo>
                      </Col>
                      <Col xs={2}>
                        <StyledTypo className="mt-1 table">
                          {res?.resource_data?.namespace ?? res?.resource_data?.metadata?.namespace ?? 'Other'}
                        </StyledTypo>
                      </Col>
                      <Col xs={2}>
                        <StyledTypo className="mt-1 table">
                          {res?.resource_data?.kind}
                        </StyledTypo>
                      </Col>
                      <Col xs={2}>
                        <StyledTypo className="mt-1 table">
                          {res?.status}
                        </StyledTypo>
                      </Col>
                    </Row>
                  ))}
                </ResultTableBodyWrapper>
              )}
            </>
          )}
        </TableWrapper>
        {/* <CodeZoomWrapper onClick={() => setCodeZoom(!codeZoom)}>
          <ExpandIcon fill="#aeaeae" />
        </CodeZoomWrapper>
        <div className="mt-1">
          <StyledTypo className="label">Remediation</StyledTypo>
        </div>
        <Row>
          <Col md={12}>
            <ActiveCodeWrapper>
              <ActiveScrollWrapper>
                {manual_cloud_remediations?.[data?.id] ? (
                  <div
                    className="cloud-manual-rememdiation-content"
                    dangerouslySetInnerHTML={{
                      __html: marked.parse(
                        manual_cloud_remediations?.[data?.id]
                      ),
                    }}
                  />
                ) : (
                  <></>
                )}
              </ActiveScrollWrapper>
            </ActiveCodeWrapper>
          </Col>
        </Row> */}
      </div>
    </SlidePanel>
  );
};

export default ClusterDetailSlide;
