import { Graph } from "react-d3-graph";
import { config1 as graphConfig } from "../graph-configs";

const Sample02 = (props) => {
    // graph payload (with minimalist structure)
    const graphData = {
        nodes: [
            { id: 1, name: "EC2 Instance", svg: './images/EC2Instance.png' },
            { id: 2, name: "EC2 Key Pair", svg: './images/EC2KeyPair.png' },
            { id: 3, name: "Image", svg: './images/Image.png' },
            { id: 4, name: "Network Interface", svg: './images/NetworkInterface.png' },
            { id: 5, name: "Unknown Key", svg: './images/UnknownKey.png' },
            { id: 6, name: "CVE Group", svg: './images/CVEGroup.png' }
        ],
        links: [
            { source: 2, target: 1 },
            { source: 3, target: 1 },
            { source: 4, target: 1 },
            { source: 5, target: 1 },
            { source: 6, target: 1 }
        ]
    };

    const onClickNode = function (nodeId) {
        console.log(`Clicked node ${nodeId}`);
    };

    const onClickLink = function (source, target) {
        console.log(`Clicked link between ${source} and ${target}`);
    };

    let myConfig = graphConfig;
    myConfig.staticGraph = false;
    myConfig.panAndZoom = props.settings.panAndZoom;
    myConfig.collapsible = false;
    myConfig.directed = props.settings.directedGraph;

    return (
        <>
            Vulnerability Assets: <em>CVE-2021-36690</em>
            <div class="box">
                <Graph
                    id="graph-02F" // id is mandatory
                    data={graphData}
                    config={myConfig}
                    onClickNode={onClickNode}
                    onClickLink={onClickLink}
                />
            </div>
        </>
    );
}

export default Sample02;