import React from "react";
import { useParams } from "react-router-dom";
import Page from "../../components/PageLayout/Page";
import InfraLayout from "./cloud/InfraLayout";

const CloudInfra = (props) => {
  const params = useParams();

  return (
    <div>
      <Page noUpperCta>
        <InfraLayout id={params.id} />
      </Page>
    </div>
  );
};

export default CloudInfra;
