import React, { useState, useEffect } from "react";
import styled from "styled-components";
import StyledTypo from "../../../components/Typo/StyledTypo";
import CustomTextarea from "../../../components/Input/CustomTextarea";
import StyledFile from "../../../components/Input/StyledFile";
import { tfCredCategory } from "../../../helpers/constants";
import PrettierPanel from "../PrettierPanel";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info_icon.svg";
import StyledCheckbox from "../../../components/Input/StyledCheckbox";

const BorderWrapper = styled.div`
  border: 0.5px solid #ccc;
  padding: 10px 15px;
  border-radius: 10px;
  position: relative;
  margin-top: 30px;

  .title {
    padding: 0 5px;
    position: absolute;
    left: 15px;
    top: -10px;
    background-color: #fff;
  }
`;

const GCPCredPartial = (props) => {
  const { credValue, setCredValue, disabled } = props;

  const [showPrettier, setShowPrettier] = useState({});
  const [checkValue, setCheckValue] = useState(false);

  const handleCredChange = (filename, content, type) => {
    if (checkValue) {
      handleChange("backend", "credential", JSON.parse(content));
    }
    handleChange(type, "credential", JSON.parse(content));
  };

  const handleChange = (parentKey, key, val) => {
    setCredValue((credValue) => ({
      ...credValue,
      [parentKey]: { ...(credValue[parentKey] ?? {}), [key]: val },
    }));
  };

  const togglePrettierPanel = (idx) => {
    setShowPrettier((showPrettier) => ({
      ...showPrettier,
      [idx]: !showPrettier[idx],
    }));
  };

  const handleCheck = () => {
    setCheckValue((checkValue) => !checkValue);
  };

  useEffect(() => {
    if (checkValue) {
      setCredValue((credValue) => ({
        ...credValue,
        backend: credValue?.backend
          ? {
              ...credValue?.backend,
              ...(credValue?.terraform ?? {}),
            }
          : { ...(credValue?.terraform ?? {}) },
      }));
    }
  }, [checkValue, setCredValue]);

  return (
    <div>
      {tfCredCategory.gcp.map((each) => (
        <BorderWrapper key={each.key}>
          <div className="title">
            <StyledTypo size="0.8">
              {each.label}
            </StyledTypo>
          </div>
          {each.children &&
            each.children.map((category, idx) =>
              category.type === "text" ? (
                <CustomTextarea
                  key={idx}
                  label={category.label}
                  value={credValue?.[each.key]?.[category.key] ?? ""}
                  setValue={(val) => handleChange(each.key, category.key, val)}
                  disabled={disabled}
                />
              ) : category.type === "file" ? (
                <div>
                  {each.hasCheck && (
                    <StyledCheckbox
                      name="Use same credential"
                      handleOnChange={handleCheck}
                      checked={checkValue}
                      color="#A4AFB7"
                      fontSize={0.875}
                      fontWeight={400}
                      className="mt-3"
                    />
                  )}
                  {each.hasCheck && checkValue ? (
                    <></>
                  ) : (
                    <div className="d-flex justify-content-between align-items-center">
                      <StyledFile
                        label="SA file"
                        onChange={(filename, content) =>
                          handleCredChange(filename, content, each.key)
                        }
                        isShowName
                        extensions={["json"]}
                        hiddenId={`hidden${each.key}`}
                        className="my-2"
                        disabled={disabled}
                      />
                      {!!credValue?.[each.key]?.[category.key] && (
                        <div className="dropdown show">
                          <div
                            className={"cursor-pointer"}
                            onClick={() => togglePrettierPanel(each.key)}
                          >
                            <InfoIcon />
                          </div>
                          {showPrettier[each.key] && (
                            <PrettierPanel
                              title="View Service Account"
                              toggle={() => togglePrettierPanel(each.key)}
                              prettierData={credValue[each.key][category.key]}
                              id={`var-${each.key}`}
                              onlyShow
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div />
              )
            )}
        </BorderWrapper>
      ))}
    </div>
  );
};

export default GCPCredPartial;
