import React, { useState, useEffect } from 'react'
import StyledTypo from '../../../components/Typo/StyledTypo'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { ReactComponent as CloseIcon } from '../../../assets/icons/cancel.svg'
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy-icon.svg'
import 'react-modern-drawer/dist/index.css'
import CommonInput from '../../../components/Input/CommonInput'
import {
  actionIacPlatform,
  platformList,
  showAlert
} from '../../../helpers/constants'
import EffectedButton from '../../../components/Buttons/EffectedButton'
import SlidePanel from '../../../components/SlidePanel/SlidePanel'

const CloseWrapper = styled.div`
  border-radius: 10px;
  min-width: 45px;
  min-height: 40px;
  cursor: pointer;
  max-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
`

const ButtonGroupWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 10px;
  width: calc(100% - 20px);
`

const DividerWrapper = styled.div`
  height: 0;
  width: calc(100% + 60px);
  margin-left: -30px;
  border-bottom: 0.5px solid #ccc;
`

const TitleWrapper = styled.span`
  display: inline-block;
  width: 100%;
  height: 1.4em;
`

const ContentWrapper = styled.div`
  padding: 20px;
  margin-top: -50px;
  bottom: 20px;
  left: 10px;
  width: calc(100% - 20px);
`

const CopyCTAWrapper = styled.div`
  padding: 5px;
  cursor: pointer;
  & svg:hover {
    fill: #5eb1e4;
  }
`

const cspOptions = [
  ...platformList,
  { value: 'kubernetes', label: 'Kubernetes' }
]

const platformOptions = [...actionIacPlatform]

const APIKeyCreatePanel = props => {
  const {
    isOpen,
    toggle,
    createProjectAction,
    createdFlag,
    clearCreateFlagAction,
    updateProject,
    updateProjectAction
  } = props
  const [payload, setPayload] = useState(
    updateProject ? { ...updateProject } : {}
  )

  useEffect(() => {
    if (isOpen && updateProject) {
      setPayload({
        ...updateProject,
        csp: cspOptions.filter(item => item.value === updateProject.csp)?.[0],
        platform: platformOptions.filter(item =>
          updateProject.platform.includes(item.value)
        )
      })
    } else {
      setPayload({})
    }
  }, [updateProject, isOpen])

  useEffect(() => {
    if (createdFlag !== 0) {
      clearCreateFlagAction()
    }
    if (createdFlag === 1) {
      closePanel()
    }
  }, [createdFlag])

  const changeValue = (key, val) => {
    setPayload(payload => ({ ...payload, [key]: val }))
  }

  const closePanel = () => {
    setPayload({})
    toggle()
  }

  const validateInput = () => {
    let msg = ''
    if (!payload?.display_name) {
      msg = 'Please fill Name.'
    }
    if (msg.length) {
      showAlert('warning', 'Invalid input', msg)
      return false
    }
    return true
  }

  const submitProject = () => {
    if (!validateInput()) return
    const data = {
      ...payload,
    }
    if (updateProject) {
      updateProjectAction(data)
    } else {
      createProjectAction(data)
    }
  }

  return (
    <SlidePanel
      slideId='create-iac-project-panel'
      isOpen={isOpen}
      toggle={closePanel}
    >
      <div className='p-3 position-relative mb-3'>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <CloseWrapper className='cursor-pointer' onClick={closePanel}>
            <CloseIcon width={22} fill='#505050' />
          </CloseWrapper>
          <TitleWrapper>
            <StyledTypo className='text-center heading ln-2'>
              {updateProject ? 'Edit' : 'Add IaC'} API Key
            </StyledTypo>
          </TitleWrapper>
        </div>
        <DividerWrapper className='mb-3' />
      </div>
      <ContentWrapper>
        {!!updateProject && (
          // <div className="mt-5">
          <div className='d-flex align-items-center mt-3'>
            <StyledTypo className='label'>
              API Key: ****
              {(updateProject?.api_key ?? '').substr(-4)}
            </StyledTypo>
            <CopyCTAWrapper
              className='ml-2'
              onClick={() => {
                navigator.clipboard.writeText(updateProject?.api_key)
                showAlert('success', 'Copied API Key', '', 2000)
              }}
            >
              <CopyIcon width={20} height={20} />
            </CopyCTAWrapper>
          </div>
          // </div>
        )}
        <div className='row'>
          <div className='col-6'>
            <CommonInput
              label='Name'
              value={payload?.display_name ?? ''}
              setValue={val => changeValue('display_name', val)}
              active={false}
              locked={false}
              placeholder=''
            />
          </div>
        </div>
      </ContentWrapper>
      <ButtonGroupWrapper className='col-12 px-3 d-flex mt-5 align-items-center justify-content-end'>
        <EffectedButton
          className='ml-5'
          padding={20}
          height={32}
          noSpan
          marginLeft='0px'
          onClick={submitProject}
          background='#5EB1E4'
          width={90}
        >
          <StyledTypo color='#fff' className='label'>
            {updateProject ? 'Save' : 'Add'}
          </StyledTypo>
        </EffectedButton>
        <EffectedButton
          className='ml-3'
          padding={20}
          height={32}
          noSpan
          marginLeft='0px'
          onClick={closePanel}
          width={90}
        >
          <StyledTypo color='#fff' className='label'>
            Cancel
          </StyledTypo>
        </EffectedButton>
      </ButtonGroupWrapper>
    </SlidePanel>
  )
}

export default connect(undefined, undefined)(APIKeyCreatePanel)
