import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { applicationActions } from '../../../../redux/actions'
import { connect } from 'react-redux'
import * as global from '../../../../helpers/constants'
import StyledTypo from '../../../../components/Typo/StyledTypo'
import { ViewContainer } from '../../../../components/PageLayout/Containers'
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy-icon.svg'
import EffectedButton from '../../../../components/Buttons/EffectedButton'

const Container = styled.div`
  padding: 15px 40px;
`

const ListPanel = styled.div`
  // width: 25%;
  min-width: 50px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  // row-gap: 15px;
  padding: 10px 0 30px;

  & p {
    cursor: pointer;
  }
`

const ButtonGroupWrapper = styled.div`
  width: 100%;
`

const CopyCTAWrapper = styled.div`
  padding: 5px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 5px;
  & svg {
    fill: #000;
    stroke: #000;
  }
  & svg:hover {
    fill: #5eb1e4;
    stroke: #5eb1e4;
  }
`

const ContentPanel = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  height: calc(100vh - 180px);
  flex: 1;
  padding-left: 15px;

  textarea {
    height: 100%;
    width: 100%;
    border: none;
    background: transparent;
    outline: none;
    resize: none;
  }

  position: relative;

`

const ConfigItem = styled.div`
  background: ${props => props.active ? '#f1f1f1' : 'transparent'};
  padding: 10px 20px;
`

const PentestingSettingsLayout = props => {
  const { 
    fetchPentestingConfigurationsAction, 
    fetchSelectedPentestingConfigurationAction,
    setSelectedPentestingConfigurationAction,
    setPentestingConfigurationAction,
    pentesting_settings, selected_pentesting_config
  } = props

  const [selectedConf, setSelectedConf] = useState('default')
  const [html, setHtml] = useState("")
  
  useEffect(() => {
    if(Object.keys(pentesting_settings ?? {}).length === 0) {
      fetchPentestingConfigurationsAction()
    }
  }, [])

  useEffect(() => {
    const configurations = Object.keys(pentesting_settings).length
    if(configurations > 0) {
      fetchSelectedPentestingConfigurationAction()
    }
  }, [pentesting_settings])

  useEffect(() => {
    setHtml(pentesting_settings?.[global.getCurrentOrganization()]?.[selectedConf] ?? "")
  }, [selectedConf, pentesting_settings])

  function onChange (e) {
    setHtml(e.target.value)
  }

  const setCustomConfig = () => {
    if(selectedConf !== 'custom') {
      setSelectedPentestingConfigurationAction(selectedConf)
    } else {
      setPentestingConfigurationAction(html)
    }
  }

  return (
    <ViewContainer>
      <Container>
        <StyledTypo
          className="mt-3"
          size={1.2}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={400}
        >
          Configurations
        </StyledTypo>
        <div className='d-flex mt-3'>
          <ListPanel className="mr-3">
            {Object.keys(pentesting_settings?.[global.getCurrentOrganization()] ?? {}).map(conf => (
              <ConfigItem active={selectedConf === conf}>
                <StyledTypo
                  family="Roboto"
                  fontWeight={selected_pentesting_config?.[global.getCurrentOrganization()] === conf ? 500 : 300}
                  onClick={() => setSelectedConf(conf)}
                  color={selected_pentesting_config?.[global.getCurrentOrganization()] === conf ? '#ff487f' : '#505050'}
                >
                  {conf}
                </StyledTypo>
              </ConfigItem>
            ))}
          </ListPanel>
          <ContentPanel>
            <CopyCTAWrapper
              className='ml-2'
              onClick={() => {
                navigator.clipboard.writeText(html)
                global.showAlert('success', `Copied ${selectedConf} configuration`, '', 2000)
              }}
            >
              <CopyIcon width={20} height={20} />
            </CopyCTAWrapper>
            <textarea value={html} onChange={onChange} disabled={selectedConf !== 'custom'}></textarea>
          </ContentPanel>
        </div>
        <ButtonGroupWrapper className='w-100 d-flex mt-3 align-items-center justify-content-end'>
          <EffectedButton
            padding={20}
            height={32}
            noSpan
            marginLeft='0px'
            onClick={setCustomConfig}
            background='#5EB1E4'
            disabled={selectedConf !== 'custom' && selectedConf === selected_pentesting_config?.[global.getCurrentOrganization()]}
            width={90}
          >
            <StyledTypo color='#fff' className='label'>
              Save
            </StyledTypo>
          </EffectedButton>
          <EffectedButton
            className='ml-3'
            padding={20}
            height={32}
            noSpan
            marginLeft='0px'
            onClick={() => setHtml(pentesting_settings?.[global.getCurrentOrganization()]?.custom)}
            disabled={selectedConf !== 'custom' || (selectedConf === 'custom' && html === pentesting_settings?.[global.getCurrentOrganization()]?.custom)}
            width={90}
          >
            <StyledTypo color='#fff' className='label'>
              Cancel
            </StyledTypo>
          </EffectedButton>
        </ButtonGroupWrapper>
      </Container>
    </ViewContainer>
  )
}

function mapStateToProps (state) {
  const { pentesting_settings, selected_pentesting_config } = state.applications
  return {
    pentesting_settings, selected_pentesting_config
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchPentestingConfigurationsAction: () => dispatch(applicationActions.fetchPentestingConfigurationsAction()),
    fetchSelectedPentestingConfigurationAction: () => dispatch(applicationActions.fetchSelectedPentestingConfigurationAction()),
    setSelectedPentestingConfigurationAction: (id) => dispatch(applicationActions.setSelectedPentestingConfigurationAction(id)),
    setPentestingConfigurationAction: (content) => dispatch(applicationActions.setPentestingConfigurationAction(content))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PentestingSettingsLayout)
