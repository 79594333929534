import { useEffect } from 'react'
import styled from 'styled-components'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import UserAssets from '../../../graphs/analysis/UserAssets'
import StyledTypo from '../../../../components/Typo/StyledTypo'
import { Col, Row } from 'reactstrap'
import InstanceCve from '../../../graphs/analysis/InstanceCve'

const StatsTabWrapper = styled.div`
  padding: 10px 0px;
  margin-top: 15px;
`

const TabPanelBodyWrapper = styled.div`
  width: 100%;
  padding: 10px 20px;

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 15px;
  }
`

const AttackPathsDetail = props => {
  const { data } = props

  return (
    <>
      <Row>
        <Col md={12} className='position-static d-flex p-0'>
          <Col md={2}>
            <StyledTypo className='mt-2 mr-3 label'>Infrastructure</StyledTypo>
          </Col>
          <Col md={4}>
            <StyledTypo className='mt-2'>{data?.app_name}</StyledTypo>
          </Col>
          <Col md={2}>
            <StyledTypo className='mt-2 mr-3 label'>Resource Type</StyledTypo>
          </Col>
          <Col md={4}>
            <StyledTypo className='mt-2'>{data?.resource_type}</StyledTypo>
          </Col>
        </Col>
        <Col md={12} className='position-static d-flex p-0'>
          <Col md={2}>
            <StyledTypo className='mt-2 mr-3 label'>Description</StyledTypo>
          </Col>
          <Col md={10}>
            <StyledTypo className='mt-2'>
              {data?.description ?? '---'}
            </StyledTypo>
          </Col>
        </Col>
      </Row>
      <StatsTabWrapper>
        <Tabs>
          <TabList>
            <Tab key={`attack-path-tab-header`}>
              <StyledTypo
                line='1.8'
                size={0.8}
                family='Poppins'
                fontWeight={300}
              >
                Attack Path
              </StyledTypo>
            </Tab>
            <Tab key={`vulnerability-tab-header`}>
              <StyledTypo
                line='1.8'
                size={0.8}
                family='Poppins'
                fontWeight={300}
              >
                Vulnerability
              </StyledTypo>
            </Tab>
            <Tab key={`compliance-tab-header`}>
              <StyledTypo
                line='1.8'
                size={0.8}
                family='Poppins'
                fontWeight={300}
              >
                Compliance
              </StyledTypo>
            </Tab>
          </TabList>
          <TabPanel>
            <TabPanelBodyWrapper key={`attack-path-tab-body`}>
              {data?.resource_type === 'iam' && (
                <UserAssets
                  settings={{ width: 1130, height: 600 }}
                  app_id={data?.app_id}
                />
              )}
              {data?.resource_type === 'vm' && (
                <InstanceCve
                  settings={{ width: 1130, height: 600 }}
                  data={{
                    app_id: data?.application_id,
                    node_id: data?.id,
                  }}
                />
              )}
            </TabPanelBodyWrapper>
          </TabPanel>
          <TabPanel>
            <TabPanelBodyWrapper
              key={`vulnerability-tab-body`}
            ></TabPanelBodyWrapper>
          </TabPanel>
          <TabPanel>
            <TabPanelBodyWrapper
              key={`compliance-path-tab-body`}
            ></TabPanelBodyWrapper>
          </TabPanel>
        </Tabs>
      </StatsTabWrapper>
    </>
  )
}

export default AttackPathsDetail;
