import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import StyledTypo from "../../components/Typo/StyledTypo";
import CommonInput from "../../components/Input/CommonInput";
import moment from 'moment'
import EffectedButton from "../../components/Buttons/EffectedButton";
import {DropdownMenu} from 'reactstrap'
import {getCurrentUser} from "../../helpers/constants";
import Cards from "react-credit-cards";
import CreditCardInput from "react-credit-card-input";

const MainContainer = styled(DropdownMenu)`
  width: 480px;
  padding: 33px 41px;
  
  background: #FFFFFF;
  border: 2px solid #F1F5FA;
  box-sizing: border-box;
  //box-shadow: 0 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 15px;
  // border-radius: ${props => props.directly ? '20px 0 20px 20px' :'20px'};
  transform: translate3d(-310px, 45px, 0px) !important;
  box-shadow: 0 0 0 9999px #00000066;
`

const AddPaymentPanel = (props) => {
  const { createPaymentAction } = props

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [number, setNumber] = useState('')
  const [expiry, setExpiry] = useState('')
  const [cvc, setCvc] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [paymentData, setPaymentData] = useState({})

  useEffect(() => {
    clearValue()
  }, [])

  useEffect(() => {
    setPaymentData({
      card: {
        number,
        cvc,
        exp_month: parseInt(expiry.split(' / ')?.[0] ?? ''),
        exp_year: parseInt(`${moment().format('YYYY').substr(0, 2)}${expiry.split(' / ')?.[1] ?? ''}`)
      },
      billing_details: {
        address: {
          postal_code: zipCode,
        },
        email: getCurrentUser()?.email,
        name: `${firstName} ${lastName}`
      }
    })
  }, [number, expiry, cvc, firstName, lastName, zipCode])


  const clearValue = () => {
    setFirstName('')
    setLastName('')
    setNumber('')
    setExpiry('')
    setCvc('')
    setZipCode('')
    setPaymentData({})
  }

  const submitNewPayment = () => {
    createPaymentAction({
      data: paymentData,
      type: 'card'
    })
    props.toggle()
  }

  return (
      <MainContainer>
        <Cards
            cvc={cvc}
            expiry={expiry.replace(' / ', '')}
            name={`${firstName} ${lastName}`}
            number={number}
        />
        <div className="mt-5">
          <CommonInput label="First Name" value={firstName} setValue={setFirstName} active={false} locked={false}
                       placeholder=""/>
          <CommonInput label="Last Name" value={lastName} setValue={setLastName} active={false} locked={false}
                       placeholder=""/>
          <CreditCardInput
              cardNumberInputProps={{value: number, onChange: (e) => setNumber(e.target.value)}}
              cardExpiryInputProps={{value: expiry, onChange: e => setExpiry(e.target.value)}}
              cardCVCInputProps={{value: cvc, onChange: e => setCvc(e.target.value)}}
              cardZipInputProps={{value: zipCode, onChange: e => setZipCode(e.target.value)}}
              fieldClassName="input"
              enableZipInput={true}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between mt-5">
          <div>
            <EffectedButton marginLeft="0" height={40} onClick={submitNewPayment}>
              <StyledTypo color="#fff" size={0.875} line={25.2 / 18} letter="0" family="Roboto" fontWeight={500}>
                Add
              </StyledTypo>
            </EffectedButton>
          </div>
          <div>
            <EffectedButton marginLeft="0" height={40} background="#485DCD" onClick={() => {
              clearValue()
              props.toggle()
            }}>
              <StyledTypo color="#fff" size={0.875} line={25.2 / 18} letter="0" family="Roboto" fontWeight={500}>
                Cancel
              </StyledTypo>
            </EffectedButton>
          </div>
        </div>
      </MainContainer>
  )
}

export default AddPaymentPanel
