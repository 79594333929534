import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { authAction, usersActions } from "../../../redux/actions";
import { useHistory, useLocation } from "react-router-dom";

import styled from "styled-components";
import StyledTypo from "../../../components/Typo/StyledTypo";
import CommonInput from "../../../components/Input/CommonInput";

import { ReactComponent as PlusIcon } from "../../../assets/icons/plus_icon.svg";
import EffectedButton from "../../../components/Buttons/EffectedButton";
import CommonSelect from "../../../components/Select/CommonSelect";
import {
  colors,
  getCurrentOrganization,
  getCurrentUser,
  showAlert,
} from "../../../helpers/constants";

const AddUserContainer = styled.div`
  width: 80%;
  //min-height: 775px;
  padding: 41px 66px;

  background: #ffffff;
  border: 1px solid #66c4d5;
  box-sizing: border-box;
  border-radius: 0 10px 10px 10px;
  //margin-left: 13px;
`;

const AddProfilePic = styled.div`
  width: 64px;
  height: 64px;
  background: #dbe0e9;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const noneOption = {
  value: "none",
  label: "None",
};

const AddUser = (props) => {
  const {
    organizations,
    addUserAction,
    createUserFlag,
    clearCreateUserFlag,
    users,
    updateUserAction,
    clearUpdateUserFlag,
    updateUserFlag,
    fetchUsersAction,
  } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [organization, setOrganization] = useState("");
  const [role] = useState("user");

  const [userId, setUserId] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  // const [organization, setOrganization] = useState(noneOption)
  // const [occupation, setOccupation] = useState('')
  const [phone, setPhone] = useState("");
  // const [address1, setAddress1] = useState('')
  // const [address2, setAddress2] = useState('')
  // const [city, setCity] = useState('')
  // const [province, setProvince] = useState('')
  // const [country, setCountry] = useState('')
  // const [zipCode, setZipCode] = useState('')
  // const [status, setStatus] = useState('')
  // const [role, setRole] = useState(noneOption)
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setUserId(
      location?.search ? new URLSearchParams(location?.search).get("id") : ""
    );
  }, []);

  useEffect(() => {
    setSelectedUser(users.filter((user) => user.UUID === userId)?.[0] ?? {});
  }, [userId, users]);

  // const roleOptions = [
  //   noneOption,
  //   {value: 'user', label: 'User'},
  //   {value: 'admin', label: 'Administrator'},
  //   {value: 'editor', label: 'Editor'},
  // ]
  //
  // const organizationOptions = [
  //   noneOption,
  //   ...organizations.map(org => ({
  //     value: org.id,
  //     label: org.name
  //   }))
  // ]

  useEffect(() => {
    if (updateUserFlag === 1) {
      history.push("/manage");
    }
    clearUpdateUserFlag();
  }, [updateUserFlag]);

  useEffect(() => {
    if (selectedUser) {
      setFirstName(selectedUser.first_name);
      setLastName(selectedUser.last_name);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (organizations) {
      setOrganization(
        organizations.filter((org) => org.id === getCurrentOrganization())?.[0]
          ?.name
      );
    }
  }, [organizations]);

  const validateInput = () => {
    let msg = "";
    if (!userId.length) {
      if (!email.length) {
        msg = "Please fill email.";
      } else if (!firstName.length) {
        msg = "Please fill First Name";
      } else if (!lastName.length) {
        msg = "Please fill First Name";
      } else if (!pwd.length) {
        msg = "Please fill Password.";
      } else if (pwd.length < 8) {
        msg = "Password must have 8 characters at least.";
      } else if (pwd !== confirmPwd) {
        msg =
          "Confirm Password is not same as Password. Please check Password again.";
      } else if (!organization || !organization.length) {
        msg = "Can not get organization. Please check your organization.";
      }
    } else {
      if (!firstName.length) {
        msg = "Please fill First Name";
      } else if (!lastName.length) {
        msg = "Please fill First Name";
      }
    }
    if (msg.length) {
      showAlert("warning", "Invalid input", msg);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!validateInput()) return;
    const registerData = {
      email,
      first_name: firstName,
      last_name: lastName,
      password: pwd,
      organization_id: getCurrentUser().organization_id,
      role,
      phone: phone,
      type: role,
    };

    if (userId.length) {
      updateUserAction(userId, { first_name: firstName, last_name: lastName });
    } else {
      addUserAction(registerData);
    }
  };

  useEffect(() => {
    if (createUserFlag === 2) {
      showAlert("success", "Success", "Successfully added a new user.");
      fetchUsersAction();
      clearCreateUserFlag();
      history.push("/manage");
    } else if (createUserFlag === 3) {
      showAlert("error", "Error", "Failed to add a new user");
      clearCreateUserFlag();
    }
  }, [clearCreateUserFlag]);

  return (
    <AddUserContainer>
      <div className="title mb-4 pb-2">
        <StyledTypo
          color="#50505088"
          size={1.625}
          line={36.4 / 26}
          letter="0"
          family="Roboto"
          fontWeight={700}
        >
          {`${userId.length ? "Edit" : "Add"}`} User
        </StyledTypo>
      </div>
      {/*<div className="profile-pic d-flex mb-4 pb-2 align-items-center">*/}
      {/*  <AddProfilePic>*/}
      {/*    <PlusIcon/>*/}
      {/*  </AddProfilePic>*/}
      {/*  <StyledTypo className="ml-3" color="#505050" size={0.875} line={19.6 / 14} letter="0" family="Roboto"*/}
      {/*              fontWeight={300}>*/}
      {/*    Add Profile Picture*/}
      {/*  </StyledTypo>*/}
      {/*</div>*/}
      <div className="row">
        <div className="col-6">
          <CommonInput
            label="First Name"
            required
            value={firstName}
            setValue={setFirstName}
            active={false}
            locked={false}
            placeholder=""
          />
        </div>
        <div className="col-6">
          <CommonInput
            label="Last Name"
            required
            value={lastName}
            setValue={setLastName}
            active={false}
            locked={false}
            placeholder=""
          />
        </div>
      </div>
      {!userId.length && (
        <div className="row">
          <div className="col-6">
            <CommonInput
              label="Email"
              required
              value={email}
              setValue={setEmail}
              active={false}
              locked={false}
              placeholder=""
            />
          </div>
          <div className="col-6">
            <CommonInput
              label="Phone"
              value={phone}
              setValue={setPhone}
              active={false}
              locked={false}
              placeholder=""
            />
          </div>
          <div className="col-6">
            <CommonInput
              inputType="password"
              required
              label="Password"
              value={pwd}
              setValue={setPwd}
              active={false}
              locked={false}
              placeholder=""
            />
          </div>
          <div className="col-6">
            <CommonInput
              inputType="password"
              required
              label="Confirm Password"
              value={confirmPwd}
              setValue={setConfirmPwd}
              active={false}
              locked={false}
              placeholder=""
            />
          </div>
        </div>
      )}
      <div className="row">
        {/*  <div className="col-4">*/}
        {/*    <CommonSelect*/}
        {/*        value={organization}*/}
        {/*        setValue={setOrganization}*/}
        {/*        options={organizationOptions}*/}
        {/*        label="Organization"*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*  <div className="col-4">*/}
        {/*    <CommonInput label="Occupation" value={occupation} setValue={setOccupation} active={false} locked={false}*/}
        {/*                 placeholder=""/>*/}
        {/*  </div>*/}
        {/*  <div className="col-4">*/}
        {/*    <CommonInput label="Phone Number" value={phone} setValue={setPhone} active={false} locked={false}*/}
        {/*                 placeholder=""/>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="row mb-4 pb-3">*/}
        {/*  <div className="col-4">*/}
        {/*    <CommonInput label="Street Address 1" value={address1} setValue={setAddress1} active={false} locked={false}*/}
        {/*                 placeholder=""/>*/}
        {/*  </div>*/}
        {/*  <div className="col-4">*/}
        {/*    <CommonInput label="Street Address 2 (optional)" value={address2} setValue={setAddress2} active={false} locked={false}*/}
        {/*                 placeholder=""/>*/}
        {/*  </div>*/}
        {/*  <div className="col-4">*/}
        {/*    <CommonInput label="City" value={city} setValue={setCity} active={false} locked={false} placeholder=""/>*/}
        {/*  </div>*/}
        {/*  <div className="col-4">*/}
        {/*    <CommonInput label="State / Province" value={province} setValue={setProvince} active={false} locked={false}*/}
        {/*                 placeholder=""/>*/}
        {/*  </div>*/}
        {/*  <div className="col-4">*/}
        {/*    <CommonInput label="Country" value={country} setValue={setCountry} active={false} locked={false}*/}
        {/*                 placeholder=""/>*/}
        {/*  </div>*/}
        {/*  <div className="col-4">*/}
        {/*    <CommonInput label="Zip-Code" value={zipCode} setValue={setZipCode} active={false} locked={false}*/}
        {/*                 placeholder=""/>*/}
        {/*  </div>*/}
      </div>
      {/*<div className="row mb-4 pb-3">*/}
      {/*  <div className="col-4">*/}
      {/*    <CommonInput label="Status" value={status} setValue={setStatus} active={false} locked={false}*/}
      {/*                 placeholder=""/>*/}
      {/*  </div>*/}
      {/*  <div className="col-4">*/}
      {/*    <CommonSelect*/}
      {/*        value={role}*/}
      {/*        setValue={setRole}*/}
      {/*        options={roleOptions}*/}
      {/*        label="Role"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="mt-5 mb-4 pb-2 d-flex justify-content-start">
        <EffectedButton
          className="mr-2"
          padding={20}
          height={45}
          noSpan
          marginLeft="0px"
          onClick={handleSubmit}
        >
          <StyledTypo
            color="#fff"
            size={1.125}
            line={25.2 / 18}
            letter="0"
            family="Roboto"
            fontWeight={400}
          >
            Confirm {`${userId.length ? "Update" : "Add"}`} User
          </StyledTypo>
        </EffectedButton>
        <EffectedButton
          background={colors.blue}
          padding={20}
          height={45}
          noSpan
          marginLeft="0px"
          onClick={() => history.push("/manage")}
        >
          <StyledTypo
            color="#fff"
            size={1.125}
            line={25.2 / 18}
            letter="0"
            family="Roboto"
            fontWeight={700}
          >
            Cancel
          </StyledTypo>
        </EffectedButton>
      </div>
    </AddUserContainer>
  );
};

function mapStateToProps(state) {
  const { updateUserFlag, createUserFlag } = state.users;
  return {
    createUserFlag,
    updateUserFlag,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addUserAction: (data) => dispatch(usersActions.addUserAction(data)),
    clearCreateUserFlag: () => dispatch(usersActions.clearCreateUserFlag()),
    updateUserAction: (id, payload) =>
      dispatch(usersActions.updateUserAction(id, payload)),
    clearUpdateUserFlag: () => dispatch(usersActions.clearUpdateUserFlag()),
    fetchUsersAction: () => dispatch(usersActions.fetchUserListAction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
