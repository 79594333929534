import { auth } from "../apis";
import { authConstants } from "../constants";
import { showAlert } from "../../helpers/constants";

export const authAction = {
  loginAction,
  logoutAction,
  registerAction,
  initializeRegisterUser,
  clearRegisterFlag,
  setTokenExpired,
  clearExpiredToken,
  clearNewRefreshToken,
  clearDefuseRefreshToken,
  getRefreshToken,
  forgotPasswordRequestAction,
  checkResetTokenAction,
  changePasswordAction,
  clearForgotPasswordFlag,
  clearChangePasswordFlag,
  setLoggedInUserAction,
  setLoggedInStatusAction,
  fetchRecaptchaSecretKeyAction,
};

function setTokenExpired(error, failure) {
  if (error === "invalid-token") {
    return { type: authConstants.EXPIRED_TOKEN };
  } else {
    return failure(error);
  }
}

function loginAction(payload) {
  return (dispatch) => {
    dispatch(request());
    auth.loginWithCredential(payload).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        showAlert(
          "error",
          "Log In",
          error || "Email or Password is not correct."
        );
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: authConstants.LOG_IN_REQUEST };
  }
  function success(user) {
    return { type: authConstants.LOG_IN_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.LOG_IN_FAILURE, error };
  }
}

function registerAction(reg_data) {
  return (dispatch) => {
    dispatch(request());
    auth.registerWithCredential(reg_data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        showAlert("error", "Register", error || "Failed to register.");
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: authConstants.REGISTER_REQUEST };
  }
  function success(user) {
    return { type: authConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.REGISTER_FAILURE, error };
  }
}

function setLoggedInUserAction(loggedInUser) {
  return (dispatch) => {
    dispatch({ type: authConstants.SET_LOGGED_IN_USER, loggedInUser });
  };
}

function setLoggedInStatusAction(loggedInStatus) {
  return (dispatch) => {
    dispatch({ type: authConstants.SET_LOGGED_IN_STATUS, loggedInStatus });
  };
}

function logoutAction(email) {
  return (dispatch) => {
    auth.logout(email).then(
      (data) => {
        dispatch({ type: authConstants.LOG_OUT_REQUEST });
      },
      (error) => {
        dispatch({ type: authConstants.LOG_OUT_REQUEST });
      }
    );
  };
}

function initializeRegisterUser() {
  return (dispatch) => {
    dispatch({ type: authConstants.INITIALIZE_REGISTER_USER });
  };
}

function clearRegisterFlag() {
  return (dispatch) => {
    dispatch({ type: authConstants.CLEAR_REGISTER_FLAG });
  };
}

function clearExpiredToken() {
  return (dispatch) => {
    dispatch({ type: authConstants.CLEAR_EXPIRED_TOKEN });
  };
}

function clearNewRefreshToken() {
  return (dispatch) => {
    dispatch({ type: authConstants.CLEAR_NEW_REFRESH_TOKEN });
  };
}

function clearDefuseRefreshToken() {
  return (dispatch) => {
    dispatch({ type: authConstants.CLEAR_DEFUSE_REFRESH_TOKEN });
  };
}

function getRefreshToken() {
  return (dispatch) => {
    // dispatch(request());
    auth.getRefreshToken().then(
      (data) => {
        let current_user = JSON.parse(localStorage.getItem("current-user"));
        current_user["refresh_token"] = data.refresh_token;
        current_user["user_token"] = data.user_token;
        localStorage.setItem("user_token", data.user_token);
        localStorage.setItem("current-user", JSON.stringify(current_user));
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(user) {
    return { type: authConstants.REFRESH_TOKEN_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.REFRESH_TOKEN_FAILURE, error };
  }
}

function forgotPasswordRequestAction(data) {
  return (dispatch) => {
    dispatch(request());
    auth.forgotPasswordRequest(data).then(
      (data) => {
        dispatch(success(data));
        showAlert(
          "success",
          "Reset Password",
          "Successfully sent reset password request. Please check your email"
        );
      },
      (error) => {
        showAlert(
          "error",
          "Reset Password",
          "Failed to send reset password request."
        );
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: authConstants.FORGOT_PASSWORD_REQUEST };
  }
  function success(status) {
    return { type: authConstants.FORGOT_PASSWORD_SUCCESS, status };
  }
  function failure(error) {
    return { type: authConstants.FORGOT_PASSWORD_FAILURE, error };
  }
}

function clearForgotPasswordFlag() {
  return (dispatch) => {
    dispatch({ type: authConstants.CLEAR_FORGOT_PASSWORD_FLAG });
  };
}

function changePasswordAction(data) {
  return (dispatch) => {
    dispatch(request());
    auth.changePassword(data).then(
      (data) => {
        dispatch(success(data));
        showAlert(
          "success",
          "Change Password",
          "Successfully changed password."
        );
      },
      (error) => {
        dispatch(failure(error));
        showAlert("error", "Change Password", error);
      }
    );
  };

  function request() {
    return { type: authConstants.CHANGE_PASSWORD_REQUEST };
  }
  function success(status) {
    return { type: authConstants.CHANGE_PASSWORD_SUCCESS, status };
  }
  function failure(error) {
    return { type: authConstants.CHANGE_PASSWORD_FAILURE, error };
  }
}

function clearChangePasswordFlag() {
  return (dispatch) => {
    dispatch({ type: authConstants.CLEAR_CHANGE_PASSWORD_FLAG });
  };
}

function checkResetTokenAction(email, token) {
  return (dispatch) => {
    dispatch(request());
    auth.checkResetToken(email, token).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
        showAlert(
          "error",
          "Validate Reset Token",
          "Invalid reset password token"
        );
      }
    );
  };

  function request() {
    return { type: authConstants.CHECK_RESET_TOKEN_REQUEST };
  }
  function success(status) {
    return { type: authConstants.CHECK_RESET_TOKEN_SUCCESS, status };
  }
  function failure(error) {
    return { type: authConstants.CHECK_RESET_TOKEN_FAILURE, error };
  }
}

function fetchRecaptchaSecretKeyAction() {
  return (dispatch) => {
    dispatch(request());
    auth.fetchRecaptchaSecretKey().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
        showAlert(
          "error",
          "Validate Reset Token",
          "Invalid reset password token"
        );
      }
    );
  };

  function request() {
    return { type: authConstants.FETCH_RECAP_SECRET_KEY_REQUEST };
  }
  function success(res) {
    return { type: authConstants.FETCH_RECAP_SECRET_KEY_SUCCESS, res };
  }
  function failure(error) {
    return { type: authConstants.FETCH_RECAP_SECRET_KEY_FAILURE, error };
  }
}
