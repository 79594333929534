import styled from "styled-components";

export const TableWrapper = styled.div`
  padding: ${(props) => props.padding ?? "0px"};
  width: 100%;
  //   margin-bottom: 70px;
  margin-top: ${(props) => props.marginTop ?? "90px"};
  .divider {
    margin: 8px 0 4px;
    border-top: 1px solid rgb(234, 234, 234);
    height: 0;
  }
`;