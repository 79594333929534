/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from 'react-router-dom';

import { iacActions, applicationActions } from "../../../redux/actions";
import { connect } from "react-redux";
import StyledTypo from "../../../components/Typo/StyledTypo";
import InfraList from "./InfraList/InfraList";
import { ReactComponent as AddItemIcon } from "../../../assets/icons/add_item.svg";
import { ReactComponent as RemoveItemIcon } from "../../../assets/icons/remove_item.svg";
import { confirmAlert } from "react-confirm-alert";
import CreateInfraPanel from "./Infra/CreateInfraPanel";
import {
  ViewContainer,
  PageTitle,
  PageMenu,
  FlexComp,
} from "../../../components/PageLayout/Containers";
import { TableWrapper } from "../../../components/PageLayout/Table";
import { BackButton } from "../../../components/PageLayout/BackButton";
import { commonTitle } from "../../../helpers/constants";
import { useTitle } from "../../../helpers/useTtitle";

const pageTitle = `${commonTitle} - Cloud Security - CSPM`

const InfraListLayout = (props) => {
  const {
    fetchApplicationListAction,
    appList,
    deleteApplicationAction,
    addApplicationAction,
    fetchOrgEnvListAction,
    addAppStatus,
    envData,
    appListLoading,
    updateApplicationAction,
    resetAddAppStatusAction,
  } = props;
  const infraListRef = useRef();
  const headerRef = useRef();
  const history = useHistory();
  const [openCreatePanel, setOpenCreatePanel] = useState(false);
  const [updateProject, setUpdateProject] = useState(null);
  const [markedInfras, setMarkedInfras] = useState([]);
  const [, setRenderFlag] = useState(false);

  const togglePanel = () => {
    setOpenCreatePanel((openCreatePanel) => !openCreatePanel);
  };

  useTitle(pageTitle);

  useEffect(() => {
    fetchApplicationListAction();
    fetchOrgEnvListAction();
  }, []);

  useEffect(() => {
    if (!openCreatePanel) {
      setUpdateProject(null);
    }
  }, [openCreatePanel]);

  const chooseProject2Update = (project) => {
    setUpdateProject(project);
    togglePanel();
  };

  const deleteConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>{`You want to delete cloud infrastructure named ${markedInfras?.[0]?.name}?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                deleteApplicationAction(markedInfras?.[0]?.id);
                onClose();
              }}
            >
              Confirm
            </button>
          </div>
        );
      },
    });
  };

  return (
    <ViewContainer>
      <div className="main-container d-flex flex-column">
        <PageTitle
          className="d-flex align-items-center justify-content-between"
          ref={headerRef}
        >
          <StyledTypo className="ml-2 heading">
            <BackButton
              onClick={() => history.push("/cloud")}
              title="Back"
              href="#"
            >
              {" "}
              Cloud Security{" "}
            </BackButton>{" "} - CSPM
          </StyledTypo>
          <FlexComp>
            {/* <PageMenu className="d-flex align-items-center">
              <a
                className="menu-icon"
                onClick={togglePanel}
                title="Add Infra"
                alt="Add Infra"
                href="#"
              >
                <AddItemIcon
                  width={22}
                  height={22}
                  fill="#606060"
                  className="svg-icon"
                />
                <span className="menu-tip">Add Infra</span>
              </a>
              <a
                className={
                  markedInfras.length > 0
                    ? "menu-icon"
                    : "menu-icon menu-disabled"
                }
                onClick={deleteConfirm}
                title="Remove Infra"
                alt="Remove Infrastructure"
                href="#"
              >
                <RemoveItemIcon
                  width={22}
                  height={22}
                  fill="#606060"
                  className="svg-icon"
                />
                <span className="menu-tip">Remove Infra</span>
              </a>
            </PageMenu> */}
            {infraListRef?.current?.getFilterPanel()}
          </FlexComp>
        </PageTitle>
        <TableWrapper
          marginTop={`${
            headerRef?.current?.offsetHeight + headerRef?.current?.offsetTop ||
            100
          }px`}
        >
          <InfraList
            topGap={
              headerRef?.current?.offsetHeight +
                headerRef?.current?.offsetTop || 100
            }
            infras={appList}
            setUpdateProject={chooseProject2Update}
            setMarkedInfras={setMarkedInfras}
            fetchingFlag={appListLoading}
            ref={infraListRef}
            setRenderFlag={setRenderFlag}
          />
        </TableWrapper>
      </div>
      <CreateInfraPanel
        isOpen={openCreatePanel}
        toggle={togglePanel}
        envData={envData}
        addAppStatus={addAppStatus}
        resetAddAppStatusAction={resetAddAppStatusAction}
        updateProject={updateProject}
        addNewApplicationAction={addApplicationAction}
        updateApplicationAction={updateApplicationAction}
      />
    </ViewContainer>
  );
};

function mapStateToProps(state) {
  const { appList, envData, addAppStatus, appListLoading } = state.applications;
  return {
    appList,
    envData,
    addAppStatus,
    appListLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchApplicationListAction: () =>
      dispatch(applicationActions.fetchApplicationListAction()),
    addApplicationAction: (data) =>
      dispatch(applicationActions.addApplicationAction(data)),
    updateApplicationAction: (data) =>
      dispatch(applicationActions.updateApplicationAction(data)),
    resetAddAppStatusAction: () =>
      dispatch(applicationActions.resetAddAppStatusAction()),
    fetchOrgEnvListAction: () =>
      dispatch(applicationActions.fetchOrgEnvListAction()),
    deleteApplicationAction: (app_id) =>
      dispatch(applicationActions.deleteApplicationAction(app_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InfraListLayout);
