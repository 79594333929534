import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { usersActions } from "../../../redux/actions";
import StyledTypo from "../../../components/Typo/StyledTypo";
import styled from "styled-components";
import { connect } from "react-redux";
import { ReactComponent as CloseIcon } from "../../../assets/icons/cancel.svg";
import CommonInput from "../../../components/Input/CommonInput";
import EffectedButton from "../../../components/Buttons/EffectedButton";
import SlidePanel from "../../../components/SlidePanel/SlidePanel";

import "react-modern-drawer/dist/index.css";
import { getCurrentOrganization, getCurrentUser, showAlert } from "../../../helpers/constants";

const CloseWrapper = styled.div`
  border-radius: 10px;
  min-width: 45px;
  min-height: 40px;
  cursor: pointer;
  max-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ButtonGroupWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 10px;
  width: calc(100% - 20px);
`;

const DividerWrapper = styled.div`
  height: 0;
  width: calc(100% + 60px);
  margin-left: -30px;
  border-bottom: 0.5px solid #ccc;
`;

const TitleWrapper = styled.span`
  display: inline-block;
  width: 100%;
  height: 1.4em;
`;

const ContentWrapper = styled.div`
  padding: 20px;
  margin-top: -50px;
  bottom: 20px;
  left: 10px;
  width: calc(100% - 20px);
`;

const AddUserSlide = (props) => {
  const {
    organizations,
    addUserAction,
    createUserFlag,
    clearCreateUserFlag,
    users,
    updateUserAction,
    clearUpdateUserFlag,
    updateUserFlag,
    fetchUsersAction,
    isOpen, closePanel
  } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [organization, setOrganization] = useState("");
  const [role] = useState("user");
  const [userId, setUserId] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [phone, setPhone] = useState("");

  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
  setUserId(
    location?.search ? new URLSearchParams(location?.search).get('id') : ''
  )
}, [])

useEffect(() => {
  setSelectedUser(users.filter(user => user.UUID === userId)?.[0] ?? {})
}, [userId, users])

useEffect(() => {
  if (updateUserFlag === 2) {
    clearUpdateUserFlag()
    closePanel()
  }
}, [updateUserFlag])

useEffect(() => {
  if (selectedUser) {
    setFirstName(selectedUser.first_name)
    setLastName(selectedUser.last_name)
  }
}, [selectedUser])

useEffect(() => {
  if (organizations) {
    setOrganization(
      organizations.filter(org => org.id === getCurrentOrganization())?.[0]
        ?.name
    )
  }
}, [organizations])

const validateInput = () => {
  let msg = ''
  if (!userId.length) {
    if (!email.length) {
      msg = 'Please fill email.'
    } else if (!firstName.length) {
      msg = 'Please fill First Name'
    } else if (!lastName.length) {
      msg = 'Please fill First Name'
    } else if (!pwd.length) {
      msg = 'Please fill Password.'
    } else if (pwd.length < 8) {
      msg = 'Password must have 8 characters at least.'
    } else if (pwd !== confirmPwd) {
      msg =
        'Confirm Password is not same as Password. Please check Password again.'
    } else if (!organization || !organization.length) {
      msg = 'Can not get organization. Please check your organization.'
    }
  } else {
    if (!firstName.length) {
      msg = 'Please fill First Name'
    } else if (!lastName.length) {
      msg = 'Please fill First Name'
    }
  }
  if (msg.length) {
    showAlert('warning', 'Invalid input', msg)
    return false
  }
  return true
}

const handleSubmit = () => {
  if (!validateInput()) return
  let registerData = {
    email,
    first_name: firstName,
    last_name: lastName,
    password: pwd,
    organization_id: getCurrentUser().organization_id,
    role,
    phone: phone,
    type: role
  }

  if(getCurrentUser()?.tid) {
    registerData = {
      ...registerData,
      tid: getCurrentUser()?.tid
    }
  }

  if (userId.length) {
    updateUserAction(userId, { first_name: firstName, last_name: lastName })
  } else {
    addUserAction(registerData)
  }
}

useEffect(() => {
  if (createUserFlag === 2) {
    showAlert('success', 'Success', 'Successfully added a new user.')
    closePanel()
    clearCreateUserFlag()
  } else if (createUserFlag === 3) {
    showAlert('error', 'Error', 'Failed to add a new user')
  }
}, [createUserFlag])


  return (
    <SlidePanel
      slideId="create-infra-project-panel"
      isOpen={isOpen}
      toggle={closePanel}
    >
      <div className="p-3 position-relative mb-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <CloseWrapper className="cursor-pointer" onClick={closePanel}>
            <CloseIcon width={22} fill="#505050" />
          </CloseWrapper>
          <TitleWrapper>
            <StyledTypo className="text-center heading ln-2">
              Add User
            </StyledTypo>
          </TitleWrapper>
        </div>
        <DividerWrapper className="mb-3" />
      </div>
      <ContentWrapper>
        <div className="row">
          <div className="col-6">
            <CommonInput
              label="First Name"
              required
              value={firstName}
              setValue={setFirstName}
              active={true}
              locked={false}
              placeholder=""
            />
          </div>
          <div className="col-6">
            <CommonInput
              label="Last Name"
              required
              value={lastName}
              setValue={setLastName}
              active={false}
              locked={false}
              placeholder=""
            />
          </div>
        </div>
        {!userId.length && (
          <div className="row">
            <div className="col-6">
              <CommonInput
                label="Your Email"
                required
                value={email}
                setValue={setEmail}
                active={false}
                locked={false}
                placeholder=""
              />
            </div>
            <div className="col-6">
              <CommonInput
                label="Phone"
                value={phone}
                setValue={setPhone}
                active={false}
                locked={false}
                placeholder=""
              />
            </div>
            <div className="col-6">
              <CommonInput
                inputType="password"
                required
                label="Password"
                value={pwd}
                setValue={setPwd}
                active={false}
                locked={false}
                placeholder=""
              />
            </div>
            <div className="col-6">
              <CommonInput
                inputType="password"
                required
                label="Confirm Password"
                value={confirmPwd}
                setValue={setConfirmPwd}
                active={false}
                locked={false}
                placeholder=""
              />
            </div>
          </div>
        )}

      </ContentWrapper>
      <ButtonGroupWrapper className="col-12 px-3 d-flex mt-5 align-items-center justify-content-end">
        <EffectedButton
          className="ml-5"
          padding={20}
          height={32}
          noSpan
          marginLeft="0px"
          onClick={handleSubmit}
          background="#5EB1E4"
          width={90}
        >
          <StyledTypo color="#fff" className="label">
            Add
          </StyledTypo>
        </EffectedButton>
        <EffectedButton
          className="ml-3"
          padding={20}
          height={32}
          noSpan
          marginLeft="0px"
          onClick={closePanel}
          width={90}
        >
          <StyledTypo color="#fff" className="label">
            Cancel
          </StyledTypo>
        </EffectedButton>
      </ButtonGroupWrapper>
    </SlidePanel>
  );
};

function mapStateToProps (state) {
  const { updateUserFlag, createUserFlag } = state.users
  return {
    createUserFlag,
    updateUserFlag
  }
}

function mapDispatchToProps (dispatch) {
  return {
    addUserAction: data => dispatch(usersActions.addUserAction(data)),
    clearCreateUserFlag: () => dispatch(usersActions.clearCreateUserFlag()),
    updateUserAction: (id, payload) =>
      dispatch(usersActions.updateUserAction(id, payload)),
    clearUpdateUserFlag: () => dispatch(usersActions.clearUpdateUserFlag()),
    fetchUsersAction: () => dispatch(usersActions.fetchUserListAction())
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AddUserSlide);
