import React, { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import StyledTypo from "../../../components/Typo/StyledTypo";

import UserTable from "./UserTable";
import { currentRole, getCurrentUser } from "../../../helpers/constants";
import ApprovalUserTable from "./ApprovalUserTable";
import { usersActions } from "../../../redux/actions";
import AddUserSlide from './AddUserSlide'

const PanelWrapper = styled.div`
  padding: 15px;
  width: 100%;

  /* white */

  // border: 1px solid #f1f1f1;
  // box-sizing: border-box;
  // border-radius: 0 20px 20px 20px;

  .panel-title-header {
    padding: 0 0 47px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const PanelHeader = styled.div`
  padding: 10px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserListPanel = (props) => {
  const { organizations, users, manageUserStatusAction, deleteUserAction } =
    props;

  const [isOpenAddPanel, setIsOpenAddPanel] = useState(false)

  return (
    <PanelWrapper>
      {currentRole() === "admin" && (
        <div className="mb-3">
          <PanelHeader>
            <StyledTypo className="heading label">
              Approval User List
            </StyledTypo>
          </PanelHeader>
          <div>
            <ApprovalUserTable
              manageUserStatusAction={manageUserStatusAction}
              data={users}
              organizations={organizations}
            />
          </div>
        </div>
      )}
      <div>
        <UserTable
          manageUserStatusAction={manageUserStatusAction}
          deleteUserAction={deleteUserAction}
          data={users}
          organizations={organizations}
          userList={users}
          setIsOpenAddPanel={setIsOpenAddPanel}
        />
        <AddUserSlide organizations={organizations} users={users} isOpen={isOpenAddPanel} closePanel={() => setIsOpenAddPanel(false)} />
      </div>
    </PanelWrapper>
  );
};

function mapStateToProps(state) {}

function mapDispatchToProps(dispatch) {
  return {
    manageUserStatusAction: (id, action) =>
      dispatch(usersActions.manageUserStatusAction(id, action)),
    deleteUserAction: (id, action) =>
      dispatch(usersActions.deleteUserAction(id, action)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListPanel);
