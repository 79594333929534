import { planConstants } from "../constants";

const initialState = {
  new_subscription_result: 0,
  new_subscription_processing: false,
  current_subscription: {},
  payment_methods: []
}

export function plans(state = initialState, action) {
  switch(action.type) {
    case planConstants.CREATE_NEW_SUBSCRIPTION_REQUEST:
      return {...state, new_subscription_processing: true, new_subscription_result: 0}
    case planConstants.CREATE_NEW_SUBSCRIPTION_SUCCESS:
      return {...state, new_subscription_processing: false, new_subscription_result: 1}
    case planConstants.CREATE_NEW_SUBSCRIPTION_FAILURE:
      return {...state, new_subscription_processing: false, new_subscription_result: 2}
    case planConstants.CLEAR_NEW_SUBSCRIPTION_STATUS:
      return {...state, new_subscription_result: 0}

    case planConstants.FETCH_CURRENT_SUBSCRIPTION_SUCCESS:
      return {...state, current_subscription: action.data}
    case planConstants.FETCH_CURRENT_SUBSCRIPTION_FAILURE:
      return {...state, current_subscription: {}}

    case planConstants.UPGRADE_SUBSCRIPTION_REQUEST:
      return {...state, new_subscription_processing: true}
    case planConstants.UPGRADE_SUBSCRIPTION_SUCCESS:
      return {...state, new_subscription_processing: false, current_subscription: action.data}
    case planConstants.UPGRADE_SUBSCRIPTION_FAILURE:
      return {...state, new_subscription_processing: false}

    case planConstants.CLEAR_SUBSCRIPTION:
      return { ...state, current_subscription: {} }

    case planConstants.FETCH_PAYMENT_METHODS_SUCCESS:
      return { ...state, payment_methods: action.data?.data ?? [] }
    case planConstants.FETCH_PAYMENT_METHODS_FAILURE:
      return { ...state, payment_methods: [] }

    case planConstants.CREATE_PAYMENT_SUCCESS:
      return { ...state, payment_methods: [...state.payment_methods, action.data] }
    case planConstants.DELETE_PAYMENT_SUCCESS:
      return {...state, payment_methods: [...state.payment_methods.filter(p => p.id !== action.data.id)]}

    default:
      return {...state}
  }
}
