import React, { useState, useEffect } from "react";
import styled from "styled-components";
import StyledTypo from "../../components/Typo/StyledTypo";
import CommonInput from "../../components/Input/CommonInput";
import EffectedButton from "../../components/Buttons/EffectedButton";
import { DropdownMenu } from "reactstrap";
import { showAlert } from "../../helpers/constants";

const MainContainer = styled(DropdownMenu)`
  width: 480px;
  padding: 33px 41px;

  background: #ffffff;
  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  //box-shadow: 0 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 15px;
  box-shadow: 0 0 0 9999px #00000066;
  // border-radius: ${(props) =>
    props.directly ? "20px 0 20px 20px" : "20px"};
  transform: translate3d(-280px, 40px, 0px) !important;
`;

const StatusWrapper = styled.div`
  background-color: ${(props) => props.color};
  padding: 5px 20px;
  border-radius: 215px;
  display: flex;

  cursor: pointer;
`;

const AddEnvironmentPanel = (props) => {
  const { addNewEnvAction, envData, editData, handleEditEnv } = props;

  const [name, setName] = useState("");
  const [key, setKey] = useState("");
  const [status, setStatus] = useState(true);
  // const [auditEnabled, setAuditEnabled] = useState(true);

  useEffect(() => {
    setName("");
    setKey("");
    setStatus(true);
    props.toggle(false);
  }, [envData]);

  useEffect(() => {
    if (editData) {
      setName(editData.name);
      setStatus(editData.is_active);
      // setAuditEnabled(editData?.auditEnabled)
    }
  }, [editData]);

  const validateInput = () => {
    let msg = "";
    if (editData) {
      if (!name.length) {
        msg = "Please fill environment name.";
      }
    } else {
      if (!name.length) {
        msg = "Please fill environment name.";
      } else if (!key.length) {
        msg = "Please fill environment key.";
      }
    }
    if (msg.length) {
      showAlert("warning", "Invalid input", msg);
      return false;
    }
    return true;
  };

  const submitNewEnvironment = () => {
    if (!validateInput()) return;
    const data = {
      name,
      key: editData ? editData.key : key,
      is_active: editData ? editData.status === "active" : status,
    };
    if (editData) {
      handleEditEnv(data);
    } else {
      addNewEnvAction(data);
    }
  };

  const toggleStatus = () => {
    setStatus((status) => !status);
  };

  return (
    <MainContainer>
      <div className="title mb-2">
        <StyledTypo
          color="#A4AFB7"
          size={1.125}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={700}
        >
          {`${editData ? "Update" : "Add"}`} an Environment
        </StyledTypo>
      </div>
      <div className="sub-title mb-4">
        <StyledTypo
          color="#A4AFB7"
          size={1}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={700}
        >
          You are {`${editData ? "updating a" : "creating a new"}`} Environment
        </StyledTypo>
      </div>
      <div>
        <CommonInput
          label="Name"
          value={name}
          setValue={setName}
          active={false}
          locked={false}
          placeholder=""
        />
      </div>
      {!editData && (
        <div>
          <CommonInput
            label="Key"
            value={key}
            setValue={setKey}
            active={false}
            locked={false}
            placeholder=""
          />
        </div>
      )}
      {/* <div className="d-flex justify-content-between align-items-center mt-4">
        <StyledTypo
            color="#505050"
            size={1}
            line={1.4}
            letter="0"
            family="Roboto"
            fontWeight={400}
            className="mr-5"
          >
            Audit Enable
          </StyledTypo>
        <Switch
          onChange={(checked) =>
            setAuditEnabled(checked)
          }
          checked={auditEnabled}
          onColor="#65C2D7"
          offColor="#ff487f"
        />
      </div> */}
      {!editData && (
        <div className="d-flex justify-content-between align-items-center mt-3 ">
          <StyledTypo
            color="#505050"
            size={1}
            line={1.4}
            letter="0"
            family="Roboto"
            fontWeight={400}
          >
            Status
          </StyledTypo>
          <StatusWrapper
            onClick={toggleStatus}
            color={status ? "#5EB1E4" : "#A4AFB7"}
          >
            <StyledTypo
              color="#fff"
              size={1}
              line={1.4}
              letter="0"
              family="Poppins"
              fontWeight={400}
            >
              {status ? "Active" : "Inactive"}
            </StyledTypo>
          </StatusWrapper>
        </div>
      )}
      <div className="d-flex align-items-center justify-content-between mt-5">
        <div>
          <EffectedButton marginLeft="0" onClick={submitNewEnvironment}>
            <StyledTypo
              color="#fff"
              size={1.125}
              line={25.2 / 18}
              letter="0"
              family="Roboto"
              fontWeight={500}
            >
              {editData ? "Update" : "Create"}
            </StyledTypo>
          </EffectedButton>
        </div>
        <div>
          <EffectedButton
            marginLeft="0"
            background="#485DCD"
            onClick={() => props.toggle(false)}
          >
            <StyledTypo
              color="#fff"
              size={1.125}
              line={25.2 / 18}
              letter="0"
              family="Roboto"
              fontWeight={500}
            >
              Cancel
            </StyledTypo>
          </EffectedButton>
        </div>
      </div>
    </MainContainer>
  );
};

export default AddEnvironmentPanel;
