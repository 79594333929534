import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import StyledTypo from "../../../components/Typo/StyledTypo";
import EffectedButton from "../../../components/Buttons/EffectedButton";

import { ReactComponent as CirclePlusIcon } from "../../../assets/icons/circle_add_icon.svg";

import { currentRole } from "../../../helpers/constants";
import { planActions } from "../../../redux/actions";
import PaymentsTable from "./PaymentsTable";
import DropdownIcon from "../../../components/Buttons/DropdownIcon";
import StyledDropdown from "../../../components/Buttons/StyledDropdown";
import AddPaymentPanel from "../../modals/AddPaymentPanel";

const PanelWrapper = styled.div`
  padding: 20px;
  width: 100%;
  // border: 1px solid #f1f1f1;
  // box-sizing: border-box;
  // border-radius: 0 15px 15px;

  .panel-title-header {
    padding: 0 0 47px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const PanelHeader = styled.div`
  padding: 0px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PaymentsListPanel = (props) => {
  const {
    payment_methods,
    fetchPaymentMethodsAction,
    createPaymentAction,
    removePaymentAction,
  } = props;

  const [paymentList, setPaymentList] = useState([]);

  const [showAddPanel, setShowAddPanel] = useState(false);

  useEffect(() => {
    fetchPaymentMethodsAction();
  }, []);

  useEffect(() => {
    setPaymentList([...payment_methods]);
  }, [payment_methods]);

  return (
    <PanelWrapper>
      <PanelHeader>
        <StyledTypo className="heading label">Payment method list</StyledTypo>
        {currentRole() === "org-admin" && (
          <div>
            <StyledDropdown
              isOpen={showAddPanel}
              toggle={() => setShowAddPanel((showAddPanel) => !showAddPanel)}
            >
              <DropdownIcon>
                <EffectedButton
                  padding={0}
                  height={40}
                  noSpan
                  onClick={() => {}}
                >
                  <CirclePlusIcon />
                  <StyledTypo className="ml-2 label" color="#fff">
                    Add New Payment
                  </StyledTypo>
                </EffectedButton>
              </DropdownIcon>
              {showAddPanel && (
                <AddPaymentPanel
                  toggle={() =>
                    setShowAddPanel((showAddPanel) => !showAddPanel)
                  }
                  createPaymentAction={createPaymentAction}
                />
              )}
            </StyledDropdown>
          </div>
        )}
      </PanelHeader>
      <div>
        <PaymentsTable
          data={paymentList}
          createPaymentAction={createPaymentAction}
          removePaymentAction={removePaymentAction}
        />
      </div>
    </PanelWrapper>
  );
};

function mapStateToProps(state) {
  const { payment_methods } = state.plans;
  return {
    payment_methods,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPaymentMethodsAction: () =>
      dispatch(planActions.fetchPaymentMethodsAction()),
    createPaymentAction: (data) =>
      dispatch(planActions.createPaymentAction(data)),
    removePaymentAction: (pm_id) =>
      dispatch(planActions.removePaymentAction(pm_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsListPanel);
