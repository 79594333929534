import React from "react";
import styled from "styled-components";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import "./SlidePanel.css";

const ScrollWrapper = styled.div`
  height: 99vh;
  overflow: auto;
  padding-right: 10px;
  padding-bottom: 10px;
`;

const SlidePanel = ({
  slideId,
  isOpen,
  toggle,
  direction = "right",
  children,
}) => {
  return (
    <Drawer
      open={isOpen}
      direction={direction}
      className="bla bla bla"
      id={slideId}
      onClose={toggle}
    >
      <ScrollWrapper className="px-3">{children}</ScrollWrapper>
    </Drawer>
  );
};

export default SlidePanel;
