import React, { useState } from 'react'
import './styles.scss'
import Tabs from './components/tabs'

import Sample01 from './analysis/apag-01'
import Sample02 from './analysis/apag-02'
import Sample03 from './analysis/apag-03'
import Sample04 from './analysis/apag-04'
import Sample05 from './analysis/apag-05'

const AttackPathAnalysisGraphs = () => {
  const [staticGraph, setStaticGraph] = useState(true)
  const [panAndZoom, setPanAndZoom] = useState(true)
  const [collapsible, setCollapsible] = useState(false)
  const [directedGraph, setDirectedGraph] = useState(true)

  return (
    <>
      <Tabs>
        <div label='Graph 01'>
          <Sample01
            settings={{ staticGraph, panAndZoom, collapsible, directedGraph }}
          />
        </div>
        <div label='Graph 02'>
          <Sample02
            settings={{ staticGraph, panAndZoom, collapsible, directedGraph }}
          />
        </div>
        <div label='Graph 03'>
          <Sample03
            settings={{ staticGraph, panAndZoom, collapsible, directedGraph }}
          />
        </div>
        <div label='Graph 04'>
          <Sample04
            settings={{ staticGraph, panAndZoom, collapsible, directedGraph }}
          />
        </div>
        <div label='Graph 05'>
          <Sample05
            settings={{ staticGraph, panAndZoom, collapsible, directedGraph }}
          />
        </div>
      </Tabs>
      <div>
        <label>
          <input
            type='checkbox'
            checked={staticGraph}
            onChange={() => setStaticGraph(prev => !prev)}
          />
          <span>Static Graph</span>
        </label>
        <label>
          <input
            type='checkbox'
            checked={panAndZoom}
            onChange={() => setPanAndZoom(prev => !prev)}
          />
          <span>Pan & Zoom</span>
        </label>
        <label>
          <input
            type='checkbox'
            checked={collapsible}
            onChange={() => setCollapsible(prev => !prev)}
          />
          <span>Collapsible</span>
        </label>
        <label>
          <input
            type='checkbox'
            checked={directedGraph}
            onChange={() => setDirectedGraph(prev => !prev)}
          />
          <span>Directed Graph</span>
        </label>
      </div>
    </>
  )
}

export default AttackPathAnalysisGraphs;
