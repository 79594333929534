import React, { useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { PrivateRoute } from "../helpers";
import { NotificationContainer } from "react-notifications";

import Login from "./auth/Login";
import Register from "./auth/Register";
import ForgotPassword from "./auth/ForgotPassword";
import EmailSentAlertPage from "./auth/EmailSentAlertPage";
import ChangePassword from "./auth/ChangePassword";

import Observe from "./dashboard/Observe";
import Observe2 from "./dashboard/Observe2";
import Deploy from "./dashboard/Deploy";
import Manage from "./dashboard/Manage";
import History from "./dashboard/History";
import UserGuide from "./guide_documentation/UserGuide";

import { MainContext } from "../contexts";
import Index from "./dashboard/Index";
import Profile from "./dashboard/Profile";
import Plan from "./dashboard/Plan";
import IaC from "./dashboard/IaC";
import IaCItem from "./dashboard/IaCItem";
import Home from "./dashboard/Home";
import CloudSecurity from "./dashboard/CloudSecurity";
import CloudInfra from "./dashboard/CloudInfra";
import Trends from "./dashboard/Trends";
import CWP from "./dashboard/CWP";
import CWPWorkload from "./dashboard/CWPWorkload";
import KSPM from "./dashboard/KSPM";
import KSPMCluster from "./dashboard/KSPMCluster";
import AttackPathAnalysisGraphs from "./graphs/graphs";
import CloudWrapper from "./dashboard/Cloud";
import APAGPreview from "./graphs/apag-preview";
import APAGInstancePreview from "./graphs/apag-instance";
import APAGSGAssetsPreview from "./graphs/apag-sg";
import APAGNetworkAssetsPreview from "./graphs/apag-network";
import CloudDashboard from "./dashboard/CloudDashboard";
import PentestingSettings from "./dashboard/PentestingSettings";
import Pentesting from "./dashboard/Pentesting";

const App = ({ firebase }) => {
  const [trackHistory, setTrackHistory] = useState([]);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [newRefreshToken, setNewRefreshToken] = useState(false);
  const [gIacProjects, setGIacProjects] = useState([]);
  const [gInfras, setGInfras] = useState([]);

  return (
    <MainContext.Provider
      value={{
        firebase,
        trackHistory,
        setTrackHistory,
        tokenExpired,
        setTokenExpired,
        newRefreshToken,
        setNewRefreshToken,
        gIacProjects,
        setGIacProjects,
        gInfras,
        setGInfras,
      }}
    >
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/email-sent" component={EmailSentAlertPage} />
          <Route path="/change-password" component={ChangePassword} />
          <Route path="/doc" component={UserGuide} />

          <Route path="/" exact component={Index} />
          <Route path="/plan" component={Plan} />

          <PrivateRoute path="/deploy" exact component={Deploy} />
          <PrivateRoute path="/dashboard" exact component={Home} />
          <PrivateRoute path="/iac" exact component={IaC} />
          <PrivateRoute path="/iac/:id" component={IaCItem} />
          <PrivateRoute path="/cwp" exact component={CWP} />
          <PrivateRoute path="/cwp/:id" component={CWPWorkload} />
          <PrivateRoute path="/kspm" exact component={KSPM} />
          <PrivateRoute path="/kspm/:id" component={KSPMCluster} />
          <PrivateRoute path="/cspm" exact component={CloudSecurity} />
          <PrivateRoute path="/cspm/:id" component={CloudInfra} />
          <PrivateRoute path="/cloud" component={CloudWrapper} />
          <PrivateRoute path="/manage" component={Manage} />
          <PrivateRoute path="/trends" component={Trends} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/observe" exact component={Observe} />
          <PrivateRoute path="/observe2" component={Observe2} />
          <PrivateRoute path="/history" exact component={History} />
          <PrivateRoute path="/apag" exact component={AttackPathAnalysisGraphs} />
          <PrivateRoute path="/apag-user-assets" exact component={APAGPreview} />
          <PrivateRoute path="/apag-instance-cve" exact component={APAGInstancePreview} />
          <PrivateRoute path="/apag-sg" exact component={APAGSGAssetsPreview} />
          <PrivateRoute path="/apag-network" exact component={APAGNetworkAssetsPreview} />
          <PrivateRoute path="/cloud2" exact component={CloudDashboard} />
          <PrivateRoute path="/pentesting-settings" exact component={PentestingSettings} />
          <PrivateRoute path="/pentesting" exact component={Pentesting} />
        </Switch>
      </BrowserRouter>
      <NotificationContainer />
    </MainContext.Provider>
  );
};

export default App;
