import React, { useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";

import styled from "styled-components";

import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";

import * as global from "../../helpers/constants";
import { authAction } from "../../redux/actions";
import { connect } from "react-redux";
import DropdownIcon from "../Buttons/DropdownIcon";
import StyledDropdown from "../Buttons/StyledDropdown";
import { DropdownMenu } from "reactstrap";
import StyledTypo from "../Typo/StyledTypo";
import { ReactComponent as UserIcon } from "../../assets/icons/account.svg";

const Menu = styled.div`
  width: 101px;
  height: 762px;
  position: fixed;

  background: linear-gradient(359.21deg, #76eeb5 -20.98%, #58a3ee 100.74%);
  border: none;
  border-radius: 0 0 60px 0;
  padding: 30px 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${global.colors.menu_inactive};
  z-index: 100;
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 37 64 / 35%) 0px -2px 6px 0px inset;

  p:hover {
    color: #58a3ee !important;
  }
  a:hover {
    text-decoration: none;
    color: inherit;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-bottom: 10px;
  margin-bottom: 28px;
  cursor: pointer;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const MenuItemLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8.9px;
  padding: 8.6px 0 12px;
  color: ${(props) =>
    props.active ? global.colors.menu_active : global.colors.menu_inactive};
  &:hover {
    text-decoration: none;
  }
`;

const ActiveMenuItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 101px;
  align-items: center;
  // margin-left: 5px;
  background: #f1f1f1;
  box-shadow: inset 1px 1px 5px #777;
  // box-shadow: inset 6px 3px 10px rgba(27, 64, 152, 0.15);
  // border-radius: 20px 0px 0px 20px;
  color: ${global.colors.menu_active};
`;

const InActiveMenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: 200% 100%;
  background-position: 100%;
  display: inline-block;
  position: relative;
  color: #fff;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 9px;
    left: 0;
    background-color: #fff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  &:hover {
    background-position: 0 100%;
    color: #fff !important;
  }
`;

const PicWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  box-shadow: rgb(150 150 150) 1px 1px 1px inset;
  img {
    object-fit: cover;
    object-position: 50% 0%;
    height: 60px;
    border-radius: 50%;
  }
`;

const DropdownMenuWrapper = styled(DropdownMenu)`
  width: 150px;
  padding: 15px 25px;

  background: #f1f1f1;
  border: 2px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: inset -4px 4px 10px rgba(27, 64, 152, 0.1);
  transform: translate3d(105px, 0px, 0px) !important;
  z-index: 21;
  position: relative;

  // &::after {
  //   content: " ";
  //   position: absolute;
  //   top: calc(50% - 7px); /* At the bottom of the tooltip */
  //   left: -1px;
  //   margin-left: -14px;
  //   border-width: 7px;
  //   border-style: solid;
  //   box-shadow: inset -4px 4px 10px rgba(27, 64, 152, 0.1);
  //   border-color: transparent rgba(27, 64, 152, 0.4) transparent transparent;
  // }
`;

const MenuItem = (props) => {
  const { menu, link } = props;

  const active = link.includes(menu.path) || menu?.submenu?.filter(sub => link.includes(sub))?.length > 0;

  return (
    <>
      {active ? (
        <ActiveMenuItem>
          <MenuItemLink active={active} to={menu.path}>
            {menu.icon.active}
            <span className="font-weight-normal font-size-12">
              {menu.title}
            </span>
          </MenuItemLink>
        </ActiveMenuItem>
      ) : (
        <InActiveMenuItem className="hover-underline-animation">
          <MenuItemLink active={active} to={menu.path}>
            {menu.icon.inactive}
            <span className="font-weight-normal font-size-12 hover-underline-animation">
              {menu.title}
            </span>
          </MenuItemLink>
        </InActiveMenuItem>
      )}
    </>
  );
};

const Sidebar = (props) => {
  const { logoutAction } = props;
  const [showLogoutPanel, setShowLogoutPanel] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const logout = () => {
    const current_user = global.getCurrentUser()
    localStorage.removeItem("current-user");
    localStorage.removeItem("user_token");
    logoutAction(current_user.email);
    history.push("/login");
    // if (current_user?.["organization"]?.["prefix"]) {
    //   window.location.href = `${current_user?.["location_origin"]}/login`;
    // } else {
    //   history.push("/login");
    // }
  };

  return (
    <Menu>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <LogoWrapper onClick={() => history.push("/dashboard")}>
          <LogoIcon fill="#fff" />
          <span className="font-weight-normal font-size-12">MatosSphere</span>
        </LogoWrapper>
        <MenuWrapper className="mt-4">
          {global.menu_list.map((menu_item, idx) => (
            <MenuItem key={idx} menu={menu_item} link={location?.pathname} />
          ))}
        </MenuWrapper>
      </div>
      <div style={{ position: "relative", zIndex: 100 }}>
        <StyledDropdown
          isOpen={showLogoutPanel}
          toggle={() =>
            setShowLogoutPanel((showLogoutPanel) => !showLogoutPanel)
          }
          className="d-flex justify-content-center"
        >
          <DropdownIcon style={{ background: "transparent" }}>
            <PicWrapper>
              <UserIcon width={32} height={32} fill="#505050" />
              {/* {getCurrentUser()?.pic ? (
                <img src={getCurrentUser()?.pic} alt="profile pic" />
              ) : (
                <StyledTypo
                  color="#5EB1E4"
                  size={1.75}
                  line={1.4}
                  letter="0"
                  family="Roboto"
                  fontWeight={700}
                >
                  {getCurrentUser()?.first_name?.substr(0, 1)?.toUpperCase()}
                  {getCurrentUser()?.last_name?.substr(0, 1)?.toUpperCase()}
                </StyledTypo>
              )} */}
            </PicWrapper>
          </DropdownIcon>
          {showLogoutPanel && (
            <DropdownMenuWrapper>
              <a
                href="#"
                onClick={() => {
                  history.push(`/profile`);
                  setShowLogoutPanel(false);
                }}
              >
                <StyledTypo
                  className="cursor-pointer mb-2"
                >
                  User Profile
                </StyledTypo>
              </a>
              {/* <a
                href="#"
                onClick={() => {
                  history.push(`/history`);
                  setShowLogoutPanel(false);
                }}
              >
                <StyledTypo
                  className="cursor-pointer mb-2"
                >
                  Notification
                </StyledTypo>
              </a>
              <a
                href="#"
                onClick={() => {
                  history.push(`/manage`);
                  setShowLogoutPanel(false);
                }}
              >
                <StyledTypo
                  className="cursor-pointer mb-2"
                >
                  Settings
                </StyledTypo>
              </a> */}
              <a href="#" onClick={logout}>
                <StyledTypo
                  className="cursor-pointer"
                >
                  Log Out
                </StyledTypo>
              </a>
            </DropdownMenuWrapper>
          )}
        </StyledDropdown>
      </div>
    </Menu>
  );
};

function mapStateToProps(state) {
  const { loggedInUser } = state.auth;
  return { loggedInUser };
}

function mapDispatchToProps(dispatch) {
  return {
    logoutAction: (email) => dispatch(authAction.logoutAction(email)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
