import styled from "styled-components";
import {
  asyncLocalStorage,
  parseSubscription,
  periodOptions,
  planList,
} from "../../helpers/constants";
import StyledTypo from "../../components/Typo/StyledTypo";
import EffectedButton from "../../components/Buttons/EffectedButton";
import React, { useState, useEffect, useCallback } from "react";
import CardInputModal from "../modals/CardInputModal";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert";

const PlanContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "1200px")};
  position: relative;
`;

const PlanContent = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 0.4fr 0.4fr 0.4fr;
  column-gap: 20px;
  padding: 0 15px;
`;

const PlanWrapper = styled.div`
  //background-color: #d1d8e0;
  background: ${(props) => 
    props.type === 'feature' ? 'transparent' :
    !props.active
      ? "#f8f8f8"
      : props.disabled
      ? "#f8f8f8"
      : `${props.badgeColor}33`};
  border-radius: 10px;
  position: relative;
  box-shadow: ${props => props.type === 'feature' ? 'none' : '0 5px 5px rgba(0, 0, 0, 0.15)'};
  z-index: 1;
  padding: 20px;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
  

  cursor: ${(props) =>
    props.type === 'feature' ? 'default' : props.active ? (!props.disabled ? "default" : "pointer") : "default"};
  &:hover {
    box-shadow: ${(props) =>
      props.type === 'feature' ? 'none' :
      props.disabled
        ? "0 5px 5px rgba(0,0,0,.30)"
        : "0 5px 5px rgba(0,0,0,.15)"};
  }

  .badge {
    border-radius: 15px;
    background: ${(props) => props.badgeColor};
    padding: 5px 10px;
    position: absolute;
    left: 10px;
    top: 20px;
  }

  .badge-invalid {
    border-radius: 15px;
    background: transparent;
    border: 1px solid #ff487f;
    padding: 5px 10px;
    position: absolute;
    left: 10px;
    top: 20px;
  }

  .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    top: -10px;
    right: -10px;
  }
  .ribbon__content {
    left: -25px;
    top: 35px;
    //-webkit-transform: rotate(45deg);
    //-ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    display: block;
    width: 240px;
    padding: 10px 0;
    background-color: ${(props) =>
      props.badgeColor ? props.badgeColor : "#f5cd79"};
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.192);
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
    border: 2px dotted #fff;
    outline: 5px solid
      ${(props) => (props.badgeColor ? props.badgeColor : "#f5cd79")};
    font-family: "Roboto";
    font-size: 0.75rem;
    font-weight: 400;
  }
  .ribbon::before {
    top: 0;
    left: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid
      ${(props) => (props.foldColor ? props.foldColor : "#f19066")};
    box-sizing: content-box;
  }
  .ribbon::after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid
      ${(props) => (props.foldColor ? props.foldColor : "#f19066")};
  }
  .horizontal-divider {
    border-top: 1px solid #d2d2d2;
    // width: calc(100% + 40px);
    height: 0;
    margin: 10px 0;
  }

  .period-wrapper {
    width: 150px;
    display: flex;
    height: 25px;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background: ${(props) => props.badgeColor};
    padding: 5px 10px;
    margin: -12.5px auto 0;
    text-align: center;
  }

  .feature-wrapper {
    display: flex;
    justify-content: ${props => props.type !== 'feature' ? 'center' : 'space-between'};
    padding: 7px 0;
    flex-wrap: wrap;
  }

  .feature-container {
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #ccc;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) =>
        props.badgeColor ? props.badgeColor : "#f5cd79"}55;
      border-radius: 10px;
    }
  }
`;

const AcceptButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 35px 10px;
  gap: 15px;
  button:hover p {
    color: #fff !important;
  }
  height: ${props => props.type === 'feature' ? '42px' : 'inherit'};
`;

const PeriodOptionWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 40px;
  justify-content: center;
`;

const PeriodOptionItem = styled.div`
  width: 100px;
  height: 40px;
  border-radius: 30px;
  background-color: ${(props) => (props.active ? "#65C2D7" : "#f8f8f8")};
  p {
    color: ${(props) => (props.active ? "#fff" : "#505050")}!important;
  }
  border: 1px solid ${(props) => (props.active ? "#65C2D7" : "#f8f8f8")};
  &:hover {
    border: 1px solid ${(props) => (props.active ? "#65C2D7" : "#ccc")};
  }
  cursor: ${(props) => (props.active ? "default" : "pointer")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const PlanComponent = (props) => {
  const {
    handleAccept,
    handleCancel,
    new_subscription_processing,
    current_subscription,
    payment_methods,
    handleRefresh,
    changePaymentMethod,
    isPlanPage,
  } = props;
  const [selectedPlan, setSelectedPlan] = useState(planList[1].id);
  const [selectedPeriod, setSelectedPeriod] = useState(
    Object.keys(periodOptions)[0]
  );
  const [showCardInput, setShowCardInput] = useState(false);

  const [chosenPlan, setChosenPlan] = useState(null);
  const [chosenPeriod, setChosenPeriod] = useState(null);

  const [subscription, setSubscription] = useState({});

  const history = useHistory();
  const location = useLocation();

  const isCurrentPlan = useCallback(
    (plan = null) => {
      return (
        (plan ? plan === chosenPlan : selectedPlan === chosenPlan) &&
        selectedPeriod === chosenPeriod
      );
    },
    [selectedPlan, selectedPeriod, chosenPlan, chosenPeriod]
  );

  const isEmptyPlan = useCallback(() => {
    return !chosenPlan;
  }, [chosenPlan]);

  useEffect(() => {
    const parsedSubscription = parseSubscription(current_subscription);
    if (parsedSubscription) {
      setSelectedPlan(parsedSubscription?.plan ?? selectedPlan);
      setSelectedPeriod(parsedSubscription?.period ?? selectedPeriod);
      setChosenPlan(parsedSubscription?.plan);
      setChosenPeriod(parsedSubscription?.period);

      setSubscription(parsedSubscription);
      const subscription = {
        subscription:
          current_subscription.status === "canceled"
            ? {}
            : {
                subscription_id: current_subscription.id,
                plan: parsedSubscription?.plan,
                period: parsedSubscription?.period,
                status: current_subscription.status,
              },
      };
      asyncLocalStorage
        .setItem("subscription", JSON.stringify(subscription))
        .then(function () {
          if (
            !["active", "trialing"].includes(current_subscription.status) &&
            location.pathname !== "/plan"
          ) {
            if (location.pathname !== "/plan") {
              history.push("/plan");
            } else {
              history.push("/login");
            }
          }
        });
    }
  }, [current_subscription]);

  const handleAcceptAction = (selectedPayment, paymentData) => {
    const data = {
      plan: selectedPlan,
      period: selectedPeriod,
      chosenPlan,
    };
    if (isCurrentPlan()) {
      changePaymentMethod({
        type: "card",
        data: paymentData,
        paymentMethod: selectedPayment.value,
      });
    } else {
      handleAccept(
        chosenPlan
          ? data
          : {
              ...data,
              type: "card",
              data: paymentData,
              payment_method: selectedPayment.value,
            }
      );
    }
  };

  const acceptConfirm = (plan_id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>
              {isCurrentPlan(plan_id)
                ? "Do you want to modify current subscription?"
                : `Do you want to subscribe selected plan ${selectedPlan}-${selectedPeriod}"?`}
            </p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                setSelectedPlan(plan_id);
                setShowCardInput(true);
                onClose();
              }}
            >
              Confirm
            </button>
          </div>
        );
      },
    });
  };

  const cancelConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>{`You want to cancel current plan ${chosenPlan}-${chosenPeriod}"?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                handleCancel();
                onClose();
              }}
            >
              Confirm
            </button>
          </div>
        );
      },
    });
  };

  return (
    <PlanContainer width={props?.width}>
      <div className="d-flex justify-content-center">
        <StyledTypo className="mb-3 heading label">Choose a plan</StyledTypo>
      </div>
      {isPlanPage && (
        <LoginButtonWrapper>
          <EffectedButton
            className="mt-2"
            width="120px"
            marginLeft="0px"
            background="#5EB1E4"
            padding={0}
            height={32}
            noSpan
            onClick={() => history.push("/login")}
          >
            <StyledTypo color="#fff" className="label">
              Sign In
            </StyledTypo>
          </EffectedButton>
        </LoginButtonWrapper>
      )}
      <PeriodOptionWrapper>
        {Object.keys(periodOptions).map((p) => (
          <PeriodOptionItem
            active={selectedPeriod === p}
            onClick={() => setSelectedPeriod(p)}
          >
            <StyledTypo>{periodOptions[p]["label"]}</StyledTypo>
          </PeriodOptionItem>
        ))}
      </PeriodOptionWrapper>
      <PlanContent>
        {planList.map((plan) => (
          <PlanWrapper
            badgeColor={plan.color}
            foldColor={plan.foldColor}
            disabled={plan.id !== chosenPlan || selectedPeriod !== chosenPeriod}
            active={plan.active}
            onClick={() => plan.active && setSelectedPlan(plan.id)}
            type={plan.id}
          >
            {plan.id !== 'feature' && (
              <div className="ribbon">
                <span className="ribbon__content">{plan.title}</span>
              </div>
            )}
            {plan.id === chosenPlan &&
              chosenPeriod === selectedPeriod &&
              plan.active &&
              (subscription.status === "Active" ? (
                <div className="badge">
                  <StyledTypo color="#fff" size={0.7}>
                    {subscription.status}
                  </StyledTypo>
                </div>
              ) : (
                <div className="badge-invalid">
                  <StyledTypo color="#ff487f" size={0.7}>
                    {subscription.status}
                  </StyledTypo>
                </div>
              ))}
            <div className="d-flex align-items-end mb-4 mt-5 justify-content-center">
              <StyledTypo size={1.2} fontWeight={500}>{`${
                plan.noUnit ? "" : "$"
              }${plan.price[selectedPeriod].toLocaleString(
                "en-US"
              )}`}</StyledTypo>
              <StyledTypo className="ml-2" size={0.8}>
                {plan.noUnit ? "" : `/${periodOptions[selectedPeriod].abbr}`}
              </StyledTypo>
            </div>
            <div className="text-center mb-4">
              <StyledTypo>{plan.description ?? ''}&nbsp;</StyledTypo>
            </div>

            <AcceptButtonWrapper type={plan.id}>
              {plan.id !== 'feature' && isCurrentPlan(plan.id) && (
                <EffectedButton
                  outline
                  width="100px"
                  marginLeft="0"
                  background={plan.color}
                  padding={0}
                  height={32}
                  noSpan
                  onClick={cancelConfirm}
                  disabled={new_subscription_processing || !chosenPlan}
                >
                  <StyledTypo color={plan.color} className="label">
                    Cancel
                  </StyledTypo>
                </EffectedButton>
              )}
              {plan.id !== 'feature' && (
                <EffectedButton
                  width="100px"
                  marginLeft="0"
                  background={plan.color}
                  padding={0}
                  height={32}
                  noSpan
                  onClick={() => acceptConfirm(plan.id)}
                  disabled={new_subscription_processing}
                >
                  <StyledTypo color="#fff" size={0.8}>
                    {new_subscription_processing
                      ? "Processing..."
                      : isCurrentPlan(plan.id)
                      ? "Modify"
                      : !isEmptyPlan() &&
                        plan.id !== chosenPlan &&
                        plan.id !== "free"
                      ? "Upgrade"
                      : "Subscribe"}
                  </StyledTypo>
                </EffectedButton>
              )}
            </AcceptButtonWrapper>

            <div className="horizontal-divider">
              {plan.id === chosenPlan &&
                chosenPeriod === selectedPeriod &&
                plan.active && (
                  <div className="period-wrapper">
                    <StyledTypo color="#fff">
                      Until{" "}
                      {moment(
                        parseInt(`${subscription.current_period_end}000`)
                      ).format("MMM D, YYYY")}
                    </StyledTypo>
                  </div>
                )}
            </div>
            <div className="feature-container">
              {plan.features
                .filter((feature) => feature.active)
                .map((feature) => (
                  <div>
                    <div className="feature-wrapper">
                      {!feature?.name ? <StyledTypo className={plan.id !== 'feature' ? '' : 'text-center'}>&nbsp;</StyledTypo> : 
                      feature.name === 'check' ? <StyledTypo className={plan.id !== 'feature' ? '' : 'text-center'}>&#x2713;</StyledTypo> :
                      (
                        <StyledTypo className={plan.id !== 'feature' ? '' : 'text-center'}>{feature.name}</StyledTypo>
                      )}
                      {!!feature.value.length && (
                        <StyledTypo className={plan.id !== 'feature' ? '' : 'text-center'}>
                          {feature.value}
                        </StyledTypo>
                      )}
                    </div>
                    {feature.children && (
                      <div className={plan.id !== 'feature' ? 'text-center' : 'pl-3'}>
                        {feature.children.map((each) => 
                          each === 'check' ? <StyledTypo className="pb-2">&#x2713;</StyledTypo> : 
                        (
                          <StyledTypo className="pb-2">
                            {each}
                          </StyledTypo>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </PlanWrapper>
        ))}
      </PlanContent>
      {showCardInput && (
        <CardInputModal
          modal={showCardInput}
          toggle={() => setShowCardInput((showCardInput) => !showCardInput)}
          payment_methods={payment_methods}
          default_payment={
            isCurrentPlan()
              ? current_subscription?.default_payment_method
              : null
          }
          handleAccept={handleAcceptAction}
        />
      )}
      <AcceptButtonWrapper>
        <EffectedButton
          className="mt-4"
          outline
          width="150px"
          marginLeft="100px"
          background="#5EB1E4"
          padding={0}
          height={40}
          noSpan
          onClick={handleRefresh}
        >
          <StyledTypo className="ml-2 label" color="#5EB1E4">
            Refresh
          </StyledTypo>
        </EffectedButton>
      </AcceptButtonWrapper>
    </PlanContainer>
  );
};

export default PlanComponent;
