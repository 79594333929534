export const applicationConstants = {
  FETCH_APP_LIST_REQUEST: "FETCH_APP_LIST_REQUEST",
  FETCH_APP_LIST_SUCCESS: "FETCH_APP_LIST_SUCCESS",
  FETCH_APP_LIST_FAILURE: "FETCH_APP_LIST_FAILURE",

  FETCH_DEMO_LIST_REQUEST: "FETCH_DEMO_LIST_REQUEST",
  FETCH_DEMO_LIST_SUCCESS: "FETCH_DEMO_LIST_SUCCESS",
  FETCH_DEMO_LIST_FAILURE: "FETCH_DEMO_LIST_FAILURE",

  FETCH_APP_HEALTH_REQUEST: "FETCH_APP_HEALTH_REQUEST",
  FETCH_APP_HEALTH_SUCCESS: "FETCH_APP_HEALTH_SUCCESS",
  FETCH_APP_HEALTH_FAILURE: "FETCH_APP_HEALTH_FAILURE",

  FETCH_APP_CLUSTER_REQUEST: "FETCH_APP_CLUSTER_REQUEST",
  FETCH_APP_CLUSTER_SUCCESS: "FETCH_APP_CLUSTER_SUCCESS",
  FETCH_APP_CLUSTER_FAILURE: "FETCH_APP_CLUSTER_FAILURE",

  FETCH_ACTIVITY_FEEDS_REQUEST: "FETCH_ACTIVITY_FEEDS_REQUEST",
  FETCH_ACTIVITY_FEEDS_SUCCESS: "FETCH_ACTIVITY_FEEDS_SUCCESS",
  FETCH_ACTIVITY_FEEDS_FAILURE: "FETCH_ACTIVITY_FEEDS_FAILURE",

  ADD_NEW_APPLICATION_REQUEST: "ADD_NEW_APPLICATION_REQUEST",
  ADD_NEW_APPLICATION_SUCCESS: "ADD_NEW_APPLICATION_SUCCESS",
  ADD_NEW_APPLICATION_FAILURE: "ADD_NEW_APPLICATION_FAILURE",

  UPDATE_APPLICATION_REQUEST: "UPDATE_APPLICATION_REQUEST",
  UPDATE_APPLICATION_SUCCESS: "UPDATE_APPLICATION_SUCCESS",
  UPDATE_APPLICATION_FAILURE: "UPDATE_APPLICATION_FAILURE",

  DELETE_APPLICATION_REQUEST: "DELETE_APPLICATION_REQUEST",
  DELETE_APPLICATION_SUCCESS: "DELETE_APPLICATION_SUCCESS",
  DELETE_APPLICATION_FAILURE: "DELETE_APPLICATION_FAILURE",

  FETCH_REMEDIATION_REQUIRED_REQUEST: "FETCH_REMEDIATION_REQUIRED_REQUEST",
  FETCH_REMEDIATION_REQUIRED_SUCCESS: "FETCH_REMEDIATION_REQUIRED_SUCCESS",
  FETCH_REMEDIATION_REQUIRED_FAILURE: "FETCH_REMEDIATION_REQUIRED_FAILURE",

  FETCH_RESOURCE_SUMMARY_REQUEST: "FETCH_RESOURCE_SUMMARY_REQUEST",
  FETCH_RESOURCE_SUMMARY_SUCCESS: "FETCH_RESOURCE_SUMMARY_SUCCESS",
  FETCH_RESOURCE_SUMMARY_FAILURE: "FETCH_RESOURCE_SUMMARY_FAILURE",

  FETCH_ORG_ENV_LIST_REQUEST: "FETCH_ORG_ENV_LIST_REQUEST",
  FETCH_ORG_ENV_LIST_SUCCESS: "FETCH_ORG_ENV_LIST_SUCCESS",
  FETCH_ORG_ENV_LIST_FAILURE: "FETCH_ORG_ENV_LIST_FAILURE",

  UPDATE_ORG_SCAN_SETTING_REQUEST: "UPDATE_ORG_SCAN_SETTING_REQUEST",
  UPDATE_ORG_SCAN_SETTING_SUCCESS: "UPDATE_ORG_SCAN_SETTING_SUCCESS",
  UPDATE_ORG_SCAN_SETTING_FAILURE: "UPDATE_ORG_SCAN_SETTING_FAILURE",

  UPDATE_ORG_ENV_LIST_REQUEST: "UPDATE_ORG_ENV_LIST_REQUEST",
  UPDATE_ORG_ENV_LIST_SUCCESS: "UPDATE_ORG_ENV_LIST_SUCCESS",
  UPDATE_ORG_ENV_LIST_FAILURE: "UPDATE_ORG_ENV_LIST_FAILURE",

  RESET_ADD_APP_STATUS: "RESET_ADD_APP_STATUS",
  RESET_DELETE_APP_STATUS: "RESET_DELETE_APP_STATUS",
  CLEAR_APP_LIST_LOADING: "CLEAR_APP_LIST_LOADING",

  FETCH_HEALTH_HISTORY_REQUEST: "FETCH_HEALTH_HISTORY_REQUEST",
  FETCH_HEALTH_HISTORY_SUCCESS: "FETCH_HEALTH_HISTORY_SUCCESS",
  FETCH_HEALTH_HISTORY_FAILURE: "FETCH_HEALTH_HISTORY_FAILURE",

  FETCH_RESOURCE_HISTORY_REQUEST: "FETCH_RESOURCE_HISTORY_REQUEST",
  FETCH_RESOURCE_HISTORY_SUCCESS: "FETCH_RESOURCE_HISTORY_SUCCESS",
  FETCH_RESOURCE_HISTORY_FAILURE: "FETCH_RESOURCE_HISTORY_FAILURE",

  FETCH_NOTIFICATIONS_REQUEST: "FETCH_NOTIFICATIONS_REQUEST",
  FETCH_NOTIFICATIONS_SUCCESS: "FETCH_NOTIFICATIONS_SUCCESS",
  FETCH_NOTIFICATIONS_FAILURE: "FETCH_NOTIFICATIONS_FAILURE",

  FETCH_LATEST_HISTORY_RESOURCE_REQUEST:
    "FETCH_LATEST_HISTORY_RESOURCE_REQUEST",
  FETCH_LATEST_HISTORY_RESOURCE_SUCCESS:
    "FETCH_LATEST_HISTORY_RESOURCE_SUCCESS",
  FETCH_LATEST_HISTORY_RESOURCE_FAILURE:
    "FETCH_LATEST_HISTORY_RESOURCE_FAILURE",

  FETCH_HISTORY_APPLICATION_REQUEST: "FETCH_HISTORY_APPLICATION_REQUEST",
  FETCH_HISTORY_APPLICATION_SUCCESS: "FETCH_HISTORY_APPLICATION_SUCCESS",
  FETCH_HISTORY_APPLICATION_FAILURE: "FETCH_HISTORY_APPLICATION_FAILURE",

  FETCH_REMEDIATION_LIST_REQUEST: "FETCH_REMEDIATION_LIST_REQUEST",
  FETCH_REMEDIATION_LIST_SUCCESS: "FETCH_REMEDIATION_LIST_SUCCESS",
  FETCH_REMEDIATION_LIST_FAILURE: "FETCH_REMEDIATION_LIST_FAILURE",

  FETCH_SOLUTIONS_REQUEST: "FETCH_SOLUTIONS_REQUEST",
  FETCH_SOLUTIONS_SUCCESS: "FETCH_SOLUTIONS_SUCCESS",
  FETCH_SOLUTIONS_FAILURE: "FETCH_SOLUTIONS_FAILURE",

  FETCH_REMEDIATION_LOGS_REQUEST: "FETCH_REMEDIATION_LOGS_REQUEST",
  FETCH_REMEDIATION_LOGS_SUCCESS: "FETCH_REMEDIATION_LOGS_SUCCESS",
  FETCH_REMEDIATION_LOGS_FAILURE: "FETCH_REMEDIATION_LOGS_FAILURE",

  DOWNLOAD_REPORT_REQUEST: "DOWNLOAD_REPORT_REQUEST",
  DOWNLOAD_REPORT_SUCCESS: "DOWNLOAD_REPORT_SUCCESS",
  DOWNLOAD_REPORT_FAILURE: "DOWNLOAD_REPORT_FAILURE",

  TRIGGER_RESOURCE_SUCCESS: "TRIGGER_RESOURCE_SUCCESS",
  TRIGGER_RESOURCE_FAILURE: "TRIGGER_RESOURCE_FAILURE",

  TRIGGER_SINGLE_RESOURCE_SUCCESS: "TRIGGER_SINGLE_RESOURCE_SUCCESS",
  TRIGGER_SINGLE_RESOURCE_FAILURE: "TRIGGER_SINGLE_RESOURCE_FAILURE",

  TRIGGER_STATUS_SUCCESS: "TRIGGER_STATUS_SUCCESS",

  SINGLE_TRIGGER_STATUS_SUCCESS: "SINGLE_TRIGGER_STATUS_SUCCESS",

  FETCH_STEAMPIPE_RESULT_REQUEST: "FETCH_STEAMPIPE_RESULT_REQUEST",
  FETCH_STEAMPIPE_RESULT_SUCCESS: "FETCH_STEAMPIPE_RESULT_SUCCESS",
  FETCH_STEAMPIPE_RESULT_FAILURE: "FETCH_STEAMPIPE_RESULT_FAILURE",

  TRIGGER_STEAMPIPE_REQUEST: "TRIGGER_STEAMPIPE_REQUEST",
  TRIGGER_STEAMPIPE_SUCCESS: "TRIGGER_STEAMPIPE_SUCCESS",
  TRIGGER_STEAMPIPE_FAILURE: "TRIGGER_STEAMPIPE_FAILURE",

  FETCH_MANUAL_CLOUD_REMEDIATION_REQUEST:
    "FETCH_MANUAL_CLOUD_REMEDIATION_REQUEST",
  FETCH_MANUAL_CLOUD_REMEDIATION_SUCCESS:
    "FETCH_MANUAL_CLOUD_REMEDIATION_SUCCESS",
  FETCH_MANUAL_CLOUD_REMEDIATION_FAILURE:
    "FETCH_MANUAL_CLOUD_REMEDIATION_FAILURE",

  FETCH_PROWLER_RESOURCE_REQUEST: "FETCH_PROWLER_RESOURCE_REQUEST",
  FETCH_PROWLER_RESOURCE_SUCCESS: "FETCH_PROWLER_RESOURCE_SUCCESS",
  FETCH_PROWLER_RESOURCE_FAILURE: "FETCH_PROWLER_RESOURCE_FAILURE",

  FETCH_PROWLER_REMEDIATION_REQUEST: "FETCH_PROWLER_REMEDIATION_REQUEST",
  FETCH_PROWLER_REMEDIATION_SUCCESS: "FETCH_PROWLER_REMEDIATION_SUCCESS",
  FETCH_PROWLER_REMEDIATION_FAILURE: "FETCH_PROWLER_REMEDIATION_FAILURE",

  FETCH_CLOUD_REMEDIATION_SOLUTION_REQUEST:
    "FETCH_CLOUD_REMEDIATION_SOLUTION_REQUEST",
  FETCH_CLOUD_REMEDIATION_SOLUTION_SUCCESS:
    "FETCH_CLOUD_REMEDIATION_SOLUTION_SUCCESS",
  FETCH_CLOUD_REMEDIATION_SOLUTION_FAILURE:
    "FETCH_CLOUD_REMEDIATION_SOLUTION_FAILURE",

  FETCH_COMPLIANCE_TREND_REQUEST: "FETCH_COMPLIANCE_TREND_REQUEST",
  FETCH_COMPLIANCE_TREND_SUCCESS: "FETCH_COMPLIANCE_TREND_SUCCESS",
  FETCH_COMPLIANCE_TREND_FAILURE: "FETCH_COMPLIANCE_TREND_FAILURE",

  FETCH_WORKLOADS_REQUEST: "FETCH_WORKLOADS_REQUEST",
  FETCH_WORKLOADS_SUCCESS: "FETCH_WORKLOADS_SUCCESS",
  FETCH_WORKLOADS_FAILURE: "FETCH_WORKLOADS_FAILURE",

  FETCH_WORKLOAD_SUMMARY_REQUEST: "FETCH_WORKLOAD_SUMMARY_REQUEST",
  FETCH_WORKLOAD_SUMMARY_SUCCESS: "FETCH_WORKLOAD_SUMMARY_SUCCESS",
  FETCH_WORKLOAD_SUMMARY_FAILURE: "FETCH_WORKLOAD_SUMMARY_FAILURE",

  FETCH_WORKLOAD_DETAIL_REQUEST: "FETCH_WORKLOAD_DETAIL_REQUEST",
  FETCH_WORKLOAD_DETAIL_SUCCESS: "FETCH_WORKLOAD_DETAIL_SUCCESS",
  FETCH_WORKLOAD_DETAIL_FAILURE: "FETCH_WORKLOAD_DETAIL_FAILURE",

  FETCH_KSPM_CLUSTERS_REQUEST: "FETCH_KSPM_CLUSTERS_REQUEST",
  FETCH_KSPM_CLUSTERS_SUCCESS: "FETCH_KSPM_CLUSTERS_SUCCESS",
  FETCH_KSPM_CLUSTERS_FAILURE: "FETCH_KSPM_CLUSTERS_FAILURE",

  FETCH_KSPM_CLUSTER_SUMMARY_REQUEST: "FETCH_KSPM_CLUSTER_SUMMARY_REQUEST",
  FETCH_KSPM_CLUSTER_SUMMARY_SUCCESS: "FETCH_KSPM_CLUSTER_SUMMARY_SUCCESS",
  FETCH_KSPM_CLUSTER_SUMMARY_FAILURE: "FETCH_KSPM_CLUSTER_SUMMARY_FAILURE",

  FETCH_KSPM_CLUSTER_DETAIL_REQUEST: "FETCH_KSPM_CLUSTER_DETAIL_REQUEST",
  FETCH_KSPM_CLUSTER_DETAIL_SUCCESS: "FETCH_KSPM_CLUSTER_DETAIL_SUCCESS",
  FETCH_KSPM_CLUSTER_DETAIL_FAILURE: "FETCH_KSPM_CLUSTER_DETAIL_FAILURE",

  FETCH_USER_ASSETS_REQUEST: "FETCH_USER_ASSETS_REQUEST",
  FETCH_USER_ASSETS_SUCCESS: "FETCH_USER_ASSETS_SUCCESS",
  FETCH_USER_ASSETS_FAILURE: "FETCH_USER_ASSETS_FAILURE",

  FETCH_INSTANCE_CVE_GROUP_REQUEST: "FETCH_INSTANCE_CVE_GROUP_REQUEST",
  FETCH_INSTANCE_CVE_GROUP_SUCCESS: "FETCH_INSTANCE_CVE_GROUP_SUCCESS",
  FETCH_INSTANCE_CVE_GROUP_FAILURE: "FETCH_INSTANCE_CVE_GROUP_FAILURE",

  FETCH_INSTANCE_CVE_DETAIL_REQUEST: "FETCH_INSTANCE_CVE_DETAIL_REQUEST",
  FETCH_INSTANCE_CVE_DETAIL_SUCCESS: "FETCH_INSTANCE_CVE_DETAIL_SUCCESS",
  FETCH_INSTANCE_CVE_DETAIL_FAILURE: "FETCH_INSTANCE_CVE_DETAIL_FAILURE",

  FETCH_SG_ASSETS_REQUEST: "FETCH_SG_ASSETS_REQUEST",
  FETCH_SG_ASSETS_SUCCESS: "FETCH_SG_ASSETS_SUCCESS",
  FETCH_SG_ASSETS_FAILURE: "FETCH_SG_ASSETS_FAILURE",

  FETCH_NETWORK_ASSETS_REQUEST: "FETCH_NETWORK_ASSETS_REQUEST",
  FETCH_NETWORK_ASSETS_SUCCESS: "FETCH_NETWORK_ASSETS_SUCCESS",
  FETCH_NETWORK_ASSETS_FAILURE: "FETCH_NETWORK_ASSETS_FAILURE",

  FETCH_GOOGLE_LOGS_REQUEST: "FETCH_GOOGLE_LOGS_REQUEST",
  FETCH_GOOGLE_LOGS_SUCCESS: "FETCH_GOOGLE_LOGS_SUCCESS",
  FETCH_GOOGLE_LOGS_FAILURE: "FETCH_GOOGLE_LOGS_FAILURE",

  FETCH_TOP_ATTACK_PATHS_REQUEST: "FETCH_TOP_ATTACK_PATHS_REQUEST",
  FETCH_TOP_ATTACK_PATHS_SUCCESS: "FETCH_TOP_ATTACK_PATHS_SUCCESS",
  FETCH_TOP_ATTACK_PATHS_FAILURE: "FETCH_TOP_ATTACK_PATHS_FAILURE",

  FETCH_TOP_INFRAS_REQUEST: "FETCH_TOP_INFRAS_REQUEST",
  FETCH_TOP_INFRAS_SUCCESS: "FETCH_TOP_INFRAS_SUCCESS",
  FETCH_TOP_INFRAS_FAILURE: "FETCH_TOP_INFRAS_FAILURE",

  FETCH_TOP_NONCOMPLIANCES_REQUEST: "FETCH_TOP_NONCOMPLIANCES_REQUEST",
  FETCH_TOP_NONCOMPLIANCES_SUCCESS: "FETCH_TOP_NONCOMPLIANCES_SUCCESS",
  FETCH_TOP_NONCOMPLIANCES_FAILURE: "FETCH_TOP_NONCOMPLIANCES_FAILURE",

  FETCH_TOP_IDENTITY_ACCESS_REQUEST: "FETCH_TOP_IDENTITY_ACCESS_REQUEST",
  FETCH_TOP_IDENTITY_ACCESS_SUCCESS: "FETCH_TOP_IDENTITY_ACCESS_SUCCESS",
  FETCH_TOP_IDENTITY_ACCESS_FAILURE: "FETCH_TOP_IDENTITY_ACCESS_FAILURE",

  FETCH_TOP_CONTAINERS_REQUEST: "FETCH_TOP_CONTAINERS_REQUEST",
  FETCH_TOP_CONTAINERS_SUCCESS: "FETCH_TOP_CONTAINERS_SUCCESS",
  FETCH_TOP_CONTAINERS_FAILURE: "FETCH_TOP_CONTAINERS_FAILURE",

  FETCH_TOP_CLUSTERS_REQUEST: "FETCH_TOP_CLUSTERS_REQUEST",
  FETCH_TOP_CLUSTERS_SUCCESS: "FETCH_TOP_CLUSTERS_SUCCESS",
  FETCH_TOP_CLUSTERS_FAILURE: "FETCH_TOP_CLUSTERS_FAILURE",

  FETCH_TOP_DATA_SECURITY_REQUEST: "FETCH_TOP_DATA_SECURITY_REQUEST",
  FETCH_TOP_DATA_SECURITY_SUCCESS: "FETCH_TOP_DATA_SECURITY_SUCCESS",
  FETCH_TOP_DATA_SECURITY_FAILURE: "FETCH_TOP_DATA_SECURITY_FAILURE",

  FETCH_TOP_PUBLIC_EXPOSURE_REQUEST: "FETCH_TOP_PUBLIC_EXPOSURE_REQUEST",
  FETCH_TOP_PUBLIC_EXPOSURE_SUCCESS: "FETCH_TOP_PUBLIC_EXPOSURE_SUCCESS",
  FETCH_TOP_PUBLIC_EXPOSURE_FAILURE: "FETCH_TOP_PUBLIC_EXPOSURE_FAILURE",

  FETCH_TOP_SOFTWARE_UPDATE_REQUEST: "FETCH_TOP_SOFTWARE_UPDATE_REQUEST",
  FETCH_TOP_SOFTWARE_UPDATE_SUCCESS: "FETCH_TOP_SOFTWARE_UPDATE_SUCCESS",
  FETCH_TOP_SOFTWARE_UPDATE_FAILURE: "FETCH_TOP_SOFTWARE_UPDATE_FAILURE",

  FETCH_PENTESTING_CONFIGURATIONS_REQUEST: "FETCH_PENTESTING_CONFIGURATIONS_REQUEST",
  FETCH_PENTESTING_CONFIGURATIONS_SUCCESS: "FETCH_PENTESTING_CONFIGURATIONS_SUCCESS",
  FETCH_PENTESTING_CONFIGURATIONS_FAILURE: "FETCH_PENTESTING_CONFIGURATIONS_FAILURE",

  FETCH_PENTESTING_CONFIGURATION_REQUEST: "FETCH_PENTESTING_CONFIGURATION_REQUEST",
  FETCH_PENTESTING_CONFIGURATION_SUCCESS: "FETCH_PENTESTING_CONFIGURATION_SUCCESS",
  FETCH_PENTESTING_CONFIGURATION_FAILURE: "FETCH_PENTESTING_CONFIGURATION_FAILURE",

  SET_PENTESTING_CONFIGURATION_REQUEST: "SET_PENTESTING_CONFIGURATION_REQUEST",
  SET_PENTESTING_CONFIGURATION_SUCCESS: "SET_PENTESTING_CONFIGURATION_SUCCESS",
  SET_PENTESTING_CONFIGURATION_FAILURE: "SET_PENTESTING_CONFIGURATION_FAILURE",

  SET_SELECTED_PENTESTING_CONFIGURATION_REQUEST: "SET_SELECTED_PENTESTING_CONFIGURATION_REQUEST",
  SET_SELECTED_PENTESTING_CONFIGURATION_SUCCESS: "SET_SELECTED_PENTESTING_CONFIGURATION_SUCCESS",
  SET_SELECTED_PENTESTING_CONFIGURATION_FAILURE: "SET_SELECTED_PENTESTING_CONFIGURATION_FAILURE",

  FETCH_WORKSPACES_REQUEST: "FETCH_WORKSPACES_REQUEST",
  FETCH_WORKSPACES_SUCCESS: "FETCH_WORKSPACES_SUCCESS",
  FETCH_WORKSPACES_FAILURE: "FETCH_WORKSPACES_FAILURE",

  FETCH_WORKSPACE_REQUEST: "FETCH_WORKSPACE_REQUEST",
  FETCH_WORKSPACE_SUCCESS: "FETCH_WORKSPACE_SUCCESS",
  FETCH_WORKSPACE_FAILURE: "FETCH_WORKSPACE_FAILURE",

  CREATE_WORKSPACE_REQUEST: "CREATE_WORKSPACE_REQUEST",
  CREATE_WORKSPACE_SUCCESS: "CREATE_WORKSPACE_SUCCESS",
  CREATE_WORKSPACE_FAILURE: "CREATE_WORKSPACE_FAILURE",

  UPDATE_WORKSPACE_REQUEST: "UPDATE_WORKSPACE_REQUEST",
  UPDATE_WORKSPACE_SUCCESS: "UPDATE_WORKSPACE_SUCCESS",
  UPDATE_WORKSPACE_FAILURE: "UPDATE_WORKSPACE_FAILURE",

  DELETE_WORKSPACE_REQUEST: "DELETE_WORKSPACE_REQUEST",
  DELETE_WORKSPACE_SUCCESS: "DELETE_WORKSPACE_SUCCESS",
  DELETE_WORKSPACE_FAILURE: "DELETE_WORKSPACE_FAILURE",

  CLEAR_CREATE_UPDATE_WORKSPACE_FLAG: "CLEAR_CREATE_UPDATE_WORKSPACE_FLAG",

};
