import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { getCurrentUser, userRoleInfo } from "../../../helpers/constants";
import { ReactComponent as ThreeDotIcon } from "../../../assets/icons/three_dot_icon.svg";

import StyledTypo from "../../../components/Typo/StyledTypo";
import StyledDropdown from "../../../components/Buttons/StyledDropdown";
import DropdownIcon from "../../../components/Buttons/DropdownIcon";
import { DropdownMenu } from "reactstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";

const TableWrapper = styled.div`
  padding-top: 20px;
  .divider {
    margin: 8px 0px 4px;
    //margin: 8px -46.5px 4px -37.5px;
    border-top: 1px solid rgba(164, 175, 183, 0.3);
    height: 0;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 26px;
`;

const EachCell = styled.div`
  width: ${(props) => props.width};
`;

const RecordWrapper = styled.div`
  padding: 8px 26px;
  background: #ffffff;
  //border: 1px solid #f1f1f1;
  box-sizing: border-box;
  //border-radius: 20px;
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const ActionDropdownMenu = styled(DropdownMenu)`
  // --width: ${(props) => props.width - 350}px;
  // left: calc(var(--width) - 50px - 1vw) !important;
  /* white */
  min-width: 150px;
  width: 180px;
  background: #ffffff;
  /* Gray light 2 */

  border: 1px solid #f1f5fa;
  box-sizing: border-box;
  /* box shadow */

  box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 10px;
  padding: 10px 20px;
  transform: translate3d(-130px, -10px, 0px) !important;

  .item:hover p {
    color: #505050 !important;
  }
`;

const ShowingWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .page-link:focus {
    box-shadow: none !important;
  }
  ul.pagination {
    margin-bottom: 0;
    font-size: 0.8rem;
    font-weight: 400;
  }
`;

const StatusWrapper = styled.div`
  background-color: ${(props) => props.color};
  padding: 5px 10px;
  border-radius: 215px;
  display: flex;
`;

const TableHeader = () => {
  return (
    <HeaderWrapper>
      <EachCell width={`${38500 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={0.8}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          User Name
        </StyledTypo>
      </EachCell>
      <EachCell width={`${27100 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={0.8}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          Organization Name
        </StyledTypo>
      </EachCell>
      <EachCell width={`${36200 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={0.8}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          Email
        </StyledTypo>
      </EachCell>
      <EachCell width={`${23600 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={0.8}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          Registered At
        </StyledTypo>
      </EachCell>
      <EachCell width={`${25800 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={0.8}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          Role's
        </StyledTypo>
      </EachCell>
      <EachCell width={`${8400 / 1596}%`}>
        <StyledTypo
          className="text-right"
          color={"#505050"}
          size={0.8}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          Actions
        </StyledTypo>
      </EachCell>
    </HeaderWrapper>
  );
};

const Record = (props) => {
  const { data, isOpenActionMenu, toggleActionMenu, manageUserStatusAction } = props;

    return (
    <RecordWrapper>
      <EachCell width={`${38500 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={0.8}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={300}
        >
          {data.first_name} {data.last_name}
        </StyledTypo>
      </EachCell>
      <EachCell width={`${27100 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={0.8}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={300}
        >
          {data.organization_name}
        </StyledTypo>
      </EachCell>
      <EachCell width={`${36200 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={0.8}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={300}
        >
          {data.email}
        </StyledTypo>
      </EachCell>
      <EachCell width={`${23600 / 1596}%`} className="d-flex">
        <StyledTypo
          color={"#505050"}
          size={0.8}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={300}
        >
          {moment(data.created_at).format("YYYY-MM-DD")}
        </StyledTypo>
      </EachCell>
      <EachCell width={`${25800 / 1596}%`} className="d-flex">
        <StatusWrapper color={userRoleInfo[data.role].color}>
          <StyledTypo
            color={"#fff"}
            size={0.8}
            line={1.4}
            letter="0"
            family="Poppins"
            fontWeight={300}
          >
            {userRoleInfo[data.role].name}
          </StyledTypo>
        </StatusWrapper>
      </EachCell>
      <EachCell width={`${8400 / 1596}%`} className="text-right">
        <StyledDropdown
          isOpen={isOpenActionMenu.filter((item) => item[data.id]).length}
          toggle={() => toggleActionMenu(data.id)}
        >
          <DropdownIcon>
            <ThreeDotIcon />
          </DropdownIcon>
          <ActionDropdownMenu>
            <div
              className="d-flex my-2 align-items-center cursor-pointer item"
              onClick={() => {
                manageUserStatusAction(data.id, "allowed");
                toggleActionMenu(data.id);
              }}
            >
              <StyledTypo
                className="ml-3"
                color={"#66C4D5"}
                size={0.8}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                Allow
              </StyledTypo>
            </div>
            <div
              className="d-flex my-2 align-items-center cursor-pointer item"
              onClick={() => {
                manageUserStatusAction(data.id, "refused");
                toggleActionMenu(data.id);
              }}
            >
              <StyledTypo
                className="ml-3"
                color={"#ff487f"}
                size={0.8}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                Disallow
              </StyledTypo>
            </div>
          </ActionDropdownMenu>
        </StyledDropdown>
      </EachCell>
    </RecordWrapper>
  );
};

const ApprovalUserTable = (props) => {
  const { data, organizations, manageUserStatusAction } = props;
  const [isOpenActionMenu, setIsOpenActionMenu] = useState([]);
  const [usersData, setUsersData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemPerPage = 5;

  useEffect(() => {
    if (organizations.length) {
      let approvalUser = [
        ...data
          .filter((user) => !user.approved)
          .filter((user) => user.UUID !== getCurrentUser().UUID)
          .map((user) => ({
            id: user.UUID,
            first_name: user.first_name,
            last_name: user.last_name,
            organization_name:
              organizations.filter(
                (org) => org?.id === user.organization_id
              )?.[0]?.name ?? "",
            email: user.email,
            role: user.role,
            created_at: user.created_at,
          })),
      ];
      setUsersData(approvalUser);
      setIsOpenActionMenu([
        ...approvalUser.map((item) => ({ [item.id]: false })),
      ]);
    }
  }, [data, organizations]);

  const toggleActionMenu = (key) => {
    setIsOpenActionMenu(
      isOpenActionMenu.map((item) => ({ ...item, [key]: !item[key] }))
    );
  };

  const handlePageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const pageFilter = useCallback(
    (data) => {
      return data.filter(
        (item, idx) =>
          idx >= itemPerPage * (currentPage - 1) &&
          idx < itemPerPage * currentPage
      );
    },
    [data, currentPage]
  );

  const showPaginateLabel = useCallback(
    (data) => {
      return `Showing 
    ${
      itemPerPage * (currentPage - 1) + 1 > data.length
        ? data.length
        : itemPerPage * (currentPage - 1) + 1
    } - 
    ${
      itemPerPage * currentPage > data.length
        ? data.length
        : itemPerPage * currentPage
    } from ${data.length} users`;
    },
    [data, currentPage]
  );

  const handleChange = (id) => {
    setUsersData((deployData) =>
      deployData.map((item) => ({
        ...item,
        selected: item.id === id ? !item.selected : item.selected,
      }))
    );
  };

  return (
    <TableWrapper>
      <TableHeader />
      <div className="divider" />
      {pageFilter(usersData).length ? (
        pageFilter(usersData).map((userData) => (
          <Record
            data={userData}
            isOpenActionMenu={isOpenActionMenu}
            toggleActionMenu={toggleActionMenu}
            handleChange={handleChange}
            manageUserStatusAction={manageUserStatusAction}
          />
        ))
      ) : (
        <div className="text-center my-5">
          <StyledTypo
            color={"#505050"}
            size={0.8}
            line={1.4}
            letter="0"
            family="Poppins"
            fontWeight={300}
          >
            No approval users
          </StyledTypo>
        </div>
      )}
      <ShowingWrapper>
        <StyledTypo
          color={"#505050"}
          size={0.8}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={300}
        >
          {showPaginateLabel(usersData)}
        </StyledTypo>
        <ReactPaginate
          pageCount={(usersData?.length ?? 0) / itemPerPage}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          previousLabel="< Prev"
          nextLabel="Next >"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          forcePage={currentPage - 1}
          onPageChange={handlePageChange}
          activeClassName="active"
        />
      </ShowingWrapper>
    </TableWrapper>
  );
};

export default ApprovalUserTable;
