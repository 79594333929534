import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { applicationActions } from "../../../redux/actions";
import { connect } from "react-redux";
import * as global from "../../../helpers/constants";
import StyledTypo from "../../../components/Typo/StyledTypo";
import WorkloadSummaryTab from "./workload/WorkloadSummaryTab";
import WorkloadDetailsTab from "./workload/WorkloadDetailsTab";
import { ReactComponent as LeftArrow } from "../../../assets/icons/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../../assets/icons/right-arrow.svg";
import { MainContext } from "../../../contexts";
import {
  ViewContainer,
  PageTitle,
  FlexComp,
} from "../../../components/PageLayout/Containers";
import {
  TabHeader,
  TabHeaderItem,
  TabBody,
} from "../../../components/PageLayout/Tab";
import { TableWrapper } from "../../../components/PageLayout/Table";
import { mockWorkloadDetail } from "../../../helpers/mockdata";
import { useTitle } from "../../../helpers/useTtitle";

const BackButton = styled.a`
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  color: #505050;
  :hover {
    text-decoration: none;
  }
`;

const MoveCTAWrapper = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  // align-items: center;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  cursor: pointer;
`;

const WorkloadLayout = (props) => {
  const {
    id: workload_id,
    clusterInfoLoading,
    workloadDetailLoading,
    workloadSummaryLoading,
    fetchWorkloadSummaryAction,
    fetchWorkloadDetailAction,
    workloads, workload_summary, workload_detail
  } = props;

  const history = useHistory();
  const filterPanelRef = useRef();
  const headerRef = useRef();

  const { gInfras } = useContext(MainContext);

  const [tabs, setTabs] = useState(global.cwpTabList);
  const [selectedTab, setSelectedTab] = useState(null);

  const [selectedFilterType, setSelectedFilterType] = useState(null);
  const [preFilterData, setPreFilterData] = useState();
  const [_, setRenderFlag] = useState(false);

  const location = useLocation();

  const [selectedWorkload, setSelectedWorkload] = useState({})
  const [pageTitle, setPageTitle] = useState(global.commonTitle)

  useTitle(pageTitle)

  useEffect(() => {
    setPageTitle(`${global.commonTitle} - Cloud Security - CWPP - ${selectedWorkload?.workload_name}`)
  }, [selectedWorkload])

  useEffect(() => {
    setSelectedTab(
      location?.search
        ? new URLSearchParams(location?.search).get("tab")
        : tabs.filter((item) => item.active)?.[0]?.key
    );
  }, [setSelectedTab]);

  useEffect(() => {
    if (selectedTab) {
      handleSelectedTab(selectedTab);
    }
  }, [selectedTab]);

  const handleSelectedTab = (key) => {
    setPreFilterData(null);
    setTabs([...tabs.map((tab) => ({ ...tab, active: tab.key === key }))]);
  };

  useEffect(() => {
    const selectedInfra = gInfras.filter(
      (workload) => workload.workload_id === workload_id
    )?.[0];
    if (selectedInfra) {
      selectedWorkload?.workload_id !== workload_id && setSelectedWorkload({ ...selectedInfra });
    } else {
      history.push("/cwp");
    }
  }, [workload_id]);

  const onLink = (data, tab = "details") => {
    handleSelectedTab(tab);
    setPreFilterData({ ...data });
  };

  const findIndex = () => {
    return gInfras.findIndex((workload) => workload.workload_id === workload_id);
  };

  const findId = (up = true) => {
    const idx = findIndex();
    const targetIdx = up ? idx + 1 : idx - 1;
    return gInfras.filter((project, idx) => idx === targetIdx)?.[0]?.workload_id;
  };

  useEffect(() => {
    if (workload_id) {
      fetchWorkloadSummaryAction(workload_id);
      fetchWorkloadDetailAction(workload_id);
    }
  }, [workload_id]);

  return (
    <ViewContainer>
      <div className="main-container d-flex flex-column">
        <PageTitle
          className="pb-2 d-flex align-items-center justify-content-between"
          ref={headerRef}
        >
          <StyledTypo className="ml-2 heading">
            <BackButton
              onClick={() => history.push("/cloud")}
              title="Back"
              href="#"
            >
              Cloud Security
            </BackButton>
            {" - "}
            <BackButton
              onClick={() => history.push("/cwp")}
              title="Back"
              href="#"
            >
              CWPP
            </BackButton>
            {" - "}
            <strong>{selectedWorkload?.workload_name}</strong>
          </StyledTypo>
          <TabHeader>
            {tabs.map((tab) => (
              <TabHeaderItem
                className={tab.active && "active"}
                onClick={() => handleSelectedTab(tab.key)}
                key={tab.key}
              >
                {tab.title}
              </TabHeaderItem>
            ))}
          </TabHeader>
          <FlexComp gap="20px">
            {!!tabs.filter((tab) => tab.key === "details" && tab.active)
              .length && filterPanelRef?.current?.getFilterPanel()}
            <FlexComp gap="0px">
              <StyledTypo className="mr-2">
                {findIndex() + 1} of {workloads.length}
              </StyledTypo>
              <MoveCTAWrapper
                disabled={findIndex() === 0}
                onClick={() => history.push(`/cwp/${findId(false)}`)}
              >
                <LeftArrow fill={findIndex() === 0 ? "#A4AFB7" : "#505050"} />
              </MoveCTAWrapper>
              <MoveCTAWrapper
                disabled={findIndex() + 1 === workloads.length}
                onClick={() => history.push(`/cwp/${findId()}`)}
              >
                <RightArrow
                  fill={
                    findIndex() + 1 === workloads.length ? "#A4AFB7" : "#505050"
                  }
                />
              </MoveCTAWrapper>
            </FlexComp>
          </FlexComp>
        </PageTitle>
        <TableWrapper
          marginTop={`${
            headerRef?.current?.offsetHeight + headerRef?.current?.offsetTop ||
            100
          }px`}
        >
          <TabBody>
            {!!tabs.filter((tab) => tab.key !== "details" && tab.active)
              .length && (
              <WorkloadSummaryTab
                workload={selectedWorkload}
                detail={workload_detail?.[workload_id] ?? {}}
                // detail={workload_detail?.[workload_id] ?? mockWorkloadDetail}
                summary={workload_summary?.[workload_id] ?? {}}
                // summary={workload_summary?.[workload_id] ?? global.mockWorkloadSummary}
                clusterInfoLoading={workloadSummaryLoading}
                hideActivity={true}
                type={
                  tabs.filter((tab) => tab.key !== "details" && tab.active)?.[0]
                    ?.key
                }
                onLink={onLink}
              />
            )}
            {!!tabs.filter((tab) => tab.key === "details" && tab.active)
              .length && (
              <WorkloadDetailsTab
                data={workload_detail?.[workload_id] ?? {}}
                dataLoading={workloadDetailLoading}
                selectedWorkload={selectedWorkload}
                setSelectedFilterType={setSelectedFilterType}
                selectedFilterType={selectedFilterType}
                preFilterData={preFilterData}
                ref={filterPanelRef}
                setRenderFlag={setRenderFlag}
              />
            )}
          </TabBody>
        </TableWrapper>
      </div>
    </ViewContainer>
  );
};

function mapStateToProps(state) {
  const {
    workloads, workload_summary, workload_detail, workloadDetailLoading, workloadSummaryLoading
  } = state.applications;
  return {
    workloads, workload_summary, workload_detail, workloadDetailLoading, workloadSummaryLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchWorkloadSummaryAction: (workload_id) => dispatch(applicationActions.fetchWorkloadSummaryAction(workload_id)),
    fetchWorkloadDetailAction: (workload_id) => dispatch(applicationActions.fetchWorkloadDetailAction(workload_id))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkloadLayout);
