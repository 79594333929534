export const authConstants = {
  LOG_IN_REQUEST: 'LOG_IN_REQUEST',
  LOG_IN_SUCCESS: 'LOG_IN_SUCCESS',
  LOG_IN_FAILURE: 'LOG_IN_FAILURE',

  SET_LOGGED_IN_USER: 'SET_LOGGED_IN_USER',
  SET_LOGGED_IN_STATUS: 'SET_LOGGED_IN_STATUS',

  LOG_OUT_REQUEST: 'LOG_OUT_REQUEST',

  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',

  CLEAR_REGISTER_FLAG: 'CLEAR_REGISTER_FLAG',

  INITIALIZE_REGISTER_USER: 'INITIALIZE_REGISTER_USER',

  REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE: 'REFRESH_TOKEN_FAILURE',

  EXPIRED_TOKEN: 'EXPIRED_TOKEN',
  CLEAR_EXPIRED_TOKEN: 'CLEAR_EXPIRED_TOKEN',
  NEW_REFRESH_TOKEN: "NEW_REFRESH_TOKEN",
  CLEAR_NEW_REFRESH_TOKEN: "CLEAR_NEW_REFRESH_TOKEN",
  CLEAR_DEFUSE_REFRESH_TOKEN: "CLEAR_DEFUSE_REFRESH_TOKEN",

  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

  CHECK_RESET_TOKEN_REQUEST: 'CHECK_RESET_TOKEN_REQUEST',
  CHECK_RESET_TOKEN_SUCCESS: 'CHECK_RESET_TOKEN_SUCCESS',
  CHECK_RESET_TOKEN_FAILURE: 'CHECK_RESET_TOKEN_FAILURE',

  FETCH_RECAP_SECRET_KEY_REQUEST: 'FETCH_RECAP_SECRET_KEY_REQUEST',
  FETCH_RECAP_SECRET_KEY_SUCCESS: 'FETCH_RECAP_SECRET_KEY_SUCCESS',
  FETCH_RECAP_SECRET_KEY_FAILURE: 'FETCH_RECAP_SECRET_KEY_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  CLEAR_FORGOT_PASSWORD_FLAG: 'CLEAR_FORGOT_PASSWORD_FLAG',
  CLEAR_CHANGE_PASSWORD_FLAG: 'CLEAR_CHANGE_PASSWORD_FLAG',
}
