import { Graph } from "react-d3-graph";
import { config1 as graphConfig } from "../graph-configs";

const Sample04 = (props) => {
    // graph payload (with minimalist structure)
    const graphData = {
        nodes: [
            { id: 1, name: "Private IP", svg: './images/PrivateIP.png' },
            { id: 2, name: "VPC 01", svg: './images/VPC.png' },
            { id: 3, name: "VPC 02", svg: './images/VPC.png' },
            { id: 4, name: "VPC 03", svg: './images/VPC.png' },
            { id: 5, name: "Subnet 01", svg: './images/Subnet.png' },
            { id: 6, name: "Subnet 02", svg: './images/Subnet.png' },
            { id: 7, name: "Route Table 01", svg: './images/RouteTable.png' },
            { id: 8, name: "Route Table 02", svg: './images/RouteTable.png' },
            { id: 9, name: "Internet Gateway 01", svg: './images/InternetGateway.png' },
            { id: 10, name: "Subnet 03", svg: './images/Subnet.png' },
            { id: 11, name: "Subnet 04", svg: './images/Subnet.png' },
            { id: 12, name: "Route Table 03", svg: './images/RouteTable.png' },
            { id: 13, name: "Route Table 04", svg: './images/RouteTable.png' },
            { id: 14, name: "Internet Gateway 02", svg: './images/InternetGateway.png' },
            { id: 15, name: "Subnet 05", svg: './images/Subnet.png' },
            { id: 16, name: "Subnet 06", svg: './images/Subnet.png' },
            { id: 17, name: "Route Table 05", svg: './images/RouteTable.png' },
            { id: 18, name: "Route Table 06", svg: './images/RouteTable.png' },
            { id: 19, name: "Internet Gateway 03", svg: './images/InternetGateway.png' }
        ],
        links: [
            { source: 1, target: 2 },
            { source: 1, target: 3 },
            { source: 1, target: 4 },
            { source: 2, target: 5 },
            { source: 2, target: 6 },
            { source: 7, target: 2 },
            { source: 8, target: 2 },
            { source: 9, target: 2 },
            { source: 3, target: 10 },
            { source: 3, target: 11 },
            { source: 12, target: 3 },
            { source: 13, target: 3 },
            { source: 14, target: 3 },
            { source: 4, target: 15 },
            { source: 4, target: 16 },
            { source: 17, target: 4 },
            { source: 18, target: 4 },
            { source: 19, target: 4 }
        ]
    };

    const onClickNode = function (nodeId) {
        console.log(`Clicked node ${nodeId}`);
    };

    const onClickLink = function (source, target) {
        console.log(`Clicked link between ${source} and ${target}`);
    };

    let myConfig = JSON.parse(JSON.stringify(graphConfig));

    myConfig.staticGraph = false;
    myConfig.collapsible = props.settings.collapsible;
    myConfig.directed = props.settings.directedGraph;
    myConfig.initialZoom = 0.8;

    myConfig.node.size = 300;
    myConfig.node.fontSize = 8;
    myConfig.node.highlightFontSize = 8;

    myConfig.link.strokeWidth = 1.5;
    myConfig.link.markerHeight = 4;
    myConfig.link.markerWidth = 4;

    return (
        <>
            Graph Discovery: <em>Full VPC View</em>
            <div class="box">
                <Graph
                    id="graph-04" // id is mandatory
                    data={graphData}
                    config={myConfig}
                    onClickNode={onClickNode}
                    onClickLink={onClickLink}
                />
            </div>
        </>
    );
}

export default Sample04;