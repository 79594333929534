import React from 'react'
import Page from "../../components/PageLayout/Page";
import HistoryLayout from "./history/HistoryLayout";

const History = (props) => {
  return (<div>
    <Page>
      <HistoryLayout />
    </Page>
  </div>)
}

export default History;