import React, { useState, useEffect, useCallback } from "react";
import StyledTypo from "../../../components/Typo/StyledTypo";
import styled from "styled-components";
import moment from "moment";
import { ReactComponent as ArrowUp } from '../../../assets/icons/up_arrow_icon.svg'
import { ReactComponent as ArrowDown } from '../../../assets/icons/down_arrow_icon.svg'
import ReactPaginate from "react-paginate";

import { loadingBar } from "../../../helpers/constants";

const TableWrapper = styled.div`
  padding-top: 10px;
  .divider {
    margin: 8px 0px;
    border-top: 1px solid rgba(164, 175, 183, 0.3);
    height: 0;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 10px;
`;

const EachCell = styled.div`
  width: ${(props) => props.width};
  padding-right: ${(props) => (props.pr ? props.pr : 0)}px;
  word-break: break-word;
`;

const RecordWrapper = styled.div`
  padding: 15px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

const ShowingWrapper = styled.div`
  margin-left: 0px;
  margin-top: 32px;
  display: flex;
  position: fixed;
  bottom: 20px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100vw - 200px);
  background-color: #fff;

  width: calc(100vw - 200px);
  background-color: #fff;

  .page-link:focus {
    box-shadow: none !important;
  }

  ul.pagination {
    margin-bottom: 0;
    font-size: 0.8rem;
    font-weight: 0.4;
    font-family: "Roboto";
  }
`;

const columns = [
  {
    title: 'Timestamp',
    access: 'timestamp',
    width: "15%",
    sort: false,
    search: true,
    type: 'date',
  },
  {
    title: 'Message',
    access: 'message',
    width: `85%`,
    sort: false,
    search: true,
    type: 'text'
  },
];

const TableHeader = ({ currentSort, setCurrentSort }) => {
  const handleSetSort = access => {
    setCurrentSort({
      access,
      order:
        (Array.isArray(currentSort.access)
          ? currentSort.access.join()
          : currentSort.access) ===
        (Array.isArray(access) ? access.join() : access)
          ? !currentSort.order
          : true
    })
  }

  return (
    <HeaderWrapper>
      {columns.map(column => (
        <EachCell 
          width={column.width}
          pr={10}
          onClick={() => {
            if (column.sort) {
              handleSetSort(column.access);
            }
          }}
          key={`table-header-${column.title}`}
          className={`d-flex align-items-center ${column.sort ? "cursor-pointer" : ""}`}
        >
          {column.sort &&
          (Array.isArray(currentSort?.access)
            ? currentSort?.access.join()
            : currentSort?.access) ===
            (Array.isArray(column.access)
              ? column.access.join()
              : column.access) ? (
            currentSort.order ? (
              <ArrowUp fill={"#A4AFB7"} className="mr-3" />
            ) : (
              <ArrowDown fill={"#A4AFB7"} className="mr-3" />
            )
          ) : (
            <div />
          )}
          <StyledTypo 
            className="label"
            letter="0"
            size={0.8}
            family="Roboto"
          >
            {column.title}
          </StyledTypo>
        </EachCell>
      ))}
    </HeaderWrapper>
  );
};

const Record = (props) => {
  const { data } = props;
  return (
    <RecordWrapper>
      {columns.map(column => (
        <EachCell width={column.width} key={`table-body-${column.title}`}>
          {column.type === 'text' && (
            <StyledTypo
              className="text-cell"
              line={1.4}
              letter="0"
            >
              {data?.[column.access]}
            </StyledTypo>
          )}
          {column.type === 'boolean' && (
            <StyledTypo
              className="text-cell"
              line={1.4}
              letter="0"
            >
              {data?.[column.access] ? column?.value?.[0] :  column?.value?.[1]}
            </StyledTypo>
          )}
          {column.type === 'func' && (
            <StyledTypo
              className="text-cell"
              line={1.4}
              letter="0"
            >
              {column.func(data?.[column.access])}
            </StyledTypo>
          )}
          {column.type === 'date' && (
            <StyledTypo
              className="text-cell"
              line={1.4}
              letter="0"
            >
              {moment(parseInt(`${data?.[column.access]}000`)).format("YYYY-MM-DD HH:mm:ss")}
            </StyledTypo>
          )}
        </EachCell>
      ))}
    </RecordWrapper>
  );
};

const NotificationsTable = (props) => {
  const { data: logs, category, loading_log } = props;

  const [data, setData] = useState([]);
  const [currentSort, setCurrentSort] = useState({ access: '', order: true })
  const [currentPage, setCurrentPage] = useState(1);

  const itemPerPage = 10;

  const handleSort = () => {
    const retOrder = currentSort.order ? 1 : -1
    setData(data => [
      ...data.sort((a, b) => {
        const aCompare = Array.isArray(currentSort.access)
          ? currentSort.access.map(access => a[access]).join(' ')
          : a[currentSort.access]
        const bCompare = Array.isArray(currentSort.access)
          ? currentSort.access.map(access => b[access]).join(' ')
          : b[currentSort.access]
        return aCompare > bCompare ? retOrder : -1 * retOrder
      })
    ])
  }

  useEffect(() => {
    setData(logs ? [...(logs?.result ?? [])?.filter(log => log.module.includes(category))] : []);
    // setCurrentSort(currentSort =>
    //   currentSort.access.length === 0
    //     ? {
    //         access: columns[0].access,
    //         order: true
    //       }
    //     : { ...currentSort }
    // )

  }, [logs, category]);

  // useEffect(() => {
  //   handleSort();
  // }, [currentSort]);

  const handlePageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const filteredData = useCallback(() => {
    let filteredData = [...data];
    
    if (
      currentPage !== 1 &&
      currentPage > filteredData.length / itemPerPage + 1
    ) {
      setCurrentPage(1);
    }
    return filteredData;
  }, [
    data,
    currentPage,
  ]);

  const pageFilter = useCallback(
    (data) => {
      return data.filter(
        (item, idx) =>
          idx >= itemPerPage * (currentPage - 1) &&
          idx < itemPerPage * currentPage
      );
    },
    [currentPage]
  );

  return (
    <TableWrapper>
      <TableHeader
        currentSort={currentSort}
        setCurrentSort={setCurrentSort}
      />
      <div className="divider" />
      {filteredData().length ? (
        pageFilter(filteredData()).map((single) => (
          <Record
            data={single}
          />
        ))
      ) : !loading_log ? (
        <div className="text-center my-5">
          <StyledTypo>No data</StyledTypo>
        </div>
      ) : (
        <div className="d-flex justify-content-center py-5 align-items-center">
          {loadingBar("Rings", 50, 50)}
          <StyledTypo className="pl-2">Loading ...</StyledTypo>
        </div>
      )}
      <ShowingWrapper>
        <StyledTypo
          size={0.8}
          line={1.6}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {`Showing ${
            itemPerPage * (currentPage - 1) + 1 > filteredData().length
              ? filteredData().length
              : itemPerPage * (currentPage - 1) + 1
          } - ${
            itemPerPage * currentPage > filteredData().length
              ? filteredData().length
              : itemPerPage * currentPage
          } from ${filteredData().length} Results`}
        </StyledTypo>
        <ReactPaginate
          pageCount={(filteredData()?.length ?? 0) / itemPerPage}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          previousLabel="< Prev"
          nextLabel="Next >"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          forcePage={currentPage - 1}
          onPageChange={handlePageChange}
          activeClassName="active"
        />
      </ShowingWrapper>
    </TableWrapper>
  );
};

export default NotificationsTable;
