import React from "react";
import { connect } from "react-redux";
import Page from "../../components/PageLayout/Page";
import HomeLayout from "./home/HomeLayout";

const Home = () => {
  return (
    <div>
      <Page>
        <HomeLayout />
      </Page>
    </div>
  );
};

export default Home;
