import React from 'react'
import StyledTypo from '../../../../components/Typo/StyledTypo'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/cancel.svg'
import 'react-modern-drawer/dist/index.css'
import SlidePanel from '../../../../components/SlidePanel/SlidePanel'

const DividerWrapper = styled.div`
  height: 0;
  width: calc(100% + 60px);
  margin-left: -30px;
  border-bottom: 0.5px solid #ccc;
`

const CloseWrapper = styled.div`
  border-radius: 10px;
  min-width: 45px;
  min-height: 40px;
  cursor: pointer;
  max-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 7px;
  left: 0px;
`

const TitleWrapper = styled.span`
  display: inline-block;
  width: 58%;
  height: 1.4em;
`

const UpsertPanel = props => {
  const { data, isOpen, toggle, isAdd } = props

  return (
    <SlidePanel isOpen={isOpen} toggle={toggle} slideId='apag-detail-slide'>
      <div className='p-3 position-relative mb-3'>
        <div className='d-flex align-items-center justify-content-center mb-3'>
          <CloseWrapper className='cursor-pointer' onClick={toggle}>
            <CloseIcon width={22} fill='#505050' />
          </CloseWrapper>
          <TitleWrapper>
            <StyledTypo className='text-center heading ln-2'>
              {isAdd ? 'Add' : 'Update'}
            </StyledTypo>
          </TitleWrapper>
        </div>
        <DividerWrapper className='mb-3' />

      </div>
    </SlidePanel>
  )
}

export default UpsertPanel
