import React, {useState, useContext, useEffect} from 'react'
import styled from 'styled-components'

import * as global from '../../../helpers/constants'
import {applicationActions} from "../../../redux/actions";
import {connect} from "react-redux";
import DeploymentsPanel from "./DeploymentsPanel";
import PoliciesPanel from "./PoliciesPanel";
import StyledTypo from "../../../components/Typo/StyledTypo";
import {MainContext} from "../../../contexts";
import {useLocation} from "react-router-dom";
import {getParamsStr} from "../../../helpers/constants";

const ContainerWrapper = styled.div`
  width: calc(100vw - 101px - 10px);
  margin-left: 101px;
  padding-top: 58px;
`

const TabHeader = styled.div`
  display: flex;
  gap: 8.5px;
  align-items: center;
  z-index: 10;
  //position: relative;
`

const TabHeaderItem = styled.div`
  background: #F1F5FA;
  /* Gray light 2 */
  
  border: 2px solid #F1F5FA;
  box-sizing: border-box;
  border-radius: 20px;
  color: #F1F5FA;
  padding: 15px 47px 47px;
  cursor: pointer;
  
  &.active {
    background: #FFFFFF;
    /* Gray light 2 */
    
    border: 2px solid #F1F5FA;
    box-sizing: border-box;
    border-radius: 20px;
    color: #A4AFB7;
  }
`

const TabBody = styled.div`
  margin-top: -40px;
  z-index: 110;
  //position: relative;
`

const DeployLayout = (props) => {
  const [tabs, setTabs] = useState(global.deployTabList)
  const [selectedTab, setSelectedTab] = useState(null)

  const { setTrackHistory, trackHistory } = useContext(MainContext)
  const location = useLocation()

  useEffect(() => {
    if(trackHistory?.[0]?.pathname != location.pathname || trackHistory?.[0]?.params != getParamsStr({tab: tabs.filter(item => item.active)?.[0]?.key})) {
      setTrackHistory(trackHistory => [
        {
          pathname: location.pathname,
          params: getParamsStr({
            tab: tabs.filter(item => item.active)?.[0]?.key
          }),
          details: [`${tabs.filter(item => item.active)?.[0]?.title}`]
        },
        ...trackHistory
      ])
    }
  }, [setTrackHistory, trackHistory])

  useEffect(() => {
    setSelectedTab(location?.search ? new URLSearchParams(location?.search).get("tab") : tabs.filter(item => item.active)?.[0]?.key)
  }, [setSelectedTab])

  useEffect(() => {
    if(selectedTab) {
      handleSelectedTab(selectedTab)
    }
  }, [selectedTab])

  const handleSelectedTab = (key) => {
    setTabs([...tabs.map(tab => ({...tab, active: tab.key === key}))])
    if(trackHistory?.[0]?.pathname != location.pathname || trackHistory?.[0]?.params != getParamsStr({tab: key})) {
      setTrackHistory(trackHistory => [
        {
          pathname: location.pathname,
          params: getParamsStr({
            tab: key
          }),
          details: [`${tabs.filter(item => item.key === key)?.[0]?.title}`]
        },
        ...trackHistory
      ])
    }
  }
  return (
      <ContainerWrapper>
        <div className="main-container d-flex flex-column">
          <TabHeader>
            {tabs.map(tab => (
              <TabHeaderItem className={tab.active && 'active'} onClick={() => handleSelectedTab(tab.key)}>
                <StyledTypo color={tab.active ? '#A4AFB7' : '#FFF'} size={2.25} line={50.4/36} letter="0" family="Roboto" fontWeight={700}>
                  {tab.title}
                </StyledTypo>
              </TabHeaderItem>
            ))}
          </TabHeader>
          <TabBody>
            {!!tabs.filter(tab => tab.key === 'deployments' && tab.active).length && (
              <DeploymentsPanel />
            )}
            {!!tabs.filter(tab => tab.key === 'policies' && tab.active).length && (
              <PoliciesPanel />
            )}
          </TabBody>
        </div>
      </ContainerWrapper>
  )
}

function mapStateToProps(state) {
  // const {clusterInfo, clusterInfoLoading, healthInfo} = state.applications;
  // const { expired_token, new_refresh_token } = state.auth
  // return {
  //   clusterInfo, clusterInfoLoading, healthInfo,
  //   expired_token, new_refresh_token,
  // }
}

function mapDispatchToProps(dispatch) {
  // return {
  //   fetchClusterDetailsAction: (org_id, app_id, realtimeFlag) => dispatch(applicationActions.fetchClusterDetailsAction(org_id, app_id, realtimeFlag)),
  //   fetchAppHealthAction: (org_id, app_id, realtimeFlag) => dispatch(applicationActions.fetchAppHealthAction(org_id, app_id, realtimeFlag)),
  // }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeployLayout);
