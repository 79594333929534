import React, {useState, useEffect} from 'react'
import { ControlledTreeEnvironment, Tree } from 'react-complex-tree'
import {userGuideItems} from "../../helpers/constants";
import styled from 'styled-components'
import './guideDocStyle.css'
import {useHistory, useLocation} from "react-router-dom";

const DocSideBarWrapper = styled.div`
  flex: 0 0 var(--doc-sidebar-width);
  overflow-y: auto;
  position: sticky;
  height: 100vh;
  top: 0;
  padding-top: 10px;
  border-right: 1px solid #f1f1f1;
  padding-left: 15px;
  
  .rct-tree-root {
    font-family: 'Roboto' !important;
    background-color: #fff;
    
  }
  
  .rct-tree-item-li {
    font-size: 1rem !important;
    margin: 10px 0 !important;
  }
  
  .rct-tree-root.rct-tree-root-focus .rct-tree-item-title-container-focused {
    border-color: transparent;
  }
  .rct-tree-root:not(.rct-tree-root-focus) .rct-tree-item-title-container-focused {
    outline: none;
    border-color: transparent;
  }
  
  .rct-tree-root-focus {
    outline: 1px solid transparent;
  }
  
  .rct-tree-item-button {
    height: inherit;
  }
`

const SideBar = (props) => {
  const [expandedItems, setExpandedItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const history = useHistory()
  const location = useLocation()

  const handleSelectItem = (items) => {
    setSelectedItems(items)
    history.push(userGuideItems[items?.[0]].link)
  }

  const getParents = (child) => {
    let temp = Object.keys(userGuideItems).filter(item => userGuideItems[item].children.includes(child))
    if(temp.length) {
      return [child, ...getParents(temp[0])]
    } else {
      return []
    }
  }

  useEffect(() => {
    let currentItem = Object.keys(userGuideItems).filter(item => userGuideItems[item].link === (location.pathname + (location.hash ? location.hash : '')))
    setSelectedItems(currentItem?.[0] === 'root' ? ['matossphere'] : currentItem)
    setExpandedItems(currentItem?.[0] === 'root' ? [] : getParents(currentItem?.[0]))
  }, [])

  return (
      <DocSideBarWrapper>
        <ControlledTreeEnvironment
            items={userGuideItems}
            getItemTitle={item => item.data}
            viewState={{
              ['tree-user-guide']: {
                focusedItem: '',
                expandedItems,
                selectedItems,
              },
            }}
            // onFocusItem={item => setFocusedItem(item.index)}
            onExpandItem={item => setExpandedItems([...expandedItems, item.index])}
            onCollapseItem={item =>
                setExpandedItems(expandedItems.filter(expandedItemIndex => expandedItemIndex !== item.index))
            }
            onSelectItems={handleSelectItem}
        >
          <Tree treeId="tree-user-guide" rootItem="root" treeLabel="User Guide Menu" />
        </ControlledTreeEnvironment>
      </DocSideBarWrapper>
  )
}

export default SideBar

