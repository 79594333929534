import React from "react";
import Page from "../../components/PageLayout/Page";
import CloudDashboardLayout from "./cloud_dashboard/CloudDashboardLayout";

const CloudDashboard = (props) => {
  return (
    <div>
      <Page noUpperCta>
        <CloudDashboardLayout />
      </Page>
    </div>
  );
};

export default CloudDashboard
;
