import React from "react";
import Page from "../../components/PageLayout/Page";
import CloudWrapperLayout from "./cloud_wrapper/CloudWrapperLayout";

const CloudWrapper = (props) => {
  return (
    <div>
      <Page noUpperCta>
        <CloudWrapperLayout />
      </Page>
    </div>
  );
};

export default CloudWrapper
;
