import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import StyledTypo from "../../../../components/Typo/StyledTypo";
import { CardViewContainer, CardViewItem } from "../../home/ProviderDashboard";
import GradientProgressBar from "../../../../components/GradientProgressBar/GradientProgressBar";
import { infrastructureType, actionIacPlatform, iac_status_color } from "../../../../helpers/constants";
import { ReactComponent as AssetsMenuIcon } from '../../../../assets/icons/assets-menu-icon.svg'


const IacCards = (props) => {
    const { iac_projects } = props;
    const history = useHistory();

    const [iacCards, setIacCards] = useState([]);

    useEffect(() => {
        setIacCards([
            ...(iac_projects ?? [])
            .map(project => ({
                id: project.id,
                label: project.display_name, 
                csp: project.csp, 
                platform: project.platform?.[0], 
                category: infrastructureType.category, 
                logo: actionIacPlatform.filter(iac => iac.label.toLowerCase() === project.platform?.[0]?.toLowerCase())?.[0]?.logo,
                status: project.severity_counters
            }))
        ]);

    }, [iac_projects])

    return (
        <div className="w-100 mt-3">
            <CardViewContainer>
                {iacCards.map((project) => (
                <CardViewItem
                    {...iac_status_color}
                    onClick={() => history.push(`/iac/${project.id}`)}
                >
                    <div className="text-center left-side">
                        <img
                            src={ project.logo }
                            alt={`${project.label} logo`}
                            width={30}
                            height={30}
                        />
                        <StyledTypo
                            className="mt-2"
                            color="#505050"
                            size={0.8}
                            line={1.4}
                            letter="0"
                            family="Roboto"
                            fontWeight={400}
                        >
                            0 %
                        </StyledTypo>
                    </div>
                    <div className="vertical-divider" />
                    <div className="right-side">
                        <div className="d-flex justify-content-between ">
                            <StyledTypo
                                color="#505050"
                                size={0.8}
                                line={1.4}
                                letter="0"
                                family="Roboto"
                                fontWeight={300}
                            >
                                {project?.label}
                            </StyledTypo>
                            <div className="status">
                                {Object.keys(iac_status_color).map(
                                    (item) => (
                                    <div className={`item ${item}`}>
                                        <StyledTypo
                                        color="#687089"
                                        size={0.625}
                                        line={1.4}
                                        letter="0"
                                        family="Roboto"
                                        fontWeight={300}
                                        >
                                         {project?.status?.[item.toUpperCase()] ?? 0}
                                        </StyledTypo>
                                    </div>
                                    )
                                )}
                            </div>
                        </div>
                        <GradientProgressBar
                            color="#ececec70" color2="#adadad"
                            value={0}
                            maxValue="100"
                            rotation="90"
                        />
                        <div className="d-flex  mt-3">
                            <div className="d-flex">
                            <AssetsMenuIcon
                                fill="#505050"
                                width={20}
                                height={20}
                            />
                            <StyledTypo
                                color="#505050"
                                size={0.8}
                                line={1.4}
                                letter="0"
                                family="Roboto"
                                fontWeight={300}
                                className="mt-1 ml-2"
                                title="Resource count: Non-Compliant / Total"
                            >
                                0 / 0
                            </StyledTypo>
                            </div>
                        </div>
                    </div>
                    {/* <div className="status-mark" /> */}
                </CardViewItem>
                ))}
            </CardViewContainer>
        </div>
    )
}

export default IacCards;
