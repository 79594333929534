import {authHeader, handleResponse} from '../../helpers';
import * as global from '../../helpers/constants'

export const auth = {
  loginWithCredential,
  registerWithCredential,
  getRefreshToken,
  forgotPasswordRequest,
  changePassword,
  checkResetToken,
  logout,
  fetchRecaptchaSecretKey,
}

function loginWithCredential(payload) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload)
  };
  const url = `${global.getBaseURL()}/onboarding${global.enableV2Auth ? '/v2' : ''}/login`
  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function registerWithCredential(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({...data})
  };
  return fetch(`${global.getBaseURL()}/onboarding/register`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function getRefreshToken() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({'refresh_token': JSON.parse(localStorage.getItem('current-user'))?.refresh_token})
  };
  return fetch(`${global.getBaseURL()}/onboarding/refresh-token`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function forgotPasswordRequest(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({...data})
  };
  const url = `${global.getBaseURL()}/onboarding${global.enableV2Auth ? '/v2' : ''}/reset-password`
  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function changePassword(data) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({...data})
  };
  const url = `${global.getBaseURL()}/onboarding${global.enableV2Auth ? '/v2' : ''}/change-password`
  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function checkResetToken(email, token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return fetch(`${global.getBaseURL()}/onboarding/check-reset-token?email=${email}&token=${token}`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function fetchRecaptchaSecretKey() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return fetch(`${global.getBaseURL()}/onboarding/recaptcha-secret-key`, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

function logout(email) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email })
  };
  const url = `${global.getBaseURL()}/onboarding${global.enableV2Auth ? '/v2' : ''}/logout`
  return fetch(url, requestOptions)
      .then(handleResponse)
      .then(result => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // localStorage.setItem('denso-user', JSON.stringify(result.data.token));
        return result;
      });
}

