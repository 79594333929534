import React from 'react'
import PlanLayout from "./plan/PlanLayout";

const Plan = (props) => {
  return (<div>
    <PlanLayout isPlanPage />
  </div>)
}

export default Plan;
