import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { usersActions } from "../../../redux/actions";
import StyledTypo from "../../../components/Typo/StyledTypo";
import styled from "styled-components";
import { connect } from "react-redux";
import { ReactComponent as CloseIcon } from "../../../assets/icons/cancel.svg";
import CommonInput from "../../../components/Input/CommonInput";
import EffectedButton from "../../../components/Buttons/EffectedButton";
import SlidePanel from "../../../components/SlidePanel/SlidePanel";

import "react-modern-drawer/dist/index.css";
import { getCurrentOrganization, getCurrentUser, showAlert } from "../../../helpers/constants";

const CloseWrapper = styled.div`
  border-radius: 10px;
  min-width: 45px;
  min-height: 40px;
  cursor: pointer;
  max-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ButtonGroupWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 10px;
  width: calc(100% - 20px);
`;

const DividerWrapper = styled.div`
  height: 0;
  width: calc(100% + 60px);
  margin-left: -30px;
  border-bottom: 0.5px solid #ccc;
`;

const TitleWrapper = styled.span`
  display: inline-block;
  width: 100%;
  height: 1.4em;
`;

const ContentWrapper = styled.div`
  padding: 20px;
  margin-top: -50px;
  bottom: 20px;
  left: 10px;
  width: 100%;
`;

const StatusWrapper = styled.div`
  background-color: ${props => props.color};
  padding: 5px 20px;
  border-radius: 215px;
  display: flex;

  cursor: pointer;
`


const AddEnvSlide = (props) => {
  const {
    addNewEnvAction, envData, editData, handleEditEnv,
    clearUpdateUserFlag,
    updateUserFlag,
    fetchUsersAction,
    isOpen, closePanel
  } = props;

  const [name, setName] = useState('')
  const [key, setKey] = useState('')
  const [status, setStatus] = useState(true)

  useEffect(() => {
    setName("");
    setKey("");
    setStatus(true);
    closePanel();
  }, [envData]);

  useEffect(() => {
    if (editData) {
      setName(editData.name);
      setStatus(editData.is_active);
      // setAuditEnabled(editData?.auditEnabled)
    }
  }, [editData]);

  const validateInput = () => {
    let msg = "";
    if (editData) {
      if (!name.length) {
        msg = "Please fill environment name.";
      }
    } else {
      if (!name.length) {
        msg = "Please fill environment name.";
      } else if (!key.length) {
        msg = "Please fill environment key.";
      }
    }
    if (msg.length) {
      showAlert("warning", "Invalid input", msg);
      return false;
    }
    return true;
  };

  const submitNewEnvironment = () => {
    if (!validateInput()) return;
    const data = {
      name,
      key: editData ? editData.key : key,
      is_active: editData ? editData.status === "active" : status,
    };
    if (editData) {
      handleEditEnv(data);
    } else {
      addNewEnvAction(data);
    }
  };

  const toggleStatus = () => {
    setStatus((status) => !status);
  };

  // useEffect(() => {
  //   if (createUserFlag === 2) {
  //     showAlert('success', 'Success', 'Successfully added a new user.')
  //     fetchUsersAction()
  //     clearCreateUserFlag()
  //     history.push('/manage')
  //   } else if (createUserFlag === 3) {
  //     showAlert('error', 'Error', 'Failed to add a new user')
  //     clearCreateUserFlag()
  //   }
  // }, [clearCreateUserFlag])


  return (
    <SlidePanel
      slideId="create-infra-project-panel"
      isOpen={isOpen}
      toggle={closePanel}
    >
      <div className="p-3 position-relative mb-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <CloseWrapper className="cursor-pointer" onClick={closePanel}>
            <CloseIcon width={22} fill="#505050" />
          </CloseWrapper>
          <TitleWrapper>
            <StyledTypo className="text-center heading ln-2">
              Add Environment
            </StyledTypo>
          </TitleWrapper>
        </div>
        <DividerWrapper className="mb-3" />
      </div>
      <ContentWrapper>
        <div>
          <CommonInput
            label="Name"
            value={name}
            setValue={setName}
            active={false}
            locked={false}
            placeholder=""
          />
        </div>
        {!editData && (
          <div>
            <CommonInput
              label="Key"
              value={key}
              setValue={setKey}
              active={false}
              locked={false}
              placeholder=""
            />
          </div>
        )}
        {!editData && (
        <div className="d-flex justify-content-between align-items-center mt-3 ">
          <StyledTypo
            color="#505050"
            size={0.8}
            line={1.4}
            letter="0"
            family="Roboto"
            fontWeight={400}
          >
            Status
          </StyledTypo>
          <StatusWrapper
            onClick={toggleStatus}
            color={status ? "#5EB1E4" : "#A4AFB7"}
          >
            <StyledTypo
              color="#fff"
              size={0.8}
              line={1.4}
              letter="0"
              family="Poppins"
              fontWeight={300}
            >
              {status ? "Active" : "Inactive"}
            </StyledTypo>
          </StatusWrapper>
        </div>
      )}
      </ContentWrapper>
      <ButtonGroupWrapper className="col-12 px-3 d-flex mt-5 align-items-center justify-content-end">
        <EffectedButton
          className="ml-5"
          padding={20}
          height={32}
          noSpan
          marginLeft="0px"
          onClick={submitNewEnvironment}
          background="#5EB1E4"
          width={90}
        >
          <StyledTypo color="#fff" className="label">
            {editData ? 'Save' : 'Add'}
          </StyledTypo>
        </EffectedButton>
        <EffectedButton
          className="ml-3"
          padding={20}
          height={32}
          noSpan
          marginLeft="0px"
          onClick={closePanel}
          width={90}
        >
          <StyledTypo color="#fff" className="label">
            Cancel
          </StyledTypo>
        </EffectedButton>
      </ButtonGroupWrapper>
    </SlidePanel>
  );
};

// function mapStateToProps (state) {
//   const { updateUserFlag, createUserFlag } = state.users
//   return {
//     createUserFlag,
//     updateUserFlag
//   }
// }

// function mapDispatchToProps (dispatch) {
//   return {

//   }
// }


export default connect(undefined, undefined)(AddEnvSlide);
