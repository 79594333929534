import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  NonComplianceStatusColor,
  loadingBar,
} from "../../../../helpers/constants";
import StyledTypo from "../../../../components/Typo/StyledTypo";
import { Row, Col } from "reactstrap";
import GradientProgressBar from '../../../../components/GradientProgressBar/GradientProgressBar'
import moment from 'moment'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

const MainBodyWrapper = styled.div`
  padding: 10px;
`;

const RemediationOverviewWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 20px 0px;
`;

const RemediationOverviewBlock = styled.div`
padding: 15px 5px;
border-radius: 5px;
// background-color: ${(props) => props.color};
border: 1px solid #eee;
border-left: 5px solid ${(props) => (props.color ? props.color : "#ccc")};
width: 100%;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
// box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 2px, rgba(0, 0, 0, 0.2) 2px 2px 2px;
filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
cursor: pointer;
`;

const OverviewContentWrapper = styled.div`
  width: 100%;
`;

const SummaryItemContainer = styled.div`
  border-top: ${props => (props.idx === 0 ? 'none' : '1px solid #f1f1f1')};
  padding: 10px;
  cursor: ${props => (props.cursor ? props.cursor : 'default')};
  ${props =>
    props.hover &&
    `
    &:hover {
      background-color: #f7f7f7;
    }
  `}
`
const SummaryWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  word-break: break-all;
`

const StyledStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 65px;

  margin-right: ${props => (props.mr ? props.mr : 10)}px;

  .mark {
    width: ${props => (props.size ? props.size : 12)}px;
    height: ${props => (props.size ? props.size : 12)}px;
    border-radius: 50%;
    background-color: ${props => props.color};
    // box-shadow: 0 8px 6px -3px ${props => props.color};
  }
`

const StatsTabWrapper = styled.div`
  padding: 10px;
`

const workloadSummary = [
  [
    { key: 'type', label: 'Type' },
    { key: 'environment', label: 'OS Family & Name' }
  ],
  [
    { key: 'updated_at', label: 'Created Date', type: 'date' },
    { key: 'updated_at', label: 'Updated Date', type: 'date' }
  ]
]

const classStatusLayout = [
  {
    label: 'Class',
    width: 3,
    subLabel: null,
    key: 'classname'
  },
  {
    label: 'Results',
    width: 4,
    subLabel: 'Result',
    key: 'result'
  },
  // {
  //   label: 'Non-Compliant',
  //   width: 1,
  //   subLabel: null,
  //   key: 'failed'
  // },
  // {
  //   label: 'Compliant',
  //   width: 1,
  //   subLabel: null,
  //   key: 'passed'
  // },
  // {
  //   label: 'Skipped',
  //   width: 1,
  //   subLabel: null,
  //   key: 'skipped'
  // },
  // { label: 'Compliance Score', width: 4, subLabel: null, key: 'overall' }
]

const yearStatusLayout = [
  {
    label: 'CVE Year',
    width: 3,
    subLabel: null,
    key: 'year'
  },
  {
    label: 'Results',
    width: 4,
    subLabel: 'Result',
    key: 'result'
  },
  // {
  //   label: 'Non-Compliant',
  //   width: 1,
  //   subLabel: null,
  //   key: 'failed'
  // },
  // {
  //   label: 'Compliant',
  //   width: 1,
  //   subLabel: null,
  //   key: 'passed'
  // },
  // {
  //   label: 'Skipped',
  //   width: 1,
  //   subLabel: null,
  //   key: 'skipped'
  // },
  // { label: 'Compliance Score', width: 4, subLabel: null, key: 'overall' }
]


const WorkloadSummaryTab = (props) => {
  const {
    workload,
    onLink,
    summary,
    detail
  } = props;

  const [vulsByClass, setVulsByClass] = useState({})
  const [vulsByYear, setVulsByYear] = useState({})

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const basedVuls = detail?.result?.results?.map(result => {
      return result.Vulnerabilities.map(vul => ({
        year: vul.VulnerabilityID.split('-')?.[1] ?? '---',
        severity: vul.Severity.toLowerCase(),
        classname: result.Class,
        id: vul.VulnerabilityID,
        type: result.Type,
        target: result.Target
      }))
    }).flat() ?? []
    const groupByClass = Object.groupBy(basedVuls, ({ classname }) => classname)
    const groupByYear = Object.groupBy(basedVuls, ({ year }) => year)
    let statusByClass = {}
    let statusByYear = {}

    Object.keys(groupByClass).forEach(classname => {
      statusByClass = {
        ...statusByClass,
        [classname]: {
          classname,
          result: {
            critical: groupByClass?.[classname]?.filter(vul => vul.severity === 'critical')?.length ?? 0,
            high: groupByClass?.[classname]?.filter(vul => vul.severity === 'high')?.length ?? 0,
            medium: groupByClass?.[classname]?.filter(vul => vul.severity === 'medium')?.length ?? 0,
            low: groupByClass?.[classname]?.filter(vul => vul.severity === 'low')?.length ?? 0,
          }
        }
      }
    })
    setVulsByClass(statusByClass)
    Object.keys(groupByYear).forEach(year => {
      statusByYear = {
        ...statusByYear,
        [year]: {
          year,
          result: {
            critical: groupByYear?.[year]?.filter(vul => vul.severity === 'critical')?.length ?? 0,
            high: groupByYear?.[year]?.filter(vul => vul.severity === 'high')?.length ?? 0,
            medium: groupByYear?.[year]?.filter(vul => vul.severity === 'medium')?.length ?? 0,
            low: groupByYear?.[year]?.filter(vul => vul.severity === 'low')?.length ?? 0,
          }
        }
      }
    })
    setVulsByYear(statusByYear)
  }, [detail])

  const getSeverityCnt = (severity, isTotal = false) => {
    const realKey = Object.keys(summary?.summary ?? {}).filter(item => item.toLowerCase() === severity.toLowerCase())?.[0];
    const total = Object.values(summary?.summary ?? {}).reduce((cur, acc) => cur + acc, 0);
    return isTotal ? total : summary?.summary?.[realKey];
  }

  return (
    <MainBodyWrapper>
      <div className="mb-1">
        {workloadSummary.map((item, idx) => (
          <SummaryItemContainer key={idx} idx={idx}>
            <Row className="align-items-center">
              {item.map((each) => (
                <Col md={6}>
                  <Row className="p-0 align-items-center">
                    <Col md={4}>
                      <StyledTypo className="text-justify label table-label">
                        {each.label}
                      </StyledTypo>
                    </Col>
                    <Col md={8}>
                      <StyledTypo className="text-justify table-label">
                        {workload?.[each.key]
                          ? each.type === "date"
                            ? moment(workload?.[each.key]).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : each.type === "array"
                            ? workload?.[each.key].join(", ")
                            : each.type === "upper_string"
                            ? workload?.[each.key].toUpperCase()
                            : workload?.[each.key]
                          : "---"}
                      </StyledTypo>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </SummaryItemContainer>
        ))}
      </div>
      {
        Object.keys(workload ?? {}).length ? (
          <>
            <div>
              <OverviewContentWrapper>
                {/* <TotalBlock>
                  {dashboardOverviewList
                    .filter((each) => each.key !== "total")
                    .map((each) => (
                      <div
                        className={`item ${!each.border && "nonborder"}`}
                        key={each.key}
                      >
                        <StyledTypo
                          className="ml-3"
                          size={0.8}
                          line={1.4}
                          letter="0"
                          family="Roboto"
                          fontWeight={300}
                        >
                          {each.title}
                        </StyledTypo>
                        <div className="d-flex justify-content-between align-items-end pt-3">
                          <StyledTypo
                            className="text-center ml-3"
                            size={1.7}
                            line={1}
                            letter="0"
                            family="Roboto"
                            fontWeight={400}
                          >
                            {`${(getResourceCnt(each.key) * 100 / getResourceCnt(each.key, true)).toFixed()} %`}
                          </StyledTypo>
                          <div className="d-flex justify-content-center align-items-center">
                            <StyledTypo
                              className="mr-2"
                              color="#505050"
                              size={0.75}
                              line={1}
                              letter="0"
                              family="Roboto"
                              fontWeight={300}
                            >
                              {`${getResourceCnt(each.key)} / ${getResourceCnt(each.key, true)}`}
                            </StyledTypo>
                          </div>
                        </div>
                        <GradientProgressBar
                          color="#ececec70" color2="#adadad"
                          value={(getResourceCnt(each.key) * 100 / getResourceCnt(each.key, true)).toFixed()}
                          maxValue="100"
                          rotation="90"
                        />
                      </div>
                    ))}
                </TotalBlock> */}
                <RemediationOverviewWrapper>
                  {Object.keys(NonComplianceStatusColor).map((status) => (
                    <RemediationOverviewBlock
                      color={NonComplianceStatusColor[status].color}
                      onClick={() =>
                        onLink({
                          type: "severity",
                          data: status.toUpperCase(),
                        })
                      }
                      key={status}
                    >
                      <StyledTypo
                        className="ml-3 severity-text"
                        size={0.75}
                        line={1.4}
                        letter="0"
                        family="Roboto"
                        fontWeight={400}
                      >
                        {status}
                      </StyledTypo>
                      <div className="d-flex justify-content-between align-items-end pt-3">
                        <StyledTypo
                          className="text-center ml-3"
                          size={1.7}
                          line={1}
                          letter="0"
                          family="Roboto"
                          fontWeight={400}
                        >
                          {`${((summary?.summary?.[status.toUpperCase()] ?? 0) * 100 / getSeverityCnt(status, true)).toFixed()} %`}
                        </StyledTypo>
                        <div className="d-flex justify-content-center align-items-center">
                          <StyledTypo
                            className="mr-2"
                            size={0.75}
                            line={1}
                            letter="0"
                            family="Roboto"
                            fontWeight={400}
                          >
                            {`${summary?.summary?.[status.toUpperCase()] ?? 0} / ${getSeverityCnt(status, true)}`}
                          </StyledTypo>
                        </div>
                      </div>
                    </RemediationOverviewBlock>
                  ))}
                </RemediationOverviewWrapper>
              </OverviewContentWrapper>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center py-5 align-items-center">
              {loadingBar("Rings", 50, 50)}
              <StyledTypo className="pl-2">Loading ...</StyledTypo>
            </div>
          </>
        )
      }
      <StatsTabWrapper>
        <Tabs>
          <TabList>
            <Tab>
              <StyledTypo
                line="1.8"
                size={0.8}
                family="Poppins"
                fontWeight={300}
              >
                Status by Class
              </StyledTypo>
            </Tab>
            <Tab>
              <StyledTypo
                line="1.8"
                size={0.8}
                family="Poppins"
                fontWeight={300}
              >
                Status by CVE Year
              </StyledTypo>
            </Tab>
          </TabList>
          <TabPanel>
            <SummaryWrapper>
              <Row className="p-2 align-items-center">
                {classStatusLayout.map((status) => (
                  <Col md={status.width} key={status.label}>
                    <StyledTypo className="text-justify table-label label">
                      {status.label}
                    </StyledTypo>
                    {status.subLabel === "Result" && (
                      <div className="d-flex align-items-center flex-wrap mt-2">
                        {["Critical", "High", "Medium", "Low"].map(
                          (key) => (
                            <StyledStatusWrapper key={key}>
                              <StyledTypo
                                color="#687089"
                                size={0.6}
                                line={1}
                                letter="0"
                                family="Roboto"
                                fontWeight={300}
                              >
                                {key}
                              </StyledTypo>
                            </StyledStatusWrapper>
                          )
                        )}
                      </div>
                    )}
                  </Col>
                ))}
              </Row>
              {Object.values(vulsByClass).map((data, idx) => (
                <SummaryItemContainer
                  hover
                  // cursor="pointer"
                  // onClick={() =>
                  //   onLink({
                  //     type: "compliance",
                  //     data: data?.compliance_name,
                  //   })
                  // }
                  key={idx}
                >
                  <Row className="d-flex align-items-center">
                    {classStatusLayout.map((status) => (
                      <Col md={status.width} key={status.key}>
                        {['passed', 'failed', 'skipped', 'total'].includes(status.key) && (
                          <StyledTypo className="text-justify table-label">
                            {data?.[status.key] ?? 0}
                          </StyledTypo>
                        )}
                        {status.key === "overall" && (
                          <GradientProgressBar
                            color="#bdbdbd"
                            color2="#bdbdbd"
                            value={data?.[status.key] ?? 0}
                            maxValue="100"
                            rotation="90"
                            showNumber
                          />
                        )}
                        {status.key === "result" && (
                          <div className="d-flex align-items-center flex-wrap">
                            {Object.keys(NonComplianceStatusColor).map(
                              (key) => (
                                <StyledStatusWrapper
                                  color={
                                    NonComplianceStatusColor[key].color
                                  }
                                  key={key}
                                >
                                  <div className="mark" />
                                  <StyledTypo
                                    size={0.9}
                                    line={1.4}
                                    letter="0"
                                    family="Roboto"
                                    fontWeight={300}
                                  >
                                    {data?.[status.key]?.[key] ??
                                      0}
                                  </StyledTypo>
                                </StyledStatusWrapper>
                              )
                            )}
                          </div>
                        )}
                        {status.key === "classname" && (
                          <div className="d-flex align-items-center">
                            <StyledTypo className="text-justify table-label">
                              {data[status.key]}
                            </StyledTypo>
                          </div>
                        )}
                      </Col>
                    ))}
                  </Row>
                </SummaryItemContainer>
              ))}
            </SummaryWrapper>
          </TabPanel>
          <TabPanel>
            <SummaryWrapper>
              <Row className="p-2 align-items-center">
                {yearStatusLayout.map((status) => (
                  <Col md={status.width} key={status.label}>
                    <StyledTypo className="text-justify table-label label">
                      {status.label}
                    </StyledTypo>
                    {status.subLabel === "Result" && (
                      <div className="d-flex align-items-center flex-wrap mt-2">
                        {["critical", "high", "medium", "low"].map(
                          (key) => (
                            <StyledStatusWrapper key={key}>
                              <StyledTypo
                                className="severity-text"
                                color="#687089"
                                size={0.6}
                                line={1}
                                letter="0"
                                family="Roboto"
                                fontWeight={300}
                              >
                                {key}
                              </StyledTypo>
                            </StyledStatusWrapper>
                          )
                        )}
                      </div>
                    )}
                  </Col>
                ))}
              </Row>
              {Object.values(vulsByYear).sort((a, b) => a.year > b.year ? -1 : 1).map((data, idx) => (
                <SummaryItemContainer
                  hover
                  // cursor="pointer"
                  // onClick={() =>
                  //   onLink({
                  //     type: "resourceType",
                  //     data: data?.resource_category,
                  //   })
                  // }
                  key={idx}
                >
                  <Row>
                    {yearStatusLayout.map((status) => (
                      <Col md={status.width} key={status.key}>
                        {['passed', 'failed', 'skipped', 'total'].includes(status.key) && (
                          <StyledTypo className="text-justify table-label">
                            {data[status.key] ?? 0}
                          </StyledTypo>
                        )}
                        {status.key === "overall" && (
                          <GradientProgressBar
                            color="#bdbdbd"
                            color2="#bdbdbd"
                            value={data[status.key] ?? 0}
                            maxValue="100"
                            rotation="90"
                            showNumber
                          />
                        )}
                        {status.key === "year" && (
                          <div className="d-flex align-items-center">
                            {/* <img
                              src={
                                Object.values(
                                  providerResourceTypes[
                                    selectedApp?.platform
                                  ]
                                ).filter(
                                  (item) =>
                                    item.label === data[status.key]
                                )?.[0]?.logo ?? CubeIcon
                              }
                              alt={data[status.key]}
                              width={25}
                              height={25}
                              className="mr-2"
                            /> */}
                            <StyledTypo className="text-justify table-label">
                              {data[status.key] === 'undefined' ? 'Other' : data[status.key]}
                            </StyledTypo>
                          </div>
                        )}
                        {status.key === "result" && (
                          <div className="d-flex align-items-center flex-wrap">
                            {Object.keys(NonComplianceStatusColor).map(
                              (key) => (
                                <StyledStatusWrapper
                                  color={
                                    NonComplianceStatusColor[key].color
                                  }
                                >
                                  <div className="mark" />
                                  <StyledTypo
                                    size={0.9}
                                    line={1.4}
                                    letter="0"
                                    family="Roboto"
                                    fontWeight={300}
                                  >
                                    {data?.[status.key]?.[key] ??
                                      0}
                                  </StyledTypo>
                                </StyledStatusWrapper>
                              )
                            )}
                          </div>
                        )}
                      </Col>
                    ))}
                  </Row>
                </SummaryItemContainer>
              ))}
            </SummaryWrapper>
          </TabPanel>
        </Tabs>
      </StatsTabWrapper>
    </MainBodyWrapper>
  );
};

export default WorkloadSummaryTab;
