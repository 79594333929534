import React from "react";
import Loader from "react-loader-spinner";
import moment from "moment";

import { NotificationManager } from "react-notifications";

import { ReactComponent as ObserveMenuIcon } from "../assets/icons/observe_menu.svg";
import { ReactComponent as CodeForkIcon } from "../assets/icons/code-fork.svg";
import { ReactComponent as DashboardIcon } from "../assets/icons/dashboard.svg";
import { ReactComponent as ShieldIcon } from "../assets/icons/shield.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/setting.svg";
import { ReactComponent as AccountMenuIcon } from "../assets/icons/user-menu-icon.svg";
import { ReactComponent as SecurityMenuIcon } from "../assets/icons/security-icon.svg";
import { ReactComponent as DashboardMenuIcon } from "../assets/icons/dashboard-menu-icon.svg";
import { ReactComponent as HomeMenuIcon } from "../assets/icons/home.svg";
import { ReactComponent as AssetsMenuIcon } from "../assets/icons/assets-menu-icon.svg";
import { ReactComponent as AWSLogo } from "../assets/icons/com_aws.svg";
import { ReactComponent as GCPLogo } from "../assets/icons/com_gcp.svg";
import { ReactComponent as AzureLogo } from "../assets/icons/com_azure.svg";
import { ReactComponent as BellLogo } from "../assets/icons/bell.svg";
import { ReactComponent as KSPMIcon } from '../assets/icons/kspm.svg'
import { ReactComponent as CSPMIcon } from '../assets/icons/cspm.svg'
import { ReactComponent as CWPPIcon } from '../assets/icons/cwpp.svg'

import { NextArrow, PrevArrow } from "../pages/partisals/ArrowButtons";
import LoginImage from "../assets/images/user_guide/login.png";
import RegisterImage from "../assets/images/user_guide/register.png";
import App1Image from "../assets/images/user_guide/app_1.png";
import App2Image from "../assets/images/user_guide/app_2.png";
import App3Image from "../assets/images/user_guide/app_3.png";
import App4Image from "../assets/images/user_guide/app_4.png";
import DashboardImage from "../assets/images/user_guide/dashboard_1.png";
import AppDetailImage from "../assets/images/user_guide/app_detail_1.png";
import PolicyImage from "../assets/images/user_guide/policy.png";
import DeployImage from "../assets/images/user_guide/deploy.png";
import AddDeployImage from "../assets/images/user_guide/add_deploy.png";
import WorkflowImage from "../assets/images/user_guide/workflow.png";
import NistLogo from "../assets/images/compliance-logos/NIST 800-53.png";
import GDPRLogo from "../assets/images/compliance-logos/GDPR.png";
import PCILogo from "../assets/images/compliance-logos/PCI DSS.png";
import ISOLogo from "../assets/images/compliance-logos/ISO 27001.png";
import HIPPALogo from "../assets/images/compliance-logos/HIPPA.png";
import SOC2Logo from "../assets/images/compliance-logos/SOC 2.png";
import CISLogo from "../assets/images/compliance-logos/CIS.png";
import BestPracticeLogo from "../assets/images/compliance-logos/Best Practice.png";
import ForsetiSecurityLogo from "../assets/images/compliance-logos/Forseti Security.png";
import CFTScorecardLogo from "../assets/images/compliance-logos/CFT.png";

import icn_aws_accessanalyzer from "../assets/images/aws-resource/accessanalyzer.png";
import icn_aws_acm from "../assets/images/aws-resource/acm.png";
import icn_aws_apigateway from "../assets/images/aws-resource/apigateway.png";
import icn_aws_appstream from "../assets/images/aws-resource/appstream.png";
import icn_aws_cloudformation from "../assets/images/aws-resource/cloudformation.png";
import icn_aws_cloudfront from "../assets/images/aws-resource/cloudfront.png";
import icn_aws_cloudtrail from "../assets/images/aws-resource/cloudtrail.png";
import icn_aws_cloudwatch from "../assets/images/aws-resource/cloudwatch.png";
import icn_aws_codebuild from "../assets/images/aws-resource/codebuild.png";
import icn_aws_config from "../assets/images/aws-resource/config.png";
import icn_aws_directoryservice from "../assets/images/aws-resource/directoryservice.png";
import icn_aws_dynamodb from "../assets/images/aws-resource/dynamodb.png";
import icn_aws_ec2 from "../assets/images/aws-resource/ec2.png";
import icn_aws_ecr from "../assets/images/aws-resource/ecr.png";
import icn_aws_ecs from "../assets/images/aws-resource/ecs.png";
import icn_aws_efs from "../assets/images/aws-resource/efs.png";
import icn_aws_eks from "../assets/images/aws-resource/eks.png";
import icn_aws_emr from "../assets/images/aws-resource/emr.png";
import icn_aws_glacier from "../assets/images/aws-resource/glacier.png";
import icn_aws_glue from "../assets/images/aws-resource/glue.png";
import icn_aws_iam from "../assets/images/aws-resource/iam.png";
import icn_aws_kms from "../assets/images/aws-resource/kms.png";
import icn_aws_lambda from "../assets/images/aws-resource/lambda.png";
import icn_aws_opensearch from "../assets/images/aws-resource/opensearch.png";
import icn_aws_rds from "../assets/images/aws-resource/rds.png";
import icn_aws_redshift from "../assets/images/aws-resource/redshift.png";
import icn_aws_route53 from "../assets/images/aws-resource/route53.png";
import icn_aws_s3 from "../assets/images/aws-resource/s3.png";
import icn_aws_sagemaker from "../assets/images/aws-resource/sagemaker.png";
import icn_aws_ssm from "../assets/images/aws-resource/ssm.png";
import icn_aws_trustedadvisor from "../assets/images/aws-resource/trustedadvisor.png";
import icn_aws_vpc from "../assets/images/aws-resource/vpc.png";
import icn_aws_workspaces from "../assets/images/aws-resource/workspaces.png";
import icn_aws_codeartifact from "../assets/images/aws-resource/codeartifact.png";
import icn_aws_guardduty from "../assets/images/aws-resource/guardduty.png";
import icn_aws_account from "../assets/images/aws-resource/account.png";
import icn_aws_macie from "../assets/images/aws-resource/macie.png";
import icn_aws_sns from "../assets/images/aws-resource/sns.png";
import icn_aws_secretsmanager from "../assets/images/aws-resource/secretsmanager.png";
import icn_aws_sqs from "../assets/images/aws-resource/sqs.png";
import icn_aws_shield from "../assets/images/aws-resource/shield.png";
import icn_aws_securityhub from "../assets/images/aws-resource/securityhub.png";

import icn_gcp_appengine from "../assets/images/gcp-resource/appengine.png"
import icn_gcp_bigquery from "../assets/images/gcp-resource/bigquery.png"
import icn_gcp_compute from "../assets/images/gcp-resource/compute.png"
import icn_gcp_dataproc from "../assets/images/gcp-resource/dataproc.png"
import icn_gcp_dns from "../assets/images/gcp-resource/dns.png"
import icn_gcp_iam from "../assets/images/gcp-resource/iam.png"
import icn_gcp_kms from "../assets/images/gcp-resource/kms.png"
import icn_gcp_kubernetes from "../assets/images/gcp-resource/kubernetes.png"
import icn_gcp_logging from "../assets/images/gcp-resource/logging.png"
import icn_gcp_organization from "../assets/images/gcp-resource/organization.png"
import icn_gcp_project from "../assets/images/gcp-resource/project.png"
import icn_gcp_sql from "../assets/images/gcp-resource/sql.png"
import icn_gcp_storage from "../assets/images/gcp-resource/storage.png"

import icn_azure_activedirectory from "../assets/images/azure-resource/activedirectory.png"
import icn_azure_apimanagement from "../assets/images/azure-resource/apimanagement.png"
import icn_azure_appconfiguration from "../assets/images/azure-resource/appconfiguration.png"
import icn_azure_appservice from "../assets/images/azure-resource/appservice.png"
import icn_azure_automation from "../assets/images/azure-resource/automation.png"
import icn_azure_batch from "../assets/images/azure-resource/batch.png"
import icn_azure_cognitiveservice from "../assets/images/azure-resource/cognitiveservice.png"
import icn_azure_compute from "../assets/images/azure-resource/compute.png"
import icn_azure_containerregistry from "../assets/images/azure-resource/containerregistry.png"
import icn_azure_cosmosdb from "../assets/images/azure-resource/cosmosdb.png"
import icn_azure_databoxedge from "../assets/images/azure-resource/databoxedge.png"
import icn_azure_datafactory from "../assets/images/azure-resource/datafactory.png"
import icn_azure_datalakeanalytics from "../assets/images/azure-resource/datalakeanalytics.png"
import icn_azure_datalakestore from "../assets/images/azure-resource/datalakestore.png"
import icn_azure_dns from "../assets/images/azure-resource/dns.png"
import icn_azure_eventgrid from "../assets/images/azure-resource/eventgrid.png"
import icn_azure_eventhub from "../assets/images/azure-resource/eventhub.png"
import icn_azure_frontdoor from "../assets/images/azure-resource/frontdoor.png"
import icn_azure_hdinsight from "../assets/images/azure-resource/hdinsight.png"
import icn_azure_hpccache from "../assets/images/azure-resource/hpccache.png"
import icn_azure_iothub from "../assets/images/azure-resource/iothub.png"
import icn_azure_keyvault from "../assets/images/azure-resource/keyvault.png"
import icn_azure_kubernetes from "../assets/images/azure-resource/kubernetes.png"
import icn_azure_logic from "../assets/images/azure-resource/logic.png"
import icn_azure_machinelearning from "../assets/images/azure-resource/machinelearning.png"
import icn_azure_mariadb from "../assets/images/azure-resource/mariadb.png"
import icn_azure_monitor from "../assets/images/azure-resource/monitor.png"
import icn_azure_mysql from "../assets/images/azure-resource/mysql.png"
import icn_azure_network from "../assets/images/azure-resource/network.png"
import icn_azure_postgres from "../assets/images/azure-resource/postgres.png"
import icn_azure_redis from "../assets/images/azure-resource/redis.png"
import icn_azure_resourcemanagement from "../assets/images/azure-resource/resourcemanagement.png"
import icn_azure_securitycenter from "../assets/images/azure-resource/securitycenter.png"
import icn_azure_servicebus from "../assets/images/azure-resource/servicebus.png"
import icn_azure_servicefabric from "../assets/images/azure-resource/servicefabric.png"
import icn_azure_signalr from "../assets/images/azure-resource/signalr.png"
import icn_azure_springcloud from "../assets/images/azure-resource/springcloud.png"
import icn_azure_sql from "../assets/images/azure-resource/sql.png"
import icn_azure_storage from "../assets/images/azure-resource/storage.png"
import icn_azure_storagesync from "../assets/images/azure-resource/storagesync.png"
import icn_azure_streamanalytics from "../assets/images/azure-resource/streamanalytics.png"
import icn_azure_synapse from "../assets/images/azure-resource/synapse.png"

import TerraformLogo from "../assets/images/iac-logos/terraform-logo.png";
import AnsibleLogo from "../assets/images/iac-logos/ansible-logo.png";
import KubernetesLogo from "../assets/images/iac-logos/kubernetes-logo.png";
import HelmLogo from "../assets/images/iac-logos/helm-logo.png";
import AWSCloudformationLogo from "../assets/images/iac-logos/aws-cloudformation-logo.png";
import MSArmLogo from "../assets/images/iac-logos/ms-arm-template-logo.png";
import DockerLogo from "../assets/images/iac-logos/docker-logo.png";
import GoogleDeploymentLogo from "../assets/images/iac-logos/google-deployment-logo.png";
import PulumiLogo from "../assets/images/iac-logos/pulumi-logo.png";

import CSPMImg from "../assets/images/CSPM/bg.jpeg"
import CWPPImg from "../assets/images/CWP/bg.jpeg"
import KSPMImg from "../assets/images/KSPM/bg.jpeg"

// export const baseURL = window?.location?.origin === 'https://pilot.cloudmatos.dev' ? window?.location?.origin : 'https://pilot.cloudmatos.dev'
export const baseURL =
  window?.location?.origin === "https://pilot.cloudmatos.dev"
    ? window?.location?.origin
    : process.env.REACT_APP_BASEURL;
export const getBaseURL = () => {
  const origin = window?.location?.origin.split("//");
  const origin_split = origin?.[1].split(".");

  if (origin_split[0]?.split(":")?.[0] === "localhost") {
    return process.env.REACT_APP_BASEURL;
    // return `http://${origin_split?.[0].split(":")?.[0]}`;
  }

  return `https://${origin_split?.[0]}-backend.${origin_split
    ?.slice(1, origin_split?.length)
    .join(".")}`;
};

export const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const noneOption = { value: "", label: "None" };
export const colors = {
  red: "#ff487f",
  blue: "#5EB1E4",
  menu_inactive: "#FAFCFF",
  menu_active: "#505050",
  custom_fill_inactive: "#65C2D7",
  caution: {
    red: "#ff487f",
    blue: "#5EB1E4",
    gray: "#A4AFB7",
  },
  status: ["#66C4D5", "#5EB1E4", "#FF487F"],
  input_color: {
    active: "#66c4d5",
    inactive: "#C1C3CD",
  },
};

export const menu_list = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: {
      active: (
        <DashboardIcon fill={colors.menu_active} width={40} height={40} />
      ),
      small: <DashboardIcon fill={colors.menu_active} style={{ height: 25 }} />,
      inactive: (
        <DashboardIcon fill={colors.menu_inactive} width={40} height={40} />
      ),
    },
  },
  {
    path: "/cloud",
    title: "Cloud Security",
    submenu: ['cspm', 'kspm', 'cwp'],
    icon: {
      active: <ShieldIcon fill={colors.menu_active} width={40} height={40} />,
      small: <ShieldIcon fill={colors.menu_active} style={{ height: 25 }} />,
      inactive: (
        <ShieldIcon fill={colors.menu_inactive} width={40} height={40} />
      ),
    },
  },
  
  {
    path: "/iac",
    title: "IaC Scan",
    icon: {
      active: <CodeForkIcon fill={colors.menu_active} width={40} height={40} />,
      small: <CodeForkIcon fill={colors.menu_active} style={{ height: 25 }} />,
      inactive: (
        <CodeForkIcon fill={colors.menu_inactive} width={40} height={40} />
      ),
    },
  },
  {
    path: "/history",
    title: "Activity Logs",
    // title: 'History',
    icon: {
      active: <BellLogo fill={colors.menu_active} width={40} height={40} />,
      small: <BellLogo fill={colors.menu_active} style={{ height: 25 }} />,
      inactive: <BellLogo fill={colors.menu_inactive} width={40} height={40} />,
    },
  },
  {
    path: "/manage",
    title: "Settings",
    // title: 'Manage',
    icon: {
      active: (
        <SettingsIcon
        // stroke={colors.menu_active}
        fill={colors.menu_active}
        width={40}
        height={40}
        />
      ),
      small: (
        <SettingsIcon
        // stroke={colors.menu_active}
        fill={colors.menu_inactive}
        style={{ height: 25 }}
        />
      ),
      inactive: (
        <SettingsIcon
          // stroke={colors.menu_inactive}
          fill={colors.menu_inactive}
          width={40}
          height={40}
          />
      ),
    },
  },
  
];

export const settingSlide = (props) => ({
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: props.showSlideCnt ?? 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow type={props.type} />,
  prevArrow: <PrevArrow type={props.type} />,
});

export const fakeActivityFeedData = {
  today: [
    {
      status: 1,
      name: "Activity Feed #1",
      passTime: "12h",
      content: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum ",
    },
    {
      status: 1,
      name: "Activity Feed #1",
      passTime: "12h",
      content: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum ",
    },
    {
      status: 2,
      name: "Activity Feed #1",
      passTime: "12h",
      content: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum ",
    },
    {
      status: 3,
      name: "Activity Feed #1",
      passTime: "12h",
      content: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum ",
    },
    {
      status: 1,
      name: "Activity Feed #1",
      passTime: "12h",
      content: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum ",
    },
    {
      status: 2,
      name: "Activity Feed #1",
      passTime: "12h",
      content: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum ",
    },
  ],
  yesterday: [
    {
      status: 1,
      name: "Activity Feed #1",
      passTime: "12h",
      content: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum ",
    },
    {
      status: 2,
      name: "Activity Feed #1",
      passTime: "12h",
      content: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum ",
    },
    {
      status: 2,
      name: "Activity Feed #1",
      passTime: "12h",
      content: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum ",
    },
    {
      status: 3,
      name: "Activity Feed #1",
      passTime: "12h",
      content: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum ",
    },
  ],
};

export const fakeObservationData = {
  instances: [
    {
      status: 1,
      name: "cloudmatos-gitlab-vm",
      passTime: "2h",
      content: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum",
    },
    {
      status: 1,
      name: "vm-prometheus",
      passTime: "4h",
      content: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum",
    },
    {
      status: 2,
      name: "vm-kiali",
      passTime: "7h",
      content: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum",
    },
  ],
  clusters: [
    {
      status: 1,
      name: "cluster-1",
      passTime: "9h",
      content: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum",
    },
    {
      status: 2,
      name: "demo-cluster",
      passTime: "12h",
      content: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum",
    },
    {
      status: 2,
      name: "grafana-cluster",
      passTime: "15h",
      content: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum",
    },
  ],
};

export const fakeDeploymentData = [
  {
    id: 0,
    name: "sched-ansible-cilium-policy-configure",
    type: "ansible",
    repo_url:
      "https://github.com/cloudmatos/sched-ansible-cilium-policy-configure - main",
    createdDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    updatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    creator: "admin 1",
    deployments: "1 day ago",
    node_instance: {
      fail: 1,
      refresh: 2,
      running: 3,
      delete: 0,
    },
  },
  {
    id: 1,
    name: "sched-ansible-cilium-policy-configure",
    type: "ansible",
    repo_url:
      "https://github.com/cloudmatos/sched-ansible-cilium-policy-configure - main",
    createdDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    updatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    creator: "admin 1",
    deployments: "1 day ago",
    node_instance: {
      fail: 1,
      refresh: 2,
      running: 3,
      delete: 0,
    },
  },
  {
    id: 2,
    name: "sched-ansible-cilium-policy-configure",
    type: "ansible",
    repo_url:
      "https://github.com/cloudmatos/sched-ansible-cilium-policy-configure - main",
    createdDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    updatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    creator: "admin 1",
    deployments: "2 days ago",
    node_instance: {
      fail: 1,
      refresh: 2,
      running: 3,
      delete: 0,
    },
  },
  {
    id: 3,
    name: "sched-terraform-cilium-policy-configure",
    type: "terraform",
    repo_url:
      "https://github.com/cloudmatos/sched-ansible-cilium-policy-configure - main",
    createdDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    updatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    creator: "admin 1",
    deployments: "3 days ago",
    node_instance: {
      fail: 1,
      refresh: 2,
      running: 3,
      delete: 0,
    },
  },
];

export const fakeRemediationData = [
  {
    status: "red",
    name: "name-eploy-openstack",
    stack: "template-deploy-openstack",
  },
  {
    status: "red",
    name: "name-eploy-openstack",
    stack: "template-deploy-openstack",
  },
  {
    status: "blue",
    name: "name-eploy-openstack",
    stack: "template-deploy-openstack",
  },
  {
    status: "blue",
    name: "name-eploy-openstack",
    stack: "template-deploy-openstack",
  },
  {
    status: "gray",
    name: "name-eploy-openstack",
    stack: "template-deploy-openstack",
  },
  {
    status: "gray",
    name: "name-eploy-openstack",
    stack: "template-deploy-openstack",
  },
];

export const fakeResourceData = [
  { status: 1, name: "node-kiali-development", resources: 372 },
  { status: 1, name: "node-production-app", resources: 280 },
  { status: 2, name: "node-testing", resources: 177 },
  { status: 2, name: "node-deploy-app", resources: 150 },
];

export const fakeHealthIndicatorData = [
  { label: "Health Indicator", percent: "-25%", timePast: 7, value: 70 },
  // {label: 'Health Indicator', percent: '-17%', timePast: 2, value: 87},
];

export const fakeAppData = [
  {
    id: 1,
    name: "demo-app-cm",
    percent: "-14%",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 2,
    name: "demo-app-cm",
    percent: "-14%",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 3,
    name: "qa-app-cm",
    percent: "-12%",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 4,
    name: "qa-app-cm",
    percent: "-12%",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 5,
    name: "jason-testing",
    percent: "-9%",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 6,
    name: "mobile-app-cm",
    percent: "-12%",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 7,
    name: "demo-app-cm",
    percent: "-14%",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 8,
    name: "qa-app-cm",
    percent: "-12%",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 9,
    name: "qa-app-cm",
    percent: "-7%",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 10,
    name: "jason-testing",
    percent: "-9%",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 11,
    name: "mobile-app-cm",
    percent: "-12%",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 12,
    name: "landing-app-cm",
    percent: "-12%",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 13,
    name: "jason-testing",
    percent: "-9%",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 14,
    name: "mobile-app-cm",
    percent: "-3%",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 15,
    name: "landing-app-cm",
    percent: "-6%",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
];

export const fakeEnvData = [
  {
    id: 1,
    name: "Production Environment",
    apps: 12,
    status: 1,
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 2,
    name: "Staging Environment",
    apps: 16,
    status: 1,
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 3,
    name: "Development Environment",
    apps: 8,
    status: 2,
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 4,
    name: "Testing Environment",
    apps: 12,
    status: 2,
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 5,
    name: "Production Environment",
    apps: 12,
    status: 1,
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 6,
    name: "Staging Environment",
    apps: 16,
    status: 1,
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  {
    id: 7,
    name: "Development Environment",
    apps: 8,
    status: 1,
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
];

export const cspData = [
  {
    key: "aws",
    title: "Amazon Web Services (AWS)",
    logo: (size) => <AWSLogo width={size ?? 40} height={size ?? 40} />,
    abbr: "AWS",
  },
  {
    key: "gcp",
    title: "Google Cloud Platform (GCP)",
    logo: (size) => <GCPLogo width={size ?? 40} height={size ?? 40} />,
    abbr: "GCP",
  },
  {
    key: "azure",
    title: "Microsoft Azure",
    logo: (size) => <AzureLogo width={size ?? 40} height={size ?? 40} />,
    abbr: "Azure",
  },
];

export const cloudDashboardTabList = [
  { key: 'top_priority', label: 'Top Priority' },
  { key: 'security', label: 'Security' },
  { key: 'compliance', label: 'Compliance' },
  { key: 'assets', label: 'Assets' },
]

export const envList = [
  {
    key: "demo",
    abbr: "dev",
    title: "Demo Environment",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
  // {key: 'staging', title: 'Staging Environment', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.'},
  // {key: 'testing', title: 'Testing Environment', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.'},
  {
    key: "production",
    abbr: "prod",
    title: "Production Environment",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
  },
];

export const defaultEnv = {
  key: "default",
  abbr: "default",
  title: "Default Environment",
  content:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at sem pulvinar.",
};

export const loadingBar = (
  type = "Rings",
  width = 80,
  height = 80,
  color = "#65C2D7",
  secondaryColor = "#ff487f"
) => {
  return (
    <Loader
      type={type}
      color={color}
      secondaryColor={secondaryColor}
      height={width}
      width={height}
    // timeout={3000} //3 secs
    />
  );
};

export const getPassTime = (time) => {
  const timeInterval = time
    ? parseInt(moment().format("X")) - parseInt(time)
    : 0;

  return timeInterval > 0 && timeInterval < 3600
    ? "~1h"
    : timeInterval > 3600
      ? `${Math.ceil(timeInterval / 3600)}h`
      : "0h";
};

export const deployTabList = [
  { title: "Deployments", key: "deployments", active: true },
  { title: "Policies", key: "policies", active: false },
];

export const manageTabList = {
  admin: [
    { title: 'Profile', key: 'profile', active: false },
    { title: 'Infrastructures', key: 'infra', active: false },
    // { title: "Organizations", key: "organizations", active: false },
    { title: "Users", key: "users", active: false },
    // {title: 'Settings', key: 'settings', active: false},
  ],
  "org-admin": [
    { title: 'Profile', key: 'profile', active: true },
    { title: "Environments", key: "environments", active: false },
    { title: 'Infrastructures', key: 'infra', active: false },
    { title: "Compliance", key: "compliance", active: false },
    { title: "Report", key: "report", active: false },
    { title: "Notification", key: "settings", active: false },
    { title: "Users", key: "users", active: false },
    { title: "API Keys", key: "api_keys", active: false },
    // { title: "Subscriptions", key: "plans", active: false },
    // { title: "Payments", key: "payments", active: false },
  ],
  user: [
    { title: 'Infrastructures', key: 'infra', active: false },
    { title: "Report", key: "report", active: false },
    // {title: 'Settings', key: 'settings', active: true},
    // {title: 'Plans', key: 'plans', active: false},
  ],
};

export const iacTabList = [
  { title: "Summary", key: "summary", active: true },
  { title: "Details", key: "details", active: false },
  // {title: 'Runtime', key: 'run_time', active: false},
];

export const cloudTabList = [
  { title: "Summary", key: "summary", active: true },
  { title: "Details", key: "details", active: false },
  // { title: "Graph", key: "graph", active: false },
  { title: "Assets", key: "assets", active: false },
  // {title: 'Runtime', key: 'run_time', active: false},
];

export const cwpTabList = [
  { title: "Summary", key: "summary", active: true },
  { title: "Details", key: "details", active: false },
];

export const historyTabList = [
  { title: "Login", key: "auth", active: false },
  { title: "Audit", key: "audit", active: false },
  { title: "Application", key: "application", active: false },
  { title: "Notifications", key: "notification", active: true },
];

export const observeTabList = [
  { title: "Summary", key: "summary", active: true },
  { title: "Remediation", key: "remediation", active: false },
];

export const dashboardTabList = [
  { title: "Cloud Security", key: "cloud", active: true },
  { title: "IaC Security", key: "iac", active: false },
];

export const policyStatusInfo = {
  draft: { name: "Draft", color: "#A4AFB7" },
  approved: { name: "Approved", color: "#70DFBF" },
  applied: { name: "Applied", color: "#FFB800" },
  deprecated: { name: "Deprecated", color: "#FF487F" },
  active: { name: "Active", color: "#66C4D5" },
};

export const fakePoliciesData = [
  {
    id: 1,
    name: "Cloudify-nodecellar-openstack 1",
    created: moment().format("YYYY-MM-DD HH:mm:ss"),
    creator: "admin 1",
    status: "draft",
    resources: "",
  },
  {
    id: 2,
    name: "Cloudify-nodecellar-openstack 2",
    created: moment().format("YYYY-MM-DD HH:mm:ss"),
    creator: "admin 1",
    status: "approved",
    resources: "",
  },
  {
    id: 3,
    name: "Cloudify-nodecellar-openstack 3",
    created: moment().format("YYYY-MM-DD HH:mm:ss"),
    creator: "admin 1",
    status: "applied",
    resources: "",
  },
  {
    id: 4,
    name: "Cloudify-nodecellar-openstack 4",
    created: moment().format("YYYY-MM-DD HH:mm:ss"),
    creator: "admin 1",
    status: "deprecated",
    resources: "",
  },
  {
    id: 5,
    name: "Cloudify-nodecellar-openstack 5",
    created: moment().format("YYYY-MM-DD HH:mm:ss"),
    creator: "admin 1",
    status: "active",
    resources: "",
  },
  {
    id: 6,
    name: "Cloudify-nodecellar-openstack 6",
    created: moment().format("YYYY-MM-DD HH:mm:ss"),
    creator: "admin 1",
    status: "draft",
    resources: "",
  },
  {
    id: 7,
    name: "Cloudify-nodecellar-openstack 7",
    created: moment().format("YYYY-MM-DD HH:mm:ss"),
    creator: "admin 1",
    status: "draft",
    resources: "",
  },
  {
    id: 8,
    name: "Cloudify-nodecellar-openstack 8",
    created: moment().format("YYYY-MM-DD HH:mm:ss"),
    creator: "admin 1",
    status: "draft",
    resources: "",
  },
  {
    id: 9,
    name: "Cloudify-nodecellar-openstack 9",
    created: moment().format("YYYY-MM-DD HH:mm:ss"),
    creator: "admin 1",
    status: "deprecated",
    resources: "",
  },
  {
    id: 10,
    name: "Cloudify-nodecellar-openstack 10",
    created: moment().format("YYYY-MM-DD HH:mm:ss"),
    creator: "admin 1",
    status: "draft",
    resources: "",
  },
];

export const userStatusInfo = {
  active: { name: "Active", color: "#66C4D5" },
  inactive: { name: "Inactive", color: "#A4AFB7" },
  deleted: { name: "Deleted", color: "#ff487f" },
};

export const orgStatusInfo = {
  active: { name: "Active", color: "#66C4D5" },
  NEW: { name: "Active", color: "#66C4D5" },
  inactive: { name: "Inactive", color: "#A4AFB7" },
  deleted: { name: "Deleted", color: "#ff487f" },
};

export const userRoleInfo = {
  admin: { name: "Admin", color: "#ff487f" },
  "org-admin": { name: "Org Admin", color: "#65C2D7" },
  user: { name: "User", color: "#485DCD" },
};
export const fakeUsersData = [
  {
    id: 1,
    first_name: "Allen",
    last_name: "Tang",
    organization_name: "organization-01",
    email: "allent@cloudmatos.com",
    status: "active",
    role: "admin",
  },
  {
    id: 2,
    first_name: "Anand",
    last_name: "Nevas",
    organization_name: "organization-02",
    email: "anandn@cloudmatos.com",
    status: "active",
    role: "operator",
  },
  {
    id: 3,
    first_name: "Hariom",
    last_name: "Tiwari",
    organization_name: "organization-01",
    email: "hariomt@cloudmatos.com",
    status: "inactive",
    role: "operator",
  },
  {
    id: 4,
    first_name: "Chris",
    last_name: "Argacia",
    organization_name: "organization-04",
    email: "chrisa@cloudmatos.com",
    status: "inactive",
    role: "operator",
  },
];

export const fakeNotificationsData = [
  {
    id: 1,
    type: "Instance",
    name: "cloudmatos-gitlab-vm",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis augue in velit dictum efficitur bibendum et ante.",
    status: "Alert",
    opened: "2 hours ago",
  },
  {
    id: 2,
    type: "Instance",
    name: "vm-prometheus",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis augue in velit dictum efficitur bibendum et ante.",
    status: "Alert",
    opened: "2 hours ago",
  },
  {
    id: 3,
    type: "Cluster",
    name: "cluster-1",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis augue in velit dictum efficitur bibendum et ante.",
    status: "Resolved",
    opened: "2 hours ago",
  },
  {
    id: 4,
    type: "Cluster",
    name: "grafana-cluster",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis augue in velit dictum efficitur bibendum et ante.",
    status: "Inactive",
    opened: "2 hours ago",
  },
];

export const fakeApplications = [
  {
    id: 1,
    name: "mobile-dev-team-jenkins-gke",
    address: "template-deploy-openstack",
  },
  {
    id: 2,
    name: "mobile-dev-team-jenkins-gke",
    address: "template-deploy-openstack",
  },
  {
    id: 3,
    name: "mobile-dev-team-jenkins-gke",
    address: "template-deploy-openstack",
  },
  {
    id: 4,
    name: "mobile-dev-team-jenkins-gke",
    address: "template-deploy-openstack",
  },
];

export const fakeGCPProjects = [
  {
    id: 1,
    name: "Lorem Ipsum",
    compute: "Lorem Ipsum",
    storage: "Lorem Ipsum",
  },
  {
    id: 2,
    name: "Lorem Ipsum",
    compute: "Lorem Ipsum",
    storage: "Lorem Ipsum",
  },
  {
    id: 3,
    name: "Lorem Ipsum",
    compute: "Lorem Ipsum",
    storage: "Lorem Ipsum",
  },
  {
    id: 4,
    name: "Lorem Ipsum",
    compute: "Lorem Ipsum",
    storage: "Lorem Ipsum",
  },
];

export const fakeVMInstances = [
  {
    id: 1,
    name: "Lorem Ipsum",
    internal_ip: "Lorem Ipsum",
    external_ip: "Lorem Ipsum",
  },
  {
    id: 2,
    name: "Lorem Ipsum",
    internal_ip: "Lorem Ipsum",
    external_ip: "Lorem Ipsum",
  },
  {
    id: 3,
    name: "Lorem Ipsum",
    internal_ip: "Lorem Ipsum",
    external_ip: "Lorem Ipsum",
  },
  {
    id: 4,
    name: "Lorem Ipsum",
    internal_ip: "Lorem Ipsum",
    external_ip: "Lorem Ipsum",
  },
];

export const fakeGCPVMInstances = [
  {
    id: 1,
    name: "Lorem Ipsum",
    internal_ip: "Lorem Ipsum",
    external_ip: "Lorem Ipsum",
    zone: "Lorem Ipsum",
  },
  {
    id: 2,
    name: "Lorem Ipsum",
    internal_ip: "Lorem Ipsum",
    external_ip: "Lorem Ipsum",
    zone: "Lorem Ipsum",
  },
  {
    id: 3,
    name: "Lorem Ipsum",
    internal_ip: "Lorem Ipsum",
    external_ip: "Lorem Ipsum",
    zone: "Lorem Ipsum",
  },
  {
    id: 4,
    name: "Lorem Ipsum",
    internal_ip: "Lorem Ipsum",
    external_ip: "Lorem Ipsum",
    zone: "Lorem Ipsum",
  },
];

export const fakeClusters = [
  {
    id: 1,
    name: "Lorem Ipsum",
    cores: 1,
    endpoint: "Lorem Ipsum",
    location: "Lorem Ipsum",
    memory: "Lorem Ipsum",
  },
  {
    id: 2,
    name: "Lorem Ipsum",
    cores: 1,
    endpoint: "Lorem Ipsum",
    location: "Lorem Ipsum",
    memory: "Lorem Ipsum",
  },
  {
    id: 3,
    name: "Lorem Ipsum",
    cores: 1,
    endpoint: "Lorem Ipsum",
    location: "Lorem Ipsum",
    memory: "Lorem Ipsum",
  },
  {
    id: 4,
    name: "Lorem Ipsum",
    cores: 1,
    endpoint: "Lorem Ipsum",
    location: "Lorem Ipsum",
    memory: "Lorem Ipsum",
  },
];

export const fakeServices = [
  {
    id: 1,
    services: "Lorem Ipsum",
    namespace: "Lorem Ipsum",
    endpoint: "Lorem Ipsum",
    type: "Lorem Ipsum",
  },
  {
    id: 2,
    services: "Lorem Ipsum",
    namespace: "Lorem Ipsum",
    endpoint: "Lorem Ipsum",
    type: "Lorem Ipsum",
  },
  {
    id: 3,
    services: "Lorem Ipsum",
    namespace: "Lorem Ipsum",
    endpoint: "Lorem Ipsum",
    type: "Lorem Ipsum",
  },
  {
    id: 4,
    services: "Lorem Ipsum",
    namespace: "Lorem Ipsum",
    endpoint: "Lorem Ipsum",
    type: "Lorem Ipsum",
  },
];

export const fakeWorkloads = [
  { id: 1, name: "Lorem Ipsum", namespace: "Lorem Ipsum", pods: "Lorem Ipsum" },
  { id: 2, name: "Lorem Ipsum", namespace: "Lorem Ipsum", pods: "Lorem Ipsum" },
  { id: 3, name: "Lorem Ipsum", namespace: "Lorem Ipsum", pods: "Lorem Ipsum" },
  { id: 4, name: "Lorem Ipsum", namespace: "Lorem Ipsum", pods: "Lorem Ipsum" },
];

export const getCurrentOrganization = () => {
  let currentUser = JSON.parse(localStorage.getItem("current-user"));
  return currentUser?.organization_id;
};

export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("current-user"));
};

export const currentRole = () => {
  return JSON.parse(localStorage.getItem("current-user"))?.role ?? "user";
};

export const showAlert = (type, title, message, timeout = 7000) => {
  switch (type) {
    case "info":
      NotificationManager.info(message, title, timeout);
      break;
    case "success":
      NotificationManager.success(message, title, timeout);
      break;
    case "warning":
      NotificationManager.warning(message, title, timeout);
      break;
    case "error":
      NotificationManager.error(message, title, timeout);
      break;
    default:
      break;
  }
};

export const devopsArr = ["terraform", "ansible"];

export const applicationInputOption = ["Git", "YAML", "JSON"];

export const categories = [
  { id: 1, name: "Infrastructure" },
  { id: 2, name: "Cloud Project" },
];

export const deployTypes = [
  { value: "infra", label: "Infrastructure" },
  { value: "policy", label: "Infra Policy" },
];

export const getParamsStr = (params) => {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
};

export const convertSummaryData = (summary) => {
  let convertedData = {};
  summary.forEach((item) => {
    convertedData[item.resource_type] = item.count;
  });
  return convertedData;
};

export const templateTypeList = [
  "Deployment",
  "Reconfiguration",
  "Remediation",
];

export const getResourceCount = (cluster, resourceKind) => {
  let resourceCnt = 0;
  if (cluster) {
    cluster.forEach((item) => {
      resourceCnt += item?.[resourceKind]?.length ?? 0;
    });
  }
  return resourceCnt;
};

export const platformList = [
  { value: "gcp", label: "GCP" },
  { value: "aws", label: "AWS" },
  { value: "azure", label: "Azure" },
];

export const clusterProviderList = [
  { value: "eks", label: "EKS" },
  { value: "gke", label: "GKE" },
  { value: "aks", label: "AKS" },
];

export const clusterStatusOptions = [
  { value: "connected", label: "Connected" },
  { value: "disconnected", label: "Disconnected" },
];

export const planList = [
  {
    id: "feature",
    title: "Fetaure",
    price: {
      monthly: 'Feature',
      annual: 'Feature',
    },
    noUnit: true,
    color: colors.blue,
    foldColor: "#5090E4",
    description: null,
    active: true,
    features: [
      { name: "User", active: true, value: "" },
      { name: "Infrastructure Projects", active: true, value: "" },
      { name: "IaC Projects", active: true, value: "" },
      { name: "Audit", active: true, value: "" },
      { name: "Tenant", active: true, value: "" },
      {
        name: "Assets",
        children: ["Total", "Nodes", "Clusters"],
        active: true,
        value: "",
      },
      {
        name: "CSPM",
        children: ["Asset Inventory", "Remediations", "Asset Graphs", "Multi-Cloud Compliance"],
        active: true,
        value: "",
      },
      {
        name: "KSPM",
        children: ["Risk Analysis, Security Compliance, and Misconfigurations"],
        active: true,
        value: "",
      },
      {
        name: "Workload Protection",
        children: ["Vulnerability Scanning (VMs, Containers, and Serverless Functions)"],
        active: true,
        value: "",
      },
      {
        name: "CIEM",
        children: ["Secure identities and cloud entitlements"],
        active: true,
        value: "",
      },
      {
        name: "IaC",
        children: ["Shift Left Security - Repository Scans (Gitlab, Github & Bitbucket)", "Remediations"],
        active: true,
        value: "",
      },
      {
        name: "Attack Path Analysis",
        // children: ["Shift Left Security - Repository Scans (Gitlab, Github & Bitbucket)", "Remediations"],
        active: true,
        value: "",
      },
      {
        name: "Integrations",
        children: ["Slack", "Microsoft Teams", "JIRA", "ServiceNow", "Tines", "PagerDuty", "Cloud Services (Amazon SQS/SNS, Google Cloud Pub/Sub, Microsoft Azure Service Bus)"],
        active: true,
        value: "",
      },
    ],
  },
  {
    id: "free",
    title: "Free",
    price: {
      monthly: 0,
      annual: 0,
    },
    color: colors.blue,
    foldColor: "#5090E4",
    // description: "",
    active: true,
    features: [
      { name: "Single", active: true, value: "" },
      { name: "5", active: true, value: "" },
      { name: "10", active: true, value: "" },
      { name: "Weekly", active: true, value: "" },
      { name: "Multi-tenant", active: true, value: "" },
      {
        name: null,
        children: ["50", "20", "2"],
        active: true,
        value: "",
      },
      {
        name: null,
        children: ["check", "check", "check", "check"],
        active: true,
        value: "",
      },
      {
        name: null,
        children: ["check"],
        active: true,
        value: "",
      },
      {
        name: null,
        children: ["check"],
        active: true,
        value: "",
      },
      {
        name: null,
        children: ["check"],
        active: true,
        value: "",
      },
      {
        name: null,
        children: ["check", "check"],
        active: true,
        value: "",
      },
      {
        name: "check",
        // children: ["Shift Left Security - Repository Scans (Gitlab, Github & Bitbucket)", "Remediations"],
        active: true,
        value: "",
      },
      {
        name: "Only Email",
        children: ["-", "-", "-", "-", "-", "-", "-"],
        active: true,
        value: "",
      },
    ],
  },
  {
    id: "prime",
    title: "Prime",
    price: {
      monthly: '1,499',
      annual: '1,499',
    },
    color: "#66C4D5",
    foldColor: "#61a1D5",
    description: "Upto 600 Assets",
    active: true,
    features: [
      { name: "Multi", active: true, value: "" },
      { name: "20", active: true, value: "" },
      { name: "200", active: true, value: "" },
      { name: "Daily", active: true, value: "" },
      { name: "Dedicated Cluster", active: true, value: "" },
      {
        name: null,
        children: ["200", "120", "6"],
        active: true,
        value: "",
      },
      {
        name: null,
        children: ["check", "check", "check", "check"],
        active: true,
        value: "",
      },
      {
        name: null,
        children: ["check"],
        active: true,
        value: "",
      },
      {
        name: null,
        children: ["check"],
        active: true,
        value: "",
      },
      {
        name: null,
        children: ["check"],
        active: true,
        value: "",
      },
      {
        name: null,
        children: ["check", "check"],
        active: true,
        value: "",
      },
      {
        name: "check",
        // children: ["Shift Left Security - Repository Scans (Gitlab, Github & Bitbucket)", "Remediations"],
        active: true,
        value: "",
      },
      {
        name: "All",
        children: ["check", "check", "check", "check", "check", "check", "check"],
        active: true,
        value: "",
      },
    ],
  },
  {
    id: "custom",
    title: "Custom",
    price: {
      monthly: "Custom",
      annual: "Custom",
    },
    noUnit: true,
    color: colors.red,
    foldColor: "#f12940",
    description: "Contact Us",
    active: true,
    features: [
      { name: "Multi", active: true, value: "" },
      { name: "Custom", active: true, value: "" },
      { name: "Custom", active: true, value: "" },
      { name: "Daily", active: true, value: "" },
      { name: "Dedicated Cluster", active: true, value: "" },
      {
        name: null,
        children: ["Custom", "Custom", "Custom"],
        active: true,
        value: "",
      },
      {
        name: null,
        children: ["check", "check", "check", "check"],
        active: true,
        value: "",
      },
      {
        name: null,
        children: ["check"],
        active: true,
        value: "",
      },
      {
        name: null,
        children: ["check"],
        active: true,
        value: "",
      },
      {
        name: null,
        children: ["check"],
        active: true,
        value: "",
      },
      {
        name: null,
        children: ["check", "check"],
        active: true,
        value: "",
      },
      {
        name: "check",
        // children: ["Shift Left Security - Repository Scans (Gitlab, Github & Bitbucket)", "Remediations"],
        active: true,
        value: "",
      },
      {
        name: null,
        children: ["check", "check", "check", "check", "check", "check", "check"],
        active: true,
        value: "",
      },
    ],
  },
];

export const getDetailParams = (count, step) => {
  const nCols = Math.ceil(count / step);
  return {
    cols: nCols,
    rows: Math.floor(count / nCols),
    size: nCols,
  };
};

export const getChildren = (data, params, color, currentRow, depth) => {
  let selectedData =
    currentRow === -1
      ? data.filter((item, idx) => idx < params.rows)
      : [data[params.rows * (depth + 1) + currentRow]];
  if (
    depth === 0 &&
    data[params.rows * params.cols + (currentRow === -1 ? 0 : currentRow)]
  ) {
    selectedData = [
      ...selectedData,
      data[params.rows * params.cols + (currentRow === -1 ? 0 : currentRow)],
    ];
  }
  return selectedData
    .filter((item) => item)
    .map((item, idx) => {
      let child = {
        name: item?.name ?? "",
        description: item?.description ?? "",
        color: color,
      };
      if (depth !== 0) {
        child["children"] = getChildren(
          data,
          params,
          color,
          currentRow === -1 ? idx : currentRow,
          depth - 1
        );
      } else {
        child["size"] = 2;
        // child['size'] = 2 / selectedData.length
        // child['size'] = params.size
      }
      return child;
    });
};

export const stackBarInfoList = [
  {
    label: "Cluster",
    labelAbbr: "cluster",
    labelKey: "green",
    color: "#00FFA2",
    grad: [
      { offset: 0, opacity: 0.49 },
      { offset: 100, opacity: 0.14 },
    ],
  },
  {
    label: "Node",
    labelAbbr: "node",
    labelKey: "yellow",
    color: "#FFBA00",
    grad: [
      { offset: 0, opacity: 0.53 },
      { offset: 100, opacity: 0.11 },
    ],
  },
  {
    label: "Pod",
    labelAbbr: "pod",
    labelKey: "red",
    color: "#FF4D00",
    grad: [
      { offset: 0, opacity: 0.57 },
      { offset: 100, opacity: 0.12 },
    ],
  },
  {
    label: "Service",
    labelAbbr: "service",
    labelKey: "flashing",
    color: "#00B1FF",
    grad: [
      { offset: 0, opacity: 0.5 },
      { offset: 100, opacity: 0 },
    ],
  },
];

export const timeSeriesColors = [
  "#FF487F",
  "#5EB1E4",
  "#485DCD",
  "#505050",
  "#FF4D00",
  "#00FFA2",
  "#66C4D5",
  "#FFBA00",
  "#00B1FF",
];

export const userGuideItems = {
  root: {
    index: "root",
    hasChildren: true,
    children: [
      "matossphere",
      "general-setup",
      "feature-functionality",
      "annexures",
    ],
    data: "MatosSphere",
    link: "/doc",
    description: "Self-Healing Cloud Infrastructure Solution",
  },
  matossphere: {
    index: "matossphere",
    hasChildren: false,
    children: [],
    data: "MatosSphere",
    link: "/doc/matossphere",
    description: "Self-Healing Cloud Infrastructure Solution",
    next: "general-setup",
    contents: [
      {
        type: "text",
        content:
          "MatosSphere is a self healing cloud infrastructure solution designed to address core infrastructure needs by providing deeper observability and intelligent remediation while simplifying management of cloud infrastructure environments.",
      },
      {
        type: "text",
        content:
          "<strong>MatosSphere</strong> enables you to access, manage and monitor applications underlying infrastructure data from multiple viewpoints. The following feature sets give better insight about the state and structure of applications.",
      },
      {
        type: "list",
        content: [
          "<strong>Dashboard</strong> enables consolidated view of all applications and underlying health status within the account grouped by defined environments.",
          "<strong>Observe</strong> provides information from running VMs and Containers to infrastructure recommendations at the environment and application level. You can view the health and life cycles of self-managed infrastructure and monitor systems at the incident level.",
          "<strong>Deploy</strong> lets you create, manage, and deploy application infrastructure to a cloud environment by using git repository or IAC templates from MatosSphere repository catalogue.",
          "<strong>Remediate</strong> enables you to fix detected or reported application anomalies associated with infrastructure resources relevant to configurations, scalability/availability, security and policies. Remediations can be automated or governed by approval workflows.",
          "<strong>Manage</strong> enables management of accounts, users, environments and settings/configurations.",
          "<strong>History</strong> enables management of audit trails for all activities and reporting configurations.",
        ],
      },
    ],
  },
  "general-setup": {
    index: "general-setup",
    hasChildren: true,
    children: ["onboarding", "login", "application"],
    data: "General Setup",
    link: "/doc/general-setup",
    next: "feature-functionality",
    prev: "matossphere",
    contents: [
      {
        type: "list",
        content: [
          "Register to onboard a valid active account with relevant plan",
          [
            "Login and setup",
            "Environments (Default) and applications",
            "Users and Roles",
            "Settings and Configurations",
          ],
        ],
      },
      {
        type: "label",
        id: "onboarding",
        content: "Onboarding",
      },
      { type: "text", content: "Register with primary account on MatosSphere" },
      {
        type: "list",
        content: [
          "On the Login screen, click on “Click here” in front of “Don’t have an account?” to visit the “Registration” screen as shown below. Fill up the mandatory data and click “Register” to complete registration.",
        ],
      },
      { type: "image", content: RegisterImage },
      {
        type: "label",
        id: "login",
        content: "Login",
      },
      { type: "text", content: "Login with primary account" },
      {
        type: "list",
        content: [
          "On the Login screen fill up the mandatory data and click “Login” to login to MatosSphere.",
        ],
      },
      { type: "image", content: LoginImage },
      {
        type: "label",
        id: "application",
        content: "Application",
      },
      {
        type: "list",
        content: [
          "Login to MatosSphere",
          "Use “Application -> Import”",
          "Add application name and Click “Create” to create a blank application shell to initiate setup of cloud infrastructure using existing templates from the repository.",
        ],
      },
      {
        type: "text",
        content: '<div class="ml-4 pl-3 mt-n3 mb-n2 font-weight-bold">OR</div>',
      },
      {
        type: "list",
        content: [
          [
            "Select the relevant option among “Git or YAML or JSON” and configure the existing application cloud infrastructure. Any of the above files follow a format and need to cover prerequisites for cloud account access along with relevant credentials, roles and permissions.",
            "Choose the appropriate option, fill up the mandatory data and click on “Create” to create an application.",
          ],
        ],
      },
      { type: "image", content: [App1Image, App2Image] },
      { type: "image", content: [App3Image, App4Image] },
    ],
  },
  onboarding: {
    index: "onboarding",
    hasChildren: false,
    children: [],
    data: "Onboarding",
    link: "/doc/general-setup#onboarding",
  },
  login: {
    index: "login",
    hasChildren: false,
    children: [],
    data: "Login",
    link: "/doc/general-setup#login",
  },
  application: {
    index: "application",
    hasChildren: false,
    children: [],
    data: "Application",
    link: "/doc/general-setup#application",
  },
  "feature-functionality": {
    index: "feature-functionality",
    hasChildren: true,
    children: [
      "dashboard-observe",
      "deploy-policies-and-deployments",
      "knowledgebase-remediation",
      "administration-audit-trail",
    ],
    data: "Feature & Functionality",
    link: "/doc/feature-functionality",
    next: "annexures",
    contents: [
      {
        type: "list",
        content: [
          "All plans have all features and functionalities",
          "The scope and usage terms changes as per plans",
        ],
      },
      {
        type: "label",
        id: "dashboard-observe",
        content: "Dashboard & Observe",
      },
      {
        type: "list",
        content: [
          [
            "Dashboard",
            "Consolidated view of all active applications grouped under configured environments with details of summary and current health status.",
          ],
          [
            "Observe",
            "Observe provides visibility and recommendations around applications underlying resources and health.",
            [
              "Sections",
              [
                "Observation",
                "On click of resource deep dive view of that specific resource",
              ],
              [
                "Deployments",
                "Second button triggers to deployment of new infrastructure based on associated template",
                "Third button triggers reconfiguration of existing deployment based on associated template",
                "Fourth button triggers removal of deployed infrastructure (functionality might be suppressed at account level and is only applicable to deployments done through MatosSphere)",
              ],
              [
                "Remediation",
                "List of recommended remediations are displayed with relevant details.",
                "SRE can opt to trigger remediations to rectify configuration/policy drifts and anomalies",
              ],
            ],
          ],
        ],
      },
      {
        type: "image",
        content: DashboardImage,
      },
      {
        type: "image",
        content: AppDetailImage,
      },
      {
        type: "label1",
        id: "visualization-summary",
        content: "Visualization Summary",
      },
      {
        type: "list",
        content: [
          [
            "Dashboard",
            "Current status of health",
            "Historical time series health data",
            "Summarized health and resources information",
          ],
          [
            "Application",
            ["Computed Health Factor", "Individual resources and aggregated"],
            ["Resources", "VPC, Subnets, Cluster, Nodes, VM’s etc"],
            [
              "Configurations, Policies, Compliance",
              "Firewall Rules, Policies etc",
            ],
            "Workloads and Applications",
          ],
        ],
      },
      {
        type: "label",
        id: "deploy-policies-and-deployments",
        content: "Deploy Policies and Deployments",
      },
      {
        type: "list",
        content: [
          "Use of pre-existing IaC or PaC templates from configured repositories",
          "Management of account owned policies and deployments as code",
          "IAC supports Terraform, Ansible and Helm",
          "Existing IAC templates from the configured repositories can be updated dynamically and reused based on exposed variables/parameters of the templates",
        ],
      },
      {
        type: "image",
        content: PolicyImage,
      },
      { type: "image", content: DeployImage },
      {
        type: "label1",
        id: "management-and-association",
        content: "Management and Association",
      },
      {
        type: "list",
        content: [
          [
            "Associate deployment/policy templates from selected catalogue to applications",
            "Existing template from the MatosSphere repository with a new set of parameters or variables",
            "Proprietary templates from repository with predefined parameters or variables",
          ],
        ],
      },
      { type: "image", content: AddDeployImage },
      {
        type: "label",
        id: "knowledgebase-remediation",
        content: "Knowledgebase & Remediation",
      },
      {
        type: "list",
        content: [
          [
            "Knowledgebase",
            [
              "Scope & Support",
              "Platforms",
              "IaC and PaC tools",
              "Resources",
              "Health indicators or parameters",
            ],
            ["Checklists", "Standard practices", "General compliances"],
          ],
          [
            "Remediation",
            [
              "Automated",
              "Configured at account level or an application level for different broader classifications like security, availability, scalability, governance etc.",
              "Auto remediation can be triggered based on above defined configurations provided prerequisites are fulfilled",
            ],
            "One-Click - Recommended remediation",
            "Approval Workflow - Multiple stakeholder approved recommended remediations",
          ],
        ],
      },
      {
        type: "label",
        id: "administration-audit-trail",
        content: "Administration & Audit Trail",
      },
      {
        type: "list",
        content: [
          [
            "Management",
            "Update current plan as account scales and requirements change overtime",
            [
              "Users and Roles",
              "Add, remove, enable/disable existing users and update roles",
              "Add custom roles if so needed",
              "Access control to environments and applications",
            ],
            "Environments to classify/group and support cloud infrastructure needs",
            "Update settings for account landing page, limits and thresholds, configurations for templates, environments,  applications and remediations",
          ],
          [
            "History",
            "Audit trail of activities carried out by all users and system for an account",
            "All alerts and notifications associated with account",
            "Snapshots across resource and health information grouped at environment or application level over a period of time",
          ],
        ],
      },
    ],
  },
  "dashboard-observe": {
    index: "dashboard-observe",
    hasChildren: true,
    children: ["visualization-summary"],
    data: "Dashboard & Observe",
    link: "/doc/feature-functionality#dashboard-observe",
  },
  "visualization-summary": {
    index: "visualization-summary",
    hasChildren: false,
    children: [],
    data: "Visualization Summary",
    link: "/doc/feature-functionality#visualization-summary",
  },
  "deploy-policies-and-deployments": {
    index: "deploy-policies-and-deployments",
    hasChildren: true,
    children: ["management-and-association"],
    data: "Deploy Policies and Deployments",
    link: "/doc/feature-functionality#deploy-policies-and-deployments",
  },
  "management-and-association": {
    index: "management-and-association",
    hasChildren: false,
    children: [],
    data: "Management and Association",
    link: "/doc/feature-functionality#management-and-association",
  },
  "knowledgebase-remediation": {
    index: "knowledgebase-remediation",
    hasChildren: false,
    children: [],
    data: "Knowledgebase & Remediation",
    link: "/doc/feature-functionality#knowledgebase-remediation",
  },
  "administration-audit-trail": {
    index: "administration-audit-trail",
    hasChildren: false,
    children: [],
    data: "Administration & Audit Trail",
    link: "/doc/feature-functionality#administration-audit-trail",
  },
  annexures: {
    index: "annexures",
    hasChildren: true,
    children: ["annexure1", "annexure2", "annexure3", "annexure4", "annexure5"],
    data: "Annexures",
    link: "/doc/annexures",
    contents: [
      {
        type: "label",
        id: "annexure1",
        content: "Annexure I - Overall Workflow",
      },
      { type: "image", content: WorkflowImage },
      {
        type: "label",
        id: "annexure2",
        content: "Annexure II - Glossary of Terms",
      },
      {
        type: "table",
        content: {
          header: ["Term", "Description"],
          body: [
            [
              "Environment",
              "A grouping classification to bind resources specific to different environments.\n" +
              "For e.g. dev, staging, pre-production, production etc.\n" +
              "\n" +
              "Accounts can define their own environments and each account starts with a “Default” environment.  \n" +
              "\n",
            ],
            [
              "Application",
              "Another sub level grouping classification to group relevant/related resources under one label.\n" +
              "For e.g. A GCP Project or an AWS account or a K8 cluster or an application stack of resources  etc.\n" +
              "\n" +
              "Accounts can define their own application as required.  \n" +
              "\n",
            ],
            [
              "Deploy",
              "Selected IAC/PAC template execution from the repository catalogue to setup or reconfigure resources or policy enforcements.\n",
            ],
            [
              "Observe",
              "Visualization of health and resource data for underlying resources of an application.",
            ],
            [
              "Manage",
              "General administration of account, settings, users and roles, template repository catalogues etc.",
            ],
            [
              "History",
              "Complete audit trail of all actions performed by human and non-human actors using the platform.",
            ],
            [
              "Resource",
              "Any cloud resource exposed by the supported cloud service providers.\n" +
              "For e.g. EC2, RDS on AWS, CE or Cloud SQL on GCP etc. \n" +
              "\n",
            ],
            [
              "Health",
              "Health is computed using a base algorithm that considers various virtual resource and compliance factors of underlying individual resources to deduce the aggregate score for an application.\n" +
              "\n",
            ],
            [
              "Template",
              "A collection of correlated scripts consisting of written instructions to set up, (re)configure cloud infrastructure resources and enforce policies as well as to remediate detected anomalies. \n" +
              "For e.g. Terraform, Helm, Cloud Formation templates\n" +
              "\n",
            ],
            [
              "Remediation",
              "Resolution of detected anomalies based on deep observability of underlying cloud resources health using automated tools, templates or scripts.  \n" +
              "\n",
            ],
            [
              "Observability",
              "Observability provides the health and resource details of underlying resources to compute a relevant score to depict the current state of the overall application.\n" +
              "\n",
            ],
          ],
        },
      },
      {
        type: "label",
        id: "annexure3",
        content: "Annexure III - Support & Prerequisites",
      },
      {
        type: "list",
        content: [
          [
            "Supported Cloud Services",
            "Google Cloud - GCP",
            "Amazon Web Services - AWS",
            "Microsoft - Azure",
          ],
          [
            "Prerequisites",
            [
              "To use MatosSphere a valid active account and plan is required",
              "Choose an appropriate plan based on cloud infrastructure footprint and scope",
            ],
            [
              "To use and execute IaC/PaC templates from MatosSphere repository catalogue of configured proprietary repositories ",
              [
                "Accounts with relevant roles/permissions",
                "AWS - Secret and Access keys",
                "GCP - Service Accounts",
              ],
              "Appropriate API’s should be enabled on the cloud services",
            ],
          ],
        ],
      },
      {
        type: "label",
        id: "annexure4",
        content: "Annexure IV - Form Fields",
      },
      {
        type: "table",
        title: "Onboarding",
        content: {
          header: ["Field", "Description"],
          body: [
            ["First Name", "First name of the primary account"],
            ["Last Name", "Last name of the primary account (Optional)"],
            ["Organization", "Organization Name"],
            ["E-mail Address", "E-mail address of the primary account"],
            ["Password", "Password to access primary account"],
          ],
        },
      },
      {
        type: "table",
        title: "Login",
        content: {
          header: ["Field", "Description"],
          body: [
            ["Email ID", "E-mail Address of the registered user"],
            ["Password", "Password to access the registered account"],
            [
              "Organization",
              "Organization Name of the registered user in case a user has multiple accounts (must match the Registered Organization Name)",
            ],
          ],
        },
      },
      {
        type: "table",
        title: "Application",
        content: {
          header: ["Field", "Description"],
          body: [
            [
              "Application Name",
              "Application name for the underlying cloud infrastructure resources already deployed or to be deployed",
            ],
            [
              "Input Option",
              "None - Application infrastructure to be deployed using MatosSphere IAC scripts <br/>Git / YAML / JSON - As explained in next set of rows",
            ],
            [
              "Git",
              "Application details including access keys/service accounts and associated YAML/JSON that describes infrastructure <ul><li>Git Repository Link</li><li>SSH Key - Access key to Git Repository</li><li>YAML - Yaml file name that describes infrastructure</li></ul>",
            ],
            [
              "YAML",
              "YAML file that describes infrastructure and has access keys/service accounts",
            ],
            [
              "JSON",
              "Service Account JSON file or JSON file that describes infrastructure and has access keys/service accounts",
            ],
          ],
        },
      },
      {
        type: "table",
        title: "Deployment Templates",
        content: {
          header: ["Field", "Description"],
          body: [
            ["Template", "Choose an existing template from repository"],
            [
              "Add/Update Variables",
              "Update relevant variables for the template",
            ],
            [
              "Credentials",
              "Upload a service account json or a json with access/secret keys as required",
            ],
            ["Application", "Application to which the template is associated"],
            ["Deployment Name", "Name of the template"],
            [
              "Git Repository Link",
              "SSH link to proprietary/open source git repository",
            ],
            ["Branch", "Name of the branch in git repository"],
            [
              "Sub Directory",
              "If a specific sub-directory within the repository holds the template code",
            ],
            [
              "SSH key",
              "Either has to be provided during onboarding or need to be here with relevant permissions for git repository",
            ],
            ["Template Type", "Deployment / Reconfiguration / Remediation"],
            ["Devops Tools", "Terraform / Terraform + Helm / Ansible"],
          ],
        },
      },
      { type: "label", id: "annexure5", content: "Annexure V - FAQ" },
      {
        type: "order-list",
        content: [
          "After Sign Up, where can I add more users?<br/><br/>" +
          "“Manage” can help you to add more users.",
          "Is there an additional cost for users, resources and all other quantified assets?<br/><br/>" +
          "Effective cost and numbers vary as per plans chosen for the account. Numbers exceeding those defined in plans will lead to additional costs but contact the support team for more information.",
          "Are Environments on top configurable?<br/><br/>" +
          "Yes “Default” environment pre-exists and more can be added during Application Onboarding or using “Manage”.",
          "Are there any predefined user roles?<br/><br/>" +
          "Yes, Organization Admin and User. Organization Admin can add more users and a non-admin user has access to the rest of the functionality like admin.",
          "Can the default landing page for an account be setup?<br/><br/>" +
          "Using the upcoming feature in “Manage” tab you can setup the default landing page for the application.",
          "After adding a git repository, not able to access the infrastructure details in the Observe view of application?<br/><br/>" +
          "Check the git repository format from  the upcoming templates. Once the templates are followed and the access is set the application view would be populated.",
          "After adding git repository, not able to access the infrastructure details in the deployment and remediation view of the application?<br/><br/>" +
          "Check the git repository format from  the upcoming templates. Once the templates are followed and the access is set the application view would be populated.",
        ],
      },
    ],
  },
  annexure1: {
    index: "annexure1",
    hasChildren: false,
    children: [],
    data: "Annexure I - Overall Workflow",
    link: "/doc/annexures#annexure1",
  },
  annexure2: {
    index: "annexure2",
    hasChildren: false,
    children: [],
    data: "Annexure II - Glossary of Terms",
    link: "/doc/annexures#annexure2",
  },
  annexure3: {
    index: "annexure3",
    hasChildren: false,
    children: [],
    data: "Annexure III - Support & Prerequisites",
    link: "/doc/annexures#annexure3",
  },
  annexure4: {
    index: "annexure4",
    hasChildren: false,
    children: [],
    data: "Annexure IV - Form Fields",
    link: "/doc/annexures#annexure4",
  },
  annexure5: {
    index: "annexure5",
    hasChildren: false,
    children: [],
    data: "Annexure V - FAQ",
    link: "/doc/annexures#annexure5",
  },
};

// export const calendarDays = {
//   1: "Last 30 days",
//   2: "Last 60 days",
//   3: "Last 1 quarter",
//   6: "Last half year",
// };

export const calendarDays = [
  { key: '1-week', label: 'Last 1 Week', value: 7, unit: 'days' },
  { key: "1-month", label: 'Last 1 Month', value: 1, unit: 'month' },
  { key: "3-months", label: 'Last 3 Months', value: 3, unit: 'months' },
  { key: "6-months", label: 'Last 6 Months', value: 6, unit: 'months' },
  { key: "1-year", label: 'Last 1 Year', value: 1, unit: 'year' },
];

export const policyCategories = [
  { value: "iam", label: "IAM" },
  { value: "monitor", label: "Monitoring" },
  { value: "network", label: "Networking" },
  { value: "k8s", label: "Kubernetes" },
  { value: "compute", label: "Compute" },
];

export const actionCategories = [
  { value: "Remediation", label: "Remediation" },
  { value: "Reconfiguration", label: "Reconfiguration" },
  { value: "Deployment", label: "Deployment" },
];

export const actionSubCategories = [
  { value: "Governance", label: "Governance" },
  { value: "Compliance", label: "Compliance" },
  { value: "Custom", label: "Custom" },
  { value: "Scalability", label: "Scalability" },
  { value: "Availability", label: "Availability" },
  { value: "Security", label: "Security" },
];

export const actionSeverity = [
  { value: "high", label: "High" },
  { value: "medium", label: "Medium" },
  { value: "low", label: "Low" },
  { value: "info", label: "Info" },
];

export const actionCloudSeverity = [
  { value: "critical", label: "Critical" },
  { value: "high", label: "High" },
  { value: "medium", label: "Medium" },
  { value: "low", label: "Low" },
];

export const actionIacPlatform = [
  {
    value: "terraform",
    label: "Terraform",
    logo: TerraformLogo,
    codeValue: "terraform",
    codeType: "hcl",
  },
  {
    value: "ansible",
    label: "Ansible",
    logo: AnsibleLogo,
    codeValue: "Ansible",
    codeType: "yaml",
  },
  {
    value: "kubernetes",
    label: "Kubernetes",
    logo: KubernetesLogo,
    codeValue: "Kubernetes",
    codeType: "yaml",
  },
  {
    value: "Docker",
    label: "Docker",
    logo: DockerLogo,
    codeValue: "Dockerfile",
    codeType: "docker",
  },
  {
    value: "AWS CloudFormation",
    label: "AWS CloudFormation",
    logo: AWSCloudformationLogo,
    codeValue: "CloudFormation",
    codeType: "yaml",
  },
  {
    value: "Google Deployment Manager",
    label: "Google Deployment Manager",
    logo: GoogleDeploymentLogo,
    codeValue: "GoogleDeploymentManager",
    codeType: "yaml",
  },
  {
    value: "Microsoft ARM",
    label: "Microsoft ARM",
    logo: MSArmLogo,
    codeValue: "AzureResourceManager",
    codeType: "json",
  },
  {
    value: "Helm",
    label: "Helm",
    logo: HelmLogo,
    codeValue: "Helm",
    codeType: "json",
  },
  {
    value: "Pulumi",
    label: "Pulumi",
    logo: PulumiLogo,
    codeValue: "Pulumi",
    codeType: "json",
  },
  {
    value: "Common",
    label: "Common",
    logo: KubernetesLogo,
    codeValue: "Common",
    codeType: "json",
  },
];

export const actionIacCategory = [
  { value: "Resource Management", label: "Resource Management" },
  { value: "Insecure Configurations", label: "Insecure Configurations" },
  { value: "Observability", label: "Observability" },
  { value: "Networking and Firewall", label: "Networking and Firewall" },
  { value: "Best Practices", label: "Best Practices" },
  { value: "Build Process", label: "Build Process" },
  { value: "Backup", label: "Backup" },
  { value: "Encryption", label: "Encryption" },
  { value: "Insecure Defaults", label: "Insecure Defaults" },
];

export const actionResourceType = {
  gcp: [
    { label: "GKE", value: "cluster" },
    { label: "Compute Engine", value: "instance,network,disk,snapshot" },
    { label: "GCS", value: "storage" },
    { label: "IAM", value: "serviceAccount,iam" },
    { label: "SQL", value: "sql" },
  ],
  aws: [
    { label: "Elastic Kubernetes Service", value: "cluster" },
    { label: "EC2", value: "instance,lb,snapshot,policy,disk,kms" },
    { label: "VPC", value: "network,eip" },
    { label: "EFS", value: "filesystem" },
    { label: "RDS", value: "sql" },
    { label: "CloudTrail", value: "trail" },
    { label: "Elastic Beanstalk", value: "apphosting" },
    { label: "IAM", value: "serviceAccount,analyzer,user_groups" },
    { label: "DynamoDB", value: "no_sql" },
    { label: "S3", value: "storage" },
    { label: "Lambda", value: "functions" },
    { label: "SageMaker", value: "sagemaker" },
  ],
  azure: [],
};

export const assetsCategories = [
  // { key: 'infrastructure', label: 'Infrastructure' },
  { key: 'iam', label: 'IAM' },
  { key: 'compute', label: 'Compute' },
  { key: 'containers', label: 'Containers' },
  { key: 'network', label: 'Network' },
  { key: 'storage', label: 'Storage' },
  { key: 'database', label: 'Database' },
  { key: 'serverless', label: 'Serverless' },
  // { key: 'other', label: 'Others' },
]

export const infrastructureType = {
  category: 'infrastructure',
  logo: {
    aws: (size) => <AWSLogo width={size ?? 40} height={size ?? 40} />,
    gcp: (size) => <GCPLogo width={size ?? 40} height={size ?? 40} />,
    azure: (size) => <AzureLogo width={size ?? 40} height={size ?? 40} />,
  }
}

export const providerResourceTypes = {
  aws: [
    { label: "Access Analyzer", value: "accessanalyzer", logo: icn_aws_accessanalyzer },
    { label: "Account", value: "account", logo: icn_aws_account },
    { label: "Certificate Manager", value: "acm", logo: icn_aws_acm },
    { label: "API Gateway", value: "apigateway", logo: icn_aws_apigateway },
    { label: "AppStream", value: "appstream", logo: icn_aws_appstream },
    { label: "Auto Scaling", value: "autoscaling", logo: icn_aws_ec2 },
    { label: "CloudFormation", value: "cloudformation", logo: icn_aws_cloudformation },
    { label: "CloudFront", value: "cloudfront", logo: icn_aws_cloudfront },
    { label: "CloudTrail", value: "cloudtrail", logo: icn_aws_cloudtrail },
    { label: "CloudWatch", value: "cloudwatch", logo: icn_aws_cloudwatch },
    { label: "CodeArtifact", value: "codeartifact", logo: icn_aws_codeartifact },
    { label: "CodeBuild", value: "codebuild", logo: icn_aws_codebuild },
    { label: "Config", value: "config", logo: icn_aws_config },
    { label: "Directory Service", value: "directoryservice", logo: icn_aws_directoryservice },
    { label: "DynamoDB", value: "dynamodb", logo: icn_aws_dynamodb },
    { label: "EC2", value: "ec2", logo: icn_aws_ec2 },
    { label: "ECR", value: "ecr", logo: icn_aws_ecr },
    { label: "ECS", value: "ecs", logo: icn_aws_ecs },
    { label: "EFS", value: "efs", logo: icn_aws_efs },
    { label: "EKS", value: "eks", logo: icn_aws_eks },
    { label: "ELB", value: "elb", logo: icn_aws_ec2 },
    { label: "ELBv2", value: "elbv2", logo: icn_aws_ec2 },
    { label: "EMR", value: "emr", logo: icn_aws_emr },
    { label: "Glacier", value: "glacier", logo: icn_aws_glacier },
    { label: "Glue", value: "glue", logo: icn_aws_glue },
    { label: "GuardDuty", value: "guardduty", logo: icn_aws_guardduty },
    { label: "IAM", value: "iam", logo: icn_aws_iam },
    { label: "KMS", value: "kms", logo: icn_aws_kms },
    { label: "Lambda", value: "lambda", logo: icn_aws_lambda },
    { label: "Macie", value: "macie", logo: icn_aws_macie },
    { label: "OpenSearch", value: "opensearch", logo: icn_aws_opensearch },
    { label: "RDS", value: "rds", logo: icn_aws_rds },
    { label: "Redshift", value: "redshift", logo: icn_aws_redshift },
    { label: "Route53", value: "route53", logo: icn_aws_route53 },
    { label: "S3", value: "s3", logo: icn_aws_s3 },
    { label: "SageMaker", value: "sagemaker", logo: icn_aws_sagemaker },
    { label: "Secrets Manager", value: "secretsmanager", logo: icn_aws_secretsmanager },
    { label: "Security Hub", value: "securityhub", logo: icn_aws_securityhub },
    { label: "Shield", value: "shield", logo: icn_aws_shield },
    { label: "SNS", value: "sns", logo: icn_aws_sns },
    { label: "SQS", value: "sqs", logo: icn_aws_sqs },
    { label: "SSM", value: "ssm", logo: icn_aws_ssm },
    { label: "Trusted Advisor", value: "trustedadvisor", logo: icn_aws_trustedadvisor },
    { label: "VPC", value: "vpc", logo: icn_aws_vpc },
    { label: "WorkSpaces", value: "workspaces", logo: icn_aws_workspaces },
  ],
  gcp: [
    { label: "App Engine", value: "appengine", logo: icn_gcp_appengine },
    { label: "BigQuery", value: "bigquery", logo: icn_gcp_bigquery },
    { label: "Compute Engine", value: "compute", logo: icn_gcp_compute },
    { label: "Dataproc", value: "dataproc", logo: icn_gcp_dataproc },
    { label: "DNS", value: "dns", logo: icn_gcp_dns },
    { label: "IAM", value: "iam", logo: icn_gcp_iam },
    { label: "KMS", value: "kms", logo: icn_gcp_kms },
    { label: "Kubernetes", value: "kubernetes", logo: icn_gcp_kubernetes },
    { label: "Cloud Logging", value: "logging", logo: icn_gcp_logging },
    { label: "Organization", value: "organization", logo: icn_gcp_organization },
    { label: "Project", value: "project", logo: icn_gcp_project },
    { label: "SQL", value: "sql", logo: icn_gcp_sql },
    { label: "Cloud Storage", value: "storage", logo: icn_gcp_storage },
  ],
  azure: [
    { label: "Active Directory", value: "activedirectory", logo: icn_azure_activedirectory },
    { label: "API Management", value: "apimanagement", logo: icn_azure_apimanagement },
    { label: "App Configuration", value: "appconfiguration", logo: icn_azure_appconfiguration },
    { label: "App Service", value: "appservice", logo: icn_azure_appservice },
    { label: "Automation", value: "automation", logo: icn_azure_automation },
    { label: "Batch", value: "batch", logo: icn_azure_batch },
    { label: "Cognitive Service", value: "cognitiveservice", logo: icn_azure_cognitiveservice },
    { label: "Cloud Compute", value: "compute", logo: icn_azure_compute },
    { label: "Container Registry", value: "containerregistry", logo: icn_azure_containerregistry },
    { label: "Cosmos DB", value: "cosmosdb", logo: icn_azure_cosmosdb },
    { label: "Data Box Edge", value: "databoxedge", logo: icn_azure_databoxedge },
    { label: "Data Factory", value: "datafactory", logo: icn_azure_datafactory },
    { label: "Data Lake Analytics", value: "datalakeanalytics", logo: icn_azure_datalakeanalytics },
    { label: "Data Lake Store", value: "datalakestore", logo: icn_azure_datalakestore },
    { label: "DNS", value: "dns", logo: icn_azure_dns },
    { label: "Event Grid", value: "eventgrid", logo: icn_azure_eventgrid },
    { label: "Event Hub", value: "eventhub", logo: icn_azure_eventhub },
    { label: "Front Door", value: "frontdoor", logo: icn_azure_frontdoor },
    { label: "HDInsight", value: "hdinsight", logo: icn_azure_hdinsight },
    { label: "Healthcare", value: "healthcare" },
    { label: "HPC Cache", value: "hpccache", logo: icn_azure_hpccache },
    { label: "IAM", value: "iam" },
    { label: "IoT Hub", value: "iothub", logo: icn_azure_iothub },
    { label: "Key Vault", value: "keyvault", logo: icn_azure_keyvault },
    { label: "Kubernetes", value: "kubernetes", logo: icn_azure_kubernetes },
    { label: "Kusto", value: "kusto" },
    { label: "Logic", value: "logic", logo: icn_azure_logic },
    { label: "Machine Learning", value: "machinelearning", logo: icn_azure_machinelearning },
    { label: "MariaDB", value: "mariadb", logo: icn_azure_mariadb },
    { label: "Monitor", value: "monitor", logo: icn_azure_monitor },
    { label: "MySQL", value: "mysql", logo: icn_azure_mysql },
    { label: "Network", value: "network", logo: icn_azure_network },
    { label: "Postgre", value: "postgres", logo: icn_azure_postgres },
    { label: "PostgreSQL", value: "postgresql", logo: icn_azure_postgres },
    { label: "Redis", value: "redis", logo: icn_azure_redis },
    { label: "Resource Manager", value: "resourcemanagement", logo: icn_azure_resourcemanagement },
    { label: "Cognitive Search", value: "search", logo: icn_azure_cognitiveservice },
    { label: "Security Center", value: "securitycenter", logo: icn_azure_securitycenter },
    { label: "Service Bus", value: "servicebus", logo: icn_azure_servicebus },
    { label: "Service Fabric", value: "servicefabric", logo: icn_azure_servicefabric },
    { label: "SignalR", value: "signalr", logo: icn_azure_signalr },
    { label: "Spring Cloud", value: "springcloud", logo: icn_azure_springcloud },
    { label: "SQL", value: "sql", logo: icn_azure_sql },
    { label: "Storage", value: "storage", logo: icn_azure_storage },
    { label: "Storage Sync", value: "storagesync", logo: icn_azure_storagesync },
    { label: "Stream Analytics", value: "streamanalytics", logo: icn_azure_streamanalytics },
    { label: "Synapse", value: "synapse", logo: icn_azure_synapse },
  ],
};

export const actionPlatforms = [
  { value: "all", label: "All" },
  { value: "gcp", label: "GCP" },
  { value: "aws", label: "AWS" },
  { value: "azure", label: "Azure" },
];

export const parseCSV = (data) => {
  const lines = data.split("\n");
  let headers = lines[0].split(",");
  return lines
    .filter((line, idx) => idx !== 0)
    .map((line) => {
      const items = line.split(",");
      let obj = {};
      headers.forEach((item, idx) => {
        obj[item] = items[idx];
      });
      return obj;
    });
};

export const convertBase64 = (str) => {
  return btoa(str.replace(/\r\n/g, "\n")); // for linux - LF, for Windows - CRLF
};

export const tfCredCategory = {
  aws: [
    {
      key: "terraform",
      label: "Provider Credential",
      children: [
        { label: "Access Key ID", key: "access_key", type: "text" },
        { label: "Secret Access Key", key: "secret_key", type: "text" },
        { label: "Default Region", key: "region", type: "text" },
      ],
    },
    {
      key: "backend",
      label: "Backend S3 Configuration",
      hasCheck: true,
      children: [
        {
          label: "Access Key ID",
          key: "access_key",
          type: "text",
          checkSame: true,
        },
        {
          label: "Secret Access Key",
          key: "secret_key",
          type: "text",
          checkSame: true,
        },
        {
          label: "Default Region",
          key: "region",
          type: "text",
          checkSame: true,
        },
        { label: "Bucket Name", key: "bucket_name", type: "text" },
      ],
    },
  ],
  gcp: [
    {
      key: "terraform",
      label: "Provider Credential",
      children: [{ label: "Credential", key: "credential", type: "file" }],
    },
    {
      key: "backend",
      label: "Backend GCS Configuration",
      hasCheck: true,
      children: [
        { label: "Bucket", key: "bucket", type: "text" },
        { label: "Prefix", key: "prefix", type: "text" },
        {
          label: "Credential",
          key: "credential",
          type: "file",
          checkSame: true,
        },
      ],
    },
  ],
  azure: [
    {
      key: "terraform",
      label: "Provider Credential",
      children: [
        { label: "Tenant ID", key: "tenant_id", type: "text" },
        { label: "Subscription ID", key: "subscription_id", type: "text" },
        { label: "Client ID", key: "client_id", type: "text" },
        { label: "Client Secret", key: "client_secret", type: "text" },
      ],
    },
    {
      key: "backend",
      label: "Backend Azurerm Configuration",
      hasCheck: false,
      children: [
        {
          label: "Storage Account Name",
          key: "storage_account_name",
          type: "text",
        },
        { label: "Container Name", key: "container_name", type: "text" },
        { label: "Access Key", key: "access_key", type: "text" },
      ],
    },
  ],
};

export const regionOptions = [
  { label: "us-east-1", value: "us-east-1" },
  { label: "us-east-2", value: "us-east-2" },
  { label: "us-west-1", value: "us-west-1" },
  { label: "us-west-2", value: "us-west-2" },
  { label: "ap-south-1", value: "ap-south-1" },
  { label: "ap-south-2", value: "ap-south-2" },
  { label: "ap-east-1", value: "ap-east-1" },
  { label: "ap-northeast-1", value: "ap-northeast-1" },
  { label: "ap-northeast-3", value: "ap-northeast-3" },
  { label: "ap-northeast-2", value: "ap-northeast-2" },
  { label: "ap-southeast-1", value: "ap-southeast-1" },
  { label: "ap-southeast-2", value: "ap-southeast-2" },
  { label: "ap-southeast-3", value: "ap-southeast-3" },
  { label: "ap-southeast-4", value: "ap-southeast-4" },
  { label: "ca-central-1", value: "ca-central-1" },
  { label: "eu-central-1", value: "eu-central-1" },
  { label: "eu-central-2", value: "eu-central-2" },
  { label: "eu-west-1", value: "eu-west-1" },
  { label: "eu-west-2", value: "eu-west-2" },
  { label: "eu-west-3", value: "eu-west-3" },
  { label: "eu-north-1", value: "eu-north-1" },
  { label: "eu-south-1", value: "eu-south-1" },
  { label: "eu-south-2", value: "eu-south-2" },
  { label: "sa-east-1", value: "sa-east-1" },
  { label: "me-south-1", value: "me-south-1" },
  { label: "me-central-1", value: "me-central-1" },
];

export const credentialMap = {
  aws: [
    {
      key: "credential",
      label: "AWS Account Credential",
      children: [
        { label: "Access Key ID", key: "ACCESS_KEY_ID", type: "text" },
        { label: "Secret Access Key", key: "SECRET_ACCESS_KEY", type: "text" },
        {
          label: "Default Region",
          key: "DEFAULT_REGION",
          type: "select",
          options: regionOptions,
        },
      ],
    },
  ],
  gcp: [
    {
      key: "credential",
      label: "GCP Account Credential",
      children: [{ label: "Credential", key: "credential", type: "file" }],
    },
  ],
  azure: [
    {
      key: "credential",
      label: "Azure Account Credential",
      children: [
        { label: "Tenant ID", key: "tenant_id", type: "text" },
        { label: "Subscription ID", key: "subscription_id", type: "text" },
        { label: "Client ID", key: "client_id", type: "text" },
        { label: "Client Secret", key: "client_secret", type: "text" },
      ],
    },
  ],
};

export const validTFCredData = (data, type) => {
  let valid = true;
  tfCredCategory[type].forEach((item) => {
    item.children.forEach((child) => {
      if (!data?.[item.key]?.[child.key]) {
        valid = false;
      }
    });
  });
  return valid;
};

export const remediationRunningStatus = {
  apply: "Apply",
  processing: "Processing",
  updating: "Updating",
  done: "Not Required",
};

export const remediationActionType = {
  report_only: {
    value: "report_only",
    label: "Report Only",
  },
  remediate: {
    value: "remediate",
    label: "Remediate",
  },
};

export const remediation_status_color = {
  critical: {
    color: "#59A3ED",
  },
  error: {
    color: "#61B8DF",
  },
  info: {
    color: "#6CD5C8",
  },
  ok: {
    color: "#A9B6C1",
  },
};

export const iac_status_color = {
  HIGH: { color: "#DC5F23" },
  MEDIUM: { color: "#F0963C" },
  LOW: { color: "#FACD41" },
  INFO: { color: "#5ABEFA" },
};

export const userModificationAlert = {
  allowed: {
    success: "Approved registered user successfully",
    failure: "Failed to approve registered user",
  },
  refused: {
    success: "Refused to approve registered user successfully",
    failure: "Failed to refuse to approve registered user",
  },
  activate: {
    success: "Enabled user successfully",
    failure: "Failed to enable user",
  },
  deactivate: {
    success: "Disabled user successfully",
    failure: "Failed to disable user",
  },
};

export const organizationModificationAlert = {
  active: {
    success: "Enabled organization successfully",
    failure: "Failed to enable organization",
  },
  inactive: {
    success: "Disabled organization successfully",
    failure: "Failed to disable organization",
  },
};

export const userDeleteAlert = {
  delete: {
    success: "Deleted user successfully",
    failure: "Failed to delete user",
  },
  restore: {
    success: "Restored deleted user successfully",
    failure: "Failed to restore deleted user",
  },
};

export const organizationDeleteAlert = {
  delete: {
    success: "Deleted user successfully",
    failure: "Failed to delete user",
  },
  restore: {
    success: "Restored deleted user successfully",
    failure: "Failed to restore deleted user",
  },
};

export const periodOptions = {
  annual: {
    label: "Annually",
    abbr: "mo",
    value: "annual",
  },
  monthly: {
    label: "Monthly",
    abbr: "mo",
    value: "monthly",
  },
};

export const trendPeriodOptions = {
  weekly: {
    label: "Weekly",
    abbr: "week",
    amount: 12,
    unit: "months",
  },
  monthly: {
    label: "Monthly",
    abbr: "mo",
    amount: 12,
    unit: "months",
  },
  quarterly: {
    label: "Quarterly",
    abbr: "qu",
    amount: 12,
    unit: "months",
  },
};

export const validSubscriptionStatus = () => {
  const subscription = localStorage.getItem("subscription")
    ? JSON.parse(localStorage.getItem("subscription"))
    : null;
  const validStatus = ["trialing", "active"];
  return !!validStatus.filter(
    (status) => subscription?.subscription?.status === status
  ).length;
};

export const parseSubscription = (subscription) => {
  if (!Object.keys(subscription).length) {
    return false;
  }
  const {
    trial_end,
    status,
    current_period_end,
    current_period_start,
    items,
    id,
  } = subscription;
  const plan =
    trial_end &&
      current_period_start < trial_end &&
      trial_end <= current_period_end
      ? "free"
      : "standard";
  const period =
    items.data?.[0]?.price?.recurring?.interval === "year"
      ? "annual"
      : "monthly";
  const subscription_status = ["trialing", "active"].includes(status)
    ? "Active"
    : ["incomplete", "incomplete_expired"].includes(status)
      ? "Payment Issue"
      : "Inactive";

  return {
    subscription_id: id,
    trial_end,
    plan,
    period,
    status: subscription_status,
    current_period_end,
  };
};

export const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
};

export const sideMenuOptions = [
  {
    label: "Home",
    href: `/`,
    iconType: <HomeMenuIcon fill={colors.menu_active} width={24} height={24} />,
    isLink: true,
    children: [],
  },
  {
    label: "Dashboard",
    href: `/dashboard`,
    iconType: (
      <DashboardMenuIcon fill={colors.menu_active} width={24} height={24} />
    ),
    isLink: true,
    children: [],
  },
  {
    label: "Assets",
    href: `/assets`,
    iconType: (
      <AssetsMenuIcon
        fill={colors.menu_active}
        stroke={colors.menu_active}
        width={24}
        height={24}
      />
    ),
    children: [
      { label: "Inventory", href: `/assets/inventory` },
      { label: "Graphs", href: `/assets/graphs` },
    ],
  },
  {
    label: "Security",
    href: `/security`,
    iconType: (
      <SecurityMenuIcon fill={colors.menu_active} width={24} height={24} />
    ),
    children: [
      { label: "Vulnerability", href: `/security/vulnerability` },
      { label: "IaC Compliance", href: `/security/iac` },
      { label: "Runtime Compliance", href: `/security/runtime` },
      { label: "Timelines", href: `/security/timelines` },
      { label: "Reports", href: `/security/reports` },
    ],
  },
  {
    label: "Activity",
    href: `/activity`,
    iconType: (
      <ObserveMenuIcon
        stroke={colors.menu_active}
        fill={colors.menu_active}
        width={24}
        height={24}
      />
    ),
    children: [
      { label: "Events", href: `/activity/events` },
      { label: "Notifications", href: `/activity/notifications` },
      { label: "API Logs", href: `/activity/api-logs` },
      { label: "Audit Logs", href: `/activity/audit-logs` },
    ],
  },
  {
    label: "Settings",
    href: `/settings`,
    iconType: <SettingsIcon fill={colors.menu_active} width={24} height={24} />,
    children: [
      { label: "General", href: `/settings/general` },
      { label: "Accounts", href: `/settings/accounts` },
      { label: "Cloud Infrastructure", href: `/settings/cloud-infrastructure` },
      { label: "Integrations", href: `/settings/integrations` },
      { label: "API Token", href: `/settings/api-token` },
    ],
  },
  {
    label: "Account",
    href: `/account`,
    iconType: (
      <AccountMenuIcon fill={colors.menu_active} width={24} height={24} />
    ),
    children: [
      { label: "Settings", href: `/account/settings` },
      { label: "Organization Profile", href: `/account/org-profile` },
      { label: "Billing and Payments", href: `/account/billing-payments` },
      { label: "Subscriptions", href: `/account/subscriptions` },
      { label: "Users", href: `/account/users` },
    ],
  },
];

export const dashboardOverviewList = [
  { color: "green", title: "Compliance", key: "pass", border: true },
  { color: "red", title: "Non-Compliance", key: "fail", border: true },
  { color: "orange", title: "Skipped", key: "skip", border: false },
  { color: "blue", title: "Total", key: "total", border: false },
];

export const iacDashboardSummaryList = [
  {
    color: "green",
    title: "Scanned Files",
    key: "files_scanned",
    border: true,
  },
  {
    color: "blue",
    title: "Scanned Lines",
    key: "lines_scanned",
    border: true,
  },
  {
    color: "orange",
    title: "Total Queries",
    key: "queries_total",
    border: true,
  },
  { color: "red", title: "Total Errors", key: "total_error", border: false },
];

export const NonComplianceStatusColor = {
  critical: { color: "#AA281E" },
  high: { color: "#DC5F23" },
  medium: { color: "#F0963C" },
  low: { color: "#FACD41" },
};

export const IacComplianceStatusColor = {
  high: { color: "#eb4637" },
  medium: { color: "#ff911e" },
  low: { color: "#facd05" },
  info: { color: "#32be46" },
};

export const complianceType = {
  "SOC 2": {
    label: "SOC 2",
    value: "soc2",
    logo: SOC2Logo,
  },
  "PCI DSS": {
    label: "PCI DSS",
    value: "pci_3_2_1",
    logo: PCILogo,
  },
  "NIST 800-53": {
    label: "NIST 800-53",
    value: "nist_800_53_revision",
    logo: NistLogo,
  },
  "ISO 27001": {
    label: "ISO 27001",
    value: "iso_27001",
    logo: ISOLogo,
  },
  HIPPA: {
    label: "HIPAA",
    value: "hipaa",
    logo: HIPPALogo,
  },
  GDPR: {
    label: "GDPR",
    value: "gdpr",
    logo: GDPRLogo,
  },
};

export const providerComplianceType = {
  aws: {
    "SOC 2": {
      label: "SOC 2",
      value: "soc2",
      logo: SOC2Logo,
    },
    "PCI DSS": {
      label: "PCI DSS",
      value: "pci_3_2_1",
      logo: PCILogo,
    },
    "NIST 800-53": {
      label: "NIST 800-53",
      value: "nist_800_53_revision",
      use: "nist_800_53_revision_5",
      logo: NistLogo,
    },
    HIPPA: {
      label: "HIPAA",
      value: "hipaa",
      logo: HIPPALogo,
    },
    GDPR: {
      label: "GDPR",
      value: "gdpr",
      logo: GDPRLogo,
    },
    CIS: {
      label: "CIS",
      value: "cis",
      use: "cis_1_5",
      logo: CISLogo,
    },
    "Best Practices": {
      label: "Best Practices",
      value: "aws_foundational_security_best_practices",
      logo: BestPracticeLogo,
    },
  },
  gcp: {
    "Forseti Security": {
      label: "Forseti Security",
      value: "forseti_security",
      logo: ForsetiSecurityLogo,
    },
    "CFT Scorecard": {
      label: "CFT Scorecard",
      value: "cft_scorecard",
      logo: CFTScorecardLogo,
    },
    CIS: {
      label: "CIS",
      value: "cis",
      use: "cis_2_0",
      logo: CISLogo,
    },
  },
  azure: {
    "PCI DSS": {
      label: "PCI DSS",
      value: "pci_3_2_1",
      logo: PCILogo,
    },
    "NIST 800-53": {
      label: "NIST 800-53",
      value: "nist_800_53_revision",
      use: "nist_800_53_revision_5",
      logo: NistLogo,
    },
    HIPPA: {
      label: "HIPAA",
      value: "hipaa",
      logo: HIPPALogo,
    },
    CIS: {
      label: "CIS",
      value: "cis",
      use: "cis_1_5",
      logo: CISLogo,
    },
  },
};

export const auditStatus = {
  pending: {
    label: 'In Progress',
    key: 'pending',
    color: '#e8f0fe'
  },
  inprogress: {
    label: 'In Progress',
    key: 'inprogress',
    color: '#0072ce'
  },
  completed: {
    label: 'Pass',
    key: 'completed',
    color: '#14711b'
  },
  partial_completed: {
    label: 'Issues',
    key: 'partial_completed',
    color: '#7b998d'
  },
  failed: {
    label: 'Fail',
    key: 'failed',
    color: '#b70404'
  },
  skipped: {
    label: 'Disabled',
    key: 'skipped',
    color: '#f8ab00'
  },
}


export const mockWorkloads = [
  {
      "application_id": "s8j968Tx50CCW0E351yN",
      "workload_name": "cwp-cluster-2",
      "workload_id": "yzKe8oLdfmYo2BW6UBlv",
      "organization_id": "GnRkAbDDMkNYyHmNh1WQ",
      "status": "active",
      "summary": {"CRITICAL": 3, "HIGH": 4, "MEDIUM": 2, "LOW": 10},
      "type": "snapshot_scan"
  }
];

export const mockWorkloadSummary = {
  "summary": {"CRITICAL": 3, "HIGH": 4, "MEDIUM": 2, "LOW": 10},
}

export const cloud_security_menus = [
  {
    title: 'Security Posture & Compliance',
    link: '/cspm',
    icon: <CSPMIcon />,
    img_src: CSPMImg
  },
  {
    title: 'Kubernetes Security',
    link: '/kspm',
    icon: <KSPMIcon />,
    img_src: KSPMImg 
  },
  {
    title: 'Vulnerabilities',
    link: '/cwp',
    icon: <CWPPIcon />,
    img_src: CWPPImg
  }
];

export const infrastructureRiskPoint = {
  critical: 0.5,
  high: 0.3,
  medium: 0.15,
  low: 0.05
}

export const aggregatedData = (obj, type) => {
  return Object.values(obj ?? {}).reduce(
    (acc, cur) => acc + ((type ? cur?.[type] : cur) ?? 0),
    0
  )
}

export const getSeverityColor = severity => {
  return NonComplianceStatusColor?.[String(severity).toLowerCase()]?.color
}

export const commonTitle = "MatosSphere"

export const topPriorityTabs = [
  { key: 'attack_paths', label: 'Attack Paths' },
  { key: 'infras', label: 'Infrastructures' },
  { key: 'compliances', label: 'Compliances' },
];

export const pentestingTabs = [
  { key: 'dashboard', label: "Dashboard" },
  { key: 'workspaces', label: "Workspaces" },
  { key: 'trends', label: "Trends" },
  { key: 'reports', label: "Reports" },
]

export const securityTabs = [
  { key: 'infras', label: 'Infrastructures' },
  { key: 'cloud_security', label: 'Cloud Security' },
  { key: 'k8s_security', label: 'K8s Security' },
  { key: 'vulnerabilities', label: 'Vulnerabilities' },
];

export const topRisksbyCategoryTabs = [
  { key: 'identity_access', label: 'Identity and Access' },
  { key: 'containers', label: 'Containers' },
  { key: 'clusters', label: 'Clusters' },
  { key: 'data_security', label: 'Data Security' },
  { key: 'public_exposure', label: 'Public Exposure' },
  { key: 'software_update', label: 'Software Update' },
];

export const priorityColumns = [
  {
    title: 'Name',
    access: 'name',
    width: "20%",
    sort: true,
    search: false,
    type: 'text'
  },
  {
    title: 'Results',
    access: 'summary',
    width: "40%",
    sort: false,
    search: false,
    type: 'object'
  },
  {
    title: 'Compliance Score',
    access: 'score',
    width: "15%",
    sort: true,
    search: false,
    type: 'text'
  },
]

export const columns = {
  top_priority: {
    attack_paths: [
      {
        title: 'Name',
        access: 'name',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Infrastructure',
        access: 'app_name',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Resource Type',
        access: 'resource_type',
        width: "15%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Path Severity',
        access: 'severity',
        width: "10%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Vulnerabilities',
        access: 'vulnerabilities',
        width: "10%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Secrets',
        access: 'secrets',
        width: "10%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Compliance',
        access: 'compliance',
        width: "10%",
        sort: true,
        search: false,
        type: 'text'
      },
    ],
    infras: [
      {
        title: 'Name',
        access: 'app_name',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Cloud Provider',
        access: 'provider',
        width: "15%",
        sort: true,
        search: false,
        type: 'icon',
        func: (provider = 'aws', size = 30) => {
          return infrastructureType.logo[provider](size)
        }
      },
      {
        title: 'Environment',
        access: 'environment',
        width: "10%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Severity',
        access: 'summary',
        width: "30%",
        sort: false,
        search: false,
        type: 'object'
      },
      {
        title: 'Compliance',
        access: 'compliance',
        width: "10%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Vulnerabilities',
        access: 'vulnerabilities',
        width: "15%",
        sort: true,
        search: false,
        type: 'text'
      },
    ],
    compliances: [
      {
        title: 'Name',
        access: 'app_name',
        width: "30%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Compliance Type',
        access: 'compliance_type',
        width: "10%",
        sort: true,
        search: false,
        type: 'img',
        func: (type) => {
          const compliance = Object.values(providerComplianceType).filter(comTypes => comTypes[type])?.[0]?.[type]
          return compliance?.['logo']
        }
      },
      {
        title: 'Severity',
        access: 'summary',
        width: "40%",
        sort: false,
        search: false,
        type: 'object'
      },
      {
        title: 'Compliance score',
        access: 'compliance',
        width: "10%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Vulnerabilities',
        access: 'vulnerabilities',
        width: "10%",
        sort: true,
        search: false,
        type: 'text'
      },
    ]
  },
  top_risks_by_category: {
    identity_access: [
      {
        title: 'Name',
        access: 'name',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Severity',
        access: 'severity',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Issues',
        access: 'issues',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Infrastructure',
        access: 'infrastructure',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      },
    ],
    containers: [
      {
        title: 'Name',
        access: 'name',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Issues',
        access: 'issues',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Severity',
        access: 'severity',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      }
    ],
    clusters: [
      {
        title: 'Name',
        access: 'name',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Issues',
        access: 'issues',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Severity',
        access: 'severity',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      }
    ],
    data_security: [
      {
        title: 'Name',
        access: 'name',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Issues',
        access: 'issues',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Severity',
        access: 'severity',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      }
    ],
    public_exposure: [
      {
        title: 'Name',
        access: 'name',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Issues',
        access: 'issues',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Severity',
        access: 'severity',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      }
    ],
    software_update: [
      {
        title: 'Name',
        access: 'name',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Issues',
        access: 'issues',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      },
      {
        title: 'Severity',
        access: 'severity',
        width: "25%",
        sort: true,
        search: false,
        type: 'text'
      }
    ]
  },
  pentesting: {
    workspaces: [
      {
        title: 'Name',
        access: 'name',
        width: "30%",
        sort: true,
        search: true,
        type: 'text'
      },
      {
        title: 'Targets',
        access: 'target',
        width: "40%",
        sort: false,
        search: true,
        type: 'array'
      },
      {
        title: 'Status',
        access: 'status',
        width: "30%",
        sort: false,
        search: true,
        type: 'text'
      },
      {
        title: 'Actions',
        access: '',
        width: '10%',
        type: 'action',
        items: ['update', 'delete']
      }
    ],
    reports: [
      {
        title: 'Date',
        access: 'name',
        width: "40%",
        sort: true,
        search: true,
        type: 'date'
      },
      {
        title: 'Summary',
        access: 'targets',
        width: "40%",
        sort: false,
        search: true,
        type: 'text'
      },
      {
        title: 'Download',
        access: '',
        width: "20%",
        sort: false,
        search: false,
        type: 'button',
        action: () => {}
      },
    ]
  }
}

export const dummyPriorIssuesData = {
  topPriority: {
    attack_paths: [
      { id: 0, name: 'Path 01', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 1, name: 'Path 02', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 2, name: 'Path 03', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 3, name: 'Path 04', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
    ],
    infras: [
      { id: 0, name: 'Infra 01', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 1, name: 'Infra 02', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 2, name: 'Infra 03', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 3, name: 'Infra 04', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 4, name: 'Infra 05', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 5, name: 'Infra 06', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
    ],
    compliances: [
      { id: 0, name: 'Compliance 01', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 1, name: 'Compliance 02', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 2, name: 'Compliance 03', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 3, name: 'Compliance 04', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 4, name: 'Compliance 05', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
    ],
  },
  topRisks: {
    identity_access: [
      { id: 0, name: 'Identity and Access 01', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 1, name: 'Identity and Access 02', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 2, name: 'Identity and Access 03', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 3, name: 'Identity and Access 04', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 4, name: 'Identity and Access 05', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
    ],
    containers: [
      { id: 0, name: 'Containers 01', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 1, name: 'Containers 02', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 2, name: 'Containers 03', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 3, name: 'Containers 04', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 4, name: 'Containers 05', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 5, name: 'Containers 06', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
    ],
    clusters: [
      { id: 0, name: 'Clusters 01', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 1, name: 'Clusters 02', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 2, name: 'Clusters 03', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 3, name: 'Clusters 04', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 4, name: 'Clusters 05', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
    ],
    data_security: [
      { id: 0, name: 'Data Security 01', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 1, name: 'Data Security 02', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 2, name: 'Data Security 03', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 3, name: 'Data Security 04', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
    ],
    public_exposure: [
      { id: 0, name: 'Public Exposure 01', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 1, name: 'Public Exposure 02', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 2, name: 'Public Exposure 03', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 3, name: 'Public Exposure 04', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 4, name: 'Public Exposure 05', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 5, name: 'Public Exposure 06', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
    ],
    software_update: [
      { id: 0, name: 'Software Update 01', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 1, name: 'Software Update 02', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 2, name: 'Software Update 03', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 3, name: 'Software Update 04', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      { id: 4, name: 'Software Update 05', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
    ],
  }
}

export const dummyInfraPreviewData = {
  infras: [
    { 
      // title: 'Top 10 Infrastructures', 
      data: [
        { id: 0, name: 'Infrastructure 01', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
        { id: 1, name: 'Infrastructure 02', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
        { id: 2, name: 'Infrastructure 03', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
        { id: 3, name: 'Infrastructure 04', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
        { id: 4, name: 'Infrastructure 05', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      ] 
    },
    // {
    //   title: "Top 10 Attack Paths",
    //   data: [
    //     { id: 0, name: 'Attack Paths 01', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
    //     { id: 1, name: 'Attack Paths 02', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
    //     { id: 2, name: 'Attack Paths 03', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
    //     { id: 3, name: 'Attack Paths 04', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
    //     { id: 4, name: 'Attack Paths 05', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
    //   ]
    // }
  ],
  cloud_security: [
    {
      // title: "Cloud Security",
      data: [
        { id: 0, name: 'Cloud Security 01', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
        { id: 1, name: 'Cloud Security 02', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
        { id: 2, name: 'Cloud Security 03', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
        { id: 3, name: 'Cloud Security 04', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
        { id: 4, name: 'Cloud Security 05', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      ]
    }
  ],
  k8s_security: [
    {
      // title: "K8s Security",
      data: [
        { id: 0, name: 'K8s Security 01', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
        { id: 1, name: 'K8s Security 02', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
        { id: 2, name: 'K8s Security 03', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
        { id: 3, name: 'K8s Security 04', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
        { id: 4, name: 'K8s Security 05', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      ]
    }
  ],
  vulnerabilities: [
    {
      // title: "Vulnerabilities",
      data: [
        { id: 0, name: 'Vulnerabilities 01', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
        { id: 1, name: 'Vulnerabilities 02', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
        { id: 2, name: 'Vulnerabilities 03', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
        { id: 3, name: 'Vulnerabilities 04', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
        { id: 4, name: 'Vulnerabilities 05', summary: { critical: 5, high: 24, medium: 3, low: 10 }, score: 40 },
      ]
    }
  ],
}

export const reportTypes = [
  {
    key: 'infra',
    label: 'CSPM',
    item: 'infrastructure'
  },
  {
    key: 'kspm',
    label: 'KSPM',
    item: 'cluster'
  },
  {
    key: 'cwpp',
    label: 'CWPP',
    item: 'workload'
  },
  {
    key: 'iac',
    label: 'IaC Scan',
    item: 'IaC project'
  },
]


// export const enableV2Auth = false;
export const enableV2Auth = process.env.REACT_APP_ENABLE_V2_AUTH;