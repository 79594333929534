import React, { useEffect, useState } from 'react'

import {useHistory, useLocation} from 'react-router-dom'
import Header from './layout/Header';
import Sidebar from './layout/Sidebar';
import {authAction} from "../../redux/actions";
import {connect} from "react-redux";
import styled from 'styled-components'

const MainContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
`

const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  flex: 1;
  .content {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    transition: width 0.1s 0.5s;
  }
`

const GoTopButton = styled.div`
  padding: 10px 15px; 
  position: fixed;
  bottom: 20px;
  right: 30px;
  //width: 20px;
  //height: 20px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #65C2D7;
  cursor: pointer;
  &:hover {
    background-color: #f6f6f6;
  }
`


const MainLayout = (props) => {
  const {
    expired_token, new_refresh_token, getRefreshToken,
    defused_refresh_token, clearDefuseRefreshToken
  } = props

  const history = useHistory()
  const [scrollY, setScrollY] = useState(window.scrollY)
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollY(position);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  useEffect(() => {
    if(expired_token && !new_refresh_token) {
      getRefreshToken()
    }
  }, [expired_token, new_refresh_token])

  useEffect(() => {
    if(defused_refresh_token) {
      clearDefuseRefreshToken()
      localStorage.removeItem('user_token')
      history.push('/login')
    }
  }, [defused_refresh_token])

  return (
      <MainContainer>
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <BodyContainer>
          <Sidebar sidebarOpen={sidebarOpen} />
          <div className="content">
            {props.children}
          </div>
        </BodyContainer>
      </MainContainer>
  )
}

function mapStateToProps(state) {
  const {expired_token, new_refresh_token, defused_refresh_token} = state.auth;
  return {
    expired_token,
    new_refresh_token,
    defused_refresh_token,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getRefreshToken: () => dispatch(authAction.getRefreshToken()),
    clearDefuseRefreshToken: () => dispatch(authAction.clearDefuseRefreshToken()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
