import { Graph } from "react-d3-graph";
import { config1 as graphConfig } from "../graph-configs";

const Sample03 = (props) => {
    // graph payload (with minimalist structure)
    const graphData = {
        nodes: [
            { id: 1, name: "Any IP", x: 300, y: 250, svg: './images/AnyIP.png' },
            { id: 2, name: "Security Group", x: 450, y: 200, svg: './images/SecurityGroup.png' },
            { id: 3, name: "Network Interface", x: 600, y: 250, svg: './images/NetworkInterface2.png' }
        ],
        links: [
            { source: 1, target: 2 },
            { source: 2, target: 3 }
        ]
    };

    const onClickNode = function (nodeId) {
        console.log(`Clicked node ${nodeId}`);
    };

    const onClickLink = function (source, target) {
        console.log(`Clicked link between ${source} and ${target}`);
    };

    let myConfig = graphConfig;
    myConfig.staticGraph = props.settings.staticGraph;
    myConfig.panAndZoom = props.settings.panAndZoom;
    myConfig.collapsible = props.settings.collapsible;
    myConfig.directed = props.settings.directedGraph;

    return (
        <>
            Cloud Security: <em>Security Group</em>
            <div class="box">
                <Graph
                    id="graph-03" // id is mandatory
                    data={graphData}
                    config={myConfig}
                    onClickNode={onClickNode}
                    onClickLink={onClickLink}
                />
            </div>
        </>
    );
}

export default Sample03;