import { DropdownToggle } from "reactstrap";
import styled from "styled-components";

const DropdownIcon = styled(DropdownToggle)`
  padding: 0;
  //padding: ${(props) => (props.padding ? props.padding : "0.5vh 0.7vw")};
  background: ${(props) => props.bg ?? "#fff"};
  border-radius: 0;
  border: 0 solid #1b1b1b;

  &:hover,
  &:focus,
  &:active {
    // background: ${(props) => props.bg ?? "#fff"}!important;
    //background: rgba(18,19,20,0.6);
    border: 0 solid #fff;
    box-shadow: none;
  }
  &:active {
    background: transparent !important;
    border: none;
    box-shadow: none;
  }
`;

export default DropdownIcon;
