import React, { forwardRef } from "react";
import styled from "styled-components";
import ClusterTable from "./ClusterTable";

const MainBodyWrapper = styled.div`
  padding: 10px;
`;

const ClusterDetailsTab = forwardRef((props, ref) => {
  const {
    data, dataLoading, selectedCluster,
    setSelectedFilterType,
    selectedFilterType,
    preFilterData,
    setRenderFlag,
  } = props;

  return (
    <MainBodyWrapper>
      <ClusterTable
        ref={ref}
        data={data}
        dataLoading={dataLoading}
        clusterId={selectedCluster?.cluster_id}
        selectedCluster={selectedCluster}
        selectedFilterType={selectedFilterType}
        setSelectedFilterType={setSelectedFilterType}
        preFilterData={preFilterData}
        setRenderFlag={setRenderFlag}
      />
    </MainBodyWrapper>
  );
});

export default ClusterDetailsTab;
