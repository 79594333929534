import React from "react";
import Page from "../../components/PageLayout/Page";
import WorkloadListLayout from "./cwp/WorkloadListLayout";

const CWP = (props) => {
  return (
    <div>
      <Page noUpperCta>
        <WorkloadListLayout />
      </Page>
    </div>
  );
};

export default CWP;
