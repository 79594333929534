/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import StyledTypo from "../../../../components/Typo/StyledTypo";
import styled from "styled-components";
import { ReactComponent as ArrowUp } from "../../../../assets/icons/up_arrow_icon.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/icons/down_arrow_icon.svg";
import ReactPaginate from "react-paginate";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filter_icon.svg";
import { ReactComponent as FilterClearIcon } from "../../../../assets/icons/filter_clear_icon.svg";
import { ReactComponent as UpArrow } from "../../../../assets/icons/arrow-angle-up-sm.svg";
import { ReactComponent as RightArrow } from "../../../../assets/icons/arrow-angle-right-sm.svg";
import { ReactComponent as ShowAllItems } from "../../../../assets/icons/list-show-all.svg";
import { Badge } from "reactstrap";
import StyledCheckbox from "../../../../components/Input/StyledCheckbox";
import CustomInput from "../../../../components/Input/CustomInput";
import CommonSelect from "../../../../components/Select/CommonSelect";
import {
  loadingBar,
  remediationActionType,
  remediationRunningStatus,
  actionCloudSeverity,
  getSeverityColor
} from "../../../../helpers/constants";
import { connect } from "react-redux";
import CommonInput from "../../../../components/Input/CommonInput";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close-icon.svg";

import WorkloadDetailSlide from "./WorkloadDetailSlide";
import { TableWrapper } from "../../../../components/PageLayout/Table";
import {
  FilterWrapper,
  FilterBodyContainer,
} from "../../../../components/PageLayout/Filter";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 26px;
`;

const EachCell = styled.div`
  width: ${(props) => props.width};
  padding-right: ${(props) => (props.pr ? props.pr : 0)}px;
  word-break: break-word;
`;

const RecordContainer = styled.div`
  border-top: 1px solid #fff;
  transition: border 500ms ease;
`;

const RecordWrapper = styled.div`
  padding: 11px 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .text-cell {
    color: #505050;
  }
  cursor: pointer;
  &:hover {
    border-radius: 5px;
    background-color: #f1f1f1;
  }
`;

const ShowingWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  position: fixed;
  right: 40px;
  bottom: 20px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100vw - 200px);
  background-color: #fff;

  .page-link:focus {
    box-shadow: none !important;
  }
  ul.pagination {
    margin-bottom: 0;
    font-size: 0.8rem;
    font-weight: 0.4;
    font-family: "Roboto";
  }
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const IconListWrapper = styled.div`
  display: flex;
  column-gap: 7px;
  flex-wrap: wrap;
`;

const selectEmptyResourceType = { label: "Select...", value: "" };

const FilterPanel = (props) => {
  const {
    searchText,
    setSearchText,
    searchResource,
    setSearchResource,
    selectedResourceType,
    setSelectedResourceType,
    selectedCompliance,
    setSelectedCompliance,
    selectedSeverity,
    setSelectedSeverity,
    selectedFilterType,
    setSelectedFilterType,
    showAll,
    setShowAll,
    preFilterData,
  } = props;

  const [filterBarOpen, setFilterBarOpen] = useState(false);

  const clearFilter = () => {
    setSearchResource("");
    setSelectedSeverity(selectEmptyResourceType);
    setFilterBarOpen(false);
  };
  useEffect(() => {
    if (selectedFilterType) {
      setSelectedFilterType(null);
      setFilterBarOpen(true);
    }
  }, [selectedFilterType, setSelectedFilterType]);

  const checkFilterContent = () => {
    return (
      !!searchResource.length ||
      !!selectedSeverity.value.length
    );
  };

  return (
    <FilterWrapper left="575px">
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="d-flex align-items-center mr-3 filter-menu">
          <a
            className="menu-icon"
            onClick={() => setFilterBarOpen(!filterBarOpen)}
            title="Filter By"
            alt="Filter By"
            href="#"
          >
            <FilterIcon
              width={22}
              height={22}
              fill="#606060"
              className="svg-icon"
            />
            <span className="menu-tip">Filter by</span>
          </a>
          <a
            className={
              checkFilterContent() ? "menu-icon" : "menu-icon menu-disabled"
            }
            onClick={clearFilter}
            title="Filter Clear"
            alt="Filter Clear"
            href="#"
          >
            <FilterClearIcon
              width={22}
              height={22}
              fill="#606060"
              className="svg-icon"
            />
            <span className="menu-tip">Filter clear</span>
          </a>
        </div>
        <div>
          <CustomInput
            minWidth={250}
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>
      {filterBarOpen && (
        <FilterBodyContainer>
          <CloseWrapper
            className="cursor-pointer"
            onClick={() => setFilterBarOpen(false)}
          >
            <CloseIcon width={15} stroke="#A4AFB7" />
          </CloseWrapper>
          <div className="ml-3" style={{ minWidth: 300 }}>
            <CommonInput
              label={"Title"}
              value={searchResource}
              setValue={(val) => setSearchResource(val)}
              active={false}
              locked={false}
              placeholder=""
            />
          </div>
          <div className="ml-3" style={{ minWidth: 150 }}>
            <CommonSelect
              value={selectedSeverity}
              setValue={setSelectedSeverity}
              options={[selectEmptyResourceType, ...actionCloudSeverity]}
              label={`Severity`}
            />
          </div>
        </FilterBodyContainer>
      )}
    </FilterWrapper>
  );
};

const TableHeader = (props) => {
  const { columns, currentSort, setCurrentSort } = props;

  const handleSetSort = (access) => {
    setCurrentSort({
      access,
      order:
        (Array.isArray(currentSort.access)
          ? currentSort.access.join()
          : currentSort.access) ===
        (Array.isArray(access) ? access.join() : access)
          ? !currentSort.order
          : true,
    });
  };

  return (
    <HeaderWrapper>
      {columns &&
        columns.map((column) => (
          <EachCell
            width={column.width ?? "100%"}
            key={column.access}
            className={` ${
              column.title === "Results" ? "flex-column align-items-start" : ""
            }d-flex align-items-center ${column.sort ? "cursor-pointer" : ""} ${
              column.title === "Action" ? "justify-content-end" : ""
            } `}
            pr={10}
            onClick={() => {
              if (column.sort) {
                handleSetSort(column.access);
              }
            }}
          >
            {column.sort &&
            (Array.isArray(currentSort.access)
              ? currentSort.access.join()
              : currentSort.access) ===
              (Array.isArray(column.access)
                ? column.access.join()
                : column.access) ? (
              currentSort.order ? (
                <ArrowUp fill={"#A4AFB7"} className="mr-3" />
              ) : (
                <ArrowDown fill={"#A4AFB7"} className="mr-3" />
              )
            ) : (
              <div />
            )}
            <div
              className={
                column.title === "Action"
                  ? "ml-auto"
                  : column.type === "overview"
                  ? "mr-auto"
                  : ""
              }
            >
              <StyledTypo
                color={"#505050"}
                size={0.8}
                line={1.2}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                {column.title}
              </StyledTypo>
            </div>
          </EachCell>
        ))}
    </HeaderWrapper>
  );
};

const Record = (props) => {
  const { data, columns, openItemId, setOpenItemId } = props;

  const handleSelectRow = () => {
    handleOpenDetail();
  };

  const handleOpenDetail = () => {
    setOpenItemId(data.id);
  };

  const removeSpace = (data) => {
    return data
      ? data
          .split(", ")
          .filter((item) => item || item !== "")
          .join(", ")
      : data;
  };

  return (
    <div>
      <RecordContainer active={data.id === openItemId}>
        <RecordWrapper
          active={data.id === openItemId}
          onClick={handleOpenDetail}
        >
          {columns.map((column) => (
            <EachCell width={column.width ?? "100%"} pr={10}>
              {column.type === "checkbox" && (
                <StyledCheckbox
                  handleOnChange={handleSelectRow}
                  checked={data.checked}
                  className="mt-2"
                />
              )}
              {column.type === "arrow" && (
                <div className="cursor-pointer">
                  {openItemId === data?.id ? (
                    <UpArrow fill="#505050" />
                  ) : (
                    <RightArrow fill="#505050" />
                  )}
                </div>
              )}
              {column.type === 'severity' && (
                <StyledTypo className = 'severity-text'>
                  <Badge
                    color={data?.[column.access]?.toLowerCase() ?? "low"}
                    pill
                  >
                    {data?.[column.access]?.toLowerCase()}
                  </Badge>
                </StyledTypo>
              )}
              {column.type === "overview" && (
                <StyledTypo>
                  {
                    data.templates.filter(
                      (res) =>
                        res.status.toLowerCase() === "fail" ||
                        res.status.toLowerCase() === "info"
                    ).length
                  }
                  {" / "}
                  {data.templates.filter(
                    (res) => res.status.toLowerCase() === "pass"
                  ).length +
                    data.templates.filter(
                      (res) => res.status.toLowerCase() === "fail"
                    ).length +
                    data.templates.filter(
                      (res) => res.status.toLowerCase() === "info"
                    ).length}
                </StyledTypo>
              )}
              {column.type === "button" &&
                (data.active === remediationRunningStatus.apply ? (
                  data.remediation_action ===
                  remediationActionType.report_only.value ? (
                    <StyledTypo
                      className="d-flex justify-content-end table-label-1 label"
                      color={"#485DCD"}
                    >
                      {remediationActionType.report_only.label.toUpperCase()}
                    </StyledTypo>
                  ) : (
                    <div className="d-flex justify-content-end"></div>
                  )
                ) : (
                  <StyledTypo
                    className="d-flex justify-content-end table-label-1"
                    color={
                      data.active === remediationRunningStatus.updating
                        ? "coral"
                        : data.active === remediationRunningStatus.processing
                        ? "#485DCD"
                        : "#505050"
                    }
                  >
                    {data?.active?.toUpperCase()}
                  </StyledTypo>
                ))}
              {column.type !== "type" && column.type !== "compliance" && column.type !== 'severity' && (
                <StyledTypo
                  className="text-cell table-label-1"
                  onClick={handleSelectRow}
                >
                  {Array.isArray(column.access)
                    ? column.access.map((access) => data[access]).join(" / ")
                    : removeSpace(data[column.access])}
                </StyledTypo>
              )}
            </EachCell>
          ))}
        </RecordWrapper>
      </RecordContainer>
    </div>
  );
};

const WorkloadTable = forwardRef((props, ref) => {
  const {
    data: workloadDetail,
    workloadId,
    workloadDetailLoading,
    selectedFilterType,
    setSelectedFilterType,
    preFilterData,
    setRenderFlag,
  } = props;

  const columns = [
    {
      title: "CVE ID",
      access: "VulnerabilityID",
      width: `20%`,
      sort: true,
      search: true,
    },
    {
      title: "Title",
      access: "Title",
      width: `50%`,
      sort: true,
      search: true,
    },
    {
      title: "PackageName",
      access: "PkgName",
      width: `30%`,
      sort: true,
      search: true,
    },
    {
      title: "Severity",
      access: "Severity",
      width: `20%`,
      sort: false,
      search: false,
      type: 'severity'
    },
    // {
    //   title: "CVSS",
    //   access: "compliance",
    //   width: `35%`,
    //   sort: false,
    //   search: false,
    //   type: "compliance",
    // },
  ];

  
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({ access: "", order: true });
  const [filters, setFilters] = useState(columns.filter((item) => item.search));
  const [searchText, setSearchText] = useState(null);
  
  const [selectedResourceType, setSelectedResourceType] = useState(
    selectEmptyResourceType
  );
  const [selectedCompliance, setSelectedCompliance] = useState(
    selectEmptyResourceType
    );
  const [selectedSeverity, setSelectedSeverity] = useState(
    selectEmptyResourceType
    );
    
  const [searchResource, setSearchResource] = useState("");
  const [openItemId, setOpenItemId] = useState(null);
  const [showAll, setShowAll] = useState(false);
  
  const [selectedData, setSelectedData] = useState({});

  const itemPerPage = 15;
  const handleSort = () => {
    const retOrder = currentSort.order ? 1 : -1;
    setData((data) => [
      ...data.sort((a, b) => {
        const aCompare = Array.isArray(currentSort.access)
          ? currentSort.access.map((access) => a[access]).join(" ")
          : a[currentSort.access];
        const bCompare = Array.isArray(currentSort.access)
          ? currentSort.access.map((access) => b[access]).join(" ")
          : b[currentSort.access];
        return aCompare > bCompare ? retOrder : -1 * retOrder;
      }),
    ]);
  };

  useEffect(() => {
    setCurrentSort((currentSort) =>
      currentSort.access.length === 0
        ? {
            access: columns[0].access,
            order: true,
          }
        : { ...currentSort }
    );
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (selectedFilterType) {
      setSelectedResourceType({ ...selectedFilterType });
    }
  }, [selectedFilterType]);

  useEffect(() => {
    if (preFilterData?.type === "severity") {
      setSelectedSeverity({
        label: preFilterData?.data,
        value: preFilterData?.data.toLowerCase(),
      });
    }
  }, [preFilterData]);

  useEffect(() => {
    const vulnerabilities = workloadDetail.result.results.map(item => item.Vulnerabilities).flat();
    const updatedVulners = vulnerabilities.map((obj, index) => ({
      ...obj,
      id: `${obj.VulnerabilityID}-${index}`
    }));
    setData([...updatedVulners]);
  }, [workloadDetail]);

  useEffect(() => {
    handleSort();
  }, [currentSort]);

  const filteredData = useCallback(() => {
    let tempFilteredData = [...data];
    if (searchText && searchText.length) {
      tempFilteredData = [
        ...tempFilteredData.filter(
          (item) =>
            !!filters.filter(
              (col) =>
                col.search &&
                item[col.access]
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
            ).length
        ),
      ];
    }

    if (searchResource && searchResource.length) {
      tempFilteredData = [
        ...tempFilteredData.filter(
          (item) =>
            item.Title.toLowerCase().includes(searchResource.toLowerCase())
        ),
      ];
    }
    if (selectedSeverity && selectedSeverity.value.length) {
      tempFilteredData = [
        ...tempFilteredData.filter(
          (item) =>
            item.Severity?.toUpperCase() ===
            selectedSeverity.value.toUpperCase()
        ),
      ];
    }
    if (
      currentPage !== 1 &&
      currentPage > tempFilteredData.length / itemPerPage + 1
    ) {
      setCurrentPage(1);
    }
    return tempFilteredData;
  }, [
    data,
    searchText,
    filters,
    currentPage,
    showAll,
    searchResource,
    selectedSeverity,
  ]);

  useEffect(() => {
    setRenderFlag((renderFlag) => !renderFlag);
  }, [
    searchText,
    searchResource,
    selectedResourceType,
    selectedCompliance,
    selectedSeverity,
  ]);

  const pageFilter = (data) => {
    return data.filter(
      (item, idx) =>
        idx >= itemPerPage * (currentPage - 1) &&
        idx < itemPerPage * currentPage
    );
  };

  const handlePageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };

  useEffect(() => {
    setSelectedData(
      filteredData()?.filter((item) => item.id === openItemId)?.[0] ?? {}
    );
  }, [openItemId]);

  const findIndex = () => {
    return filteredData()?.findIndex((project) => project.id === openItemId);
  };

  const findId = (up = true) => {
    const idx = findIndex();
    const targetIdx = up ? idx + 1 : idx - 1;
    return filteredData()?.filter((_, idx) => idx === targetIdx)?.[0]?.id;
  };

  useImperativeHandle(ref, () => ({
    getFilterPanel: () => (
      <FilterPanel
        columns={columns}
        filters={filters}
        setFilters={setFilters}
        searchText={searchText}
        setSearchText={setSearchText}
        searchResource={searchResource}
        setSearchResource={setSearchResource}
        selectedResourceType={selectedResourceType}
        setSelectedResourceType={setSelectedResourceType}
        selectedCompliance={selectedCompliance}
        setSelectedCompliance={setSelectedCompliance}
        selectedSeverity={selectedSeverity}
        setSelectedSeverity={setSelectedSeverity}
        selectedFilterType={selectedFilterType}
        setSelectedFilterType={setSelectedFilterType}
        showAll={showAll}
        setShowAll={setShowAll}
        preFilterData={preFilterData}
      />
    ),
  }));

  return (
    <TableWrapper marginTop="10px">
      <TableHeader
        columns={columns}
        currentSort={currentSort}
        setCurrentSort={setCurrentSort}
      />
      <div className="divider" />
      {(workloadDetail.result.results || !workloadDetailLoading) ? (
        filteredData().length ? (
          pageFilter(filteredData()).map((vulnerability) => (
            <Record
              data={vulnerability}
              columns={columns}
              workloadId={workloadId}
              openItemId={openItemId}
              force={currentPage - 1}
              setOpenItemId={setOpenItemId}
            />
          ))
        ) : (
          <StyledTypo className="text-center py-5">
            There are no items to show. Please check the filter or search.
          </StyledTypo>
        )
      ) : (
        <div className="d-flex justify-content-center py-5 align-items-center">
          {loadingBar("Rings", 50, 50)}
          <StyledTypo className="pl-2">Loading...</StyledTypo>
        </div>
      )}
      <ShowingWrapper>
        <StyledTypo className="pagination-label">
          {`Showing ${
            itemPerPage * (currentPage - 1) + 1 > filteredData().length
              ? filteredData().length
              : itemPerPage * (currentPage - 1) + 1
          } - ${
            itemPerPage * currentPage > filteredData().length
              ? filteredData().length
              : itemPerPage * currentPage
          } from ${filteredData().length} Results`}
        </StyledTypo>
        <ReactPaginate
          pageCount={(filteredData()?.length ?? 0) / itemPerPage}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          previousLabel="< Prev"
          nextLabel="Next >"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          forcePage={currentPage - 1}
          onPageChange={handlePageChange}
          activeClassName="active"
        />
      </ShowingWrapper>
      <WorkloadDetailSlide
        data={selectedData}
        isOpen={!!openItemId}
        openItemId={openItemId}
        toggle={() =>
          setOpenItemId((openItemId) => (openItemId ? null : openItemId))
        }
        setOpenItemId={setOpenItemId}
        findIndex={findIndex}
        findId={findId}
        projectsCnt={filteredData().length}
      />
    </TableWrapper>
  );
});

function mapStateToProps(state) {
  const {
    workloadDetailLoading,
  } = state.applications;
  return {
    workloadDetailLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(WorkloadTable);
