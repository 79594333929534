import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Badge } from "reactstrap";
import StyledTypo from "../../components/Typo/StyledTypo";
import moment from "moment";
import EffectedButton from "../../components/Buttons/EffectedButton";
import {
  getCurrentOrganization,
  showAlert,
  platformList,
  deployTypes,
  colors,
  validTFCredData,
} from "../../helpers/constants";
// import {ReactComponent as CirclePlusIcon} from "../../assets/icons/circle_add_icon.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit_icon.svg";
import AddVarsPanel from "./AddVarsPanel";
import CommonSelect from "../../components/Select/CommonSelect";
import AWSCredPartial from "./deploymentPanel/AWSCredPartial";
import GCPCredPartial from "./deploymentPanel/GCPCredPartial";
import AzureCredPartial from "./deploymentPanel/AzureCredPartial";

const MainContainer = styled.div`
  width: 480px;
  padding: 33px 41px;

  background: #ffffff;
  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  box-shadow: 0 0 0 9999px #00000066;
  //box-shadow: 0 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 20px;
  transform: translate3d(-300px, 45px, 0px) !important;
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  z-index: 105;
`;


const AddDeploymentPanel = (props) => {
  const {
    appList,
    templates,
    createNewOrgTemplate,
    templateVars,
    fetchTemplateVarsAction,
    orgTemplates,
  } = props;

  const appListOptions = [
    ...appList.map((app) => ({ value: app.id, label: app.name })),
  ];
  const templateOptions = [
    ...templates.map((item) => ({ value: item.id, label: item.name })),
  ];
  const platformOptions = platformList.map((item) => ({
    value: item.value,
    label: item.label,
  }));
  const categoryOptions = deployTypes;

  const [selectedApp, setSelectedApp] = useState(appListOptions?.[0]);
  const [addVarModal, setAddVarModal] = useState(false);
  const [variables, setVariables] = useState([{ key: "", value: "" }]);
  const [deployment, setDeployment] = useState(null);
  // const [orgVars, setOrgVars] = useState({})

  const [category, setCategory] = useState(categoryOptions?.[0]);
  const [selectedTemplate, setSelectedTemplate] = useState(templateOptions[0]);
  const [platform, setPlatform] = useState(platformOptions?.[0]);

  const [credValue, setCredValue] = useState({});

  const ref = useRef();

  useEffect(() => {
    if (selectedTemplate?.value?.length) {
      // if(selectedTemplate.value.length && (!templateVars[selectedTemplate.value] || templateVars[selectedTemplate.value] && !Object.keys(templateVars[selectedTemplate.value])?.length)) {
      fetchTemplateVarsAction(selectedTemplate.value);
      // set template value
      // setCategory(categoryOptions.filter(option => option.value === currentTemplate.category)?.[0] ?? categoryOptions?.[0])
    }
  }, [selectedTemplate, fetchTemplateVarsAction]);

  useEffect(() => {
    const currentOrgTemplate = orgTemplates.filter(
      (orgTemplate) =>
        orgTemplate.application_id === selectedApp?.value &&
        orgTemplate.template_id === selectedTemplate?.value
    )?.[0];
    if (currentOrgTemplate) {
      if (currentOrgTemplate?.vars_cred) {
        setCredValue({
          backend: currentOrgTemplate?.vars_cred?.backend ?? {},
          terraform: currentOrgTemplate?.vars_cred?.terraform ?? {},
        });
      }
      if (currentOrgTemplate?.platform) {
        setPlatform(
          platformOptions.filter(
            (platform) => platform.value === currentOrgTemplate?.platform
          )?.[0]
        );
      }
      setDeployment(currentOrgTemplate);
    } else {
      setPlatform(platformOptions[0]);
      setCredValue({});
      setDeployment(null);
    }
  }, [selectedTemplate, selectedApp]);

  useEffect(() => {
    const vars = {
      ...templateVars[selectedTemplate.value],
      ...(deployment?.variables ?? {}),
    };
    const modifiedVar = [
      ...Object.keys(vars).map((item) => ({
        key: item,
        type: typeof vars?.[item],
        value:
          typeof vars?.[item] === "object"
            ? JSON.stringify(vars?.[item])
            : vars?.[item],
      })),
    ];
    setVariables(modifiedVar);
  }, [templateVars, deployment]);

  const toggleAddVarModal = () => {
    setAddVarModal((addVarModal) => !addVarModal);
  };

  const validateInput = () => {
    let msg = "";
    if (!selectedApp.value.length) {
      msg = "Please select application.";
    } else if (
      !deployment?.is_deployed &&
      !validTFCredData(credValue, platform?.value)
    ) {
      msg = "Please fill terraform and backend configuration";
    }
    if (msg.length) {
      showAlert("warning", "Invalid input", msg);
      return false;
    }
    return true;
  };

  const convertVars = () => {
    let retVal = {};
    variables
      .filter((item) => item.key !== "" && item.value !== "")
      .forEach((item) => {
        try {
          retVal[item.key.trim()] = JSON.parse(item.value);
        } catch (err) {
          retVal[item.key.trim()] = item.value;
        }
      });
    return retVal;
  };

  const submitNewDeployment = () => {
    if (!validateInput()) return;
    // if (!validateInput() || deployment?.is_deployed) return
    let orgTemplateData = {
      application_id: selectedApp?.value,
      template_id: selectedTemplate?.value,
      variables: convertVars(),
      organization_id: getCurrentOrganization(),
      platform: platform.value,
      vars_cred: credValue,
    };
    createNewOrgTemplate(orgTemplateData);
  };

  return (
    <MainContainer className="dropdown-menu show" ref={ref}>
      <div className="title mb-2 d-flex justify-content-between align-items-center">
        <StyledTypo
          color="#A4AFB7"
          size={1.125}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={700}
        >
          {deployment ? "Update" : "Add"} a Deployment
        </StyledTypo>
        {deployment?.is_deployed && (
          <h6>
            <Badge color="success" pill>
              DEPLOYED
            </Badge>
          </h6>
        )}
        {!deployment && (
          <h6>
            <Badge color="primary" pill>
              NEW
            </Badge>
          </h6>
        )}
      </div>
      <div className="sub-title mb-4">
        <StyledTypo
          color="#A4AFB7"
          size={1}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={700}
        >
          {deployment
            ? "You are updating existed deployment"
            : "You are creating a new deployment"}
        </StyledTypo>
      </div>
      {/*{deployment?.is_deployed && (*/}
      {/*  <WarningWrapper>*/}
      {/*    <StyledTypo color="#fff" size={0.875} line={1.4} letter="0" family="Roboto" fontWeight={400}>*/}
      {/*      Note. It is not allowed you to update variables and credentials for the deployed deployment*/}
      {/*    </StyledTypo>*/}
      {/*  </WarningWrapper>*/}
      {/*)}*/}
      <CommonSelect
        value={selectedTemplate}
        setValue={setSelectedTemplate}
        options={templateOptions}
        label="Template"
      />
      <CommonSelect
        value={selectedApp}
        setValue={setSelectedApp}
        options={appListOptions}
        label={`Application`}
      />
      <div className="dropdown show">
        <div className="my-4">
          <EffectedButton
            padding={5}
            width="100%"
            background={colors.blue}
            noSpan
            onClick={toggleAddVarModal}
          >
            {/*<CirclePlusIcon/>*/}
            {/*{!deployment?.is_deployed && (*/}
            <EditIcon width="17px" fill="#fff" />
            {/*)}*/}
            <StyledTypo
              className="ml-3"
              color="#fff"
              size={0.875}
              line={1.4}
              letter="0"
              family="Roboto"
              fontWeight={400}
            >
              Update variables
              {/*{deployment?.is_deployed ? 'View' : 'Update'} variables*/}
            </StyledTypo>
          </EffectedButton>
        </div>
        {addVarModal && (
          <AddVarsPanel
            toggle={toggleAddVarModal}
            variables={variables}
            setVariables={setVariables}
            // disabled={deployment?.is_deployed}
          />
        )}
      </div>
      <CommonSelect
        value={platform}
        setValue={setPlatform}
        options={platformOptions}
        label={`Cloud Service Platform`}
        // disabled={deployment?.is_deployed}
      />
      {platform?.value === "aws" && (
        <AWSCredPartial
          credValue={credValue}
          setCredValue={setCredValue}
          // disabled={deployment?.platform === platform?.value && deployment?.is_deployed}
        />
      )}
      {platform?.value === "gcp" && (
        <GCPCredPartial
          credValue={credValue}
          setCredValue={setCredValue}
          // disabled={deployment?.platform === platform?.value && deployment?.is_deployed}
        />
      )}
      {platform?.value === "azure" && (
        <AzureCredPartial credValue={credValue} setCredValue={setCredValue} />
      )}
      <div className="d-flex justify-content-between align-items-center mt-3 mb-5 pb-2">
        <StyledTypo
          color="#505050"
          size={1}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={400}
        >
          {deployment ? "Update Date" : "Create Date"}
        </StyledTypo>
        <StyledTypo
          color="#505050"
          size={1}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {moment().format("MM-DD-YYYY hh:mm")}
        </StyledTypo>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <EffectedButton
            marginLeft="0"
            onClick={submitNewDeployment}
            // disabled={deployment?.is_deployed}
          >
            <StyledTypo
              color="#fff"
              size={1.125}
              line={25.2 / 18}
              letter="0"
              family="Roboto"
              fontWeight={500}
            >
              {deployment ? "Update" : "Create"}
            </StyledTypo>
          </EffectedButton>
        </div>
        <div>
          <EffectedButton
            marginLeft="0"
            background="#485DCD"
            onClick={props.toggle}
          >
            <StyledTypo
              color="#fff"
              size={1.125}
              line={25.2 / 18}
              letter="0"
              family="Roboto"
              fontWeight={500}
            >
              Cancel
            </StyledTypo>
          </EffectedButton>
        </div>
      </div>
    </MainContainer>
  );
};

export default AddDeploymentPanel;
