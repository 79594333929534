import React, { useState, useEffect, useCallback, Fragment } from 'react'
import styled from 'styled-components'
import { NonComplianceStatusColor } from '../../../../helpers/constants'
import { ReactComponent as ArrowUp } from '../../../../assets/icons/up_arrow_icon.svg'
import { ReactComponent as ArrowDown } from '../../../../assets/icons/down_arrow_icon.svg'
import { ReactComponent as AddItemIcon } from '../../../../assets/icons/add_item.svg'
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit_rec_icon.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/trash_icon.svg'

import StyledTypo from '../../../../components/Typo/StyledTypo'
import ReactPaginate from 'react-paginate'
import moment from 'moment'
import DetailPanel from './DetailPanel'
import CustomInput from '../../../../components/Input/CustomInput'
import UpsertPanel from './UpsertPanel'

const TableWrapper = styled.div`
  .divider {
    margin: 5px 0;
    border-top: 1px solid rgba(164, 175, 183, 0.3);
    height: 0;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  margin-right: ${props => (props.noPagination ? '7px' : 0)};
`

const EachCell = styled.div`
  width: ${props => props.width};
  padding-right: ${props => (props.pr ? props.pr : 0)}px;
  word-break: break-word;
`

const BodyWrapper = styled.div`
  ${props =>
    props.noPagination &&
    `
    max-height: 28vh;
    overflow-y: auto;
  `}
`

const RecordContainer = styled.div`
  border-top: 1px solid #fff;
  transition: border 500ms ease;
  background: ${props => (props.active ? '#f1f1f1' : 'transparent')};
  position: relative;

  .floating-button {
    display: none;
    z-index: 100;
  }
  &:hover .floating-button {
    display: block;
  }
`

const RecordWrapper = styled.div`
  padding: 11px 0px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .text-cell {
    color: #505050;
  }
  cursor: pointer;
  &:hover {
    border-radius: 5px;
    background-color: #f1f1f1;
  }
`

const StyledStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 65px;

  margin-right: ${props => (props.mr ? props.mr : 20)}px;

  .mark {
    width: ${props => (props.size ? props.size : 12)}px;
    height: ${props => (props.size ? props.size : 12)}px;
    border-radius: 50%;
    background-color: ${props => props.color};
    // box-shadow: 0 8px 6px -3px ${props => props.color};
  }
`

const ShowingWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  position: fixed;
  right: 40px;
  bottom: 20px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100vw - 200px);
  background-color: #fff;

  .page-link:focus {
    box-shadow: none !important;
  }
  ul.pagination {
    margin-bottom: 0;
    font-size: 0.8rem;
    font-weight: 0.4;
    font-family: 'Roboto';
  }
`

const CustomizeFilterWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 15px 0;
  z-index: 100;

  .filter-menu {
    gap: 10px;
  }
`

const FilterPanel = props => {
  const {
    searchText,
    setSearchText,
    openPanel,
    hasCreate
  } = props

  return (
    <CustomizeFilterWrapper>
      <div className='d-flex align-items-center justify-content-end'>
        <div className='d-flex align-items-center mr-3 filter-menu'>
          {hasCreate && (
            <a
              className='menu-icon'
              onClick={() => openPanel(true)}
              title='Add New'
              alt='Add New'
            >
              <AddItemIcon
                width={22}
                height={22}
                fill='#606060'
                className='svg-icon'
              />
              <span className='menu-tip'>Add New</span>
            </a>
          )}
          {/* <a
            className='menu-icon'
            onClick={() => setFilterBarOpen(!filterBarOpen)}
            title='Filter By'
            alt='Filter'
          >
            <FilterIcon
              width={22}
              height={22}
              fill='#606060'
              className='svg-icon'
            />
            <span className='menu-tip'>Filter by</span>
          </a>
          <a
            className={
              checkFilterContent() ? 'menu-icon' : 'menu-icon menu-disabled'
            }
            onClick={clearFilter}
            title='Filter Clear'
            alt='Filter Clear'
          >
            <FilterClearIcon
              width={22}
              height={22}
              fill='#606060'
              className='svg-icon'
            />
            <span className='menu-tip'>Filter clear</span>
          </a> */}
        </div>
        <div>
          <CustomInput
            minWidth={350}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
        </div>
      </div>
      {/* {filterBarOpen && (
        <CustomizeFilterBodyContainer>
          <CloseWrapper
            className='cursor-pointer'
            onClick={() => setFilterBarOpen(false)}
          >
            <CloseIcon width={15} stroke='#A4AFB7' />
          </CloseWrapper>
          <div className='ml-3' style={{ minWidth: 250 }}>
            <CommonSelect
              value={selectedOrg}
              setValue={setSelectedOrg}
              options={orgOptions}
              label={`Organization`}
            />
          </div>
          <div className='ml-3' style={{ minWidth: 250 }}>
            <CommonSelect
              value={selectedRole}
              setValue={setSelectedRole}
              options={roleOptions}
              label={`Role`}
            />
          </div>
        </CustomizeFilterBodyContainer>
      )} */}
    </CustomizeFilterWrapper>
  )
}

const TableHeader = ({
  currentSort,
  setCurrentSort,
  columns,
  noPagination
}) => {
  const handleSetSort = access => {
    setCurrentSort({
      access,
      order:
        (Array.isArray(currentSort.access)
          ? currentSort.access.join()
          : currentSort.access) ===
        (Array.isArray(access) ? access.join() : access)
          ? !currentSort.order
          : true
    })
  }

  return (
    <HeaderWrapper noPagination={noPagination}>
      {columns.map((column, idx) => (
        <EachCell
          width={column.width}
          key={`header-${idx}`}
          className={` ${
            column.type === 'object' ? 'flex-column align-items-start' : ''
          }d-flex align-items-center ${column.sort ? 'cursor-pointer' : ''} ${
            column.title === 'Actions' ? 'justify-content-end' : ''
          } `}
          pr={10}
          onClick={() => {
            if (column.sort) {
              handleSetSort(column.access)
            }
          }}
        >
          {column.sort &&
          (Array.isArray(currentSort?.access)
            ? currentSort?.access.join()
            : currentSort?.access) ===
            (Array.isArray(column.access)
              ? column.access.join()
              : column.access) ? (
            currentSort.order ? (
              <ArrowUp fill={'#A4AFB7'} className='mr-3' />
            ) : (
              <ArrowDown fill={'#A4AFB7'} className='mr-3' />
            )
          ) : (
            <div />
          )}
          <StyledTypo
            color={'#505050'}
            size={0.8}
            line={1.2}
            letter='0'
            family='Roboto'
            fontWeight={400}
          >
            {column.title}
          </StyledTypo>
          <div>
            {column.type === 'object' && (
              <div className='d-flex align-items-center flex-wrap mt-2'>
                {Object.keys(NonComplianceStatusColor).map(key => (
                  <StyledStatusWrapper
                    color={NonComplianceStatusColor[key].color}
                  >
                    <StyledTypo
                      color='#687089'
                      size={0.6}
                      line={1}
                      letter='0'
                      family='Roboto'
                      fontWeight={300}
                      className='severity-text'
                    >
                      {key.toLowerCase()}
                    </StyledTypo>
                  </StyledStatusWrapper>
                ))}
              </div>
            )}
          </div>
        </EachCell>
      ))}
    </HeaderWrapper>
  )
}

const Record = props => {
  const { data, columns, onItemDetail, updateAction, deleteAction } = props

  return (
    <RecordContainer>
      <RecordWrapper>
        {columns.map((column, idx) => (
          <EachCell
            width={column.width}
            key={`${column.title}-${idx}-table-body`}
            pr={10}
          >
            {column.type === 'text' && (
              <StyledTypo
                className='text-cell'
                // onClick={handleSelectRow}
                size={0.9}
                line={1.4}
                letter='0'
              >
                {data?.[column.access] ?? 'TBD'}
              </StyledTypo>
            )}
            {column.type === 'date' && (
              <StyledTypo
                className='text-cell'
                // onClick={handleSelectRow}
                size={0.9}
                line={1.4}
                letter='0'
              >
                {moment(data?.[column.access]).format('YYYY-MM-DD hh:mm')}
              </StyledTypo>
            )}
            {column.type === 'object' && (
              <div className='d-flex align-items-center flex-wrap'>
                {Object.keys(NonComplianceStatusColor).map(key => (
                  <StyledStatusWrapper
                    color={NonComplianceStatusColor[key].color}
                  >
                    <div className='mark' />
                    <StyledTypo size={0.9} line={1.4} letter='0'>
                      {data?.[column.access]?.[key] ?? 0}
                    </StyledTypo>
                  </StyledStatusWrapper>
                ))}
              </div>
            )}
            {column.type === 'array' && data?.[column.access]?.map(item => (
              <StyledTypo
                className='text-cell'
                // onClick={handleSelectRow}
                size={0.9}
                line={1.4}
                letter='0'
              >
                {item}
              </StyledTypo>
            ))}
            {column.type === 'icon' && column.func(data?.[column.access])}
            {column.type === 'img' && (
              <img
                src={column.func(data?.[column.access])}
                alt={data?.[column.access]}
                width={30}
                height={30}
              />
            )}
            {column.type === 'action' && 
              <div className='d-flex align-items-center justify-content-end flex-wrap'>
                {column.items.map(item => item === 'update' ? (
                  <div
                    className="ml-3"
                    onClick={() => {
                      updateAction(data?.id)
                    }}
                  >
                    <EditIcon width={20} height={20} fill="#606060" />
                </div>
                ) : item === 'delete' ? (
                  <div
                    className="ml-3"
                    onClick={() => {
                      deleteAction(data?.id)
                    }}
                  >
                    <DeleteIcon width={20} height={20} fill="#606060" />
                </div>
                ) : <div></div>)}
              </div>}
          </EachCell>
        ))}
      </RecordWrapper>
    </RecordContainer>
  )
}

const CommonTable = props => {
  const { 
    data: listData, 
    columns, options, type, 
    hasDetail = false, 
    hasSearch = false, 
    hasCreate = false,
    category
  } = props

  const [data, setData] = useState([])
  const [currentSort, setCurrentSort] = useState({ access: '', order: true })
  const [currentPage, setCurrentPage] = useState(1)
  const [itemPerPage, setItemPerPage] = useState(10)

  const [openDetail, setOpenDetail] = useState(false)
  const [openUpsert, setOpenUpsert] = useState(false)
  
  const [searchText, setSearchText] = useState("")
  const [data2Show, setData2Show] = useState()

  const [updateItem, setUpdateItem] = useState()

  useEffect(() => {
    if (options?.noPagination) {
      setItemPerPage(listData?.length ?? 0)
    }
  }, [options, listData])

  const selectItemtoUpdate = (id) => {
    setOpenUpsert(true)
    setUpdateItem(data?.filter(item => item.id === id)?.[0])
  }

  const handleSort = () => {
    const retOrder = currentSort.order ? 1 : -1
    setData(data => [
      ...data.sort((a, b) => {
        const aCompare = Array.isArray(currentSort.access)
          ? currentSort.access.map(access => a[access]).join(' ')
          : a[currentSort.access]
        const bCompare = Array.isArray(currentSort.access)
          ? currentSort.access.map(access => b[access]).join(' ')
          : b[currentSort.access]
        return aCompare > bCompare ? retOrder : -1 * retOrder
      })
    ])
  }

  useEffect(() => {
    setData([...(listData ?? [])])
    setCurrentSort(currentSort =>
      currentSort.access.length === 0
        ? {
            access: columns[0].access,
            order: true
          }
        : { ...currentSort }
    )
  }, [listData])

  useEffect(() => {
    handleSort()
  }, [currentSort])

  const handlePageChange = e => {
    setCurrentPage(e.selected + 1)
  }

  const filteredData = useCallback(() => {
    let filteredData = [...data]

    if (hasSearch && searchText && searchText.length) {
      const filters = columns.filter(item => item.search)
      filteredData = [
        ...filteredData.filter(
          item =>
            !!filters.filter(
              col =>
                col.search &&
                item[col.access]
                  .toLowerCase()
                  .includes(searchText.trim().toLowerCase())
            ).length
        )
      ]
    }

    if (
      currentPage !== 1 &&
      currentPage > filteredData.length / itemPerPage + 1
    ) {
      setCurrentPage(1)
    }
    return filteredData
  }, [data, currentPage, itemPerPage])

  const pageFilter = useCallback(
    data => {
      return data.filter(
        (item, idx) =>
          idx >= itemPerPage * (currentPage - 1) &&
          idx < itemPerPage * currentPage
      )
    },
    [currentPage, itemPerPage]
  )

  const onItemDetail = data => {
    if (hasDetail) {
      setOpenDetail(true)
      setData2Show({
        type,
        data
      })
    }
  }

  return (
    <Fragment>
      <TableWrapper>
        {hasSearch && (
          <FilterPanel
            openPanel={() => {
              setOpenUpsert(true)
              setUpdateItem(null)
            }}
            setSearchText={setSearchText}
            searchText={searchText}
            hasCreate={hasCreate}
          />
        )}
        <TableHeader
          columns={columns}
          currentSort={currentSort}
          setCurrentSort={setCurrentSort}
          noPagination={options.noPagination}
        />
        <div className='divider' />
        <BodyWrapper noPagination={options.noPagination}>
          {filteredData().length ? (
            pageFilter(filteredData()).map(userData => (
              <Record
                data={userData}
                columns={columns}
                onItemDetail={onItemDetail}
                updateAction={selectItemtoUpdate}
                deleteAction={() => {}}
              />
            ))
          ) : (
            <div className='text-center my-5'>
              <StyledTypo>No data</StyledTypo>
            </div>
          )}
        </BodyWrapper>
        {!options.noPagination && (
          <ShowingWrapper>
            <StyledTypo
              size={0.8}
              line={1.6}
              letter='0'
              family='Poppins'
              fontWeight={400}
            >
              {`Showing ${
                itemPerPage * (currentPage - 1) + 1 > filteredData().length
                  ? filteredData().length
                  : itemPerPage * (currentPage - 1) + 1
              } - ${
                itemPerPage * currentPage > filteredData().length
                  ? filteredData().length
                  : itemPerPage * currentPage
              } from ${filteredData().length} Results`}
            </StyledTypo>
            <ReactPaginate
              pageCount={(filteredData()?.length ?? 0) / itemPerPage}
              renderOnZeroPageCount={null}
              containerClassName='pagination'
              previousLabel='< Prev'
              nextLabel='Next >'
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              forcePage={currentPage - 1}
              onPageChange={handlePageChange}
              activeClassName='active'
            />
          </ShowingWrapper>
        )}
      </TableWrapper>
      {hasDetail && (
        <DetailPanel
          isOpen={openDetail}
          toggle={() => setOpenDetail(openDetail => !openDetail)}
          data={data2Show}
          total={data}
          setData={setData2Show}
        />
      )}
      {hasCreate && (
        <UpsertPanel
          isOpen={openUpsert}
          toggle={() => setOpenUpsert(openUpsert => !openUpsert)}
          category={category}
          data={updateItem}
        />
      )}
    </Fragment>
  )
}

export default CommonTable
