import React from "react";
import Page from "../../components/PageLayout/Page";
import ClusterListLayout from "./kspm/ClusterListLayout";

const KSPM = (props) => {
  return (
    <div>
      <Page noUpperCta>
        <ClusterListLayout />
      </Page>
    </div>
  );
};

export default KSPM;
