const { Dropdown } = require("reactstrap");
const { default: styled } = require("styled-components");

const StyledDropdown = styled(Dropdown)`
    //&:after {
    //    content: '';
    //    top: -100%;
    //    right: -100%;
    //    bottom: -100%;
    //    left: -100%;
    //    transition: opacity .15s ease-in-out;
    //    opacity: 0;
    //    visibility: hidden;
    //    background-color: #fff;
    //    pointer-events: none
    //}
    .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus {
      box-shadow: none;
    }
`

export default StyledDropdown
