import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import AuthDashboard from "./AuthDashboard";
import styled from "styled-components";

import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import StyledTypo from "../../components/Typo/StyledTypo";
import CommonInput from "../../components/Input/CommonInput";
import { ReactComponent as CheckIcon } from "../../assets/icons/check_icon.svg";
import EffectedButton from "../../components/Buttons/EffectedButton";
import { MainContext } from "../../contexts";
import { commonTitle, getParamsStr, showAlert } from "../../helpers/constants";
import { authAction } from "../../redux/actions";
import PlanModal from "../modals/PlanModal";

import RecaptchaWrapper from "../../components/recaptchaWrapper/RecaptchaWraper";
import { useTitle } from "../../helpers/useTtitle";

const LeftWrapper = styled.div`
  background: linear-gradient(359.21deg, #76eeb5 -20.98%, #58a3ee 100.74%);
  width: 426px;
  height: 100%;
  min-height: 715px;
  border-radius: 20px 0 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 47px 0 65px;
`;

const RightWrapper = styled.div`
  padding: 84px 111px 93px 203px;
  height: 100%;
  width: 652px;
`;

const pageTitle = `${commonTitle} - Register`;

const Register = (props) => {
  const {
    registeredUser,
    registerAction,
    initializeRegisterUser,
    initializeLoggedInUser,
  } = props;

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [organization, setOrganization] = useState("");

  const [jwtToken, setJwtToken] = useState(null);
  const [accountId, setAccountId] = useState(null);

  const [showPlanModal, setShowPlanModal] = useState(false);
  const [token, setToken] = useState();
  const [isRefresh, setRefresh] = useState(false);

  const history = useHistory();

  const { setTrackHistory } = useContext(MainContext);
  const location = useLocation();

  useTitle(pageTitle)

  useEffect(() => {
    initializeRegisterUser();
    setTrackHistory((trackHistory) => [
      {
        pathname: location.pathname,
        params: getParamsStr({}),
      },
      ...trackHistory,
    ]);
    setJwtToken(
      location?.search ? new URLSearchParams(location?.search).get("jwt") : null
    );
    setAccountId(
      location?.search
        ? new URLSearchParams(location?.search).get("account_id")
        : null
    );
    setRefresh(true);
  }, []);

  useEffect(() => {
    if (Object.keys(registeredUser).length) {
      showAlert("success", "Register", "You registered successfully.");
      localStorage.setItem(
        "subscription",
        JSON.stringify({
          org_id: registeredUser?.organization_id,
          subscription: registeredUser?.organization?.subscription,
        })
      );
      initializeLoggedInUser(registeredUser.email);
      history.push("/plan");
    }
  }, [registeredUser]);

  const validateInput = () => {
    let msg = "";
    if (!email.length) {
      msg = "Please fill email.";
    } else if (!firstName.length) {
      msg = "Please fill First Name";
    } else if (!lastName.length) {
      msg = "Please fill First Name";
    } else if (!pwd.length) {
      msg = "Please fill Password.";
    } else if (pwd.length < 8) {
      msg = "Password must have 8 characters at least.";
    } else if (pwd !== confirmPwd) {
      msg =
        "Confirm Password is not same as Password. Please check Password again.";
    } else if (!organization.length) {
      msg = "Please fill Organization.";
    }
    if (msg.length) {
      showAlert("warning", "Invalid input", msg);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!validateInput()) return;
    handleAcceptPlan();
    // setShowPlanModal(true)
  };

  const handleAcceptPlan = async (plan) => {
    let registerData = {
      email,
      first_name: firstName,
      last_name: lastName,
      password: pwd,
      organization,
      // plan,
    };

    if (jwtToken) {
      registerData["jwt_token"] = jwtToken;
    }
    if (accountId) {
      registerData["account_id"] = accountId;
    }
    registerData["recaptchaToken"] = token;
    setShowPlanModal(false);
    registerAction(registerData);
    setRefresh(true);
  };

  const handleGoLogin = () => {
    history.push("/login");
  };

  return (
    <AuthDashboard>
      <RecaptchaWrapper
        setToken={setToken}
        isRefresh={isRefresh}
        setRefresh={setRefresh}
      />
      <div className="d-flex">
        <LeftWrapper>
          <div className="text-center">
            <Logo width={40} height={60} fill="#fff" />
            <StyledTypo
              className="mt-3"
              color="#fff"
              size={1.2}
              line={34.4 / 28}
              letter="0"
              family="Roboto"
              fontWeight={400}
            >
              MatosSphere
            </StyledTypo>
          </div>
          <div className="text-center">
            <StyledTypo
              color="#fff"
              size={0.8}
              line={36.4 / 26}
              letter="0"
              family="Roboto"
              fontWeight={300}
            >
              Have an Account?
            </StyledTypo>
            <div className="mt-3">
              <EffectedButton width="240px" onClick={handleGoLogin}>
                <StyledTypo
                  color="#fff"
                  size={0.8}
                  line={25.2 / 18}
                  letter="0"
                  family="Roboto"
                  fontWeight={400}
                >
                  Sign In
                </StyledTypo>
              </EffectedButton>
            </div>
          </div>
          <div className="text-center">
            <StyledTypo
              color="#fff"
              size={1}
              line={1.4}
              letter="0"
              family="Roboto"
              fontWeight={400}
            >
              Secure your cloud from build to run time
            </StyledTypo>
            <div className="col-10 offset-1">
              <StyledTypo
                className="mt-3 pt-1"
                color="#fff"
                size={0.8}
                line={25.2 / 18}
                letter="0"
                family="Roboto"
                fontWeight={300}
              >
                CloudMatos continuously assess, detect, govern, and remediate
                misconfigurations and threats in your build, deploy and cloud
                (AWS, GCP, Azure and Kubernetes) environments
              </StyledTypo>
            </div>
          </div>
        </LeftWrapper>
        <RightWrapper>
          <StyledTypo
            className="mb-4"
            color="#505050"
            size={1.5}
            line={50.4 / 36}
            letter="0"
            family="Roboto"
            fontWeight={400}
          >
            Register
          </StyledTypo>
          <div>
            <CommonInput
              label="First Name"
              value={firstName}
              setValue={setFirstName}
              active={false}
              locked={false}
              icon={CheckIcon}
              placeholder=""
            />
          </div>
          <div>
            <CommonInput
              label="Last Name"
              value={lastName}
              setValue={setLastName}
              active={false}
              locked={false}
              placeholder=""
            />
          </div>
          <div>
            <CommonInput
              label="Organization"
              value={organization}
              setValue={setOrganization}
              active={false}
              locked={false}
              placeholder=""
            />
          </div>
          <div>
            <CommonInput
              label="E-mail Address"
              value={email}
              setValue={setEmail}
              active={false}
              locked={false}
              placeholder=""
            />
          </div>
          <div>
            <CommonInput
              label="Password"
              value={pwd}
              setValue={setPwd}
              active={false}
              locked={false}
              placeholder=""
              inputType="password"
            />
          </div>
          <div>
            <CommonInput
              label="Password Again"
              value={confirmPwd}
              setValue={setConfirmPwd}
              active={false}
              locked={false}
              placeholder=""
              inputType="password"
            />
          </div>
          <div className="mt-4">
            <EffectedButton width="100%" type="submit" onClick={handleSubmit}>
              <StyledTypo
                color="#fff"
                size={0.8}
                line={25.2 / 18}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                Register
              </StyledTypo>
            </EffectedButton>
          </div>
        </RightWrapper>
      </div>
      <PlanModal
        modal={showPlanModal}
        toggle={() => setShowPlanModal((showPlanModal) => !showPlanModal)}
        handleAccept={handleAcceptPlan}
      />
    </AuthDashboard>
  );
};

function mapStateToProps(state) {
  const { registeredUser } = state.auth;
  return {
    registeredUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    registerAction: (data) => dispatch(authAction.registerAction(data)),
    initializeRegisterUser: () => dispatch(authAction.initializeRegisterUser()),
    initializeLoggedInUser: (email) => dispatch(authAction.logoutAction(email)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
