import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import StyledTypo from "../../../components/Typo/StyledTypo";
import { useHistory, useLocation } from "react-router-dom";
import { userGuideItems } from "../../../helpers/constants";
import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css";
import { Row, Col } from "reactstrap";

const ArticleContentWrapper = styled.div`
  flex: 1 1 auto;
  //display: flex;
  padding: 30px 31px;
`;

const ContentHeader = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
`;

const ContentBody = styled.div`
  max-width: 100%;
  width: 800px;
  padding: 20px 0;

  ul.deep2 {
    list-style-type: circle;
  }
  ul.deep3 {
    list-style-type: square;
  }
  ul.deep4 {
    list-style-type: disc;
  }

  .react-fancybox {
    display: flex;
    justify-content: center;
  }

  .multi-images .react-fancybox .thumbnail img {
    max-width: 300px;
  }
`;

const ContentFooter = styled.div`
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 30px;
`;

const StyledLink = styled.a`
  height: 30px;
  padding: 10px;
  text-decoration: none;
  color: var(--doc-main-font-color);

  &:hover {
    color: var(--doc-logo-color);
    text-decoration: none;
    cursor: pointer;
  }
`;

export const ParagraphWrapper = styled.div`
  padding: 10px 0;
  text-align: justify;

  li {
    margin-bottom: 15px;
  }

  li > ul {
    margin-top: 15px;
  }

  ol li {
    margin-bottom: 20px;
  }
`;

const ArticleContentTemplate = () => {
  const [currentArticle, setCurrentArticle] = useState(null);
  const [nextArticle, setNextArticle] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const ref = useRef();

  useEffect(() => {
    if (ref?.current && ref?.current?.offsetTop) {
      window.scrollTo(0, ref.current.offsetTop);
    }
  }, [location.hash, ref?.current]);

  useEffect(() => {
    if (location.pathname) {
      const itemKeyArr = Object.keys(userGuideItems);
      let tempItem =
        userGuideItems[
          itemKeyArr.filter(
            (item) => userGuideItems[item].link === location.pathname
          )?.[0]
        ];
      if (tempItem.index === "root") {
        tempItem = userGuideItems["matossphere"];
      }
      setCurrentArticle(tempItem);
      setNextArticle(tempItem.next ? userGuideItems[tempItem.next] : null);
    }
  }, [location]);

  const getLoopList = (deep, item) => {
    return (
      <ul className={`deep${deep}`}>
        {item.map((each) =>
          Array.isArray(each) ? (
            <li>
              {each[0]}{" "}
              {getLoopList(
                deep + 1,
                each.filter((e, idx) => idx !== 0)
              )}
            </li>
          ) : (
            <li>
              <StyledTypo
                size={1}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={400}
                dangerouslySetInnerHTML={{ __html: each }}
              />
            </li>
          )
        )}
      </ul>
    );
  };

  return (
    <ArticleContentWrapper>
      <ContentHeader ref={!location.hash.length ? ref : null}>
        <h1>{currentArticle?.data ?? ""}</h1>
        {currentArticle?.description && (
          <StyledTypo
            size={1}
            line={1.4}
            letter="0"
            family="Roboto"
            fontWeight={400}
          >
            {currentArticle?.description ?? ""}
          </StyledTypo>
        )}
      </ContentHeader>
      <ContentBody>
        {/*{props.children}*/}
        {currentArticle &&
          currentArticle.contents &&
          currentArticle.contents.map((item) => {
            if (item.type === "text") {
              return (
                <ParagraphWrapper>
                  <StyledTypo
                    size={1}
                    line={1.4}
                    letter="0"
                    family="Roboto"
                    fontWeight={400}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                </ParagraphWrapper>
              );
            } else if (item.type === "label") {
              return (
                <ParagraphWrapper
                  id={item.id}
                  ref={`#${item.id}` === location.hash ? ref : null}
                >
                  <StyledTypo
                    size={1}
                    line={1.4}
                    letter="0"
                    family="Roboto"
                    fontWeight={700}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                </ParagraphWrapper>
              );
            } else if (item.type === "label1") {
              return (
                <ParagraphWrapper
                  id={item.id}
                  ref={`#${item.id}` === location.hash ? ref : null}
                >
                  <StyledTypo
                    className="ml-3"
                    size={1.25}
                    line={1.4}
                    letter="0"
                    family="Roboto"
                    fontWeight={700}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                </ParagraphWrapper>
              );
            } else if (item.type === "image") {
              return (
                <ParagraphWrapper>
                  {Array.isArray(item.content) ? (
                    <Row>
                      {item.content.map((each) => (
                        <Col
                          md={12 / item.content.length}
                          className="multi-images my-3"
                        >
                          <ReactFancyBox thumbnail={each} image={each} />
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <ReactFancyBox
                      thumbnail={item.content}
                      image={item.content}
                    />
                  )}
                </ParagraphWrapper>
              );
            } else if (item.type === "list") {
              return (
                <ParagraphWrapper>
                  {getLoopList(1, item.content)}
                </ParagraphWrapper>
              );
            } else if (item.type === "order-list") {
              return (
                <ParagraphWrapper>
                  <ol>
                    {item.content.map((each) => (
                      <li>
                        <StyledTypo
                          className="ml-3"
                          size={1}
                          line={1.4}
                          letter="0"
                          family="Roboto"
                          fontWeight={400}
                          dangerouslySetInnerHTML={{ __html: each }}
                        />
                      </li>
                    ))}
                  </ol>
                </ParagraphWrapper>
              );
            } else if (item.type === "table") {
              return (
                <ParagraphWrapper>
                  {item.title && (
                    <StyledTypo
                      size={1}
                      line={1.4}
                      letter="0"
                      family="Roboto"
                      fontWeight={700}
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                  )}
                  <table className="table table-striped table-bordered">
                    <thead>
                      {item.content.header.map((header) => (
                        <th>{header}</th>
                      ))}
                    </thead>
                    <tbody>
                      {item.content.body.map((body) => (
                        <tr>
                          {body.map((bodyItem) => (
                            <td>
                              <StyledTypo
                                size={1}
                                line={1.4}
                                letter="0"
                                family="Roboto"
                                fontWeight={400}
                                dangerouslySetInnerHTML={{ __html: bodyItem }}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ParagraphWrapper>
              );
            } else {
              return <div />;
            }
          })}
      </ContentBody>
      <ContentFooter>
        <StyledTypo
          size={1}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={400}
        >
          Next:{" "}
          {nextArticle ? (
            <StyledLink onClick={() => history.push(nextArticle.link)}>
              {nextArticle.data}
            </StyledLink>
          ) : (
            ""
          )}
        </StyledTypo>
      </ContentFooter>
    </ArticleContentWrapper>
  );
};

export default ArticleContentTemplate;
