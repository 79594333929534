import { applications } from "../apis";
import { applicationConstants } from "../constants";
import { authAction } from "./auth.actions";
import { showAlert, getCurrentOrganization } from "../../helpers/constants";

export const applicationActions = {
  fetchApplicationListAction,
  fetchDemoBlogListAction,
  fetchClusterDetailsAction,
  fetchAppHealthAction,
  fetchActivityFeedsAction,
  addApplicationAction,
  updateApplicationAction,
  resetAddAppStatusAction,
  deleteApplicationAction,
  resetDeleteAppStatusAction,

  fetchRemediationRequiredAction,
  fetchResourceSummaryAction,
  fetchOrgEnvListAction,
  updateOrgEnvListAction,
  clearAppListLoadingFlag,

  fetchHealthHistoryAction,
  fetchResourceHistoryAction,
  fetchNotificationsAction,
  fetchLatestResourceHistoryAction,
  fetchHistoryApplicationAction,

  fetchRemediationListAction,
  fetchSolutionsAction,
  fetchRemediationLogsAction,
  generateSummaryReportAction,
  generateDetailReportAction,
  triggerResourceAction,
  triggerStatusAction,
  fetchSteampipeAction,
  triggerSteampipeAction,

  triggerSingleResourceAction,
  fetchSingleTriggerStatusAction,
  fetchManualCloudRemediationAction,

  fetchProwlerResourceAction,
  fetchProwlerRemediationAction,
  fetchCloudRemediationSolutionAction,
  updateOrgScanSettingAction,
  updateOrgComplianceSettingAction,
  fetchTrendDataAction,
  fetchWorkloadsAction,
  fetchWorkloadSummaryAction,
  fetchWorkloadDetailAction,

  fetchKspmClustersAction,
  fetchKspmClusterSummaryAction,
  fetchKspmClusterDetailAction,
  fetchUserAssetsAction,
  fetchInstanceCveGroupAction,
  fetchSGAssetsAction,
  fetchNetworkAssetsAction,
  fetchGLogsAction,

  fetchInstanceCveDetailAction,

  fetchTopAttackPathsAction,
  fetchTopInfrasAction,
  fetchTopNoncompliancesAction,

  fetchTopIdentityAccessAction,
  fetchTopContainersAction,
  fetchTopClustersAction,
  fetchTopDataSecurityAction,
  fetchTopPublicExposureAction,
  fetchTopSoftwareUpdateAction,

  fetchPentestingConfigurationsAction,
  fetchSelectedPentestingConfigurationAction,
  setSelectedPentestingConfigurationAction,
  setPentestingConfigurationAction,

  fetchWorkspacesAction,
  fetchWorkspaceAction,
  createWorkspaceAction,
  updateWorkspaceAction,
  deleteWorkspaceAction,
  clearCRUDWorkspaceFlagAction,
};

function fetchApplicationListAction() {
  return (dispatch) => {
    dispatch(request());
    applications.fetchApplicationList().then(
      (appList) => {
        dispatch(success(appList));
      },
      (error) => {
        if (error === "invalid-token") {
          showAlert("error", "Error", "Token was expired or Invalid token");
        } else {
          showAlert("error", "Error", "Failed to load infrastructures.");
        }
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_APP_LIST_REQUEST };
  }
  function success(appList) {
    return { type: applicationConstants.FETCH_APP_LIST_SUCCESS, appList };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_APP_LIST_FAILURE, error };
  }
}

function fetchDemoBlogListAction() {
  return (dispatch) => {
    dispatch(request());
    applications.fetchDemoBlogList().then(
      (appList) => {
        dispatch(success(appList));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
        // dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_DEMO_LIST_REQUEST };
  }
  function success(demoList) {
    return { type: applicationConstants.FETCH_DEMO_LIST_SUCCESS, demoList };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_DEMO_LIST_FAILURE, error };
  }
}

function fetchAppHealthAction(org_id, app_id, realtimeFlag) {
  return (dispatch) => {
    dispatch(request());
    applications.fetchAppHealth(org_id, app_id, realtimeFlag).then(
      (healthInfo) => {
        dispatch(success(healthInfo, app_id));
      },
      (error) => {
        if (error === "invalid-token") {
          dispatch(authAction.setTokenExpired(error, failure));
        } else {
          dispatch(failure(error, app_id));
        }
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_APP_HEALTH_REQUEST };
  }
  function success(healthInfo, app_id) {
    return {
      type: applicationConstants.FETCH_APP_HEALTH_SUCCESS,
      healthInfo,
      app_id,
    };
  }
  function failure(error) {
    return {
      type: applicationConstants.FETCH_APP_HEALTH_FAILURE,
      error,
      app_id,
    };
  }
}

function fetchClusterDetailsAction(app_id, realtimeFlag) {
  const org_id = getCurrentOrganization();
  return (dispatch) => {
    if (realtimeFlag) {
      showAlert(
        "info",
        "Refreshing resource",
        `Fetching resources of ${app_id} from cloud...`
      );
    }
    dispatch(request());
    applications.fetchClusterDetails(org_id, app_id, realtimeFlag).then(
      (clusterInfo) => {
        if (realtimeFlag) {
          showAlert(
            "success",
            "Refreshing resource",
            `Successfully fetched resources of ${app_id} from cloud.`
          );
        }
        dispatch(fetchCloudRemediationSolutionAction(app_id));
        dispatch(success(app_id, clusterInfo));
      },
      (error) => {
        if (realtimeFlag) {
          dispatch(fetchClusterDetailsAction(org_id, app_id, false));
          showAlert(
            "success",
            "Refreshing resource",
            `Successfully fetched resources of ${app_id} from cloud.`
          );
        }
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_APP_CLUSTER_REQUEST };
  }
  function success(app_id, clusterInfo) {
    return {
      type: applicationConstants.FETCH_APP_CLUSTER_SUCCESS,
      app_id,
      clusterInfo,
    };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_APP_CLUSTER_FAILURE, error };
  }
}

function fetchActivityFeedsAction() {
  return (dispatch) => {
    dispatch(request());
    applications.fetchActivityFeeds().then(
      (activityFeeds) => {
        dispatch(success(activityFeeds));
      },
      (error) => {
        dispatch(authAction.setTokenExpired(error, failure));
        // dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_ACTIVITY_FEEDS_REQUEST };
  }
  function success(activityFeeds) {
    return {
      type: applicationConstants.FETCH_ACTIVITY_FEEDS_SUCCESS,
      activityFeeds,
    };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_ACTIVITY_FEEDS_FAILURE, error };
  }
}

function addApplicationAction(data) {
  return (dispatch) => {
    dispatch(request());
    applications.addApplication(data).then(
      (res) => {
        showAlert(
          "success",
          "New Application",
          "Successfully added new application"
        );
        dispatch(success(res));
        dispatch(fetchApplicationListAction());
        dispatch(fetchActivityFeedsAction());
      },
      (error) => {
        showAlert("error", "New Application", "Failed to add new application");
        error && dispatch(authAction.setTokenExpired(error, failure));
        // dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: applicationConstants.ADD_NEW_APPLICATION_REQUEST };
  }
  function success(appRes) {
    return { type: applicationConstants.ADD_NEW_APPLICATION_SUCCESS, appRes };
  }
  function failure(error) {
    return { type: applicationConstants.ADD_NEW_APPLICATION_FAILURE, error };
  }
}

function updateApplicationAction(data) {
  return (dispatch) => {
    dispatch(request());
    applications.updateApplication(data).then(
      (res) => {
        showAlert(
          "success",
          "Update cloud infrastructure",
          "Successfully updated cloud infrastructure"
        );
        dispatch(success(res));
        dispatch(fetchApplicationListAction());
      },
      (error) => {
        showAlert(
          "error",
          "Update cloud infrastructure",
          "Failed to update cloud infrastructure"
        );
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.UPDATE_APPLICATION_REQUEST };
  }
  function success(appRes) {
    return { type: applicationConstants.UPDATE_APPLICATION_SUCCESS, appRes };
  }
  function failure(error) {
    return { type: applicationConstants.UPDATE_APPLICATION_FAILURE, error };
  }
}

function resetAddAppStatusAction() {
  return (dispatch) => {
    dispatch({ type: applicationConstants.RESET_ADD_APP_STATUS });
  };
}

function resetDeleteAppStatusAction() {
  return (dispatch) => {
    dispatch({ type: applicationConstants.RESET_DELETE_APP_STATUS });
  };
}

function deleteApplicationAction(app_id) {
  return (dispatch) => {
    dispatch(request());
    applications.deleteApplication(app_id).then(
      (res) => {
        showAlert(
          "success",
          "Delete Application",
          "Successfully deleted application"
        );
        dispatch(success(res));
        dispatch(fetchApplicationListAction());
        dispatch(fetchActivityFeedsAction());
      },
      (error) => {
        showAlert(
          "error",
          "Delete Application",
          "Failed to delete application"
        );
        if (error) {
          dispatch(authAction.setTokenExpired(error, failure));
        }
        // dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: applicationConstants.DELETE_APPLICATION_REQUEST };
  }
  function success(appRes) {
    return { type: applicationConstants.DELETE_APPLICATION_SUCCESS, appRes };
  }
  function failure(error) {
    return { type: applicationConstants.DELETE_APPLICATION_FAILURE, error };
  }
}

function fetchRemediationRequiredAction(app_id) {
  return (dispatch) => {
    dispatch(request());
    applications.fetchRemediationRequired(app_id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        if (error) {
          dispatch(authAction.setTokenExpired(error, failure));
        }
        // dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_REMEDIATION_REQUIRED_REQUEST };
  }
  function success(templates) {
    return {
      type: applicationConstants.FETCH_REMEDIATION_REQUIRED_SUCCESS,
      templates,
    };
  }
  function failure(error) {
    return {
      type: applicationConstants.FETCH_REMEDIATION_REQUIRED_FAILURE,
      error,
    };
  }
}

function fetchResourceSummaryAction() {
  return (dispatch) => {
    dispatch(request());
    applications.fetchResourceSummary().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
        // dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_RESOURCE_SUMMARY_REQUEST };
  }
  function success(summary) {
    return {
      type: applicationConstants.FETCH_RESOURCE_SUMMARY_SUCCESS,
      summary,
    };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_RESOURCE_SUMMARY_FAILURE, error };
  }
}

function fetchOrgEnvListAction(org_id) {
  const orgId = org_id ? org_id : getCurrentOrganization();
  return (dispatch) => {
    dispatch(request());
    applications.fetchOrgEnvList(orgId).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
        // dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_ORG_ENV_LIST_REQUEST };
  }
  function success(envData) {
    return { type: applicationConstants.FETCH_ORG_ENV_LIST_SUCCESS, envData };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_ORG_ENV_LIST_FAILURE, error };
  }
}

function updateOrgEnvListAction(payload, kind) {
  return (dispatch) => {
    dispatch(request());
    applications.updateOrgEnvList(payload).then(
      (res) => {
        showAlert(
          "success",
          "Successful",
          `Successfully ${
            kind === "create"
              ? "created new"
              : kind === "remove"
              ? "removed"
              : "updated"
          } environment list.`
        );
        dispatch(success(res));
      },
      (error) => {
        showAlert("error", "Failed", `${error.result}`);
        error && dispatch(authAction.setTokenExpired(error, failure));
        // dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: applicationConstants.UPDATE_ORG_ENV_LIST_REQUEST };
  }
  function success(envData) {
    return { type: applicationConstants.UPDATE_ORG_ENV_LIST_SUCCESS, envData };
  }
  function failure(error) {
    return { type: applicationConstants.UPDATE_ORG_ENV_LIST_FAILURE, error };
  }
}

function clearAppListLoadingFlag() {
  return (dispatch) => {
    dispatch({ type: applicationConstants.RESET_DELETE_APP_STATUS });
  };
}

function fetchHealthHistoryAction(data) {
  return (dispatch) => {
    dispatch(request());
    applications.fetchHealthHistory(data).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_HEALTH_HISTORY_REQUEST };
  }
  function success(historyData) {
    return {
      type: applicationConstants.FETCH_HEALTH_HISTORY_SUCCESS,
      historyData,
    };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_HEALTH_HISTORY_FAILURE, error };
  }
}

function fetchResourceHistoryAction(data) {
  return (dispatch) => {
    dispatch(request());
    applications.fetchResourceHistory(data).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_RESOURCE_HISTORY_REQUEST };
  }
  function success(historyData) {
    return {
      type: applicationConstants.FETCH_RESOURCE_HISTORY_SUCCESS,
      historyData,
    };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_RESOURCE_HISTORY_FAILURE, error };
  }
}

function fetchNotificationsAction(page) {
  return (dispatch) => {
    dispatch(request());
    applications.fetchActivityFeeds(page).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_NOTIFICATIONS_REQUEST };
  }
  function success(notifications) {
    return {
      type: applicationConstants.FETCH_NOTIFICATIONS_SUCCESS,
      notifications,
    };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_NOTIFICATIONS_FAILURE, error };
  }
}

function fetchLatestResourceHistoryAction(data) {
  return (dispatch) => {
    dispatch(request());
    applications.fetchLatestResourceHistory(data).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_LATEST_HISTORY_RESOURCE_REQUEST };
  }
  function success(resourceHistory) {
    return {
      type: applicationConstants.FETCH_LATEST_HISTORY_RESOURCE_SUCCESS,
      resourceHistory,
      platform: data?.platform ?? "gcp",
    };
  }
  function failure(error) {
    return {
      type: applicationConstants.FETCH_LATEST_HISTORY_RESOURCE_FAILURE,
      error,
      platform: data?.platform ?? "gcp",
    };
  }
}

function fetchHistoryApplicationAction(data) {
  return (dispatch) => {
    dispatch(request());
    applications.fetchApplicationList(data).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_HISTORY_APPLICATION_REQUEST };
  }
  function success(historyApps) {
    return {
      type: applicationConstants.FETCH_HISTORY_APPLICATION_SUCCESS,
      historyApps,
    };
  }
  function failure(error) {
    return {
      type: applicationConstants.FETCH_HISTORY_APPLICATION_FAILURE,
      error,
    };
  }
}

function fetchRemediationListAction(platform = "gcp") {
  return (dispatch) => {
    dispatch(request());
    applications.fetchRemediations(platform).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_REMEDIATION_LIST_REQUEST };
  }
  function success(remediations) {
    return {
      type: applicationConstants.FETCH_REMEDIATION_LIST_SUCCESS,
      remediations,
    };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_REMEDIATION_LIST_FAILURE, error };
  }
}

function fetchSolutionsAction(data) {
  return (dispatch) => {
    dispatch(request());
    showAlert("info", "Observing resource", `Fetching observing items...`);
    applications.fetchSolutions(data).then(
      (res) => {
        showAlert(
          "success",
          "Observing resource",
          `Successfully fetched observed items.`
        );
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_SOLUTIONS_REQUEST };
  }
  function success(solution) {
    return {
      type: applicationConstants.FETCH_SOLUTIONS_SUCCESS,
      solution,
      app_id: data.app_id,
    };
  }
  function failure(error) {
    return {
      type: applicationConstants.FETCH_SOLUTIONS_FAILURE,
      error,
      app_id: data.app_id,
    };
  }
}

function fetchRemediationLogsAction(data) {
  return (dispatch) => {
    dispatch(request());
    applications.fetchRemediationLogs(data).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_REMEDIATION_LOGS_REQUEST };
  }
  function success(logs) {
    return { type: applicationConstants.FETCH_REMEDIATION_LOGS_SUCCESS, logs };
  }
  function failure(error) {
    return {
      type: applicationConstants.FETCH_REMEDIATION_LOGS_FAILURE,
      error,
      app_id: data.app_id,
      remediation_id: data.remediation_id,
    };
  }
}

function generateSummaryReportAction(type) {
  const typeKey = `${type}_summary`
  return (dispatch) => {
    dispatch(request());
    applications.generateSummaryReport(type).then(
      (res) => {
        showAlert(
          "success",
          "Download report",
          `Successfully downloaded report.`
        );
        dispatch(success(res));
      },
      (error) => {
        showAlert("error", "Download report", `Failed to generate report.`);
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };


  function request() {
    return {
      type: applicationConstants.DOWNLOAD_REPORT_REQUEST,
      kind: typeKey,
    };
  }
  function success(logs) {
    return {
      type: applicationConstants.DOWNLOAD_REPORT_SUCCESS,
      kind: typeKey,
    };
  }
  function failure(error) {
    return {
      type: applicationConstants.DOWNLOAD_REPORT_FAILURE,
      kind: typeKey,
    };
  }
}

function generateDetailReportAction(type, cluster_id) {
  const typeKey = `${type}_detail`
  return (dispatch) => {
    dispatch(request());
    applications.generateDetailReport(type, cluster_id).then(
      (res) => {
        showAlert(
          "success",
          "Download report",
          `Successfully downloaded report.`
        );
        dispatch(success(res));
      },
      (error) => {
        showAlert("error", "Download report", `Failed to generate report.`);
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return {
      type: applicationConstants.DOWNLOAD_REPORT_REQUEST,
      kind: typeKey,
    };
  }
  function success(logs) {
    return {
      type: applicationConstants.DOWNLOAD_REPORT_SUCCESS,
      kind: typeKey,
    };
  }
  function failure(error) {
    return {
      type: applicationConstants.DOWNLOAD_REPORT_FAILURE,
      kind: typeKey,
    };
  }
}

function triggerResourceAction(application_id) {
  return (dispatch) => {
    // dispatch(request());
    applications.triggerResource(application_id).then(
      () => {
        dispatch(success());
        showAlert(
          "success",
          "Fetching Resource",
          `Started fetching resources of ${application_id}. Running as background...`
        );
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
        showAlert(
          "error",
          "Fetching Resource",
          `Already running process to fetch resource of ${application_id}.`
        );
      }
    );
  };

  // function request() { return { type: applicationConstants.FETCH_REMEDIATION_LOGS_REQUEST } }
  function success() {
    return {
      type: applicationConstants.TRIGGER_RESOURCE_SUCCESS,
      application_id,
    };
  }
  function failure() {
    return {
      type: applicationConstants.TRIGGER_RESOURCE_FAILURE,
      application_id,
    };
  }
}

function triggerStatusAction(application_id) {
  return (dispatch) => {
    // dispatch(request());
    applications.triggerStatus(application_id).then(
      (res) => {
        const task_status = res.task_status;
        dispatch(success(res));
        if (task_status === "completed") {
          showAlert(
            "success",
            "Fetching Resource",
            `Successfully fetched resource of ${application_id}.`
          );
          dispatch(
            fetchClusterDetailsAction(
              getCurrentOrganization(),
              application_id,
              false
            )
          );
        }
        if (task_status === "failed") {
          showAlert(
            "error",
            "Fetching Resource",
            `Failed to fetch resource of ${application_id}.`
          );
        }
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  // function request() { return { type: applicationConstants.FETCH_REMEDIATION_LOGS_REQUEST } }
  function success(status) {
    return {
      type: applicationConstants.TRIGGER_STATUS_SUCCESS,
      application_id,
      status,
    };
  }
  function failure() {}
}

function fetchSteampipeAction(application_id) {
  return (dispatch) => {
    dispatch(request());
    applications.fetchSteampipeResult(application_id).then(
      (res) => {
        dispatch(success(res));
        showAlert(
          "success",
          "Evaluating resource",
          `Started evaluating resources of ${application_id}. Running as background...`
        );
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
        showAlert(
          "error",
          "Evaluating resource",
          `Already running process to evaluate resource of ${application_id}.`
        );
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_STEAMPIPE_RESULT_REQUEST };
  }
  function success(result) {
    return {
      type: applicationConstants.FETCH_STEAMPIPE_RESULT_SUCCESS,
      result,
      application_id,
    };
  }
  function failure() {
    return {
      type: applicationConstants.FETCH_STEAMPIPE_RESULT_FAILURE,
      application_id,
    };
  }
}

function triggerSteampipeAction(application_id) {
  return (dispatch) => {
    dispatch(request());
    applications.triggerSteampipe(application_id).then(
      () => {
        dispatch(success());
        showAlert(
          "success",
          "Evaluating resource",
          `Started evaluating resources of ${application_id}. Running as background...`
        );
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
        showAlert(
          "error",
          "Evaluating resource",
          `Already running process to evaluate resource of ${application_id}.`
        );
      }
    );
  };

  function request() {
    return { type: applicationConstants.TRIGGER_STEAMPIPE_REQUEST };
  }
  function success() {
    return {
      type: applicationConstants.TRIGGER_STEAMPIPE_SUCCESS,
      application_id,
    };
  }
  function failure() {
    return {
      type: applicationConstants.TRIGGER_STEAMPIPE_FAILURE,
      application_id,
    };
  }
}

function triggerSingleResourceAction(
  application_id,
  remediation_id,
  resource_type
) {
  return (dispatch) => {
    applications
      .triggerSingleResource(application_id, remediation_id, resource_type)
      .then(
        (res) => {
          dispatch(success(res));
          // showAlert('success', "Fetching Resource", `Started fetching resources of ${application_id}. Running as background...`)
        },
        (error) => {
          error && dispatch(authAction.setTokenExpired(error, failure));
          // showAlert('error', "Fetching Resource", `Already running process to fetch resource of ${application_id}.`)
        }
      );
  };

  // function request() { return { type: applicationConstants.FETCH_REMEDIATION_LOGS_REQUEST } }
  function success(payload) {
    return {
      type: applicationConstants.TRIGGER_SINGLE_RESOURCE_SUCCESS,
      application_id,
      remediation_id,
      resource_type,
      task_id: payload.task_id,
    };
  }
  function failure() {
    return {
      type: applicationConstants.TRIGGER_SINGLE_RESOURCE_FAILURE,
      application_id,
      remediation_id,
      resource_type,
    };
  }
}

function fetchSingleTriggerStatusAction(
  application_id,
  remediation_id,
  task_id
) {
  return (dispatch) => {
    // dispatch(request());
    applications.fetchSingleResourceTriggerStatus(task_id).then(
      (res) => {
        const task_status = res.task_status;
        dispatch(success(res));
        if (task_status === "completed") {
          // showAlert('success', "Fetching Special Resource", `Successfully fetched resource of ${application_id}.`)
          dispatch(
            fetchClusterDetailsAction(
              getCurrentOrganization(),
              application_id,
              false
            )
          );
        }
        if (task_status === "failed") {
          // showAlert('error', "Fetching Special Resource", `Failed to fetch resource of ${application_id}.`)
        }
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  // function request() { return { type: applicationConstants.FETCH_REMEDIATION_LOGS_REQUEST } }
  function success(status) {
    return {
      type: applicationConstants.SINGLE_TRIGGER_STATUS_SUCCESS,
      application_id,
      remediation_id,
      task_id,
      status,
    };
  }
  function failure() {}
}

function fetchManualCloudRemediationAction(remediation_id) {
  return (dispatch) => {
    applications.fetchManualCloudRemediation(remediation_id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function success(res) {
    return {
      type: applicationConstants.FETCH_MANUAL_CLOUD_REMEDIATION_SUCCESS,
      remediation_id,
      payload: res,
    };
  }
  function failure() {
    return {
      type: applicationConstants.FETCH_MANUAL_CLOUD_REMEDIATION_FAILURE,
      remediation_id,
    };
  }
}

function fetchProwlerResourceAction(app_id) {
  return (dispatch) => {
    applications.fetchProwlerResource(app_id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function success(res) {
    return {
      type: applicationConstants.FETCH_PROWLER_RESOURCE_SUCCESS,
      app_id,
      payload: res,
    };
  }
  function failure() {
    return {
      type: applicationConstants.FETCH_PROWLER_RESOURCE_FAILURE,
      app_id,
    };
  }
}

function fetchProwlerRemediationAction(app_id) {
  return (dispatch) => {
    applications.fetchProwlerRemediation(app_id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function success(res) {
    return {
      type: applicationConstants.FETCH_PROWLER_REMEDIATION_SUCCESS,
      app_id,
      payload: res,
    };
  }
  function failure() {
    return {
      type: applicationConstants.FETCH_PROWLER_REMEDIATION_FAILURE,
      app_id,
    };
  }
}

function fetchCloudRemediationSolutionAction(app_id) {
  return (dispatch) => {
    applications.fetchCloudRemediationSolution(app_id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function success(res) {
    return {
      type: applicationConstants.FETCH_CLOUD_REMEDIATION_SOLUTION_SUCCESS,
      app_id,
      payload: res,
    };
  }
  function failure() {
    return {
      type: applicationConstants.FETCH_CLOUD_REMEDIATION_SOLUTION_FAILURE,
      app_id,
    };
  }
}

function updateOrgScanSettingAction(data) {
  const orgId = getCurrentOrganization();
  return (dispatch) => {
    dispatch(request());
    applications.updateOrgScanSetting(orgId, data).then(
      (res) => {
        showAlert("success", "Success", "Updated audit enable status successfully");
        dispatch(success(res));
        dispatch(fetchOrgEnvListAction(orgId))
      },
      (error) => {
        showAlert("error", "Error", "Failed to update audit enable status");
        error && dispatch(authAction.setTokenExpired(error, failure));
        // dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: applicationConstants.UPDATE_ORG_SCAN_SETTING_REQUEST };
  }
  function success(envData) {
    return { type: applicationConstants.UPDATE_ORG_SCAN_SETTING_SUCCESS, envData };
  }
  function failure(error) {
    return { type: applicationConstants.UPDATE_ORG_SCAN_SETTING_FAILURE, error };
  }
}

function updateOrgComplianceSettingAction(data) {
  const orgId = getCurrentOrganization();
  return (dispatch) => {
    dispatch(request());
    applications.updateOrgComplianceSetting(data).then(
      (res) => {
        showAlert("success", "Success", "Updated compliance enable status successfully");
        dispatch(success(res));
        dispatch(fetchOrgEnvListAction(orgId))
      },
      (error) => {
        showAlert("error", "Error", "Failed to update compliance enable status");
        error && dispatch(authAction.setTokenExpired(error, failure));
        // dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: applicationConstants.UPDATE_ORG_SCAN_SETTING_REQUEST };
  }
  function success(envData) {
    return { type: applicationConstants.UPDATE_ORG_SCAN_SETTING_SUCCESS, envData };
  }
  function failure(error) {
    return { type: applicationConstants.UPDATE_ORG_SCAN_SETTING_FAILURE, error };
  }
}

function fetchTrendDataAction(data) {
  return (dispatch) => {
    dispatch(request());
    applications.fetchTrendData(data).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_COMPLIANCE_TREND_REQUEST };
  }
  function success(complianceTrend) {
    return { type: applicationConstants.FETCH_COMPLIANCE_TREND_SUCCESS, complianceTrend, provider: data.provider };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_COMPLIANCE_TREND_FAILURE, error };
  }
}

function fetchWorkloadsAction() {
  return (dispatch) => {
    dispatch(request());
    applications.fetchWorkloads().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_WORKLOADS_REQUEST };
  }
  function success(workloads) {
    return { type: applicationConstants.FETCH_WORKLOADS_SUCCESS, workloads };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_WORKLOADS_FAILURE, error };
  }
}

function fetchWorkloadSummaryAction(workload_id) {
  return (dispatch) => {
    dispatch(request());
    applications.fetchWorkloadSummary(workload_id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_WORKLOAD_SUMMARY_REQUEST };
  }
  function success(summary) {
    return { type: applicationConstants.FETCH_WORKLOAD_SUMMARY_SUCCESS, summary, workload_id };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_WORKLOAD_SUMMARY_FAILURE, error, workload_id };
  }
}

function fetchWorkloadDetailAction(workload_id) {
  return (dispatch) => {
    dispatch(request());
    applications.fetchWorkloadDetail(workload_id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_WORKLOAD_DETAIL_REQUEST };
  }
  function success(detail) {
    return { type: applicationConstants.FETCH_WORKLOAD_DETAIL_SUCCESS, detail, workload_id };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_WORKLOAD_DETAIL_FAILURE, error, workload_id };
  }
}

function fetchKspmClustersAction() {
  return (dispatch) => {
    dispatch(request());
    applications.fetchKspmClusters().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_KSPM_CLUSTERS_REQUEST };
  }
  function success(clusters) {
    return { type: applicationConstants.FETCH_KSPM_CLUSTERS_SUCCESS, clusters };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_KSPM_CLUSTERS_FAILURE };
  }
}

function fetchKspmClusterSummaryAction(cluster_id) {
  return (dispatch) => {
    dispatch(request());
    applications.fetchKspmClusterSummary(cluster_id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_KSPM_CLUSTER_SUMMARY_REQUEST };
  }
  function success(summary) {
    return { type: applicationConstants.FETCH_KSPM_CLUSTER_SUMMARY_SUCCESS, summary, cluster_id };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_KSPM_CLUSTER_SUMMARY_FAILURE, cluster_id };
  }
}

function fetchKspmClusterDetailAction(cluster_id) {
  return (dispatch) => {
    dispatch(request());
    applications.fetchKspmClusterDetail(cluster_id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_KSPM_CLUSTER_DETAIL_REQUEST };
  }
  function success(detail) {
    return { type: applicationConstants.FETCH_KSPM_CLUSTER_DETAIL_SUCCESS, detail, cluster_id };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_KSPM_CLUSTER_DETAIL_FAILURE, cluster_id };
  }
}

function fetchUserAssetsAction(app_id) {
  return (dispatch) => {
    dispatch(request());
    applications.fetchUserAssets(app_id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: applicationConstants.FETCH_USER_ASSETS_REQUEST };
  }
  function success(userAssets) {
    return { type: applicationConstants.FETCH_USER_ASSETS_SUCCESS, userAssets, app_id };
  }
  function failure(error) {
    return { type: applicationConstants.FETCH_USER_ASSETS_FAILURE, app_id };
  }
}

function fetchInstanceCveGroupAction (app_id) {
  return dispatch => {
    dispatch(request())
    applications.fetchInstanceCveGroup(app_id).then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_INSTANCE_CVE_GROUP_REQUEST }
  }
  function success (instanceCve) {
    return {
      type: applicationConstants.FETCH_INSTANCE_CVE_GROUP_SUCCESS,
      instanceCve,
      app_id
    }
  }
  function failure (error) {
    return { type: applicationConstants.FETCH_INSTANCE_CVE_GROUP_FAILURE, app_id }
  }
}

function fetchInstanceCveDetailAction (app_id, node_id) {
  return dispatch => {
    dispatch(request())
    applications.fetchInstanceCveDetail(app_id, node_id).then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_INSTANCE_CVE_DETAIL_REQUEST }
  }
  function success (instanceCveDetail) {
    return {
      type: applicationConstants.FETCH_INSTANCE_CVE_DETAIL_SUCCESS,
      instanceCveDetail,
      app_id, node_id
    }
  }
  function failure (error) {
    return { type: applicationConstants.FETCH_INSTANCE_CVE_DETAIL_FAILURE, app_id, node_id }
  }
}

function fetchSGAssetsAction (app_id) {
  return dispatch => {
    dispatch(request())
    applications.fetchSGAssets(app_id).then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_SG_ASSETS_REQUEST }
  }
  function success (sgAssets) {
    return {
      type: applicationConstants.FETCH_SG_ASSETS_SUCCESS,
      sgAssets,
      app_id
    }
  }
  function failure (error) {
    return { type: applicationConstants.FETCH_SG_ASSETS_FAILURE, app_id }
  }
}

function fetchNetworkAssetsAction (app_id) {
  return dispatch => {
    dispatch(request())
    applications.fetchNetworkAssets(app_id).then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_NETWORK_ASSETS_REQUEST }
  }
  function success (networkAssets) {
    return {
      type: applicationConstants.FETCH_NETWORK_ASSETS_SUCCESS,
      networkAssets,
      app_id
    }
  }
  function failure (error) {
    return { type: applicationConstants.FETCH_NETWORK_ASSETS_FAILURE, app_id }
  }
}


function fetchGLogsAction (period) {
  return dispatch => {
    dispatch(request())
    applications.fetchGLogs(period).then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_GOOGLE_LOGS_REQUEST }
  }
  function success (logs) {
    return {
      type: applicationConstants.FETCH_GOOGLE_LOGS_SUCCESS, logs, org_id: getCurrentOrganization()
    }
  }
  function failure (error) {
    return { type: applicationConstants.FETCH_GOOGLE_LOGS_FAILURE, org_id: getCurrentOrganization() }
  }
}

function fetchTopAttackPathsAction () {
  return dispatch => {
    dispatch(request())
    applications.fetchTopAttackPaths().then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_TOP_ATTACK_PATHS_REQUEST }
  }
  function success (payload) {
    return {
      type: applicationConstants.FETCH_TOP_ATTACK_PATHS_SUCCESS,
      payload,
      org_id: getCurrentOrganization()
    }
  }
  function failure (error) {
    return {
      type: applicationConstants.FETCH_TOP_ATTACK_PATHS_FAILURE,
      org_id: getCurrentOrganization()
    }
  }
}

function fetchTopInfrasAction () {
  return dispatch => {
    dispatch(request())
    applications.fetchTopInfras().then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_TOP_INFRAS_REQUEST }
  }
  function success (payload) {
    return {
      type: applicationConstants.FETCH_TOP_INFRAS_SUCCESS,
      payload,
      org_id: getCurrentOrganization()
    }
  }
  function failure (error) {
    return {
      type: applicationConstants.FETCH_TOP_INFRAS_FAILURE,
      org_id: getCurrentOrganization()
    }
  }
}

function fetchTopNoncompliancesAction () {
  return dispatch => {
    dispatch(request())
    applications.fetchTopNoncompliances().then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_TOP_NONCOMPLIANCES_REQUEST }
  }
  function success (payload) {
    return {
      type: applicationConstants.FETCH_TOP_NONCOMPLIANCES_SUCCESS,
      payload,
      org_id: getCurrentOrganization()
    }
  }
  function failure (error) {
    return {
      type: applicationConstants.FETCH_TOP_NONCOMPLIANCES_FAILURE,
      org_id: getCurrentOrganization()
    }
  }
}

function fetchTopIdentityAccessAction () {
  return dispatch => {
    dispatch(request())
    applications.fetchTopIdentityAccess().then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_TOP_IDENTITY_ACCESS_REQUEST }
  }
  function success (payload) {
    return {
      type: applicationConstants.FETCH_TOP_IDENTITY_ACCESS_SUCCESS,
      payload,
      org_id: getCurrentOrganization()
    }
  }
  function failure (error) {
    return {
      type: applicationConstants.FETCH_TOP_IDENTITY_ACCESS_FAILURE,
      org_id: getCurrentOrganization()
    }
  }
}

function fetchTopContainersAction () {
  return dispatch => {
    dispatch(request())
    applications.fetchTopContainers().then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_TOP_CONTAINERS_REQUEST }
  }
  function success (payload) {
    return {
      type: applicationConstants.FETCH_TOP_CONTAINERS_SUCCESS,
      payload,
      org_id: getCurrentOrganization()
    }
  }
  function failure (error) {
    return {
      type: applicationConstants.FETCH_TOP_CONTAINERS_FAILURE,
      org_id: getCurrentOrganization()
    }
  }
}

function fetchTopClustersAction () {
  return dispatch => {
    dispatch(request())
    applications.fetchTopClusters().then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_TOP_CLUSTERS_REQUEST }
  }
  function success (payload) {
    return {
      type: applicationConstants.FETCH_TOP_CLUSTERS_SUCCESS,
      payload,
      org_id: getCurrentOrganization()
    }
  }
  function failure (error) {
    return {
      type: applicationConstants.FETCH_TOP_CLUSTERS_FAILURE,
      org_id: getCurrentOrganization()
    }
  }
}

function fetchTopDataSecurityAction () {
  return dispatch => {
    dispatch(request())
    applications.fetchTopDataSecurity().then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_TOP_DATA_SECURITY_REQUEST }
  }
  function success (payload) {
    return {
      type: applicationConstants.FETCH_TOP_DATA_SECURITY_SUCCESS,
      payload,
      org_id: getCurrentOrganization()
    }
  }
  function failure (error) {
    return {
      type: applicationConstants.FETCH_TOP_DATA_SECURITY_FAILURE,
      org_id: getCurrentOrganization()
    }
  }
}

function fetchTopPublicExposureAction () {
  return dispatch => {
    dispatch(request())
    applications.fetchTopPublicExposure().then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_TOP_PUBLIC_EXPOSURE_REQUEST }
  }
  function success (payload) {
    return {
      type: applicationConstants.FETCH_TOP_PUBLIC_EXPOSURE_SUCCESS,
      payload,
      org_id: getCurrentOrganization()
    }
  }
  function failure (error) {
    return {
      type: applicationConstants.FETCH_TOP_PUBLIC_EXPOSURE_FAILURE,
      org_id: getCurrentOrganization()
    }
  }
}

function fetchTopSoftwareUpdateAction () {
  return dispatch => {
    dispatch(request())
    applications.fetchTopSoftwareUpdate().then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_TOP_SOFTWARE_UPDATE_REQUEST }
  }
  function success (payload) {
    return {
      type: applicationConstants.FETCH_TOP_SOFTWARE_UPDATE_SUCCESS,
      payload,
      org_id: getCurrentOrganization()
    }
  }
  function failure (error) {
    return {
      type: applicationConstants.FETCH_TOP_SOFTWARE_UPDATE_FAILURE,
      org_id: getCurrentOrganization()
    }
  }
}

function fetchPentestingConfigurationsAction () {
  return dispatch => {
    dispatch(request())
    applications.fetchPentestingConfigurations().then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_PENTESTING_CONFIGURATIONS_REQUEST }
  }
  function success (payload) {
    return {
      type: applicationConstants.FETCH_PENTESTING_CONFIGURATIONS_SUCCESS,
      payload, org_id: getCurrentOrganization()
    }
  }
  function failure (error) {
    return {
      type: applicationConstants.FETCH_PENTESTING_CONFIGURATIONS_FAILURE,
      org_id: getCurrentOrganization()
    }
  }
}

function fetchSelectedPentestingConfigurationAction(id) {
  return dispatch => {
    dispatch(request())
    applications.fetchPentestingConfiguration().then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_PENTESTING_CONFIGURATION_REQUEST }
  }
  function success (payload) {
    return {
      type: applicationConstants.FETCH_PENTESTING_CONFIGURATION_SUCCESS,
      payload, org_id: getCurrentOrganization()
    }
  }
  function failure (error) {
    return {
      type: applicationConstants.FETCH_PENTESTING_CONFIGURATION_FAILURE,
      org_id: getCurrentOrganization()
    }
  }
}

function setSelectedPentestingConfigurationAction(id) {
  return dispatch => {
    dispatch(request())
    applications.setSelectedPentestingConfiguration(id).then(
      res => {
        dispatch(
          success({
            custom: res?.[getCurrentOrganization()]
          })
        )
        showAlert(
          'success',
          'Success',
          `Successfully set custom configuration with ${id} configuration`
        )
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.SET_SELECTED_PENTESTING_CONFIGURATION_REQUEST }
  }
  function success (result) {
    return {
      type: applicationConstants.SET_SELECTED_PENTESTING_CONFIGURATION_SUCCESS,
      id,
      org_id: getCurrentOrganization()
    }
  }
  function failure (error) {
    return {
      type: applicationConstants.SET_SELECTED_PENTESTING_CONFIGURATION_FAILURE,
      org_id: getCurrentOrganization()
    }
  }
}


function setPentestingConfigurationAction(content) {
  return dispatch => {
    dispatch(request())

    applications.setPentestingConfigurationByContent(content).then(
      res => {
        dispatch(success(res))
        showAlert('success', 'Success', `Successfully set custom configuration`)
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.SET_PENTESTING_CONFIGURATION_REQUEST }
  }
  function success (result) {
    return {
      type: applicationConstants.SET_PENTESTING_CONFIGURATION_SUCCESS,
      result,
      org_id: getCurrentOrganization()
    }
  }
  function failure (error) {
    return {
      type: applicationConstants.SET_PENTESTING_CONFIGURATION_FAILURE,
      org_id: getCurrentOrganization()
    }
  }
}

function fetchWorkspacesAction() {
  return dispatch => {
    dispatch(request())

    applications.fetchWorkspaces().then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_WORKSPACES_REQUEST }
  }
  function success (result) {
    return {
      type: applicationConstants.FETCH_WORKSPACES_SUCCESS,
      result,
    }
  }
  function failure (error) {
    return {
      type: applicationConstants.FETCH_WORKSPACES_FAILURE,
    }
  }
}

function fetchWorkspaceAction(id) {
  return dispatch => {
    dispatch(request())

    applications.fetchWorkspace(id).then(
      res => {
        dispatch(success(res))
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
      }
    )
  }

  function request () {
    return { type: applicationConstants.FETCH_WORKSPACE_REQUEST }
  }
  function success (result) {
    return {
      type: applicationConstants.FETCH_WORKSPACE_SUCCESS,
      result,
    }
  }
  function failure (error) {
    return {
      type: applicationConstants.FETCH_WORKSPACE_FAILURE,
    }
  }
}

function createWorkspaceAction(payload) {
  return dispatch => {
    dispatch(request())

    applications.createWorkspace(payload).then(
      res => {
        dispatch(success(res))
        dispatch(fetchWorkspacesAction())
        showAlert('success', 'Success', `Successfully created workspace`)
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
        showAlert('error', 'Failure', `Failed to create workspace`)

      }
    )
  }

  function request () {
    return { type: applicationConstants.CREATE_WORKSPACE_REQUEST }
  }
  function success (result) {
    return {
      type: applicationConstants.CREATE_WORKSPACE_SUCCESS
    }
  }
  function failure (error) {
    return {
      type: applicationConstants.CREATE_WORKSPACE_FAILURE,
    }
  }
}

function updateWorkspaceAction(id, payload) {
  return dispatch => {
    dispatch(request())

    applications.updateWorkspace(id, payload).then(
      res => {
        dispatch(success(res))
        dispatch(fetchWorkspacesAction())
        showAlert('success', 'Success', `Successfully updated workspace`)
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
        showAlert('error', 'Failure', `Failed to update workspace`)
      }
    )
  }

  function request () {
    return { type: applicationConstants.UPDATE_WORKSPACE_REQUEST }
  }
  function success (result) {
    return {
      type: applicationConstants.UPDATE_WORKSPACE_SUCCESS
    }
  }
  function failure (error) {
    return {
      type: applicationConstants.UPDATE_WORKSPACE_FAILURE,
    }
  }
}

function deleteWorkspaceAction(id) {
  return dispatch => {
    dispatch(request())

    applications.deleteWorkspace(id).then(
      res => {
        if(res) {
          dispatch(success())
          dispatch(fetchWorkspacesAction())
          showAlert('success', 'Success', `Successfully deleted workspace`)
        } else {
          dispatch(failure())
          showAlert('error', 'Failure', `Failed to delete workspace`)
        }
      },
      error => {
        error && dispatch(authAction.setTokenExpired(error, failure))
        showAlert('error', 'Failure', `Failed to delete workspace`)
      }
    )
  }

  function request () {
    return { type: applicationConstants.DELETE_WORKSPACE_REQUEST }
  }
  function success () {
    return {
      type: applicationConstants.DELETE_WORKSPACE_SUCCESS
    }
  }
  function failure () {
    return {
      type: applicationConstants.DELETE_WORKSPACE_FAILURE,
    }
  }
}

function clearCRUDWorkspaceFlagAction() {
  return dispatch => {
    dispatch({
      type: applicationConstants.CLEAR_CREATE_UPDATE_WORKSPACE_FLAG
    })
  }
}
