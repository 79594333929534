import React from 'react'
import Page from "../../components/PageLayout/Page";
import ManageLayout from "./manage/ManageLayout";

const Manage = (props) => {
  return (<div>
    <Page>
      <ManageLayout />
    </Page>
  </div>)
}

export default Manage;