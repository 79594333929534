export function authHeader() {
  // return authorization header with jwt token
  let token = localStorage.getItem("user_token");
  // let token = "OkhrWc8myVM7JurX3PoiGoebQW9D1WNwloMx6PMVpsK4bLeDXxXi2ZaOVI2TetpOoaN5hFp1VCX0N9JRqYSF4EIrAKr7jSG84q6omxgjOt03rh70CAqNxLWtsUgWr65u9XR/63gdXYbgCS8w3SimX5piDOud7Y6pm8MUS24GxRIQtyyOn4y4gZi4weOs/9cXZTFHySEJmANzDE7aqRr+lHzRg6UvoCiVprOuvwkAqsA=";

  if (token) {
    return {
      "user-token": token,
      "Content-Type": "application/json",
    };
  } else {
    return {};
  }
}
