import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import StyledTypo from "../../../../components/Typo/StyledTypo";
import { CardViewContainer, CardViewItem } from "../../home/ProviderDashboard";
import GradientProgressBar from "../../../../components/GradientProgressBar/GradientProgressBar";
import { aggregatedData, NonComplianceStatusColor, infrastructureType } from "../../../../helpers/constants";
import { ReactComponent as AssetsMenuIcon } from '../../../../assets/icons/assets-menu-icon.svg'


const ClusterCards = (props) => {
    const { clusters } = props;
    const history = useHistory();

    const [clusterCards, setClusterCards] = useState([]);

    useEffect(() => {
        setClusterCards([
            ...(clusters ?? [])
            .map(cluster => ({
                id: cluster.cluster_id,
                label: cluster.cluster_name, 
                category: infrastructureType.category, 
                logo: infrastructureType.logo?.[cluster?.platform ?? 'aws'](30),
                status: cluster.summary
            }))
        ]);

    }, [clusters])

    return (
        <div className="w-100 mt-3">
            <CardViewContainer>
                {clusterCards.map((cluster) => (
                <CardViewItem
                    {...NonComplianceStatusColor}
                    onClick={() => history.push(`/kspm/${cluster.id}`)}
                >
                    <div className="text-center left-side">
                        {cluster.logo}

                        <StyledTypo
                            className="mt-2"
                            color="#505050"
                            size={0.8}
                            line={1.4}
                            letter="0"
                            family="Roboto"
                            fontWeight={400}
                        >
                            {aggregatedData(cluster?.status?.resource_counters) !== 0 ? (cluster?.status?.resource_counters?.passedResources / aggregatedData(cluster?.status?.resource_counters) * 100).toFixed() : 0}
                            %
                        </StyledTypo>
                    </div>
                    <div className="vertical-divider" />
                    <div className="right-side">
                        <div className="d-flex justify-content-between ">
                            <StyledTypo
                                color="#505050"
                                size={0.8}
                                line={1.4}
                                letter="0"
                                family="Roboto"
                                fontWeight={300}
                            >
                                {cluster?.label}
                            </StyledTypo>
                            <div className="status">
                                {Object.keys(NonComplianceStatusColor).map(
                                    (item) => (
                                    <div className={`item ${item}`}>
                                        <StyledTypo
                                        color="#687089"
                                        size={0.625}
                                        line={1.4}
                                        letter="0"
                                        family="Roboto"
                                        fontWeight={300}
                                        >
                                         {cluster?.status?.resource_severity_counters?.[`${item.toLowerCase()}Severity`] ?? 0}
                                        </StyledTypo>
                                    </div>
                                    )
                                )}
                            </div>
                        </div>
                        <GradientProgressBar
                            color="#ececec70" color2="#adadad"
                            value={aggregatedData(cluster?.status?.resource_counters) !== 0 ? (cluster?.status?.resource_counters?.passedResources / aggregatedData(cluster?.status?.resource_counters) * 100).toFixed() : 0}
                            maxValue="100"
                            rotation="90"
                        />
                        <div className="d-flex  mt-3">
                            <div className="d-flex">
                            <AssetsMenuIcon
                                fill="#505050"
                                width={20}
                                height={20}
                            />
                            <StyledTypo
                                color="#505050"
                                size={0.8}
                                line={1.4}
                                letter="0"
                                family="Roboto"
                                fontWeight={300}
                                className="mt-1 ml-2"
                                title="Resource count: Non-Compliant / Total"
                            >
                                {cluster?.status?.resource_counters?.passedResources} /{" "}
                                {aggregatedData(cluster?.status?.resource_counters)}
                            </StyledTypo>
                            </div>
                        </div>
                    </div>
                    {/* <div className="status-mark" /> */}
                </CardViewItem>
                ))}
            </CardViewContainer>
        </div>
    )
}

export default ClusterCards;
