export const iacConstants = {
  FETCH_IAC_SCAN_REPORT_REQUEST: "FETCH_IAC_SCAN_REPORT_REQUEST",
  FETCH_IAC_SCAN_REPORT_SUCCESS: "FETCH_IAC_SCAN_REPORT_SUCCESS",
  FETCH_IAC_SCAN_REPORT_FAILURE: "FETCH_IAC_SCAN_REPORT_FAILURE",

  FETCH_IAC_PROJECTS_REQUEST: "FETCH_IAC_PROJECTS_REQUEST",
  FETCH_IAC_PROJECTS_SUCCESS: "FETCH_IAC_PROJECTS_SUCCESS",
  FETCH_IAC_PROJECTS_FAILURE: "FETCH_IAC_PROJECTS_FAILURE",

  IAC_CREATE_PROJECT_SUCCESS: "IAC_CREATE_PROJECT_SUCCESS",
  IAC_CREATE_PROJECT_FAILURE: "IAC_CREATE_PROJECT_FAILURE",

  IAC_DELETE_PROJECT_SUCCESS: "IAC_DELETE_PROJECT_SUCCESS",
  IAC_DELETE_PROJECT_FAILURE: "IAC_DELETE_PROJECT_FAILURE",

  FETCH_IAC_REMEDIATION_SUCCESS: "FETCH_IAC_REMEDIATION_SUCCESS",
  FETCH_IAC_REMEDIATION_REQUEST: "FETCH_IAC_REMEDIATION_REQUEST",
  FETCH_IAC_REMEDIATION_FAILURE: "FETCH_IAC_REMEDIATION_FAILURE",

  CLEAR_CREATE_FLAG: "CLEAR_CREATE_FLAG",
};
