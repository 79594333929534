import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import StyledTypo from "../../../../components/Typo/StyledTypo";
import { iac_status_color } from "../../../../helpers/constants";
import { applicationActions, iacActions } from "../../../../redux/actions";
import moment from "moment";
import { Col, Row } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import { useHistory } from "react-router-dom";
import GradientProgressBar from "../../../../components/GradientProgressBar/GradientProgressBar";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const PanelWrapper = styled.div`
  padding: 10px;
`;

const PanelBody = styled.div`
  padding: 10px;
`;

const StatsTabWrapper = styled.div`
  padding: 10px;
`;

const RemediationOverviewWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 20px 0px;
`;

const RemediationOverviewBlock = styled.div`
  padding: 15px 5px;
  border-radius: 5px;
  // background-color: ${(props) => props.color};
  border: 1px solid #eee;
  border-left: 5px solid ${(props) => (props.color ? props.color : "#ccc")};
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  // box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 2px, rgba(0, 0, 0, 0.2) 2px 2px 2px;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
  cursor: pointer;
`;

const SummaryWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  word-break: break-all;
`;

const SummaryItemContainer = styled.div`
  border-top: ${(props) => (props.idx === 0 ? "none" : "1px solid #f1f1f1")};
  padding: 10px;
  cursor: ${(props) => (props.cursor ? props.cursor : "default")};
  ${props => props.hover && `
    &:hover {
      background-color: #f7f7f7;
    }
  `}
`;

const StyledStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 70px;

  margin-right: ${(props) => (props.mr ? props.mr : 10)}px;

  .mark {
    width: ${(props) => (props.size ? props.size : 12)}px;
    height: ${(props) => (props.size ? props.size : 12)}px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    // box-shadow: 0 8px 6px -3px ${(props) => props.color};
  }
`;

const SummaryItem = ({ title, value, type }) => {
  return (
    <div className="d-flex align-items-center">
      <StyledTypo className="table-label">{title}:</StyledTypo>
      <StyledTypo className="ml-3 table-label">
        {value
          ? type === "date"
            ? moment(value).format("YYYY-MM-DD HH:mm:ss")
            : value
          : " - "}
      </StyledTypo>
    </div>
  );
};

const SummaryPanel = ({
  report: { report },
  project,
  deleteIacProjectAction,
  onLink,
}) => {
  const [categoryStatusData, setCategoryStatusData] = useState([]);
  const [platformStatusData, setPlatformStatusData] = useState([]);

  const summaryItems = [
    [
      { key: "total_counter", label: "Issues Count", type: "string" },
      { key: "queries_total", label: "Total Checks", type: "string" },
    ],
    [
      { key: "files_parsed", label: "Files Parsed", type: "string" },
      { key: "files_scanned", label: "Files Scanned", type: "string" },
    ],
    [
      { key: "lines_parsed", label: "Lines Parsed", type: "string" },
      { key: "lines_scanned", label: "Lines Scanned", type: "string" },
    ],
    [
      { key: "start", label: "Start Date", type: "date" },
      { key: "end", label: "End Date", type: "date" },
    ],
  ];

  const projectSummary = [
    [
      { key: "git_url", label: "Git URL" },
      { key: "branch_name", label: "Git Branch" },
    ],
    [
      { key: "csp", label: "Cloud Service Provider" },
      { key: "platform", label: "Platform", type: "array" },
    ],
    [
      { key: "created_at", label: "Created Date", type: "date" },
      { key: "last_scanned_date", label: "Update Date", type: "date" },
    ],
  ];

  const categoryStatusLayout = [
    {
      label: "Category",
      width: 2,
      subLabel: null,
      key: "status_name",
    },
    {
      label: "Results",
      width: 4,
      subLabel: "Result",
      key: "result",
    },
    {
      label: "Total",
      width: 1,
      subLabel: null,
      key: "fail",
    },
    { label: "Spread", width: 2, subLabel: null, key: "overall" },
  ];

  const platformStatusLayout = [
    {
      label: "Platform",
      width: 2,
      subLabel: null,
      key: "status_name",
    },
    {
      label: "Results",
      width: 4,
      subLabel: "Result",
      key: "result",
    },
    {
      label: "Total",
      width: 1,
      subLabel: null,
      key: "fail",
    },
    { label: "Spread", width: 2, subLabel: null, key: "overall" },
  ];

  const history = useHistory();

  const getSeverityTotal = () => {
    let result = report?.severity_counters?.["HIGH"] + report?.severity_counters?.["MEDIUM"] + report?.severity_counters?.["LOW"] + report?.severity_counters?.["INFO"];
    return isNaN(result) ? 0 : result;
  }

  const getSeverityPercentage = (status) => {
    let result = (Math.round(100 * (report?.severity_counters?.[status] ?? 0)) / (report?.severity_counters?.["HIGH"] + report?.severity_counters?.["MEDIUM"] + report?.severity_counters?.["LOW"] + report?.severity_counters?.["INFO"]) ?? 1).toFixed(0);
    return isNaN(result) ? 0 : result;
  }

  const deleteConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>{`You want to delete IaC project named ${project?.display_name}?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                deleteIacProjectAction(project);
                onClose();
                history.push("/iac");
              }}
            >
              Confirm
            </button>
          </div>
        );
      },
    });
  };

  useEffect(() => {
    if (report && report?.queries) {
      const query_status = (report?.queries ?? []).reduce((acc, cur) => {
        const temp = {
          ...acc,
          [cur?.category]: {
            ...acc?.[cur?.category],
            [cur?.severity?.toLowerCase()]:
              (acc?.[cur?.category]?.[cur?.severity?.toLowerCase()] ?? 0) +
              (cur?.files ?? []).length,
          },
        };
        return temp;
      }, {});

      const platform_query_status = (report?.queries ?? []).reduce((acc, cur) => {
        const temp = {
          ...acc,
          [cur?.platform]: {
            ...acc?.[cur?.platform],
            [cur?.severity?.toLowerCase()]:
              (acc?.[cur?.platform]?.[cur?.severity?.toLowerCase()] ?? 0) +
              (cur?.files ?? []).length,
          },
        };
        return temp;
      }, {});
      const total_counter = report?.["total_counter"];
      setCategoryStatusData(
        Object.keys(query_status).map((category) => ({
          status_name: category,
          total: total_counter,
          count: (query_status?.[category]?.high ?? 0) + (query_status?.[category]?.medium ?? 0) + (query_status?.[category]?.low ?? 0) + (query_status?.[category]?.info ?? 0),
          result: {
            high: query_status?.[category]?.high ?? 0,
            medium: query_status?.[category]?.medium ?? 0,
            low: query_status?.[category]?.low ?? 0,
            info: query_status?.[category]?.info ?? 0,
          },
        }))
      );
      setPlatformStatusData(
        Object.keys(platform_query_status).map((platform) => ({
          status_name: platform,
          total: total_counter,
          count: (platform_query_status?.[platform]?.high ?? 0) + (platform_query_status?.[platform]?.medium ?? 0) + (platform_query_status?.[platform]?.low ?? 0) + (platform_query_status?.[platform]?.info ?? 0),
          result: {
            high: platform_query_status?.[platform]?.high ?? 0,
            medium: platform_query_status?.[platform]?.medium ?? 0,
            low: platform_query_status?.[platform]?.low ?? 0,
            info: platform_query_status?.[platform]?.info ?? 0,
          },
        }))
      );
    }
  }, [report]);

  return (
    <PanelWrapper>
      <PanelBody>
        <div className="mb-1">
          {projectSummary.map((item, idx) => (
            <SummaryItemContainer key={idx} idx={idx}>
              <Row className="align-items-center">
                {item.map((each) => (
                  <Col md={6}>
                    <Row className="p-0">
                      <Col md={4}>
                        <StyledTypo className="text-justify label table-label">
                          {each.label}
                        </StyledTypo>
                      </Col>
                      <Col md={8}>
                        <StyledTypo className="text-justify table-label">
                          {project?.[each.key]
                            ? each.type === "date"
                              ? moment(project?.[each.key]).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                              : each.type === "array"
                                ? project?.[each.key].join(", ")
                                : each.type === "upper_string"
                                  ? project?.[each.key].toUpperCase()
                                  : project?.[each.key]
                            : "---"}
                        </StyledTypo>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </SummaryItemContainer>
          ))}
        </div>
        <RemediationOverviewWrapper>
          {Object.keys(iac_status_color).map((status) => (
            <RemediationOverviewBlock
              color={iac_status_color[status].color}
              onClick={() =>
                onLink({
                  type: "severity",
                  data: status.toUpperCase(),
                })
              }
            >
              <StyledTypo
                className="ml-3 severity-text"
                size={0.75}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                {status.toLowerCase()}
              </StyledTypo>
              <div className="d-flex justify-content-between align-items-end pt-3">
                <StyledTypo
                  className="text-center ml-3"
                  size={1.7}
                  line={1}
                  letter="0"
                  family="Roboto"
                  fontWeight={400}
                >
                  {getSeverityPercentage(status)}{" "}%
                </StyledTypo>
                <div className="d-flex justify-content-center align-items-center">
                  <StyledTypo
                    className="mr-2"
                    size={0.75}
                    line={1}
                    letter="0"
                    family="Roboto"
                    fontWeight={400}
                  >
                    {report?.severity_counters?.[status] ?? 0} /{" "}{getSeverityTotal()}
                  </StyledTypo>
                </div>
              </div>
            </RemediationOverviewBlock>
          ))}
        </RemediationOverviewWrapper>
        <StatsTabWrapper>
          <Tabs>
            <TabList>
              <Tab>
                <StyledTypo line="1.8"
                  size={0.8}
                  family="Poppins"
                  fontWeight={300}>
                  Scan Statistics
                </StyledTypo>
              </Tab>
              <Tab>
                <StyledTypo line="1.8"
                  size={0.8}
                  family="Poppins"
                  fontWeight={300}>
                  Status by Category
                </StyledTypo>
              </Tab>
              <Tab>
                <StyledTypo line="1.8"
                  size={0.8}
                  family="Poppins"
                  fontWeight={300}>
                  Status by Platform
                </StyledTypo>
              </Tab>
            </TabList>
            <TabPanel>
              <div className="mb-1">
                <SummaryWrapper>
                  {summaryItems.map((item, idx) => (
                    <SummaryItemContainer key={idx} idx={idx}>
                      <Row className="align-items-center">
                        {item.map((each) => (
                          <Col md={6}>
                            <Row className="p-0">
                              <Col md={4}>
                                <StyledTypo className="text-justify label table-label">
                                  {each.label}
                                </StyledTypo>
                              </Col>
                              <Col md={8}>
                                <StyledTypo className="text-justify table-label">
                                  {each.type === "date"
                                    ? moment(report?.[each.key]).format(
                                      "YYYY-MM-DD HH:mm:ss")
                                    : report?.[each.key]}
                                </StyledTypo>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                      </Row>
                    </SummaryItemContainer>
                  ))}
                </SummaryWrapper>
              </div>
            </TabPanel>
            <TabPanel>
              <SummaryWrapper>
                <Row className="p-2 align-items-center">
                  {categoryStatusLayout.map((status) => (
                    <Col md={status.width}>
                      <StyledTypo className="text-justify table-label label">
                        {status.label}
                      </StyledTypo>
                      {status.subLabel === "Result" && (
                        <div className="d-flex align-items-center flex-wrap mt-2">
                          {["High", "Medium", "Low", "Info"].map((key) => (
                            <StyledStatusWrapper>
                              <StyledTypo
                                color="#687089"
                                size={0.6}
                                line={1}
                                letter="0"
                                family="Roboto"
                                fontWeight={300}
                              >
                                {key}
                              </StyledTypo>
                            </StyledStatusWrapper>
                          ))}
                        </div>
                      )}
                      {status.subLabel === "total" && (
                        <div className="d-flex align-items-center flex-wrap mt-2">
                          <StyledStatusWrapper>
                            <StyledTypo
                              color="#687089"
                              size={0.6}
                              line={1}
                              letter="0"
                              family="Roboto"
                              fontWeight={300}
                            >
                              TOTAL: {categoryStatusData?.reduce((acc, item) => {
                                acc = acc + item.count;
                                return acc;
                              }, 0)}
                            </StyledTypo>
                          </StyledStatusWrapper>
                        </div>
                      )}
                    </Col>
                  ))}
                </Row>
                {categoryStatusData.map((data) => (
                  <SummaryItemContainer
                    hover
                    cursor="pointer"
                    onClick={() =>
                      onLink({
                        type: "category",
                        data: data?.status_name,
                      })
                    }
                  >
                    <Row className="d-flex align-items-center">
                      {categoryStatusLayout.map((status) => (
                        <Col md={status.width}>
                          {status.key === "pass" && (<StyledTypo className="text-justify table-label">{data?.["total"] - data?.["count"]}</StyledTypo>)}
                          {status.key === "fail" && (<StyledTypo className="text-justify table-label">{data?.["count"]}</StyledTypo>)}
                          {status.key === "total" && (<StyledTypo className="text-justify table-label">{data?.["total"]}</StyledTypo>)}
                          {status.key === "overall" && (<GradientProgressBar
                            color="#bdbdbd"
                            color2="#bdbdbd"
                            value={(data?.["count"] * 100) / data?.["total"] ?? 0}
                            maxValue="100"
                            rotation="90"
                            showNumber
                          />)}
                          {status.key === "status_name" && (
                            <div className="d-flex align-items-center">
                              <StyledTypo className="text-justify table-label">
                                {data[status.key]}
                              </StyledTypo>
                            </div>
                          )}
                          {status.key === "result" && (
                            <div className="d-flex align-items-center flex-wrap">
                              {Object.keys(iac_status_color).map((key) => (
                                <StyledStatusWrapper
                                  color={iac_status_color[key].color}
                                >
                                  <div className="mark" />
                                  <StyledTypo
                                    size={0.9}
                                    line={1.4}
                                    letter="0"
                                    family="Roboto"
                                    fontWeight={300}
                                  >
                                    {data?.[status.key]?.[key.toLowerCase()] ?? 0}
                                  </StyledTypo>
                                </StyledStatusWrapper>
                              ))}
                            </div>
                          )}
                        </Col>
                      ))}
                    </Row>
                  </SummaryItemContainer>
                ))}
              </SummaryWrapper>
            </TabPanel>
            <TabPanel>
              <SummaryWrapper>
                <Row className="p-2 align-items-center">
                  {platformStatusLayout.map((status) => (
                    <Col md={status.width}>
                      <StyledTypo className="text-justify table-label label">
                        {status.label}
                      </StyledTypo>
                      {status.subLabel === "Result" && (
                        <div className="d-flex align-items-center flex-wrap mt-2">
                          {["High", "Medium", "Low", "Info"].map((key) => (
                            <StyledStatusWrapper>
                              <StyledTypo
                                color="#687089"
                                size={0.6}
                                line={1}
                                letter="0"
                                family="Roboto"
                                fontWeight={300}
                              >
                                {key}
                              </StyledTypo>
                            </StyledStatusWrapper>
                          ))}
                        </div>
                      )}
                    </Col>
                  ))}
                </Row>
                {platformStatusData.map((data) => (
                  <SummaryItemContainer
                    hover
                    cursor="pointer"
                    onClick={() =>
                      onLink({
                        type: "platform",
                        data: data?.status_name,
                      })
                    }
                  >
                    <Row className="d-flex align-items-center">
                      {platformStatusLayout.map((status) => (
                        <Col md={status.width}>
                          {status.key === "pass" ? (<StyledTypo className="text-justify table-label">{data?.["total"] - data?.["count"]}</StyledTypo>) : ""}
                          {status.key === "fail" ? (<StyledTypo className="text-justify table-label">{data?.["count"]}</StyledTypo>) : ""}
                          {status.key === "total" ? (<StyledTypo className="text-justify table-label">{data?.["total"]}</StyledTypo>) : ""}
                          {status.key === "overall" ? (<GradientProgressBar
                            color="#bdbdbd"
                            color2="#bdbdbd"
                            value={(data?.["count"] * 100) / data?.["total"] ?? 0}
                            maxValue="100"
                            rotation="90"
                            showNumber
                          />) : ""}
                          {status.key === "status_name" ? (
                            <div className="d-flex align-items-center">
                              <StyledTypo className="text-justify table-label">
                                {data[status.key]}
                              </StyledTypo>
                            </div>
                          ) : ""}
                          {status.key === "result" ? (
                            <div className="d-flex align-items-center flex-wrap">
                              {Object.keys(iac_status_color).map((key) => (
                                <StyledStatusWrapper
                                  color={iac_status_color[key].color}
                                >
                                  <div className="mark" />
                                  <StyledTypo
                                    size={0.9}
                                    line={1.4}
                                    letter="0"
                                    family="Roboto"
                                    fontWeight={300}
                                  >
                                    {data?.[status.key]?.[key.toLowerCase()] ?? 0}
                                  </StyledTypo>
                                </StyledStatusWrapper>
                              ))}
                            </div>
                          ) : ""}
                        </Col>
                      ))}
                    </Row>
                  </SummaryItemContainer>
                ))}
              </SummaryWrapper>
            </TabPanel>
          </Tabs>
        </StatsTabWrapper>
      </PanelBody>
    </PanelWrapper>
  );
};

function mapStateToProps(state) {
  const { report_downloading } = state.applications;
  return {
    report_downloading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    generateReportAction: (appId) =>
      dispatch(applicationActions.generateReportAction(appId)),
    deleteIacProjectAction: (payload) =>
      dispatch(iacActions.deleteIacProjectAction(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryPanel);
