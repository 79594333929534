import moment from "moment";
import React from "react";

const CustomizedAxisTick = (props) => {
  const { x, y, payload, dateStr, showDate, yAxis, summaryData, neg } = props;
  const currentTimestamp = parseInt(
    moment(moment().format("YYYY-MM-DD HH") + ":00:00").format("X")
  );
  if (payload.value === "")
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16}>
          <tspan textAnchor="middle" fill="#fff" x="0">
            {payload.value}
          </tspan>
        </text>
      </g>
    );
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={yAxis ? 2 : 16} dx={yAxis ? -10 : 0}>
        <tspan textAnchor="middle" fill="#505050" x="0" fontSize={14}>
          {summaryData
            ? summaryData[payload.value]?.name?.length > 10
              ? `${summaryData[payload.value]?.name?.substr(0, 10)}...`
              : summaryData[payload.value]?.name
            : neg
            ? `${payload.value > 0 ? "-" : ""}${payload.value}`
            : payload.value}
        </tspan>
        {!yAxis && showDate && (
          <tspan
            textAnchor="middle"
            fill="#50505099"
            x="0"
            dy="20"
            fontSize={12}
          >
            {moment(
              (dateStr
                ? parseInt(dateStr[payload.index])
                : currentTimestamp -
                  3600 * (props.visibleTicksCount - payload.index - 1)) * 1000
            ).format("MMM DD")}
          </tspan>
        )}
        {/* {yAxis && payload.value === 100 && (
          <tspan
            textAnchor="middle"
            fill="#50505099"
            x="-10"
            dy="20"
            fontSize={12}
          >
            %
          </tspan>
        )} */}
      </text>
    </g>
  );
};

export default CustomizedAxisTick;
