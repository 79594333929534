import React, { useEffect } from "react";
import styled from "styled-components";
import AuthBackground from "../partisals/AuthBackground";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const AuthWrapper = styled.div`
  height: 719px;
  width: 1078px;
  margin: calc(50vh - 360px) auto 0;
  /* white */
  background: #fff;
  /* Gray light 2 */

  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  /* box shadow */

  box-shadow: 0 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 20px;
  z-index: 2;
`;

const AuthDashboard = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="dashboard">
      <AuthBackground />
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_SITE_KEY}
        container={{
          parameters: {
            badge: "bottomright", // optional, default undefined
            theme: "dark", // optional, default undefined
          },
        }}
      >
        <AuthWrapper>{props.children}</AuthWrapper>
      </GoogleReCaptchaProvider>
    </div>
  );
};

export default AuthDashboard;
