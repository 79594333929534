import React, { useEffect, useState } from "react";
import Resources from "../Resources";
import styled from "styled-components";
import {
  cspData,
  providerComplianceType,
  dashboardOverviewList,
  NonComplianceStatusColor,
  providerResourceTypes,
  loadingBar,
} from "../../../../helpers/constants";
import { Col, Row } from "reactstrap";
import StyledTypo from "../../../../components/Typo/StyledTypo";
import GradientProgressBar from "../../../../components/GradientProgressBar/GradientProgressBar";
import CubeIcon from "../../../../assets/images/3d-cube.png";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const MainBodyWrapper = styled.div`
  padding: 10px;
`;

const GraphWrapper = styled.div`
  box-sizing: border-box;
  min-height: 424px;
  width: 100%;
  display: flex;
  margin-top: 10px;
`;

const StatsTabWrapper = styled.div`
  padding: 10px;
`;

const RemediationOverviewWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 20px 0px;
`;

const RemediationOverviewBlock = styled.div`
  padding: 15px 5px;
  border-radius: 5px;
  // background-color: ${(props) => props.color};
  border: 1px solid #eee;
  border-left: 5px solid ${(props) => (props.color ? props.color : "#ccc")};
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  // box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 2px, rgba(0, 0, 0, 0.2) 2px 2px 2px;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
  cursor: pointer;
`;

const SummaryItemContainer = styled.div`
  border-top: ${(props) => (props.idx === 0 ? "none" : "1px solid #f1f1f1")};
  padding: 10px;
  cursor: ${(props) => (props.cursor ? props.cursor : "default")};
  ${(props) =>
    props.hover &&
    `
    &:hover {
      background-color: #f7f7f7;
    }
  `}
`;

const OverviewContentWrapper = styled.div`
  width: 100%;
`;

const TotalBlock = styled.div`
  // padding: 10px;
  padding: 15px 5px;
  border-radius: 5px;
  // background-color: ${(props) => (props.color ? props.color : "#fff")};
  width: 100%;
  min-height: 120px;
  border: 1px solid rgba(234, 234, 234, 0.65);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  // filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
  display: flex;
  align-items: center;
  justify-content: space-between;

  .item {
    border-right: 1px solid #eaeaea;
    &.nonborder {
      border: none;
    }
    width: 100%;
    padding: 5px 10px;
  }
`;

const SummaryWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  word-break: break-all;
`;

const StyledStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 65px;

  margin-right: ${(props) => (props.mr ? props.mr : 10)}px;

  .mark {
    width: ${(props) => (props.size ? props.size : 12)}px;
    height: ${(props) => (props.size ? props.size : 12)}px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    // box-shadow: 0 8px 6px -3px ${(props) => props.color};
  }
`;

const InfraSummary = (props) => {
  const {
    selectedApp,
    clusterInfo,
    clusterInfoLoading,
    remediations,
    solution,
    setSelectedFilterType,
    hideActivity,
    type,
    onLink,
  } = props;

  const [overviewCount, setOverviewCount] = useState({});
  const [complianceStatusData, setComplianceStatusData] = useState([]);
  const [resourceStatusData, setResourceStatusData] = useState([]);

  const infraSummary = [
    [
      { key: "platform", label: "Cloud Service Provider" },
      { key: "environment", label: "Environment" },
    ],
    [
      { key: "created_timestamp", label: "Created Date", type: "date" },
      { key: "updated_timestamp", label: "Updated Date", type: "date" },
    ],
  ];

  const complianceStatusLayout = [
    {
      label: "Compliance",
      width: 2,
      subLabel: null,
      key: "compliance_name",
    },
    {
      label: "Results",
      width: 3,
      subLabel: "Result",
      key: "result",
    },
    {
      label: "Non-Compliant",
      width: 1,
      subLabel: null,
      key: "fail",
    },
    {
      label: "Compliant",
      width: 1,
      subLabel: null,
      key: "pass",
    },
    { label: "Compliance Score", width: 2, subLabel: null, key: "overall" },
  ];

  const resourceStatusLayout = [
    {
      label: "Resource",
      width: 2,
      subLabel: null,
      key: "resource_category",
    },
    {
      label: "Results",
      width: 3,
      subLabel: "Result",
      key: "result",
    },
    {
      label: "Non-Compliant",
      width: 1,
      subLabel: null,
      key: "fail",
    },
    {
      label: "Compliant",
      width: 1,
      subLabel: null,
      key: "pass",
    },
    { label: "Compliance Score", width: 2, subLabel: null, key: "overall" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let overviewData = {};
    Object.keys(NonComplianceStatusColor).forEach((key) => {
      overviewData = {
        ...overviewData,
        [key.toLowerCase()]: {
          pass:
            solution.filter(
              (s) =>
                s?.details?.severity?.toLowerCase() === key.toLowerCase() &&
                s.resources?.filter(
                  (r) =>
                    r.status.toLowerCase() === "info" ||
                    r.status.toLowerCase() === "fail"
                )?.length === 0
            )?.length ?? 0,
          total: solution?.length ?? 0,
        },
      };
    });

    overviewData = {
      ...overviewData,
      total: Object.values(overviewData).reduce((acc, cur) => acc + cur, 0),
    };

    const rsc_status_data = solution.reduce((acc, cur) => {
      const data = {
        ...acc,
        [cur?.details?.resource_type]: {
          ...(acc?.[cur?.details?.resource_type] ?? {}),
          [cur?.details?.severity]: {
            pass:
              (acc?.[cur?.details?.resource_type]?.[cur?.details?.severity]
                ?.pass ?? 0) +
              (cur?.resources?.filter(
                (r) =>
                  r.status !== "fail" &&
                  r.status !== "info" &&
                  r.status === "pass"
              )?.length ?? 0),
            total:
              (acc?.[cur?.details?.resource_type]?.[cur?.details?.severity]
                ?.total ?? 0) + (cur?.resources?.length ?? 0),
          },
          total:
            (acc?.[cur?.details?.resource_type]?.total ?? 0) +
            (cur?.resources?.length ?? 0),
          pass:
            (acc?.[cur?.details?.resource_type]?.pass ?? 0) +
            (cur?.resources?.filter(
              (r) =>
                r.status !== "fail" &&
                r.status !== "info" &&
                r.status === "pass"
            )?.length ?? 0),
        },
      };
      return data;
    }, {});

    const platform = selectedApp?.platform ?? "aws";
    const compliance_status_data = Object.values(
      providerComplianceType[platform]
    ).reduce((acc, cur) => {
      const data = {
        ...acc,
        [cur.label]: {
          ...acc?.[cur.label],
          ...Object.keys(NonComplianceStatusColor).reduce((accu, curr) => {
            const severity = curr.toLowerCase();
            const temp = {
              ...accu,
              [severity]: {
                pass:
                  solution.filter(
                    (s) =>
                      s?.details?.severity === severity &&
                      s?.details?.compliance
                        .toLowerCase()
                        .includes(cur.value) &&
                      s?.resources?.filter(
                        (r) => r.status === "fail" || r.status === "info"
                      )?.length === 0
                  )?.length ?? 0,
                total:
                  solution.filter(
                    (s) =>
                      s?.details?.severity === severity &&
                      s?.details?.compliance.toLowerCase().includes(cur.value)
                  )?.length ?? 0,
              },
            };
            return temp;
          }, {}),
          pass:
            solution.filter(
              (s) =>
                s?.details?.compliance.toLowerCase().includes(cur.value) &&
                s?.resources?.filter(
                  (r) => r.status === "fail" || r.status === "info"
                )?.length === 0
            )?.length ?? 0,
          total:
            solution.filter((s) =>
              s?.details?.compliance.toLowerCase().includes(cur.value)
            )?.length ?? 0,
        },
      };
      return data;
    }, {});

    setResourceStatusData(
      Object.keys(rsc_status_data ?? {}).map((type) => ({
        resource_category:
          providerResourceTypes?.[selectedApp?.platform].filter(
            (item) => item.value === type
          )?.[0]?.label ?? type,
        overall: rsc_status_data?.[type]?.total
          ? Math.round(
              (rsc_status_data?.[type]?.pass /
                (rsc_status_data?.[type]?.pass > rsc_status_data?.[type]?.total
                  ? rsc_status_data?.[type]?.pass
                  : rsc_status_data?.[type]?.total)) *
                100
            )
          : 0,
        resource_cnt: `${rsc_status_data?.[type]?.pass}/${
          rsc_status_data?.[type]?.pass > rsc_status_data?.[type]?.total
            ? rsc_status_data?.[type]?.pass
            : rsc_status_data?.[type]?.total
        }`,
        pass: rsc_status_data?.[type]?.pass,
        fail: rsc_status_data?.[type]?.total - rsc_status_data?.[type]?.pass,
        total: rsc_status_data?.[type]?.total,
        result: {
          critical: rsc_status_data?.[type]?.critical ?? { total: 0, pass: 0 },
          medium: rsc_status_data?.[type]?.medium ?? { total: 0, pass: 0 },
          high: rsc_status_data?.[type]?.high ?? { total: 0, pass: 0 },
          low: rsc_status_data?.[type]?.low ?? { total: 0, pass: 0 },
        },
      }))
    );
    setComplianceStatusData(
      Object.keys(compliance_status_data).map((type) => ({
        compliance_name: type,
        compliance_cnt: `${compliance_status_data?.[type]?.pass}/${compliance_status_data?.[type]?.total}`,
        pass: compliance_status_data?.[type]?.pass,
        fail:
          compliance_status_data?.[type]?.total -
          compliance_status_data?.[type]?.pass,
        total: compliance_status_data?.[type]?.total,
        overall: compliance_status_data?.[type]?.total
          ? Math.round(
              (compliance_status_data?.[type]?.pass /
                compliance_status_data?.[type]?.total) *
                100
            )
          : 0,
        result: {
          critical: compliance_status_data?.[type]?.critical ?? {
            total: 0,
            pass: 0,
          },
          medium: compliance_status_data?.[type]?.medium ?? {
            total: 0,
            pass: 0,
          },
          high: compliance_status_data?.[type]?.high ?? { total: 0, pass: 0 },
          low: compliance_status_data?.[type]?.low ?? { total: 0, pass: 0 },
        },
      }))
    );
    // }
    setOverviewCount({ ...overviewData });
  }, [remediations, solution, selectedApp, clusterInfo]);

  const getTotalCount = () => {
    return solution?.length ?? 0;
  };

  const getSeverityTotalCount = (severity) => {
    return (
      solution.filter(
        (s) => s?.details?.severity?.toLowerCase() === severity.toLowerCase()
      )?.length ?? 0
    );
  };

  const getPassCount = () => {
    return (
      solution.filter(
        (s) =>
          s.resources?.filter(
            (r) =>
              r.status.toLowerCase() === "fail" ||
              r.status.toLowerCase() === "info"
          )?.length === 0
      )?.length ?? 0
    );
  };

  const getSeverityPassCount = (severity) => {
    return (
      solution.filter(
        (s) =>
          s?.details?.severity?.toLowerCase() === severity.toLowerCase() &&
          s.resources?.filter(
            (r) =>
              r.status.toLowerCase() === "fail" ||
              r.status.toLowerCase() === "info"
          )?.length === 0
      )?.length ?? 0
    );
  };

  const getFailCount = (severity) => {
    return getTotalCount() - getPassCount();
  };

  const getSeverityFailCount = (severity) => {
    return getSeverityTotalCount(severity) - getSeverityPassCount(severity);
  };

  return (
    <MainBodyWrapper>
      {type === "summary" ? (
        solution.length ? (
          <>
            <div className="mb-1">
              {infraSummary.map((item, idx) => (
                <SummaryItemContainer key={idx} idx={idx}>
                  <Row className="align-items-center">
                    {item.map((each) => (
                      <Col md={6}>
                        <Row className="p-0 align-items-center">
                          <Col md={4}>
                            <StyledTypo className="text-justify label table-label">
                              {each.label}
                            </StyledTypo>
                          </Col>
                          <Col md={8}>
                            <StyledTypo className="text-justify table-label">
                              {selectedApp?.[each.key]
                                ? each.type === "date"
                                  ? moment(selectedApp?.[each.key]).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    )
                                  : each.type === "array"
                                  ? selectedApp?.[each.key].join(", ")
                                  : each.type === "upper_string"
                                  ? selectedApp?.[each.key].toUpperCase()
                                  : each.key === "platform"
                                  ? cspData
                                      .filter(
                                        (csp) =>
                                          csp.key === selectedApp?.[each.key]
                                      )?.[0]
                                      ?.logo(30)
                                  : selectedApp?.[each.key]
                                : "---"}
                            </StyledTypo>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </SummaryItemContainer>
              ))}
            </div>
            <div>
              <OverviewContentWrapper>
                <TotalBlock>
                  {dashboardOverviewList
                    .filter((each) => each.key !== "total")
                    .map((each) => (
                      <div
                        className={`item ${!each.border && "nonborder"}`}
                        key={each.key}
                      >
                        <StyledTypo
                          className="ml-3"
                          color="#505050"
                          size={0.8}
                          line={1.4}
                          letter="0"
                          family="Roboto"
                          fontWeight={300}
                        >
                          {each.title}
                        </StyledTypo>
                        <div className="d-flex justify-content-between align-items-end pt-3">
                          <StyledTypo
                            className="text-center ml-3"
                            color="#505050"
                            size={1.7}
                            line={1}
                            letter="0"
                            family="Roboto"
                            fontWeight={400}
                          >
                            {each.key === "pass" &&
                              (
                                (getPassCount() * 100) /
                                getTotalCount()
                              ).toFixed() + " %"}
                            {each.key === "fail" &&
                              (
                                (getFailCount() * 100) /
                                getTotalCount()
                              ).toFixed() + " %"}
                            {each.key === "skip" && " 0 %"}
                          </StyledTypo>
                          <div className="d-flex justify-content-center align-items-center">
                            <StyledTypo
                              className="mr-2"
                              color="#505050"
                              size={0.75}
                              line={1}
                              letter="0"
                              family="Roboto"
                              fontWeight={300}
                            >
                              {each.key === "pass" &&
                                getPassCount() + " / " + getTotalCount()}
                              {each.key === "fail" &&
                                getFailCount() + " / " + getTotalCount()}
                              {each.key === "skip" && "0 / " + getTotalCount()}
                            </StyledTypo>
                          </div>
                        </div>
                        <GradientProgressBar
                          color="#ececec70" color2="#adadad"
                          value={(
                            ((each.key === "pass"
                              ? getPassCount()
                              : each.key === "fail"
                              ? getFailCount()
                              : 0) *
                              100) /
                            getTotalCount()
                          ).toFixed()}
                          maxValue="100"
                          rotation="90"
                        />
                      </div>
                    ))}
                </TotalBlock>
                <RemediationOverviewWrapper>
                  {Object.keys(NonComplianceStatusColor).map((status) => (
                    <RemediationOverviewBlock
                      color={NonComplianceStatusColor[status].color}
                      onClick={() =>
                        onLink({
                          type: "severity",
                          data: ['High', 'Critical', 'Medium', 'Low'].filter(item => item.toLowerCase() === status.toLowerCase())?.[0],
                        })
                      }
                      key={status}
                    >
                      <StyledTypo
                        className="ml-3 severity-text"
                        size={0.75}
                        line={1.4}
                        letter="0"
                        family="Roboto"
                        fontWeight={400}
                      >
                        {status}
                      </StyledTypo>
                      <div className="d-flex justify-content-between align-items-end pt-3">
                        <StyledTypo
                          className="text-center ml-3"
                          size={1.7}
                          line={1}
                          letter="0"
                          family="Roboto"
                          fontWeight={400}
                        >
                          {overviewCount?.[status]?.total
                            ? (
                                (getSeverityFailCount(status) /
                                  overviewCount?.[status]?.total) *
                                100
                              ).toFixed()
                            : 0}
                          {" %"}
                        </StyledTypo>
                        <div className="d-flex justify-content-center align-items-center">
                          <StyledTypo
                            className="mr-2"
                            size={0.75}
                            line={1}
                            letter="0"
                            family="Roboto"
                            fontWeight={400}
                          >
                            {getSeverityFailCount(status)} /{" "}
                            {overviewCount?.[status]?.total}
                          </StyledTypo>
                        </div>
                      </div>
                    </RemediationOverviewBlock>
                  ))}
                </RemediationOverviewWrapper>
              </OverviewContentWrapper>
            </div>
            <StatsTabWrapper>
              <Tabs>
                <TabList>
                  <Tab>
                    <StyledTypo
                      line="1.8"
                      size={0.8}
                      family="Poppins"
                      fontWeight={300}
                    >
                      Status by Compliance
                    </StyledTypo>
                  </Tab>
                  <Tab>
                    <StyledTypo
                      line="1.8"
                      size={0.8}
                      family="Poppins"
                      fontWeight={300}
                    >
                      Status by Resource
                    </StyledTypo>
                  </Tab>
                </TabList>
                <TabPanel>
                  <SummaryWrapper>
                    <Row className="p-2 align-items-center">
                      {complianceStatusLayout.map((status) => (
                        <Col md={status.width} key={status.label}>
                          <StyledTypo className="text-justify table-label label">
                            {status.label}
                          </StyledTypo>
                          {status.subLabel === "Result" && (
                            <div className="d-flex align-items-center flex-wrap mt-2">
                              {["Critical", "High", "Medium", "Low"].map(
                                (key) => (
                                  <StyledStatusWrapper key={key}>
                                    <StyledTypo
                                      color="#687089"
                                      size={0.6}
                                      line={1}
                                      letter="0"
                                      family="Roboto"
                                      fontWeight={300}
                                    >
                                      {key}
                                    </StyledTypo>
                                  </StyledStatusWrapper>
                                )
                              )}
                            </div>
                          )}
                        </Col>
                      ))}
                    </Row>
                    {complianceStatusData.map((data, idx) => (
                      <SummaryItemContainer
                        hover
                        cursor="pointer"
                        onClick={() =>
                          onLink({
                            type: "compliance",
                            data: data?.compliance_name,
                          })
                        }
                        key={idx}
                      >
                        <Row className="d-flex align-items-center">
                          {complianceStatusLayout.map((status) => (
                            <Col md={status.width} key={status.key}>
                              {status.key === "pass" && (
                                <StyledTypo className="text-justify table-label">
                                  {data[status.key] ?? 0}
                                </StyledTypo>
                              )}
                              {status.key === "fail" && (
                                <StyledTypo className="text-justify table-label">
                                  {data[status.key] ?? 0}
                                </StyledTypo>
                              )}
                              {status.key === "total" && (
                                <StyledTypo className="text-justify table-label">
                                  {data[status.key] ?? 0}
                                </StyledTypo>
                              )}
                              {status.key === "overall" && (
                                <GradientProgressBar
                                  color="#bdbdbd"
                                  color2="#bdbdbd"
                                  value={data[status.key] ?? 0}
                                  maxValue="100"
                                  rotation="90"
                                  showNumber
                                />
                              )}
                              {status.key === "result" && (
                                <div className="d-flex align-items-center flex-wrap">
                                  {Object.keys(NonComplianceStatusColor).map(
                                    (key) => (
                                      <StyledStatusWrapper
                                        color={
                                          NonComplianceStatusColor[key].color
                                        }
                                        key={key}
                                      >
                                        <div className="mark" />
                                        <StyledTypo
                                          size={0.9}
                                          line={1.4}
                                          letter="0"
                                          family="Roboto"
                                          fontWeight={300}
                                        >
                                          {data?.[status.key]?.[key]?.total -
                                            data?.[status.key]?.[key]?.pass ??
                                            0}
                                        </StyledTypo>
                                      </StyledStatusWrapper>
                                    )
                                  )}
                                </div>
                              )}
                              {status.key === "compliance_name" && (
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      Object.values(
                                        providerComplianceType[
                                          selectedApp?.platform
                                        ]
                                      ).filter(
                                        (item) =>
                                          item.label === data[status.key]
                                      )?.[0]?.logo
                                    }
                                    alt={data[status.key]}
                                    width={30}
                                    height={30}
                                    className="mr-1"
                                  />
                                  <StyledTypo className="text-justify table-label">
                                    {data[status.key]}
                                  </StyledTypo>
                                </div>
                              )}
                            </Col>
                          ))}
                        </Row>
                      </SummaryItemContainer>
                    ))}
                  </SummaryWrapper>
                </TabPanel>
                <TabPanel>
                  <SummaryWrapper>
                    <Row className="p-2 align-items-center">
                      {resourceStatusLayout.map((status) => (
                        <Col md={status.width} key={status.label}>
                          <StyledTypo className="text-justify table-label label">
                            {status.label}
                          </StyledTypo>
                          {status.subLabel === "Result" && (
                            <div className="d-flex align-items-center flex-wrap mt-2">
                              {["Critical", "High", "Medium", "Low"].map(
                                (key) => (
                                  <StyledStatusWrapper key={key}>
                                    <StyledTypo
                                      color="#687089"
                                      size={0.6}
                                      line={1}
                                      letter="0"
                                      family="Roboto"
                                      fontWeight={300}
                                    >
                                      {key}
                                    </StyledTypo>
                                  </StyledStatusWrapper>
                                )
                              )}
                            </div>
                          )}
                        </Col>
                      ))}
                    </Row>
                    {resourceStatusData.map((data, idx) => (
                      <SummaryItemContainer
                        hover
                        cursor="pointer"
                        onClick={() =>
                          onLink({
                            type: "resourceType",
                            data: data?.resource_category,
                          })
                        }
                        key={idx}
                      >
                        <Row>
                          {resourceStatusLayout.map((status) => (
                            <Col md={status.width} key={status.key}>
                              {status.key === "pass" && (
                                <StyledTypo className="text-justify table-label">
                                  {data[status.key] ?? 0}
                                </StyledTypo>
                              )}
                              {status.key === "fail" && (
                                <StyledTypo className="text-justify table-label">
                                  {data[status.key] ?? 0}
                                </StyledTypo>
                              )}
                              {status.key === "total" && (
                                <StyledTypo className="text-justify table-label">
                                  {data[status.key] ?? 0}
                                </StyledTypo>
                              )}
                              {status.key === "overall" && (
                                <GradientProgressBar
                                  color="#bdbdbd"
                                  color2="#bdbdbd"
                                  value={data[status.key] ?? 0}
                                  maxValue="100"
                                  rotation="90"
                                  showNumber
                                />
                              )}
                              {status.key === "resource_category" && (
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      Object.values(
                                        providerResourceTypes[
                                          selectedApp?.platform
                                        ]
                                      ).filter(
                                        (item) =>
                                          item.label === data[status.key]
                                      )?.[0]?.logo ?? CubeIcon
                                    }
                                    alt={data[status.key]}
                                    width={25}
                                    height={25}
                                    className="mr-2"
                                  />
                                  <StyledTypo className="text-justify table-label">
                                    {data[status.key]}
                                  </StyledTypo>
                                </div>
                              )}
                              {status.key === "result" && (
                                <div className="d-flex align-items-center flex-wrap">
                                  {Object.keys(NonComplianceStatusColor).map(
                                    (key) => (
                                      <StyledStatusWrapper
                                        color={
                                          NonComplianceStatusColor[key].color
                                        }
                                      >
                                        <div className="mark" />
                                        <StyledTypo
                                          size={0.9}
                                          line={1.4}
                                          letter="0"
                                          family="Roboto"
                                          fontWeight={300}
                                        >
                                          {data?.[status.key]?.[key]?.total -
                                            data?.[status.key]?.[key]?.pass ??
                                            0}
                                        </StyledTypo>
                                      </StyledStatusWrapper>
                                    )
                                  )}
                                </div>
                              )}
                            </Col>
                          ))}
                        </Row>
                      </SummaryItemContainer>
                    ))}
                  </SummaryWrapper>
                </TabPanel>
              </Tabs>
            </StatsTabWrapper>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center py-5 align-items-center">
              {loadingBar("Rings", 50, 50)}
              <StyledTypo className="pl-2">Loading ...</StyledTypo>
            </div>
          </>
        )
      ) : (
        <GraphWrapper>
          {!clusterInfoLoading && (
            <Resources
              resources={selectedApp ? clusterInfo?.["resources"] ?? {} : {}}
              grouped={clusterInfo?.["grouped_type"]}
              clusterInfoLoading={clusterInfoLoading}
              csp={selectedApp?.platform}
              setSelectedFilterType={setSelectedFilterType}
              hideActivity={hideActivity}
              type={type}
            />
          )}
        </GraphWrapper>
      )}
    </MainBodyWrapper>
  );
};

export default InfraSummary;
