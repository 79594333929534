import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import StyledTypo from "../../../components/Typo/StyledTypo";
import { applicationActions } from "../../../redux/actions";
import { cspData, getCurrentOrganization, providerComplianceType } from "../../../helpers/constants";
import EffectedButton from '../../../components/Buttons/EffectedButton';
import Switch from 'react-switch'


const PanelWrapper = styled.div`
  padding: 15px;
  padding-top: 30px;
  width: 100%;
  // border: 1px solid #f1f1f1;
  // box-sizing: border-box;
  // border-radius: 0 20px 20px 20px;

  display: flex;
  gap: 100px;

  .panel-title-header {
    padding: 0 0 47px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const ComplianceListWrapper = styled.div`
  padding: 15px;
  // border-radius: 10px;
  // box-shadow: 1px 2px 6px #ccc;
  border-right: 1px solid #eaeaea;
  padding-right: 100px;
  height: fit-content;

  .compliance-item {
    padding: 5px 15px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
  }

  .compliance-item.active {
    background: rgb(241, 241, 241);
    pointer-events: none;
  }
`

const SwitchItem = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  padding: 7px 15px;
  .label {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
  }

`


const CompliancePanel = (props) => {

  const { updateOrgComplianceSettingAction, envData, fetchOrgEnvListAction } = props

  const [selectedCsp, setSelectedCsp] = useState(cspData?.[0])
  const [complianceEnabled, setComplianceEnabled] = useState(envData?.org_setting?.compliance ?? {})

  useEffect(() => {
    fetchOrgEnvListAction();
  }, [])

  useEffect(() => {
    setComplianceEnabled(envData?.org_setting?.compliance ?? {})
  }, [envData?.org_setting?.compliance])

  const changeEnableStatus = (key, value) => {
    const updatedStatus = {
      ...complianceEnabled,
      [selectedCsp?.key]: {
        ...complianceEnabled?.[selectedCsp?.key],
        [key]: value
      }
    }
    const isValid = Object.values(updatedStatus[selectedCsp?.key]).filter(item => item)?.length > 0
    if(isValid) {
      updateOrgComplianceSettingAction(updatedStatus);
      // setComplianceEnabled(updatedStatus)
    }
  }

  const enableAll = () => {
    const updatedStatus = {
      ...(complianceEnabled ?? {}),
      [selectedCsp?.key]: Object.values(providerComplianceType?.[selectedCsp?.key]).reduce((acc, cur) => ({
        ...acc,
        [cur?.value]: true
      }), {})
    }
    updateOrgComplianceSettingAction(updatedStatus);
    // setComplianceEnabled(updatedStatus);
  }

  return (
    <PanelWrapper>
      <ComplianceListWrapper>
        {cspData.map(csp => (
            <div 
                className={`compliance-item ${selectedCsp.key === csp?.key ? 'active' : ''}`} key={csp.key}
                onClick={() => setSelectedCsp(csp)}
            >
               {csp.logo(30)}
                <StyledTypo
                    size={0.9}
                    line={1.4}
                >
                    {csp.title}
                </StyledTypo>
            </div>
        ))}
      </ComplianceListWrapper>
      <div>
        {Object.values(providerComplianceType?.[selectedCsp?.key]).map(compliance => (
          <SwitchItem>
            <div className="label">
              <img 
                  src={compliance.logo} alt={compliance.value} 
                  width={40}
                  height={40}
              />
              <StyledTypo
                size={0.9}
                line={1.4}
              >
                {compliance.label}
              </StyledTypo>
            </div>
            <Switch
              onChange={(checked) => changeEnableStatus(compliance?.value, checked)}
              checked={complianceEnabled?.[selectedCsp?.key]?.[compliance?.value]}
              onColor="#65C2D7"
              offColor="#ff487f"
            />
          </SwitchItem>
        ))}
        <div className="ml-3 mt-4">
          <EffectedButton
            padding={20}
            height={32}
            noSpan
            marginLeft="0px"
            onClick={() => enableAll()}
            background="#5EB1E4"
            // width={100}
          >
            <StyledTypo color="#fff" className="label">
              Enable All
            </StyledTypo>
          </EffectedButton>
        </div>
      </div>
    </PanelWrapper>
  );
};

function mapStateToProps(state) {
  const { envData } = state.applications;
  return {
    envData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrgEnvListAction: (org_id) =>
      dispatch(applicationActions.fetchOrgEnvListAction(org_id)),
    updateOrgEnvListAction: (payload, kind) =>
      dispatch(applicationActions.updateOrgEnvListAction(payload, kind)),
    updateOrgComplianceSettingAction: (data) => dispatch(applicationActions.updateOrgComplianceSettingAction(data))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompliancePanel);
