/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";

import { applicationActions } from "../../../redux/actions";
import { connect } from "react-redux";
import StyledTypo from "../../../components/Typo/StyledTypo";
import ClusterList from "./clusterList/ClusterList";
import {
  ViewContainer,
  PageTitle,
  FlexComp,
} from "../../../components/PageLayout/Containers";
import { TableWrapper } from "../../../components/PageLayout/Table";
import { BackButton } from "../../../components/PageLayout/BackButton";
import { useHistory } from 'react-router-dom'
import { commonTitle } from "../../../helpers/constants";
import { useTitle } from "../../../helpers/useTtitle";

const pageTitle = `${commonTitle} - Cloud Security - KSPM`

const ClusterListLayout = (props) => {
  const {
    fetchKspmClustersAction,
    clusters,
    clustersLoading
  } = props;
  const clusterRef = useRef();
  const headerRef = useRef();
  const history = useHistory();
  const [, setRenderFlag] = useState(false);

  useTitle(pageTitle)

  useEffect(() => {
    fetchKspmClustersAction()
  }, []);

  return (
    <ViewContainer>
      <div className="main-container d-flex flex-column">
        <PageTitle
          className="d-flex align-items-center justify-content-between"
          ref={headerRef}
        >
          <StyledTypo className="ml-2 heading">
            <BackButton
              onClick={() => history.push("/cloud")}
              title="Back"
              href="#"
            >
              Cloud Security
            </BackButton>{" - "}KSPM
          </StyledTypo>
          <FlexComp>
            {clusterRef?.current?.getFilterPanel()}
          </FlexComp>
        </PageTitle>
        <TableWrapper
          marginTop={`${
            headerRef?.current?.offsetHeight + headerRef?.current?.offsetTop ||
            100
          }px`}
        >
          <ClusterList
            topGap={
              headerRef?.current?.offsetHeight +
                headerRef?.current?.offsetTop || 100
            }
            clusters={clusters}
            fetchingFlag={clustersLoading}
            ref={clusterRef}
            setRenderFlag={setRenderFlag}
          />
        </TableWrapper>
      </div>
    </ViewContainer>
  );
};

function mapStateToProps(state) {
  const { clusters, clustersLoading } = state.applications;
  return {
    clusters,
    clustersLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchKspmClustersAction: () => dispatch(applicationActions.fetchKspmClustersAction())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClusterListLayout);
