import { iac } from "../apis";
import { authAction } from "./auth.actions";
import { iacConstants } from "../constants";
import { showAlert } from "../../helpers/constants";
import { Buffer } from 'buffer';

export const iacActions = {
  fetchIaCReportAction,
  createIacProjectAction,
  updateIacProjectAction,
  fetchIacProjectsAction,
  clearCreateFlagAction,
  deleteIacProjectAction,
  fetchIacRemediationAction,
};

function fetchIaCReportAction(project) {
  return (dispatch) => {
    dispatch(request());
    iac.fetchIacScanReport(project?.git_url).then(
      (iac_report) => {
        dispatch(success(iac_report));
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: iacConstants.FETCH_IAC_SCAN_REPORT_REQUEST };
  }
  function success(iacReport) {
    return {
      type: iacConstants.FETCH_IAC_SCAN_REPORT_SUCCESS,
      iacReport,
      project_id: project?.id,
    };
  }
  function failure(error) {
    return {
      type: iacConstants.FETCH_IAC_SCAN_REPORT_FAILURE,
      error,
      project_id: project?.id,
    };
  }
}

function createIacProjectAction(payload) {
  return (dispatch) => {
    iac.createIacProject(payload).then(
      (res) => {
        dispatch(fetchIacProjectsAction());
        dispatch(success());
        showAlert(
          "success",
          "Create Project",
          `Successfully created new project with ${res.result} ID.`
        );
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
        dispatch(failure());
        showAlert(
          "error",
          "Create Project",
          `Failed to create new project named with ${payload?.display_name}`
        );
      }
    );
  };
  function success() {
    return { type: iacConstants.IAC_CREATE_PROJECT_SUCCESS };
  }
  function failure() {
    return { type: iacConstants.IAC_CREATE_PROJECT_FAILURE };
  }
}

function updateIacProjectAction(payload) {
  return (dispatch) => {
    iac.updateIacProject(payload).then(
      (res) => {
        dispatch(fetchIacProjectsAction());
        dispatch(success());
        showAlert(
          "success",
          "Update Project",
          `Successfully updated project with ${res.result} ID.`
        );
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
        dispatch(failure());
        showAlert(
          "error",
          "Update Project",
          `Failed to update project named with ${payload?.display_name}`
        );
      }
    );
  };
  function success() {
    return { type: iacConstants.IAC_CREATE_PROJECT_SUCCESS };
  }
  function failure() {
    return { type: iacConstants.IAC_CREATE_PROJECT_FAILURE };
  }
}

function fetchIacProjectsAction() {
  return (dispatch) => {
    dispatch(request());
    iac.fetchIacProjects().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        showAlert(
          "error",
          "Internal Error",
          `Failed to fetch IaC project data`
        );
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function request() {
    return { type: iacConstants.FETCH_IAC_PROJECTS_REQUEST };
  }

  function success(projects) {
    return { type: iacConstants.FETCH_IAC_PROJECTS_SUCCESS, projects };
  }
  function failure() {
    return { type: iacConstants.FETCH_IAC_PROJECTS_FAILURE };
  }
}

function fetchIacRemediationAction(query_id) {
  return (dispatch) => {
    iac.fetchIacRemediation(query_id).then(
      (res) => {
        let temp = "output" in res ? res["output"] : res;
        let compliant = "",
          non_compliant = "";
        if (temp) {
          temp = JSON.stringify(temp);
          temp = JSON.parse(temp);
          if (temp.hasOwnProperty("compliant")) {
            compliant = Buffer.from(temp["compliant"], "base64").toString();
          }
          if (temp.hasOwnProperty("non-compliant")) {
            non_compliant = Buffer.from(
              temp["non-compliant"],
              "base64"
            ).toString();
          }
        }
        dispatch(
          success({
            compliant,
            non_compliant,
            positive_line_numbers: res?.output?.positive_line_numbers,
          })
        );
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
      }
    );
  };

  function success(payload) {
    return {
      type: iacConstants.FETCH_IAC_REMEDIATION_SUCCESS,
      query_id,
      payload,
    };
  }
  function failure() {
    return {
      type: iacConstants.FETCH_IAC_REMEDIATION_FAILURE,
      query_id,
    };
  }
}

function clearCreateFlagAction() {
  return (dispatch) => {
    dispatch({ type: iacConstants.CLEAR_CREATE_FLAG });
  };
}

function deleteIacProjectAction(payload) {
  return (dispatch) => {
    iac.deleteIacProject(payload).then(
      (res) => {
        dispatch(fetchIacProjectsAction());
        dispatch(success());
        showAlert(
          "success",
          "Delete Project",
          `Successfully deleted project with ${res.result} ID.`
        );
      },
      (error) => {
        error && dispatch(authAction.setTokenExpired(error, failure));
        dispatch(failure());
        showAlert(
          "error",
          "Update Project",
          `Failed to delete project named with ${payload?.display_name}`
        );
      }
    );
  };
  function success() {
    return { type: iacConstants.IAC_DELETE_PROJECT_SUCCESS };
  }
  function failure() {
    return { type: iacConstants.IAC_DELETE_PROJECT_FAILURE };
  }
}
