import React, { useState } from "react";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar_icon.svg";
import { ReactComponent as DropdownIconSVG } from "../../../assets/icons/dropdown_icon.svg";
import styled from "styled-components";
import StyledTypo from "../../../components/Typo/StyledTypo";

import StyledDropdown from "../../../components/Buttons/StyledDropdown";
import DropdownIcon from "../../../components/Buttons/DropdownIcon";
import { DropdownMenu } from "reactstrap";
import { trendPeriodOptions } from "../../../helpers/constants";
import moment from 'moment';

const CalendarWrapper = styled.div`
  display: flex;
  align-items: center;
  background: transparent;
`;

const CalendarIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  background: rgba(45, 156, 219, 0.15);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionDropdownMenu = styled(DropdownMenu)`
  min-width: 260px;
  background: #fff;
  border: 1px solid #f1f5fa;
  box-sizing: border-box;
  /* box shadow */

  box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 10px;
  padding: 15px;
  transform: translate3d(0px, 35px, 0px) !important;
`;

const RecordWrapper = styled.div`
  padding: 8px 20px;
  border: 1px solid ${(props) => (props.active ? "#999" : "#f1f1f1")};
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: ${(props) => (props.active ? "default" : "pointer")};
`;

const PeriodOption = (props) => {
  const { duration, setDuration } = props;
  const [isOpenCalendarPanel, setIsOpenCalendarPanel] = useState(false);

  const toggleCalendarPanel = () =>
    setIsOpenCalendarPanel((isOpenCalendarPanel) => !isOpenCalendarPanel);

  return (
    <div>
      <StyledDropdown
        isOpen={isOpenCalendarPanel}
        toggle={() => toggleCalendarPanel()}
      >
        <DropdownIcon bg="transparent">
          <CalendarWrapper>
            <CalendarIconWrapper>
              <CalendarIcon width={20} height={20} />
            </CalendarIconWrapper>
            <div className="ml-3 text-left">
              <StyledTypo>
                {trendPeriodOptions[duration].label}
              </StyledTypo>
              <StyledTypo
                color={"#858585"}
                size={0.8}
                line={1.2}
                letter="0"
                family="Poppins"
                fontWeight={300}
              >
                {moment()
                  .subtract(trendPeriodOptions[duration]?.amount, trendPeriodOptions[duration].unit)
                  .format("MMM Do, YYYY")}{" "}
                - {moment().format("MMM Do, YYYY")}
              </StyledTypo>
            </div>
            <div className="ml-3">
              <DropdownIconSVG width={18} height={18} />
            </div>
          </CalendarWrapper>
        </DropdownIcon>
        {setDuration && (
          <ActionDropdownMenu>
            {Object.keys(trendPeriodOptions).map((periodKey) => (
              <RecordWrapper
                active={periodKey === duration}
                onClick={() => {
                  if (periodKey !== duration) {
                    setDuration(periodKey);
                    toggleCalendarPanel();
                  }
                }}
              >
                <StyledTypo
                  color={"#505050"}
                  size={0.8}
                  line={1.4}
                  letter="0"
                  family="Poppins"
                  fontWeight={300}
                >
                  {trendPeriodOptions[periodKey].label}
                </StyledTypo>
              </RecordWrapper>
            ))}
          </ActionDropdownMenu>
        )}
      </StyledDropdown>
    </div>
  );
};

export default PeriodOption;
