import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
  orgStatusInfo,
} from "../../../helpers/constants";
import { ReactComponent as ThreeDotIcon } from "../../../assets/icons/three_dot_icon.svg";
import { ReactComponent as DeployButtonIcon } from "../../../assets/icons/deploy_icon.svg";

import StyledTypo from "../../../components/Typo/StyledTypo";
import StyledDropdown from "../../../components/Buttons/StyledDropdown";
import DropdownIcon from "../../../components/Buttons/DropdownIcon";
import { DropdownMenu } from "reactstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/trash_icon.svg";
import { confirmAlert } from "react-confirm-alert";

const TableWrapper = styled.div`
  padding-top: 20px;
  .divider {
    margin: 8px 0px 4px 0;
    //margin: 8px -46.5px 4px -37.5px;
    border-top: 1px solid rgba(164, 175, 183, 0.3);
    height: 0;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 26px;
`;

const EachCell = styled.div`
  width: ${(props) => props.width};
`;

const RecordWrapper = styled.div`
  padding: 10px 26px;
  background: #ffffff;
  //border: 1px solid #f1f1f1;
  box-sizing: border-box;
  //border-radius: 20px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  &:hover {
    border-radius: 40px;
    background-color: #f1f1f1;
  }
`;

const ActionDropdownMenu = styled(DropdownMenu)`
  // --width: ${(props) => props.width - 350}px;
  // left: calc(var(--width) - 50px - 1vw) !important;
  /* white */
  min-width: 150px;
  width: 250px;
  background: #ffffff;
  /* Gray light 2 */

  border: 1px solid #f1f5fa;
  box-sizing: border-box;
  /* box shadow */

  box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 10px;
  padding: 15px 20px;
  transform: translate3d(-230px, -10px, 0px) !important;

  .item:hover p {
    color: #66c4d5 !important;
  }
`;

const ShowingWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .page-link:focus {
    box-shadow: none !important;
  }
  ul.pagination {
    margin-bottom: 0;
  }
`;

const StatusWrapper = styled.div`
  background-color: ${(props) => props.color};
  padding: 5px 10px;
  border-radius: 215px;
  display: flex;
`;

const TableHeader = () => {
  return (
    <HeaderWrapper>
      <EachCell width={`${43800 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          Name
        </StyledTypo>
      </EachCell>
      <EachCell width={`${36200 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          Domain
        </StyledTypo>
      </EachCell>
      <EachCell width={`${27100 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          Domain Prefix
        </StyledTypo>
      </EachCell>
      {/*<EachCell width={`${18300 / 1596}%`}>*/}
      {/*  <StyledTypo color={'#505050'} size={1} line={1.4} letter="0" family="Poppins" fontWeight={500}>*/}
      {/*    Email*/}
      {/*  </StyledTypo>*/}
      {/*</EachCell>*/}
      <EachCell width={`${18300 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          Status
        </StyledTypo>
      </EachCell>
      <EachCell width={`${25800 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={1}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          Created At
        </StyledTypo>
      </EachCell>
      <EachCell width={`${8400 / 1596}%`}>
        <StyledTypo
          className="text-right"
          color={"#505050"}
          size={1}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={500}
        >
          Actions
        </StyledTypo>
      </EachCell>
    </HeaderWrapper>
  );
};

const Record = (props) => {
  const {
    data,
    isOpenActionMenu,
    toggleActionMenu,
    manageOrgStatusAction,
    deleteManageOrgAction,
  } = props;

  const deleteConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>{`You want to ${
              data.is_deleted ? "restore" : "delete"
            } organization "${data.name}"?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                deleteManageOrgAction(
                  data.name,
                  data.is_deleted ? "restore" : "delete"
                );
                onClose();
              }}
            >
              Confirm
            </button>
          </div>
        );
      },
    });
  };

  return (
    <RecordWrapper>
      <EachCell width={`${43800 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={0.875}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {data.name}
        </StyledTypo>
      </EachCell>
      <EachCell width={`${36200 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={0.875}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {data.prefix
            ? `${data.prefix}.matossphere.com`
            : "demo-be.cloudmatos.dev"}
        </StyledTypo>
      </EachCell>
      <EachCell width={`${27100 / 1596}%`}>
        <StyledTypo
          color={"#505050"}
          size={0.875}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {data.prefix}
        </StyledTypo>
      </EachCell>
      {/*<EachCell width={`${18300 / 1596}%`}>*/}
      {/*  <StyledTypo color={'#505050'} size={0.875} line={1.4} letter="0" family="Poppins" fontWeight={400}>*/}
      {/*    {data.email}*/}
      {/*  </StyledTypo>*/}
      {/*</EachCell>*/}
      <EachCell width={`${18300 / 1596}%`} className="d-flex">
        <StatusWrapper
          color={
            orgStatusInfo[data.is_deleted ? "deleted" : data.status ?? "active"]
              .color
          }
        >
          <StyledTypo
            color={"#fff"}
            size={0.875}
            line={1.4}
            letter="0"
            family="Poppins"
            fontWeight={400}
          >
            {
              orgStatusInfo[
                data.is_deleted ? "deleted" : data.status ?? "active"
              ].name
            }
          </StyledTypo>
        </StatusWrapper>
      </EachCell>
      <EachCell width={`${25800 / 1596}%`} className="d-flex">
        <StyledTypo
          color={"#505050"}
          size={0.875}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {moment(data.created_at).format("YYYY-MM-DD")}
        </StyledTypo>
      </EachCell>
      <EachCell width={`${8400 / 1596}%`} className="text-right">
        <StyledDropdown
          isOpen={isOpenActionMenu.filter((item) => item[data.id]).length}
          toggle={() => toggleActionMenu(data.id)}
        >
          <DropdownIcon>
            <ThreeDotIcon />
          </DropdownIcon>
          <ActionDropdownMenu>
            {/*<div className="d-flex mb-2 align-items-center cursor-pointer item"*/}
            {/*     onClick={() => history.push(`/manage/user-profile?id=${data.id}`)}>*/}
            {/*  <EditUserIcon fill="#485DCD" width={20}/>*/}
            {/*  <StyledTypo className="ml-3" color={'#505050'} size={0.875} line={1.4} letter="0" family="Roboto"*/}
            {/*              fontWeight={500}>*/}
            {/*    Edit Details*/}
            {/*  </StyledTypo>*/}
            {/*</div>*/}
            {/*<div className="d-flex mb-2 align-items-center cursor-pointer item">*/}
            {/*  <EditPermissionIcon width={20}/>*/}
            {/*  <StyledTypo className="ml-3" color={'#505050'} size={0.875} line={1.4} letter="0" family="Roboto"*/}
            {/*              fontWeight={500}>*/}
            {/*    Edit Permissions*/}
            {/*  </StyledTypo>*/}
            {/*</div>*/}
            {!data.is_deleted && (
              <div
                className="d-flex my-2 align-items-center cursor-pointer item"
                onClick={() => {
                  manageOrgStatusAction(
                    data.name,
                    data.status === "active" || data.status === "NEW"
                      ? "inactive"
                      : "active"
                  );
                  toggleActionMenu(data.id);
                }}
              >
                <DeployButtonIcon width={20} />
                <StyledTypo
                  className="ml-3"
                  color={"#505050"}
                  size={0.875}
                  line={1.4}
                  letter="0"
                  family="Roboto"
                  fontWeight={500}
                >
                  {data.status === "active" || data.status === "NEW"
                    ? "Disable"
                    : "Enable"}
                </StyledTypo>
              </div>
            )}
            <div
              className="d-flex my-2 align-items-center cursor-pointer item"
              onClick={() => {
                deleteConfirm();
                toggleActionMenu(data.id);
              }}
            >
              <DeleteIcon width={20} fill={"#ff487f"} />
              <StyledTypo
                className="ml-3"
                color={"#ff487f"}
                size={0.875}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={500}
              >
                {data.is_deleted ? "Restore" : "Delete"}
              </StyledTypo>
            </div>
            {/*<div className="d-flex align-items-center cursor-pointer item">*/}
            {/*  <ImpersonateIcon width={20}/>*/}
            {/*  <StyledTypo className="ml-3" color={'#505050'} size={0.875} line={1.4} letter="0" family="Roboto"*/}
            {/*              fontWeight={500}>*/}
            {/*    Impersonate*/}
            {/*  </StyledTypo>*/}
            {/*</div>*/}
          </ActionDropdownMenu>
        </StyledDropdown>
      </EachCell>
    </RecordWrapper>
  );
};

const OrganizationTable = (props) => {
  const { organizations, manageOrgStatusAction, deleteManageOrgAction } = props;
  const [isOpenActionMenu, setIsOpenActionMenu] = useState([]);
  const [orgData, setOrgData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemPerPage = 10;

  useEffect(() => {
    setOrgData([...organizations]);
    setIsOpenActionMenu([
      ...organizations.map((item) => ({ [item.id]: false })),
    ]);
  }, [organizations]);

  const toggleActionMenu = (key) => {
    setIsOpenActionMenu(
      isOpenActionMenu.map((item) => ({ ...item, [key]: !item[key] }))
    );
  };

  const handlePageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const pageFilter = useCallback(
    (data) => {
      return data.filter(
        (item, idx) =>
          idx >= itemPerPage * (currentPage - 1) &&
          idx < itemPerPage * currentPage
      );
    },
    [orgData, currentPage]
  );

  const showPaginateLabel = useCallback(
    (data) => {
      return `Showing 
    ${
      itemPerPage * (currentPage - 1) + 1 > data.length
        ? data.length
        : itemPerPage * (currentPage - 1) + 1
    } - 
    ${
      itemPerPage * currentPage > data.length
        ? data.length
        : itemPerPage * currentPage
    } from ${data.length} organizations`;
    },
    [orgData, currentPage]
  );

  const handleChange = (id) => {
    setOrgData((deployData) =>
      deployData.map((item) => ({
        ...item,
        selected: item.id === id ? !item.selected : item.selected,
      }))
    );
  };

  return (
    <TableWrapper>
      <TableHeader />
      <div className="divider" />
      {pageFilter(orgData).length ? (
        pageFilter(orgData).map((orgData) => (
          <Record
            data={orgData}
            isOpenActionMenu={isOpenActionMenu}
            toggleActionMenu={toggleActionMenu}
            handleChange={handleChange}
            manageOrgStatusAction={manageOrgStatusAction}
            deleteManageOrgAction={deleteManageOrgAction}
          />
        ))
      ) : (
        <div className="text-center my-5">
          <StyledTypo
            color={"#505050"}
            size={0.875}
            line={1.4}
            letter="0"
            family="Poppins"
            fontWeight={400}
          >
            No organizations
          </StyledTypo>
        </div>
      )}
      <ShowingWrapper>
        <StyledTypo
          color={"#505050"}
          size={0.875}
          line={1.4}
          letter="0"
          family="Poppins"
          fontWeight={400}
        >
          {showPaginateLabel(orgData)}
        </StyledTypo>
        <ReactPaginate
          pageCount={(orgData?.length ?? 0) / itemPerPage}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          previousLabel="< Prev"
          nextLabel="Next >"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          forcePage={currentPage - 1}
          onPageChange={handlePageChange}
          activeClassName="active"
        />
      </ShowingWrapper>
    </TableWrapper>
  );
};

export default OrganizationTable;
