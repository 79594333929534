import { Modal, ModalBody } from 'reactstrap'
import styled from 'styled-components';
import React from "react";
import {ReactComponent as CloseIcon} from "../../assets/icons/close-icon.svg";
import Tree from "react-d3-tree";

const ModalWrapper = styled.div`
`

const ModalContent = styled.div`
    margin: 30px 10px;
`

const CloseWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`

const ResourceTreeWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 150px);
  //border: 1px solid #f1f1f1;
  border-radius: 20px;
  margin-bottom: 30px;
  padding: 10px;
  position: relative;
`

const ResourceTreeModal = (props) => {
  const { toggle, treeData, renderCustomNode } = props

  return (
      <ModalWrapper>
        <Modal isOpen={props.modal} className="resource-tree-modal" fade={true} toggle={toggle} style={{background: 'transparent'}}>
          <ModalBody>
            <ModalContent>
          <CloseWrapper className="cursor-pointer" onClick={toggle}>
            <CloseIcon width={20} stroke="#A4AFB7"/>
          </CloseWrapper>
              <ResourceTreeWrapper>
                <Tree
                    data={treeData}
                    translate={{
                      x: 600,
                      y: 400
                    }}
                    initialDepth={1}
                    zoom={1}
                    nodeSize={{
                      x: 300,
                      y: 30
                    }}
                    transitionDuration={500}
                    centeringTransitionDuration={800}
                    renderCustomNodeElement={renderCustomNode}
                    enableLegacyTransitions={true}
                />
              </ResourceTreeWrapper>
            </ModalContent>
          </ModalBody>
        </Modal>

      </ModalWrapper>
  );
}

export default ResourceTreeModal;
