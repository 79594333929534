import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import StyledTypo from "../../components/Typo/StyledTypo";
import React from "react";
import RemediationTable from "./RemediationTable";

const ModalWrapper = styled.div``;

const ModalContent = styled.div`
  margin: 30px 10px;
`;

const RemediationModal = (props) => {
  const { toggle, remediations } = props;

  return (
    <ModalWrapper>
      <Modal
        isOpen={props.modal}
        className="plan-modal"
        fade={true}
        toggle={toggle}
        style={{ background: "transparent" }}
      >
        <ModalBody>
          <ModalContent>
            <div className="d-flex justify-content-center">
              <StyledTypo
                className="pb-4 mt-2"
                color="#50505099"
                size={2.5}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                Remediations
              </StyledTypo>
            </div>
            <RemediationTable remediationList={remediations} />
          </ModalContent>
        </ModalBody>
      </Modal>
    </ModalWrapper>
  );
};

export default RemediationModal;
