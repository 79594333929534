import React, { useState, useEffect } from "react";

import { useHistory, Link } from "react-router-dom";

import { connect } from "react-redux";
import styled from "styled-components";
import { sideMenuOptions } from "../../../helpers/constants";
import StyledTypo from "../../../components/Typo/StyledTypo";
import { ReactComponent as UpArrow } from "../../../assets/icons/arrow-angle-up-sm.svg";
import { ReactComponent as RightArrow } from "../../../assets/icons/arrow-angle-right-sm.svg";

const MainContainer = styled.div`
  width: ${(props) => (props.open ? 260 : 56)}px;
  max-height: calc(100vh - 64px);
  transition: width 0.2s;
  transition-timing-function: ease-in-out;
  //box-shadow: 1px 2px 5px 10px #ccc;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 2.7px 9px,
    rgba(0, 0, 0, 0.09) 0px 9.4px 24px, rgba(0, 0, 0, 0.08) 0px 21.8px 43px;
  align-items: center;
  padding: 16px;
  overflow-y: auto;
`;

const MenuItem = styled.div`
  padding-top: 16px;

  .parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .icon-wrapper {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .children-wrapper {
    padding-left: 40px;
    padding-top: 16px;
  }
`;

const Sidebar = (props) => {
  const { sidebarOpen } = props;

  const history = useHistory();
  const [scrollY, setScrollY] = useState(window.scrollY);

  const [openItems, setOpenItems] = useState({});
  const [showMenu, setShowMenu] = useState(sidebarOpen);

  const toggleMenu = (label) => {
    setOpenItems((openItems) => ({ ...openItems, [label]: !openItems[label] }));
  };

  useEffect(() => {
    setShowMenu(sidebarOpen);
  }, [sidebarOpen]);

  return (
    <MainContainer
      open={showMenu}
      onMouseOver={() => !sidebarOpen && setShowMenu(true)}
      onMouseLeave={() => setShowMenu(sidebarOpen ? sidebarOpen : false)}
    >
      {sideMenuOptions.map((menu) => (
        <MenuItem>
          <div
            className="parent cursor-pointer"
            onClick={() => toggleMenu(menu.label)}
          >
            <div className="icon-wrapper">
              {menu.iconType}
              {showMenu && (
                <StyledTypo
                  color="#505050"
                  size={1}
                  line={1.4}
                  letter="0"
                  family="Roboto"
                  fontWeight={500}
                >
                  {menu.label}
                </StyledTypo>
              )}
            </div>
            {menu.children.length > 0 && showMenu && (
              <div className="cursor-pointer">
                {openItems[menu.label] ? (
                  <UpArrow fill="#505050" />
                ) : (
                  <RightArrow fill="#505050" />
                )}
              </div>
            )}
          </div>
          {showMenu &&
            openItems[menu.label] &&
            menu.children.map((child) => (
              <div
                className="children-wrapper cursor-pointer"
                key={child.label}
              >
                <StyledTypo
                  color="#505050"
                  size={1}
                  line={1.4}
                  letter="0"
                  family="Roboto"
                  fontWeight={400}
                >
                  {child.label}
                </StyledTypo>
              </div>
            ))}
        </MenuItem>
      ))}
    </MainContainer>
  );
};

function mapStateToProps(state) {
  const { expired_token, new_refresh_token, defused_refresh_token } =
    state.auth;
  return {
    expired_token,
    new_refresh_token,
    defused_refresh_token,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
