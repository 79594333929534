import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";

import { ReactComponent as CircleTimesIcon } from "../../../assets/icons/m_circle_times_icon.svg";
import { ReactComponent as CircleRefreshIcon } from "../../../assets/icons/m_circle_refresh_icon.svg";
import { ReactComponent as CircleCheckIcon } from "../../../assets/icons/m_circle_check_icon.svg";
import { ReactComponent as CircleTrashIcon } from "../../../assets/icons/m_circle_trash_icon.svg";
import moment from "moment";
import { deploymentActions } from "../../../redux/actions";
import { connect } from "react-redux";
import { Badge } from "reactstrap";
import { loadingBar, policyCategories } from "../../../helpers/constants";
import CommonSelect from "../../../components/Select/CommonSelect";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info_icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit_icon.svg";
import PrettierPanel from "../../modals/PrettierPanel";

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  width: 100%;
`;

const MarginElement = styled.div`
  margin: 0 -10px;
`;

const DeploymentItem = styled.div`
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 13px 26px 10px 18px;
  margin-top: 20px;
`;

const ObservationEachPart = styled.div`
  margin-top: 50px;
`;

const StatusItem = styled.div`
  position: relative;
  width: 40px;
  padding-top: 10px;
  margin-top: 10px;
  opacity: ${(props) => (props.active ? 1 : 0.3)};

  .status-badge {
    position: absolute;
    top: 5px;
    right: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #f1f1f1;
    color: #ff487f;
    font-size: 11px;
    line-height: 20px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
`;

const StyledAnchor = styled.a`
  cursor: ${(props) => (props.active ? "pointer" : "auto")};
`;

const Deployments = (props) => {
  const {
    templates,
    applyTerraformAction,
    destroyTerraformAction,
    selectedApp,
    loading_task_id,
    appStatus,
  } = props;
  const [deploymentData, setDeploymentData] = useState({
    "apps/iac": [],
    "apps/infra policies": [],
  });
  const [showPrettier, setShowPrettier] = useState({});
  const [selectedPolicyCategory, setSelectedPolicyCategory] = useState([]);

  const policyCategoryOptions = [...policyCategories];

  useEffect(() => {
    setDeploymentData({
      "apps/iac": templates
        .filter(
          (item) =>
            !item.remediation &&
            item.template.category !== "policy" &&
            item.template.type &&
            !!item.template?.type?.filter(
              (each) => each === "deployment" || each === "reconfiguration"
            ).length
        )
        .map((app) => ({
          status: {
            fail: 0,
            refresh: 0,
            running: 0,
            delete: 0,
          },
          name: app.template.name,
          stack: selectedApp.platform,
          // stack: 'template-deploy-openstack',
          time: app.template.createdDate
            ? moment(app.template.createdDate).format("MM/DD/YY")
            : moment().format("MM/DD/YY"),
          app_id: app.template.app_id,
          type: app.template.type,
          is_deployed: !!app?.is_deployed,
          variables: app?.variables ?? {},
          id: app.id,
        })),
      "apps/infra policies": templates
        .filter(
          (item) =>
            !item.remediation &&
            item.template.category === "policy" &&
            item.template.type &&
            !!item.template?.type?.filter(
              (each) => each === "deployment" || each === "reconfiguration"
            ).length
        )
        .map((app) => ({
          status: {
            fail: 0,
            refresh: 0,
            running: 0,
            delete: 0,
          },
          name: app.template.name,
          stack: selectedApp.platform,
          // stack: 'template-deploy-openstack',
          time: app.template.createdDate
            ? moment(app.template.createdDate).format("MM/DD/YY")
            : moment().format("MM/DD/YY"),
          app_id: app.template.app_id,
          type: app.template.type,
          is_deployed: app?.is_deployed,
          variables: app?.variables ?? {},
          id: app.id,
        })),
    });
  }, [templates]);

  const validClick = () => {
    return !loading_task_id.filter((item) => item.app === selectedApp?.id)
      .length;
  };

  const validType = (type) => {
    return !loading_task_id.filter(
      (item) => item.app === selectedApp?.id && item.type === type
    ).length;
  };

  const togglePrettierPanel = (idx) => {
    setShowPrettier((showPrettier) => ({
      ...showPrettier,
      [idx]: !showPrettier[idx],
    }));
  };

  return (
    <div>
      <span className="font-size-36 font-weight-bold m-gray">Deployments</span>
      <ItemWrapper>
        {Object.keys(deploymentData).map((key, idx) => (
          <ObservationEachPart>
            <span className="font-size-26 font-weight-bold m-gray mb-3">
              {key}
            </span>
            {/*{idx > 0 && (*/}
            {/*    <div className="mt-3">*/}
            {/*      <CommonSelect*/}
            {/*          value={selectedPolicyCategory}*/}
            {/*          setValue={setSelectedPolicyCategory}*/}
            {/*          options={policyCategoryOptions}*/}
            {/*          label="Policy Category"*/}
            {/*          isMulti*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*)}*/}
            {deploymentData[key].map((item, idx) => (
              <MarginElement>
                <DeploymentItem>
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="font-size-18 font-weight-bold m-dark-gray">
                      {item.name}
                    </div>
                    <div className="dropdown show">
                      <div
                        className="cursor-pointer"
                        onClick={() => togglePrettierPanel(item.id)}
                      >
                        <InfoIcon />
                      </div>
                      {showPrettier[item.id] && (
                        <PrettierPanel
                          title="Variables"
                          toggle={() => togglePrettierPanel(item.id)}
                          prettierData={item.variables}
                          id={`var-${item.id}`}
                          onlyShow
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-1 d-flex justify-content-between align-items-center font-size-14 font-weight-light">
                    {/*<div>{item.stack}</div>*/}
                    <h6>
                      <Badge color={item.is_deployed ? "success" : "danger"}>
                        {item.is_deployed ? "DEPLOYED" : "DESTROYED"}
                      </Badge>
                    </h6>
                    <div>{item.time}</div>
                  </div>

                  <div className="d-flex justify-content-between">
                    <Fragment>
                      <StatusItem active={item.type.includes("fail")}>
                        <StyledAnchor>
                          <CircleTimesIcon />
                        </StyledAnchor>
                        {/*<span className="status-badge">{item.status.fail}</span>*/}
                      </StatusItem>
                      {validType("deploy") ? (
                        // <StatusItem className="m-tooltip" active={true}>
                        <StatusItem
                          className="m-tooltip"
                          active={
                            item.type.includes("deployment") &&
                            validClick() &&
                            !item.is_deployed
                          }
                        >
                          <StyledAnchor
                            // active={true}
                            active={
                              item.type.includes("deployment") &&
                              validClick() &&
                              !item.is_deployed
                            }
                            // onClick={() =>  applyTerraformAction(item.id, 'deploy', selectedApp.id)}>
                            onClick={() =>
                              item.type.includes("deployment") &&
                              validClick() &&
                              !item.is_deployed &&
                              applyTerraformAction(
                                item.id,
                                "deploy",
                                selectedApp.id
                              )
                            }
                          >
                            <CircleRefreshIcon />
                          </StyledAnchor>
                          {/*<span className="status-badge">{item.status.refresh}</span>*/}
                          <span className="tooltiptext-top">
                            {" "}
                            <div>Deploy</div>
                          </span>
                        </StatusItem>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center mt-3">
                          {loadingBar("Rings", 50, 50)}
                        </div>
                      )}
                      {validType("reconfiguration") ? (
                        <StatusItem
                          className="m-tooltip"
                          active={
                            item.type.includes("reconfiguration") &&
                            validClick() &&
                            appStatus
                          }
                        >
                          <StyledAnchor
                            active={
                              item.type.includes("reconfiguration") &&
                              validClick() &&
                              appStatus
                            }
                            onClick={() =>
                              item.type.includes("reconfiguration") &&
                              validClick() &&
                              appStatus &&
                              applyTerraformAction(
                                item.id,
                                "reconfiguration",
                                selectedApp.id
                              )
                            }
                          >
                            <CircleCheckIcon />
                          </StyledAnchor>
                          {/*<span className="status-badge">{item.status.running}</span>*/}
                          <span className="tooltiptext-top">
                            {" "}
                            <div>Reconfigure</div>
                          </span>
                        </StatusItem>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center mt-3">
                          {loadingBar("Rings", 50, 50)}
                        </div>
                      )}
                      {validType("destroy") ? (
                        <StatusItem
                          className="m-tooltip"
                          active={
                            item.type.includes("deployment") && validClick()
                          }
                        >
                          <StyledAnchor
                            active={
                              item.type.includes("deployment") && validClick()
                            }
                            onClick={() =>
                              item.type.includes("deployment") &&
                              validClick() &&
                              destroyTerraformAction(item.id, selectedApp.id)
                            }
                          >
                            <CircleTrashIcon />
                          </StyledAnchor>
                          {/*<span className="status-badge">{item.status.delete}</span>*/}
                          <span className="tooltiptext-top">
                            {" "}
                            <div>Destroy</div>
                          </span>
                        </StatusItem>
                      ) : (
                        // <StatusItem className="m-tooltip" active={item.type.includes("deployment") && validClick() && item.is_deployed}>
                        //   <StyledAnchor
                        //       active={item.type.includes("deployment") && validClick() && item.is_deployed}
                        //       onClick={() => item.type.includes("deployment") && validClick() && item.is_deployed && destroyTerraformAction(item.id, selectedApp.id)}>
                        //     <CircleTrashIcon/>
                        //   </StyledAnchor>
                        //   {/*<span className="status-badge">{item.status.delete}</span>*/}
                        //   <span className="tooltiptext-top"> <div>Destroy</div></span>
                        // </StatusItem>
                        <div className="d-flex justify-content-center align-items-center mt-3">
                          {loadingBar("Rings", 50, 50)}
                        </div>
                      )}
                    </Fragment>
                  </div>
                </DeploymentItem>
              </MarginElement>
            ))}
          </ObservationEachPart>
        ))}
      </ItemWrapper>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    deploy,
    reconfiguration,
    remediation,
    destroy,
    loading_task_id,
    type,
    loading,
  } = state.deployments;
  return {
    deploy,
    reconfiguration,
    remediation,
    destroy,
    loading_task_id,
    type,
    loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    applyTerraformAction: (org_temp_id, type, app_id) =>
      dispatch(
        deploymentActions.applyTerraformAction(org_temp_id, type, app_id)
      ),
    destroyTerraformAction: (org_temp_id, app_id) =>
      dispatch(deploymentActions.destroyTerraformAction(org_temp_id, app_id)),
    fetchTaskStatusAction: (task_id, apply_type) =>
      dispatch(deploymentActions.fetchTaskStatusAction(task_id, apply_type)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Deployments);
