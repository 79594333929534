import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'

import { Graph } from 'react-d3-graph'
import { config1 as graphConfig } from '../graph-configs'
import { applicationActions } from '../../../redux/actions'
import styled from 'styled-components'

const UserAssetsWrapper = styled.div`
    width: ${props => props?.settings?.width ?? 1500}px;
    height: ${props => props?.settings?.height ?? 600}px;
    background: #F8F9F9;
    margin: 10px 10px;
    border-radius: 4px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`

// const app_id = 's8j968Tx50CCW0E351yN';
const userAssetsIcons = {
  user: './images/User.png',
  group: './images/Group.png',
  policy: './images/Policy.png',
  accesskey: './images/IAMAccessKey.png'
}

const UserAssets = props => {
  // graph payload (with minimalist structure)
  const { user_assets, fetchUserAssetsAction, settings, app_id } = props

  const [userAssetsData, setUserAssetsData] = useState(null)

  useEffect(() => {
    fetchUserAssetsAction(app_id)
  }, [app_id])

  useEffect(() => {
    if (user_assets[app_id]) {
      const userAssetsResp = user_assets[app_id].result ?? {}
      const links = [
        ...userAssetsResp?.links?.map(link => ({
          ...link,
          target: userAssetsResp.nodes.filter(node => node.name === link.target)?.[0]?.id ?? link.target
        }))
      ]
      setUserAssetsData(() => ({
        nodes: userAssetsResp.nodes.map(node => ({
          id: node.id ?? node.name,
          name: node.type === 'AccessKey' ? `${node.type}: ****${node.id.substr(-4)}` : `${node.type}: ${node.name ?? node.id}`,
          svg: userAssetsIcons?.[node.type.toLowerCase()],
          // viewGenerator: node => {
          //   return (
          //     <div>
          //       <p>{node.id}</p>
          //       <p>{node.name}</p>
          //     </div>
          //   )
          // }
        })),
        links: links.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              t => t.source === value.source && t.target === value.target
            )
        )
      }))
    }
  }, [user_assets])

  const onClickNode = function (nodeId) {
    console.log(`Clicked node ${nodeId}`)
  }

  const onClickLink = function (source, target) {
    console.log(`Clicked link between ${source} and ${target}`)
  }

  const onMouseOverNode = function (nodeId, node) {
    console.log(`Mouse over ${nodeId}`)
    console.log(node, "====== node")
  }

  let myConfig = JSON.parse(JSON.stringify(graphConfig))

  myConfig.staticGraph = false
  myConfig.collapsible = props.settings.collapsible
  myConfig.directed = props.settings.directedGraph
  myConfig.initialZoom = 0.8

  myConfig.node.size = 300
  myConfig.node.fontSize = 10
  myConfig.node.highlightFontSize = 10
  myConfig.node.labelProperty = (node) => {
    return node.name
  }

  myConfig.link.strokeWidth = 1.5
  myConfig.link.markerHeight = 4
  myConfig.link.markerWidth = 4
  myConfig.width = settings?.width ?? 1500
  myConfig.height = settings?.height ?? 600

  return (
    <>
      <UserAssetsWrapper settings={settings}>
        {userAssetsData && (
          <Graph
            id='graph-user-assets' // id is mandatory
            data={userAssetsData}
            config={myConfig}
            onClickNode={onClickNode}
            onClickLink={onClickLink}
            onMouseOverNode={onMouseOverNode}
          />
        )}
        {/* <Graph
            id='graph-user-assets' // id is mandatory
            data={graph_data}
            config={myConfig}
            onClickNode={onClickNode}
            onClickLink={onClickLink}
        /> */}
      </UserAssetsWrapper>
    </>
  )
}
function mapStateToProps (state) {
  const { user_assets } = state.applications
  return {
    user_assets
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchUserAssetsAction: app_id =>
      dispatch(applicationActions.fetchUserAssetsAction(app_id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAssets)
