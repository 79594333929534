import styled from "styled-components";

export const TabHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  // z-index: 100;
  // position: fixed;
  // top: 37px;
  // left: 50%;
  // transform: translate(-50%, 0);
`;

export const TabHeaderItem = styled.div`
  background: #fff;
  border: 1px solid #f1f5fa;
  box-sizing: border-box;
  border-radius: 4px;
  color: #808080;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.8rem;

  &:hover {
    background: #f5f5f5;
  }

  &.active {
    background: #5ba5ed;
    color: #fff;
    font-weight: 400;
  }
`;

export const TabBody = styled.div`
  z-index: 20;
`;
