import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'

import {usersActions} from "../../../redux/actions";
import {connect} from "react-redux";
import UserProfile from "./UserProfile";
import { ViewContainer } from "../../../components/PageLayout/Containers";


const ProfileLayout = (props) => {
  const { organizationList, fetchOrganizationList, userList, fetchUserListAction, expired_token, new_refresh_token } = props

  const history = useHistory()
  const [users, setUsers] = useState([])
  const [organizations, setOrganizations] = useState([])


  useEffect(() => {
    fetchOrganizationList()
    fetchUserListAction()
  }, [fetchOrganizationList, fetchUserListAction])

  useEffect(() => {
    if(!expired_token && new_refresh_token) {
      fetchOrganizationList()
      fetchUserListAction()
    }
  }, [expired_token, new_refresh_token, fetchOrganizationList, fetchUserListAction])

  useEffect(() => {
    setOrganizations([...organizationList])
  }, [organizationList])

  useEffect(() => {
    setUsers([...userList])
  }, [userList])

  return (
      <ViewContainer>
        <UserProfile users={users} organizations={organizations} />
      </ViewContainer>
  )
}

function mapStateToProps(state) {
  const {organizationList, userList} = state.users;
  const { expired_token, new_refresh_token } = state.auth;
  return {
    organizationList, userList,
    expired_token, new_refresh_token,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrganizationList: () => dispatch(usersActions.fetchOrganizationListAction()),
    fetchUserListAction: () => dispatch(usersActions.fetchUserListAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileLayout);
