import React, {useState} from 'react'
import styled from 'styled-components'
import StyledTypo from "../../components/Typo/StyledTypo";
import CommonInput from "../../components/Input/CommonInput";
import EffectedButton from "../../components/Buttons/EffectedButton";
import {DropdownMenu} from 'reactstrap'
import {showAlert, getCurrentUser} from "../../helpers/constants";
import {confirmAlert} from "react-confirm-alert";

const MainContainer = styled(DropdownMenu)`
  width: 480px;
  padding: 33px 41px;
  
  background: #FFFFFF;
  border: 2px solid #F1F5FA;
  box-sizing: border-box;
  box-shadow: 0 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 15px;
  // border-radius: ${props => props.directly ? '20px 0 20px 20px' :'20px'};
  transform: translate3d(-280px, 35px, 0px)!important;
`

const ChangePasswordPanel = (props) => {
  const { toggle, changePasswordAction } = props

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  const validateInput = () => {
    let msg = ''
    if(!oldPassword.length) {
      msg = "Please fill Old Password."
    } else if(!newPassword.length) {
      msg = "Please fill New Password"
    } else if(newPassword.length < 8) {
      msg = "New password must be 8 characters at least."
    } else if(confirmNewPassword !== newPassword) {
      msg = "Confirm New Password must be same as New Password."
    }
    if(msg.length) {
      showAlert('warning', "Invalid input", msg)
      return false
    }
    return true
  }

  const submitChangePassword = () => {
    const data = {
      email: getCurrentUser()?.email,
      old_password: oldPassword,
      new_password: newPassword
    }
    changePasswordAction(data)
  }

  const changePasswordConfirm = () => {
    if(!validateInput()) return
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <div className='custom-ui'>
              <h1>Are you sure?</h1>
              <p>You want to change password?</p>
              <button onClick={onClose}>No</button>
              <button
                  onClick={() => {
                    submitChangePassword()
                    onClose();
                  }}
              >
                Confirm
              </button>
            </div>
        );
      }
    });
  };

  return (
      <MainContainer >
        <div className="title mb-2">
          <StyledTypo color="#A4AFB7" size={1.125} line={1.4} letter="0" family="Roboto" fontWeight={700}>
            Change Password
          </StyledTypo>
        </div>
        <div className="sub-title mb-4">
          <StyledTypo color="#A4AFB7" size={1} line={1.4} letter="0" family="Roboto" fontWeight={700}>
            You can change password
          </StyledTypo>
        </div>
        <div>
          <CommonInput label="Old Password" value={oldPassword} setValue={setOldPassword} active={false} locked={false} inputType="password"
                       placeholder=""/>
        </div>
        <div>
          <CommonInput label="New Password" value={newPassword} setValue={setNewPassword} active={false} locked={false} inputType="password"
                       placeholder=""/>
        </div>
        <div>
          <CommonInput label="Confirm New Password" value={confirmNewPassword} setValue={setConfirmNewPassword} active={false} locked={false} inputType="password"
                       placeholder=""/>
        </div>

        <div className="d-flex align-items-center justify-content-between mt-5">
          <div>
            <EffectedButton marginLeft="0" onClick={changePasswordConfirm}>
              <StyledTypo color="#fff" size={1.125} line={25.2 / 18} letter="0" family="Roboto" fontWeight={500}>
                Change
              </StyledTypo>
            </EffectedButton>
          </div>
          <div>
            <EffectedButton marginLeft="0" background="#485DCD" onClick={toggle}>
              <StyledTypo color="#fff" size={1.125} line={25.2 / 18} letter="0" family="Roboto" fontWeight={500}>
                Cancel
              </StyledTypo>
            </EffectedButton>
          </div>
        </div>
      </MainContainer>
  )
}

export default ChangePasswordPanel
