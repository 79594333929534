import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/App";

import { Provider } from "react-redux";
import { store } from "./redux/store";
import "bootstrap/dist/css/bootstrap.min.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-notifications/lib/notifications.css";
import "react-complex-tree/lib/style.css";
import "./index.scss";

import reportWebVitals from "./reportWebVitals";
import * as Firebase from "./helpers/Firebase";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <App firebase={Firebase.firebaseObj} />
  </Provider>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
