import React, { useState, useEffect, Fragment } from "react";
import StyledTypo from "../../../../components/Typo/StyledTypo";
import styled from "styled-components";
import { connect } from "react-redux";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/cancel.svg";
import CommonInput from "../../../../components/Input/CommonInput";
import CommonSelect from "../../../../components/Select/CommonSelect";
import EffectedButton from "../../../../components/Buttons/EffectedButton";
import SlidePanel from "../../../../components/SlidePanel/SlidePanel";
import StyledFile from "../../../../components/Input/StyledFile";
import AWSCredPartial from "../../../modals/applicationPanel/AWSCredPartial";
import GCPCredPartial from "../../../modals/applicationPanel/GCPCredPartial";
import AzureCredPartial from "../../../modals/applicationPanel/AzureCredPartial";
import { ReactComponent as GitUrlIcon } from "../../../../assets/icons/git_link_icon.svg";
import { cspData } from "../../../../helpers/constants";
import moment from "moment";
import {
  applicationInputOption,
  convertBase64,
  showAlert,
} from "../../../../helpers/constants";
import Switch from "react-switch";

import "react-modern-drawer/dist/index.css";

const CloseWrapper = styled.div`
  border-radius: 10px;
  min-width: 45px;
  min-height: 40px;
  cursor: pointer;
  max-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ButtonGroupWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 10px;
  width: calc(100% - 20px);
`;

const DividerWrapper = styled.div`
  height: 0;
  width: calc(100% + 60px);
  margin-left: -30px;
  border-bottom: 0.5px solid #ccc;
`;

const TitleWrapper = styled.span`
  display: inline-block;
  width: 100%;
  height: 1.4em;
`;

const ContentWrapper = styled.div`
  padding: 20px;
  margin-top: -50px;
  bottom: 20px;
  left: 10px;
  width: calc(100% - 20px);
`;

const CreateInfraPanel = (props) => {
  const {
    addNewApplicationAction,
    selectedEnv,
    envData,
    addAppStatus,
    isOpen,
    toggle,
    updateProject,
    updateApplicationAction,
    resetAddAppStatusAction,
  } = props;

  const emptyOption = { value: -1, label: "None" };
  const defaultEnvOption = { value: "default", label: "Default Environment" };

  const [name, setName] = useState("");
  const [gitUrl, setGitUrl] = useState("");
  const [yamlFileName, setYamlFileName] = useState("");
  const [sshKey, setSSHKey] = useState("");
  const [inputOption, setInputOption] = useState({ ...emptyOption });

  const [fileContent, setFileContent] = useState(null);
  const [fileName, setFileName] = useState("");
  const [environment, setEnvironment] = useState({ ...defaultEnvOption });
  const [credValue, setCredValue] = useState({});
  const [auditEnabled, setAuditEnabled] = useState(true);
  const [selectedCsp, setSelectedCsp] = useState({
    ...cspData[0],
    value: cspData[0]?.key,
    label: cspData[0]?.title,
  });

  const cspOptions = cspData.map((csp) => ({
    ...csp,
    value: csp.key,
    label: csp.title,
  }));
  const envOptions = [
    { ...defaultEnvOption },
    ...(envData
      ? envData?.environments
          ?.filter((item) => item.is_active && item.key !== "default")
          .map((item) => ({ value: item.key, label: item.name }))
      : []),
  ];

  const clearValue = () => {
    setName("");
    setGitUrl("");
    setYamlFileName("");
    setSSHKey("");
    setInputOption({ ...emptyOption });
    setFileContent(null);
    setFileName("");
    setEnvironment({ ...defaultEnvOption });
    setCredValue({});
    setAuditEnabled(true);
  };

  const closePanel = () => {
    clearValue();
    toggle();
  };

  useEffect(() => {
    setEnvironment(
      selectedEnv
        ? envOptions.filter((op) => op.value === selectedEnv.key)?.[0]
        : envOptions?.[0]
    );
  }, [selectedEnv]);

  useEffect(() => {
    if (addAppStatus === 1) {
      resetAddAppStatusAction();
      closePanel();
    }
  }, [addAppStatus]);

  useEffect(() => {
    if (updateProject) {
      setEnvironment(
        envOptions.filter((op) => op.value === updateProject.environment)?.[0]
      );
      setName(updateProject?.name);
      const existingCsp = cspData.filter(
        (csp) => csp.key === updateProject.platform
      )?.[0];
      setSelectedCsp({
        label: existingCsp?.title,
        value: existingCsp?.key,
        ...existingCsp,
      });
      setAuditEnabled(updateProject?.skip_scan)
    }
  }, [updateProject]);

  const handleUploadFile = (filename, content) => {
    setFileName(filename);
    setFileContent(content);
  };

  const handleKeyChange = (filename, content) => {
    setSSHKey(convertBase64(content));
  };

  const validateInput = () => {
    let msg = "";
    const sshKeyValid =
      /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
    if (!name.length) {
      msg = "Please fill new infrastructure name.";
    } else if (inputOption.value === -1) {
      msg = "";
    } else if (
      inputOption.value === 0 &&
      gitUrl.startsWith("ssh@") &&
      !sshKey.length &&
      !updateProject
    ) {
      msg = "Please input valid SSH Key. Empty key.";
    } else if (
      inputOption.value === 0 &&
      gitUrl.startsWith("ssh@") &&
      !sshKeyValid.test(sshKey) &&
      !updateProject
    ) {
      msg =
        "Please input valid SSH Key. SSH Key format needs to be encoded by Base64.";
    } else if (inputOption.value === 0 && !yamlFileName.length) {
      msg = "Please input valid YAML file name.";
    }
    if (msg.length) {
      showAlert("warning", "Invalid input", msg);
      return false;
    }
    return true;
  };

  const submitNewApplication = () => {
    if (!validateInput()) return;
    let data = {};
    if (!updateProject) {
      data = {
        name,
        git_url: inputOption.value === 0 ? gitUrl : null,
        file_name:
          inputOption.value === 0
            ? yamlFileName
            : inputOption.value === -1
            ? null
            : fileName,
        git_key: inputOption.value === 0 ? sshKey : null,
        content: inputOption.value > 0 ? fileContent : null,
        createdDate: moment().format("YYYY-MM-DD hh:mm"),
        environment: environment.value ?? "default",
        platform: selectedCsp.key,
        skip_scan: auditEnabled
      };
      if (credValue?.credential) {
        data["credential"] = credValue?.credential;
      }

      addNewApplicationAction(data);
    } else {
      data = {
        app_id: updateProject?.id,
        name,
        environment: environment.value ?? "default",
        skip_scan: auditEnabled
      };
      if (credValue?.credential) {
        data["credential"] = credValue?.credential;
      }
      updateApplicationAction(data);
    }
  };

  return (
    <SlidePanel
      slideId="create-infra-project-panel"
      isOpen={isOpen}
      toggle={closePanel}
    >
      <div className="p-3 position-relative mb-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <CloseWrapper className="cursor-pointer" onClick={closePanel}>
            <CloseIcon width={22} fill="#505050" />
          </CloseWrapper>
          <TitleWrapper>
            <StyledTypo className="text-center heading ln-2">
              {`${updateProject ? "Update" : "Add"}`} Cloud Infrastructure
            </StyledTypo>
          </TitleWrapper>
        </div>
        <DividerWrapper className="mb-3" />
      </div>
      {/* <CommonSelect
        value={inputOption}
        setValue={setInputOption}
        options={options}
        label="Input Option"
        onChange={handleModeChange}
      /> */}
      <ContentWrapper>
        <CommonInput
          label="Infrastructure Name"
          value={name}
          setValue={setName}
          active={false}
          locked={false}
          placeholder=""
        />
        <CommonSelect
          value={selectedCsp}
          setValue={setSelectedCsp}
          options={cspOptions}
          label="Cloud Service Provider"
          disabled={!!updateProject}
        />
        <div></div>
        <CommonSelect
          value={environment}
          setValue={setEnvironment}
          options={envOptions}
          label="Environment"
        />
        <div className="d-flex align-items-center mt-4">
          <StyledTypo
            className="mr-5"
          >
            Audit
          </StyledTypo>
          <Switch
            onChange={(checked) =>
              setAuditEnabled(checked)
            }
            checked={auditEnabled}
            onColor="#65C2D7"
            offColor="#ff487f"
          />
        </div>
        {inputOption.value === 0 ? (
          <>
            <div>
              <CommonInput
                label="Git Repository Link"
                value={gitUrl}
                setValue={setGitUrl}
                active={false}
                locked={false}
                icon={GitUrlIcon}
                placeholder=""
              />
            </div>
            <div className="d-flex">
              <div style={{ width: "80%" }}>
                <CommonInput
                  label="SSH Key"
                  value={sshKey}
                  setValue={setSSHKey}
                  active={false}
                  locked={false}
                  placeholder=""
                />
              </div>
              <StyledFile
                hiddenId="hiddenKeyInputField"
                onChange={handleKeyChange}
              />
            </div>
            <div>
              <CommonInput
                label="YAML File Name"
                value={yamlFileName}
                setValue={setYamlFileName}
                active={false}
                locked={false}
                placeholder=""
              />
            </div>
          </>
        ) : inputOption.value === -1 ? (
          <Fragment>
            {selectedCsp?.key === "aws" && (
              <AWSCredPartial
                credValue={credValue}
                setCredValue={setCredValue}
              />
            )}
            {selectedCsp?.key === "gcp" && (
              <GCPCredPartial
                credValue={credValue}
                setCredValue={setCredValue}
              />
            )}
            {selectedCsp?.key === "azure" && (
              <AzureCredPartial
                credValue={credValue}
                setCredValue={setCredValue}
              />
            )}
          </Fragment>
        ) : (
          <StyledFile
            // label="Upload a file"
            extensions={
              inputOption?.value === 1
                ? ["yml", "yaml"]
                : inputOption?.value === 2
                ? ["json"]
                : []
            }
            isShowName
            className="mt-4"
            onChange={handleUploadFile}
            hiddenId="hiddenAppInputField"
          />
        )}
        {updateProject && (
          <div className="d-flex justify-content-between align-items-center mt-4">
            <StyledTypo size={0.8}>Created Date</StyledTypo>
            <StyledTypo size={0.8}>
              {moment(updateProject?.updated_timestamp).format(
                "MM-DD-YYYY hh:mm"
              )}
            </StyledTypo>
          </div>
        )}
      </ContentWrapper>
      <ButtonGroupWrapper className="col-12 px-3 d-flex mt-5 align-items-center justify-content-end">
        <EffectedButton
          className="ml-5"
          padding={20}
          height={32}
          noSpan
          marginLeft="0px"
          onClick={submitNewApplication}
          background="#5EB1E4"
          width={90}
        >
          <StyledTypo color="#fff" className="label">
            {`${updateProject ? "Save" : "Add"}`}
          </StyledTypo>
        </EffectedButton>
        <EffectedButton
          className="ml-3"
          padding={20}
          height={32}
          noSpan
          marginLeft="0px"
          onClick={closePanel}
          width={90}
        >
          <StyledTypo color="#fff" className="label">
            Cancel
          </StyledTypo>
        </EffectedButton>
      </ButtonGroupWrapper>
    </SlidePanel>
  );
};

export default connect(undefined, undefined)(CreateInfraPanel);
