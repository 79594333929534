import React, { useState, useEffect, useRef, Fragment } from "react";
import styled from "styled-components";
import Hexagon from "react-hexagon";

import Gradient from "./Gradient";
import Shadow from "./Shadow";
import { Tooltip } from "reactstrap";
import moment from "moment";
import StyledTypo from "../../../components/Typo/StyledTypo";

const Wrapper = styled.div`
  //min-height: 200px;
  padding: 40px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));

  svg {
    width: ${(props) => props.hexWidth}px;
  }
`;

const Row = styled.div`
  > * {
    &:not(:last-child) {
      margin-right: 2px;
    }
  }

  &:nth-child(1n + 2) {
    margin-top: calc(
      (${(props) => props.hexWidth}px * 0.375 * -1) +
        ${(props) => props.hexWidth / 12}px + 2px
    );
  }

  &:nth-child(odd) > :first-child {
    margin-left: calc((${(props) => props.hexWidth}px / 2) + 1px);
  }
`;

const StyledLegend = styled.div`
  display: flex;
  gap: 7px;
  .legend {
    width: 15px;
    height: 15px;
    background-color: ${(props) => props.color};
  }
  align-items: center;
`;

const LegendWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const LegendContainer = styled.div`
  padding: 5px 20px;
  border-radius: 10px;
  background-color: #f1f1f1;
  margin-left: 20px;
  box-shadow: 0px 6px 4px rgb(104 112 137 / 5%);
  margin-bottom: 20px;
`;

const Hex = ({ children, style, data, strokeWidth, key, ...rest }) => (
  <Hexagon
    style={{
      fill:
        data.health >= 0 && data.health <= 20
          ? "url(#inactive)"
          : data.health > 20 && data.health < 80
          ? "url(#normal)"
          : "url(#perfect)",
      filter: "url(#filter)",
      stroke: "#606F7D",
      strokeWidth,
      ...style,
    }}
    className="hexaPolygon"
    {...rest}
  >
    {children}
  </Hexagon>
);

const HexaGrid = (props) => {
  const { data } = props;
  const [gridWidth, setGridWidth] = useState(0);
  const [hexWidth] = useState(32);
  const [strokeWidth] = useState(4);
  const [hexes, setHexes] = useState(
    new Array(70).fill(0).map((item, idx) => ({
      health: Math.floor(Math.random() * 100),
      id: `hexa-${idx}`,
      name: `App ${idx + 1}`,
      active: false,
    }))
  );

  let gridRef = useRef(null);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    updateWidth();

    return function cleanup() {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      setHexes([
        ...data.map((item, idx) => ({
          health: item.health,
          id: `hexa-${idx}`,
          cluster: item?.resource?.cluster?.length,
          node: item?.resource?.cluster
            ?.map((cluster) =>
              cluster?.node?.map((node) => ({
                name: node.self.name,
              }))
            )
            .flat().length,
          pod: item?.resource?.cluster
            ?.map((cluster) =>
              cluster?.pod?.map((pod) => ({
                name: pod.self.name,
              }))
            )
            .flat().length,
          service: item?.resource?.cluster
            ?.map((cluster) =>
              cluster?.service?.map((service) => ({
                name: service.self.name,
              }))
            )
            .flat().length,
          time: moment(parseInt(item.time) * 1000).format("MMM Do, YYYY"),
          active: false,
        })),
      ]);
    }
  }, [data]);

  const updateWidth = () => {
    if (gridRef && gridRef.current) {
      const computedWidth = window
        .getComputedStyle(gridRef.current)
        .getPropertyValue("width")
        .replace("px", "");
      setGridWidth(parseInt(computedWidth, 10) - 20);
    }
  };

  const getRows = () => {
    const hexesPerRow = Math.floor(gridWidth / (hexWidth + strokeWidth));
    const rows = hexes.map((hex, index) =>
      index % hexesPerRow === 0 ? buildRow(hexesPerRow, index) : null
    );
    return rows.filter((item) => item);
  };

  const buildRow = (hexesPerRow, index) => {
    const even = (index / hexesPerRow) % 2 === 0;
    const sliceEnd = even ? hexesPerRow - 1 : hexesPerRow;
    return hexes.slice(index, index + sliceEnd);
  };

  return (
    <Wrapper ref={gridRef} hexWidth={hexWidth}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div className="font-size-36 font-weight-bold m-gray mb-4">
            History Data
          </div>
          <StyledTypo
            className="ml-2 mb-3"
            color="#A4AFB7"
            size={1}
            line={1.4}
            letter="0"
            family="Roboto"
            fontWeight={500}
          >
            (last 30 days)
          </StyledTypo>
        </div>
        <LegendContainer>
          <StyledTypo
            className="mb-1"
            color="#505050"
            size={1}
            line={1.4}
            letter="0"
            family="Roboto"
            fontWeight={500}
          >
            Health Score
          </StyledTypo>
          <LegendWrapper>
            <StyledLegend color="#61bf92">
              <div className="legend" />
              <StyledTypo
                color="#505050"
                size={0.875}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                over 80%
              </StyledTypo>
            </StyledLegend>
            <StyledLegend color="#FFBA00">
              <div className="legend" />
              <StyledTypo
                color="#505050"
                size={0.875}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                between 20% and 80%
              </StyledTypo>
            </StyledLegend>
            <StyledLegend color="#ff487f">
              <div className="legend" />
              <StyledTypo
                color="#505050"
                size={0.875}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                below 20%
              </StyledTypo>
            </StyledLegend>
          </LegendWrapper>
        </LegendContainer>
      </div>
      <div />
      {getRows().map((row, index) => (
        <Row key={index} hexWidth={hexWidth}>
          {row.map((hex, index) => (
            <span id={hex.id}>
              <Hex key={index} data={hex} strokeWidth={strokeWidth}>
                <Gradient id="normal" color="#FFBA00" />
                <Gradient id="perfect" color="#61bf92" />
                <Gradient id="inactive" color="#ff487f" />
                <Shadow id="filter" />
              </Hex>
              <Tooltip
                placement="top"
                isOpen={hexes.filter((item) => item.id === hex.id)?.[0]?.active}
                autohide={false}
                target={hex.id}
                toggle={() =>
                  setHexes((hexes) => [
                    ...hexes.map((item) => ({
                      ...item,
                      active: hex.id === item.id ? !item.active : item.active,
                    })),
                  ])
                }
              >
                <div className="px-2 pt-2">
                  <StyledTypo
                    color="#fff"
                    size={1}
                    line={1.4}
                    letter="0"
                    family="Roboto"
                    fontWeight={700}
                  >
                    {hex.time}
                  </StyledTypo>
                  <div className="text-left my-3 ">
                    <StyledTypo
                      color="#65C2D7"
                      size={1}
                      line={1.4}
                      letter="0"
                      family="Roboto"
                      fontWeight={500}
                    >
                      {`Cluster: ${hex.cluster}`}
                    </StyledTypo>
                    <StyledTypo
                      color="#65C2D7"
                      size={1}
                      line={1.4}
                      letter="0"
                      family="Roboto"
                      fontWeight={500}
                    >
                      {`Node: ${hex.node}`}
                    </StyledTypo>
                    <StyledTypo
                      color="#65C2D7"
                      size={1}
                      line={1.4}
                      letter="0"
                      family="Roboto"
                      fontWeight={500}
                    >
                      {`Pod: ${hex.pod}`}
                    </StyledTypo>
                    <StyledTypo
                      color="#65C2D7"
                      size={1}
                      line={1.4}
                      letter="0"
                      family="Roboto"
                      fontWeight={500}
                    >
                      {`Service: ${hex.service}`}
                    </StyledTypo>
                    <StyledTypo
                      color="#65C2D7"
                      size={1}
                      line={1.4}
                      letter="0"
                      family="Roboto"
                      fontWeight={500}
                    >
                      {`Health: ${hex.health}%`}
                    </StyledTypo>
                  </div>
                </div>
              </Tooltip>
            </span>
          ))}
        </Row>
      ))}
    </Wrapper>
  );
};

export default HexaGrid;
