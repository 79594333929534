import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import StyledTypo from "../../../components/Typo/StyledTypo";
import { GradientCircularProgress } from "react-circular-gradient-progress";
import GradientProgressBar from "../../../components/GradientProgressBar/GradientProgressBar";
import {
  providerComplianceType,
  dashboardOverviewList,
  NonComplianceStatusColor,
  providerResourceTypes,
  loadingBar,
  aggregatedData,
  commonTitle,
  cspData,
  topPriorityTabs,
  topRisksbyCategoryTabs,
  dummyPriorIssuesData,
  priorityColumns,
  columns
} from "../../../helpers/constants";
import { ReactComponent as AssetsMenuIcon } from "../../../assets/icons/assets-menu-icon.svg";
import { ReactComponent as CloudSecurityIcon } from "../../../assets/icons/cloud-security.svg";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { dashboardActions, applicationActions } from "../../../redux/actions";
import { connect } from "react-redux";
import { useTitle } from "../../../helpers/useTtitle";
import CommonTable from "./CommonTable";


const PanelWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  box-sizing: border-box;
  padding: 0 30px;

  .top-section {
    padding-top: 15px;
    height: 50%;
  }

  .bottom-section {
    padding-top: 15px;
    height: 50%;
  }

  .panel-title-header {
    padding: 0 0 47px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .CircularProgress {
    position: relative;
  }

  #cpg-main {
    top: 12px;
  }
`;

const TabPanelBodyWrapper = styled.div`
  width: calc(100vw - 195px);
  padding: 10px 30px;

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 15px;
  }
`;
const StatsTabWrapper = styled.div`
  padding: 10px 0px;
`;

const TopPriorityPanel = (props) => {
  const { 
    top_priority, top_risks,
    fetchTopAttackPathsAction, 
    fetchTopInfrasAction, 
    fetchTopNoncompliancesAction,
    fetchTopIdentityAccessAction,
    fetchTopContainersAction,
    fetchTopClustersAction,
    fetchTopDataSecurityAction,
    fetchTopPublicExposureAction,
    fetchTopSoftwareUpdateAction,
  } = props

  useEffect(() => {
    fetchTopAttackPathsAction()
    fetchTopInfrasAction()
    fetchTopNoncompliancesAction()

    fetchTopIdentityAccessAction()
    fetchTopContainersAction()
    fetchTopClustersAction()
    fetchTopDataSecurityAction()
    fetchTopPublicExposureAction()
    fetchTopSoftwareUpdateAction()
  }, [])

  return (
    <PanelWrapper>
      <div className="top-section">
        <StyledTypo
          fontWeight={400}
          fontSize={0.8}
          line = '1.8'
        >
          Top Priority
        </StyledTypo>
        <StatsTabWrapper>
          <Tabs>
            <TabList>
              {topPriorityTabs.map((item, idx) => (
                <Tab key={`${item.key}-tab-header`}>
                  <StyledTypo
                    line="1.8"
                    size={0.8}
                    family="Poppins"
                    fontWeight={300}
                  >
                    {item.label}
                  </StyledTypo>
                </Tab>
              ))}
            </TabList>
            {topPriorityTabs.map((item, idx) => (
              <TabPanel>
                <TabPanelBodyWrapper key={`${item.key}-tab-body`}>
                  <CommonTable 
                    data={top_priority?.[item.key] ?? []}
                    columns={columns?.top_priority?.[item.key]}
                    options={{ noPagination: true }}
                    hasDetail={item.key === 'attack_paths'}
                    type={item.key}
                  />
                </TabPanelBodyWrapper>
              </TabPanel>
            ))}
          </Tabs>
        </StatsTabWrapper>
      </div>
      <div className="bottom-section">
        <StyledTypo
          fontWeight={400}
          fontSize={0.8}
          line = '1.8'
        >
          Top Risks by Category
        </StyledTypo>
        <StatsTabWrapper>
          <Tabs>
            <TabList>
              {topRisksbyCategoryTabs.map((item, idx) => (
                <Tab key={`${item.key}-tab-header`}>
                  <StyledTypo
                    line="1.8"
                    size={0.8}
                    family="Poppins"
                    fontWeight={300}
                  >
                    {item.label}
                  </StyledTypo>
                </Tab>
              ))}
            </TabList>
            {topRisksbyCategoryTabs.map((item, idx) => (
              <TabPanel>
                <TabPanelBodyWrapper key={`${item.key}-tab-body`}>
                  <CommonTable 
                    data={top_risks?.[item.key] ?? []}
                    columns={columns?.top_risks_by_category?.[item.key]}
                    options={{ noPagination: true }}
                    type={item.key}
                  />
                </TabPanelBodyWrapper>
              </TabPanel>
            ))}
          </Tabs>
        </StatsTabWrapper>
      </div>
    </PanelWrapper>
  );
};

function mapStateToProps(state) {
  const { top_priority, top_risks } = state.applications

  return {
    top_priority, top_risks
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTopAttackPathsAction: () => dispatch(applicationActions.fetchTopAttackPathsAction()),
    fetchTopInfrasAction: () => dispatch(applicationActions.fetchTopInfrasAction()),
    fetchTopNoncompliancesAction: () => dispatch(applicationActions.fetchTopNoncompliancesAction()),
    fetchTopIdentityAccessAction: () => dispatch(applicationActions.fetchTopIdentityAccessAction()),
    fetchTopContainersAction: () => dispatch(applicationActions.fetchTopContainersAction()),
    fetchTopClustersAction: () => dispatch(applicationActions.fetchTopClustersAction()),
    fetchTopDataSecurityAction: () => dispatch(applicationActions.fetchTopDataSecurityAction()),
    fetchTopPublicExposureAction: () => dispatch(applicationActions.fetchTopPublicExposureAction()),
    fetchTopSoftwareUpdateAction: () => dispatch(applicationActions.fetchTopSoftwareUpdateAction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopPriorityPanel);
// export default connect(undefined, undefined)(TopPriorityPanel);
