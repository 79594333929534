import { authConstants } from "../constants";

const initialState = {
  loggedInUser: {},
  registeredUser: {},
  registeringFlag: 0,
  expired_token: false,
  new_refresh_token: false,
  defused_refresh_token: false,

  loggedInStatus: false,

  forgot_password_flag: false,
  change_password_flag: false,
  recap_secret_key: null
}

export function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOG_IN_REQUEST:
      return {...state, loggedInUser: {}, loggedInStatus: false}
    case authConstants.LOG_IN_SUCCESS:
      return {...state, loggedInUser: action.user, loggedInStatus: true}
    case authConstants.LOG_IN_FAILURE:
      return {...state, loggedInUser: {}, loggedInStatus: false}

    case authConstants.SET_LOGGED_IN_USER:
      return {...state, loggedInUser: action.loggedInUser}

    case authConstants.SET_LOGGED_IN_STATUS:
      return {...state, loggedInStatus: action.loggedInStatus}

    case authConstants.LOG_OUT_REQUEST:
      return { ...state, loggedInUser: {}, loggedInStatus: false }

    case authConstants.REGISTER_REQUEST:
      return { ...state, registeringFlag: 1 }
    case authConstants.REGISTER_SUCCESS:
      return { ...state, registeredUser: action.user, registeringFlag: 2 }
    case authConstants.REGISTER_FAILURE:
      return { ...state, registeredUser: {}, registeringFlag: 3 }
    case authConstants.CLEAR_REGISTER_FLAG:
      return { ...state, registeredUser: {}, registeringFlag: 0 }

    case authConstants.INITIALIZE_REGISTER_USER:
      return { ...state, registeredUser: {} }

    case authConstants.EXPIRED_TOKEN:
      return {
        ...state,
        expired_token: state.expired_token || (!state.expired_token && !state.new_refresh_token)
      }

    case authConstants.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        new_refresh_token: true,
        expired_token: false,
      }
    case authConstants.REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        new_refresh_token: false,
        expired_token: false,
        defused_refresh_token: true,
      }
    case authConstants.CLEAR_EXPIRED_TOKEN:
      return {
        ...state,
        expired_token: false
      }

    case authConstants.CLEAR_NEW_REFRESH_TOKEN:
      return {
        ...state,
        new_refresh_token: false
      }

    case authConstants.CLEAR_DEFUSE_REFRESH_TOKEN:
      return {
        ...state,
        defused_refresh_token: false,
      }

    case authConstants.FORGOT_PASSWORD_REQUEST:
      return { ...state, forgot_password_flag: false }
    case authConstants.FORGOT_PASSWORD_SUCCESS:
      return { ...state, forgot_password_flag: true }
    case authConstants.FORGOT_PASSWORD_FAILURE:
    case authConstants.CLEAR_FORGOT_PASSWORD_FLAG:
      return { ...state, forgot_password_flag: false }

    case authConstants.CHANGE_PASSWORD_REQUEST:
      return { ...state, change_password_flag: false }
    case authConstants.CHANGE_PASSWORD_SUCCESS:
      return { ...state, change_password_flag: true }
    case authConstants.CHANGE_PASSWORD_FAILURE:
    case authConstants.CLEAR_CHANGE_PASSWORD_FLAG:
      return { ...state, change_password_flag: false }


    case authConstants.FETCH_RECAP_SECRET_KEY_SUCCESS:
      return {
        ...state,
        recap_secret_key: action?.res?.recaptcha_secret_key
      }

    default:
      return { ...state }
  }
}
