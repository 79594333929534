import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const config = {
  "apiKey": process.env.REACT_APP_API_KEY ?? "AIzaSyAcINMps89m_xLvL34JHLaJ4-RLA3cqp4E",
  "authDomain": process.env.REACT_APP_AUTH_DOMAIN ?? "matos-sphere-saas.firebaseapp.com",
  "databaseURL": process.env.REACT_APP_DATABASE_URL ?? "https://matos-sphere-saas.firebaseio.com",
  "projectId": process.env.REACT_APP_PROJECT_ID ?? "matos-sphere-saas",
  "storageBucket": process.env.REACT_APP_STORAGE_BUCKET ?? "matos-sphere-saas.appspot.com",
  "messagingSenderId": process.env.REACT_APP_MESSAGING_SENDER_ID ?? "1032977691525",
  "appId": process.env.REACT_APP_APP_ID ?? "1:1032977691525:web:50740bb29208bd64ca0aec",
  "measurementId": process.env.REACT_APP_MEASUREMENT_ID ?? "G-T1VL6QC22E",
}

class Firebase {
  constructor() {
    app.initializeApp(config)
    this.auth = app.auth()
    this.db = app.firestore()
    this.authObj = app.auth
    this.provider = new app.auth.GoogleAuthProvider();
    this.provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
      this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWidthEmailAndPassword = (email, password) =>
      this.auth.signInWithEmailAndPassword(email, password)

  doSignOut = () => this.auth.signOut();

  sendPasswordResetEmail = email => this.auth.sendPasswordResetEmail(email)

  handleVerifyResetCode = (actionCode) => this.auth.verifyPasswordResetCode(actionCode)

  handleConfirmNewPassword = (actionCode, newPwd) => this.auth.confirmPasswordReset(actionCode, newPwd)

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password)

  getUsers = () => this.db.collection('users').get()

  getUser = (uuid) => this.db.collection('users').doc(uuid).get()

  setUser = (uuid, user) => this.db.collection('users').doc(uuid).set(user)

  getLoggedInUser = (uuid) => this.db.collection('users').doc(uuid).get()

  modifyUser = (uuid, data) => this.db.collection('users').doc(uuid).set(data)
}

export const firebaseObj = new Firebase()

// export const getFirebase = () => {
//   app.initializeApp(config)
//   const auth = app.auth()
//   const db = app.firestore()
//   const authObj = app.auth
//   const provider = new app.auth.GoogleAuthProvider();
//   provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
//
//   return {auth, db, authObj, provider}
// }

