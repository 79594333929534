import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { applicationActions } from "../../../redux/actions";
import { connect } from "react-redux";
import * as global from "../../../helpers/constants";
import StyledTypo from "../../../components/Typo/StyledTypo";
import ClusterSummaryTab from "./cluster/ClusterSummaryTab";
import ClusterDetailsTab from "./cluster/ClusterDetailsTab";
import { ReactComponent as LeftArrow } from "../../../assets/icons/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../../assets/icons/right-arrow.svg";
import { MainContext } from "../../../contexts";
import {
  ViewContainer,
  PageTitle,
  FlexComp,
} from "../../../components/PageLayout/Containers";
import {
  TabHeader,
  TabHeaderItem,
  TabBody,
} from "../../../components/PageLayout/Tab";
import { TableWrapper } from "../../../components/PageLayout/Table";
import { useTitle } from "../../../helpers/useTtitle";

const BackButton = styled.a`
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  color: #505050;
  :hover {
    text-decoration: none;
  }
`;

const MoveCTAWrapper = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  // align-items: center;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  cursor: pointer;
`;

const ClusterLayout = (props) => {
  const {
    id: cluster_id,
    clusterDetailLoading,
    clusterSummaryLoading,
    fetchKspmClusterSummaryAction,
    fetchKspmClusterDetailAction,
    clusters, cluster_summary, cluster_detail
  } = props;

  const history = useHistory();
  const filterPanelRef = useRef();
  const headerRef = useRef();

  const { gInfras } = useContext(MainContext);

  const [tabs, setTabs] = useState(global.cwpTabList);
  const [selectedTab, setSelectedTab] = useState(null);

  const [selectedFilterType, setSelectedFilterType] = useState(null);
  const [preFilterData, setPreFilterData] = useState();
  const [_, setRenderFlag] = useState(false);
  const [pageTitle, setPageTitle] = useState(global.commonTitle)

  const location = useLocation();

  const [selectedCluster, setSelectedCluster] = useState({})

  const clustersData = [...(clusters ?? [])];

  useTitle(pageTitle)

  useEffect(() => {
    setPageTitle(`${global.commonTitle} - Cloud Security - KSPM - ${selectedCluster?.cluster_name}`)
  }, [selectedCluster])

  useEffect(() => {
    setSelectedTab(
      location?.search
        ? new URLSearchParams(location?.search).get("tab")
        : tabs.filter((item) => item.active)?.[0]?.key
    );
  }, [setSelectedTab]);

  useEffect(() => {
    if (selectedTab) {
      handleSelectedTab(selectedTab);
    }
  }, [selectedTab]);

  const handleSelectedTab = (key) => {
    setPreFilterData(null);
    setTabs([...tabs.map((tab) => ({ ...tab, active: tab.key === key }))]);
  };

  useEffect(() => {
    const selectedInfra = gInfras.filter(
      (cluster) => cluster.cluster_id === cluster_id
    )?.[0];
    if (selectedInfra) {
      selectedCluster?.cluster_id !== cluster_id && setSelectedCluster({ ...selectedInfra });
    } else {
      history.push("/kspm");
    }
  }, [cluster_id]);

  const onLink = (data, tab = "details") => {
    handleSelectedTab(tab);
    setPreFilterData({ ...data });
  };

  const findIndex = () => {
    return gInfras.findIndex((cluster) => cluster.cluster_id === cluster_id);
  };

  const findId = (up = true) => {
    const idx = findIndex();
    const targetIdx = up ? idx + 1 : idx - 1;
    return gInfras.filter((project, idx) => idx === targetIdx)?.[0]?.id;
  };

  useEffect(() => {
    if (cluster_id) {
      fetchKspmClusterSummaryAction(cluster_id);
      fetchKspmClusterDetailAction(cluster_id);
    }
  }, [cluster_id, fetchKspmClusterDetailAction, fetchKspmClusterSummaryAction]);

  return (
    <ViewContainer>
      <div className="main-container d-flex flex-column">
        <PageTitle
          className="pb-2 d-flex align-items-center justify-content-between"
          ref={headerRef}
        >
          <StyledTypo className="ml-2 heading">
            <BackButton
              onClick={() => history.push("/cloud")}
              title="Back"
              href="#"
            >
              {" "}
              Cloud Security{" "}
            </BackButton>{" - "}
            <BackButton
              onClick={() => history.push("/kspm")}
              title="Back"
              href="#"
            >
              {" "}
              KSPM{" "}
            </BackButton>{" - "}<strong>{selectedCluster?.cluster_name}</strong>
          </StyledTypo>
          <TabHeader>
            {tabs.map((tab) => (
              <TabHeaderItem
                className={tab.active && "active"}
                onClick={() => handleSelectedTab(tab.key)}
              >
                {tab.title}
              </TabHeaderItem>
            ))}
          </TabHeader>
          <FlexComp gap="20px">
            {!!tabs.filter((tab) => tab.key === "details" && tab.active)
              .length && filterPanelRef?.current?.getFilterPanel()}
            <FlexComp gap="0px">
              <StyledTypo className="mr-2">
                {findIndex() + 1} of {clustersData.length}
              </StyledTypo>
              <MoveCTAWrapper
                disabled={findIndex() === 0}
                onClick={() => history.push(`/kspm/${findId(false)}`)}
              >
                <LeftArrow fill={findIndex() === 0 ? "#A4AFB7" : "#505050"} />
              </MoveCTAWrapper>
              <MoveCTAWrapper
                disabled={findIndex() + 1 === clustersData.length}
                onClick={() => history.push(`/kspm/${findId()}`)}
              >
                <RightArrow
                  fill={
                    findIndex() + 1 === clustersData.length ? "#A4AFB7" : "#505050"
                  }
                />
              </MoveCTAWrapper>
            </FlexComp>
          </FlexComp>
        </PageTitle>
        <TableWrapper
          marginTop={`${
            headerRef?.current?.offsetHeight + headerRef?.current?.offsetTop ||
            100
          }px`}
        >
          <TabBody>
            {!!tabs.filter((tab) => tab.key !== "details" && tab.active)
              .length && (
              <ClusterSummaryTab
                cluster={selectedCluster}
                detail={cluster_detail?.[cluster_id]}
                summary={cluster_summary?.[cluster_id]}
                clusterInfoLoading={clusterSummaryLoading}
                hideActivity={true}
                type={
                  tabs.filter((tab) => tab.key !== "details" && tab.active)?.[0]
                    ?.key
                }
                onLink={onLink}
              />
            )}
            {!!tabs.filter((tab) => tab.key === "details" && tab.active)
              .length && (
              <ClusterDetailsTab
                data={cluster_detail?.[cluster_id]}
                dataLoading={clusterDetailLoading}
                selectedCluster={selectedCluster}
                setSelectedFilterType={setSelectedFilterType}
                selectedFilterType={selectedFilterType}
                preFilterData={preFilterData}
                ref={filterPanelRef}
                setRenderFlag={setRenderFlag}
              />
            )}
          </TabBody>
        </TableWrapper>
      </div>
    </ViewContainer>
  );
};

function mapStateToProps(state) {
  const {
    clusters, cluster_summary, cluster_detail, clusterDetailLoading, clusterSummaryLoading,
  } = state.applications;
  return {
    clusters, cluster_summary, cluster_detail, clusterDetailLoading, clusterSummaryLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchKspmClusterSummaryAction: (cluster_id) => dispatch(applicationActions.fetchKspmClusterSummaryAction(cluster_id)),
    fetchKspmClusterDetailAction: (cluster_id) => dispatch(applicationActions.fetchKspmClusterDetailAction(cluster_id))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClusterLayout);
