import React, { useEffect, useState } from "react";
import StyledTypo from "../../../../components/Typo/StyledTypo";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/cancel.svg";
import "react-modern-drawer/dist/index.css";
import { Col, Row } from "reactstrap";
import SlidePanel from "../../../../components/SlidePanel/SlidePanel";
import { ReactComponent as LeftArrow } from "../../../../assets/icons/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../../../assets/icons/right-arrow.svg";

import moment from "moment";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { coldarkCold } from "react-syntax-highlighter/dist/esm/styles/prism";
import { NonComplianceStatusColor, cspData } from "../../../../helpers/constants";

const StyledStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 65px;

  margin-right: ${props => (props.mr ? props.mr : 20)}px;

  .mark {
    width: ${props => (props.size ? props.size : 12)}px;
    height: ${props => (props.size ? props.size : 12)}px;
    border-radius: 50%;
    background-color: ${props => props.color};
    // box-shadow: 0 8px 6px -3px ${props => props.color};
  }
`

const DividerWrapper = styled.div`
  height: 0;
  width: calc(100% + 60px);
  margin-left: -30px;
  border-bottom: 0.5px solid #ccc;
`;

const CloseWrapper = styled.div`
  border-radius: 10px;
  min-width: 45px;
  min-height: 40px;
  cursor: pointer;
  max-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MoveCTAWrapper = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  // align-items: center;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  cursor: pointer;
`;

const SeverityWrapper = styled.span`
  position: absolute;
  left: calc(100% - 240px);
`;

const TitleWrapper = styled.span`
  display: inline-block;
  width: 58%;
  height: 1.4em;
`;

const InfraDetail = (props) => {
  const {
    data,
    isOpen,
    toggle,
    setOpenItemId,
    findIndex,
    findId,
    projectsCnt,
  } = props;

  return (
    <SlidePanel isOpen={isOpen} toggle={toggle} slideId="infra-detail-slide">
      <div className="p-3 position-relative mb-3">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <CloseWrapper className="cursor-pointer" onClick={toggle}>
            <CloseIcon width={22} fill="#505050" />
          </CloseWrapper>
          {/* <SeverityWrapper>
            <Badge
              color={
                resources.filter((res) => res.status.toLowerCase() === "fail")
                  .length > 0 ||
                resources.filter((res) => res.status.toLowerCase() === "info")
                  .length > 0
                  ? data?.severity?.toLowerCase() ?? "low"
                  : "low"
              }
              pill
            >
              Resources:{" "}
              {data?.templates?.filter(
                (res) =>
                  res.status.toLowerCase() === "fail" ||
                  res.status.toLowerCase() === "info"
              ).length ?? 0}{" "}
              {" / "} {data?.templates?.length ?? 0}
            </Badge>
          </SeverityWrapper> */}
          <TitleWrapper>
            <StyledTypo className="text-center heading ln-2">
              {data?.name}
            </StyledTypo>
          </TitleWrapper>
          <div className="d-flex align-items-center">
            <StyledTypo className="mr-2" size="0.8">
              {findIndex() + 1} of {projectsCnt}
            </StyledTypo>
            <MoveCTAWrapper
              disabled={findIndex() === 0}
              onClick={() => setOpenItemId(findId(false))}
            >
              <LeftArrow fill={findIndex() === 0 ? "#A4AFB7" : "#505050"} />
            </MoveCTAWrapper>
            <MoveCTAWrapper
              disabled={findIndex() + 1 === projectsCnt}
              onClick={() => setOpenItemId(findId(true))}
            >
              <RightArrow
                fill={findIndex() + 1 === projectsCnt ? "#A4AFB7" : "#505050"}
              />
            </MoveCTAWrapper>
          </div>
        </div>
        <DividerWrapper className="mb-3" />
        <Row>
          <Col md={12} className="position-static d-flex p-0 mt-2">
            <Col md={2}>
              <StyledTypo className="mt-2 mr-3 label">Cloud Service Provider</StyledTypo>
            </Col>
            <Col md={10}>
              {cspData
                  .filter((csp) => csp.key === data.platform)?.[0]
                  ?.logo(30)}
            </Col>
          </Col>
          <Col md={12} className="position-static d-flex p-0 mt-2">
            <Col md={2}>
              <StyledTypo className="mt-2 mr-3 label">Environment</StyledTypo>
            </Col>
            <Col md={10}>
              <StyledTypo className="mt-2">{data?.environment}</StyledTypo>
            </Col>
          </Col>
          <Col md={12} className="position-static d-flex p-0 align-items-center mt-2">
            <Col md={2}>
              <StyledTypo className="mt-2 mr-3 label">Result</StyledTypo>
            </Col>
            <Col md={10}>
              <div className="d-flex align-items-center flex-wrap">
                {Object.keys(NonComplianceStatusColor).map((key) => (
                  <StyledStatusWrapper color={NonComplianceStatusColor[key].color} key={key}>
                    <div className="mark" />
                    <StyledTypo
                      size={0.9}
                      line={1.4}
                      letter="0"
                    >
                      {data?.summary?.[key]?.fail ?? 0}
                    </StyledTypo>
                  </StyledStatusWrapper>
                ))}
              </div>
            </Col>
          </Col>
          <Col md={12} className="position-static d-flex p-0 mt-2">
            <Col md={2}>
              <StyledTypo className="mt-2 mr-3 label">
                Created Date
              </StyledTypo>
            </Col>
            <Col md={4}>
              <StyledTypo className="mt-2">
                {data?.created_timestamp
                  ? moment(data?.created_timestamp).format("YYYY-MM-DD HH:mm")
                  : "--"}
              </StyledTypo>
            </Col>
            <Col md={2}>
              <StyledTypo className="mt-2 mr-3 label">
                Updated Date
              </StyledTypo>
            </Col>
            <Col md={4}>
              <StyledTypo className="mt-2">
                {data?.updated_timestamp
                  ? moment(data?.updated_timestamp).format("YYYY-MM-DD HH:mm")
                  : "--"}
              </StyledTypo>
            </Col>
          </Col>
          {data?.remediation_cli && (
            <Col
              md={12}
              className="position-static d-flex p-0 align-items-center"
            >
              <Col md={2}>
                <StyledTypo className="mt-2 mr-3 label">
                  Remediation CLI
                </StyledTypo>
              </Col>
              <Col md={10}>
                <SyntaxHighlighter
                  language="bash"
                  code={data?.remediation_cli}
                  wrapLongLines={true}
                  style={coldarkCold}
                />
              </Col>
            </Col>
          )}
          {data?.recommendation_url && (
            <Col md={12} className="position-static d-flex p-0">
              <Col md={2}>
                <StyledTypo className="mt-2 mr-3 label">
                  Recommendation URL
                </StyledTypo>
              </Col>
              <Col md={10}>
                <a href={data?.recommendation_url} target="_blank">
                  <StyledTypo className="mt-2">
                    {data?.recommendation_url}
                  </StyledTypo>
                </a>
              </Col>
            </Col>
          )}
          {data?.recommendation_txt && (
            <Col md={12} className="position-static d-flex p-0">
              <Col md={2}>
                <StyledTypo className="mt-2 mr-3 label">
                  Recommendation Text
                </StyledTypo>
              </Col>
              <Col md={10}>
                <StyledTypo className="mt-2">
                  {data?.recommendation_txt}
                </StyledTypo>
              </Col>
            </Col>
          )}
        </Row>
      </div>
    </SlidePanel>
  );
};

export default InfraDetail;
