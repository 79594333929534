import React, {useState} from 'react'
import styled from 'styled-components'

import * as global from '../../../helpers/constants'
import {applicationActions} from "../../../redux/actions";
import {connect} from "react-redux";
import {ReactComponent as SlideLeftIcon} from "../../../assets/icons/circle_arrow_left_icon.svg"
import {ReactComponent as SlideRightIcon} from "../../../assets/icons/circle_arrow_right_icon.svg"

import DisplayDetailDeployment from "./DisplayDetailDeployment";
import DisplayTablePolicies from "./DisplayTablePolicies";

const PanelWrapper = styled.div`
  padding: 42.5px 46.5px 42.5px 37.5px;
  width: 100%;
  min-height: 80vh;
  
  /* white */
  
  background: #FFFFFF;
  /* Gray light 2 */
  
  border: 2px solid #F1F5FA;
  box-sizing: border-box;
  border-radius: 20px;
  
  .panel-title-header {
    padding: 0 0 47px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const PoliciesPanel = (props) => {
  const [deploymentsData] = useState(global.fakeDeploymentData)

  return (
      <PanelWrapper>
        <div className="d-flex justify-content-between">
          {deploymentsData
              .filter((item, idx) => idx < 4)
              .map(item => (
                  <DisplayDetailDeployment data={item}/>
              ))}
        </div>
        <div className="slide-buttons d-flex justify-content-end align-items-center my-3">
          <div className="mr-2">
            <SlideLeftIcon fill={props.active ? '#A4AFB7' : '#A4AFB788'} />
          </div>
          <div>
            <SlideRightIcon fill={props.active ? '#A4AFB7' : '#A4AFB788'} />
          </div>
        </div>
        <div>
          <DisplayTablePolicies />
        </div>
      </PanelWrapper>
  )
}

function mapStateToProps(state) {
  const {clusterInfo, clusterInfoLoading, healthInfo} = state.applications;
  return {
    clusterInfo, clusterInfoLoading, healthInfo
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchClusterDetailsAction: (org_id, app_id, realtimeFlag) => dispatch(applicationActions.fetchClusterDetailsAction(org_id, app_id, realtimeFlag)),
    fetchAppHealthAction: (org_id, app_id, realtimeFlag) => dispatch(applicationActions.fetchAppHealthAction(org_id, app_id, realtimeFlag)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PoliciesPanel);
