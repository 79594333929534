import { applicationConstants } from "../constants";

const initialState = {
  appListLoading: false,
  appListLoadingError: "",
  appList: [],

  clusterInfo: {},
  clusterInfoLoading: false,
  clusterInfoLoadingError: false,

  healthInfo: null,

  demoList: [],

  activityFeeds: [],

  addAppStatus: 0,
  deleteAppStatus: 0,
  activeOrgTemplates: [],
  appResourceSummary: [],
  envData: null,

  healthHistoryData: null,
  resourceHistoryData: [],
  notifications: null,
  latestHistoryResources: {},
  historyApps: [],

  remediations: [],
  solution: {},
  remediation_logs: [],
  report_downloading: {},
  trigger_infra: {},
  steampipe_result: {},
  single_resource_update_status: [],
  iac_projects: [],
  manual_cloud_remediations: {},
  prowler_resources: {},
  prowler_remediations: {},
  cloud_solutions: {},
  compliance_trend: {},

  workloadsLoading: false,
  workloads: [],
  workload_summary: {},
  workload_detail: {},
  workloadDetailLoading: false,

  clustersLoading: false,
  clusters: [],
  clusterSummaryLoading: false,
  cluster_summary: {},
  clusterDetailLoading: false,
  cluster_detail: {},

  user_assets: {},
  instance_cve_group: {},
  instance_cve_detail: {},
  sg_assets: {},
  network_assets: {},
  logs: {},
  loading_log: false,
  top_priority: {},
  top_risks: {},

  pentesting_settings: {},
  selected_pentesting_config: {},
  workspaces: [],
  create_update_workspace_flag: 0, // 0- request, 1- success, -1: failure
};

export function applications(state = initialState, action) {
  let logs;
  switch (action.type) {
    case applicationConstants.FETCH_APP_LIST_REQUEST:
      return { ...state, appListLoadingError: "", appListLoading: true };
    case applicationConstants.FETCH_APP_LIST_SUCCESS:
      return { ...state, appList: action.appList, appListLoading: false };
    case applicationConstants.FETCH_APP_LIST_FAILURE:
      return {
        ...state,
        appListLoadingError: action.error,
        appListLoading: false,
        appList: [],
      };

    case applicationConstants.FETCH_DEMO_LIST_REQUEST:
      return { ...state };
    case applicationConstants.FETCH_DEMO_LIST_SUCCESS:
      return { ...state, demoList: action.demoList };
    case applicationConstants.FETCH_DEMO_LIST_FAILURE:
      return { ...state, demoList: [] };

    case applicationConstants.FETCH_APP_HEALTH_REQUEST:
      return { ...state };
    case applicationConstants.FETCH_APP_HEALTH_SUCCESS:
      return {
        ...state,
        healthInfo: action.healthInfo,
        appList: [
          ...state.appList.map((item) => ({
            ...item,
            health:
              item.id === action.app_id
                ? action.healthInfo?.health ?? 0
                : item.health,
          })),
        ],
      };
    case applicationConstants.FETCH_APP_HEALTH_FAILURE:
      return {
        ...state,
        healthInfo: null,
        appList: [
          ...state.appList.map((item) => ({
            ...item,
            health: item.id === action.app_id ? 0 : item.health,
          })),
        ],
      };

    case applicationConstants.FETCH_APP_CLUSTER_REQUEST:
      return { ...state, clusterInfoLoading: true };
    case applicationConstants.FETCH_APP_CLUSTER_SUCCESS:
      return {
        ...state,
        clusterInfo: {
          ...state.clusterInfo,
          [action.app_id]: action.clusterInfo,
        },
        clusterInfoLoading: false,
      };
    case applicationConstants.FETCH_APP_CLUSTER_FAILURE:
      return {
        ...state,
        clusterInfo: { ...state.clusterInfo },
        clusterInfoLoading: false,
      };

    case applicationConstants.FETCH_ACTIVITY_FEEDS_REQUEST:
      return { ...state };
    case applicationConstants.FETCH_ACTIVITY_FEEDS_SUCCESS:
      return { ...state, activityFeeds: action.activityFeeds.data };
    case applicationConstants.FETCH_ACTIVITY_FEEDS_FAILURE:
      return { ...state, activityFeeds: [] };

    case applicationConstants.ADD_NEW_APPLICATION_REQUEST:
      return { ...state, addAppStatus: 0 };
    case applicationConstants.ADD_NEW_APPLICATION_SUCCESS:
      return { ...state, addAppStatus: 1 };
    case applicationConstants.ADD_NEW_APPLICATION_FAILURE:
      return { ...state, addAppStatus: 2 };

    case applicationConstants.UPDATE_APPLICATION_REQUEST:
      return { ...state, addAppStatus: 0 };
    case applicationConstants.UPDATE_APPLICATION_SUCCESS:
      return { ...state, addAppStatus: 1 };
    case applicationConstants.UPDATE_APPLICATION_FAILURE:
      return { ...state, addAppStatus: 2 };

    case applicationConstants.DELETE_APPLICATION_REQUEST:
      return { ...state, deleteAppStatus: 0 };
    case applicationConstants.DELETE_APPLICATION_SUCCESS:
      return { ...state, deleteAppStatus: 1 };
    case applicationConstants.DELETE_APPLICATION_FAILURE:
      return { ...state, deleteAppStatus: 2 };

    case applicationConstants.FETCH_REMEDIATION_REQUIRED_REQUEST:
      return { ...state, activeOrgTemplates: [] };
    case applicationConstants.FETCH_REMEDIATION_REQUIRED_SUCCESS:
      return { ...state, activeOrgTemplates: action.templates };
    case applicationConstants.FETCH_REMEDIATION_REQUIRED_FAILURE:
      return { ...state, activeOrgTemplates: [] };

    case applicationConstants.FETCH_RESOURCE_SUMMARY_REQUEST:
      return { ...state, appResourceSummary: [] };
    case applicationConstants.FETCH_RESOURCE_SUMMARY_SUCCESS:
      return { ...state, appResourceSummary: action.summary };
    case applicationConstants.FETCH_RESOURCE_SUMMARY_FAILURE:
      return { ...state, appResourceSummary: [] };

    case applicationConstants.FETCH_ORG_ENV_LIST_REQUEST:
    case applicationConstants.UPDATE_ORG_ENV_LIST_REQUEST:
      return { ...state };
    case applicationConstants.FETCH_ORG_ENV_LIST_SUCCESS:
    case applicationConstants.UPDATE_ORG_ENV_LIST_SUCCESS:
      return { ...state, envData: action.envData.result };
    case applicationConstants.FETCH_ORG_ENV_LIST_FAILURE:
      return {
        ...state,
        envData: state.envData
          ? { ...state.envData, environments: [] }
          : state.envData,
      };

    case applicationConstants.RESET_ADD_APP_STATUS:
      return { ...state, addAppStatus: 0 };
    case applicationConstants.RESET_DELETE_APP_STATUS:
      return { ...state, deleteAppStatus: 0 };

    case applicationConstants.CLEAR_APP_LIST_LOADING:
      return { ...state, appListLoading: false };

    case applicationConstants.FETCH_HEALTH_HISTORY_REQUEST:
      return { ...state };
    case applicationConstants.FETCH_HEALTH_HISTORY_SUCCESS:
      return { ...state, healthHistoryData: action.historyData };
    case applicationConstants.FETCH_HEALTH_HISTORY_FAILURE:
      return { ...state, healthHistoryData: null };

    case applicationConstants.FETCH_RESOURCE_HISTORY_REQUEST:
      return { ...state };
    case applicationConstants.FETCH_RESOURCE_HISTORY_SUCCESS:
      return { ...state, resourceHistoryData: action.historyData };
    case applicationConstants.FETCH_RESOURCE_HISTORY_FAILURE:
      return { ...state, resourceHistoryData: [] };

    case applicationConstants.FETCH_NOTIFICATIONS_REQUEST:
      return { ...state };
    case applicationConstants.FETCH_NOTIFICATIONS_SUCCESS:
      return { ...state, notifications: action.notifications };
    case applicationConstants.FETCH_NOTIFICATIONS_FAILURE:
      return { ...state, resourceHistoryData: null };

    case applicationConstants.FETCH_LATEST_HISTORY_RESOURCE_REQUEST:
      return { ...state };
    case applicationConstants.FETCH_LATEST_HISTORY_RESOURCE_SUCCESS:
      return {
        ...state,
        latestHistoryResources: {
          ...state.latestHistoryResources,
          [action.platform]: action.resourceHistory,
        },
      };
    case applicationConstants.FETCH_LATEST_HISTORY_RESOURCE_FAILURE:
      return {
        ...state,
        latestHistoryResources: {
          ...state.latestHistoryResources,
          [action.platform]: null,
        },
      };

    case applicationConstants.FETCH_HISTORY_APPLICATION_REQUEST:
      return { ...state };
    case applicationConstants.FETCH_HISTORY_APPLICATION_SUCCESS:
      return { ...state, historyApps: action.historyApps };
    case applicationConstants.FETCH_HISTORY_APPLICATION_FAILURE:
      return { ...state, historyApps: [] };

    case applicationConstants.FETCH_REMEDIATION_LIST_REQUEST:
      return { ...state };
    case applicationConstants.FETCH_REMEDIATION_LIST_SUCCESS:
      return { ...state, remediations: action.remediations };
    case applicationConstants.FETCH_REMEDIATION_LIST_FAILURE:
      return { ...state, remediations: [] };

    case applicationConstants.FETCH_SOLUTIONS_REQUEST:
      return { ...state };
    case applicationConstants.FETCH_SOLUTIONS_SUCCESS:
      return {
        ...state,
        solution: { ...state.solution, [action.app_id]: action.solution },
      };
    case applicationConstants.FETCH_SOLUTIONS_FAILURE:
      return { ...state, solution: { ...state.solution } };

    case applicationConstants.FETCH_REMEDIATION_LOGS_REQUEST:
      return { ...state };
    case applicationConstants.FETCH_REMEDIATION_LOGS_SUCCESS:
      logs = state.remediation_logs.filter(
        (log) =>
          log.app_id !== action.logs.app_id ||
          log.remediation_id !== action.logs.remediation_id
      );
      logs.push(action.logs);
      return { ...state, remediation_logs: logs };
    case applicationConstants.FETCH_REMEDIATION_LOGS_FAILURE:
      logs = state.remediation_logs.filter(
        (log) =>
          log.app_id !== action.app_id ||
          log.remediation_id !== action.remediation_id
      );
      return { ...state, remediation_logs: logs };

    case applicationConstants.DOWNLOAD_REPORT_REQUEST:
      return {
        ...state,
        report_downloading: {
          ...state.report_downloading,
          [action.kind]: true,
        },
      };
    case applicationConstants.DOWNLOAD_REPORT_FAILURE:
    case applicationConstants.DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        report_downloading: {
          ...state.report_downloading,
          [action.kind]: false,
        },
      };

    case applicationConstants.TRIGGER_RESOURCE_SUCCESS:
      return {
        ...state,
        trigger_infra: {
          ...state.trigger_infra,
          [action.application_id]: "pending",
        },
      };
    case applicationConstants.TRIGGER_RESOURCE_FAILURE:
      return {
        ...state,
        trigger_infra: {
          ...state.trigger_infra,
          [action.application_id]: "failed",
        },
      };

    case applicationConstants.TRIGGER_STATUS_SUCCESS:
      return {
        ...state,
        trigger_infra: {
          ...state.trigger_infra,
          [action.application_id]: action.status.task_status,
        },
      };

    case applicationConstants.FETCH_STEAMPIPE_RESULT_SUCCESS:
      return {
        ...state,
        steampipe_result: {
          ...state.steampipe_result,
          [action.application_id]: action.result.remediations,
        },
      };
    case applicationConstants.FETCH_STEAMPIPE_RESULT_FAILURE:
      return {
        ...state,
        steampipe_result: {
          ...state.steampipe_result,
          [action.application_id]: [],
        },
      };

    case applicationConstants.TRIGGER_SINGLE_RESOURCE_SUCCESS:
      return {
        ...state,
        single_resource_update_status: [
          ...state.single_resource_update_status.filter(
            (item) =>
              item.application_id !== action.application_id &&
              item.remediation_id,
            action.remediation_id
          ),
          { ...action, status: "pending" },
        ],
      };
    case applicationConstants.TRIGGER_SINGLE_RESOURCE_FAILURE:
      return {
        ...state,
        single_resource_update_status: [
          ...state.single_resource_update_status.filter(
            (item) =>
              item.application_id !== action.application_id &&
              item.remediation_id,
            action.remediation_id
          ),
        ],
      };
    case applicationConstants.SINGLE_TRIGGER_STATUS_SUCCESS:
      return {
        ...state,
        single_resource_update_status: [
          ...state.single_resource_update_status.filter(
            (item) =>
              item.application_id !== action.application_id &&
              item.remediation_id,
            action.remediation_id
          ),
          action.status.task_status !== "failed" && {
            ...action,
            status: action.status.task_status,
          },
        ],
      };

    case applicationConstants.FETCH_MANUAL_CLOUD_REMEDIATION_SUCCESS:
      return {
        ...state,
        manual_cloud_remediations: {
          ...state.manual_cloud_remediations,
          [action.remediation_id]: action.payload,
        },
      };
    case applicationConstants.FETCH_MANUAL_CLOUD_REMEDIATION_FAILURE:
      return {
        ...state,
        manual_cloud_remediations: {
          ...state.manual_cloud_remediations,
          [action.remediation_id]: null,
        },
      };

    case applicationConstants.FETCH_PROWLER_RESOURCE_SUCCESS:
      return {
        ...state,
        prowler_resources: {
          ...state.prowler_resources,
          [action.app_id]: action.payload,
        },
      };
    case applicationConstants.FETCH_PROWLER_RESOURCE_FAILURE:
      return {
        ...state,
        prowler_resources: {
          ...state.prowler_resources,
          [action.app_id]: null,
        },
      };

    case applicationConstants.FETCH_PROWLER_REMEDIATION_SUCCESS:
      return {
        ...state,
        prowler_remediations: {
          ...state.prowler_remediations,
          [action.app_id]: action.payload,
        },
      };
    case applicationConstants.FETCH_PROWLER_REMEDIATION_FAILURE:
      return {
        ...state,
        prowler_remediations: {
          ...state.prowler_remediations,
          [action.app_id]: null,
        },
      };
    case applicationConstants.FETCH_CLOUD_REMEDIATION_SOLUTION_SUCCESS:
      return {
        ...state,
        cloud_solutions: {
          ...state.prowler_remediations,
          [action.app_id]: action.payload,
        },
      };
    case applicationConstants.FETCH_CLOUD_REMEDIATION_SOLUTION_FAILURE:
      return {
        ...state,
        cloud_solutions: {
          ...state.prowler_remediations,
          [action.app_id]: null,
        },
      };

    case applicationConstants.FETCH_COMPLIANCE_TREND_SUCCESS:
      return {
        ...state,
        compliance_trend: {
          ...state.compliance_trend,
          [action.provider]: action.complianceTrend
        }
      };

    case applicationConstants.FETCH_WORKLOADS_REQUEST:
      return {
        ...state,
        workloadsLoading: true,
      }

    case applicationConstants.FETCH_WORKLOADS_SUCCESS:
      return {
        ...state,
        workloads: action.workloads,
        workloadsLoading: false,
      }

    case applicationConstants.FETCH_WORKLOADS_FAILURE:
      return {
        ...state,
        workloads: [],
        workloadsLoading: false,
      }

    case applicationConstants.FETCH_WORKLOAD_SUMMARY_SUCCESS:
      return {
        ...state,
        workload_summary: {...state.workload_summary, [action.workload_id]: action.summary},
      }

    case applicationConstants.FETCH_WORKLOAD_SUMMARY_FAILURE:
      return {
        ...state,
        workload_summary: {...state.workload_summary, [action.workload_id]: null},
      }

    case applicationConstants.FETCH_WORKLOAD_DETAIL_REQUEST:
      return {
        ...state,
        workloadDetailLoading: true,
      }

    case applicationConstants.FETCH_WORKLOAD_DETAIL_SUCCESS:
      return {
        ...state,
        workload_detail: {...state.workload_detail, [action.workload_id]: action.detail},
        workloadDetailLoading: false,
      }

    case applicationConstants.FETCH_WORKLOAD_DETAIL_FAILURE:
      return {
        ...state,
        workload_detail: {...state.workload_detail, [action.workload_id]: null},
        workloadDetailLoading: false
      }
  
    case applicationConstants.FETCH_KSPM_CLUSTERS_REQUEST:
      return {
        ...state,
        clustersLoading: true
      }
  
    case applicationConstants.FETCH_KSPM_CLUSTERS_SUCCESS:
      return {
        ...state,
        clustersLoading: false,
        clusters: action.clusters
      }

    case applicationConstants.FETCH_KSPM_CLUSTERS_FAILURE:
      return {
        ...state,
        clustersLoading: false,
        clusters: []
      }
  
    case applicationConstants.FETCH_KSPM_CLUSTER_SUMMARY_REQUEST:
      return {
        ...state,
        clusterSummaryLoading: true,
      }
  
    case applicationConstants.FETCH_KSPM_CLUSTER_SUMMARY_SUCCESS:
      return {
        ...state,
        clusterSummaryLoading: false,
        cluster_summary: {
          ...state.cluster_summary,
          [action.cluster_id]: action.summary
        }
      }
    
    case applicationConstants.FETCH_KSPM_CLUSTER_SUMMARY_FAILURE:
      return {
        ...state,
        clusterSummaryLoading: false,
        cluster_summary: {
          ...state.cluster_summary,
          [action.cluster_id]: null
        }
      }

    case applicationConstants.FETCH_KSPM_CLUSTER_DETAIL_REQUEST:
      return {
        ...state,
        clusterDetailLoading: true,
      }

    case applicationConstants.FETCH_KSPM_CLUSTER_DETAIL_SUCCESS:
      return {
        ...state,
        clusterDetailLoading: false,
        cluster_detail: {
          ...state.cluster_detail,
          [action.cluster_id]: action.detail
        }
      }
  
    case applicationConstants.FETCH_KSPM_CLUSTER_DETAIL_FAILURE:
      return {
        ...state,
        clusterDetailLoading: false,
        cluster_detail: {
          ...state.cluster_detail,
          [action.cluster_id]: null
        }
      }

    case applicationConstants.FETCH_USER_ASSETS_SUCCESS:
      return {
        ...state,
        user_assets: {
          ...state.user_assets,
          [action.app_id]: action.userAssets
        }
      }

    case applicationConstants.FETCH_USER_ASSETS_FAILURE:
      return {
        ...state,
        user_assets: {
          ...state.user_assets,
          [action.app_id]: null
        }
      }

    case applicationConstants.FETCH_INSTANCE_CVE_GROUP_SUCCESS:
      return {
        ...state,
        instance_cve_group: {
          ...state.instance_cve_group,
          [action.app_id]: action.instanceCve
        }
      }

    case applicationConstants.FETCH_INSTANCE_CVE_GROUP_FAILURE:
      return {
        ...state,
        instance_cve_group: {
          ...state.instance_cve_detail,
          [action.app_id]: null
        }
      }

    case applicationConstants.FETCH_INSTANCE_CVE_DETAIL_SUCCESS:
      return {
        ...state,
        instance_cve_detail: {
          ...state.instance_cve_detail,
          [action.app_id]: { 
            ...(state.instance_cve_detail?.[action.app_id] ?? {}),
            [action.node_id]: action.instanceCveDetail
           }
        }
      }

    case applicationConstants.FETCH_INSTANCE_CVE_DETAIL_FAILURE:
      return {
        ...state,
        instance_cve_detail: {
          ...state.instance_cve_group,
          [action.app_id]: null
        }
      }

    case applicationConstants.FETCH_SG_ASSETS_SUCCESS:
      return {
        ...state,
        sg_assets: {
          ...state.sg_assets,
          [action.app_id]: action.sgAssets
        }
      }

    case applicationConstants.FETCH_SG_ASSETS_FAILURE:
      return {
        ...state,
        sg_assets: {
          ...state.sg_assets,
          [action.app_id]: null
        }
      }
    case applicationConstants.FETCH_NETWORK_ASSETS_SUCCESS:
      return {
        ...state,
        network_assets: {
          ...state.network_assets,
          [action.app_id]: action.networkAssets
        }
      }

    case applicationConstants.FETCH_NETWORK_ASSETS_FAILURE:
      return {
        ...state,
        network_assets: {
          ...state.network_assets,
          [action.app_id]: null
        }
      }

    case applicationConstants.FETCH_GOOGLE_LOGS_REQUEST:
      return {
        ...state,
        loading_log: true
      }

    case applicationConstants.FETCH_GOOGLE_LOGS_SUCCESS:
      return {
        ...state,
        logs: {
          ...state.logs,
          [action.org_id]: action.logs
        },
        loading_log: false
      }

    case applicationConstants.FETCH_GOOGLE_LOGS_FAILURE:
      return {
        ...state,
        logs: {
          ...state.logs,
          [action.org_id]: null
        },
        loading_log: false
      }

    case applicationConstants.FETCH_TOP_ATTACK_PATHS_SUCCESS:
      return {
        ...state,
        top_priority: {
          ...state.top_priority,
          attack_paths: action.payload?.result
        }
      }
    case applicationConstants.FETCH_TOP_ATTACK_PATHS_FAILURE:
      return {
        ...state,
        top_priority: {
          ...state.top_priority,
          attack_paths: []
        }
      }
    case applicationConstants.FETCH_TOP_INFRAS_SUCCESS:
      return {
        ...state,
        top_priority: {
          ...state.top_priority,
          infras: action.payload?.result
        }
      }
    case applicationConstants.FETCH_TOP_INFRAS_FAILURE:
      return {
        ...state,
        top_priority: {
          ...state.top_priority,
          infras: []
        }
      }
    case applicationConstants.FETCH_TOP_NONCOMPLIANCES_SUCCESS:
      return {
        ...state,
        top_priority: {
          ...state.top_priority,
          compliances: action.payload?.result
        }
      }
    case applicationConstants.FETCH_TOP_NONCOMPLIANCES_FAILURE:
      return {
        ...state,
        top_priority: {
          ...state.top_priority,
          compliances: []
        }
      }
    case applicationConstants.FETCH_TOP_IDENTITY_ACCESS_SUCCESS:
      return {
        ...state,
        top_risks: {
          ...state.top_risks,
          identity_access: action.payload?.result
        }
      }
    case applicationConstants.FETCH_TOP_IDENTITY_ACCESS_FAILURE:
      return {
        ...state,
        top_risks: {
          ...state.top_risks,
          identity_access: []
        }
      }
    case applicationConstants.FETCH_TOP_CONTAINERS_SUCCESS:
      return {
        ...state,
        top_risks: {
          ...state.top_risks,
          containers: action.payload?.result
        }
      }
    case applicationConstants.FETCH_TOP_CONTAINERS_FAILURE:
      return {
        ...state,
        top_risks: {
          ...state.top_risks,
          containers: []
        }
      }
    case applicationConstants.FETCH_TOP_CLUSTERS_SUCCESS:
      return {
        ...state,
        top_risks: {
          ...state.top_risks,
          clusters: action.payload?.result
        }
      }
    case applicationConstants.FETCH_TOP_CLUSTERS_FAILURE:
      return {
        ...state,
        top_risks: {
          ...state.top_risks,
          clusters: []
        }
      }
    case applicationConstants.FETCH_TOP_DATA_SECURITY_SUCCESS:
      return {
        ...state,
        top_risks: {
          ...state.top_risks,
          data_security: action.payload?.result
        }
      }
    case applicationConstants.FETCH_TOP_DATA_SECURITY_FAILURE:
      return {
        ...state,
        top_risks: {
          ...state.top_risks,
          data_security: []
        }
      }
    case applicationConstants.FETCH_TOP_PUBLIC_EXPOSURE_SUCCESS:
      return {
        ...state,
        top_risks: {
          ...state.top_risks,
          public_exposure: action.payload?.result
        }
      }
    case applicationConstants.FETCH_TOP_PUBLIC_EXPOSURE_FAILURE:
      return {
        ...state,
        top_risks: {
          ...state.top_risks,
          public_exposure: []
        }
      }
    case applicationConstants.FETCH_TOP_SOFTWARE_UPDATE_SUCCESS:
      return {
        ...state,
        top_risks: {
          ...state.top_risks,
          software_update: action.payload?.result
        }
      }
    case applicationConstants.FETCH_TOP_SOFTWARE_UPDATE_FAILURE:
      return {
        ...state,
        top_risks: {
          ...state.top_risks,
          software_update: []
        }
      }
    case applicationConstants.FETCH_PENTESTING_CONFIGURATIONS_SUCCESS:
      const custom = action.payload?.custom
      delete action.payload?.custom
      return {
        ...state,
        pentesting_settings: {
          ...state.pentesting_settings,
          [action.org_id]: {
            ...action.payload,
            custom,
          }
        }
      }
    case applicationConstants.FETCH_PENTESTING_CONFIGURATION_SUCCESS:
      return {
        ...state,
        selected_pentesting_config: {
          ...state.selected_pentesting_config,
          [action.org_id]: action?.payload?.['pentesting_configuration']
        }
      }
    case applicationConstants.SET_PENTESTING_CONFIGURATION_SUCCESS:
      return {
        ...state,
        pentesting_settings: {
          ...state.pentesting_settings,
          [action.org_id]: {
            ...(state.pentesting_settings?.[action.org_id] ?? {}),
            ...action.result
          }
        },
        selected_pentesting_config: {
          ...state.selected_pentesting_config,
          [action.org_id]: 'custom'
        }
      }
    case applicationConstants.SET_SELECTED_PENTESTING_CONFIGURATION_SUCCESS:
      return {
        ...state,
        selected_pentesting_config: {
          ...state.selected_pentesting_config,
          [action.org_id]: action.id
        }
      }

    case applicationConstants.FETCH_WORKSPACES_SUCCESS:
      return {
        ...state,
        workspaces: action.result
      }

    case applicationConstants.FETCH_WORKSPACE_SUCCESS:
      return {
        ...state,
        workspaces: [
          ...state.workspaces.filter(workspace => workspace.id !== action.result.id),
          action.result
        ]
      }
    case applicationConstants.CREATE_WORKSPACE_SUCCESS:
    case applicationConstants.UPDATE_WORKSPACE_SUCCESS:
      return {
        ...state,
        create_update_workspace_flag: 1
      }
    case applicationConstants.CREATE_WORKSPACE_FAILURE:
    case applicationConstants.UPDATE_WORKSPACE_FAILURE:
      return {
        ...state,
        create_update_workspace_flag: -1
      }
    case applicationConstants.CLEAR_CREATE_UPDATE_WORKSPACE_FLAG:
      return {
        ...state,
        create_update_workspace_flag: 0,
      }
    default:
      return { ...state };
  }
}
