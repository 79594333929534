import React, {useState} from 'react'
import {Row, Col} from 'reactstrap'
import styled from 'styled-components'
import StyledTypo from "../../components/Typo/StyledTypo";
import EffectedButton from "../../components/Buttons/EffectedButton";
import CustomTextarea from "../../components/Input/CustomTextarea";
import {ReactComponent as InfoIcon} from "../../assets/icons/info_icon.svg";
import {ReactComponent as EditIcon} from "../../assets/icons/edit_icon.svg";
import PrettierPanel from "./PrettierPanel";

const MainContainer = styled.div`
  width: 480px;
  padding: 33px 25px;
  
  background: #FFFFFF;
  border: 2px solid #F1F5FA;
  box-sizing: border-box;
  //box-shadow: 0 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 20px;
  transform: translate3d(-500px, -300px, 0px) !important;
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  box-shadow: 0 0 0 9999px #00000066;
  z-index: 105;
`


const AddVarsPanel = (props) => {
  const {variables, setVariables, disabled} = props
  const [showPrettier, setShowPrettier] = useState({})
  const [selectedVar, setSelectedVar] = useState(-1)

  const setEachVariable = (idx, kind, value) => {
    const data = [...variables]
    data[idx][kind] = value
    setVariables(data)
  }



  const togglePrettierPanel = (idx) => {
    setShowPrettier(showPrettier => ({...showPrettier, [idx]: !showPrettier[idx]}))
  }

  const prettierData = (item) => {
    try {
      return {
        [item['key']]: JSON.parse(item['value'])
      }
    } catch (err) {
      return {[item['key']]: item['value']}
    }
  }

  const onChangeVars = (order, data) => {
    if(data) {
      const keys = Object.keys(data)
      const modifiedVar = {key: keys[0], value: JSON.stringify(data[keys[0]])}
      setVariables(variables => variables.map((item, idx) => idx === order ? modifiedVar : item))
    }
  }

  return (
      <MainContainer className="dropdown-menu show">
        <div className="title d-flex align-items-center mb-4">
          <StyledTypo color="#A4AFB7" size={1.125} line={1.4} letter="0" family="Roboto" fontWeight={500}>
            Variables({variables.length})
          </StyledTypo>
          {/*<div className="ml-auto">*/}
          {/*  <EffectedButton marginLeft="auto" padding={0} height={40} noSpan onClick={addNewRow}>*/}
          {/*    <CirclePlusIcon />*/}
          {/*    <StyledTypo className="ml-2" color="#fff" size={0.875} line={1.4} letter="0" family="Roboto" fontWeight={400}>*/}
          {/*      New*/}
          {/*    </StyledTypo>*/}
          {/*  </EffectedButton>*/}
          {/*</div>*/}
        </div>
        {/*<ScrollPanel>*/}
          {variables.map((item, idx) => (
              <Row key={idx} className="align-items-start mt-2">
                <Col md={1}>
                  <StyledTypo className="mt-1" color="#505050" size={0.875} line={1.4} letter="0" family="Roboto"
                              fontWeight={300}>
                    {idx + 1}
                  </StyledTypo>
                </Col>
                <Col md={10}>
                  {/*<CommonInput label="Key" value={item['key']} setValue={(val) => setEachVariable(idx, 'key', val)}*/}
                  {/*             active={false} locked={false} placeholder="" disabled/>*/}
                  <CustomTextarea label={item['key']} value={typeof(item['value']) === 'String' ? item['value'] : item['value'].toString()} disabled
                                  setValue={(val) => setEachVariable(idx, 'value', val)}/>
                </Col>
                <Col md={1} className="px-0">
                  <div className="dropdown show">
                    <div className="cursor-pointer" onClick={() => togglePrettierPanel(idx)}>
                      {disabled ? <InfoIcon /> : <EditIcon width="17px" fill="#5EB1E4"/>}
                    </div>
                    {showPrettier[idx] && (
                      <PrettierPanel
                          title={disabled ? 'View variables' : 'Update variables'}
                          toggle={() => togglePrettierPanel(idx)}
                          prettierData={prettierData(item)}
                          id={`var-${idx}`}
                          onChangeValue={(data) => onChangeVars(idx, data.jsObject)}
                          onlyShow={disabled}
                      />
                    )}
                  </div>
                  {/*<a onClick={() => removeRow(idx)} className="cursor-pointer float-right mr-2">*/}
                  {/*  <DeleteButtonIcon fill={colors.red} width={15} height={20} />*/}
                  {/*</a>*/}
                </Col>
              </Row>
          ))}
        {/*</ScrollPanel>*/}
        <div>
        </div>
        <div className="d-flex align-items-center justify-content-end mt-3">
          <EffectedButton marginLeft="auto" background="#485DCD" onClick={props.toggle}>
            <StyledTypo color="#fff" size={0.875} line={1.4} letter="0" family="Roboto" fontWeight={400}>
              OK
            </StyledTypo>
          </EffectedButton>
        </div>
      </MainContainer>
  )
}

export default AddVarsPanel
