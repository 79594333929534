import React, { useState } from 'react'
import styled from "styled-components";
import StyledTypo from "../../../components/Typo/StyledTypo";
import CustomTextarea from "../../../components/Input/CustomTextarea";
import StyledFile from "../../../components/Input/StyledFile";
import {credentialMap} from "../../../helpers/constants";
import PrettierPanel from "../PrettierPanel";
import {ReactComponent as InfoIcon} from "../../../assets/icons/info_icon.svg";

const BorderWrapper = styled.div`
  border: 0.5px solid #ccc;
  padding: 10px 15px;
  border-radius: 10px;
  position: relative;
  margin-top: 30px;

  .title {
    padding: 0 10px;
    position: absolute;
    left: 15px;
    top: -10px;
    background-color: #fff;

  }
`

const GCPCredPartial = props => {
  const {credValue, setCredValue} = props

  const [showPrettier, setShowPrettier] = useState({})

  const handleCredChange = (filename, content, type) => {
    handleChange(type, 'credential', JSON.parse(content))
  }

  const handleChange = (parentKey, key, val) => {
    setCredValue(credValue => ({...credValue, [key]: val}))
  }

  const togglePrettierPanel = (idx) => {
    setShowPrettier(showPrettier => ({...showPrettier, [idx]: !showPrettier[idx]}))
  }

  return (
      <div>
        {credentialMap.gcp.map((each) => (
            <BorderWrapper key={each.key}>
              <div className="title">
                <StyledTypo size="0.8">
                  {each.label}
                </StyledTypo>
              </div>
              {each.children && each.children.map((category, idx) => category.type === 'text' ? (
                  <CustomTextarea key={idx} label={category.label} value={credValue?.[each.key]?.[category.key] ?? ''}
                                  setValue={(val) => handleChange(each.key, category.key, val)} disabled={false}/>
              ) : category.type === 'file' ? (
                  <div className="d-flex justify-content-between align-items-center">
                    <StyledFile
                        label="SA file"
                        onChange={(filename, content) => handleCredChange(filename, content, each.key)}
                        isShowName
                        extensions={['json']}
                        hiddenId={`hidden${each.key}`}
                        className="my-2"
                        disabled={false}
                    />
                    {!!credValue?.[each.key] && (
                        <div className="dropdown show">
                          <div className={'cursor-pointer'}
                               onClick={() => togglePrettierPanel(each.key)}>
                            <InfoIcon/>
                          </div>
                          {showPrettier[each.key] && (
                              <PrettierPanel
                                  title="View Service Account"
                                  toggle={() => togglePrettierPanel(each.key)}
                                  prettierData={credValue[each.key]}
                                  id={`var-${each.key}`}
                                  onlyShow
                              />
                          )}
                        </div>
                    )}
                  </div>
              ) : (<div/>))}
            </BorderWrapper>
        ))}
      </div>
  )
}

export default GCPCredPartial
