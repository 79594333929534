import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StyledTypo from "../../components/Typo/StyledTypo";
import { ReactComponent as CirclePlusIcon } from "../../assets/icons/circle_add_icon.svg";
import { ReactComponent as VMIcon } from "../../assets/icons/vm_icon.svg";
import { ReactComponent as ClusterIcon } from "../../assets/icons/cluster_icon.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash_icon.svg";
import EffectedButton from "../../components/Buttons/EffectedButton";
import { DropdownMenu } from "reactstrap";
import StyledDropdown from "../../components/Buttons/StyledDropdown";
import DropdownIcon from "../../components/Buttons/DropdownIcon";
import AddApplicationPanel from "./AddApplicationPanel";

import { confirmAlert } from "react-confirm-alert"; // Import

const MainContainer = styled(DropdownMenu)`
  width: 850px;
  padding: 31px;

  background: #ffffff;
  border: 2px solid #f1f5fa;
  border-bottom: ${(props) => (props.active ? "none" : "2px solid #F1F5FA")};
  box-sizing: border-box;
  //box-shadow: 0 6px 4px rgba(104, 112, 137, 0.05);
  box-shadow: 0 0 0 9999px #00000066;
  border-radius: 20px 0 20px 20px;
  transform: translate3d(-597px, 36px, 0px) !important;
`;

const AppNameWrapper = styled.div`
  width: 100%;
  margin-top: 18px;
  margin-bottom: 18px;
  padding: 18px 30px;
  /* white */

  background: #ffffff;
  /* Gray light 2 */

  border: 1px solid #f1f5fa;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  gap: 10px;
`;

const VMClusterWrapper = styled.div`
  width: 49%;
  padding: 26px 30px;
  /* white */

  background: #ffffff;
  /* Gray light 2 */

  border: 1px solid #f1f5fa;
  box-sizing: border-box;
  border-radius: 20px;

  .divider {
    margin: 0 -30px;
    height: 0;
    border-top: 1px solid #f1f5fa;
  }
`;

const ApplicationDetailsPanel = (props) => {
  const {
    app,
    resources,
    addAppStatus,
    resetAddAppStatusAction,
    addNewApplicationAction,
    deleteApplicationAction,
    selectedEnv,
    envData,
    setDefaultCsp,
    selectedCsp,
  } = props;

  const [observationData, setObservationData] = useState({
    instances: [],
    clusters: [],
  });

  const [showAddApplicationPanel, setShowAddApplicationPanel] = useState(false);

  useEffect(() => {
    if (addAppStatus === 1) {
      resetAddAppStatusAction();
      // setDefaultCsp()
      setShowAddApplicationPanel(false);
    }
  }, [addAppStatus, resetAddAppStatusAction]);

  useEffect(() => {
    if (resources) {
      setObservationData({ ...resources });
    }
  }, [resources]);

  const deleteConfirm = (app_name) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to delete infrastructure "{app_name}"?</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                app?.id && deleteApplicationAction(app?.id);
                onClose();
              }}
            >
              Confirm
            </button>
          </div>
        );
      },
    });
  };

  return (
    <MainContainer>
      <div className="d-flex justify-content-between align-items-center">
        <EffectedButton
          padding={15}
          marginLeft="0"
          noSpan
          background="#485DCD"
          onClick={
            // () => app?.id && deleteApplicationAction(app?.id)
            () => app && deleteConfirm(app?.name)
          }
        >
          <TrashIcon fill="#FCFDFF" className="mb-1" />
          <StyledTypo
            className="ml-3"
            color="#fff"
            size={1.125}
            line={25.2 / 18}
            letter="0"
            family="Roboto"
            fontWeight={500}
          >
            Delete
          </StyledTypo>
        </EffectedButton>
        <StyledDropdown
          isOpen={showAddApplicationPanel}
          toggle={() =>
            setShowAddApplicationPanel(
              (showAddApplicationPanel) => !showAddApplicationPanel
            )
          }
        >
          <DropdownIcon>
            <EffectedButton padding={15} noSpan>
              <CirclePlusIcon />
              <StyledTypo
                className="ml-3"
                color="#fff"
                size={1.125}
                line={25.2 / 18}
                letter="0"
                family="Roboto"
                fontWeight={500}
              >
                Import
              </StyledTypo>
            </EffectedButton>
          </DropdownIcon>
          {showAddApplicationPanel && (
            <AddApplicationPanel
              addNewApplicationAction={addNewApplicationAction}
              selectedEnv={selectedEnv}
              toggle={() =>
                setShowAddApplicationPanel(
                  (showAddApplicationPanel) => !showAddApplicationPanel
                )
              }
              selectedCsp={selectedCsp}
              envData={envData}
            />
          )}
        </StyledDropdown>
      </div>
      <AppNameWrapper>
        <StyledTypo
          color="#50505066"
          size={1.125}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={500}
        >
          Current Infrastructure:
        </StyledTypo>
        <StyledTypo
          color="#505050"
          size={1.125}
          line={1.4}
          letter="0"
          family="Roboto"
          fontWeight={700}
        >
          {app?.name ?? "no-selected"}
        </StyledTypo>
      </AppNameWrapper>
      <div className="d-flex justify-content-between">
        <VMClusterWrapper>
          <div className="header d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <StyledTypo
                color="#50505066"
                size={1.125}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={500}
              >
                VM Instances:
              </StyledTypo>
              <StyledTypo
                className="ml-2"
                color="#505050"
                size={1.125}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                {observationData?.["resources"]?.[0]?.details?.nodes?.count}
              </StyledTypo>
            </div>
            <VMIcon />
          </div>
        </VMClusterWrapper>
        <VMClusterWrapper>
          <div className="header d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <StyledTypo
                color="#50505066"
                size={1.125}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={500}
              >
                Kubernetes Clusters:
              </StyledTypo>
              <StyledTypo
                className="ml-2"
                color="#505050"
                size={1.125}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                {Array.isArray(
                  observationData?.["resources"]?.[0]?.details?.cluster
                )
                  ? observationData?.["resources"]?.[0]?.details?.cluster
                      ?.length
                  : 1}
              </StyledTypo>
            </div>
            <ClusterIcon />
          </div>
        </VMClusterWrapper>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <VMClusterWrapper>
          <div className="header d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <StyledTypo
                color="#50505066"
                size={1.125}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={500}
              >
                Workloads:
              </StyledTypo>
              <StyledTypo
                className="ml-2"
                color="#505050"
                size={1.125}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                {observationData?.["resources"]?.[0]?.details?.pods?.count}
              </StyledTypo>
            </div>
          </div>
        </VMClusterWrapper>
        <VMClusterWrapper>
          <div className="header d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <StyledTypo
                color="#50505066"
                size={1.125}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={500}
              >
                Service & Ingress:
              </StyledTypo>
              <StyledTypo
                className="ml-2"
                color="#505050"
                size={1.125}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={700}
              >
                {observationData?.["resources"]?.[0]?.details?.services?.count}
              </StyledTypo>
            </div>
            {/*<ClusterIcon />*/}
          </div>
        </VMClusterWrapper>
      </div>
    </MainContainer>
  );
};

export default ApplicationDetailsPanel;
