export const planConstants = {
  CREATE_NEW_SUBSCRIPTION_REQUEST: 'CREATE_NEW_SUBSCRIPTION_REQUEST',
  CREATE_NEW_SUBSCRIPTION_SUCCESS: 'CREATE_NEW_SUBSCRIPTION_SUCCESS',
  CREATE_NEW_SUBSCRIPTION_FAILURE: 'CREATE_NEW_SUBSCRIPTION_FAILURE',
  CLEAR_NEW_SUBSCRIPTION_STATUS: 'CLEAR_NEW_SUBSCRIPTION_STATUS',
  CLEAR_SUBSCRIPTION: 'CLEAR_SUBSCRIPTION',

  FETCH_CURRENT_SUBSCRIPTION_REQUEST: 'FETCH_CURRENT_SUBSCRIPTION_REQUEST',
  FETCH_CURRENT_SUBSCRIPTION_SUCCESS: 'FETCH_CURRENT_SUBSCRIPTION_SUCCESS',
  FETCH_CURRENT_SUBSCRIPTION_FAILURE: 'FETCH_CURRENT_SUBSCRIPTION_FAILURE',

  FETCH_PAYMENT_METHODS_REQUEST: 'FETCH_PAYMENT_METHODS_REQUEST',
  FETCH_PAYMENT_METHODS_SUCCESS: 'FETCH_PAYMENT_METHODS_SUCCESS',
  FETCH_PAYMENT_METHODS_FAILURE: 'FETCH_PAYMENT_METHODS_FAILURE',

  UPGRADE_SUBSCRIPTION_REQUEST: 'UPGRADE_SUBSCRIPTION_REQUEST',
  UPGRADE_SUBSCRIPTION_SUCCESS: 'UPGRADE_SUBSCRIPTION_SUCCESS',
  UPGRADE_SUBSCRIPTION_FAILURE: 'UPGRADE_SUBSCRIPTION_FAILURE',

  CREATE_PAYMENT_REQUEST: 'CREATE_PAYMENT_REQUEST',
  CREATE_PAYMENT_SUCCESS: 'CREATE_PAYMENT_SUCCESS',
  CREATE_PAYMENT_FAILURE: 'CREATE_PAYMENT_FAILURE',

  DELETE_PAYMENT_REQUEST: 'DELETE_PAYMENT_REQUEST',
  DELETE_PAYMENT_SUCCESS: 'DELETE_PAYMENT_SUCCESS',
  DELETE_PAYMENT_FAILURE: 'DELETE_PAYMENT_FAILURE',
}
