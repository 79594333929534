import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { iacActions } from "../../../redux/actions";
import { connect } from "react-redux";
import * as global from "../../../helpers/constants";
import StyledTypo from "../../../components/Typo/StyledTypo";
import SummaryPanel from "./SummaryPanel/SummaryPanel";
import BuildTimePanel from "./BuildTimePanel/BuildTimePanel";
import CreateProjectPanel from "./Projects/CreateProjectPanel";
import { ReactComponent as LeftArrow } from "../../../assets/icons/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../../assets/icons/right-arrow.svg";
import { MainContext } from "../../../contexts";
import {
  ViewContainer,
  PageTitle,
  FlexComp,
} from "../../../components/PageLayout/Containers";
import {
  TabHeader,
  TabHeaderItem,
  TabBody,
} from "../../../components/PageLayout/Tab";
import { TableWrapper } from "../../../components/PageLayout/Table";
import { BackButton } from "../../../components/PageLayout/BackButton";
import { useTitle } from "../../../helpers/useTtitle";

const MoveCTAWrapper = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  // align-items: center;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  cursor: pointer;
`;

const IaCLayout = (props) => {
  const {
    fetchIaCReportAction,
    iacReport,
    iac_projects,
    fetchIacProjectsAction,
    id: project_id,
    createdFlag,
    createIacProjectAction,
    updateIacProjectAction,
    clearCreateFlagAction,
    fetchIacRemediationAction,
    iac_remediation,
  } = props;

  const history = useHistory();

  const filterPanelRef = useRef();
  const headerRef = useRef();

  const { gIacProjects } = useContext(MainContext);

  const [tabs, setTabs] = useState(global.iacTabList);
  const [selectedTab, setSelectedTab] = useState(null);

  const [openCreatePanel, setOpenCreatePanel] = useState(false);
  const [updateProject, setUpdateProject] = useState(null);

  const location = useLocation();

  const [selectedReport, setSelectedReport] = useState({});
  const [preFilterData, setPreFilterData] = useState();
  const [, setRenderFlag] = useState(false);

  useTitle(`${global.commonTitle} - IaC Scan - ${iac_projects.filter(project => project.id === project_id)?.[0]?.['display_name']}`)

  useEffect(() => {
    setSelectedTab(
      location?.search
        ? new URLSearchParams(location?.search).get("tab")
        : tabs.filter((item) => item.active)?.[0]?.key
    );
  }, [setSelectedTab]);

  useEffect(() => {
    if (selectedTab) {
      handleSelectedTab(selectedTab);
    }
  }, [selectedTab]);

  const handleSelectedTab = (key) => {
    setPreFilterData(null);
    setTabs([...tabs.map((tab) => ({ ...tab, active: tab.key === key }))]);
  };

  const onLink = (data, tab = "details") => {
    handleSelectedTab(tab);
    setPreFilterData({ ...data });
  };

  useEffect(() => {
    if (iacReport?.[project_id] && iacReport?.[project_id].length > 0) {
      setSelectedReport(iacReport?.[project_id]?.[0]);
    }
  }, [iacReport]);

  useEffect(() => {
    if (iac_projects.length === 0) {
      fetchIacProjectsAction();
    }
  }, []);

  useEffect(() => {
    if (
      iac_projects.filter((project) => project.id === project_id).length > 0
    ) {
      fetchIaCReportAction(
        iac_projects.filter((project) => project.id === project_id)?.[0]
      );
    } else {
      history.push("/iac");
    }
  }, [iac_projects, project_id]);

  useEffect(() => {
    setUpdateProject(
      iac_projects.filter((project) => project.id === project_id)?.[0]
    );
  }, [project_id]);

  const togglePanel = () => {
    setOpenCreatePanel((openCreatePanel) => !openCreatePanel);
  };

  const findIndex = () => {
    return gIacProjects.findIndex((project) => project.id === project_id);
  };

  const findId = (up = true) => {
    const idx = findIndex();
    const targetIdx = up ? idx + 1 : idx - 1;
    return gIacProjects.filter((project, idx) => idx === targetIdx)?.[0]?.id;
  };

  return (
    <ViewContainer>
      <div className="main-container d-flex flex-column">
        <PageTitle
          className="pb-2 d-flex align-items-center justify-content-between"
          ref={headerRef}
        >
          <StyledTypo className="ml-2 heading">
            <BackButton
              onClick={() => history.push("/iac")}
              title="Back"
              href="#"
            >
              {" "}
              IaC Scan{" "}
            </BackButton>{" "}
            - Project :{" "}
            <strong>
              {
                iac_projects.filter(
                  (project) => project.id === project_id
                )?.[0]?.["display_name"]
              }
            </strong>
          </StyledTypo>

          <TabHeader>
            {tabs.map((tab) => (
              <TabHeaderItem
                className={tab.active && "active"}
                onClick={() => handleSelectedTab(tab.key)}
              >
                {tab.title}
              </TabHeaderItem>
            ))}
          </TabHeader>

          <FlexComp gap="20px">
            {!!tabs.filter((tab) => tab.key === "details" && tab.active)
              .length && filterPanelRef?.current?.getFilterPanel()}
            <FlexComp gap="0px">
              <StyledTypo className="mr-2 heading">
                {findIndex() + 1} of {gIacProjects.length}
              </StyledTypo>
              <MoveCTAWrapper
                disabled={findIndex() === 0}
                onClick={() => history.push(`/iac/${findId(false)}`)}
              >
                <LeftArrow fill={findIndex() === 0 ? "#A4AFB7" : "#505050"} />
              </MoveCTAWrapper>
              <MoveCTAWrapper
                disabled={findIndex() + 1 === iac_projects.length}
                onClick={() => history.push(`/iac/${findId()}`)}
              >
                <RightArrow
                  fill={
                    findIndex() + 1 === iac_projects.length
                      ? "#A4AFB7"
                      : "#505050"
                  }
                />
              </MoveCTAWrapper>
            </FlexComp>
          </FlexComp>
        </PageTitle>
        <TableWrapper
          marginTop={`${
            headerRef?.current?.offsetHeight + headerRef?.current?.offsetTop ||
            100
          }px`}
        >
          <TabBody>
            {!!tabs.filter((tab) => tab.key === "summary" && tab.active)
              .length && (
              <SummaryPanel
                report={selectedReport}
                project={
                  iac_projects.filter(
                    (project) => project.id === project_id
                  )?.[0]
                }
                setOpenUpdatePanel={setOpenCreatePanel}
                onLink={onLink}
              />
            )}
            {!!tabs.filter((tab) => tab.key === "details" && tab.active)
              .length && (
              <BuildTimePanel
                report={selectedReport}
                fetchIacRemediationAction={fetchIacRemediationAction}
                iac_remediation={iac_remediation}
                preFilterData={preFilterData}
                setRenderFlag={setRenderFlag}
                ref={filterPanelRef}
              />
            )}
          </TabBody>
        </TableWrapper>
      </div>
      <CreateProjectPanel
        isOpen={openCreatePanel}
        toggle={togglePanel}
        createProjectAction={createIacProjectAction}
        updateProjectAction={updateIacProjectAction}
        createdFlag={createdFlag}
        clearCreateFlagAction={clearCreateFlagAction}
        updateProject={updateProject}
      />
    </ViewContainer>
  );
};

function mapStateToProps(state) {
  const { iacReport, iac_projects, createdFlag, iac_remediation } = state.iacs;
  return {
    iacReport,
    iac_projects,
    createdFlag,
    iac_remediation,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchIaCReportAction: (project) =>
      dispatch(iacActions.fetchIaCReportAction(project)),
    fetchIacProjectsAction: () => dispatch(iacActions.fetchIacProjectsAction()),
    createIacProjectAction: (payload) =>
      dispatch(iacActions.createIacProjectAction(payload)),
    updateIacProjectAction: (payload) =>
      dispatch(iacActions.updateIacProjectAction(payload)),
    clearCreateFlagAction: () => dispatch(iacActions.clearCreateFlagAction()),
    fetchIacRemediationAction: (query_id) => {
      dispatch(iacActions.fetchIacRemediationAction(query_id));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IaCLayout);
