import React from "react";
import styled from "styled-components";
import StyledTypo from "../Typo/StyledTypo";

const CommonInputWrapper = styled.div`
  textarea {
    width: 100%;
    margin-top: 5px;
    position: relative;
    padding: 0;
    border: none;
    border-bottom: 1px solid #ebebeb;
    font-family: "Roboto";
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 2;
    background-color: transparent;
    color: #485DCD;
    outline: none;
    transition: 0.3s border-bottom-color ease-in-out;
    -webkit-appearance: none;
    resize: none;
    &:active, &:focus {
      border-bottom: 1px solid #66c4d5;
    }
    &:disabled {
      color: #485DCDcc;
    }
`;

const CustomTextarea = (props) => {
  const { value, setValue, label, disabled } = props;

  return (
    <CommonInputWrapper>
      <StyledTypo size="0.8" color={disabled ? "#505050cc" : "#505050"}>
        {label}
      </StyledTypo>
      <textarea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        rows={value.length < 50 ? 1 : value.length < 400 ? 3 : 5}
        disabled={disabled}
      />
    </CommonInputWrapper>
  );
};

export default CustomTextarea;
