import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import StyledTypo from "../../../components/Typo/StyledTypo";
import { GradientCircularProgress } from "react-circular-gradient-progress";
import GradientProgressBar from "../../../components/GradientProgressBar/GradientProgressBar";
import {
  providerComplianceType,
  dashboardOverviewList,
  NonComplianceStatusColor,
  providerResourceTypes,
  loadingBar,
  aggregatedData,
  commonTitle,
  cspData,
} from "../../../helpers/constants";
import { ReactComponent as AssetsMenuIcon } from "../../../assets/icons/assets-menu-icon.svg";
import { ReactComponent as CloudSecurityIcon } from "../../../assets/icons/cloud-security.svg";

import CubeIcon from "../../../assets/images/3d-cube.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ProjectsSummary from "./ProjectsSummary";
import { dashboardActions, applicationActions } from "../../../redux/actions";
import { connect } from "react-redux";
import ProviderComplianceTrend from '../trends/ProviderComplianceTrend'

import InfraListByCompliance from './InfraListByCompliance'
import InfraListByRisks from './InfraListByRisks'
import { useTitle } from "../../../helpers/useTtitle";


const PanelWrapper = styled.div`
  // padding: 20px;
  width: 100%;
  // background: #ffffff;

  box-sizing: border-box;
  // border-radius: 0 10px 10px 10px;
  // box-shadow: 1px 2px 6px #ccc;
  // border: 1px solid #f1f5fa;

  .panel-title-header {
    padding: 0 0 47px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .CircularProgress {
    position: relative;
  }

  #cpg-main {
    top: 12px;
  }
`;

const OverviewWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  // background: #fff;
  // cursor: pointer;

  & .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #505050;
    font-family: "Roboto";
    font-size: 2rem;
    font-weight: 400;
  }
`;

const OverviewBlock = styled.div`
  // padding: 15px;
  border-radius: 5px;
  // background-color: ${(props) => (props.color ? props.color : "#fff")};
  width: 20%;
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  //min-height: 100px;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
`;

const OverviewContentWrapper = styled.div`
  width: 100%;
`;

const TotalBlock = styled.div`
  padding: 10px;
  border-radius: 5px;
  // background-color: ${(props) => (props.color ? props.color : "#fff")};
  width: 100%;
  min-height: 120px;
  border: 1px solid rgba(234, 234, 234, 0.65);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  // filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
  display: flex;
  align-items: center;
  justify-content: space-between;

  .item {
    border-right: 1px solid #eaeaea;
    &.nonborder {
      border: none;
    }
    width: 100%;
    padding: 5px 10px;
  }
`;

const RemediationOverviewWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 15px;
`;

const RemediationOverviewBlock = styled.div`
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #eee;
  border-left: 5px solid ${(props) => (props.color ? props.color : "#ccc")};
  width: 100%;
  min-height: 90px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  // filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
`;

const ComplianceScoreWrapper = styled.div`
  // margin-top: 10px;
  width: calc(100vw - 145px);
  padding: 10px;
  // border: 1px solid #eaeaea;
  // border-radius: 5px;
  // background: #fff;
  // height: fit-content;
  // height: 640px;

  .content {
    display: grid;
    // align-items: center;
    // flex-wrap: wrap;
    // justify-content: space-between;
    grid-template-columns: 33% 33% 33%;
    column-gap: 15px;
  }
`;

export const CardViewContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 15px;
`;

export const CardViewItem = styled.div`
  border-radius: 5px;
  // background: #fff;
  padding: 20px;
  // padding-bottom: 10px;
  justify-content: center;
  pointer-events: ${(props) => (props.active ? "none" : "auto")};
  cursor: pointer;
  display: flex;
  // align-items: center;
  // width: ${(props) => (props.width ? props.width : "32.5%")};
  min-width: 380px;
  margin: 10px 0;
  border: 1px solid #eaeaea;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  // $ {(props) =>
  //   props.active
  //     ? "rgb(14 30 37 / 12%) 0px 2px 4px 0px, rgb(14 30 37 / 32%) 0px 2px 16px 0px"
  //     : "rgb(0 0 0 / 10%) 0px 3px 12px"};

  border-bottom: 3px solid
    ${(props) => (props.active ? "rgb(90 163 237)" : "transparent")};
  position: relative;
  transition: transform 0.5s;
  &:hover {
    // border-bottom: 3px solid rgb(90 163 237);
    transform: scale(1.02);
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 12px;
  }

  .horizon-divider {
    width: calc(100% + 40px);
    border-top: 1px solid #eaeaea;
    margin: 5px 0;
    margin-left: -20px;
    height: 0;
  }

  .vertical-divider {
    width: 0;
    border-left: 1px solid #eaeaea;
    margin: 0 15px;
    margin-top: -20px;
    margin-bottom: -23px;
    min-height: 100%;
  }

  .status-mark {
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: 50%;
    background: ${(props) => props.markColor ?? "#ff487f"};
    width: ${(props) => props.markSize ?? 7}px;
    height: ${(props) => props.markSize ?? 7}px;
  }

  .right-side {
    flex: 1;
    margin: auto 0;
  }
  .left-side {
    width: 20%;
    margin: auto 0;
  }

  .status {
    display: flex;
    gap: 10px;
    align-items: center;

    .item {
      width: 30px;
      height: 25px;
      text-align: center;
      border-radius: 3px;
      // box-shadow: rgba(0, 0, 0, 0.16) 1px 1px 4px;
      align-items: center;
      display: flex;
      justify-content: center;

      &.critical, &.CRITICAL {
        border: 1px solid #eee;
        border-bottom: 3px solid  ${(props) => props.critical?.color ?? "#ccc"};
      }
      &.high, &.HIGH {
        border: 1px solid #eee;
        border-bottom: 3px solid  ${(props) => props.high?.color ?? props?.HIGH?.color ?? "#ccc"};
      }
      &.medium, &.MEDIUM {
        border: 1px solid #eee;
        border-bottom:3px solid  ${(props) => props.medium?.color ?? props.MEDIUM?.color ?? "#ccc"};
      }
      &.low, &.LOW {
        border: 1px solid #eee;
        border-bottom: 3px solid  ${(props) => props.low?.color ?? props.LOW?.color ?? "#ccc"};
      }
      &.info, &.INFO {
        border: 1px solid #eee;
        border-bottom: 3px solid  ${(props) => props.info?.color ?? props.INFO?.color ?? "#ccc"};
      }
    }
  }
`;

const CategoryWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
  padding-block: 15px;
`;

const CategoryItem = styled.div`
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 1px 1px 4px;
  border: 1px solid #eee;
  border-radius: 10px;
  &.active {
    border: 1px solid #666;
    box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 4px;
    cursor: default;
  }
  cursor: pointer;
  display: flex;
  gap: 5px;
`;

const StatsTabWrapper = styled.div`
  padding: 10px 0px;
`;

const ProviderDashboard = (props) => {
  const {
    data,
    provider,
    loading_summary,
    compliance_infralist,
    resource_infralist,
    compliance_trend,
    fetchComplianceInfrastructureListAction,
    fetchResourceInfrastructureListAction,
    fetchTrendDataAction,
  } = props;

  const [complianceView, setComplianceView] = useState({});
  const [resourceStatus, setResourceStatus] = useState([]);
  const [toggleFixed, setToggleFixed] = useState(1);
  const [toggleFlyout, setToggleFlyout] = useState(false);
  const [selectedCompliance, setSelectedCompliance] = useState("");
  const [selectedResource, setSelectedResource] = useState("");

  const [activeCategory, setActiveCategory] = useState(null)

  const [infraListByCompliance, setInfraListByCompliance] = useState([])
  const [infraListByRisks, setInfraListByRisks] = useState([])

  const [searchText, setSearchText] = useState("");
  const [pageTitle, setPageTitle] = useState(`${commonTitle} - Dashboard`)

  const handleComplianceCardSelect = (compliance) => {
    setSelectedCompliance(compliance?.use ?? compliance.value);
    setSelectedResource("");

    setToggleFlyout((prevState) => !prevState);
  };

  const handleResourceCardSelect = (resource) => {
    setSelectedCompliance("");
    setSelectedResource(resource?.value);

    setToggleFlyout((prevState) => !prevState);
  };

  useTitle(pageTitle);

  useEffect(() => {
    setPageTitle(pageTitle => `${pageTitle} (${cspData?.filter(csp => csp.key === provider)?.[0]?.abbr})`);
  }, [provider])


  const filteredResources = useCallback(() => {
    let filteredData = [...resourceStatus];
    if(searchText && searchText.length > 0) {
      filteredData = filteredData.filter(item => item?.label?.toLowerCase().includes(searchText.toLowerCase()))
    }

    return filteredData
    
  }, [searchText, resourceStatus])

  useEffect(() => {
    if (selectedCompliance) {
      fetchComplianceInfrastructureListAction(provider, selectedCompliance);
    }
    if (selectedResource) {
      fetchResourceInfrastructureListAction(provider, selectedResource);
    }
  }, [
    fetchComplianceInfrastructureListAction,
    fetchResourceInfrastructureListAction,
    provider,
    selectedCompliance,
    selectedResource,
  ]);

  useEffect(() => {
    fetchTrendDataAction({ provider })
  }, [provider])

  useEffect(() => {
    if (Object.values(data).length === 0) return;
    setComplianceView([
      ...Object.keys(providerComplianceType[provider]).map((compliance) => {
        const complianceData = data?.compliance?.details;
        let complianceValue = {};
        complianceValue = {
          ...providerComplianceType[provider]?.[compliance],
          status:
            complianceData
              ?.filter((item) =>
                item.id.includes(
                  providerComplianceType[provider]?.[compliance]?.value
                )
              )
              .reduce((acc, cur) => {
                const tempData = {
                  ...acc,
                  ...Object.keys(NonComplianceStatusColor).reduce(
                    (accu, key) => {
                      const temp = {
                        ...accu,
                        [key]: {
                          fail:
                            (cur?.results?.[key]?.fail ?? 0) +
                            (acc?.[key]?.fail ?? 0),
                          pass:
                            (cur?.results?.[key]?.pass ?? 0) +
                            (acc?.[key]?.pass ?? 0),
                          skip:
                            (cur?.results?.[key]?.skip ?? 0) +
                            (acc?.[key]?.skip ?? 0),
                          total:
                            (cur?.results?.[key]?.total ?? 0) +
                            (acc?.[key]?.total ?? 0),
                        },
                      };
                      return temp;
                    },
                    {}
                  ),
                };
                return tempData;
              }, {}) ?? {},
        };
        return complianceValue;
      }),
    ]);

    setResourceStatus([
      ...providerResourceTypes?.[data?.provider].map((type) => ({
        ...type,
        status:
          data?.resource?.details?.filter((item) => item.id === type.value)?.[0]
            ?.results ?? {},
      })),
    ]);
  }, [data, provider]);

  // useEffect(() => {
  //   // setResourceStatus( resourceStatus => [
  //   //   ...resourceStatus,
  //   //   ...(appList ?? [])
  //   //   .filter(app => app.platform === provider)
  //   //   .map(app => ({
  //   //     label: app.name, category: infrastructureType.category, logo: infrastructureType.logo?.[provider](30),
  //   //     status: app.summary
  //   //   }))
  //   // ]);
  //   const complianceInfraList = (appList ?? [])
  //   .filter(app => app.platform === provider)
  //   .map(infra => ({
  //       id: infra.id,
  //       name: infra.name,
  //       summary: infra.summary,
  //       compliance_score: aggregatedData(infra.summary, 'total') !== 0 ? (aggregatedData(infra.summary, 'fail') / aggregatedData(infra.summary, 'total') * 100) : 0
  //     })).sort((a, b) => a.compliance_score > b.compliance_score ? -1 : b.compliance_score > a.compliance_score ? 1 : 0)
  //   setInfraListByCompliance([
  //     ...complianceInfraList.map(infra => ({
  //       ...infra,
  //       compliance_score: infra.compliance_score
  //     }))
  //   ])

  //   const riskInfraList = (appList ?? []).filter(app => app.platform === provider)
  //     .map(infra => ({
  //       name: infra.name,
  //       id: infra.id,
  //       summary: infra.summary,
  //       risks: Object.keys(infra.summary).reduce((acc, cur) => acc + infra.summary?.[cur]?.fail * infrastructureRiskPoint?.[cur], 0)
  //     })).sort((a, b) => a.risks > b.risks ? -1 : b.risks > a.risks ? 1 : 0)
  //   setInfraListByRisks([
  //     ...riskInfraList.map(infra => ({
  //       ...infra,
  //       risks: Math.round(infra.risks * 10) / 10
  //     }))
  //   ])
  // }, [appList, provider])

  
  const getComplianceResult = (compliance, type, arg = "") => {
    let result = 0;
    const severity = ["critical", "high", "medium", "low"];
    const compliance_name = compliance?.use ?? compliance?.value;
    const compliance_detail = data?.compliance?.details?.find(
      (detail) => detail.id === compliance_name
    );

    if (type === "percent") {
      if (compliance_detail?.results !== undefined) {
        let compliance_detail_agg = Object.values(
          compliance_detail?.results
        ).reduce(
          (acc, cur) => {
            Object.keys(cur).forEach((key) => {
              acc[key] += cur[key];
            });
            return acc;
          },
          { pass: 0, fail: 0, skip: 0, total: 0 }
        );
        result = (
          (compliance_detail_agg?.pass * 100) /
          compliance_detail_agg.total
        ).toFixed(toggleFixed);
      }
    }

    if (severity.includes(type)) {
      result = compliance_detail?.results[type]?.fail ?? 0;
    }

    if (type === "resource") {
      result = compliance_detail?.result_by_resource?.[arg];
    }

    if (type === "compliance") {
      if (compliance_detail?.results !== undefined) {
        let compliance_detail_agg = Object.values(
          compliance_detail?.results
        ).reduce(
          (acc, cur) => {
            Object.keys(cur).forEach((key) => {
              acc[key] += cur[key];
            });
            return acc;
          },
          { pass: 0, fail: 0, skip: 0, total: 0 }
        );
        result = compliance_detail_agg?.[arg] ?? 0;
      }
    }

    return result;
  };

  const getCount = (arg) => {
    return Object.keys(data?.summary).reduce((acc, key) => {
      return acc + data?.summary?.[key]?.[arg];
    }, 0);
  };

  return (
    <PanelWrapper>
      <div>
        {Object.values(data ?? {}).length ? (
          <>
            <div>
              <OverviewWrapper
                onClick={() =>
                  setToggleFixed((toggleFixed) =>
                    toggleFixed === 0 ? 1 : toggleFixed === 1 ? 2 : 0
                  )
                }
              >
                <OverviewBlock color="#fff">
                  <StyledTypo
                    color="#505050"
                    size={0.8}
                    line={1.4}
                    letter="0"
                    family="Poppins"
                    fontWeight={300}
                  >
                    Overall Score
                  </StyledTypo>
                  <GradientCircularProgress
                    id="cpg-main"
                    startColor="#6fe0c1"
                    middleColor="#61bcdc"
                    endColor="#58a3ee"
                    color="#dddddd1f" color2="#989EA2"
                    progress={(
                      (getCount("pass") * 100) /
                      getCount("total")
                    ).toFixed()}
                    size={190}
                    strokeWidth={6}
                    emptyColor="#f1f1f1"
                  />
                </OverviewBlock>
                <OverviewContentWrapper>
                  <TotalBlock>
                    {dashboardOverviewList
                      .filter((each) => each.key !== "total")
                      .map((each) => (
                        <div className={`item ${!each.border && "nonborder"}`}>
                          <StyledTypo
                            className="ml-3"
                            color="#505050"
                            size={0.8}
                            line={1.4}
                            letter="0"
                            family="Roboto"
                            fontWeight={300}
                          >
                            {each.title}
                          </StyledTypo>
                          <div className="d-flex justify-content-between align-items-end pt-3">
                            <StyledTypo
                              className="text-center ml-3"
                              color="#505050"
                              size={1.7}
                              line={1}
                              letter="0"
                              family="Roboto"
                              fontWeight={400}
                            >
                              {(
                                (getCount(each.key) * 100) /
                                getCount("total")
                              ).toFixed(toggleFixed)}
                              {" %"}
                            </StyledTypo>
                            <div
                              className="d-flex justify-content-center align-items-center"
                              title="Non-Compliant / Total"
                            >
                              <StyledTypo
                                className="mr-2"
                                color="#505050"
                                size={0.75}
                                line={1}
                                letter="0"
                                family="Roboto"
                                fontWeight={300}
                              >
                                {getCount(each.key) + " / " + getCount("total")}
                              </StyledTypo>
                            </div>
                          </div>
                        </div>
                      ))}
                  </TotalBlock>
                  <RemediationOverviewWrapper>
                    {Object.keys(NonComplianceStatusColor).map((status) => (
                      <RemediationOverviewBlock
                        color={NonComplianceStatusColor[status].color}
                      >
                        <StyledTypo
                          className="severity-text"
                          size={0.7}
                          line={1.4}
                          letter="0"
                          family="Roboto"
                          fontWeight={400}
                          marginTop="4"
                        >
                          {status}
                        </StyledTypo>
                        <div className="d-flex justify-content-between align-items-end pt-3">
                          <StyledTypo
                            size={1.7}
                            line={1}
                            letter="0"
                            family="Roboto"
                            fontWeight={400}
                          >
                            {getCount("total") > 0
                              ? (
                                  ((data?.summary?.[status]?.fail ?? 0) * 100) /
                                  getCount("total")
                                ).toFixed(toggleFixed)
                              : 0}
                            {" %"}
                          </StyledTypo>
                          <StyledTypo
                            size={0.65}
                            line={1}
                            letter="0"
                            family="Roboto"
                            fontWeight={400}
                            title="Non-Compliant / Total"
                          >
                            {data?.summary?.[status]?.fail ?? 0} /{" "}
                            {getCount("total") ?? 0}
                          </StyledTypo>
                        </div>
                      </RemediationOverviewBlock>
                    ))}
                  </RemediationOverviewWrapper>
                </OverviewContentWrapper>
              </OverviewWrapper>
            </div>
            <StatsTabWrapper className="d-flex">
              <Tabs>
                <TabList>
                  <Tab>
                    <StyledTypo
                      line="1.8"
                      size={0.8}
                      family="Poppins"
                      fontWeight={300}
                    >
                      Status by Compliance
                    </StyledTypo>
                  </Tab>
                  <Tab>
                    <StyledTypo
                      line="1.8"
                      size={0.8}
                      family="Poppins"
                      fontWeight={300}
                    >
                      Status by Resources
                    </StyledTypo>
                  </Tab>
                  {/* <Tab>
                    <StyledTypo
                      line="1.8"
                      size={0.8}
                      family="Poppins"
                      fontWeight={300}
                    >
                      Compliance & Risks
                    </StyledTypo>
                  </Tab> */}
                  <Tab>
                    <StyledTypo
                      line="1.8"
                      size={0.8}
                      family="Poppins"
                      fontWeight={300}
                    >
                      Trends
                    </StyledTypo>
                  </Tab>
                </TabList>
                <TabPanel>
                  <ComplianceScoreWrapper>
                    <div className="content">
                      {Object.values(complianceView).map((compliance) => (
                        <CardViewItem
                          {...NonComplianceStatusColor}
                          onClick={() => handleComplianceCardSelect(compliance)}
                        >
                          <div className="text-center left-side">
                            <img
                              src={compliance?.logo}
                              alt={`${compliance?.label} logo`}
                              width={65}
                              height={65}
                            />
                            <StyledTypo
                              className="mt-2"
                              color="#505050"
                              size={0.8}
                              line={1.4}
                              letter="0"
                              family="Roboto"
                              fontWeight={400}
                            >
                              {getComplianceResult(compliance, "percent")} %
                            </StyledTypo>
                          </div>
                          <div className="vertical-divider" />
                          <div className="right-side">
                            <div className="d-flex justify-content-between ">
                              <StyledTypo
                                color="#505050"
                                size={0.8}
                                line={1.4}
                                letter="0"
                                family="Roboto"
                                fontWeight={300}
                              >
                                {compliance?.label}
                              </StyledTypo>
                              <div className="status">
                                {Object.keys(compliance?.status).map(
                                  (status) => (
                                    <div className={`item ${status}`}>
                                      <StyledTypo
                                        size={0.625}
                                        line={1.4}
                                        letter="0"
                                        family="Roboto"
                                        fontWeight={300}
                                      >
                                        {getComplianceResult(
                                          compliance,
                                          status
                                        )}
                                      </StyledTypo>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            <GradientProgressBar
                              color="#ececec70" color2="#adadad"
                              value={getComplianceResult(compliance, "percent")}
                              maxValue="100"
                              rotation="90"
                            />
                            <div className="d-flex mt-3">
                              <div className="d-flex">
                                <CloudSecurityIcon
                                  fill="#505050"
                                  width={20}
                                  height={20}
                                />
                                <StyledTypo
                                  color="#505050"
                                  size={0.8}
                                  line={1.4}
                                  letter="0"
                                  family="Roboto"
                                  fontWeight={300}
                                  className="mt-1 ml-2"
                                  title="Compliance count: Non-Compliant / Total"
                                >
                                  {getComplianceResult(
                                    compliance,
                                    "compliance",
                                    "fail"
                                  )}{" "}
                                  /{" "}
                                  {getComplianceResult(
                                    compliance,
                                    "compliance",
                                    "total"
                                  )}
                                </StyledTypo>
                              </div>
                              <div className="d-flex ml-5">
                                <AssetsMenuIcon
                                  fill="#505050"
                                  width={20}
                                  height={20}
                                />
                                <StyledTypo
                                  color="#505050"
                                  size={0.8}
                                  line={1.4}
                                  letter="0"
                                  family="Roboto"
                                  fontWeight={300}
                                  className="mt-1 ml-2"
                                  title="Resource count: Non-Compliant / Total"
                                >
                                  {getComplianceResult(
                                    compliance,
                                    "resource",
                                    "fail"
                                  )}{" "}
                                  /{" "}
                                  {getComplianceResult(
                                    compliance,
                                    "resource",
                                    "total"
                                  )}
                                </StyledTypo>
                              </div>
                            </div>
                          </div>
                        </CardViewItem>
                      ))}
                    </div>
                  </ComplianceScoreWrapper>
                </TabPanel>
                <TabPanel>
                  <ComplianceScoreWrapper>
                    {/* <div className="w-100 d-flex  align-items-center justify-content-end flex-wrap">
                      <div className="d-flex align-items-center mr-3 filter-menu">
                        <a
                          className="menu-icon"
                          onClick={() => setFilterBarOpen(!filterBarOpen)}
                          title="Filter By"
                          alt="Filter By"
                          href="#"
                        >
                          <FilterIcon
                            width={22}
                            height={22}
                            fill="#606060"
                            className="svg-icon"
                          />
                          <span className="menu-tip">Filter by</span>
                        </a>
                        <a
                          className={
                            checkFilterContent() ? "menu-icon" : "menu-icon menu-disabled"
                          }
                          onClick={clearFilter}
                          title="Filter Clear"
                          alt="Filter Clear"
                          href="#"
                        >
                          <FilterClearIcon
                            width={22}
                            height={22}
                            fill="#606060"
                            className="svg-icon"
                          />
                          <span className="menu-tip">Filter clear</span>
                        </a>
                      </div>
                      <div>
                        <CustomInput
                          minWidth={250}
                          placeholder="Search"
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                      </div>
                    </div> */}
                    {/* <CategoryWrapper>
                      <CategoryItem 
                        onClick={() => setActiveCategory(null)} 
                        className={!activeCategory ? 'active' : ''}
                      >
                        <StyledTypo size={0.8}>All</StyledTypo>
                        <StyledTypo size={0.8} fontWeight={400}>{(filteredResources() ?? []).length ?? 0}</StyledTypo>
                      </CategoryItem>
                      {assetsCategories.map((category) => (
                        <CategoryItem 
                          key={category.key} 
                          onClick={() => setActiveCategory((activeCategory) => category.key === 'infrastructure' ? activeCategory : category)} 
                          className={activeCategory?.key === category.key ? 'active' : ''}
                        >
                          <StyledTypo size={0.8}>{category.label}</StyledTypo>
                          <StyledTypo size={0.8} fontWeight={400}>{(filteredResources() ?? []).filter(resource => resource.category === category.key).length ?? 0}</StyledTypo>
                        </CategoryItem>
                      ))}
                    </CategoryWrapper> */}
                    
                    <CardViewContainer>
                      {resourceStatus
                      // .filter(resource => !activeCategory || resource.category === activeCategory?.key)
                      .map((status) => (
                        <CardViewItem
                          {...NonComplianceStatusColor}
                          onClick={() => handleResourceCardSelect(status)}
                        >
                          <div className="text-center left-side">
                            <img
                              src={status?.logo ?? CubeIcon}
                              alt={`${status} logo`}
                              width={40}
                              height={40}
                            />
                            <StyledTypo
                              className="mt-2"
                              color="#505050"
                              size={0.8}
                              line={1.4}
                              letter="0"
                              family="Roboto"
                              fontWeight={400}
                            >
                              {aggregatedData(status?.status, "total") !== 0
                                ? (
                                    (aggregatedData(status?.status, "pass") /
                                      aggregatedData(status?.status, "total")) *
                                    100
                                  ).toFixed(1)
                                : 0}{" "}
                              %
                            </StyledTypo>
                          </div>
                          <div className="vertical-divider" />
                          <div className="right-side">
                            <div className="d-flex justify-content-between ">
                              <StyledTypo
                                color="#505050"
                                size={0.8}
                                line={1.4}
                                letter="0"
                                family="Roboto"
                                fontWeight={300}
                              >
                                {status?.label}
                              </StyledTypo>
                              <div className="status">
                                {Object.keys(NonComplianceStatusColor).map(
                                  (item) => (
                                    <div className={`item ${item}`}>
                                      <StyledTypo
                                        color="#687089"
                                        size={0.625}
                                        line={1.4}
                                        letter="0"
                                        family="Roboto"
                                        fontWeight={300}
                                      >
                                        {(status?.status?.[item]?.fail ?? 0) +
                                          (status?.status?.[item]?.skip ?? 0)}
                                      </StyledTypo>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            <GradientProgressBar
                              color="#ececec70" color2="#adadad"
                              value={
                                aggregatedData(status?.status, "total") !== 0
                                  ? (
                                      (aggregatedData(status?.status, "pass") /
                                        aggregatedData(
                                          status?.status,
                                          "total"
                                        )) *
                                      100
                                    ).toFixed(1)
                                  : 0
                              }
                              maxValue="100"
                              rotation="90"
                            />
                            <div className="d-flex  mt-3">
                              <div className="d-flex">
                                <AssetsMenuIcon
                                  fill="#505050"
                                  width={20}
                                  height={20}
                                />
                                <StyledTypo
                                  color="#505050"
                                  size={0.8}
                                  line={1.4}
                                  letter="0"
                                  family="Roboto"
                                  fontWeight={300}
                                  className="mt-1 ml-2"
                                  title="Resource count: Non-Compliant / Total"
                                >
                                  {aggregatedData(status?.status, "pass")} /{" "}
                                  {aggregatedData(status?.status, "total")}
                                </StyledTypo>
                              </div>
                            </div>
                          </div>
                          {/* <div className="status-mark" /> */}
                        </CardViewItem>
                      ))}
                    </CardViewContainer>
                  </ComplianceScoreWrapper>
                </TabPanel>
                {/* <TabPanel>
                  <ComplianceScoreWrapper>
                    <StyledTypo size={0.875} fontWeight={400}>
                      Top 10 Infrastructure - Non-compliance
                    </StyledTypo>
                    <InfraListByCompliance infras={infraListByCompliance} />
                    <StyledTypo size={0.875} className="mt-4" fontWeight={400}>
                      Top 10 Infrastructure - High Risks
                    </StyledTypo>
                    <InfraListByRisks infras={infraListByRisks} />
                  </ComplianceScoreWrapper>
                </TabPanel> */}
                <TabPanel>
                  <ProviderComplianceTrend
                      data={(compliance_trend ?? {})?.[provider] ?? []}
                  />
                </TabPanel>
              </Tabs>
            </StatsTabWrapper>
          </>
        ) : (
          <div className="d-flex justify-content-center py-5 align-items-center">
            {loadingBar("Rings", 50, 50)}
            <StyledTypo className="pl-2">Loading ...</StyledTypo>
          </div>
        )}
      </div>
      <ProjectsSummary
        provider={provider}
        loading={loading_summary}
        compliance={selectedCompliance}
        compliance_infralist={compliance_infralist ?? {}}
        resource={selectedResource}
        resource_infralist={resource_infralist ?? {}}
        isOpen={toggleFlyout}
        toggle={() => setToggleFlyout(() => !toggleFlyout)}
      />
    </PanelWrapper>
  );
};

function mapStateToProps(state) {
  const { loading_summary, compliance_infralist, resource_infralist } =
    state.dashboard;
  const { compliance_trend } = state.applications

  return {
    loading_summary,
    compliance_infralist,
    resource_infralist,
    compliance_trend,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchComplianceInfrastructureListAction: (provider, compliance) =>
      dispatch(
        dashboardActions.fetchComplianceInfrastructureListAction(
          provider,
          compliance
        )
      ),
    fetchResourceInfrastructureListAction: (provider, resource) =>
      dispatch(
        dashboardActions.fetchResourceInfrastructureListAction(
          provider,
          resource
        )
      ),
    fetchTrendDataAction: (data) => dispatch(applicationActions.fetchTrendDataAction(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderDashboard);
