import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { ReactComponent as CautionIcon } from "../../../assets/icons/m_caution_icon.svg";

import * as global from "../../../helpers/constants";
import { deploymentActions } from "../../../redux/actions";
import { connect } from "react-redux";
import { loadingBar } from "../../../helpers/constants";

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  width: 100%;
`;

const MarginElement = styled.div`
  margin: 0 -10px;
`;

const RemediationItem = styled.div`
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 17px 22px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 28px;
  //cursor: pointer;
  word-break: break-all;

  &.active:hover {
    cursor: pointer;
    border: 1px solid #b8b8bb;
    opacity: 1;
  }

  &.inactive {
    cursor: auto;
    opacity: 0.3;
  }
`;

const ObservationEachPart = styled.div`
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  width: 35px;
  min-width: 35px;
`;

const Remediation = (props) => {
  const {
    templates,
    applyTerraformAction,
    loading_task_id,
    selectedApp,
    remediationTemplates,
    appStatus,
  } = props;
  const [remediationData, setRemediationData] = useState([]);

  useEffect(() => {
    if (Array.isArray(remediationTemplates)) {
      setRemediationData([
        ...remediationTemplates.map((item) => ({
          ...item,
          id: item.organization_template_id,
          description: templates.filter(
            (each) => each.id === item.organization_template_id
          )?.[0]?.template.description,
          stack: "gcp",
          status: "red",
        })),
      ]);
    }
  }, [remediationTemplates, templates]);

  const validClick = () => {
    return !loading_task_id.filter((item) => item.app === selectedApp?.id)
      .length;
  };

  const validType = (type) => {
    return !loading_task_id.filter(
      (item) => item.app === selectedApp?.id && item.type === type
    ).length;
  };

  return (
    <div>
      <span className="font-size-36 font-weight-bold m-gray">Remediation</span>
      <ItemWrapper>
        <ObservationEachPart>
          {remediationData.map((item, idx) => (
            <MarginElement>
              <RemediationItem
                className={`${
                  validClick() && !appStatus ? "active" : "inactive"
                }`}
                onClick={() =>
                  validClick() &&
                  !appStatus &&
                  applyTerraformAction(item.id, "remediation", selectedApp.id)
                }
              >
                <IconWrapper className="m-tooltip">
                  {validType("remediation") ? (
                    <CautionIcon fill={global.colors.caution[item.status]} />
                  ) : (
                    <div className="d-flex justify-content-center">
                      {loadingBar("Rings", 50, 50)}
                    </div>
                  )}
                  <span className="tooltiptext-top">
                    {" "}
                    <div>Remediate</div>
                  </span>
                </IconWrapper>
                <div>
                  <div className="font-size-18 font-weight-bold m-dark-gray">
                    {item.name}
                  </div>
                  <div className="font-size-14 font-weight-light m-dark-gray">
                    {item.description}
                  </div>
                </div>
              </RemediationItem>
            </MarginElement>
          ))}
        </ObservationEachPart>
      </ItemWrapper>
    </div>
  );
};

function mapStateToProps(state) {
  const {
    deploy,
    reconfiguration,
    remediation,
    destroy,
    loading_task_id,
    type,
  } = state.deployments;
  return {
    deploy,
    reconfiguration,
    remediation,
    destroy,
    loading_task_id,
    type,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    applyTerraformAction: (org_temp_id, type, app_id) =>
      dispatch(
        deploymentActions.applyTerraformAction(org_temp_id, type, app_id)
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Remediation);
