import React, {useState, useContext, useEffect} from 'react'
import styled from 'styled-components'

import {connect} from "react-redux";
import StyledTypo from "../../../../components/Typo/StyledTypo";
import {MainContext} from "../../../../contexts";
import {useLocation} from "react-router-dom";
import {observeTabList} from "../../../../helpers/constants";
import AppDetailPanel from "./AppDetailPanel";
import RemediationPanel from "./RemediationPanel";
import {applicationActions} from "../../../../redux/actions";

const TabHeader = styled.div`
  display: flex;
  gap: 8.5px;
  align-items: center;
  z-index: 10;
  //position: relative;
`

const TabHeaderItem = styled.div`
  background: #F1F5FA;
  /* Gray light 2 */
  
  border: 2px solid #F1F5FA;
  box-sizing: border-box;
  border-radius: 15px;
  color: #F1F5FA;
  padding: 15px 47px 47px;
  cursor: pointer;
  
  &.active {
    background: #FFFFFF;
    /* Gray light 2 */
    
    border: 2px solid #F1F5FA;
    box-sizing: border-box;
    border-radius: 15px;
    color: #A4AFB7;
  }
  
  &:hover p{
    color: #5EB1E4;
    transition: color 0.5s;
  }
`

const TabBody = styled.div`
  margin-top: -40px;
  z-index: 20;
  //position: relative;
`

const ObserveTab = (props) => {
  const {setHideActivity, selectedRemediationTab, setSelectedRemediationTab, fetchSteampipeAction, steampipe_result, selectedApp} = props
  const [tabs, setTabs] = useState(observeTabList)
  const [selectedTab, setSelectedTab] = useState(null)
  const [selectedFilterType, setSelectedFilterType] = useState(null)

  const location = useLocation()

  useEffect(() => {
    if(selectedRemediationTab !== '') {
      setSelectedTab(location?.search ? new URLSearchParams(location?.search).get("tab") : selectedRemediationTab)
      setSelectedRemediationTab('')
    }
  }, [selectedRemediationTab])

  useEffect(() => {
    if (selectedTab) {
      handleSelectedTab(selectedTab)
    }
  }, [selectedTab])

  // useEffect(() => {
  //   if(selectedApp && selectedApp?.platform !== 'aws') {
  //     fetchSteampipeAction(selectedApp?.id)
  //   }
  // }, [selectedApp])

  const handleSelectedTab = (key) => {
    setTabs([...tabs.map(tab => ({...tab, active: tab.key === key}))])
    setHideActivity(true)
  }
  return (
      <div className="d-flex flex-column">
        <TabHeader>
          {tabs.map(tab => (
              <TabHeaderItem className={tab.active && 'active'} onClick={() => setSelectedTab(tab.key)}>
                <StyledTypo color={tab.active ? '#A4AFB7' : '#FFF'} size={1.25} line={1.4} letter="0" family="Roboto"
                            fontWeight={500}>
                  {tab.title}
                </StyledTypo>
              </TabHeaderItem>
          ))}
        </TabHeader>
        <TabBody>
          {!!tabs.filter(tab => tab.key === 'summary' && tab.active).length && (
              <AppDetailPanel {...props} setSelectedFilterType={setSelectedFilterType}/>
          )}
          {!!tabs.filter(tab => tab.key === 'remediation' && tab.active).length && (
              <RemediationPanel {...props} setSelectedFilterType={setSelectedFilterType}
                                selectedFilterType={selectedFilterType} steampipe={steampipe_result?.[selectedApp?.id]}/>
          )}
        </TabBody>
      </div>
  )
}

function mapStateToProps(state) {
  const {
    steampipe_result
  } = state.applications;
  return {
    steampipe_result
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSteampipeAction: (app_id) => dispatch(applicationActions.fetchSteampipeAction(app_id)),
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ObserveTab);
