import React from 'react'

import {ReactComponent as InfoIcon} from "../../../assets/icons/info_icon.svg";

import styled from 'styled-components'
import GradientProgressBar from "../../../components/GradientProgressBar/GradientProgressBar";
import {Col, Row} from "reactstrap";

import {getResourceCount} from "../../../helpers/constants";

const WidthContainer = styled.div`
  width: ${props => props.value || 100}%;
`

const CursorPointer = styled.span`
  cursor: pointer;
`

const TextWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const AppDescription = styled.div`
  background: #FFFFFF;
  border: 1px solid #F1F5FA;
  box-sizing: border-box;
  box-shadow: 0px 6px 4px rgba(104, 112, 137, 0.05);
  border-radius: 20px;
  width: 50%;
  padding: 20px 30px;
`

const HealthIndicator = (props) => {

  return (
      <div className="d-flex justify-content-between pt-3">
        <WidthContainer value={45}>
          <div className="d-flex pt-2 justify-content-between align-items-center ">
            <div className="font-size-26 font-weight-bold m-blue d-flex align-items-center">
              <div className="pr-2">Health Indicator</div>
            </div>
            <div className="font-size-36 font-weight-bold m-red m-tooltip">
              <span>{props.health ? Math.ceil(props.health) : 0} %</span>
              <CursorPointer>
                <InfoIcon width={15} height={15} fill="#485DCD" style={{marginBottom: 25}}/>
                <span className="tooltiptext"> <div>The overall health score is computed based on underlying computed individual resource health.</div></span>
              </CursorPointer>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <WidthContainer value={55}>
              <GradientProgressBar color="#5EB1E4" color2="#6FDBC4" value={props.health} maxValue="100" rotation="90"/>
            </WidthContainer>
            <div className="font-weight-lighter font-size-18 m-dark-gray">Since checked 7 hours ago</div>
          </div>
        </WidthContainer>
        <AppDescription>
          <Row>
            <Col md={6} className="mb-2">
              <TextWrapper>
                <div className="font-weight-bolder font-size-16 m-dark-gray">Platform:</div>
                <div className="font-weight-lighter font-size-16 m-blue">{props.provider?.toUpperCase() ?? ''}</div>
              </TextWrapper>
            </Col>
            <Col md={6}>
              <TextWrapper>
                <div className="font-weight-bolder font-size-16 m-dark-gray">Cluster:</div>
                <div className="font-weight-lighter font-size-16 m-blue">{props.resource?.cluster ? Array.isArray(props.resource?.cluster) ? props.resource?.cluster?.length : 1 ?? 0 : 0}</div>
              </TextWrapper>
            </Col>
            <Col md={6}>
              <TextWrapper>
                <div className="font-weight-bolder font-size-16 m-dark-gray">{props.resource?.instance?.length ? 'Instances' : 'Nodes'}:</div>
                <div
                  className="font-weight-lighter font-size-16 m-blue">{props.resource?.instance ? props.resource?.instance?.length ?? 0 : getResourceCount(props.resource?.cluster, 'node')}</div>
              </TextWrapper>
            </Col>
            <Col md={6}>
              <TextWrapper>
                <div className="font-weight-bolder font-size-16 m-dark-gray">Pods:</div>
                <div className="font-weight-lighter font-size-16 m-blue">{getResourceCount(props.resource?.cluster, 'pod')}</div>
              </TextWrapper>
            </Col>
          </Row>
        </AppDescription>
      </div>
  )
}

export default HealthIndicator;
