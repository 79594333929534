import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import AuthDashboard from "./AuthDashboard";
import styled from "styled-components";

import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import StyledTypo from "../../components/Typo/StyledTypo";
import { ReactComponent as BackArrowIcon } from "../../assets/icons/back_arrow_icon.svg";
import { ReactComponent as MailBoxIcon } from "../../assets/icons/mailbox_with_circle_icon.svg";

const LeftWrapper = styled.div`
  background: linear-gradient(359.21deg, #76eeb5 -20.98%, #58a3ee 100.74%);
  width: 426px;
  height: 100%;
  min-height: 715px;
  border-radius: 20px 0 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 47px 0 65px;
`;

const RightWrapper = styled.div`
  padding: 164px 111px 93px 203px;
  height: 100%;
  width: 652px;
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

const StyledUnderline = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

const EmailSentAlertPage = () => {
  const history = useHistory();

  const handleResubmission = () => {
    history.push("/forgot-password");
  };

  return (
    <AuthDashboard>
      <div className="d-flex">
        <LeftWrapper>
          <div className="text-center">
            <Logo width={40} height={60} fill="#fff" />
            <StyledTypo
              className="mt-3"
              color="#fff"
              size={1.2}
              line={34.4 / 28}
              letter="0"
              family="Roboto"
              fontWeight={400}
            >
              MatosSphere
            </StyledTypo>
          </div>
          <div className="text-center">
            <StyledTypo
              color="#fff"
              size={1}
              line={1.4}
              letter="0"
              family="Roboto"
              fontWeight={400}
            >
              Secure your cloud from build to run time
            </StyledTypo>
            <div className="col-10 offset-1">
              <StyledTypo
                className="mt-3 pt-1"
                color="#fff"
                size={0.8}
                line={25.2 / 18}
                letter="0"
                family="Roboto"
                fontWeight={300}
              >
                CloudMatos continuously assess, detect, govern, and remediate
                misconfigurations and threats in your build, deploy and cloud
                (AWS, GCP, Azure and Kubernetes) environments
              </StyledTypo>
            </div>
          </div>
        </LeftWrapper>
        <RightWrapper>
          <div className="pl-5 ml-3 mb-4 pb-2">
            <MailBoxIcon />
          </div>
          <StyledAnchor
            className="d-flex align-items-center mb-3 pb-1"
            onClick={() => history.goBack()}
          >
            <BackArrowIcon width={12} height={12} />
            <StyledTypo
              className="ml-2"
              color="#505050"
              size={0.8}
              line={23.44 / 20}
              letter="0"
              family="Roboto"
              fontWeight={300}
            >
              Back
            </StyledTypo>
          </StyledAnchor>
          <StyledTypo
            className="mb-4"
            color="#505050"
            size={1}
            line={36.4 / 26}
            letter="0"
            family="Roboto"
            fontWeight={400}
          >
            Email Sent
          </StyledTypo>
          <StyledTypo
            className="mb-4"
            color="#505050"
            size={0.8}
            line={21 / 14}
            letter="0"
            family="Poppins"
            fontWeight={300}
          >
            We have sent password resetting instructions to your email.
          </StyledTypo>
          <StyledTypo
            color="#50505088"
            size={0.75}
            line={18 / 12}
            letter="0"
            family="Poppins"
            fontWeight={300}
          >
            Didn't get our email? check your spam box or
          </StyledTypo>
          <div className="d-flex mb-4">
            <StyledUnderline onClick={handleResubmission}>
              <StyledTypo
                color="#485DCD"
                size={0.75}
                line={18 / 12}
                letter="0"
                family="Poppins"
                fontWeight={300}
              >
                Click here
              </StyledTypo>
            </StyledUnderline>
            <StyledTypo
              className="ml-1"
              color="#50505088"
              size={0.75}
              line={18 / 12}
              letter="0"
              family="Poppins"
              fontWeight={300}
            >
              for resubmission
            </StyledTypo>
          </div>
        </RightWrapper>
      </div>
    </AuthDashboard>
  );
};

function mapStateToProps(state) {
  const { appList, demoList, activityFeeds } = state.applications;
  const { deploymentApps, templates } = state.deployments;
  return {
    appList,
    demoList,
    activityFeeds,
    deploymentApps,
    templates,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailSentAlertPage);
