import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import StyledTypo from "../../../components/Typo/StyledTypo";
import EffectedButton from "../../../components/Buttons/EffectedButton";

import { ReactComponent as CirclePlusIcon } from "../../../assets/icons/circle_add_icon.svg";

import DropdownIcon from "../../../components/Buttons/DropdownIcon";
import StyledDropdown from "../../../components/Buttons/StyledDropdown";
import AddEnvironmentPanel from "../../modals/AddEnvironmentPanel";
import EnvTable from "./EnvTable";
import { applicationActions } from "../../../redux/actions";
import { getCurrentOrganization } from "../../../helpers/constants";
import EnvironmentTable from "./EnvironmentTable";

const PanelWrapper = styled.div`
  padding: 15px;
  width: 100%;
  // border: 1px solid #f1f1f1;
  // box-sizing: border-box;
  // border-radius: 0 20px 20px 20px;

  .panel-title-header {
    padding: 0 0 47px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const PanelHeader = styled.div`
  padding: 20px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EnvironmentListPanel = (props) => {
  const { envData, updateOrgEnvListAction, fetchOrgEnvListAction, updateOrgScanSettingAction } = props;

  const [environments, setEnvironments] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);

  useEffect(() => {
    if (envData) {
      setEnvironments(envData);
    }
  }, [envData]);

  useEffect(() => {
    fetchOrgEnvListAction(getCurrentOrganization());
  }, [fetchOrgEnvListAction]);

  const addEnvironments = (newEnv) => {
    let envPayload = {
      org_id: environments.organization_id,
      envList: environments.environments.map((env) =>
        env.key === newEnv.key ? { ...newEnv } : env
      ),
    };
    if (!envPayload.envList.filter((env) => env.key === newEnv.key).length) {
      envPayload = { ...envPayload, envList: [...envPayload.envList, newEnv] };
    }
    updateOrgEnvListAction(envPayload, "create");
  };

  const handleRemoveEnv = (envKey) => {
    let envPayload = {
      org_id: environments.organization_id,
      envList: environments.environments.filter((env) => env.key !== envKey),
    };
    updateOrgEnvListAction(envPayload, "remove");
  };

  const handleChangeStatus = (envKey) => {
    let envPayload = {
      org_id: environments.organization_id,
      envList: environments.environments.map((env) =>
        env.key !== envKey ? env : { ...env, is_active: !env.is_active }
      ),
    };
    updateOrgEnvListAction(envPayload, "update");
  };

  const handleEditEnv = (updatedEnv) => {
    let envPayload = {
      org_id: environments.organization_id,
      envList: environments.environments.map((env) =>
        env.key === updatedEnv.key ? { ...updatedEnv } : env
      ),
    };
    updateOrgEnvListAction(envPayload, "update");
  };

  return (
    <PanelWrapper>
      {/* <PanelHeader>
        <StyledTypo className="heading label">Environment List</StyledTypo>
        <div>
          <StyledDropdown
            isOpen={showAddModal}
            toggle={() => setShowAddModal((showAddModal) => !showAddModal)}
          >
            <DropdownIcon>
              <EffectedButton padding={0} height={45} noSpan>
                <CirclePlusIcon />
                <StyledTypo className="ml-2 label" color="#fff">
                  Add Environment
                </StyledTypo>
              </EffectedButton>
            </DropdownIcon>
            <AddEnvironmentPanel
              addNewEnvAction={addEnvironments}
              toggle={(status) => setShowAddModal(status)}
              envData={envData}
            />
          </StyledDropdown>
        </div>
      </PanelHeader> */}
      <div>
        <EnvironmentTable 
          data={environments}
          handleRemoveEnv={handleRemoveEnv}
          handleChangeStatus={handleChangeStatus}
          handleEditEnv={handleEditEnv}
          updateOrgScanSettingAction={updateOrgScanSettingAction}
          addNewEnvAction={addEnvironments}
          envData={envData}
        />
      </div>
    </PanelWrapper>
  );
};

function mapStateToProps(state) {
  const { envData } = state.applications;
  return {
    envData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrgEnvListAction: (org_id) =>
      dispatch(applicationActions.fetchOrgEnvListAction(org_id)),
    updateOrgEnvListAction: (payload, kind) =>
      dispatch(applicationActions.updateOrgEnvListAction(payload, kind)),
    updateOrgScanSettingAction: (data) => dispatch(applicationActions.updateOrgScanSettingAction(data))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnvironmentListPanel);
