import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'

import { Graph } from 'react-d3-graph'
import { config1 as graphConfig } from '../graph-configs'
import { applicationActions } from '../../../redux/actions'
import styled from 'styled-components'

const InstanceCveWrapper = styled.div`
  width: ${props => props?.settings?.width ?? 1500}px;
  height: ${props => props?.settings?.height ?? 600}px;
  background: #f8f9f9;
  margin: 10px 10px;
  border-radius: 4px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`

const instanceCveIcons = {
  Instance: './images/EC2Instance.png',
  InstanceKeyPair: './images/EC2KeyPair.png',
  CVEGroup: './images/CVEGroup.png',
  NetworkInterface: './images/NetworkInterface.png',
  AttachVolume: './images/Image.png'
}

const InstanceCve = props => {
  // graph payload (with minimalist structure)
  const { instance_cve_detail, fetchInstanceCveDetailAction, data, settings } = props

  const [instanceCveData, setInstanceCveData] = useState(null)

  useEffect(() => {
    fetchInstanceCveDetailAction(data?.app_id, data?.node_id)
  }, [data])

  useEffect(() => {
    if (instance_cve_detail?.[data?.app_id]?.[data?.node_id]) {
      const userAssetsResp = instance_cve_detail?.[data?.app_id]?.[data?.node_id].result ?? {}
      setInstanceCveData(() => ({
        nodes: userAssetsResp.nodes.map(node => ({
          id: node.id ?? node.name,
          name: `${node.type}: ${node.name ?? node.id}`,
          svg: instanceCveIcons?.[node.type]
        })),
        links: userAssetsResp.links.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              t => t.source === value.source && t.target === value.target
            )
        )
      }))
    }
  }, [instance_cve_detail])

  const onClickNode = function (nodeId) {
    console.log(`Clicked node ${nodeId}`)
  }

  const onClickLink = function (source, target) {
    console.log(`Clicked link between ${source} and ${target}`)
  }

  let myConfig = JSON.parse(JSON.stringify(graphConfig))

  myConfig.staticGraph = false
  myConfig.collapsible = props.settings.collapsible
  myConfig.directed = props.settings.directedGraph
  myConfig.initialZoom = 0.8

  myConfig.node.size = 300
  myConfig.node.fontSize = 10
  myConfig.node.highlightFontSize = 10

  myConfig.link.strokeWidth = 1.5
  myConfig.link.markerHeight = 4
  myConfig.link.markerWidth = 4
  myConfig.width = settings?.width ?? 1500
  myConfig.height = settings?.height ?? 600


  return (
    <>
      <InstanceCveWrapper settings={settings}>
        {instanceCveData && (
          <Graph
            id='graph-instance-cve' // id is mandatory
            data={instanceCveData}
            config={myConfig}
            onClickNode={onClickNode}
            onClickLink={onClickLink}
          />
        )}
      </InstanceCveWrapper>
    </>
  )
}
function mapStateToProps (state) {
  const { instance_cve_group, instance_cve_detail } = state.applications
  return {
    instance_cve_group, instance_cve_detail
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchInstanceCveGroupAction: app_id =>
      dispatch(applicationActions.fetchInstanceCveGroupAction(app_id)),
    fetchInstanceCveDetailAction: (app_id, node_id) =>
      dispatch(applicationActions.fetchInstanceCveDetailAction(app_id, node_id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstanceCve)
