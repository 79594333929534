import React, {
  useState,
  useEffect,
  useRef,
} from "react";
import styled from "styled-components";

import * as global from "../../../helpers/constants";
import StyledTypo from "../../../components/Typo/StyledTypo";
import { actionResourceType } from "../../../helpers/constants";
import { ReactComponent as UpArrow } from "../../../assets/icons/arrow-angle-up-sm.svg";
import { ReactComponent as RightArrow } from "../../../assets/icons/arrow-angle-right-sm.svg";
import { ReactComponent as ScanIcon } from "../../../assets/icons/scan_icon.svg";
import { ReactComponent as AWSLogo } from "../../../assets/icons/aws_logo.svg";
import { ReactComponent as FullscreenIcon } from "../../../assets/icons/fullscreen_icon.svg";
import { Badge } from "reactstrap";

import Tree from "react-d3-tree";
import ResourceTreeModal from "../../modals/ResourceTreeModal";

const orgChart = {
  name: "",
  icon: <AWSLogo width={30} height={20} />,
};

const ResourceTreeWrapper = styled.div`
  width: 100%;
  height: 400px;
  border: 1px solid #f1f1f1;
  border-radius: 20px;
  margin-bottom: 30px;
  padding: 10px;
  position: relative;
`;

const FullscreenButtonWrapper = styled.div`
  padding: 5px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
`;

const ResourcesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const GraphContentWrapper = styled.div`
  width: 100%;
  padding-right: 70px;
`;

const ResourceContainer = styled.div`
  //display: flex;
  //justify-content: space-between;
  //align-items: center;
  //margin-top: 35px;
  //border-radius: 5px;
  //border: 1px solid #f1f1f1;
  //background-color: #fff;
  //padding: 10px 15px;
  //margin-top: 10px;
  //display: flex;
  //align-items: center;
  //cursor: pointer;
  position: relative;
  margin-right: 20px;
  width: 45%;
  .scan-icon {
    display: none;
    position: absolute;
    right: 10px;
    top: 20px;
    cursor: pointer;
  }
  &:hover {
    .scan-icon {
      display: block;
    }
  }
`;

const ResourceItem = styled.div`
  //display: flex;
  //justify-content: space-between;
  //align-items: center;
  //margin-top: 35px;
  border-radius: 5px;
  //border: 1px solid #ccc;
  background-color: #fff;
  padding: 10px 15px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: border 500ms ease;
  box-shadow: inset 0 1px 3px 1px rgba(0, 0, 0, 0.3);
  &:hover {
    //border: 1px solid #65C2D7;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.3);
  }
  //width: 45%;
`;

const SubResourceItem = styled.div`
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  background-color: #fff;
  padding: 10px 15px;
  margin-top: 10px;
  //display: flex;
  //align-items: center;
  cursor: pointer;
  //width: 350px;
  margin-left: 40px;
  //justify-content: space-between;
  &:hover {
    //border: 1px solid #65C2D7;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.3);
  }
`;

const TitleWrapper = styled.div`
  //padding-left: 18px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: #505050;
  //width: 90%;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  right: -15%;
  top: 120px;
`;

const StyledLegend = styled.div`
  display: flex;
  gap: 7px;
  .legend {
    width: 15px;
    height: 15px;
    background-color: ${(props) => props.color};
  }
  align-items: center;
`;

const LegendWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const LegendContainer = styled.div`
  padding: 5px 20px;
  border-radius: 10px;
  background-color: #f1f1f1;
  margin-left: 20px;
  box-shadow: 0px 6px 4px rgb(104 112 137 / 5%);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ResourceShowWrapper = styled.div`
  margin-top: 10px;
  border-top: 1px solid #f1f1f1;
  max-height: 180px;
  overflow-y: auto;
`;

const ResourceShowDetailBlock = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: break-word;
`;

const Resources = (props) => {
  const {
    resources,
    clusterInfoLoading,
    csp,
    setSelectedRemediationTab,
    setSelectedFilterType,
    hideActivity,
    grouped,
  } = props;
  const [resourceData, setResourceData] = useState({ ...resources });
  const [, setGraphData] = useState(null);
  const [resourceType, setResourceType] = useState({});
  const [groupedType, setGroupedType] = useState(actionResourceType[csp]);

  const [showResourceDetail, setShowResourceDetail] = useState({});
  const [graphSize, setGraphSize] = useState(400);
  const [isGraphShow, setIsGraphShow] = useState(true);

  const [showTreeModal, setShowTreeModal] = useState(false);
  const [treeData, setTreeData] = useState(orgChart);

  const graphRef = useRef();

  useEffect(() => {
    if (grouped) {
      setGroupedType(
        Object.keys(grouped).map((type) => ({
          label: type,
          value: grouped[type].map((i) => i.type).join(","),
        }))
      );
    }
  }, [grouped]);

  useEffect(() => {
    if (graphRef.current) {
      const size = graphRef?.current?.clientWidth ?? 400;
      if (size !== graphSize) {
        setGraphSize(size);
        setIsGraphShow(false);
      }
    }
  }, [hideActivity]);

  useEffect(() => {
    if (!isGraphShow) {
      setIsGraphShow(true);
    }
  }, [isGraphShow]);

  useEffect(() => {
    setResourceData({ ...resources });
    if (
      resources &&
      Object.keys(resources).filter((key) =>
        ["cluster", "instance"].includes(key)
      ).length
    ) {
      const totalResource = {
        cluster: resources?.cluster?.map((item) => ({
          name: item.self.name,
          description: `<div class="pb-2"><strong>CLUSTER</strong></div><div class="pb-2"><strong>name</strong>: ${item.self.name}</div> <div class="pb-2"><strong>region</strong>: ${item.self.region}</div>`,
        })),
        node: resources?.cluster
          ?.map((cluster) =>
            cluster?.node?.map((node) => ({
              name: node.self.name,
              cluster: node.self.cluster_name,
              description: `<div class="pb-2"><strong>NODE</strong></div><div class="pb-1"><strong>name</strong>: ${node.self.name}</div><div class="pb-2"><strong>cluster</strong>: ${node.self.cluster_name}</div>`,
            }))
          )
          .flat()
          .filter((item) => item),
        pod: resources?.cluster
          ?.map((cluster) =>
            cluster?.pod?.map((pod) => ({
              name: pod.self.name,
              cluster: pod.self.cluster_name,
              node: pod.self.node_name,
              description: `<div class="pb-2"><strong>POD</strong></div><div class="pb-2"><strong>name</strong>: ${pod.self.name}</div> <div class="pb-2"><strong>cluster</strong>: ${pod.self.cluster_name}</div> <div class="pb-2"><strong>node</strong>: ${pod.self.node_name}</div>`,
            }))
          )
          .flat()
          .filter((item) => item),
        service: resources?.cluster
          ?.map((cluster) =>
            cluster?.service?.map((service) => ({
              name: service.self.name,
              description: `<div class="pb-2"><strong>SERVICE</strong></div><div class="pb-2"><strong>name</strong>: ${service.self.name}</div> <div class="pb-2"><strong>cluster</strong>: ${service.self.cluster_name}</div> <div class="pb-2"><strong>namespace</strong>: ${service.self.namespace}</div>`,
            }))
          )
          .flat(),
      };
      const tempData = {
        name: "Resources",
        description: `<div class="pb-2"><strong>Resources</strong></div><div class="pb-2"><strong>cluster</strong>: ${totalResource?.cluster?.length}</div><div class="pb-2"><strong>node</strong>: ${totalResource?.node?.length}</div><div class="pb-2"><strong>pod</strong>: ${totalResource?.pod?.length}</div><div class="pb-2"><strong>service</strong>: ${totalResource?.service?.length}</div>`,
        children: totalResource.cluster
          ? totalResource.cluster.map((cluster) => ({
              name: cluster.name,
              description: cluster.description,
              color: "#ffba00",
              // color: '#5EB1E4',
              children: [
                ...(totalResource?.node
                  ? totalResource.node
                      .filter((node) => node.cluster === cluster.name)
                      .map((node) => ({
                        name: node.name,
                        description: node.description,
                        color: "#485DCD",
                        children: global.getChildren(
                          totalResource?.pod.filter(
                            (pod) =>
                              pod.cluster === cluster.name &&
                              pod.node === node.name
                          ),
                          global.getDetailParams(
                            totalResource?.pod?.filter(
                              (pod) =>
                                pod.cluster === cluster.name &&
                                pod.node === node.name
                            ).length,
                            6
                          ),
                          "#61bf92",
                          // '#FF487F',
                          -1,
                          global.getDetailParams(
                            totalResource?.pod?.filter(
                              (pod) =>
                                pod.cluster === cluster.name &&
                                pod.node === node.name
                            ).length,
                            6
                          ).cols - 1
                        ),
                      }))
                  : []),
                ...global.getChildren(
                  totalResource?.service,
                  global.getDetailParams(totalResource?.service?.length, 3),
                  "#65C2D7",
                  -1,
                  global.getDetailParams(totalResource?.service?.length, 3)
                    .cols - 1
                ),
              ],
              size:
                totalResource?.node && totalResource?.node?.length > 0
                  ? null
                  : 1,
            }))
          : [],
      };
      setGraphData({ ...tempData });
    }
  }, [resources]);

  useEffect(() => {
    let temp = {};
    let showDetail = {};
    groupedType.forEach((item) => {
      temp[item.label] = {
        children: item.value.split(",").map((type) => ({
          type,
          label:
            grouped?.[item?.label]?.filter((i) => i.type === type)?.[0]
              ?.label ?? type.toUpperCase(),
        })),
        open: false,
      };
      item.value.split(",").forEach((type) => {
        showDetail[type] = false;
      });
    });
    setShowResourceDetail(showDetail);
    setResourceType(temp);
  }, [groupedType]);

  useEffect(() => {
    setTreeData((treeData) => ({
      ...treeData,
      children: Object.keys(resourceType).map((type) => ({
        name: type,
        children: resourceType[type].children.map((item) => ({
          name: item.label,
          ...(resourceData[item.type]
            ? {
                children: resourceData[item.type]
                  ? resourceData[item.type].map((rsc) => ({
                      name: rsc.self.name,
                      attributes: rsc.self.region
                        ? {
                            region: rsc.self.region,
                          }
                        : {},
                      leaf: true,
                    }))
                  : [],
              }
            : { no_children: true }),
        })),
      })),
    }));
  }, [resourceType, resourceData]);

  const type = "MutatingDots";

  // const showGraph = useCallback(() => {
  //   return (
  //     <Sunburst
  //       data={graphData}
  //       width={graphSize}
  //       height={graphSize}
  //       count_member="size"
  //       font_size={12}
  //       labelFunc={(node) => node.data.name}
  //       tooltipFunc={(data) => data.description}
  //       colorFunc={(node, current_color) => {
  //         return node.data.color ? node.data.color : current_color;
  //       }}
  //     />
  //   );
  // }, [graphSize]);

  const renderCustomNode = ({ nodeDatum, toggleNode }) => (
    <g onClick={toggleNode}>
      <defs>
        <filter x="0" y="0" width="1" height="1" id="solid">
          <feFlood flood-color="white" result="bg" />
          <feMerge>
            <feMergeNode in="bg" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      {nodeDatum.icon ??
        (nodeDatum.leaf ? (
          <circle r={7} strokeWidth={1} fill="#fff" stroke="#5EB1E4"></circle>
        ) : !nodeDatum.children ? (
          <circle r={7} strokeWidth={0} fill="#ff487f"></circle>
        ) : (
          <circle r={7} strokeWidth={0} fill="#5EB1E4"></circle>
        ))}
      <g fill="#ff0000">
        <text
          filter="url(#solid)"
          fill="#505050"
          strokeWidth="0.5"
          x="15"
          fontSize="12px"
        >
          {nodeDatum.name}
        </text>
        {Object.keys(nodeDatum?.attributes ?? {}).map((key, idx) => (
          <text
            filter="url(#solid)"
            fill="#505050"
            strokeWidth="0.1"
            dy={16 * (idx + 1)}
            x="15"
            fontSize="12px"
            key={idx}
          >
            {key}: {nodeDatum.attributes[key]}
          </text>
        ))}
      </g>
    </g>
  );

  return (
    <ResourcesWrapper className="row">
      <span className="font-size-26 font-weight-bold m-gray mb-3">
        Resources
      </span>
      {/* {treeData && ( */}
        <ResourceTreeWrapper id="resource-tree">
          <FullscreenButtonWrapper onClick={() => setShowTreeModal(true)}>
            <FullscreenIcon width={20} height={20} />
          </FullscreenButtonWrapper>
          <Tree
            data={treeData}
            translate={{
              x: 300,
              y: 200,
            }}
            initialDepth={1}
            zoom={1}
            nodeSize={{
              x: 300,
              y: 30,
            }}
            transitionDuration={500}
            centeringTransitionDuration={800}
            renderCustomNodeElement={renderCustomNode}
            enableLegacyTransitions={true}
          />
        </ResourceTreeWrapper>
      {/* )} */}
      <GraphContentWrapper className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
        {/*<span className="font-size-26 font-weight-bold m-gray">Resources</span>*/}
        {clusterInfoLoading ? (
          <LoaderWrapper>{global.loadingBar(type)}</LoaderWrapper>
        ) : (
          <div className="d-flex flex-wrap mt-3">
            {Object.keys(resourceType).map((rsc_type) => (
              <ResourceContainer>
                <ResourceItem
                  onClick={() =>
                    setResourceType((resourceType) => ({
                      ...resourceType,
                      [rsc_type]: {
                        ...resourceType[rsc_type],
                        open: !resourceType[rsc_type].open,
                      },
                    }))
                  }
                >
                  <div className="ml-3 cursor-pointer mr-4">
                    {resourceType[rsc_type].open ? (
                      <UpArrow fill="#505050" />
                    ) : (
                      <RightArrow width="100%" fill="#505050" />
                    )}
                  </div>
                  <TitleWrapper>{rsc_type}</TitleWrapper>
                </ResourceItem>
                <div
                  className="scan-icon"
                  data-tooltip="Remediation"
                  onClick={() => {
                    setSelectedRemediationTab("remediation");
                    setSelectedFilterType(
                      groupedType.filter((item) => item.label === rsc_type)?.[0]
                    );
                  }}
                >
                  <ScanIcon width={21} height={"auto"} />
                </div>
                {resourceType[rsc_type].open && (
                  <div>
                    {resourceType[rsc_type].children.map((subItem) =>
                      resourceData[subItem.type]?.length ? (
                        <SubResourceItem>
                          <div
                            className="d-flex justify-content-between align-items-center"
                            onClick={() =>
                              setShowResourceDetail((showResourceDetail) => ({
                                ...showResourceDetail,
                                [subItem.type]:
                                  !showResourceDetail[subItem.type],
                              }))
                            }
                          >
                            <div className="d-flex align-items-center">
                              <StyledTypo
                                color="#505050"
                                size={0.875}
                                line={1.4}
                                letter="0"
                                family="Roboto"
                                fontWeight={500}
                              >
                                {subItem.label}
                              </StyledTypo>
                              <div className="ml-3 cursor-pointer">
                                {showResourceDetail[subItem.type] ? (
                                  <UpArrow fill="#505050" />
                                ) : (
                                  <RightArrow width="100%" fill="#505050" />
                                )}
                              </div>
                            </div>
                            <Badge
                              color={
                                resourceData[subItem.type]?.length
                                  ? "success"
                                  : "danger"
                              }
                            >
                              {resourceData[subItem.type]?.length ?? 0}
                            </Badge>
                          </div>
                          {!!resourceData[subItem.type]?.length &&
                            showResourceDetail[subItem.type] && (
                              <ResourceShowWrapper>
                                {resourceData[subItem.type].map((resource) => (
                                  <ResourceShowDetailBlock>
                                    <StyledTypo
                                      color="#505050"
                                      size={0.75}
                                      line={1.4}
                                      letter="0"
                                      family="Roboto"
                                      fontWeight={400}
                                    >
                                      {resource.self.name}
                                    </StyledTypo>
                                    {resource.self.region && (
                                      <StyledTypo
                                        color="#505050"
                                        size={0.75}
                                        line={1.4}
                                        letter="0"
                                        family="Roboto"
                                        fontWeight={400}
                                      >
                                        {resource.self.region}
                                      </StyledTypo>
                                    )}
                                  </ResourceShowDetailBlock>
                                ))}
                              </ResourceShowWrapper>
                            )}
                        </SubResourceItem>
                      ) : (
                        <div />
                      )
                    )}
                  </div>
                )}
              </ResourceContainer>
            ))}
            {/*{Object.keys(resourceData).map(key => (*/}
            {/*    <ResourceItem className="row align-items-center">*/}
            {/*      <div className="col-12 col-md-12 col-lg-7 d-flex align-items-center">*/}
            {/*        <StatusMark size={10}*/}
            {/*                    color={global.colors.status[resourceData[key].status === "RUNNING" ? 1 : 0]}/>*/}
            {/*        /!*<StatusMark size={10} color={global.colors.status[item.is_active ? 1 : 0]}/>*!/*/}
            {/*        <TitleWrapper>{key}</TitleWrapper>*/}
            {/*      </div>*/}
            {/*      <div className="col-12 col-md-12 col-lg-5 ">*/}
            {/*        <div className="float-right">*/}
            {/*          {resourceData[key].count ?? (Array.isArray(resourceData[key]) ? resourceData[key].length : 1)} resources*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </ResourceItem>*/}
            {/*))}*/}
            {/*{resourceData?.cluster && resourceTypes.map(t => (*/}
            {/*    <ResourceItem className="row align-items-center">*/}
            {/*      <div className="col-12 col-md-12 col-lg-7 d-flex align-items-center">*/}
            {/*        <StatusMark size={10}*/}
            {/*                    color={global.colors.status[resourceData[t]?.status === "RUNNING" ? 1 : 0]}/>*/}
            {/*        <TitleWrapper>{t}</TitleWrapper>*/}
            {/*      </div>*/}
            {/*      <div className="col-12 col-md-12 col-lg-5 ">*/}
            {/*        <div className="float-right">*/}
            {/*          {global.getResourceCount(resourceData?.cluster, t)} resources*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </ResourceItem>*/}
            {/*))}*/}
          </div>
        )}
      </GraphContentWrapper>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
        <LegendContainer>
          <LegendWrapper>
            <StyledLegend color="#ffba00">
              <div className="legend" />
              <StyledTypo
                color="#505050"
                size={0.875}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                CLUSTER
              </StyledTypo>
            </StyledLegend>
            <StyledLegend color="#485DCD">
              <div className="legend" />
              <StyledTypo
                color="#505050"
                size={0.875}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                NODE
              </StyledTypo>
            </StyledLegend>
            <StyledLegend color="#61bf92">
              <div className="legend" />
              <StyledTypo
                color="#505050"
                size={0.875}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                POD
              </StyledTypo>
            </StyledLegend>
            <StyledLegend color="#65C2D7">
              <div className="legend" />
              <StyledTypo
                color="#505050"
                size={0.875}
                line={1.4}
                letter="0"
                family="Roboto"
                fontWeight={400}
              >
                SERVICE
              </StyledTypo>
            </StyledLegend>
          </LegendWrapper>
        </LegendContainer>
        {/* <GraphContainer ref={graphRef}>
          {!clusterInfoLoading &&
            graphData &&
            resourceData?.cluster &&
            !!resourceData?.cluster.length &&
            isGraphShow &&
            showGraph()}
        </GraphContainer> */}
      </div>
      <ResourceTreeModal
        modal={showTreeModal}
        treeData={treeData}
        renderCustomNode={renderCustomNode}
        toggle={() => setShowTreeModal((showTreeModal) => !showTreeModal)}
      />
    </ResourcesWrapper>
  );
};

export default Resources;
