export function handleResponse(response) {
  if (response.status === 401) {
    return Promise.reject("invalid-token");
  }
  return response.text().then((text) => {
    try {
      const data = response.url.includes("manual-cloud-remediation")
        ? text
        : text && JSON.parse(text);
      if (!response.ok) {
        const error =
          (data && data?.description) ||
          (data && data.error) ||
          (data && data.message) ||
          response.statusText ||
          (data && data.errorMessage);
        return Promise.reject(error);
      }
      return data;
    } catch (err) {
      return Promise.reject("Failed");
    }
  });
}
