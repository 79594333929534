import React from "react";
import styled from "styled-components";
import RemediationTable from "../../../modals/RemediationTable";

const MainBodyWrapper = styled.div`
  width: 100%;
  //width: ${126200 / 1742}%;
  //border: 1px solid #ccc;
  //height: 1200px;
  padding: 30px 25px;
  //min-height: 80vh;

  /* white */

  background: #ffffff;
  /* Gray light 2 */

  border: 2px solid #f1f5fa;
  box-sizing: border-box;
  border-radius: 15px;
`;

const RemediationPanel = (props) => {
  const {
    remediations,
    solution,
    selectedApp,
    clusterInfo,
    setSelectedFilterType,
    selectedFilterType,
    steampipe,
  } = props;
  return (
    <MainBodyWrapper>
      {/*{selectedApp.platform === 'aws' ? (*/}
      <RemediationTable
        remediationList={remediations.filter(
          (item) => item.platform === selectedApp.platform
        )}
        solution={solution}
        appId={selectedApp?.id}
        selectedApp={selectedApp}
        csp={selectedApp.platform}
        resources={clusterInfo?.["resources"] ?? {}}
        grouped={clusterInfo?.["grouped_type"]}
        selectedFilterType={selectedFilterType}
        setSelectedFilterType={setSelectedFilterType}
      />
    </MainBodyWrapper>
  );
};

export default RemediationPanel;
